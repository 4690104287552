/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_rayon_id } from "../../actions/tracking";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class STEP_8_MODAL_REPLACE_RAYON extends Component {
  state = {
    rayon_id: "",
  };

  on_set_rayon_id = () => {
    const { rayon_id } = this.state;
    const { driver_object } = this.props;
    const driver_id = driver_object._id;
    const body = { driver_id, rayon_id, rayon_mode: "manual" };
    this.props.set_rayon_id(body);
    this.props.toggle_replace_rayon();
  };

  on_change_rayon_id = (rayon_id) => {
    this.setState({ rayon_id });
  };

  render() {
    //local storage

    //local state
    const { rayon_id } = this.state;

    //global props
    const { rayon_list_database } = this.props.data;
    const { driver_city, rayon_city } = this.props.properties;
    const { driver_object } = this.props;
    const { driver_list } = this.props.tracking;

    //content
    return (
      <main className="max_height">
        <h1 className="text_header">ONE RAYON MODE</h1>

        <section className="container_light mb-3">
          <table style={{ width: "100%", tableLayout: "fixed" }}>
            <tbody>
              <tr>
                <td>
                  Detail driver:
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>NAME</td>
                        <td>{driver_object?.DRIVER}</td>
                      </tr>
                      <tr>
                        <td>NOPOL</td>
                        <td>{driver_object?.NOPOL}</td>
                      </tr>
                      <tr>
                        <td>JENIS</td>
                        <td>{driver_object?.JENIS}</td>
                      </tr>
                      <tr>
                        <td>KAPASITAS_M3</td>
                        <td>{driver_object?.KAPASITAS_M3}</td>
                      </tr>
                      <tr>
                        <td>KAPASITAS_CTN</td>
                        <td>{driver_object?.KAPASITAS_CTN}</td>
                      </tr>
                      <tr>
                        <td>KELURAHAN</td>
                        <td>
                          {
                            driver_city?.find(
                              (item) => item?._id === driver_object?._id
                            )?.city_object?.kelurahan
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>KECAMATAN</td>
                        <td>
                          {
                            driver_city?.find(
                              (item) => item?._id === driver_object?._id
                            )?.city_object?.kecamatan
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>KOTA</td>
                        <td>
                          {
                            driver_city?.find(
                              (item) => item?._id === driver_object?._id
                            )?.city_object?.kota
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>PROVINSI</td>
                        <td>
                          {
                            driver_city?.find(
                              (item) => item?._id === driver_object?._id
                            )?.city_object?.provinsi
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <section className="container_light mb-3 background_white">
                    <div className="background_red badge_pill mb-3">
                      WARNING
                    </div>
                    <br />
                    This action will <b>replace</b> all existing rayons with
                    just one rayon.
                    <br />
                    The previous rayon in this driver data will be replaced!
                    <br />
                    <br />
                    If you want to add rayon without replacing the previous
                    rayon, select the "Add rayon" option.
                  </section>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="container_light mb-3">
          <p className="text_medium">Choose one rayon</p>
          <br />
          <table className="table full_width">
            <thead>
              <tr>
                <th></th>
                <th>NAME</th>
                <th>KELURAHAN</th>
                <th>KECAMATAN</th>
                <th>KOTA</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {rayon_list_database.map((rayon, idx) => {
                const city_object = rayon_city?.find(
                  (element) => element._id === rayon._id
                )?.city_object;
                const driver_object_rayon = driver_list?.find((driver) =>
                  driver?.rayon_id_list?.includes?.(rayon._id)
                );
                return (
                  <tr
                    key={idx}
                    className={
                      driver_object_rayon?._id
                        ? "background_grey"
                        : "background_white"
                    }
                  >
                    <td>
                      <button
                        className={`${
                          rayon_id === rayon._id
                            ? "checklist_active"
                            : "checklist_not"
                        } mb-2`}
                        onClick={this.on_change_rayon_id.bind(this, rayon._id)}
                      />
                    </td>
                    <td>
                      {rayon?.name}
                      <br />
                      {driver_object?.rayon_id_list?.includes(rayon._id) && (
                        <div className="badge_pill background_green">
                          Current rayon
                        </div>
                      )}
                    </td>
                    <td>{city_object?.kelurahan}</td>
                    <td>{city_object?.kecamatan}</td>
                    <td>{city_object?.kota}</td>
                    <td>
                      {driver_object_rayon?._id && (
                        <div>
                          <span className="badge_pill background_blue">
                            Already used
                          </span>
                          <p>{driver_object_rayon?.DRIVER}</p>
                          <p>{driver_object_rayon?.NOPOL}</p>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <br />
          <button
            className="button background_green"
            onClick={this.on_set_rayon_id}
          >
            Save
          </button>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  auth: state.auth,
  tracking: state.tracking,
  properties: state.properties,
});

export default connect(mapStateToProps, { set_rayon_id })(
  STEP_8_MODAL_REPLACE_RAYON
);
