//generate_filter_item
export const generate_filter_item = (toko_list) => (dispatch) => {
  /*
      yang perlu di agregat via fe:
      1. Depo
      2. SYSTEM
      3. SEGMENT
      4. Type Outlet

      5. rayon
      6. HARIKunjungan
      7. FreqVisit

      8. PROPINSI
      9. KABUPATEN
      10. KECAMATAN
      11. KELURAHAN
      */

  let depo_filter_array = [];
  let system_filter_array = [];
  let segment_filter_array = [];
  let type_filter_array = [];

  let rayon_filter_array = [];

  let day_filter_array = [];
  let cycle_filter_array = [];

  let provinsi_filter_array = [];
  let kabupaten_filter_array = [];
  let kecamatan_filter_array = [];
  let kelurahan_filter_array = [];

  const array_item = [
    {
      properties_name: "Depo",
      array_variable: depo_filter_array,
      array_name: "depo_filter_array",
    },

    {
      properties_name: "SYSTEM",
      array_variable: system_filter_array,
      array_name: "system_filter_array",
    },
    {
      properties_name: "SEGMENT",
      array_variable: segment_filter_array,
      array_name: "segment_filter_array",
    },
    {
      properties_name: "Type Outlet",
      array_variable: type_filter_array,
      array_name: "type_filter_array",
    },
    {
      properties_name: "rayon",
      array_variable: rayon_filter_array,
      array_name: "rayon_filter_array",
    },
    {
      properties_name: "HARIKunjungan",
      array_variable: day_filter_array,
      array_name: "day_filter_array",
    },
    {
      properties_name: "FreqVisit",
      array_variable: cycle_filter_array,
      array_name: "cycle_filter_array",
    },
    {
      properties_name: "PROPINSI",
      array_variable: provinsi_filter_array,
      array_name: "provinsi_filter_array",
    },
    {
      properties_name: "KABUPATEN",
      array_variable: kabupaten_filter_array,
      array_name: "kabupaten_filter_array",
    },
    {
      properties_name: "KECAMATAN",
      array_variable: kecamatan_filter_array,
      array_name: "kecamatan_filter_array",
    },
    {
      properties_name: "KELURAHAN",
      array_variable: kelurahan_filter_array,
      array_name: "kelurahan_filter_array",
    },
  ];

  toko_list.forEach((element) => {
    array_item.forEach((array_item) => {
      const value = element?.[array_item.properties_name];
      if (!array_item.array_variable.includes(value)) {
        array_item.array_variable.push(value);
      }
    });
  });

  array_item.forEach((array_item) => {
    dispatch({
      type: "push_unique_items_to_array",
      payload: {
        key: array_item.array_name,
        value: array_item.array_variable,
      },
    });
  });
};

//hide_filter_array
export const hide_filter_array =
  ({ array_name, element_name }) =>
  (dispatch) => {
    dispatch({
      type: "hide_filter_array",
      payload: { array_name, element_name },
    });
    dispatch({
      type: "status_action",
    });
  };

//show_filter_array
export const show_filter_array =
  ({ array_name, element_name }) =>
  (dispatch) => {
    dispatch({
      type: "show_filter_array",
      payload: { array_name, element_name },
    });
    dispatch({
      type: "status_action",
    });
  };

//hide_all_filter_array
export const hide_all_filter_array = () => (dispatch) => {
  dispatch({
    type: "hide_all_filter_array",
  });
  dispatch({
    type: "status_action",
  });
};

//show_all_filter_array
export const show_all_filter_array =
  ({ array_name }) =>
  (dispatch) => {
    dispatch({
      type: "show_all_filter_array",
      payload: { array_name },
    });
    dispatch({
      type: "status_action",
    });
  };
