/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { bbox } from "@turf/turf";
import Select from "react-select";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal";
import EditSalesman from "../parent/EditSalesman";
import TrackingBulanan from "./TrackingBulanan";
import Driver from "./Driver";
import LIST_OUTLET_BY_SALESMAN from "./LIST_OUTLET_BY_SALESMAN";

/*REDUX FUNCTION*/
import {
  getSalesman,
  clearSalesman,
  getOutlets,
  clearOutletSalesman,
  set_selected_salesman,
  searchSalesman,
  setSalesmanMode,
  setToggleRoute,
  getSalesmanRegister,
  getOutletFilter,
  setSalesmanFilterVal,
  setSalesmanFilterMode,
  getTotalSalesman,
  set_selected_menu,
  edit_hari_kunjungan,
  turn_on_menu,
  turn_on_menu_status,
  turn_off_menu,
  selectedFilter1Obj,
  listSelectFilter2,
  selectedFilter2Obj,
  selectedFilter2MultiLength,
  withOutFilter2,
  clear_salesman_list,
  search_salesman_by_divisi,
  get_salesman_by_id,
  popUpAction,
  edit_status,
  searchOutletBySalesman,
  getSalesmanAll,
} from "../../actions/dataActions";
import { setTrackingView, setOutletView } from "../../actions/mapActions";
import {
  getLog,
  clearLog,
  sliderLog,
  filter_divisi,
  set_time_array,
  tracking_menu_selected,
} from "../../actions/tracking";
import {
  get_salesman_active,
  get_salesman_online,
} from "../../actions/salesmanActions";
import { set_value_properties } from "../../actions/propertiesActions";

/*PICTURE ASSET*/
import icon_view from "../../img/icon_view_grey.svg";
import icon_view_active from "../../img/icon_view_green.svg";

/*GENERAL*/
import formatDateFormal from "../../validation/format_date_formal";
import formatDateLengkap from "../../validation/format-date-lengkap";
import isEmpty from "../../validation/is-empty";

/*NON IMPORT*/
const data_hirarki_filter_divisi = require("../../reducers/localData/hirarkiFilter.json");
const bike = require("../../img/bike.png");
const truck = require("../../img/truck.png");

class PLANNING_TRACKING_MENU extends Component {
  onlineMode = [
    {
      bgColor: "bg-green-600",
      stat: "Online",
    },
    {
      bgColor: "bg-blue-600",
      stat: "Online a few hours ago",
    },
    {
      bgColor: "bg-red-600",
      stat: "Offline",
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      list_column: 50,
      intervalvalid: {},
      mode: { key: "Nama Salesman", value: "Salesman" },
      dummyProgress: Math.floor(Math.random() * 100),
      realtimeStatus: "Inactive",
      selectedData: "",
      selectedFilter1: "",
      selectedFilter2: "",
      selectedFilter2MultiLength: 0,
      listSelectFilter: [
        { key: "Region", label: "Region", value: "REGIONAL" },
        { key: "Branch", label: "Branch", value: "CABANG" },
        { key: "Depo", label: "Depo", value: "Depo" },
        {
          key: "Salesman Online",
          label: "Online Salesman",
          value: "Salesman Online",
        },
        {
          key: "status",
          label: "Active Salesman",
          value: "on",
        },
        {
          key: "All Salesman",
          label: "All Salesman",
          value: "All Salesman",
        },
      ],
      selectedSalesmanDetail: {},
      salesmanDetailNav: false,
      salesmanList: [],
      searchSalesmanQuery: "",
      filterHari: "semua",
      filterFreq: "semua",
      outlet: {},
      modal_edit_hari: false,
      hari: "R01",
      modal_mode_edit_salesman: false,
      state_modal_edit_salesman: false,
      time_array: [],
      start_time_hour: "08",
      start_time_minutes: "00",
      end_time_hour: "16",
      end_time_minutes: "00",
      start_date: 0,
      end_date: 0,
      data_hirarki_filter_divisi: data_hirarki_filter_divisi,
      interval_realtime: 5,
      sub_limitasi_fitur: false,
      findSearch: "",
      valueTarget: "",
    };
  }

  componentDidMount() {
    this.props.getTotalSalesman(this.props.auth.country_selected);
    this.filterBerdasarkanAdmin();
    this.filterSubLimitasi();
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalvalid);
    this.props.set_selected_menu("");
    this.props.setSalesmanFilterVal("");
  }

  filterBerdasarkanAdmin() {
    const cwk = this.props.auth.job_positions.filter(
      (e) => e.value === this.props.auth.user.jabatan
    )[0];

    if (cwk) {
      if (cwk.cakupan_wilayah_kerja === "depo") {
        this.setState({
          listSelectFilter: [{ key: "Depo", label: "Depo", value: "Depo" }],
        });
      }
      if (cwk.cakupan_wilayah_kerja === "cabang") {
        this.setState({
          listSelectFilter: [
            { key: "Branch", label: "Branch", value: "CABANG" },
            { key: "Depo", label: "Depo", value: "Depo" },
          ],
        });
      }
      if (cwk.cakupan_wilayah_kerja === "regional") {
        this.setState({
          listSelectFilter: [
            { key: "Region", label: "Region", value: "REGIONAL" },
            { key: "Branch", label: "Branch", value: "CABANG" },
            { key: "Depo", label: "Depo", value: "Depo" },
          ],
        });
      }
    }
  }

  filterSubLimitasi() {
    const sub_limit = this.props.auth.job_positions.filter(
      (e) => e.value === this.props.auth.user.jabatan
    )[0];
    if (sub_limit) {
      if (sub_limit.sub_limitasi.includes("driver")) {
        this.setState({ sub_limitasi_fitur: true });
        this.on_change_tracking_menu_selected("driver");
      }
    }
  }

  on_change_tracking_menu_selected = (menu) => {
    this.props.tracking_menu_selected(menu);
  };

  toggle_tracking_bulanan = () => {
    this.setState({
      modal_tracking_bulanan: !this.state.modal_tracking_bulanan,
    });
  };

  on_toggle_view_log = (item) => {
    const { list_menu_active } = this.props.data;
    const is_visible = list_menu_active.find((e) => e.key === item).active;
    if (is_visible) {
      this.props.turn_off_menu(item);
    } else {
      this.props.turn_on_menu_status(item);
    }
  };

  on_close_toko = () => {
    this.props.set_selected_salesman("");
    this.setState({ salesmanDetailNav: false });
    this.props.turn_off_menu("salesman_log");
    this.props.turn_off_menu("toko_salesman");
    this.props.set_selected_menu("planing");
  };

  on_view_toko_salesman = () => {
    const { list_menu_active } = this.props.data;
    const is_toko_salesman = list_menu_active.find(
      (e) => e.key === "toko_salesman"
    ).active;
    if (is_toko_salesman) {
      this.props.turn_off_menu("toko_salesman");
    } else {
      const params = {
        key: "toko_salesman",
        value: [{ value: "toko_salesman" }],
      };
      this.props.turn_on_menu({ key: "toko_salesman", config: params });
    }
  };

  toggle_edit_hari = (outlet) => {
    this.setState({ modal_edit_hari: !this.state.modal_edit_hari });
    if (outlet) {
      this.setState({ outlet, hari: outlet.HARIKunjungan });
    }
  };

  on_change = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  submit_edit_hari = () => {
    const { outlet, hari } = this.state;
    const { KodeSales, NamaSales } = outlet;
    const body = {
      _id: outlet._id,
      HARIKunjungan: hari,
      KodeSales,
      NamaSales,
    };
    this.props.edit_hari_kunjungan(body);
    this.setState({ modal_edit_hari: false });
  };

  realtimeToggle = (p) => {
    const checked = p.target.checked;
    if (checked) {
      if (this.state.selectedFilter1 === "Salesman Register") {
        this.props.getSalesmanRegister(this.props.auth.country_selected);
      } else if (this.state.selectedFilter1 === "Salesman Online") {
        this.props.get_salesman_online(this.props.auth.country_selected);
      } else {
        this.props.getSalesman({
          key: this.props.data.selectedFilter1Obj.value,
          value: this.props.data.selectedFilter2Obj,
          tambah_kurang: "kurang",
        });
      }
      let intervalvalid = setInterval(() => {
        if (this.state.selectedFilter1 === "Salesman Register") {
          this.props.getSalesmanRegister(this.props.auth.country_selected);
        } else if (this.state.selectedFilter1 === "Salesman Online") {
          this.props.get_salesman_online(this.props.auth.country_selected);
        } else {
          this.props.getSalesman({
            key: this.props.data.selectedFilter1Obj.value,
            value: this.props.data.selectedFilter2Obj,
            tambah_kurang: "kurang",
          });
        }
        console.log(
          "raltime",
          new Date(),
          this.state.interval_realtime * 1000 * 60
        );
      }, this.state.interval_realtime * 1000 * 60);
      this.setState({ intervalvalid, realtimeStatus: "Active" });
    } else {
      clearInterval(this.state.intervalvalid);
      this.setState({ realtimeStatus: "Inactive" });
    }
  };

  routeToggle = (p) => {
    const checked = p.target.checked;
    this.props.setToggleRoute(checked);
  };

  handleSelectFilter1 = (val) => {
    clearInterval(this.state.intervalvalid);
    this.props.clear_salesman_list();
    this.setState({
      realtimeStatus: "Inactive",
    });
    this.props.selectedFilter1Obj(val);

    if (
      val.key === "All Salesman" ||
      val.key === "status" ||
      val.key === "Salesman Online"
    ) {
      this.props.withOutFilter2(true);
      if (this.props.auth?.country_selected === "INDONESIA") {
        const depo = this.props.data.salesman_filter.depo
          .filter((e) => e.folder_id === "63a4341e303d716c2f70bf04")
          .map((x) => {
            return x.Depo;
          });
        this.props.getSalesmanAll(depo, val.key);
        this.props.turn_on_menu({
          key: "salesman",
          config: {
            key: "Depo",
          },
        });
      } else {
        const depo = this.props.data.salesman_filter.depo
          .filter((e) => e)
          .map((x) => {
            return x.Depo;
          });
        this.props.getSalesmanAll(depo, val.key);
        this.props.turn_on_menu({
          key: "salesman",
          config: {
            key: "Depo",
          },
        });
      }
    } else if (val.key !== "status") {
      let arrSFilter2 = [];
      let keyObj = "";
      if (val.value === "Depo") {
        keyObj = "depo";
      }
      if (val.value === "CABANG") {
        keyObj = "cabang";
      }
      if (val.value === "REGIONAL") {
        keyObj = "regional";
      }
      this.props.data.salesman_filter[keyObj].forEach((element) => {
        arrSFilter2.push({
          value: element[val.value],
          label: element[val.value],
        });
      });
      this.setState({
        selectedFilter1: val.value,
      });
      this.props.withOutFilter2(false);
      this.props.listSelectFilter2(
        arrSFilter2.sort((a, b) => {
          if (a["label"] < b["label"]) return -1;
          if (a["label"] > b["label"]) return 1;
          return 0;
        })
      );
    } else {
      this.setState({
        selectedFilter1: "status",
        selectedFilter2: "on",
      });
      this.props.withOutFilter2(true);
      const params = {
        key: val.key,
        value: [{ value: val.value }],
        tambah_kurang: "kurang",
      };

      this.props.get_salesman_active(this.props.auth.country_selected);
      this.props.turn_on_menu({ key: "salesman", config: params });
    }
    this.props.setSalesmanFilterMode(val.value);
    this.props.setSalesmanFilterVal("");
  };

  handleSelectFilter2 = (val) => {
    this.setState({
      selectedFilter2: val.value,
    });
    this.props.selectedFilter2Obj(val);
    this.props.setSalesmanFilterVal(val.map((e) => e.label));
  };

  handleClick = () => {
    const params = {
      key: this.props.data.selectedFilter1Obj.value,
      value: this.props.data.selectedFilter2Obj,
      tambah_kurang: "kurang",
    };
    this.props.getSalesman(params);
    this.setState({ list_column: 50 });
    this.props.turn_on_menu({
      key: "salesman",
      config: params,
    });
  };

  detailSalesman(e) {
    const salesman_id = e._id;
    this.props.set_value_properties({
      key: "salesman_id_active",
      value: salesman_id,
    });
    this.setState({ selectedSalesmanDetail: e, salesmanDetailNav: true });
    const params = {
      id: e._id,
      kd_sales_arr: e.kd_sales_arr,
      Depo: e.Depo,
    };

    this.props.getLog({ id: e._id });
    this.props.set_selected_salesman(e._id);
    this.props.setSalesmanMode("icon");
    this.props.set_selected_menu("toko_salesman");
    this.props.turn_on_menu({ key: "salesman_log", config: params });
    this.props.turn_off_menu("salesman");
  }

  handleLogSlider(e) {
    this.props.sliderLog({ slider: e.target.value });
  }

  handleSearch(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  findSearch = (event) => {
    event.preventDefault();
    const { findSearch } = this.state;
    this.props.searchSalesman(findSearch);
  };

  handleFilterHari(e) {
    this.setState({ filterHari: e.target.value });
    this.props.getOutletFilter({
      filterHari: e.target.value,
      filterFreq: this.state.filterFreq,
    });
  }

  handleFilterFreq(e) {
    this.setState({ filterFreq: e.target.value });
    this.props.getOutletFilter({
      filterFreq: e.target.value,
      filterHari: this.state.filterHari,
    });
  }

  handle_filter_divisi_1 = (e) => {
    const { data_hirarki_filter_divisi } = this.state;
    let data_filter = [];
    let data_filter_child = [];
    e.forEach((element) => {
      data_hirarki_filter_divisi.forEach((el) => {
        if (el.TYPE === element.value) {
          data_filter.push({ label: el.DIVISI, value: el.DIVISI });
          data_filter_child.push(el.DETAIL);
        }
      });
    });
    let unix_data_filter = [];
    data_filter.forEach((element) => {
      if (!unix_data_filter.includes(element.value)) {
        unix_data_filter.push(element.value);
      }
    });
    unix_data_filter = unix_data_filter.map((e) => {
      return { label: e, value: e };
    });
    this.props.filter_divisi("filter_divisi_1_choose", e);
    this.props.filter_divisi("filter_divisi_2", unix_data_filter);
    this.props.filter_divisi("filter_divisi_child", data_filter_child);
    this.props.search_salesman_by_divisi(data_filter_child);
    if (e.length === 0) {
      this.props.filter_divisi("filter_divisi_2", []);
      this.props.filter_divisi("filter_divisi_2_choose", []);
      this.props.filter_divisi("filter_divisi_3", []);
      this.props.filter_divisi("filter_divisi_3_choose", []);
    }
  };

  handle_filter_divisi_2 = (e) => {
    const { data_hirarki_filter_divisi } = this.state;
    let data_filter = [];
    let data_filter_child = [];
    e.forEach((element) => {
      data_hirarki_filter_divisi.forEach((el) => {
        if (el.DIVISI === element.value) {
          data_filter.push({ label: el.DETAIL, value: el.DETAIL });
          data_filter_child.push(el.DETAIL);
        }
      });
    });
    let unix_data_filter = [];
    data_filter.forEach((element) => {
      if (!unix_data_filter.includes(element.value)) {
        unix_data_filter.push(element.value);
      }
    });
    unix_data_filter = unix_data_filter.map((e) => {
      return { label: e, value: e };
    });
    this.props.filter_divisi("filter_divisi_2_choose", e);
    this.props.filter_divisi("filter_divisi_3", unix_data_filter);
    this.props.filter_divisi("filter_divisi_child", data_filter_child);
    this.props.search_salesman_by_divisi(data_filter_child);
    if (e.length === 0) {
      this.props.filter_divisi("filter_divisi_3", []);
      this.props.filter_divisi("filter_divisi_3_choose", []);
    }
  };

  handle_filter_divisi_3 = (e) => {
    const data_filter_child = e.map((e) => {
      return e.value;
    });
    this.props.filter_divisi("filter_divisi_3_choose", e);
    this.props.filter_divisi("filter_divisi_child", data_filter_child);
    this.props.search_salesman_by_divisi(data_filter_child);
  };

  handle_edit_salesman = (e) => {
    this.setState({
      state_modal_edit_salesman: !this.state_modal_edit_salesman,
    });
    this.props.get_salesman_by_id(e._id);
  };

  flyToLog = () => {
    const { lineString } = this.props.tracking;
    const { map } = this.props.map;
    const sidebarW = parseInt(localStorage.getItem("sidebar-width") || 500);
    let outlet_list_ft = [];
    if (lineString.length > 0) {
      const trackFt = lineString.map((l) => [l.longitude, l.latitude]);
      let lineFt = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: trackFt,
        },
      };
      outlet_list_ft.push(lineFt);
    }
    let geojson = {
      type: "FeatureCollection",
      features: [...outlet_list_ft],
    };
    try {
      let [minLng, minLat, maxLng, maxLat] = bbox(geojson);
      map.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: { top: 200, bottom: 200, left: sidebarW + 50, right: 50 },
        }
      );
    } catch (e) {
    } finally {
      this.props.setTrackingView(true);
    }
  };

  flyToOutlet = () => {
    const { outlet_list_by_salesman_filter_0 } = this.props.data;
    const { map } = this.props.map;
    const sidebarW = parseInt(localStorage.getItem("sidebar-width") || 500);
    const outlet_list_ft = outlet_list_by_salesman_filter_0.map((o) => {
      const coords = o["Detected Location"].split(",");
      return {
        type: "Feature",
        properties: { ...o },
        geometry: {
          type: "Point",
          coordinates: [coords[1], coords[0]],
        },
      };
    });
    let geojson = {
      type: "FeatureCollection",
      features: [...outlet_list_ft],
    };
    try {
      let [minLng, minLat, maxLng, maxLat] = bbox(geojson);
      map.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: { top: 200, bottom: 200, left: sidebarW + 50, right: 50 },
        }
      );
    } catch (e) {
    } finally {
      this.props.setOutletView(true);
    }
  };

  flyToAll = () => {
    const { lineString } = this.props.tracking;
    const { outlet_list_by_salesman } = this.props.data;
    const { map } = this.props.map;
    const sidebarW = parseInt(localStorage.getItem("sidebar-width") || 500);
    let outlet_list_ft = [],
      log_list_ft = [];
    if (lineString.length > 0) {
      const trackFt = lineString.map((l) => [l.longitude, l.latitude]);
      let lineFt = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: trackFt,
        },
      };
      log_list_ft.push(lineFt);
    }
    if (outlet_list_by_salesman.length > 0) {
      outlet_list_ft = outlet_list_by_salesman.map((o) => {
        const coords = o["Detected Location"].split(",");
        return {
          type: "Feature",
          properties: { ...o },
          geometry: {
            type: "Point",
            coordinates: [coords[1], coords[0]],
          },
        };
      });
    }
    let geojson = {
      type: "FeatureCollection",
      features: [...outlet_list_ft, ...log_list_ft],
    };
    try {
      let [minLng, minLat, maxLng, maxLat] = bbox(geojson);
      map.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: { top: 200, bottom: 200, left: sidebarW + 50, right: 50 },
        }
      );
    } catch (e) {
    } finally {
      this.props.setTrackingView(true);
      this.props.setOutletView(true);
    }
  };

  on_checked = (p) => {
    const id = p.target.id;
    const checked = p.target.checked;
    if (checked) {
      this.props.edit_status({
        salesman_id: id,
        status: "nonactive",
      });
    } else {
      this.props.edit_status({
        salesman_id: id,
        status: "off",
      });
    }
  };

  on_handle_date = (event) => {
    let unixDate = 0;
    if (event.target.name === "start_date") {
      unixDate = new Date(`${event.target.value} 00:00:00`).getTime();
    }
    if (event.target.name === "end_date") {
      unixDate = new Date(`${event.target.value} 23:59:00`).getTime();
    }
    this.setState({
      [event.target.name]: unixDate,
    });
  };

  on_handle_submit = (event) => {
    event.preventDefault();
    this.props.getLog({
      id: this.state.selectedSalesmanDetail._id,
      filter: true,
      start: this.state.start_date,
      end: this.state.end_date,
    });
  };

  flyToAllDriver = () => {
    const { salesman_list_filter_0 } = this.props.data;
    const sidebarW = parseInt(localStorage.getItem("sidebar-width") || 500);
    const { map } = this.props.map;
    const new_features = salesman_list_filter_0.map((e) => {
      return {
        type: "Feature",
        properties: { ...e },
        geometry: e.location,
      };
    });
    const geojson = {
      type: "FeatureCollection",
      features: [...new_features],
    };
    let [minLng, minLat, maxLng, maxLat] = bbox(geojson);
    map.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      {
        padding: { top: 200, bottom: 200, left: sidebarW + 50, right: 50 },
      }
    );
  };

  handleSearchOutletBySalesman = (event) => {
    this.props.searchOutletBySalesman(event.target.value);
  };

  render() {
    //local storage

    //local state
    const { modal_tracking_bulanan } = this.state;

    //global props
    const {
      listSelectFilter,
      selectedFilter1,
      selectedSalesmanDetail,
      salesmanDetailNav,
      realtimeStatus,
      modal_edit_hari,
      hari,
      outlet,
      state_modal_edit_salesman,
    } = this.state;
    const {
      route_mode,
      loading_item,
      loading_status,
      sidebarBreakpoints,
      loading_top,
      list_menu_active,
    } = this.props.data;
    const {
      filter_divisi_1,
      filter_divisi_2,
      filter_divisi_3,
      filter_divisi_1_choose,
      filter_divisi_2_choose,
      filter_divisi_3_choose,
      lineString,
      indexLineString,
    } = this.props.tracking;
    const { user } = this.props.auth;

    //content
    const modal_tracking_bulanan_content = modal_tracking_bulanan && (
      <Modal
        modalSize="large"
        isOpen={modal_tracking_bulanan}
        onClose={this.toggle_tracking_bulanan}
      >
        <div className="box-body">
          <TrackingBulanan />
        </div>
      </Modal>
    );

    const trackingView = list_menu_active.find(
      (e) => e.key === "salesman_log"
    ).active;
    const filter = (
      <React.Fragment>
        <p>Working Area Filter</p>
        <div
          className={`grid ${
            sidebarBreakpoints !== "sm" ? "grid-cols-2" : "grid-cols-1"
          } gap-1 mb-1`}
        >
          <div className="">
            <Select
              options={listSelectFilter}
              onChange={this.handleSelectFilter1}
              defaultValue={this.props.data.selectedFilter1Obj}
            />
          </div>
          <div
            className={`${
              this.props.data.withOutFilter2 === true ? "hidden" : "block"
            }`}
          >
            <Select
              isMulti
              options={this.props.data.listSelectFilter2}
              onChange={this.handleSelectFilter2}
              defaultValue={this.props.data.selectedFilter2Obj}
            />

            <div className="flex">
              <button
                onClick={this.handleClick}
                className="button_main mt-2"
                disabled={loading_top}
              >
                {loading_top ? "Loading" : "Find Salesman"}
              </button>
              <button
                onClick={this.handleClick}
                title="Refresh Data"
                className="ml-auto"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-green-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    const buttonRealtime = this.props.data.selectedFilter1Obj.value !==
      "Pilih" && (
      <React.Fragment>
        <hr />
        <div className={`grid grid-cols-2 gap-2 mt-2`}>
          <div className="gap-2 flex items-center">
            <label>Interval</label>
            <input
              type="number"
              defaultValue={this.state.interval_realtime}
              onChange={(e) => {
                let value = e.target.value;
                if (value === "") value = 1;
                this.setState({ interval_realtime: parseInt(value) });
              }}
            />
            <label>Minutes</label>
          </div>
          <div className="flex items-center border-l-2 pl-2">
            <label className="switch">
              <input
                type="checkbox"
                onChange={this.realtimeToggle}
                checked={realtimeStatus === "Active" ? true : false}
              />
              <span className="slider round"></span>
            </label>
            <p className="ml-2">Auto {realtimeStatus}</p>
          </div>
        </div>
        <form onSubmit={this.findSearch}>
          <div className="flex gap-1">
            <div className="w-full my-2">
              <input
                type="text"
                placeholder={`Search Salesman Name or Employee ID Number`}
                className="w-full"
                name="findSearch"
                onChange={this.handleSearch.bind(this)}
                autoComplete="off"
              />
            </div>
            <button className="px-1" type="sumbit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </button>
          </div>
        </form>
      </React.Fragment>
    );

    const filterDivisi = (
      <React.Fragment>
        <p>Division Filter</p>
        <div
          className={` ${
            this.props.data.selectedFilter1Obj.label === "Pilih" ? "hidden" : ""
          }`}
        >
          <Select
            isMulti
            options={filter_divisi_1}
            onChange={this.handle_filter_divisi_1}
            defaultValue={filter_divisi_1_choose}
          />
        </div>
        <div className={` ${filter_divisi_2.length === 0 ? "hidden" : ""}`}>
          <Select
            isMulti
            options={filter_divisi_2}
            onChange={this.handle_filter_divisi_2}
            defaultValue={filter_divisi_2_choose}
          />
        </div>
        <div className={` ${filter_divisi_3.length === 0 ? "hidden" : ""}`}>
          <Select
            isMulti
            options={filter_divisi_3}
            onChange={this.handle_filter_divisi_3}
            defaultValue={filter_divisi_3_choose}
          />
        </div>
      </React.Fragment>
    );

    const listSalesman = (
      <React.Fragment>
        {this.props.data.salesman_list.length > 0 && (
          <div>
            <hr />
            <p className="my-2 text-gray-600">
              Showing total {this?.props?.data?.salesman_list?.length} salesman
            </p>

            <div className="text-xs">
              {this.onlineMode.map((m, idx) => (
                <div className="flex my-0.5" key={idx}>
                  <div className={`${m.bgColor} w-5 h-5 mr-0.5`}></div>
                  <p>
                    <span className="align-middle">{m.stat}</span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
        {this.props.data.salesman_list.length > 0 ? (
          this.props.data.salesman_list
            .sort((a, b) => {
              if (a["status"] < b["status"]) return 1;
              if (a["status"] > b["status"]) return -1;
              return 0;
            })
            .slice(0, this.state.list_column)
            .map((e, idx) => {
              const onlineStat = e.status_bike;
              let mssg, bgColor;
              switch (onlineStat) {
                case "bike_green":
                  mssg = "Online";
                  bgColor = "bg-green-600";
                  break;
                case "bike_blue":
                  mssg = "Online a few hours ago";
                  bgColor = "bg-blue-600";
                  break;
                default:
                  mssg = "Offline";
                  bgColor = "bg-red-600";
              }
              return (
                <main className="cursor-pointer hover:bg-gray-100" key={idx}>
                  <section
                    className="pt-4 px-1"
                    onClick={this.detailSalesman.bind(this, e)}
                  >
                    <section>
                      <p className="flex-1">
                        {idx + 1}
                        {". "}
                        {e["Nama Only"]}
                      </p>
                      {e["testing"] && e["testing"] === true && (
                        <div className="border border-yellow-600 text-yellow-600 rounded-full w-fit px-2 text-xs flex items-center mr-1">
                          Testing
                        </div>
                      )}
                      <button
                        className={`${bgColor} text-white rounded-full w-fit px-2 text-xs flex items-center`}
                      >
                        {mssg}
                      </button>
                    </section>
                    {/* badge (belum daftar baru, sudah mendaftar, belum ada lokasi) */}
                    <div className="flex justify-end">
                      <div className="flex justify-end my-1">
                        {e["status_register"] === "Belum Register" ? (
                          <div className="bg-gray-400 text-white rounded-full w-fit px-2 py-1 text-xs flex items-center">
                            Not registered
                          </div>
                        ) : (
                          <div className="bg-green-600 text-white rounded-full w-fit px-2 py-1 text-xs flex items-center">
                            Registered
                          </div>
                        )}
                      </div>
                      {isEmpty(e["location"]["coordinates"]) && (
                        <div className="flex justify-end my-1  ml-1">
                          <div className="bg-red-600 text-white rounded-full w-fit px-2 py-1 text-xs flex items-center">
                            No location
                          </div>
                        </div>
                      )}
                    </div>
                    {/* detail salesman */}
                    <div className="flex">
                      <div className="flex-1 text-sm text-gray-600">
                        Sales code: {e["kd_sales_arr"].join()}
                      </div>
                      <div className="flex-1 text-sm text-gray-600">
                        Division: {e["divisi_arr"].join()}
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex-1 text-sm text-gray-600">
                        Employee ID: {e["NIK"]}
                      </div>
                      <div className="flex-1 text-sm text-gray-600">
                        Depo: {e["Depo"]}
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex-1 text-sm text-gray-600">
                        Branch:{" "}
                        {(() => {
                          const result =
                            this.props.data.salesman_filter?.depo.find(
                              (obj) => obj.Depo === e["Depo"]
                            );
                          if (result) {
                            return result.CABANG;
                          } else {
                            return "-";
                          }
                        })()}
                      </div>
                      <div className="flex-1 text-sm text-gray-600">
                        Region:{" "}
                        {(() => {
                          const result =
                            this.props.data.salesman_filter.depo.find(
                              (obj) => obj.Depo === e["Depo"]
                            );
                          if (result) {
                            return result.REGION;
                          } else {
                            return "-";
                          }
                        })()}
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex-1 text-gray-600 text-sm">
                        {selectedFilter1 === "Salesman Register" ? (
                          <p>
                            Register At: {formatDateFormal(e["created_on"])}
                          </p>
                        ) : (
                          <p>
                            Last Update:{" "}
                            {formatDateFormal(e["last_time_stamp"])}
                          </p>
                        )}
                      </div>
                    </div>
                  </section>
                  {e["status_register"] !== "Belum Register" ? (
                    <section className="mt-1 ml-1 mb-1">
                      {user.badge === "super_admin" && (
                        <button
                          className="button_main mr-2"
                          onClick={this.handle_edit_salesman.bind(this, e)}
                        >
                          Edit Salesman
                        </button>
                      )}

                      <button
                        onClick={() =>
                          this.props.popUpAction(e, "modal_salesman")
                        }
                        className="button"
                        id="green"
                      >
                        View Salesman
                      </button>
                    </section>
                  ) : (
                    <section className="flex gap-2 mt-1 p-1">
                      <p className="text-gray-600">Inactivate salesman</p>
                      <label className="switch">
                        <input
                          type="checkbox"
                          id={e["_id"]}
                          onChange={this.on_checked}
                        />
                        <span className="slider round red"></span>
                      </label>
                    </section>
                  )}
                  <hr />
                </main>
              );
            })
        ) : (
          <div className="border p-4 my-4 bg-yellow-100 rounded">
            For showing tracking sales data list, please select filters above
          </div>
        )}
      </React.Fragment>
    );

    const detail_salesman = (
      <div className="mt-2">
        <hr />
        <div className="flex mt-4">
          <h1 className="flex-1 font-medium" onClick={this.flyToAll}>
            {selectedSalesmanDetail["Nama Only"]}
          </h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-700 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            onClick={this.on_close_toko}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div className="flex">
          <div className="flex-1 text-sm text-gray-600">
            Salesman Code:{" "}
            {selectedSalesmanDetail.kd_sales_arr
              ? selectedSalesmanDetail.kd_sales_arr.join()
              : "No sales code"}
          </div>
          <div className="flex-1 text-sm text-gray-600">
            Division:{" "}
            {selectedSalesmanDetail.divisi_arr
              ? selectedSalesmanDetail.divisi_arr.join()
              : "No sales code"}
          </div>
        </div>
        <div className="flex">
          <div className="flex-1 text-sm text-gray-600">
            Employee ID: {selectedSalesmanDetail["NIK"]}
          </div>
          <div className="flex-1 text-sm text-gray-600">
            Depo: {selectedSalesmanDetail["Depo"]}
          </div>
        </div>
        <div className="flex">
          <div className="flex-1 text-sm text-gray-600">
            Branch: {selectedSalesmanDetail["CABANG"]}
          </div>
          <div className="flex-1 text-sm text-gray-600">
            Region: {selectedSalesmanDetail["REGIONAL"]}
          </div>
        </div>
        <div className="flex">
          <div className="flex-1 text-gray-600 text-sm">
            {selectedFilter1 === "Salesman Register" ? (
              <p>
                Register At:{" "}
                {formatDateFormal(selectedSalesmanDetail["created_on"])}
              </p>
            ) : (
              <p>
                Last Update:{" "}
                {formatDateFormal(selectedSalesmanDetail["last_time_stamp"])}
              </p>
            )}
          </div>
        </div>
      </div>
    );

    const trackingHistory = (
      <div className="mt-2">
        <div className="relative border p-2 rounded mt-2">
          <p
            className="text-left text-lg cursor-pointer"
            onClick={this.flyToLog}
          >
            Salesman history journey
          </p>
          <div className="absolute top-[8px] right-[8px] cursor-pointer">
            <img
              src={trackingView ? icon_view_active : icon_view}
              alt="View icon"
              className="h-[28px]"
              onClick={this.on_toggle_view_log.bind(this, "salesman_log")}
            />
          </div>
          <hr className="my-2" />
          <div>
            <form onSubmit={this.on_handle_submit}>
              <div className="grid grid-cols-2 gap-2">
                <div className="flex flex-col">
                  <label htmlFor="start-date">Select Start Date</label>
                  <input
                    type="date"
                    className="w-full"
                    id="start_date"
                    name="start_date"
                    onChange={this.on_handle_date}
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="end-date">Select End Date</label>
                  <input
                    type="date"
                    className="w-full"
                    id="end_date"
                    name="end_date"
                    onChange={this.on_handle_date}
                    required
                  />
                </div>
              </div>
              <div className="mt-2">
                <button className="button" type="submit" id="green">
                  {this.state.loading_process ? "Loading..." : "Filter"}
                </button>
              </div>
            </form>
          </div>
          {lineString.length > 0 ? (
            <div className="relative">
              <input
                className="w-full"
                type="range"
                step="1"
                min="0"
                max={`${lineString.length - 1}`}
                value={indexLineString}
                onChange={this.handleLogSlider.bind(this)}
              />
              <div className="flex">
                <div className="flex-1">
                  <p>
                    Time:{" "}
                    {formatDateLengkap(
                      lineString[indexLineString].time_stamp[
                        lineString[indexLineString].time_stamp.length - 1
                      ]
                    )}
                  </p>
                </div>
                <div className="">
                  <div className="flex items-end">
                    <p className="mr-2">Showing route</p>
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={this.routeToggle}
                        checked={route_mode}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-4">
              <hr />
              <div className="text-center py-4">"No history data recorded"</div>
            </div>
          )}
          <button
            className="button mt-4"
            id="green"
            onClick={this.toggle_tracking_bulanan}
          >
            View statistics
          </button>
        </div>
      </div>
    );

    const r_array = ["R01", "R02", "R03", "R04", "R05", "R06"];
    let button_simpan_hari = (
      <button className="button_main" onClick={this.submit_edit_hari}>
        Save
      </button>
    );
    if (loading_status && loading_item === "edit_hari_kunjungan") {
      button_simpan_hari = <div className="button_main">Saving</div>;
    }

    const modal_edit_hari_content = modal_edit_hari && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_edit_hari}
        onClose={this.toggle_edit_hari}
      >
        <div className="box-body">
          <section style={{ marginBottom: "10px" }}>
            Edit visit day {outlet?.["Nama Toko"]}
          </section>
          <select id="hari" name="hari" value={hari} onChange={this.on_change}>
            {r_array.map((r, idx) => {
              return (
                <option key={idx} value={r}>
                  {r}
                </option>
              );
            })}
          </select>
          <section>{button_simpan_hari}</section>
        </div>
      </Modal>
    );

    const modal_edit_salesman = state_modal_edit_salesman && (
      <Modal
        modalSize="small"
        isOpen={state_modal_edit_salesman}
        onClose={() => this.setState({ state_modal_edit_salesman: false })}
      >
        <div className="box-body">
          <EditSalesman />
        </div>
      </Modal>
    );

    const { tracking_menu_selected } = this.props.tracking;

    const tab_nav = (
      <div className="mb-2 grid grid-cols-2">
        <button
          className={`w-full py-2 border-b-4 hover:bg-gray-50 ${
            tracking_menu_selected === "salesman"
              ? "border-green-500"
              : "border-gray-200"
          }`}
          onClick={this.on_change_tracking_menu_selected.bind(this, "salesman")}
        >
          <div className="flex items-center justify-center gap-2">
            <img className="w-4 h-auto" src={bike} alt="salesman icon" />
            Salesman
          </div>
        </button>
        <button
          className={`w-full py-2 border-b-4 hover:bg-gray-50 ${
            tracking_menu_selected === "driver"
              ? "border-green-500"
              : "border-gray-200"
          }`}
          onClick={this.on_change_tracking_menu_selected.bind(this, "driver")}
        >
          <div className="flex items-center justify-center gap-2">
            <img className="w-4 h-auto" src={truck} alt="driver icon" />
            Driver
          </div>
        </button>
      </div>
    );

    let button_slice;
    if (this.props.data.salesman_list.length > 0) {
      button_slice = (
        <div className="flex items-center justify-center">
          <button
            className="button_main mt-5"
            onClick={() => {
              let number = this.state.list_column + 50;
              this.setState({ list_column: number });
            }}
          >
            Load More Datas
          </button>
        </div>
      );
    }

    if (
      this.props.data.salesman_list.length &&
      this.state.list_column > this.props.data.salesman_list.length
    ) {
      button_slice = (
        <div className="flex items-center justify-center mt-5">
          All Data Has Been Loaded
        </div>
      );
    }

    if (salesmanDetailNav) {
      return (
        <React.Fragment>
          {detail_salesman}
          {trackingHistory}
          <LIST_OUTLET_BY_SALESMAN />
          {modal_edit_hari_content}
          {modal_edit_salesman}
          {modal_tracking_bulanan_content}
        </React.Fragment>
      );
    } else {
      const is_visible_salesman = list_menu_active.find(
        (e) => e.key === "salesman"
      ).active;
      let list_content = (
        <main>
          <div className="columns-2 w-20">
            <img
              src={is_visible_salesman ? icon_view_active : icon_view}
              alt="View icon"
              className="h-[28px]"
              onClick={this.on_toggle_view_log.bind(this, "salesman")}
            />
            {this.props.data.salesman_list_filter_0.length > 1 && (
              <button
                onClick={this.flyToAllDriver}
                className="button_main w-56"
                style={{ marginBottom: "20px" }}
              >
                Zoom salesman
              </button>
            )}
          </div>
          {filter}
          {filterDivisi}
          {buttonRealtime}
          {listSalesman}
          {modal_edit_salesman}
          {modal_tracking_bulanan_content}
          {button_slice}
        </main>
      );
      if (tracking_menu_selected === "driver") {
        list_content = <Driver />;
      }
      const main_content = (
        <main>
          {!this.state.sub_limitasi_fitur && tab_nav}
          {list_content}
        </main>
      );

      return <React.Fragment>{main_content}</React.Fragment>;
    }
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  tracking: state.tracking,
  map: state.map,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getSalesman,
  clearSalesman,
  getOutlets,
  getLog,
  clearOutletSalesman,
  clearLog,
  sliderLog,
  set_selected_salesman,
  searchSalesman,
  setSalesmanMode,
  setToggleRoute,
  getSalesmanRegister,
  getOutletFilter,
  setTrackingView,
  setOutletView,
  setSalesmanFilterVal,
  setSalesmanFilterMode,
  getTotalSalesman,
  set_selected_menu,
  edit_hari_kunjungan,
  turn_on_menu,
  turn_on_menu_status,
  turn_off_menu,
  selectedFilter1Obj,
  selectedFilter2Obj,
  listSelectFilter2,
  selectedFilter2MultiLength,
  withOutFilter2,
  clear_salesman_list,
  search_salesman_by_divisi,
  get_salesman_by_id,
  popUpAction,
  edit_status,
  filter_divisi,
  set_time_array,
  searchOutletBySalesman,
  get_salesman_online,
  tracking_menu_selected,
  get_salesman_active,
  getSalesmanAll,
  set_value_properties,
})(PLANNING_TRACKING_MENU);
