//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";
//Personal Component
//Redux function
//Picture Asset
//General Function
import generate_color_map from "../../validation/generate_color_map";
class DayLayer extends Component {
  render() {
    let text_final, array_name, array_color, color_map;
    const is_visible = this.props.data.list_menu_active.find(
      (e) => e.key === "day"
    )?.active;
    //generate day list dengan parameter parent
    const { rayon_list_database, rayon_select, cycle_select, salesman_select } =
      this.props.data;
    const rayon_object = rayon_list_database.find(
      (r) => r._id === rayon_select
    );
    const plan_list = rayon_object?.plan_list ? rayon_object?.plan_list : [];
    let plan = plan_list.filter(
      (p) =>
        // p.cycle === cycle_select &&
        p.salesman_id === salesman_select._id
    );
    plan = plan.map((p) => {
      let day_list = p.day_list.map((d) => {
        return { ...d, cycle: p.cycle, name_cycle: `${d.name} (${p.cycle})` };
      });
      return day_list;
    });
    let day_list = plan.flat();

    day_list = day_list.filter((d) => d.cycle === cycle_select);

    const list = day_list;
    let geojson = {
      type: "FeatureCollection",
      features: [],
    };
    if (list?.length > 0) {
      array_name = list.map((e) => e.name_cycle);
      if (array_name?.length > 0) {
        array_color = list.map((e) => e.color);
        array_color.push("#000000");
        text_final = "name_cycle";
        color_map = generate_color_map(array_name, array_color, text_final);
      }
      const new_features = list.map((e) => {
        return {
          type: "Feature",
          properties: { ...e },
          geometry: e.location,
        };
      });
      geojson = {
        type: "FeatureCollection",
        features: [...new_features],
      };
    }
    return (
      list?.length > 0 && (
        <main>
          <Source
            key={"hari-src"}
            id="hari-src"
            type="geojson"
            data={geojson}
          />
          <Layer
            id="hari"
            key="hari"
            before="cycle"
            type="symbol"
            source={"hari-src"}
            layout={{
              visibility: is_visible ? "visible" : "none",
              "icon-image": "none",
              "text-field": ["get", "name_cycle"],
              "text-font": ["Open Sans Bold"],
              "text-anchor": "top",
              "text-offset": [0, 0],
              "text-line-height": 1,
              "text-allow-overlap": false,
              "text-ignore-placement": false,
              "text-size": 12,
              "text-max-width": 5,
            }}
            paint={{
              "text-color": color_map,
              "text-halo-color": "white",
              "text-halo-width": 4,
            }}
            onLeave={this.onLeave}
            onClick={this.props.layerOnClick.bind(this, "modal_plotting")}
          />
        </main>
      )
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, {})(DayLayer);
