import axios from "axios";
import convex from "@turf/convex";
// import polygon_smooth from "@turf/polygon-smooth";
import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;

/*NON API*/

export const hide_outlet_without_rayon = () => (dispatch) => {
  dispatch({
    type: "hide_outlet_without_rayon",
  });
  dispatch({
    type: "status_action",
  });
};

export const show_all_outlet = () => (dispatch) => {
  dispatch({
    type: "show_all_outlet",
  });
  dispatch({
    type: "status_action",
  });
};

export const set_loading = (loading_item) => {
  return {
    type: "SET_LOADING",
    payload: loading_item,
  };
};

export const clear_loading = () => {
  return {
    type: "CLEAR_LOADING",
  };
};

/*POST METHODE*/
export const edit_many_toko_rayon_bulk =
  (rayon_body_api) => async (dispatch) => {
    //edit_many_toko_rayon_bulk
    /*
    rayon_body_api=[
      {
        rayon_id,
        outlet_id_array
      }
    ]
  */
    try {
      dispatch({
        type: "set_loading_properties",
        payload: "edit_many_toko_rayon_bulk",
      });

      dispatch({
        type: "set_value_properties",
        payload: {
          key: "total_edit_many_toko_rayon_bulk",
          value: rayon_body_api.length,
        },
      });

      dispatch({
        type: "set_value_properties",
        payload: {
          key: "progress_edit_many_toko_rayon_bulk",
          value: 0,
        },
      });

      const delay_c = 0;
      const delay = () => new Promise((res) => setTimeout(res, delay_c));
      const parent_function = () => {
        return rayon_body_api.reduce(
          (last_promise, object, index) =>
            last_promise.then((result_sum) =>
              child_function(object, index).then((result_current) => [
                ...result_sum,
                result_current,
              ])
            ),
          Promise.resolve([])
        );
      };
      const child_function = async (body, index) => {
        return delay().then(async () => {
          try {
            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };
            await axios.post(
              `${SERVER_URL}/nabati_per/edit_many_toko_rayon`,
              body,
              config
            );
            dispatch({
              type: "edit_many_toko_rayon",
              payload: body,
            });
            dispatch({
              type: "status_action",
            });
            dispatch({
              type: "set_value_properties",
              payload: {
                key: "progress_edit_many_toko_rayon_bulk",
                value: index + 1,
              },
            });
          } catch (error) {}
        });
      };
      parent_function().then(() => {
        dispatch({
          type: "clear_loading_properties",
        });
      });
    } catch (error) {
      dispatch({
        type: "clear_loading_properties",
      });
    }
  };

export const edit_rayon_location = (body) => async (dispatch) => {
  //edit_rayon_location
  /*
  body = {
    rayon_list_database,
    outlet_list_filter_0_back_up
  }
  */
  try {
    dispatch(set_loading("edit_rayon_location"));

    const { rayon_list_database, outlet_list_filter_0_back_up } = body;
    const array = rayon_list_database;
    const delay = () => new Promise((res) => setTimeout(res, 0));
    const parent_function = () => {
      return array.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const { _id } = object;
            const outlet_list = outlet_list_filter_0_back_up.filter(
              (item) => item.rayon_id === _id
            );
            const features = outlet_list.map((item) => {
              return {
                type: "Feature",
                properties: {},
                geometry: {
                  coordinates: item.location.coordinates,
                  type: "Point",
                },
              };
            });
            const geojson_features = {
              type: "FeatureCollection",
              features,
            };
            let feature_polygon = convex(geojson_features, {
              concavity: 2.5,
            });
            // const geojson_polygon_smooth = polygon_smooth(feature_polygon, {
            //   iterations: 2,
            // });
            // feature_polygon = geojson_polygon_smooth.features[0];

            let long = outlet_list.map((t) => t.location.coordinates[0]);
            let lat = outlet_list.map((t) => t.location.coordinates[1]);
            long = long.reduce((p, a) => p + a, 0) / long.length;
            lat = lat.reduce((p, a) => p + a, 0) / lat.length;
            const body = {
              rayon_id: _id,
              location: {
                type: "Point",
                coordinates: [long, lat],
              },
              feature_polygon,
            };
            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };
            await axios.post(
              `${SERVER_URL}/nabati_per/edit_rayon_location`,
              body,
              config
            );
            dispatch({
              type: "edit_rayon_location",
              payload: body,
            });
            dispatch({
              type: "status_action",
            });
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(() => {
      dispatch({
        type: "status_action",
      });
      dispatch(clear_loading());
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const set_kunjungan_arr_new = (body) => async (dispatch) => {
  //set_kunjungan_arr_new
  /*
  body = {
    day_list,
    outlet_list_filter_0,
    cycle_select,
    salesman_id
  }
  */
  try {
    const { day_list, outlet_list_filter_0, cycle_select, salesman_id } = body;

    const array = day_list;
    dispatch(set_loading("set_kunjungan_arr_new"));
    const delay = () => new Promise((res) => setTimeout(res, 0));
    const parent_function = () => {
      return array.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const { name } = object;
            let outlet_id_array = outlet_list_filter_0
              .filter((t) => t.day === name)
              .map((t) => t._id);
            const date_submit = Date.now();
            const HARIKunjungan = name.replace("day ", "R0");
            let FreqVisit = cycle_select;
            if (cycle_select === "cycle 1") FreqVisit = "M2C13";
            if (cycle_select === "cycle 2") FreqVisit = "M2C24";
            const kunjungan_object = {
              date_submit,
              HARIKunjungan,
              FreqVisit,
              day: name,
              cycle: cycle_select,
              salesman_id,
            };
            const body = {
              outlet_id_array,
              kunjungan_object,
            };
            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };
            await axios.post(
              `${SERVER_URL}/nabati_per/set_kunjungan_arr_new`,
              body,
              config
            );
            dispatch({
              type: "set_kunjungan_arr_new",
              payload: body,
            });
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(() => {
      dispatch({
        type: "turn_on_menu_status",
        payload: { key: "day" },
      });
      dispatch({
        type: "turn_off_menu",
        payload: { key: "cycle" },
      });
      dispatch({
        type: "set_config_mode_menu",
        payload: { menu: "distribution", mode: "day" },
      });
      dispatch({
        type: "status_action",
      });
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const push_kunjungan_arr_new = (body) => async (dispatch) => {
  //push_kunjungan_arr_new
  /*body: {
    day_list, outlet_list_filter_0, cycle_select, salesman_select
  }*/
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };

    const { day_list, outlet_list_filter_0, cycle_select, salesman_select } =
      body;

    const array = day_list;
    dispatch(set_loading("update_many_toko_rayon"));
    const delay = () => new Promise((res) => setTimeout(res, 0));
    const parent_function = () => {
      return array.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const { name } = object;
            let outlet_id_array = outlet_list_filter_0
              .filter((t) => t.day === name)
              .map((t) => t._id);
            const date_submit = Date.now();
            const HARIKunjungan = name.replace("day ", "R0");
            let FreqVisit = cycle_select;
            const salesman_id = salesman_select._id;
            if (cycle_select === "cycle 1") FreqVisit = "M2C13";
            if (cycle_select === "cycle 2") FreqVisit = "M2C24";
            const kunjungan_object = {
              date_submit,
              HARIKunjungan,
              FreqVisit,
              day: name,
              cycle: cycle_select,
              salesman_id,
            };
            const body = {
              outlet_id_array,
              kunjungan_object,
            };

            await axios.post(
              `${SERVER_URL}/nabati_per/push_kunjungan_arr_new`,
              body,
              config
            );
            dispatch({
              type: "push_kunjungan_arr_new",
              payload: body,
            });
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(() => {
      dispatch({
        type: "turn_on_menu_status",
        payload: { key: "day" },
      });
      dispatch({
        type: "turn_off_menu",
        payload: { key: "cycle" },
      });
      dispatch({
        type: "set_config_mode_menu",
        payload: { menu: "distribution", mode: "day" },
      });
      dispatch({
        type: "status_action",
      });
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const set_plan_list = (body) => async (dispatch) => {
  //set_plan_list
  /*body = {
    rayon_id, cycle, salesman_id, day_list
  }*/
  try {
    dispatch(set_loading("set_plan_list"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(`${SERVER_URL}/nabati_per/set_plan_list`, body, config);
    dispatch({
      type: "set_plan_list",
      payload: body,
    });

    dispatch(clear_loading());
    dispatch({
      type: "status_action",
    });

    dispatch({
      type: "set_list",
      payload: { text: "plan_cycle_loading", list: body.cycle },
    });
    dispatch({
      type: "set_list",
      payload: { text: "plan_salesman_loading", list: body.salesman_id },
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const push_plan_list = (body) => async (dispatch) => {
  //push_plan_list
  /*body: {
  
  }*/
  try {
    dispatch(set_loading("push_plan_list"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(`${SERVER_URL}/nabati_per/push_plan_list`, body, config);
    dispatch({
      type: "push_plan_list",
      payload: body,
    });

    dispatch(clear_loading());
    dispatch({
      type: "status_action",
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const edit_cycle_outlet = (body) => async (dispatch) => {
  //edit_cycle_outlet
  /*
  body = {
    cycle_list,
    toko_list,
  }
  */
  try {
    const { cycle_list, toko_list } = body;
    const array = cycle_list;
    dispatch(set_loading("edit_cycle_outlet"));
    const delay = () => new Promise((res) => setTimeout(res, 0));
    const parent_function = () => {
      return array.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const cycle = object.name;

            let outlet_id_array = object?.outlet_id_array;
            if (!outlet_id_array) {
              outlet_id_array = toko_list
                .filter((t) => t.cycle === cycle)
                .map((t) => t._id);
            }

            if (outlet_id_array.length > 0) {
              const body = {
                cycle,
                outlet_id_array,
              };
              const config = {
                headers: {
                  accesstoken: localStorage.jwtTokenNabati,
                },
              };
              await axios.post(
                `${SERVER_URL}/nabati_per/edit_cycle_outlet`,
                body,
                config
              );

              dispatch({
                type: "edit_cycle_outlet",
                payload: body,
              });
            }
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(() => {
      dispatch({
        type: "status_action",
      });

      dispatch(clear_loading());
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const edit_many_toko_rayon = (body) => async (dispatch) => {
  //edit_many_toko_rayon
  /*
  body={
    rayon_id,
    outlet_id_array
  }
  */
  try {
    dispatch(set_loading("edit_many_toko_rayon"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(
      `${SERVER_URL}/nabati_per/edit_many_toko_rayon`,
      body,
      config
    );
    dispatch({
      type: "edit_many_toko_rayon",
      payload: body,
    });
    dispatch({
      type: "status_action",
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const create_rayon_edit_outlet = (body) => async (dispatch) => {
  //create_rayon_edit_outlet
  try {
    const { rayon_list_complete, mode_timpa } = body;
    const array = rayon_list_complete;
    let rayon_list = [];
    dispatch(set_loading("create_rayon_edit_outlet"));
    const delay = () => new Promise((res) => setTimeout(res, 0));
    const parent_function = () => {
      return array.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };
            const res = await axios.post(
              `${SERVER_URL}/nabati_per/create_rayon_edit_outlet`,
              object,
              config
            );
            dispatch({
              type: "create_rayon_edit_outlet",
              payload: res.data,
            });
            rayon_list.push(res.data.rayon_db);
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(() => {
      if (mode_timpa === "overwrite") {
        dispatch({
          type: "set_list",
          payload: { text: "rayon_list_database", list: rayon_list },
        });
      } else {
        dispatch({
          type: "push_rayon_list",
          payload: rayon_list,
        });
      }

      if (rayon_list.length === rayon_list_complete.length) {
        dispatch(clear_loading());
      }
      dispatch({
        type: "status_action",
      });
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const ow_salesman_rayon = (body) => async (dispatch) => {
  //ow_salesman_rayon
  /*
body = {
 
}
*/
  try {
    dispatch(set_loading("ow_salesman_outlet"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };

    await axios.post(
      `${SERVER_URL}/nabati_per/ow_salesman_rayon`,
      body,
      config
    );
    dispatch({
      type: "ow_salesman_rayon",
      payload: body,
    });
    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const ow_salesman_outlet = (body) => async (dispatch) => {
  //ow_salesman_outlet
  /*
body = {
  outlet_update_array,
  salesman_id
}
*/
  try {
    dispatch(set_loading("ow_salesman_outlet"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };

    await axios.post(
      `${SERVER_URL}/nabati_per/ow_salesman_outlet`,
      body,
      config
    );
    dispatch({
      type: "ow_salesman_outlet",
      payload: body,
    });
    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

/*GET METHODE*/
