/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import ProgressBar from "../common/ProgressBar";

/*REDUX FUNCTION*/
import {
  edit_many_toko_rayon_bulk,
  edit_rayon_location,
} from "../../actions/rayonActions";
import { set_value_properties } from "../../actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import calc_distance from "../../validation/calc_distance";
import sort_array from "../../validation/sort_array";

/*NON IMPORT*/

class STEP_1_2_EXPAND_RAYON extends Component {
  state = {};

  componentDidUpdate(prevProps) {
    if (
      prevProps.properties.loading_status !==
        this.props.properties.loading_status &&
      !this.props.properties.loading_status &&
      this.props.properties.loading_item === "edit_many_toko_rayon_bulk"
    ) {
      const { rayon_list_database, outlet_list_filter_0_back_up } =
        this.props.data;
      const body = {
        rayon_list_database,
        outlet_list_filter_0_back_up,
      };
      this.props.edit_rayon_location(body);
    }
  }

  on_expand_rayon = () => {
    this.props.set_value_properties({
      key: "rayon_body_api",
      value: [],
    });

    const { outlet_list_filter_0_back_up, rayon_list_database } =
      this.props.data;
    const outlet_without_rayon = outlet_list_filter_0_back_up.filter(
      (t) => !t.rayon_id
    );

    let rayon_body_api = rayon_list_database.map((item) => {
      item.outlet_id_array = [];
      return item;
    });

    const total = outlet_without_rayon.length;
    const limit = 20;
    const iteration = Math.ceil(total / limit);
    let array_loop = [];
    for (let i = 0; i < iteration; i++) {
      array_loop.push({
        start: i * limit,
        end: i * limit + limit,
      });
    }
    const delay_c = 0;
    const delay = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function = () => {
      return array_loop.reduce(
        (last_promise, object, index) =>
          last_promise.then((result_sum) =>
            child_function(object, index).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object, index) => {
      return delay().then(async () => {
        try {
          const { start, end } = object;
          const outlet_list = outlet_without_rayon.slice(start, end);
          outlet_list.forEach((outlet) => {
            //looping outlet, ukur jarak dari setiap titik rayon, ambil yang terdekat, simpan rayon_id ke dalam atribut outlet
            const long_1 = outlet.location.coordinates[0];
            const lat_1 = outlet.location.coordinates[1];
            let rayon_list_test = rayon_list_database.map((rayon) => {
              const long_2 = rayon.location.coordinates[0];
              const lat_2 = rayon.location.coordinates[1];
              const distance = calc_distance(lat_1, long_1, lat_2, long_2);
              rayon.distance = distance;
              return rayon;
            });
            rayon_list_test = sort_array(rayon_list_test, "distance", true);
            const rayon_nearest_id = rayon_list_test[0]._id;
            const rayon_nearest_index = rayon_body_api.findIndex(
              (item) => item._id === rayon_nearest_id
            );
            rayon_body_api[rayon_nearest_index].outlet_id_array.push(
              outlet._id
            );
          });
        } catch (error) {}
      });
    };
    parent_function().then(() => {
      rayon_body_api = rayon_body_api.map((item) => {
        return {
          ...item,
          rayon_id: item._id,
          outlet_id_array: item.outlet_id_array,
        };
      });

      rayon_body_api = rayon_body_api.filter(
        (item) => item.outlet_id_array.length > 0
      );

      this.props.edit_many_toko_rayon_bulk(rayon_body_api);
      this.props.set_value_properties({
        key: "rayon_body_api",
        value: rayon_body_api,
      });
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const { outlet_list_filter_0_back_up } = this.props.data;
    const {
      loading_status,
      loading_item,
      progress_edit_many_toko_rayon_bulk,
      total_edit_many_toko_rayon_bulk,
      rayon_body_api,
    } = this.props.properties;

    //content
    const outlet_without_rayon = outlet_list_filter_0_back_up.filter(
      (t) => !t.rayon_id
    );

    let button_content;
    let result_content;

    if (outlet_list_filter_0_back_up.length === 0) {
      button_content = (
        <>
          <div className="button" id="grey">
            Run rayon expansion
          </div>
          <p className="text_small">Please load outlet data first.</p>
        </>
      );
    } else if (outlet_without_rayon.length === 0) {
      button_content = (
        <>
          <div className="button" id="grey">
            Run rayon expansion
          </div>
          <p className="text_small">All outlets already have rayon.</p>
        </>
      );
    } else if (loading_status && loading_item === "edit_many_toko_rayon_bulk") {
      button_content = (
        <>
          <div className="button" id="grey">
            Saving...
          </div>
          <ProgressBar
            current_number={progress_edit_many_toko_rayon_bulk}
            total_number={total_edit_many_toko_rayon_bulk}
          />
        </>
      );
    } else {
      button_content = (
        <>
          <button className="button" id="green" onClick={this.on_expand_rayon}>
            Run rayon expansion
          </button>
          <p className="text_small">
            Run rayon expansion rayonization of outlets without rayon to the
            nearest rayon group
          </p>
        </>
      );
    }

    if (rayon_body_api.length > 0) {
      result_content = (
        <section className="mt-3">
          <p className="text_small">Result summary:</p>
          <table className="table full_width">
            <thead>
              <tr>
                <th>No</th>
                <th>Rayon</th>
                <th>New outlet</th>
              </tr>
            </thead>
            <tbody>
              {rayon_body_api.map((rayon, idx) => {
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{rayon.name}</td>
                    <td>{rayon.outlet_id_array.length}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      );
    }

    return (
      <main>
        {button_content}
        {result_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.data,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  edit_many_toko_rayon_bulk,
  set_value_properties,
  edit_rayon_location,
})(STEP_1_2_EXPAND_RAYON);
