/*LIBRARY MODULE*/
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import pointsWithinPolygon from "@turf/points-within-polygon";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal";
import TextFieldGroup from "../common/TextFieldGroup";
import ToolRayonSalesmanCompetitor from "./ToolRayonSalesmanCompetitor";
import ProgresRayon from "./ProgresRayon";

/*REDUX FUNCTION*/
import {
  set_config_mode_menu,
  getSalesmanFilter,
  get_depo_by_name,
  turn_on_menu,
  turn_on_menu_status,
  init_mode_radius_depo,
  change_radius_depo,
  get_all_depo,
  get_all_depo_country,
  set_depo_loc_list,
  toggle_depo_loc_list,
  edit_depo,
  delete_depo,
  set_competitor_list_filter_0,
  edit_depo_toko_competitor_bulk,
  update_many_toko_rayon,
  insert_many_rayon,
  save_depo_kec,
  get_rayon_multi_depo,
  get_depo_list_by_multi_name,
  get_rayon_list_by_multi_id,
  update_many_outlet_depo,
  turn_off_menu,
  set_config_menu,
  change_radius_all_depo,
  get_item_list,
} from "../../actions/dataActions";
import { manual_add_depo, manual_edit_depo } from "../../actions/authActions";
import {
  activate_draw_point_depo,
  inactivate_draw_point_depo,
  setDrawModeCompetitor,
  setDrawMode,
  addPolygon,
  statusDraw,
  activate_edit_point_depo,
  inactivate_edit_point_depo,
  set_depo_selected,
  set_polygon_depo,
} from "../../actions/mapActions";
import formatDateFormal from "../../validation/format_date_formal";

/*PICTURE ASSET*/
import icon_arrow_left from "../../img/icon_arrow_left.png";
import icon_success from "../../img/icon_success.png";
import polygon_step_1 from "../../img/polygon_step_1.png";
import polygon_step_2 from "../../img/polygon_step_2.png";
import polygon_step_3 from "../../img/polygon_step_3.png";
import polygon_step_4 from "../../img/polygon_step_4.png";
import polygon_step_5 from "../../img/polygon_step_5.png";

/*GENERAL*/
import st_colors from "../../validation/st_colors";
import sort_array from "../../validation/sort_array";
import calc_crow from "../../validation/calc_crow";
import writeXlsxFile from "write-excel-file";
import DownloadAllDepo from "./DownloadAllDepo";
import DownloadUnsavedDepo from "./DownloadUnsavedDepo";
import EditDepo from "./EditDepo";

class ToolDepo extends Component {
  state = {
    depo_name: "",
    depo_folder_name: "",
    dataCSV: [],
    loading: false,
    jumlah_rayon: 5,
    jumlah_toko: 300,
    mode_rayon: "jumlah_toko",
    mode_sisa_toko_rayon: "rata", //rata | akhir
    modal_manage_salesman: false,
    modal_check_rayon: false,
    mode_screen: "manual", // view | manual | upload
    modal_progres_rayon: false,
    current: 0,
    total: 0,
    is_run: "pending", //pending | start | finish
    downloadResult: false,
    modal_add_depo: false,
    depo: {},
    depo_longitude: 0,
    depo_latitude: 0,
    negara: "",
    modal_download_depo: false,
    modal_download_unsaved_depo: false,
    modal_edit_depo: false,
  };

  componentDidMount() {
    if (this.props.data.salesman_filter.depo.length === 0) {
      this.props.getSalesmanFilter();
    }
    this.props.get_item_list("depo_folder");
  }

  toggle_download_depo = () => {
    this.setState({ modal_download_depo: !this.state.modal_download_depo });
  };

  toggle_download_unsaved_depo = () => {
    this.setState({
      modal_download_unsaved_depo: !this.state.modal_download_unsaved_depo,
    });
  };

  submit_manual_add_depo = () => {
    const { depo_name, depo_longitude, depo_latitude, depo } = this.state;
    const body = {
      id: depo.id,
      Depo: depo_name.toUpperCase(),
      location: {
        type: "Point",
        coordinates: [depo_longitude, depo_latitude],
      },
      CABANG: "",
      REGION: "",
      CABANG_id: "634f60685e2fdde524c3eb17",
      REGION_id: "634f602d5e2fdde524c3eb14",
      country: this.state.negara,
    };
    // console.log(depo);
    this.props.manual_add_depo(body);
    this.setState({ modal_add_depo: false });
  };

  toggle_edit_depo = (depo) => {
    this.setState({ modal_edit_depo: !this.state.modal_edit_depo });
    if (depo) {
      this.setState({
        depo_name: depo?.Depo,
        depo,
        depo_longitude: depo.location.coordinates[0],
        depo_latitude: depo.location.coordinates[1],
      });
    }
  };

  toggle_add_depo = (depo) => {
    this.setState({ modal_add_depo: !this.state.modal_add_depo });
    if (depo) {
      this.setState({
        depo_name: depo?.Depo,
        depo,
        depo_longitude: depo.location.coordinates[0],
        depo_latitude: depo.location.coordinates[1],
      });
    }
  };

  on_update_many_outlet_depo = () => {
    let { depo_loc_list, outlet_list_filter_0 } = this.props.data;
    const body_depo = {
      depo_list: depo_loc_list,
      toko_list: outlet_list_filter_0,
    };
    this.props.update_many_outlet_depo(body_depo);
    depo_loc_list = depo_loc_list.filter((d) => d.KELURAHAN_array.length > 0);
    const body_kec = { depo_loc_list };
    this.props.save_depo_kec(body_kec);
  };

  toggle_progres_rayon = () => {
    this.setState({ modal_progres_rayon: !this.state.modal_progres_rayon });
  };

  on_get_rayon_list_by_multi_id = () => {
    const { outlet_list_filter_0 } = this.props.data;
    let rayon_id_array = outlet_list_filter_0.map((t) => {
      return t.rayon_id;
    });
    rayon_id_array = [...new Set(rayon_id_array)];
    const params = { rayon_id_array };
    this.props.get_rayon_list_by_multi_id(params);
    this.props.set_config_mode_menu("distribution", "rayon");
    // this.props.turn_on_menu_status("depo_loc_list");
  };

  on_get_depo_list_by_multi_name = () => {
    this.props.set_config_mode_menu("distribution", "depo_color");
    this.props.turn_on_menu_status("depo_loc_list");
    const { outlet_list_filter_0 } = this.props.data;
    let depo_array = outlet_list_filter_0.map((t) => {
      return t.Depo;
    });
    depo_array = [...new Set(depo_array)];
    const body = { depo_array };
    this.props.get_depo_list_by_multi_name(body);
    // this.props.get_all_depo();
    // if (this.props.data.depo_loc_list.length > 0) {
    //   this.tampilkan_yang_ada_isinya_saja();
    // }
  };

  set_mode_screen = (mode_screen) => {
    this.setState({ mode_screen });
  };

  toggle_check_rayon = () => {
    this.setState({ modal_check_rayon: !this.state.modal_check_rayon });
  };

  on_get_rayon_multi_depo = () => {
    const { depo_loc_list } = this.props.data;
    let depo_id_array = depo_loc_list.filter(
      (d) => d.KELURAHAN_array.length > 0
    );
    depo_id_array = depo_id_array.map((d) => d._id).join(",");
    const params = { depo_id_array };
    this.props.get_rayon_multi_depo(params);
  };

  on_save_depo_kec = () => {
    let { depo_loc_list } = this.props.data;
    depo_loc_list = depo_loc_list.filter((d) => d.KELURAHAN_array.length > 0);
    const body = { depo_loc_list };
    this.props.save_depo_kec(body);
  };

  toggle_manage_salesman = () => {
    this.setState({ modal_manage_salesman: !this.state.modal_manage_salesman });
  };

  on_insert_many_rayon = () => {
    let { list_menu_active } = this.props.data;
    const menu_status = list_menu_active.find((e) => e.key === "depo_loc_list");
    let rayon_list_complete = menu_status.config.rayon_list_complete;
    const body = { rayon_array: rayon_list_complete };
    this.props.insert_many_rayon(body);
  };

  on_update_many_toko_rayon = () => {
    const { rayon_list_database, outlet_list_filter_0 } = this.props.data;
    const body = rayon_list_database.map((r) => {
      let toko_id_array = [];
      toko_id_array = outlet_list_filter_0.filter((t) => t.rayon === r.name);
      toko_id_array = toko_id_array.map((t) => t._id);
      return {
        toko_id_array,
        depo_id: r?.depo_id?._id ? r?.depo_id?._id : r.depo_id,
        depo_string: r.depo_string,
        rayon_id: r._id,
        rayon_string: r.name,
      };
    });
    this.props.update_many_toko_rayon(body);
  };

  on_done_draw = () => {
    const { outlet_list_filter_0 } = this.props.data;
    const features = outlet_list_filter_0.map((c) => {
      const feature = {
        type: "Feature",
        properties: { ...c },
        geometry: c.location,
      };
      return feature;
    });
    const competitor_list_geojson = {
      type: "FeatureCollection",
      features,
    };
    const { polygon_depo, depo_selected } = this.props.map;
    let points = pointsWithinPolygon(competitor_list_geojson, polygon_depo);
    points = points.features.map((p) => p.properties._id);
    this.props.statusDraw("");
    this.props.set_polygon_depo({
      type: "FeatureCollection",
      features: [],
    });
    const body = {
      toko_id_array: points,
      depo: depo_selected.Depo,
    };
    this.props.edit_depo_toko_competitor_bulk(body);
  };

  on_draw_poligon = (depo) => {
    this.props.set_depo_selected(depo);
    this.props.statusDraw("poligon_depo");
    this.props.setDrawMode("draw_polygon");
  };

  on_reset_depo = () => {
    let { outlet_list_filter_0 } = this.props.data;
    outlet_list_filter_0 = outlet_list_filter_0.map((t) => {
      delete t.Depo;
      return t;
    });
    this.props.set_competitor_list_filter_0(outlet_list_filter_0);
  };

  on_reset_rayon = () => {
    this.props.set_config_mode_menu("distribution", "depo_color");
    this.props.turn_on_menu({
      key: "depo_loc_list",
      config: {
        rayon_list: [],
        mode: "rayon",
      },
    });
    let { outlet_list_filter_0 } = this.props.data;
    outlet_list_filter_0 = outlet_list_filter_0.map((t) => {
      delete t.rayon;
      return t;
    });
    this.props.set_competitor_list_filter_0(outlet_list_filter_0);
  };

  on_dev_edit = () => {
    let { depo } = this.props.map;
    depo.location.coordinates[0] = depo.location.coordinates[0] + 0.5;
    depo.location.coordinates[1] = depo.location.coordinates[1] + 0.5;
    this.props.edit_depo(depo);
    this.props.inactivate_edit_point_depo();
  };

  on_activate_edit_point_depo = (depo) => {
    // this.props.activate_draw_point_depo();
    // this.props.setDrawModeCompetitor("draw_point");
    // this.props.statusDraw("competitor");
    this.props.activate_edit_point_depo(depo);
  };

  on_delete_depo = (depo) => {
    this.props.delete_depo(depo);
  };

  on_activate_draw_point_depo = () => {
    this.props.activate_draw_point_depo();
    this.props.turn_on_menu_status("depo_loc_list");
    this.props.setDrawModeCompetitor("draw_point");
    this.props.statusDraw("competitor");
  };

  on_toggle_depo_loc_list = (depo_name) => {
    this.props.toggle_depo_loc_list({ depo_name });
  };

  on_bagikan_toko_mode_rayon = () => {
    this.on_reset_rayon();
    let { jumlah_toko, mode_sisa_toko_rayon } = this.state;
    let { depo_loc_list, outlet_list_filter_0 } = this.props.data;
    // const { list_menu_active } = this.props.data;
    // const menu_status = list_menu_active.find((e) => e.key === "competitor");
    // const name_array = menu_status.config.name_array;
    // let simple_mode = false;
    // if (name_array.length > 1) {
    //   simple_mode = true;
    // }
    let rayon_list = [];
    let rayon_list_complete = [];
    depo_loc_list.forEach((d) => {
      const jumlah_toko_depo = outlet_list_filter_0.filter(
        (t) => t.Depo === d.Depo
      )?.length;
      // const kab_sample = outlet_list_filter_0.filter(
      //   (t) => t.Depo === d.Depo
      // )?.[0]?.KABUPATEN;
      //untuk dua metode jumlah rayon tetap sama untuk simplifikasi
      let jumlah_rayon;
      jumlah_rayon = Math.ceil(jumlah_toko_depo / jumlah_toko);
      if (jumlah_rayon === 0) {
        jumlah_rayon = 1;
      }
      for (let index = 0; index < jumlah_rayon; index++) {
        // const element = `${d.Depo}_Rayon ${index + 1}_${kab_sample}`;
        const element = `${d.Depo}_Rayon ${index + 1}`;
        const object = {
          name: element,
          depo_id: d._id,
          depo_string: d.Depo,
          date_created: Date.now(),
          date_updated: Date.now(),
        };
        rayon_list.push(element);
        rayon_list_complete.push(object);
      }
    });
    //biarkan warna berjalan seperti progress bar
    depo_loc_list.forEach((d) => {
      let toko_list = outlet_list_filter_0.filter((t) => t.Depo === d.Depo);
      toko_list = toko_list.map((t, idx) => {
        t.index = idx;
        return t;
      });
      const jumlah_toko_depo = outlet_list_filter_0.filter(
        (t) => t.Depo === d.Depo
      )?.length;
      //untuk dua metode jumlah rayon tetap sama untuk simplifikasi
      let jumlah_rayon;
      jumlah_rayon = Math.ceil(jumlah_toko_depo / jumlah_toko);
      if (jumlah_rayon === 0) {
        jumlah_rayon = 1;
      }
      // for (let index = 0; index < jumlah_rayon; index++) {
      //   const element = `${d.Depo}_Rayon ${index + 1}`;
      //   rayon_list.push(element);
      // }
      const toko_perloop = Math.ceil(toko_list.length / jumlah_rayon) - 1;
      let jumlah_toko_final;
      if (mode_sisa_toko_rayon === "rata") {
        //jika menggunakan metode rata maka jumlah toko menjadi lebih rata dari 300 (misal)
        jumlah_toko_final = toko_perloop;
      } else if (mode_sisa_toko_rayon === "akhir") {
        //jika menggunakan metode akhir maka jumlah toko dipaksa 300 (misal)
        jumlah_toko_final = jumlah_toko;
      }
      for (
        let index_loop_rayon = 1;
        index_loop_rayon <= jumlah_rayon;
        index_loop_rayon++
      ) {
        const value_string = `${d.Depo}_Rayon ${index_loop_rayon}`;
        let toko_list_undone = toko_list.filter((toko) => !toko.rayon);
        if (index_loop_rayon < jumlah_rayon) {
          toko_list_undone = toko_list_undone.map((toko) => {
            const jarak_pojok = calc_crow(
              20.298626098167475,
              81.61835965538779,
              toko.location.coordinates[1],
              toko.location.coordinates[0]
            );
            toko.jarak_pojok = jarak_pojok;
            return toko;
          });
          toko_list_undone = sort_array(toko_list_undone, "jarak_pojok", true);
          const first_toko = toko_list_undone[0];
          const is_pojok_kiri_atas = (toko) => {
            if (
              toko?.location?.coordinates?.[1] ===
                first_toko?.location?.coordinates?.[1] &&
              toko?.location?.coordinates?.[0] ===
                first_toko?.location?.coordinates?.[0]
            ) {
              return true;
            }
          };
          const first_index = toko_list.findIndex(is_pojok_kiri_atas)
            ? toko_list.findIndex(is_pojok_kiri_atas)
            : 0;
          // toko_list[
          //   first_index
          // ].rayon = `${value_string}_${toko_list?.[first_index]?.KABUPATEN}`;
          toko_list[first_index].rayon = `${value_string}`;
          toko_list_undone = toko_list.filter((toko) => !toko.rayon);
          toko_list_undone = toko_list_undone.map((toko) => {
            const distance = calc_crow(
              toko.location.coordinates[1],
              toko.location.coordinates[0],
              first_toko.location.coordinates[1],
              first_toko.location.coordinates[0]
            );
            const result = { index: toko.index, distance };
            return result;
          });
          toko_list_undone = sort_array(toko_list_undone, "distance", true);
          toko_list_undone = toko_list_undone.slice(0, jumlah_toko_final - 1);
          toko_list_undone.forEach((toko) => {
            // toko_list[toko.index].rayon = `${value_string}_${
            //   toko_list?.[toko.index]?.KABUPATEN
            //   }`;
            toko_list[toko.index].rayon = `${value_string}`;
          });
        } else {
          toko_list_undone.forEach((toko) => {
            // toko_list[toko.index].rayon = `${value_string}_${
            //   toko_list?.[toko.index]?.KABUPATEN
            //   }`;
            toko_list[toko.index].rayon = `${value_string}`;
          });
        }
      }
    });
    rayon_list_complete = rayon_list_complete.map((d) => {
      //kelurahan
      let KELURAHAN_array = outlet_list_filter_0
        .filter((t) => t.rayon === d.name)
        .map((t) => t.KELURAHAN);
      KELURAHAN_array = [...new Set(KELURAHAN_array)];
      KELURAHAN_array = sort_array(KELURAHAN_array, "", true);
      //kecamatan
      let KECAMATAN_array = outlet_list_filter_0
        .filter((t) => t.rayon === d.name)
        .map((t) => t.KECAMATAN);
      KECAMATAN_array = [...new Set(KECAMATAN_array)];
      KECAMATAN_array = sort_array(KECAMATAN_array, "", true);
      //kabupaten
      let KABUPATEN_array = outlet_list_filter_0
        .filter((t) => t.rayon === d.name)
        .map((t) => t.KABUPATEN);
      KABUPATEN_array = [...new Set(KABUPATEN_array)];
      KABUPATEN_array = sort_array(KABUPATEN_array, "", true);
      //provinsi
      let PROPINSI_array = outlet_list_filter_0
        .filter((t) => t.rayon === d.name)
        .map((t) => t.PROPINSI);
      PROPINSI_array = [...new Set(PROPINSI_array)];
      PROPINSI_array = sort_array(PROPINSI_array, "", true);
      //insert
      const jumlah_toko = outlet_list_filter_0.filter(
        (t) => t.rayon === d.name
      )?.length;
      d.jumlah_toko = jumlah_toko;
      d.KELURAHAN_array = KELURAHAN_array;
      d.KECAMATAN_array = KECAMATAN_array;
      d.KABUPATEN_array = KABUPATEN_array;
      d.PROPINSI_array = PROPINSI_array;
      return d;
    });

    //aktifkan mode warna depo
    this.props.turn_on_menu({
      key: "depo_loc_list",
      config: {
        rayon_list,
        rayon_list_complete,
        mode: "rayon",
      },
    });
    this.props.set_config_mode_menu("distribution", "rayon");
  };

  on_set_depo_loc_list = () => {
    const { outlet_list_filter_0 } = this.props.data;
    let depo_loc_list = this.props.data.depo_loc_list;
    depo_loc_list = depo_loc_list.map((d) => {
      const jumlah_toko_depo = outlet_list_filter_0.filter(
        (t) => t.Depo === d.Depo
      )?.length;
      d.jumlah_toko_depo = jumlah_toko_depo;
      return { ...d };
    });
    depo_loc_list = depo_loc_list.filter((d) => d.jumlah_toko_depo > 0);
    this.props.set_depo_loc_list(depo_loc_list);
  };

  on_get_all_depo = () => {
    this.props.get_all_depo();
    this.props.turn_on_menu_status("depo_loc_list");
  };

  on_get_all_depo_country = () => {
    const params = {
      COUNTRY: this.props.auth.country_selected,
    };
    this.props.get_all_depo_country(params);
    this.props.turn_on_menu_status("depo_loc_list");
  };

  on_get_all_test_depo = () => {
    const params = { MODE: "test" };
    this.props.get_all_depo(params);
    this.props.turn_on_menu_status("depo_loc_list");
  };

  on_bagikan_toko_mode_radius = () => {
    let { outlet_list_filter_0, depo_loc_list } = this.props.data;
    outlet_list_filter_0.forEach((t) => {
      let distance_array_on_depo = depo_loc_list.map((depo) => {
        //[long, lat]
        const distance = calc_crow(
          depo.location.coordinates[1],
          depo.location.coordinates[0],
          t.location.coordinates[1],
          t.location.coordinates[0]
        );
        let in_circle = false;
        if (distance <= depo.radius) {
          in_circle = true;
        }
        return { ...depo, distance, in_circle };
      });
      distance_array_on_depo = distance_array_on_depo.filter(
        (d) => d.in_circle
      );
      distance_array_on_depo = sort_array(
        distance_array_on_depo,
        "distance",
        true
      );
      if (
        distance_array_on_depo?.[0]?.distance <=
        distance_array_on_depo?.[0]?.radius
      ) {
        t.Depo = distance_array_on_depo?.[0]?.Depo;
      } else {
        t.Depo = "";
      }
    });
    this.props.set_config_mode_menu("distribution", "depo_color");
    this.on_set_depo_loc_list();
  };

  on_change_radius_all_depo(e) {
    const value = e.target.value;
    const { list_menu_active } = this.props.data;
    let config = list_menu_active.find(
      (e) => e.key === "depo_loc_list"
    )?.config;
    config.radius_all_depo = value;
    this.props.set_config_menu("depo_loc_list", config);
    this.props.change_radius_all_depo({ value });
  }

  on_change_radius_depo(e) {
    const value = e.target.value;
    const id = e.target.id;
    const body = {
      idx: id,
      value,
    };
    this.props.change_radius_depo(body);
  }

  on_set_mode_depo = (mode) => {
    this.props.set_config_mode_menu("depo_loc_list", mode);
    if (mode === "radius") {
      const { depo_loc_list } = this.props.data;
      if (!depo_loc_list?.[0]?.radius) {
        this.props.init_mode_radius_depo();
      }
    }
  };

  on_set_mode_competitor_color = (mode) => {
    this.props.set_config_mode_menu("distribution", mode);
  };

  on_unduh_csv = () => {
    let { list_menu_active, outlet_list_filter_0 } = this.props.data;
    this.setState({ loading: true });
    const mode_color_competitor_status = list_menu_active.find(
      (e) => e.key === "distribution"
    )?.config?.mode;
    let header;
    let tempData = [];
    let { depo_loc_list } = this.props.data;
    let depo_name_array_hide = depo_loc_list.filter((d) => d.is_hide);
    depo_name_array_hide = depo_name_array_hide.map((d) => d.Depo);
    let competitor_current = outlet_list_filter_0.filter(
      (t) => !depo_name_array_hide.includes(t.Depo)
    );
    if (mode_color_competitor_status === "rayon_color") {
      competitor_current = sort_array(competitor_current, "rayon", true);
      header = [
        "_id",
        "Store Name",
        "Depo",
        "Rayon",
        "Type Outlet",
        "POSTCODE",
        "ID Outlet",
        "TELEPHONE NUMBER",
        "CP",
        "ADDRESS",
        "OMSET",
        "PROVINCE",
        "CITY/REGENCY",
        "DISTRICT",
        "SUBDISTRICT",
        "ISLAND",
        "COUNTRY",
        "SYSTEM",
        "LOCATION",
      ];
      tempData.push(header);
      for (let index = 0; index < competitor_current.length; index++) {
        const element = competitor_current[index];
        let row = [];
        const { user, master_admin } = this.props.auth;
        let location = "";
        if (element?.location?.coordinates?.[0] !== 0) {
          const long = element.location.coordinates[0];
          const lat = element.location.coordinates[1];
          location = `${lat},${long}`;
        }
        if (
          master_admin.filter((element) => element._id === user._id).length <= 0
        ) {
          if (element?.location?.coordinates?.[0] !== 0) {
            location = "YES";
          } else {
            location = "NO";
          }
        }
        row.push(
          element["_id"] ?? "-",
          element["Nama Toko"] ?? "-",
          element["Depo"] ?? "-",
          element["Rayon"] ?? "-",
          element["Type Outlet"] ?? "-",
          element["KDPOS"] ?? "-",
          element["ID Outlet"] ?? "-",
          element["NO Tlpon"] ?? "-",
          element["CP"] ?? "-",
          element["ALAMAT"] ?? "-",
          element["OMSET"] ?? "-",
          element["PROPINSI"] ?? "-",
          element["KABUPATEN"] ?? "-",
          element["KECAMATAN"] ?? "-",
          element["KELURAHAN"] ?? "-",
          element["PULAU"] ?? "-",
          element["COUNTRY"] ?? "-",
          element["SYSTEM"] ?? "-",
          location ?? "-"
        );
        tempData.push(row);
      }
    } else {
      competitor_current = competitor_current.filter((c) => c.Depo);
      competitor_current = sort_array(competitor_current, "Depo", true);
      header = [
        "_id",
        "Store Name",
        "Depo",
        "Type Outlet",
        "POSTCODE",
        "ID Outlet",
        "TELEPHONE NUMBER",
        "CP",
        "ADDRESS",
        "OMSET",
        "PROVINCE",
        "CITY/REGENCY",
        "DISTRICT",
        "SUBDISTRICT",
        "ISLAND",
        "COUNTRY",
        "SYSTEM",
        "LOCATION",
      ];

      tempData.push(header);
      for (let index = 0; index < competitor_current.length; index++) {
        const element = competitor_current[index];
        let row = [];
        const { user, master_admin } = this.props.auth;
        let location = "";
        if (element?.location?.coordinates?.[0] !== 0) {
          const long = element.location.coordinates[0];
          const lat = element.location.coordinates[1];
          location = `${lat},${long}`;
        }
        if (
          master_admin.filter((element) => element._id === user._id).length <= 0
        ) {
          if (element?.location?.coordinates?.[0] !== 0) {
            location = "YES";
          } else {
            location = "NO";
          }
        }
        row.push(
          element["_id"] ?? "-",
          element["Nama Toko"] ?? "-",
          element["Depo"] ?? "-",
          element["Type Outlet"] ?? "-",
          element["KDPOS"] ?? "-",
          element["ID Outlet"] ?? "-",
          element["NO Tlpon"] ?? "-",
          element["CP"] ?? "-",
          element["ALAMAT"] ?? "-",
          element["OMSET"] ?? "-",
          element["PROPINSI"] ?? "-",
          element["KABUPATEN"] ?? "-",
          element["KECAMATAN"] ?? "-",
          element["KELURAHAN"] ?? "-",
          element["PULAU"] ?? "-",
          element["COUNTRY"] ?? "-",
          element["SYSTEM"] ?? "-",
          location ?? "-"
        );
        tempData.push(row);
      }
    }

    this.setState({
      dataCSV: tempData,
      loading: false,
    });
  };

  on_unduh_excel = async () => {
    let { list_menu_active, outlet_list_filter_0 } = this.props.data;
    this.setState({ loading: true });
    const mode_color_competitor_status = list_menu_active.find(
      (e) => e.key === "distribution"
    )?.config?.mode;
    // let header;
    let tempData = [];
    let { depo_loc_list } = this.props.data;
    let depo_name_array_hide = depo_loc_list.filter((d) => d.is_hide);
    depo_name_array_hide = depo_name_array_hide.map((d) => d.Depo);
    let competitor_current = outlet_list_filter_0.filter(
      (t) => !depo_name_array_hide.includes(t.Depo)
    );
    let schema;
    let newData = [];
    if (mode_color_competitor_status === "rayon_color") {
      competitor_current = sort_array(competitor_current, "rayon", true);

      schema = [
        {
          column: "_id",
          type: String,
          value: (depo) => depo._id,
        },
        {
          column: "Store Name",
          type: String,
          value: (depo) => depo["Nama Toko"],
        },
        {
          column: "Depo",
          type: String,
          value: (depo) => depo.Depo,
        },
        {
          column: "Rayon",
          type: String,
          value: (depo) => depo.rayon,
        },
        {
          column: "Outlet Type",
          type: String,
          value: (depo) => depo["Type Outlet"],
        },
        {
          column: "Post ID",
          type: String,
          value: (depo) => depo.KDPOS,
        },
        {
          column: "Telephone Number",
          type: String,
          value: (depo) => depo["NO Tlpon"],
        },
        {
          column: "CP",
          type: String,
          value: (depo) => depo.cp,
        },
        {
          column: "Address",
          type: String,
          value: (depo) => depo.ALAMAT,
        },
        {
          column: "Revenue",
          type: String,
          value: (depo) => depo.revenue,
        },
        {
          column: "Province",
          type: String,
          value: (depo) => depo.PROPINSI,
        },
        {
          column: "City/Regency",
          type: String,
          value: (depo) => depo.KABUPATEN,
        },
        {
          column: "District",
          type: String,
          value: (depo) => depo.KECAMATAN,
        },
        {
          column: "SubDistrict",
          type: String,
          value: (depo) => depo.KELURAHAN,
        },
        {
          column: "Island",
          type: String,
          value: (depo) => depo.island,
        },
        {
          column: "Country",
          type: String,
          value: (depo) => depo.COUNTRY,
        },
        {
          column: "System",
          type: String,
          value: (depo) => depo.SYSTEM,
        },
        {
          column: "Location",
          type: String,
          value: (depo) => depo.location,
        },
      ];
    } else {
      competitor_current = competitor_current.filter((c) => c.Depo);
      competitor_current = sort_array(competitor_current, "Depo", true);
      schema = [
        {
          column: "_id",
          type: String,
          value: (depo) => depo._id,
        },
        {
          column: "Store Name",
          type: String,
          value: (depo) => depo["Nama Toko"],
        },
        {
          column: "Depo",
          type: String,
          value: (depo) => depo.Depo,
        },
        {
          column: "Outlet Type",
          type: String,
          value: (depo) => depo["Type Outlet"],
        },
        {
          column: "Post ID",
          type: String,
          value: (depo) => depo.KDPOS,
        },
        {
          column: "Outlet ID",
          type: String,
          value: (depo) => depo["ID Outlet"],
        },
        {
          column: "Telephone Number",
          type: String,
          value: (depo) => depo["NO Tlpon"],
        },
        {
          column: "CP",
          type: String,
          value: (depo) => depo.cp,
        },
        {
          column: "Address",
          type: String,
          value: (depo) => depo.ALAMAT,
        },
        {
          column: "Revenue",
          type: String,
          value: (depo) => depo.revenue,
        },
        {
          column: "Province",
          type: String,
          value: (depo) => depo.PROPINSI,
        },
        {
          column: "City/Regency",
          type: String,
          value: (depo) => depo.KABUPATEN,
        },
        {
          column: "District",
          type: String,
          value: (depo) => depo.KECAMATAN,
        },
        {
          column: "SubDistrict",
          type: String,
          value: (depo) => depo.KELURAHAN,
        },
        {
          column: "Island",
          type: String,
          value: (depo) => depo.island,
        },
        {
          column: "Country",
          type: String,
          value: (depo) => depo.COUNTRY,
        },
        {
          column: "System",
          type: String,
          value: (depo) => depo.SYSTEM,
        },
        {
          column: "Location",
          type: String,
          value: (depo) => depo.location,
        },
      ];
    }
    competitor_current.forEach((e) => {
      const { user, master_admin } = this.props.auth;
      let location = "";
      if (e?.location?.coordinates?.[0] !== 0) {
        const long = e.location.coordinates[0];
        const lat = e.location.coordinates[1];
        location = `${lat},${long}`;
      }
      if (master_admin.filter((e) => e._id === user._id).length <= 0) {
        if (e?.location?.coordinates?.[0] !== 0) {
          location = "YES";
        } else {
          location = "NO";
        }
      }
      let getData = {
        _id: e._id,
        "Nama Toko": e["Nama Toko"],
        Depo: e.Depo,
        rayon: e.rayon,
        "Type Outlet": e["Type Outlet"],
        KDPOS: e.KDPOS,
        "ID Outlet": e["ID Outlet"],
        "NO Tlpon": e["NO Tlpon"],
        cp: e.cp,
        ALAMAT: e.ALAMAT,
        revenue: e.revenue,
        PROPINSI: e.PROPINSI,
        KABUPATEN: e.KABUPATEN,
        KECAMATAN: e.KECAMATAN,
        KELURAHAN: e.KELURAHAN,
        island: e.island,
        COUNTRY: e.COUNTRY,
        SYSTEM: e.SYSTEM,
        location: location,
      };
      newData.push(getData);
    });
    await writeXlsxFile(newData, {
      schema,
      fileName: "ResultOutlet.xlsx",
    });
    this.setState({
      dataCSV: tempData,
      loading: false,
    });
  };

  on_bagikan_toko_mode_jarak = () => {
    this.props.turn_off_menu("depo_loc_list");
    this.setState({ is_run: "start" });
    setTimeout(() => {
      let { outlet_list_filter_0, depo_loc_list } = this.props.data;
      outlet_list_filter_0.forEach((t) => {
        let distance_array_on_depo = depo_loc_list.map((depo) => {
          //[long, lat]
          const distance = calc_crow(
            depo.location.coordinates[1],
            depo.location.coordinates[0],
            t.location.coordinates[1],
            t.location.coordinates[0]
          );
          return { ...depo, distance };
        });
        distance_array_on_depo = sort_array(
          distance_array_on_depo,
          "distance",
          true
        );
        t.Depo = distance_array_on_depo?.[0]?.Depo;
      });
      depo_loc_list = depo_loc_list.map((d) => {
        const jumlah_toko_depo = outlet_list_filter_0.filter(
          (t) => t.Depo === d.Depo
        )?.length;
        d.jumlah_toko_depo = jumlah_toko_depo;
        return d;
      });
      this.props.set_config_mode_menu("distribution", "depo_loc_list");
      this.on_set_depo_loc_list();
      this.setState({ is_run: "finish" });
    }, 500);
  };

  on_get_depo_by_name = () => {
    const { depo_name } = this.state;
    if (depo_name === "all_this_folder") {
      let matchDepo = this.props.data.salesman_filter.depo.filter(
        (e) => e.folder_id === this.state.depo_folder_name
      );

      function compare(a, b) {
        if (a.Depo < b.Depo) {
          return -1;
        }
        if (a.Depo > b.Depo) {
          return 1;
        }
        return 0;
      }

      matchDepo = matchDepo.sort(compare);

      matchDepo.forEach((e, p) => {
        this.props.get_depo_by_name(e.Depo);
        this.props.turn_on_menu_status("depo_loc_list");
        this.setState({ depo_name: "" });
      });
    } else {
      this.props.get_depo_by_name(depo_name);
      this.props.turn_on_menu_status("depo_loc_list");
      this.setState({ depo_name: "" });
    }
  };

  on_change = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value });
  };

  render() {
    const {
      depo_name,
      depo_folder_name,
      dataCSV,
      loading,
      jumlah_rayon,
      jumlah_toko,
      mode_rayon,
      mode_sisa_toko_rayon,
      modal_manage_salesman,
      modal_check_rayon,
      mode_screen,
      modal_progres_rayon,
      is_run,
      modal_add_depo,
      modal_download_depo,
      modal_download_unsaved_depo,
      modal_edit_depo,
    } = this.state;
    let {
      salesman_filter,
      loading_status,
      loading_item,
      depo_loc_list,
      outlet_list_filter_0,
      list_menu_active,
      rayon_list_database,
      rayon_check_status,
      current,
    } = this.props.data;

    const { country_select } = this.props.auth;
    let percent = 0;
    if (depo_loc_list.length > 0) {
      percent = parseInt((current / depo_loc_list.length) * 100);
    }

    const { draw_point_depo_status, depo_selected, statusDraw, polygon_depo } =
      this.props.map;

    let rayon_list_complete = list_menu_active.find(
      (e) => e.key === "depo_loc_list"
    )?.config?.rayon_list_complete;

    rayon_list_complete = rayon_list_complete ? rayon_list_complete : [];

    const radius_all_depo = list_menu_active.find(
      (e) => e.key === "depo_loc_list"
    )?.config?.radius_all_depo
      ? list_menu_active.find((e) => e.key === "depo_loc_list")?.config
          ?.radius_all_depo
      : 1;

    const mode_color_competitor_status = list_menu_active.find(
      (e) => e.key === "distribution"
    )?.config?.mode;

    const currDate = formatDateFormal(Date.now());
    const depo_mode = list_menu_active.find((e) => e.key === "depo_loc_list")
      ?.config?.mode;

    const depo_mode_content = (
      <main className="mb-3">
        <button
          className="button mr-2"
          id={depo_mode === "jarak" ? "green" : "grey"}
          onClick={this.on_set_mode_depo.bind(this, "jarak")}
        >
          Distance
        </button>
        <button
          className="button mr-2"
          id={depo_mode === "radius" ? "green" : "grey"}
          onClick={this.on_set_mode_depo.bind(this, "radius")}
        >
          Radius
        </button>
        <button
          className="button"
          id={depo_mode === "rayon" ? "green" : "grey"}
          onClick={this.on_set_mode_depo.bind(this, "rayon")}
        >
          Rayon
        </button>
      </main>
    );

    const mode_color_competitor_content = (
      <section className="mb-3">
        <p className="text_medium">Color mode</p>
        <button
          className="button mr-2"
          id={mode_color_competitor_status === "pt_color" ? "green" : "grey"}
          onClick={this.on_set_mode_competitor_color.bind(this, "pt_color")}
        >
          Company (CK/IF)
        </button>
        <button
          className="button mr-2"
          id={mode_color_competitor_status === "depo_color" ? "green" : "grey"}
          onClick={this.on_set_mode_competitor_color.bind(this, "depo_color")}
        >
          Depo
        </button>
        <button
          className="button mr-2"
          id={mode_color_competitor_status === "rayon_color" ? "green" : "grey"}
          onClick={this.on_set_mode_competitor_color.bind(this, "rayon_color")}
        >
          Rayon
        </button>
      </section>
    );

    // if (outlet_list_filter_0?.[0]?.rayon) {
    //   rayon_list_complete = rayon_list_complete.map((d) => {
    //     //kelurahan
    //     let KELURAHAN_array = outlet_list_filter_0
    //       .filter((t) => t.rayon === d.name)
    //       .map((t) => t.KELURAHAN);
    //     KELURAHAN_array = [...new Set(KELURAHAN_array)];
    //     KELURAHAN_array = sort_array(KELURAHAN_array, "", true);
    //     //kecamatan
    //     let KECAMATAN_array = outlet_list_filter_0
    //       .filter((t) => t.rayon === d.name)
    //       .map((t) => t.KECAMATAN);
    //     KECAMATAN_array = [...new Set(KECAMATAN_array)];
    //     KECAMATAN_array = sort_array(KECAMATAN_array, "", true);
    //     //kabupaten
    //     let KABUPATEN_array = outlet_list_filter_0
    //       .filter((t) => t.rayon === d.name)
    //       .map((t) => t.KABUPATEN);
    //     KABUPATEN_array = [...new Set(KABUPATEN_array)];
    //     KABUPATEN_array = sort_array(KABUPATEN_array, "", true);
    //     //provinsi
    //     let PROPINSI_array = outlet_list_filter_0
    //       .filter((t) => t.rayon === d.name)
    //       .map((t) => t.PROPINSI);
    //     PROPINSI_array = [...new Set(PROPINSI_array)];
    //     PROPINSI_array = sort_array(PROPINSI_array, "", true);
    //     //insert
    //     const jumlah_toko = outlet_list_filter_0.filter(
    //       (t) => t.rayon === d.name
    //     )?.length;
    //     d.jumlah_toko = jumlah_toko;
    //     d.KELURAHAN_array = KELURAHAN_array;
    //     d.KECAMATAN_array = KECAMATAN_array;
    //     d.KABUPATEN_array = KABUPATEN_array;
    //     d.PROPINSI_array = PROPINSI_array;
    //     return d;
    //   });
    // }
    if (outlet_list_filter_0?.[0]?.Depo) {
      depo_loc_list = depo_loc_list.map((d) => {
        //kelurahan
        let KELURAHAN_array = outlet_list_filter_0
          .filter((t) => t.Depo === d.Depo)
          .map((t) => t.KELURAHAN);
        KELURAHAN_array = [...new Set(KELURAHAN_array)];
        KELURAHAN_array = sort_array(KELURAHAN_array, "", true);
        //kecamatan
        let KECAMATAN_array = outlet_list_filter_0
          .filter((t) => t.Depo === d.Depo)
          .map((t) => t.KECAMATAN);
        KECAMATAN_array = [...new Set(KECAMATAN_array)];
        KECAMATAN_array = sort_array(KECAMATAN_array, "", true);
        //kabupaten
        let KABUPATEN_array = outlet_list_filter_0
          .filter((t) => t.Depo === d.Depo)
          .map((t) => t.KABUPATEN);
        KABUPATEN_array = [...new Set(KABUPATEN_array)];
        KABUPATEN_array = sort_array(KABUPATEN_array, "", true);
        //provinsi
        let PROPINSI_array = outlet_list_filter_0
          .filter((t) => t.Depo === d.Depo)
          .map((t) => t.PROPINSI);
        PROPINSI_array = [...new Set(PROPINSI_array)];
        PROPINSI_array = sort_array(PROPINSI_array, "", true);
        //insert
        // const jumlah_depo = outlet_list_filter_0.filter(
        //   (t) => t.Depo === d.Depo
        // )?.length;
        // d.jumlah_depo = jumlah_depo;
        d.KELURAHAN_array = KELURAHAN_array;
        d.KECAMATAN_array = KECAMATAN_array;
        d.KABUPATEN_array = KABUPATEN_array;
        d.PROPINSI_array = PROPINSI_array;
        return d;
      });
      // depo_loc_list = sort_array(depo_loc_list, "jumlah_depo", false);
    }
    let depo_tertampil_content;
    if (statusDraw === "poligon_depo") {
      let status_polygon = false;
      if (polygon_depo?.features?.length > 0) {
        status_polygon = true;
      }
      const container_content = (
        <section className="main_container mb-3">
          <p className="text_inferior">Add outlets:</p>
          <p className="text_bold mb-2">{depo_selected.Depo}</p>
          <p className="text_inferior">Drawn area status:</p>
          <p className="text_bold  mb-2">
            {status_polygon ? "Done" : "Not yet drawn"}
          </p>
          <button className="button" id="green" onClick={this.on_done_draw}>
            Submit
          </button>
        </section>
      );
      depo_tertampil_content = (
        <main>
          {container_content}
          <section className="text_bold mb-5">
            How to make group outlet to depo?
          </section>
          <section className="mb-3">
            <p className="text_inferior">Step 1</p>
            <p className="text_medium">
              Move the cursor to the selected changed outlet
            </p>
            <img alt="step_1" src={polygon_step_1} style={{ width: "100%" }} />
          </section>
          <section className="mb-3">
            <p className="text_inferior">Step 2</p>
            <p className="text_medium">Click to start drawing the area</p>
            <img alt="step_2" src={polygon_step_2} style={{ width: "100%" }} />
          </section>
          <section className="mb-3">
            <p className="text_inferior">Step 3</p>
            <p className="text_medium">
              Move the cursor to another area to create new area
            </p>
            <img alt="step_3" src={polygon_step_3} style={{ width: "100%" }} />
          </section>
          <section className="mb-3">
            <p className="text_inferior">Step 4</p>
            <p className="text_medium">
              Click again to create a polygon, do the same thing so the drawn
              polygon will replace the outlets
            </p>
            <img alt="step_4" src={polygon_step_4} style={{ width: "100%" }} />
          </section>
          <section className="mb-3">
            <p className="text_inferior">Step 5</p>
            <p className="text_medium">Double click to finish</p>
            <img alt="step_5" src={polygon_step_5} style={{ width: "100%" }} />
          </section>
        </main>
      );
    } else {
      depo_tertampil_content = (
        <main className="mt-5">
          <p className="mb-3">Display depo points:</p>
          <section>
            <button
              className="button mb-3"
              id="green"
              onClick={this.on_set_depo_loc_list}
            >
              View Depo which has outlets only
            </button>
          </section>
          <section>
            <button
              className="button mb-3"
              id="red"
              onClick={this.on_reset_depo}
            >
              Reset
            </button>
          </section>
          <table
            style={{
              fontSize: "13px",
              width: "100%",
            }}
            className="table_lite"
          >
            <tbody>
              {depo_loc_list.map((d, idx) => {
                let index = idx;
                if (idx > 15) {
                  index = idx % 16;
                }
                const color = st_colors[index];
                let text_button = "✓";
                let id_button = "green";
                if (d?.is_hide) {
                  id_button = "grey";
                  text_button = "-";
                }
                return (
                  <tr key={idx}>
                    <td>
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: color,
                          display: "inline-block",
                          marginRight: "5px",
                          border: "2px solid #000000",
                          borderRadius: "50%",
                          verticalAlign: "top",
                        }}
                      />
                      <br />
                      {idx + 1}
                    </td>
                    <td className="text_left">
                      <b>{d.Depo}</b>
                      {d.is_local && (
                        <span>
                          <br />
                          (not yet saved)
                        </span>
                      )}
                      {d.is_local && (
                        <span>
                          <br />
                          <button
                            className="button_small_pill mb-1"
                            id="blue"
                            onClick={this.toggle_edit_depo.bind(this, d)}
                          >
                            Edit temporary depo
                          </button>
                        </span>
                      )}
                      {d.is_local && (
                        <span>
                          <br />
                          <button
                            className="button_small_pill mb-1"
                            id="blue"
                            onClick={this.toggle_add_depo.bind(this, d)}
                          >
                            Save depo to database
                          </button>
                        </span>
                      )}
                      <br />
                      <button
                        className="button_small_pill mb-3"
                        id="green"
                        onClick={this.on_draw_poligon.bind(this, d)}
                      >
                        + Add outlet
                      </button>
                      {/* {d?.KECAMATAN_array?.length > 0 &&
                        d.KECAMATAN_array.map((k, idx) => {
                          return <div key={idx}>{k}</div>;
                        })} */}
                    </td>
                    <td className="text_right">
                      {d?.jumlah_toko_depo &&
                        new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(d?.jumlah_toko_depo)}
                    </td>
                    <td>
                      <div
                        onClick={this.on_toggle_depo_loc_list.bind(
                          this,
                          d.Depo
                        )}
                        className="button_small_pill"
                        id={id_button}
                      >
                        {text_button}
                      </div>
                    </td>
                    <td className="text_right">
                      <button
                        onClick={this.on_delete_depo.bind(this, d)}
                        className="button_small_pill mb-3"
                        id="red"
                      >
                        x
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      );
    }
    let depo_name_array_hide = depo_loc_list.filter((d) => d.is_hide);
    depo_name_array_hide = depo_name_array_hide.map((d) => d.Depo);
    const competitor_current = outlet_list_filter_0.filter(
      (t) => !depo_name_array_hide.includes(t.Depo)
    );
    const depo_already_get_data = depo_loc_list.map((d) => d.Depo);
    let depo_list = salesman_filter.depo;
    depo_list = depo_list.filter(
      (d) => !depo_already_get_data.includes(d.Depo)
    );

    let button_all_country_content = (
      <button
        className="button"
        id="green"
        onClick={this.on_get_all_depo_country}
      >
        Get all depo in this country
      </button>
    );
    if (loading_status && loading_item === "get_all_depo_country") {
      button_all_country_content = (
        <button className="button" id="grey">
          Loading...
        </button>
      );
    }

    let button_all_content = (
      <button className="button" id="green" onClick={this.on_get_all_depo}>
        Get all depo
      </button>
    );
    if (loading_status && loading_item === "get_all_depo") {
      button_all_content = (
        <button className="button" id="grey">
          Loading...
        </button>
      );
    }
    // let button_all_test_content = (
    //   <button className="button" id="grey" onClick={this.on_get_all_test_depo}>
    //     Test
    //   </button>
    // );
    let button_content = (
      <button className="button" id="green" onClick={this.on_get_depo_by_name}>
        Get depo point
      </button>
    );
    if (loading_status && loading_item === "get_depo_by_name") {
      button_content = (
        <button className="button" id="black">
          Showing...
        </button>
      );
    } else if (!depo_name) {
      button_content = (
        <button className="button" id="black">
          Choose depo
        </button>
      );
    }
    let step_1,
      step_2,
      step_3,
      step_4,
      step_5,
      step_6,
      step_7,
      step_opsi,
      rayon_tertampil_content,
      add_depo_content;

    let matchDepo = this.props.data.salesman_filter.depo.filter(
      (e) => e.folder_id === depo_folder_name
    );
    const pilih_depo_content = (
      <main>
        <p className="text_inferior">Step 2</p>
        <p className="text_medium mb-3">Choose the displayed depo point</p>
        <select
          onChange={this.on_change}
          value={depo_folder_name}
          name="depo_folder_name"
        >
          <option value="">Select Folder Depo</option>
          <option value="all">All Depo</option>
          {this.props.data.depo_folder_list.map((d, idx) => {
            return (
              <option key={idx} value={d._id}>
                {d.text}
              </option>
            );
          })}
        </select>
        {this.state.depo_folder_name === "" ? (
          <div></div>
        ) : this.state.depo_folder_name === "all" ? (
          <select onChange={this.on_change} value={depo_name} name="depo_name">
            <option value="">Select Depo Lama</option>
            {depo_list.map((d, idx) => {
              return (
                <option key={idx} value={d.Depo}>
                  {d.Depo}
                </option>
              );
            })}
          </select>
        ) : (
          <select onChange={this.on_change} value={depo_name} name="depo_name">
            <option value="">Select Depo</option>
            <option value="all_this_folder">All This Folder</option>
            {matchDepo.map((d, idx) => {
              return (
                <option key={idx} value={d.Depo}>
                  {d.Depo}
                </option>
              );
            })}
          </select>
        )}
        {button_content}
        <section className="mt-3">{button_all_country_content}</section>
        <section className="mt-3">{button_all_content}</section>
        {/* <section className="mt-3">{button_all_test_content}</section> */}
      </main>
    );
    step_1 = (
      <section className="main_container mb-3">
        <p className="text_inferior">Step 1</p>
        <p className="text_medium mb-3">
          Get competitor outlet (query by depo or country)
        </p>
        <img alt="arrow_left" src={icon_arrow_left} width="50" />
      </section>
    );
    if (outlet_list_filter_0.length > 0) {
      step_1 = (
        <section className="main_container mb-3">
          <p className="text_inferior">Step 1</p>
          <p className="text_medium mb-3">Competitor data process is succeed</p>
          <img alt="success" src={icon_success} width="50" />
        </section>
      );
    }
    step_4 = (
      <section className="main_container mb-3">
        <p className="text_inferior">Step 4</p>
        <p className="text_medium mb-3">Download the CSV / EXCEL Result</p>
        <button
          className="button ml-1"
          id="green"
          onClick={() => {
            this.setState({ downloadResult: true });
          }}
        >
          Download Result
        </button>
      </section>
    );
    let button_add_point = (
      <section
        className="text_center"
        onClick={this.on_activate_draw_point_depo}
      >
        <button className="button_circle" id="green">
          +
        </button>
        <div className="text_inferior">Add</div>
      </section>
    );
    if (draw_point_depo_status) {
      button_add_point = (
        <section className="text_center">
          <button style={{ fontSize: "100px", fontWeight: "100" }}>+</button>
          <div className="text_inferior">Click on map to add a depo point</div>
        </section>
      );
    }
    step_opsi = (
      <main>
        <section className="main_container_outline mb-3">
          <p className="text_inferior">Optional step</p>
          <p className="text_medium mb-3">Add new Depo location</p>
          {button_add_point}
        </section>

        <section className="main_container_outline mb-3">
          <p className="text_inferior">Optional step</p>
          <p className="text_medium mb-3">Download Depo List</p>
          <button
            className="button mb-1"
            id="green"
            onClick={this.toggle_download_depo}
          >
            Download All Depo
          </button>
          <br />
          <button
            className="button"
            id="green"
            onClick={this.toggle_download_unsaved_depo}
          >
            Download Unsaved Depo
          </button>
        </section>
      </main>
    );
    if (depo_mode === "jarak") {
      step_2 = (
        <section className="main_container mb-3">{pilih_depo_content}</section>
      );
      step_3 = (
        <section className="main_container mb-3">
          <p className="text_inferior">Step 3</p>
          <p className="text_medium mb-3">
            Run the matching process of outlet to the nearest depo
          </p>
          {is_run === "pending" ? (
            <button
              className="button"
              id="green"
              onClick={this.on_bagikan_toko_mode_jarak}
            >
              Run
            </button>
          ) : is_run === "start" ? (
            <button className="button" id="grey">
              Running...
            </button>
          ) : (
            <button
              className="button"
              id="green"
              onClick={this.on_bagikan_toko_mode_jarak}
            >
              Finish
            </button>
          )}
        </section>
      );
      step_5 = (
        <section className="main_container mb-3">
          <p className="text_inferior">Step 5</p>
          <p className="text_medium mb-3">Save Depo to outlet</p>
          <section>
            {loading_status && loading_item === "update_many_outlet_depo" ? (
              <button className="button" id="grey">
                Saving...
              </button>
            ) : (
              <button
                className="button"
                id="green"
                onClick={this.on_update_many_outlet_depo}
              >
                Save
              </button>
            )}
            <section
              className="mt-3"
              style={{
                height: "20px",
                backgroundColor: "#ffffff",
                borderRadius: "2rem",
                padding: "5px",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: `${percent}%`,
                  backgroundColor: "#7cc735ff",
                  borderRadius: "2rem",
                }}
              />
            </section>
            <p className="text_inferior">
              {current}/{depo_loc_list.length}
            </p>
            <p className="text_inferior">{percent}%</p>
          </section>
        </section>
      );
    } else if (depo_mode === "radius") {
      step_2 = (
        <section className="main_container mb-3">
          {pilih_depo_content}
          <p className="text_medium mb-5">Set the radius for each Depo</p>
          <section className="mb-5">
            <p className="text_inferior">Or set one radius for all depo (km)</p>
            <input
              type="number"
              value={radius_all_depo}
              name="radius"
              min={1}
              onChange={this.on_change_radius_all_depo.bind(this)}
            />
            {/* <button className="button" id="green">
              Apply to all depo
            </button> */}
          </section>
          {depo_loc_list.map((d, idx) => {
            let index = idx;
            if (idx > 15) {
              index = idx % 16;
            }
            const color = st_colors[index];
            let input_radius_content = null;
            if (depo_mode === "radius") {
              input_radius_content = (
                <main>
                  <section className="text_inferior">Input radius (km)</section>
                  <input
                    type="number"
                    value={d.radius ? d.radius : 1}
                    name="radius"
                    min={1}
                    id={idx}
                    onChange={this.on_change_radius_depo.bind(this)}
                  />
                </main>
              );
            }
            return (
              <main key={idx}>
                <table
                  style={{
                    tableLayout: "fixed",
                    fontSize: "13px",
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: color,
                            display: "inline-block",
                            marginRight: "5px",
                            border: "2px solid #000000",
                            borderRadius: "50%",
                          }}
                        />
                      </td>
                      <td>
                        <b>{d.Depo ? d.Depo : "-"}</b>
                        {input_radius_content}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </main>
            );
          })}
        </section>
      );
      step_3 = (
        <section className="main_container mb-3">
          <p className="text_inferior">Step 3</p>
          <p className="text_medium mb-3">
            Run the matching process of outlet to the nearest radius
          </p>
          <button
            className="button"
            id="green"
            onClick={this.on_bagikan_toko_mode_radius}
          >
            Run
          </button>
        </section>
      );
      step_5 = (
        <section className="main_container mb-3">
          <p className="text_inferior">Step 5</p>
          <p className="text_medium mb-3">Save Depo to outlet</p>
          <section>
            {loading_status && loading_item === "update_many_outlet_depo" ? (
              <button className="button" id="grey">
                Saving...
              </button>
            ) : (
              <button
                className="button"
                id="green"
                onClick={this.on_update_many_outlet_depo}
              >
                Save
              </button>
            )}
            <section
              className="mt-3"
              style={{
                height: "20px",
                backgroundColor: "#ffffff",
                borderRadius: "2rem",
                padding: "5px",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: `${percent}%`,
                  backgroundColor: "#7cc735ff",
                  borderRadius: "2rem",
                }}
              />
            </section>
            <p className="text_inferior">
              {current}/{depo_loc_list.length}
            </p>
            <p className="text_inferior">{percent}%</p>
          </section>
        </section>
      );
    } else if (depo_mode === "rayon") {
      rayon_tertampil_content = (
        <main className="mt-5">
          <p className="mb-3 text_medium">Displayed Rayon:</p>
          <button
            onClick={this.on_reset_rayon}
            className="button mb-5"
            id="red"
          >
            Reset
          </button>
          {depo_loc_list.map((d, idx) => {
            const rayon_list_current = rayon_list_complete.filter(
              (r) => r.depo_string === d.Depo
            );
            return (
              <main key={idx}>
                <div className="button_small" id="blue_bright">
                  Depo: {d.Depo}
                </div>
                <table
                  style={{
                    fontSize: "13px",
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "20px", height: "20px" }}></th>
                      <th className="text_left">Rayon name</th>
                      <th className="text_right">Total outlet</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rayon_list_current.map((r, idx) => {
                      let index = idx;
                      if (idx > 15) {
                        index = idx % 16;
                      }
                      const color = st_colors[index];
                      // const jumlah_toko_rayon = outlet_list_filter_0.filter(
                      //   (t) => t.rayon === r.name
                      // ).length;
                      return (
                        <tr key={idx}>
                          <td style={{ width: "20px", height: "20px" }}>
                            <div
                              style={{
                                width: "16px",
                                height: "16px",
                                backgroundColor: color,
                                display: "inline-block",
                                marginRight: "5px",
                                border: "2px solid #ffffff",
                                borderRadius: "50%",
                                marginBottom: "5px",
                              }}
                            />
                          </td>
                          <td className="text_left">
                            {r.name}
                            <section className="mb-2">
                              Subdistrict: {r?.KELURAHAN_array?.length} |
                              District: {r?.KECAMATAN_array?.length} |
                              City/Regency: {r?.KABUPATEN_array?.length} |
                              Province: {r?.PROPINSI_array?.length}
                            </section>
                          </td>
                          <td className="text_right">{r.jumlah_toko}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <hr style={{ marginBottom: "5px", borderColor: "#aeb0b1ff" }} />
              </main>
            );
          })}
        </main>
      );
      step_2 = (
        <section className="main_container mb-3">
          <p className="text_inferior">Step 2</p>
          <p className="text_medium mb-3">Set the rayon number</p>
          <section className="text_inferior">Choose Parameter</section>
          <select
            onChange={this.on_change}
            value={mode_rayon}
            id="mode_rayon"
            name="mode_rayon"
          >
            <option value="jumlah_toko">Outlet</option>
            <option value="jumlah_rayon">Rayon</option>
          </select>
          {mode_rayon === "jumlah_toko" ? (
            <main>
              <section className="text_inferior">Total outlet</section>
              <section className="text_bold">
                {new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(competitor_current.length)}
              </section>
              <section className="text_inferior">
                Set the outlets number per rayon
              </section>
              <input
                name="jumlah_toko"
                id="jumlah_toko"
                value={jumlah_toko}
                onChange={this.on_change}
                type="number"
                min="1"
                style={{ width: "100px" }}
              />
              <section className="text_inferior">
                Rayon number estimation
              </section>
              {/* <section className="text_bold">
                {new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(
                  parseInt(competitor_current.length / jumlah_toko)
                )}{" "}
                rayon
              </section> */}
            </main>
          ) : (
            <main>
              <main>
                <section className="text_inferior">Total Outlet</section>
                <section className="text_bold">
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(competitor_current.length)}
                </section>
                <section className="text_inferior">Total Rayon</section>
                <input
                  name="jumlah_rayon"
                  id="jumlah_rayon"
                  value={jumlah_rayon}
                  onChange={this.on_change}
                  type="number"
                  min="2"
                  style={{ width: "100px" }}
                />
                <section className="text_inferior">
                  Each rayon will have{" "}
                </section>
                <section className="text_bold">
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(
                    parseInt(competitor_current.length / jumlah_rayon)
                  )}{" "}
                  Outlet
                </section>
              </main>
            </main>
          )}
          <table
            style={{
              fontSize: "13px",
              width: "100%",
            }}
          >
            <thead>
              <tr>
                <th colSpan={2}></th>
                <th className="text_right">Total outlet</th>
                <th className="text_right">Total rayon</th>
              </tr>
            </thead>
            <tbody>
              {depo_loc_list.map((d, idx) => {
                let index = idx;
                if (idx > 15) {
                  index = idx % 16;
                }
                const color = st_colors[index];
                const jumlah_toko_depo = outlet_list_filter_0.filter(
                  (t) => t.Depo === d.Depo
                )?.length;
                let jumlah_rayon_depo = Math.ceil(
                  jumlah_toko_depo / jumlah_toko
                );
                if (jumlah_rayon_depo === 0) {
                  jumlah_rayon_depo = 1;
                }
                return (
                  <tr key={idx}>
                    <td
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: color,
                          border: "2px solid #000000",
                          borderRadius: "50%",
                          verticalAlign: "top",
                          marginBottom: "5px",
                        }}
                      />
                    </td>
                    <td className="text_left">
                      <b>{d.Depo}</b>
                    </td>
                    <td className="text_right">{jumlah_toko_depo}</td>
                    <td className="text_right">
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(jumlah_rayon_depo)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      );
      step_3 = (
        <section className="main_container mb-3">
          <p className="text_inferior">Step 3</p>
          <p className="text_medium mb-3">Set the rule to the last rayon</p>
          <select
            onChange={this.on_change}
            value={mode_sisa_toko_rayon}
            id="mode_sisa_toko_rayon"
            name="mode_sisa_toko_rayon"
          >
            <option value="rata">Equal to all rayon</option>
            <option value="akhir">Put to the last rayon</option>
          </select>
          <button
            className="button"
            id="green"
            onClick={this.on_bagikan_toko_mode_rayon}
          >
            Share to rayon
          </button>
        </section>
      );
      //step 5
      let button_insert_rayon = (
        <button
          className="button"
          id="green"
          onClick={this.on_insert_many_rayon}
        >
          Save rayon to database
        </button>
      );
      if (loading_status && loading_item === "insert_many_rayon") {
        button_insert_rayon = (
          <button className="button" id="green">
            Saving...
          </button>
        );
      }
      let badge_saved_rayon;
      if (rayon_list_database.length > 0) {
        button_insert_rayon = (
          <button className="button mb-1" id="black">
            Save rayon to database (Already saved)
          </button>
        );
        badge_saved_rayon = (
          <div className="badge ml-1" id="green_lime">
            Saved ✓
          </div>
        );
      }
      if (rayon_list_complete.length === 0) {
        button_insert_rayon = (
          <button className="button" id="black">
            Save rayon to database
          </button>
        );
      }
      step_5 = (
        <section className="main_container mb-3">
          <p className="text_inferior">Step 5</p>
          <p className="text_medium mb-3">Save rayon to database</p>
          <p className="text_inferior mb-1">
            To avoid double saving by other admin, run the checking before
            saving
          </p>
          <button
            className="button mb-1"
            id="green"
            onClick={this.on_get_rayon_multi_depo}
          >
            Checking rayon in Depo database
          </button>
          <section className="badge mb-1" id="green_lime">
            {rayon_check_status === "not_yet"
              ? "Not checked"
              : rayon_check_status === "checked"
              ? "Checked"
              : "Saved"}
          </section>
          {rayon_check_status === "checked" && (
            <section className="button mb-1" id="grey_outline">
              Saved total rayon : {rayon_list_database.length}
              {rayon_check_status === "checked" &&
              rayon_list_database.length === 0 ? (
                <section className="badge mb-1" id="green_lime">
                  Secured to save ↓
                </section>
              ) : (
                <button
                  className="badge mb-1"
                  id="red_lite"
                  onClick={this.toggle_check_rayon}
                >
                  Check the saved rayon
                </button>
              )}
            </section>
          )}
          {button_insert_rayon}
          {badge_saved_rayon}
        </section>
      );
      //step 6
      let percent_save_toko = 0;
      if (outlet_list_filter_0.length > 0) {
        percent_save_toko = parseInt(
          (100 * outlet_list_filter_0.filter((t) => t.saved).length) /
            outlet_list_filter_0.length
        );
        if (percent_save_toko > 100) {
          percent_save_toko = 100;
        }
      }
      let badge_update_toko;
      let button_update_toko = (
        <button className="button" id="black">
          Save rayon & Depo to competitor's outlet
        </button>
      );
      if (percent_save_toko === 100) {
        button_update_toko = (
          <button className="button" id="black">
            Save rayon & Depo to competitor's outlet
          </button>
        );
        badge_update_toko = (
          <div className="badge mt-1" id="green_lime">
            Saved ✓
          </div>
        );
      } else if (rayon_list_database.length > 0) {
        button_update_toko = (
          <button
            className="button"
            id="green"
            onClick={this.on_update_many_toko_rayon}
          >
            Save rayon & Depo to competitor's outlet
          </button>
        );
      }
      step_6 = (
        <section className="main_container mb-3">
          <p className="text_inferior">Step 6</p>
          <p className="text_medium mb-3">
            Save rayon & Depo to competitor's outlet
          </p>
          <section>{percent_save_toko} %</section>
          {button_update_toko}
          {badge_update_toko}
        </section>
      );
      //step 7
      let button_save_salesman = (
        <button className="button" id="black">
          Open the management salesman planning
        </button>
      );
      if (
        percent_save_toko >= 100
        // ||
        // /*Untuk test*/ /*Untuk test*/
        // percent_save_toko < 100
      ) {
        button_save_salesman = (
          <button
            className="button"
            id="green"
            onClick={this.toggle_manage_salesman}
          >
            Open the management salesman planning
          </button>
        );
      }
      step_7 = (
        <section className="main_container mb-3">
          <p className="text_inferior">Step 7</p>
          <p className="text_medium mb-3">Share to salesman</p>
          {button_save_salesman}
          {rayon_tertampil_content}
          <button className="button" id="grey">
            Reset test
          </button>
        </section>
      );
    }
    const steps_content = (
      <main>
        {step_1}
        {step_2}
        {step_3}
        {step_4}
        {step_5}
        {step_6}
        {step_7}
        {step_opsi}
        {add_depo_content}
        {rayon_tertampil_content}
      </main>
    );

    // const toko_with_depo_length = outlet_list_filter_0.filter(
    //   (t) => !!t.Depo
    // ).length;
    const toko_with_rayon_length = outlet_list_filter_0.filter(
      (t) => !!t.rayon_id
    ).length;
    let percent_rayon_vs_total = parseInt(
      (toko_with_rayon_length / outlet_list_filter_0.length) * 100
    );
    if (percent_rayon_vs_total > 100) {
      percent_rayon_vs_total = 100;
    } else if (outlet_list_filter_0.length === 0) {
      percent_rayon_vs_total = 0;
    }
    let status_rayon_toko_content,
      // status_rayon_salesman,
      button_trigger_plan,
      depo_legend,
      rayon_legend,
      loading_depo_content,
      loading_rayon_content;
    if (loading_status && loading_item === "get_depo_list_by_multi_name") {
      loading_depo_content = (
        <main className="main_container mt-5 text_center">Load depos</main>
      );
    }

    if (loading_status && loading_item === "get_rayon_list_by_multi_id") {
      loading_rayon_content = (
        <main className="main_container mt-5 text_center">Load rayons</main>
      );
    }
    let button_manage_salesman;
    if (rayon_list_database.length > 0 && depo_loc_list.length > 0) {
      button_manage_salesman = (
        <button
          className="button mt-2"
          id="green"
          onClick={this.toggle_manage_salesman}
        >
          Open the management salesman planning
        </button>
      );
    }
    if (percent_rayon_vs_total === 100) {
      status_rayon_toko_content = (
        <p className="badge" id="green_lime">
          {percent_rayon_vs_total} %
        </p>
      );
      // button_trigger_plan = (
      //   <main className="main_container mt-5">
      //     <button
      //       className="button mr-1"
      //       id="green"
      //       onClick={this.on_get_depo_list_by_multi_name}
      //     >
      //       Apply Depo color
      //     </button>
      //     <button
      //       className="button mt-2"
      //       id="green"
      //       onClick={this.on_get_rayon_list_by_multi_id}
      //     >
      //       Apply rayon color
      //     </button>
      //   </main>
      // );
      // status_rayon_salesman = (
      //   <p className="badge" id="green_lime">
      //     Cek
      //   </p>
      // );
    } else {
      // status_rayon_salesman = (
      //   <p className="badge" id="red_lite">
      //     0 %
      //   </p>
      // );
      status_rayon_toko_content = (
        <p className="badge" id="red_lite">
          {percent_rayon_vs_total} %
        </p>
      );
      // button_trigger_plan = (
      //   <main className="main_container mt-5">
      //     <button
      //       className="button mr-1 mb-2"
      //       id="green"
      //       onClick={this.set_mode_screen.bind(this, "manual")}
      //     >
      //       Run manual rayonization
      //     </button>
      //     <button
      //       className="button"
      //       id="green"
      //       onClick={this.set_mode_screen.bind(this, "upload")}
      //     >
      //       Upload CSV
      //     </button>
      //   </main>
      // );
    }

    button_trigger_plan = (
      <main>
        <main className="main_container mt-5">
          <button
            className="button mr-1 mb-2"
            id="green"
            onClick={this.set_mode_screen.bind(this, "manual")}
          >
            Run manual rayonization
          </button>
          <button
            className="button"
            id="green"
            onClick={this.set_mode_screen.bind(this, "upload")}
          >
            Upload CSV
          </button>
        </main>
        <main className="main_container mt-5">
          <button
            className="button mr-1"
            id="green"
            onClick={this.on_get_depo_list_by_multi_name}
          >
            Apply Depo color
          </button>
          <button
            className="button mt-2"
            id="green"
            onClick={this.on_get_rayon_list_by_multi_id}
          >
            Apply rayon color
          </button>
        </main>
      </main>
    );

    if (depo_loc_list.length > 0) {
      depo_legend = (
        <main className="mt-5">
          <section className="text_inferior">Depo Legend</section>
          <table
            style={{
              fontSize: "13px",
              width: "100%",
            }}
            className="table_lite"
          >
            <tbody>
              {depo_loc_list.map((d, idx) => {
                let index = idx;
                if (idx > 15) {
                  index = idx % 16;
                }
                const color = st_colors[index];
                return (
                  <tr key={idx}>
                    <td style={{ width: "20px", height: "20px" }}>
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: color,
                          display: "inline-block",
                          marginRight: "5px",
                          border: "2px solid #000000",
                          borderRadius: "50%",
                          verticalAlign: "top",
                        }}
                      />
                    </td>
                    <td className="text_left">
                      <b>{d.Depo}</b> {d.is_local ? "local" : ""}
                    </td>
                    <td className="text_right">
                      {d?.jumlah_toko_depo &&
                        new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(d?.jumlah_toko_depo)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      );
    }

    if (rayon_list_database.length > 0) {
      rayon_legend = (
        <main className="mt-5">
          <section className="text_inferior">Legend rayon</section>
          <table
            className="table_lite"
            style={{ width: "100%", fontSize: "13px" }}
          >
            <tbody>
              {rayon_list_database.map((r, idx) => {
                let index = idx;
                if (idx > 15) {
                  index = idx % 16;
                }
                const color = st_colors[index];
                const jumlah_toko = outlet_list_filter_0.filter(
                  (t) => t.rayon_id === r._id
                ).length;
                return (
                  <tr key={idx}>
                    <td style={{ width: "20px", height: "20px" }}>
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: color,
                          display: "inline-block",
                          marginRight: "5px",
                          border: "2px solid #000000",
                          borderRadius: "50%",
                          verticalAlign: "top",
                        }}
                      />
                    </td>
                    <td className="text_left">
                      <b>
                        {r.name} ({r?.KABUPATEN_array?.[0]})
                      </b>
                    </td>
                    <td className="text_right">{jumlah_toko}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      );
    }
    const detail_toko_content = (
      <main>
        <main className="main_container">
          <p className="text_inferior">Outlets with rayon & Depo</p>
          <p className="text_bold">
            {new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(toko_with_rayon_length)}
          </p>
          <p className="text_inferior">Total outlets displayed</p>
          <p className="text_bold">
            {new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(outlet_list_filter_0.length)}
          </p>
          <br />
          <p className="text_inferior">Status</p>
          {status_rayon_toko_content}
          {/* <p className="text_inferior">Status rayonisasi salesman</p>
          {status_rayon_salesman} */}
        </main>
        {button_trigger_plan}
        {button_manage_salesman}
        {loading_depo_content}
        {loading_rayon_content}
        {depo_legend}
        {rayon_legend}
      </main>
    );
    let final_content;
    if (mode_screen === "view") {
      final_content = detail_toko_content;
    } else if (mode_screen === "manual") {
      final_content = (
        <main>
          <button
            className="button mb-2"
            id="green"
            onClick={this.set_mode_screen.bind(this, "view")}
          >
            🡠
          </button>
          {depo_mode_content}
          {steps_content}
          {mode_color_competitor_content}
          {depo_tertampil_content}
        </main>
      );
    } else {
      final_content = (
        <main>
          <button
            className="button mb-2"
            id="green"
            onClick={this.set_mode_screen.bind(this, "view")}
          >
            🡠
          </button>
          <section className="text_bold">Upload CSV</section>
        </main>
      );
    }
    if (this.props.auth.user.badge !== "super_admin")
      final_content = (
        <main>Rayonization & download menu only for super admin</main>
      );
    const modal_manage_salesman_content = modal_manage_salesman && (
      <Modal
        modalSize="large"
        isOpen={modal_manage_salesman}
        onClose={this.toggle_manage_salesman}
      >
        <div className="box-body">
          <main style={{ minHeight: "100vh" }}>
            <ToolRayonSalesmanCompetitor />
          </main>
        </div>
      </Modal>
    );
    const modal_check_rayon_content = modal_check_rayon && (
      <Modal
        modalSize="large"
        isOpen={modal_check_rayon}
        onClose={this.toggle_check_rayon}
      >
        <div className="box-body">
          <main style={{ minHeight: "100vh" }}>
            <p className="text_bold">Check the saved rayon</p>
            <p className="text_inferior">
              If only half the rayons contains the same information there is a
              possibility this Depo already rayonized in different city/regency
              separately,{" "}
            </p>
          </main>
        </div>
      </Modal>
    );
    const modal_progres_rayon_content = modal_progres_rayon && (
      <Modal
        modalSize="small"
        isOpen={modal_progres_rayon}
        onClose={this.toggle_progres_rayon}
      >
        <div className="box-body">
          <main>
            <ProgresRayon />
          </main>
        </div>
      </Modal>
    );
    const modalDownloadResult = this.state.downloadResult && (
      <Modal
        modalSize="small"
        isOpen={this.state.downloadResult}
        onClose={() =>
          this.setState({ downloadResult: !this.state.downloadResult })
        }
      >
        <div className="box-body">
          <h1 className="text-center font-bold">Download Result by CSV</h1>
          <section className={`flex justify-center mb-3`}>
            <CSVLink
              className="button"
              id="green"
              data={dataCSV}
              filename={`Data_Kompetitor_${currDate}.csv`}
              onClick={(e, done) => {
                this.on_unduh_csv({}, done);
              }}
              separator={","}
            >
              {loading ? "Downloading data . . ." : "Download Result CSV"}
            </CSVLink>
          </section>
          <h1 className="text-center font-bold">Download Result by EXCEL</h1>
          <section className={`flex justify-center`}>
            <button
              className="button"
              id="green"
              onClick={(e, done) => {
                this.on_unduh_excel({}, done);
              }}
            >
              {loading ? "Downloading data . . ." : "Download Result EXCEL"}
            </button>
          </section>
        </div>
      </Modal>
    );
    const modal_add_depo_content = modal_add_depo && (
      <Modal
        modalSize="small"
        isOpen={modal_add_depo}
        onClose={this.toggle_add_depo}
      >
        <div className="box-body">
          <h1 className="text_bold">Save Depo Location</h1>
          <TextFieldGroup
            type="text"
            placeholder="Depo name"
            name="depo_name"
            id="depo_name"
            value={this.state.depo_name}
            onChange={this.on_change}
            error=""
          />
          <TextFieldGroup
            type="text"
            placeholder="Latitude"
            name="depo_latitude"
            id="depo_lattude"
            value={this.state.depo_latitude}
            onChange={this.on_change}
            error=""
          />
          <TextFieldGroup
            type="text"
            placeholder="Longitude"
            name="depo_longitude"
            id="depo_longitude"
            value={this.state.depo_longitude}
            onChange={this.on_change}
            error=""
          />
          <label className="text_bold">Choose Country</label>
          <select
            className="w-full"
            name="negara"
            onChange={this.on_change}
            required
            value={this.state.negara}
          >
            <option value="">Choose Country</option>
            {country_select.map((e, i) => (
              <option title={e.label} key={i} value={e.value}>
                {e.label}
              </option>
            ))}
          </select>
          <button
            className="button"
            id="green"
            onClick={this.submit_manual_add_depo}
          >
            Submit
          </button>
        </div>
      </Modal>
    );
    const modal_download_depo_content = modal_download_depo && (
      <Modal
        modalSize="small"
        isOpen={modal_download_depo}
        onClose={this.toggle_download_depo}
      >
        <div className="box-body">
          <h1 className="text_bold">Download All Depo Shown in Map</h1>
          <DownloadAllDepo />
        </div>
      </Modal>
    );
    const modal_download_unsaved_depo_content = modal_download_unsaved_depo && (
      <Modal
        modalSize="small"
        isOpen={modal_download_unsaved_depo}
        onClose={this.toggle_download_unsaved_depo}
      >
        <div className="box-body">
          <h1 className="text_bold">Download Unsaved Depo</h1>
          <DownloadUnsavedDepo />
        </div>
      </Modal>
    );
    const modal_edit_depo_content = modal_edit_depo && (
      <Modal
        modalSize="small"
        isOpen={modal_edit_depo}
        onClose={this.toggle_edit_depo}
      >
        <div className="box-body">
          <EditDepo
            depo={this.state.depo}
            toggle_edit_depo={this.toggle_edit_depo}
          />
        </div>
      </Modal>
    );
    return (
      <main className="mt-4" style={{ marginBottom: "100px" }}>
        {modal_edit_depo_content}
        {modal_manage_salesman_content}
        {modal_check_rayon_content}
        {modal_progres_rayon_content}
        {modalDownloadResult}
        {modal_add_depo_content}
        {modal_download_depo_content}
        {modal_download_unsaved_depo_content}
        <h1 className="text_bold">
          Distribute competitor outlets to the nearest depo
        </h1>
        <button
          className="button mb-2"
          id="green"
          onClick={this.toggle_progres_rayon}
        >
          View progress of rayonization
        </button>
        {final_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  set_config_mode_menu,
  getSalesmanFilter,
  get_depo_by_name,
  turn_on_menu,
  turn_on_menu_status,
  init_mode_radius_depo,
  change_radius_depo,
  get_all_depo,
  get_all_depo_country,
  set_depo_loc_list,
  toggle_depo_loc_list,
  activate_draw_point_depo,
  inactivate_draw_point_depo,
  setDrawModeCompetitor,
  setDrawMode,
  addPolygon,
  statusDraw,
  edit_depo,
  activate_edit_point_depo,
  inactivate_edit_point_depo,
  delete_depo,
  set_competitor_list_filter_0,
  set_depo_selected,
  edit_depo_toko_competitor_bulk,
  set_polygon_depo,
  update_many_toko_rayon,
  insert_many_rayon,
  save_depo_kec,
  get_rayon_multi_depo,
  get_depo_list_by_multi_name,
  get_rayon_list_by_multi_id,
  update_many_outlet_depo,
  turn_off_menu,
  manual_add_depo,
  manual_edit_depo,
  set_config_menu,
  change_radius_all_depo,
  get_item_list,
})(ToolDepo);
