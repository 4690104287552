/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/
import generate_array_color from "../../validation/generate_array_color";
import generate_color_map from "../../validation/generate_color_map";
import generate_style_step from "../../validation/generate_style_step";

/*NON IMPORT*/

class MERCH_LAYER extends Component {
  render() {
    //local storage

    //local storage

    //global props
    const { outlet_mode } = this.props.data;
    const { class_array, omset_array, omset_color_array } =
      this.props.properties;
    const { outlet_merch_list_filter_0 } = this.props.data;
    const is_visible = this.props.data.list_menu_active.find(
      (e) => e.key === "merch"
    )?.active;
    const color_mode = this.props.data.list_menu_active.find(
      (e) => e.key === "merch"
    )?.config?.color_mode;

    //content
    let geojson = {
      type: "FeatureCollection",
      features: [],
    };

    if (outlet_merch_list_filter_0.length > 0) {
      const new_features = outlet_merch_list_filter_0.map((e) => {
        let object = {
          type: "Feature",
          properties: { ...e, ...e?.outlet_id },
          geometry: e.outlet_id.location,
        };
        delete object.properties.outlet_id;
        return object;
      });
      geojson = {
        type: "FeatureCollection",
        features: [...new_features],
      };
    }

    /*
    text:
    1. "TARGET KELAS": "A",
    2. "REALISASI KELAS": "B",
    3. "Plan": "TEST",

    number:
    1. "OMSET": 1000000,
    2. "QTY": 200,
    3. "BUDGET PROGRAM": 2000000,
    4. "RASIO BUDGET PER OMSET": 2,

    not used:
    1. "FLAGGING": "MERCHANDISING PROGRAM",
    2. "FLAG FOTO": "YES",
    3. "NOMOR BULAN": 5,
    4. "NOMOR TAHUN": 2023,
    */

    let array_name = class_array;
    let array_color = generate_array_color(array_name);
    let text_final = "REALISASI KELAS";
    let circle = generate_color_map(array_name, array_color, text_final);

    if (color_mode === "REALISASI KELAS") {
      array_name = class_array;
      array_color = generate_array_color(array_name);
      text_final = "REALISASI KELAS";
      circle = generate_color_map(array_name, array_color, text_final);
    } else if (color_mode === "TARGET KELAS") {
      array_name = class_array;
      array_color = generate_array_color(array_name);
      text_final = "TARGET KELAS";
      circle = generate_color_map(array_name, array_color, text_final);
    } else if (color_mode === "OMSET") {
      /*
      [
 'step',
 ['get', 'step'],
 'black',
 0,
 'red',
 20,
 'blue',
 30,
 'green'
]

This expression uses the step expression to match the value of the step property to a range of values and returns the corresponding color.
If the value of step is less than 0, it will return black as the default color.
If it is between 0 and 19, it will return red.
If it is between 20 and 29, it will return blue.
If it is greater than or equal to 30, it will return green.

      */

      array_name = omset_array;
      array_color = omset_color_array;
      text_final = "OMSET";
      circle = generate_style_step(array_name, array_color, text_final);
    } else {
      array_name = class_array;
      array_color = generate_array_color(array_name);
      text_final = "REALISASI KELAS";
      circle = generate_color_map(array_name, array_color, text_final);
    }

    let paint = {
      "circle-color": circle,
      "circle-radius": 5,
      "circle-stroke-width": 1,
      "circle-stroke-color": "black",
      "circle-opacity": 1,
    };

    return (
      outlet_merch_list_filter_0.length > 0 && (
        <React.Fragment>
          {
            // mode cluster
            outlet_mode === "cluster" ? (
              <main>
                <Source
                  key="merch_cluster_src"
                  id="merch_cluster_src"
                  type="geojson"
                  data={geojson}
                  generateId={true}
                  cluster={true}
                  clusterMaxZoom={14}
                  clusterRadius={50}
                />
                <Layer
                  id="merch_cluster"
                  key="merch_cluster"
                  before="competitor_success"
                  source="merch_cluster_src"
                  type="circle"
                  paint={{
                    "circle-color": [
                      "step",
                      ["get", "point_count"],
                      "#51bbd6",
                      100,
                      "#f1f075",
                      750,
                      "#f28cb1",
                    ],
                    "circle-radius": [
                      "step",
                      ["get", "point_count"],
                      20,
                      100,
                      30,
                      750,
                      40,
                    ],
                  }}
                />
                <Layer
                  id="cluster_count_merch"
                  type="symbol"
                  source="merch_cluster_src"
                  before="competitor_success"
                  filter={["has", "point_count"]}
                  layout={{
                    "text-field": "{point_count_abbreviated}",
                    "text-font": [
                      "DIN Offc Pro Medium",
                      "Arial Unicode MS Bold",
                    ],
                    "text-size": 12,
                  }}
                />
                <Layer
                  id="uncluster_count_merch"
                  type="circle"
                  source="merch_cluster_src"
                  before="competitor_success"
                  filter={["!", ["has", "point_count"]]}
                  paint={{
                    "circle-color": "#11b4da",
                    "circle-radius": 4,
                    "circle-stroke-width": 1,
                    "circle-stroke-color": "#fff",
                  }}
                />
              </main>
            ) : (
              // mode non cluster/expand
              <main>
                <Source
                  key={"merch-src"}
                  id="merch-src"
                  type="geojson"
                  data={geojson}
                />
                <Layer
                  id="merch"
                  key="merch"
                  type="circle"
                  source="merch-src"
                  before="competitor_success"
                  layout={{
                    visibility: is_visible ? "visible" : "none",
                  }}
                  paint={paint}
                  onLeave={this.onLeave}
                  onClick={this.props.layerOnClick.bind(
                    this,
                    "modal_distribution"
                  )}
                />
              </main>
            )
          }
        </React.Fragment>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(MERCH_LAYER);
