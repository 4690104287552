/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal";
import STEP_1_1_GET_DATA_OUTLET from "../rayonisasi_internal/STEP_1_1_GET_DATA_OUTLET";
import STEP_2_PARENT from "../rayonisasi_internal/STEP_2_PARENT";
import STEP_3_CHOOSE_RAYON from "../rayonisasi_internal/STEP_3_CHOOSE_RAYON";
import STEP_4_1_RUN_CYCLE from "../rayonisasi_internal/STEP_4_1_RUN_CYCLE";
import STEP_5_FIRST_SALESMAN from "../rayonisasi_internal/STEP_5_FIRST_SALESMAN";
import STEP_6_DAY from "../rayonisasi_internal/STEP_6_DAY";
import STEP_7_PARENT from "../rayonisasi_internal/STEP_7_PARENT";
import STEP_8_MANAGE_DRIVER_PARENT from "../rayonisasi_internal/STEP_8_MANAGE_DRIVER_PARENT";
import STEP_9_DOWNLOAD_JKS from "../rayonisasi_internal/STEP_9_DOWNLOAD_JKS";

/*REDUX FUNCTION*/
import {
  turn_on_menu_status,
  turn_off_menu,
  set_config_mode_menu,
  set_list,
  reset_rayon_outlet,
  clear_get_outlet_list_by_area,
} from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL*/

class TOOL_RAYON_OUTLET_INTERNAL extends Component {
  state = {
    jumlah_toko: 300,
    rayon_select: "",
    cycle_select: "",

    separator: ";",
    dataCSV: [],
    mode_first: "v_first",
    ratio: 0.5,
    mode_pembagian_rayon: "jumlah_toko", // jumlah_toko | jumlah_omset | jumlah_qty
    rayon_id_polygon: "",

    modal_choose_salesman: false,
    modal_rotate: false,
    modal_error: false,
    modal_warning: false,

    modal_reset: false,
    loading_csv: false,
    downloadJKS: false,
    modal_overwrite_salesman: false,
  };

  componentDidMount() {
    this.props.turn_off_menu("outlet_by_salesman");
    this.props.turn_on_menu_status("distribution");
  }

  toggle_reset = () => {
    this.setState({ modal_reset: !this.state.modal_reset });
  };

  on_reset_rayon_outlet = () => {
    const { outlet_list_filter_0_back_up } = this.props.data;
    const outlet_id_array = outlet_list_filter_0_back_up.map((t) => t._id);
    const body = { outlet_id_array };
    this.props.reset_rayon_outlet(body);
    this.setState({ modal_reset: false });

    this.props.turn_off_menu("salesman");
    this.props.turn_off_menu("day");
    this.props.turn_off_menu("cycle");
    this.props.turn_off_menu("rayon");
    //delete all rayon in local db
    //rayon_list_database
    this.props.set_list({
      text: "rayon_list_database",
      list: [],
    });
    this.props.set_list({
      text: "rayon_select",
      list: "",
    });
  };

  render() {
    const { modal_reset } = this.state;

    const {
      rayon_select,
      loading_status,
      rayon_list_database,
      outlet_multi_select_data,
    } = this.props.data;

    let rayon_object = {};
    if (rayon_list_database.length > 0) {
      rayon_object = rayon_list_database.find((r) => r._id === rayon_select);
    }

    const plan_list = rayon_object?.plan_list ? rayon_object?.plan_list : [];

    const modal_reset_content = modal_reset && (
      <Modal modalSize="small" isOpen={modal_reset} onClose={this.toggle_reset}>
        <div className="box-body text_center">
          Are you sure to reset all rayonization data?
          <br />
          <br />
          <br />
          <section className="text_left">
            This data will be deleted:
            <br />
            <br />
            <b>
              <p>Rayon name inside outlet</p>
              <p>Cycle data inside outlet</p>
              <p>Salesman visit data (JKS) inside outlet</p>
            </b>
          </section>
          <br />
          {loading_status && loading_status === "reset_rayon_outlet" ? (
            <button className="button mt-3" id="red">
              Resetting...
            </button>
          ) : (
            <button
              className="button mt-3"
              id="red"
              onClick={this.on_reset_rayon_outlet}
            >
              Reset
            </button>
          )}
        </div>
      </Modal>
    );

    return (
      <main className="mt-10">
        {modal_reset_content}
        <h1 className="text_header">OUTLET RAYONIZATION</h1>
        <STEP_1_1_GET_DATA_OUTLET />
        <STEP_2_PARENT />
        <STEP_3_CHOOSE_RAYON />
        {!outlet_multi_select_data && (
          <>
            <main className="container_outline mb-3">
              {rayon_select ? (
                <p className="text_inferior">
                  Allowed to continue planning on:
                  <br />
                  <b>{rayon_object?.name}</b>
                </p>
              ) : (
                <p className="text_inferior" id="red_text">
                  Choose rayon first
                </p>
              )}
              <STEP_4_1_RUN_CYCLE />
              <STEP_5_FIRST_SALESMAN />
              <br />
              <STEP_6_DAY />
              {plan_list?.length > 0 ? (
                <p className="text_inferior">Allowed to rotate template</p>
              ) : (
                <p className="text_inferior" id="red_text">
                  Run day planning first
                </p>
              )}
              <STEP_7_PARENT />
            </main>
            <STEP_8_MANAGE_DRIVER_PARENT />
          </>
        )}

        <STEP_9_DOWNLOAD_JKS />
        <section className="main_container mb-3">
          <p className="text_inferior">Reset JKS</p>
          <p className="text_medium">Reset all rayonization in this Depo</p>
          <hr />
          <button className="button mt-3" id="red" onClick={this.toggle_reset}>
            Reset
          </button>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
  auth: state.auth,
  tracking: state.tracking,
});

export default connect(mapStateToProps, {
  turn_off_menu,
  set_config_mode_menu,
  set_list,
  reset_rayon_outlet,
  clear_get_outlet_list_by_area,
  turn_on_menu_status,
})(TOOL_RAYON_OUTLET_INTERNAL);
