//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import writeXlsxFile from "write-excel-file";

//Personal Component
import Modal from "../common/Modal";

//Redux function
import { get_driver_multi_depo } from "../../actions/tracking";
// import axios from "axios";

//Picture Asset

//General Function

class STEP_9_DOWNLOAD_JKS extends Component {
  state = {
    separator: ";",
    dataCSV: [],
    loading_csv: false,
    downloadJKS: false,
  };

  on_toggle_jks = () => {
    this.get_toggle_driver();
    this.setState({ downloadJKS: true });
  };

  get_toggle_driver = () => {
    const { list_menu_active } = this.props.data;
    const config_plan = list_menu_active.find((e) => e.key === "salesman")
      ?.config?.value;
    const depos = config_plan.map((e) => e.value);
    this.props.get_driver_multi_depo(depos);
  };

  generateDataCSV = () => {
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });
      let { outlet_list_filter_0, rayon_list_database, salesman_list } =
        this.props.data;
      const { user, master_admin } = this.props.auth;

      //ambil max_day karena request dari nabati ingin memanjang kebawah (ada transisi memanjang ke samping dan ke bawah)
      //ada permasalahan ketika max yang yang ada lebih besar dari salah satu toko
      //maka akan ada baris yang kosong
      let max_day = outlet_list_filter_0.map((t) =>
        t?.kunjungan_arr_new?.length ? t?.kunjungan_arr_new?.length : 0
      );
      max_day = Math.max(...max_day);

      let toko_list = [];

      //jika ada hari kunjungannya maka
      if (max_day > 0) {
        outlet_list_filter_0.forEach((item, idx) => {
          for (
            let index_salesman = 0;
            index_salesman < max_day;
            index_salesman++
          ) {
            const item_new = {
              ...item,
              kunjungan_1: item.kunjungan_arr_new[index_salesman],
            };
            toko_list.push(item_new);
          }
        });
        outlet_list_filter_0 = toko_list;
      }

      try {
        let tempData = [];
        let header = [
          "_id",
          "PLAN",
          "ID OUTLET",
          "ID SFA",
          "DEPO",
          "STATUS DEPO",
          "SYSTEM",
          "OUTLET NAME",
          "ADDRESS 1",
          "ADDRESS 2",
          "TELEPHONE NUMBER",
          "TYPE OUTLET",
          "SUBDISTRICT",
          "DISTRICT",
          "CITY/REGENCY",
          "PROVINCE",
          "FLAG DISTRICT",
          "DETECTED LOCATION",
          "UPDATE DESCRIPTION",
          "POSTCODE",
          "OUTLET STATUS",
          "ID BRANCH",
          "COUNTRY",
          "COORDINATE STATUS",
          "RAYON_ID",
          "RAYON_NAME",
          "OMSET_AVG",
          "QTY_AVG",
        ];
        //looping untuk header hari kunjungan header
        //1. nama
        header.push(`SALESMAN NAME`);
        //2. divisi
        header.push(`DIV`);
        //3. kode sales
        header.push(`KODE SALES`);
        //4. nik
        header.push(`SALESMAN NIK`);
        //5. id mapid
        header.push(`SALESMAN ID MAPID`);
        //6. day
        header.push(`DAY`);
        //7. freq
        header.push(`FREQ`);
        //8. nama driver
        header.push("DRIVER NAME");
        //9. nopol
        header.push("NOPOL");
        //10. kapasitas ctn
        header.push("CTN CAPACITY");
        //11. kapasitas m3
        header.push("M3 CAPACITY");

        tempData.push(header);
        for (let index = 0; index < outlet_list_filter_0.length; index++) {
          const element = outlet_list_filter_0[index];
          let result = {};
          this.props.tracking.driver_list.forEach((r, idx) => {
            let y = r.rayon_id_list.includes(element.rayon_id);
            if (y) {
              result = r;
            }
          });
          const rayon_match = rayon_list_database.find(
            (e) => e._id === outlet_list_filter_0[index].rayon_id
          );
          let rayon_name = "Rayon Not Found";
          if (rayon_match) {
            rayon_name = rayon_match.name ?? "Rayon Not Found";
          }

          let location = "";
          let status_koordinat = "no";

          if (element?.location?.coordinates?.[0] !== 0) {
            const long = element.location.coordinates[0];
            const lat = element.location.coordinates[1];
            location = `${lat},${long}`;
            status_koordinat = "yes";
          }

          if (master_admin.filter((e) => e._id === user._id).length <= 0) {
            location = "";
          }

          let array = [
            element._id,
            element["Plan"] ?? "-",
            element["ID Outlet"] ?? "-",
            element["ID SFA"] ?? "-",
            element["Depo"] ?? "-",
            element["STATUS DEPO"] ?? "-",
            element["SYSTEM"] ?? "-",
            element["Nama Toko"] ?? "-",
            element["ALAMAT"] ?? "-",
            element["ALAMATLAIN"] ?? "-",
            element["NO Tlpon"] ?? "-",
            element["Type Outlet"] ?? "-",
            element["KELURAHAN"] ?? "-",
            element["KECAMATAN"] ?? "-",
            element["KABUPATEN"] ?? "-",
            element["PROPINSI"] ?? "-",
            element["FLAG DISTRICT"] ?? "-",
            location,
            element["KET UPDATE"] ?? "-",
            element["KDPOS"] ?? "-",
            element["Status Outlet"] ?? "-",
            element["ID BRANCH"] ?? "-",
            element["COUNTRY"] ?? "-",
            status_koordinat,
            element["rayon_id"] ?? "-",
            rayon_name,
            element?.["value"] ?? 0,
            element?.["qty"] ?? 0,
          ];

          //looping untuk header hari kunjungan value
          const kunjungan_1 = element?.kunjungan_1 ? element?.kunjungan_1 : {};
          const salesman_id = kunjungan_1?.salesman_id
            ? kunjungan_1?.salesman_id
            : "";
          let salesman_object = {};
          if (salesman_id) {
            salesman_object = salesman_list.find((s) => s._id === salesman_id);
          }

          const text_empty = "Not yet planning the day";

          //1. nama
          array.push(
            salesman_object?.["Nama Only"]
              ? salesman_object?.["Nama Only"]
              : text_empty
          );
          //2. divisi
          array.push(
            salesman_object?.["divisi_arr"]?.[0]
              ? salesman_object?.["divisi_arr"]?.[0].slice(3, 5)
              : text_empty
          );
          //3. kode sales
          array.push(
            salesman_object?.["kd_sales_arr"]?.[0]
              ? salesman_object?.["kd_sales_arr"]?.[0]
              : text_empty
          );
          //4. nik
          array.push(salesman_object?.NIK ? salesman_object?.NIK : text_empty);
          //5. id mapid
          array.push(
            kunjungan_1?.salesman_id ? kunjungan_1?.salesman_id : text_empty
          );
          //6. day
          array.push(
            kunjungan_1.HARIKunjungan ? kunjungan_1.HARIKunjungan : text_empty
          );
          //7. freq
          array.push(
            kunjungan_1.FreqVisit ? kunjungan_1.FreqVisit : text_empty
          );

          const text_empty_driver = "Not yet planning driver";
          //8. driver
          array.push(result?.["DRIVER"] || text_empty_driver);
          //9. nopol
          array.push(result?.["NOPOL"] || text_empty_driver);
          //10. kapasitas_ctn
          array.push(result?.["KAPASITAS_CTN"] || text_empty_driver);
          //11. kapasitas_m3
          array.push(result?.["KAPASITAS_M3"] || text_empty_driver);

          tempData.push(array);
        }
        const { omset_split } = this.props.data;
        tempData = this.generateDataSplitOmset(omset_split, tempData);
        this.setState({
          loading_csv: false,
          dataCSV: tempData,
        });
      } catch (error) {
        this.setState({
          loading: false,
        });
      }
    }
  };

  generateDataEXCEL = async () => {
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });
      let {
        outlet_list_filter_0,
        rayon_list_database,
        salesman_list,
        outlet_multi_select_data,
        outlet_list_select_0,
      } = this.props.data;
      const { user, master_admin } = this.props.auth;

      let data_excel = outlet_multi_select_data
        ? outlet_list_select_0
        : outlet_list_filter_0;
      let max_day = data_excel.map((t) =>
        t?.kunjungan_arr_new?.length ? t?.kunjungan_arr_new?.length : 0
      );
      max_day = Math.max(...max_day);
      let toko_list = [];
      if (max_day > 0) {
        data_excel.forEach((item, idx) => {
          for (
            let index_salesman = 0;
            index_salesman < max_day;
            index_salesman++
          ) {
            const item_new = {
              ...item,
              kunjungan_1: item.kunjungan_arr_new[index_salesman],
            };

            toko_list.push(item_new);
          }
        });
        data_excel = toko_list;
      }

      try {
        let schema = [
          {
            column: "_id",
            type: String,
            value: (jks) => jks._id,
          },
          {
            column: "PLAN",
            type: String,
            value: (jks) => jks.Plan,
          },
          {
            column: "ID OUTLET",
            type: String,
            value: (jks) => jks["ID Outlet"],
          },
          {
            column: "ID SFA",
            type: String,
            value: (jks) => jks["ID SFA"],
          },
          {
            column: "DEPO",
            type: String,
            value: (jks) => jks.Depo,
          },
          {
            column: "DEPO STATUS",
            type: String,
            value: (jks) => jks["STATUS DEPO"],
          },
          {
            column: "SYSTEM",
            type: String,
            value: (jks) => jks.SYSTEM,
          },
          {
            column: "OUTLET NAME",
            type: String,
            value: (jks) => jks["Nama Toko"],
          },
          {
            column: "ADDRESS 1",
            type: String,
            value: (jks) => jks.ALAMAT,
          },
          {
            column: "ADDRESS 2",
            type: String,
            value: (jks) => jks.ALAMATLAIN,
          },
          {
            column: "TELEPHONE NUMBER",
            type: String,
            value: (jks) => jks["NO Tlpon"],
          },
          {
            column: "TYPE OUTLET",
            type: String,
            value: (jks) => jks["Type Outlet"],
          },
          {
            column: "SUBDISTRICT",
            type: String,
            value: (jks) => jks.KELURAHAN,
          },
          {
            column: "DISTRICT",
            type: String,
            value: (jks) => jks.KECAMATAN,
          },
          {
            column: "CITY/REGENCY",
            type: String,
            value: (jks) => jks.KABUPATEN,
          },
          {
            column: "PROVINCE",
            type: String,
            value: (jks) => jks.PROPINSI,
          },
          {
            column: "FLAG DISTRICT",
            type: String,
            value: (jks) => jks["FLAG DISTRICT"],
          },
          {
            column: "DETECTED LOCATION",
            type: String,
            value: (jks) => jks.location,
          },
          {
            column: "UPDATE DESCRIPTION",
            type: String,
            value: (jks) => jks["KET UPDATE"],
          },
          {
            column: "POSTCODE",
            type: String,
            value: (jks) => jks.KDPOS,
          },
          {
            column: "OUTLET STATUS",
            type: String,
            value: (jks) => jks["Status Outlet"],
          },
          {
            column: "ID BRANCH",
            type: String,
            value: (jks) => jks["ID BRANCH"],
          },
          {
            column: "COUNTRY",
            type: String,
            value: (jks) => jks.COUNTRY,
          },
          {
            column: "COORDINATE STATUS",
            type: String,
            value: (jks) => jks.status_koordinat,
          },
          {
            column: "RAYON_ID",
            type: String,
            value: (jks) => jks.rayon_id,
          },
          {
            column: "RAYON_NAME",
            type: String,
            value: (jks) => jks.rayon_name,
          },
          {
            column: "OMSET AVG",
            type: Number,
            value: (jks) => jks.value,
          },
          {
            column: "QTY AVG",
            type: Number,
            value: (jks) => jks.qty,
          },
        ];

        const text_empty_salesman = "Not yet planning the day";
        const text_empty_driver = "Not yet planning driver";
        //looping untuk header hari kunjungan header
        //1. nama
        schema.push({
          column: `SALESMAN NAME`,
          type: String,
          value: (jks) => jks?.[`SALESMAN NAME`] || text_empty_salesman,
        });
        //2. divisi
        schema.push({
          column: `DIV`,
          type: String,
          value: (jks) => jks?.[`DIV`] || text_empty_salesman,
        });
        //3. kode sales
        schema.push({
          column: `SALES CODE`,
          type: String,
          value: (jks) => jks?.[`KODE SALES`] || text_empty_salesman,
        });
        //4. nik
        schema.push({
          column: `SALESMAN NIK`,
          type: String,
          value: (jks) => jks?.[`SALESMAN NIK`] || text_empty_salesman,
        });
        //5. id mapid
        schema.push({
          column: `SALESMAN ID MAPID`,
          type: String,
          value: (jks) => jks?.[`SALESMAN ID MAPID`] || text_empty_salesman,
        });
        //6. day
        schema.push({
          column: `DAY`,
          type: String,
          value: (jks) => jks?.[`DAY`] || text_empty_salesman,
        });
        //7. freq
        schema.push({
          column: `FREQ`,
          type: String,
          value: (jks) => jks?.[`FREQ`] || text_empty_salesman,
        });
        //8. nama driver
        schema.push({
          column: `DRIVER NAME`,
          type: String,
          value: (jks) => jks?.namaDriver || text_empty_driver,
        });
        //9. nopol
        schema.push({
          column: `POLICY NUMBER`,
          type: String,
          value: (jks) => jks?.nopol || text_empty_driver,
        });
        //10. KAPASITAS_CTN
        schema.push({
          column: `CTN CAPACITY`,
          type: String,
          value: (jks) => jks?.kapasitasCTN || text_empty_driver,
        });
        //11. KAPASITAS_M3
        schema.push({
          column: `CTN CAPACITY`,
          type: String,
          value: (jks) => jks?.kapasitasM3 || text_empty_driver,
        });
        let newData = [];

        data_excel.forEach((e, index) => {
          let result = {};
          this.props.tracking.driver_list.forEach((r, idx) => {
            let y = r.rayon_id_list.includes(e.rayon_id);
            if (y) {
              result = r;
            }
          });
          const rayon_match = rayon_list_database.find(
            (x) => x._id === data_excel[index].rayon_id
          );
          let rayon_name = "Rayon Not Found";
          if (rayon_match) {
            rayon_name = rayon_match.name ?? "Rayon Not Found";
          }
          let location = "";
          let status_koordinat = "no";
          if (e?.location?.coordinates?.[0] !== 0) {
            const long = e.location.coordinates[0];
            const lat = e.location.coordinates[1];
            location = `${lat},${long}`;
            status_koordinat = "yes";
          }
          if (master_admin.filter((e) => e._id === user._id).length <= 0) {
            location = "";
          }
          let getData = {
            _id: e?._id,
            Plan: e?.["Plan"],
            "ID SFA": e?.["ID SFA"],
            "ID Outlet": e["ID Outlet"],
            Depo: e.Depo,
            "STATUS DEPO": e?.["STATUS DEPO"],
            SYSTEM: e?.SYSTEM,
            "Nama Toko": e?.["Nama Toko"],
            ALAMAT: e?.ALAMAT,
            ALAMATLAIN: e?.ALAMATLAIN,
            "NO Tlpon": e?.["NO Tlpon"],
            "Type Outlet": e?.["Type Outlet"],
            KELURAHAN: e?.KELURAHAN,
            KECAMATAN: e?.KECAMATAN,
            KABUPATEN: e?.KABUPATEN,
            PROPINSI: e?.PROPINSI,
            "FLAG DISTRICT": e?.["FLAG DISTRICT"],
            location: location,
            "KET UPDATE": e?.["KET UPDATE"],
            KDPOS: e?.KDPOS,
            "Status Outlet": e?.["Status Outlet"],
            "ID BRANCH": e?.["ID BRANCH"],
            COUNTRY: e?.COUNTRY,
            status_koordinat: status_koordinat,
            rayon_id: e?.["rayon_id"],
            rayon_name: rayon_name,
            value: e?.value,
            qty: e?.qty,
            // namaDriver: result.DRIVER,
            // nopol: result.NOPOL,
            // kapasitasCTN: result.KAPASITAS_CTN,
            // kapasitasM3: result.KAPASITAS_M3,
          };
          //looping untuk header hari kunjungan value
          const kunjungan_1 = e?.kunjungan_1 ? e?.kunjungan_1 : {};

          const salesman_id = kunjungan_1.salesman_id
            ? kunjungan_1.salesman_id
            : "";

          let salesman_object = {};
          if (salesman_id) {
            salesman_object = salesman_list.find((s) => s._id === salesman_id);
          }

          const text_empty_salesman = "Not yet planning the day";
          const text_empty_driver = "Have not selected a driver";

          //1. nama
          getData[`SALESMAN NAME`] =
            salesman_object?.["Nama Only"] || text_empty_salesman;
          //2. divisi
          getData[`DIV`] =
            salesman_object?.["divisi_arr"]?.[0] || text_empty_salesman;
          //3. kode sales
          getData[`KODE SALES`] =
            salesman_object?.["kd_sales_arr"]?.[0] || text_empty_salesman;
          //4. nik
          getData[`SALESMAN NIK`] = salesman_object?.NIK || text_empty_salesman;
          //5. id mapid
          getData[`SALESMAN ID MAPID`] =
            kunjungan_1?.salesman_id || text_empty_salesman;
          //6. day
          getData[`DAY`] = kunjungan_1?.HARIKunjungan || text_empty_salesman;
          //7. freq
          getData[`FREQ`] = kunjungan_1?.FreqVisit || text_empty_salesman;
          getData[`namaDriver`] = result?.DRIVER || text_empty_driver;
          getData[`nopol`] = result?.NOPOL || text_empty_driver;
          getData[`kapasitasCTN`] = result?.KAPASITAS_CTN || text_empty_driver;
          getData[`kapasitasM3`] = result?.KAPASITAS_M3 || text_empty_driver;
          newData.push(getData);
        });

        await writeXlsxFile(newData, {
          schema,
          fileName: "DataExcelJKS.xlsx",
        });
        this.setState({
          loading: false,
          loading_csv: false,
        });
      } catch (error) {
        this.setState({
          loading: false,
        });
      }
    }
  };

  generateDataSplitOmset = (omset_split, data_jks) => {
    const key = data_jks.shift();
    let objs = [];
    for (let index = 0; index < data_jks.length; index++) {
      const list_jks = data_jks[index];
      let obj = {};
      for (let index = 0; index < list_jks.length; index++) {
        const data = list_jks[index];
        obj[`${key[index]}`] = data;
      }
      objs.push(obj);
    }

    for (let index = 0; index < objs.length; index++) {
      const element = objs[index];
      const match = omset_split.find(
        (omset) =>
          omset.customer_id === element["ID OUTLET"] &&
          omset.division_product === element["DIV"]
      );
      if (match) {
        element["OMSET_AVG"] = parseFloat(match.value)
          .toString()
          .replace(/\./g, ",");
        element["QTY_AVG"] = parseFloat(match.qty)
          .toString()
          .replace(/\./g, ",");
      } else {
        element["OMSET_AVG"] = "Not yet";
        element["QTY_AVG"] = "Not yet";
      }
    }

    const arrayOfArrays = objs.map((obj) => Object.values(obj));
    const final = [key, ...arrayOfArrays];
    return final;
  };

  render() {
    //local storage

    //local state
    const { dataCSV, separator, loading_csv } = this.state;

    //global props
    const { outlet_list_filter_0 } = this.props.data;

    //content
    const modal_download_jks_content = this.state.downloadJKS && (
      <Modal
        modalSize="small"
        isOpen={this.state.downloadJKS}
        onClose={() => this.setState({ downloadJKS: !this.state.downloadJKS })}
      >
        <div className="box-body">
          <h1 className="text-center font-bold">Download JKS by CSV</h1>
          <section className={`flex justify-center mb-3`}>
            <CSVLink
              className="button"
              id="green"
              data={dataCSV}
              separator={separator}
              filename={`Outlet Data JKS.csv`}
              onClick={(e, done) => {
                this.generateDataCSV(outlet_list_filter_0, done);
              }}
            >
              {loading_csv ? "Loading ..." : "Download JKS CSV"}
            </CSVLink>
            {/* <button onClick={() => this.generateDataCSV()}>TES</button> */}
          </section>
          <h1 className="text-center font-bold">Download JKS by EXCEL</h1>
          <section className={`flex justify-center`}>
            <button
              className="button"
              id="green"
              onClick={(e, done) => {
                this.generateDataEXCEL(outlet_list_filter_0, done);
              }}
            >
              {loading_csv ? "Loading ..." : "Download JKS EXCEL"}
            </button>
          </section>
        </div>
      </Modal>
    );

    return (
      <section className="main_container mb-3">
        {modal_download_jks_content}
        <p className="badge mb-1" id="blue">
          Step 9
        </p>
        <p className="text_medium">View & download JKS</p>
        <hr />
        <p className="text_inferior">Mode outlet filter with rayon</p>
        <button className="button" id="green" onClick={this.on_toggle_jks}>
          Download JKS
        </button>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
  auth: state.auth,
  tracking: state.tracking,
});

export default connect(mapStateToProps, { get_driver_multi_depo })(
  STEP_9_DOWNLOAD_JKS
);
