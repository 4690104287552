/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { bbox } from "@turf/turf";
import { CSVLink } from "react-csv";
import writeXlsxFile from "write-excel-file";
import Modal from "../common/Modal";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-moment";
import Select from "react-select";

/*PERSONAL COMPONENT*/
import Spinner from "../parent/Spinner";
import BloopProduct from "../parent/BloopProduct";
import ON_FLY from "../main/ON_FLY";

/*REDUX FUNCTION*/
import {
  get_omset,
  turn_on_menu,
  turn_on_menu_status,
  turn_off_menu,
  getSalesmanFilter,
  popUpAction,
  getConclusion,
  filterCollor,
  filterCollorSalesman,
  get_business_unit,
  get_brand_unit,
  get_product_unit,
  get_principle_unit,
  getListTypeToko,
  searchShop,
  searchPrinciple,
  searchBu,
  searchBrands,
  searchProducts,
  resetMode,
  getSalesmanOmset,
  getDate,
  getDataSalesman,
} from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL*/
import isEqual from "../../validation/is-equal";
import ft_colors from "../../validation/ft_colors.json";
import sort_array from "../../validation/sort_array";

/*NON IMPORT*/

class OMSET_MENU extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MONTH_NUMBER: 2,
      YEAR_NUMBER: 2022,
      name_arr: [],
      depo: "",
      plan_id: "",
      loading_csv: false,
      separator: ";",
      dataCSV: [],
      downloadOutlet: false,
      optionValue: "",
      selectArea: "",
      selectedType: [],
      list_column: 50,
      modeClick: "",
      selectedShop: [],
      searchToko: "",
      searchPrinciple: "",
      searchBu: "",
      searchBrands: "",
      searchProducts: "",
      showTable: false,
      selectMode: "single",
      throwParams: {},
    };
  }

  componentDidMount() {
    this.props.getListTypeToko();
  }

  on_turn_on_menu_status = () => {
    this.props.turn_on_menu_status("omset");
  };

  componentDidUpdate(prevProps) {
    const { omset_list_filter_0 } = this.props.data;
    const sidebarW = parseInt(localStorage.getItem("sidebar-width") || 500);
    if (
      !isEqual(
        prevProps.data.omset_list_filter_0,
        this.props.data.omset_list_filter_0
      ) &&
      omset_list_filter_0.length > 0
    ) {
      const { map } = this.props.map;
      const new_features = omset_list_filter_0.map((e) => {
        return {
          type: "Feature",
          properties: { ...e },
          geometry: e?.location,
        };
      });
      const geojson = {
        type: "FeatureCollection",
        features: [...new_features],
      };
      let [minLng, minLat, maxLng, maxLat] = bbox(geojson);
      map.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: { top: 200, bottom: 200, left: sidebarW + 50, right: 50 },
        }
      );
    }
  }

  on_turn_off_menu = () => {
    this.props.turn_off_menu("omset");
  };

  on_change = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    if (name === "depo") {
      this.setState({
        plan_id: target.options[target.selectedIndex].id,
      });
    }
  };

  generateDataCSV = (omset_list) => {
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });
      try {
        let tempData = [];
        if (this.state.modeClick === "principle") {
          const header = [
            "_id",
            "ADDRESS",
            "DEPO",
            "ID OUTLET",
            "MONTH NUMBER",
            "STORE NAME",
            "PLAN",
            "TYPE OUTLET",
            "VALUE",
            "QTY",
            "YEAR",
            "LOCATION",
            "RAYON",
            "PROVINCE",
            "CITY/REGENCY",
            "DISTRICT",
            "SUBDISTRICT",
            "PRINCIPAL",
          ];
          tempData.push(header);
          for (let index = 0; index < omset_list.length; index++) {
            const element = omset_list[index];
            const { user, master_admin } = this.props.auth;
            const { rayon_list_database } = this.props.data;
            let location = "";
            let rayon_name = "Rayon Not Found";
            const rayon_match = rayon_list_database.find(
              (x) => x._id === omset_list[index].rayon_id
            );
            if (rayon_match) {
              rayon_name = rayon_match.name ?? "Rayon Not Found";
            }
            if (element?.location?.coordinates?.[0] !== 0) {
              const long = element.location.coordinates[0];
              const lat = element.location.coordinates[1];
              location = `${lat},${long}`;
            }
            if (
              master_admin.filter((element) => element._id === user._id)
                .length <= 0
            ) {
              if (element?.location?.coordinates?.[0] !== 0) {
                location = "YES";
              } else {
                location = "NO";
              }
            }
            tempData.push([
              element._id,
              element["ALAMAT"] ?? "-",
              element["Depo"] ?? "-",
              element["ID Outlet"] ?? "-",
              element["MONTH_NUMBER"] ?? "-",
              element["Nama Toko"] ?? "-",
              element["Plan"] ?? "-",
              element["Type Outlet"] ?? "-",
              parseFloat(element["VALUE"]).toString().replace(/\./g, ","),
              parseFloat(element["QTY"]).toString().replace(/\./g, ","),
              element["YEAR_NUMBER"] ?? "-",
              location ?? "-",
              rayon_name ?? "-",
              element["PROPINSI"] ?? "-",
              element["KABUPATEN"] ?? "-",
              element["KECAMATAN"] ?? "-",
              element["KELURAHAN"] ?? "-",
              element["principle"] ?? "-",
            ]);
          }
        }
        if (this.state.modeClick === "bisnis_unit") {
          const header = [
            "_id",
            "ADDRESS",
            "DEPO",
            "ID OUTLET",
            "MONTH NUMBER",
            "STORE NAME",
            "PLAN",
            "TYPE OUTLET",
            "VALUE",
            "QTY",
            "YEAR",
            "LOCATION",
            "RAYON",
            "PROVINCE",
            "CITY/REGENCY",
            "DISTRICT",
            "SUBDISTRICT",
            "PRINCIPAL",
            "BUSINESS UNIT",
          ];
          tempData.push(header);
          for (let index = 0; index < omset_list.length; index++) {
            const element = omset_list[index];
            const { user, master_admin } = this.props.auth;
            const { rayon_list_database } = this.props.data;
            let location = "";
            let rayon_name = "Rayon Not Found";
            const rayon_match = rayon_list_database.find(
              (x) => x._id === omset_list[index].rayon_id
            );
            if (rayon_match) {
              rayon_name = rayon_match.name ?? "Rayon Not Found";
            }
            if (element?.location?.coordinates?.[0] !== 0) {
              const long = element.location.coordinates[0];
              const lat = element.location.coordinates[1];
              location = `${lat},${long}`;
            }
            if (
              master_admin.filter((element) => element._id === user._id)
                .length <= 0
            ) {
              if (element?.location?.coordinates?.[0] !== 0) {
                location = "YES";
              } else {
                location = "NO";
              }
            }
            tempData.push([
              element._id,
              element["ALAMAT"] ?? "-",
              element["Depo"] ?? "-",
              element["ID Outlet"] ?? "-",
              element["MONTH_NUMBER"] ?? "-",
              element["Nama Toko"] ?? "-",
              element["Plan"] ?? "-",
              element["Type Outlet"] ?? "-",
              parseFloat(element["VALUE"]).toString().replace(/\./g, ","),
              parseFloat(element["QTY"]).toString().replace(/\./g, ","),
              element["YEAR_NUMBER"] ?? "-",
              location ?? "-",
              rayon_name ?? "-",
              element["PROPINSI"] ?? "-",
              element["KABUPATEN"] ?? "-",
              element["KECAMATAN"] ?? "-",
              element["KELURAHAN"] ?? "-",
              element["principle"] ?? "-",
              element["business_unit"] ?? "-",
            ]);
          }
        }
        if (this.state.modeClick === "brand") {
          const header = [
            "_id",
            "ADDRESS",
            "DEPO",
            "ID OUTLET",
            "MONTH NUMBER",
            "STORE NAME",
            "PLAN",
            "TYPE OUTLET",
            "VALUE",
            "QTY",
            "YEAR",
            "LOCATION",
            "RAYON",
            "PROVINCE",
            "CITY/REGENCY",
            "DISTRICT",
            "SUBDISTRICT",
            "PRINCIPAL",
            "BUSINESS UNIT",
            "BRAND",
          ];
          tempData.push(header);
          for (let index = 0; index < omset_list.length; index++) {
            const element = omset_list[index];
            const { user, master_admin } = this.props.auth;
            const { rayon_list_database } = this.props.data;
            let location = "";
            let rayon_name = "Rayon Not Found";
            const rayon_match = rayon_list_database.find(
              (x) => x._id === omset_list[index].rayon_id
            );
            if (rayon_match) {
              rayon_name = rayon_match.name ?? "Rayon Not Found";
            }
            if (element?.location?.coordinates?.[0] !== 0) {
              const long = element.location.coordinates[0];
              const lat = element.location.coordinates[1];
              location = `${lat},${long}`;
            }
            if (
              master_admin.filter((element) => element._id === user._id)
                .length <= 0
            ) {
              if (element?.location?.coordinates?.[0] !== 0) {
                location = "YES";
              } else {
                location = "NO";
              }
            }
            tempData.push([
              element._id,
              element["ALAMAT"] ?? "-",
              element["Depo"] ?? "-",
              element["ID Outlet"] ?? "-",
              element["MONTH_NUMBER"] ?? "-",
              element["Nama Toko"] ?? "-",
              element["Plan"] ?? "-",
              element["Type Outlet"] ?? "-",
              parseFloat(element["VALUE"]).toString().replace(/\./g, ","),
              parseFloat(element["QTY"]).toString().replace(/\./g, ","),
              element["YEAR_NUMBER"] ?? "-",
              location ?? "-",
              rayon_name ?? "-",
              element["PROPINSI"] ?? "-",
              element["KABUPATEN"] ?? "-",
              element["KECAMATAN"] ?? "-",
              element["KELURAHAN"] ?? "-",
              element["principle"] ?? "-",
              element["business_unit"] ?? "-",
              element["brand"] ?? "-",
            ]);
          }
        }
        if (this.state.modeClick === "product") {
          const header = [
            "_id",
            "ADDRESS",
            "DEPO",
            "ID OUTLET",
            "MONTH NUMBER",
            "STORE NAME",
            "PLAN",
            "TYPE OUTLET",
            "VALUE",
            "QTY",
            "YEAR",
            "LOCATION",
            "RAYON",
            "PROVINCE",
            "CITY/REGENCY",
            "DISTRICT",
            "SUBDISTRICT",
            "PRINCIPAL",
            "BUSINESS UNIT",
            "BRAND",
            "PRODUCT ID",
            "PRODUCT",
          ];
          tempData.push(header);
          for (let index = 0; index < omset_list.length; index++) {
            const element = omset_list[index];
            const { user, master_admin } = this.props.auth;
            const { rayon_list_database } = this.props.data;
            let location = "";
            let rayon_name = "Rayon Not Found";
            const rayon_match = rayon_list_database.find(
              (x) => x._id === omset_list[index].rayon_id
            );
            if (rayon_match) {
              rayon_name = rayon_match.name ?? "Rayon Not Found";
            }
            if (element?.location?.coordinates?.[0] !== 0) {
              const long = element.location.coordinates[0];
              const lat = element.location.coordinates[1];
              location = `${lat},${long}`;
            }
            if (
              master_admin.filter((element) => element._id === user._id)
                .length <= 0
            ) {
              if (element?.location?.coordinates?.[0] !== 0) {
                location = "YES";
              } else {
                location = "NO";
              }
            }
            tempData.push([
              element._id,
              element["ALAMAT"] ?? "-",
              element["Depo"] ?? "-",
              element["ID Outlet"] ?? "-",
              element["MONTH_NUMBER"] ?? "-",
              element["Nama Toko"] ?? "-",
              element["Plan"] ?? "-",
              element["Type Outlet"] ?? "-",
              parseFloat(element["VALUE"]).toString().replace(/\./g, ","),
              parseFloat(element["QTY"]).toString().replace(/\./g, ","),
              element["YEAR_NUMBER"] ?? "-",
              location ?? "-",
              rayon_name ?? "-",
              element["PROPINSI"] ?? "-",
              element["KABUPATEN"] ?? "-",
              element["KECAMATAN"] ?? "-",
              element["KELURAHAN"] ?? "-",
              element["principle"] ?? "-",
              element["business_unit"] ?? "-",
              element["brand"] ?? "-",
              element["product_parent_id"] ?? "-",
              element["product_parent_name"] ?? "-",
            ]);
          }
        }
        if (this.state.modeClick === "") {
          const header = [
            "_id",
            "ADDRESS",
            "DEPO",
            "ID OUTLET",
            "MONTH NUMBER",
            "STORE NAME",
            "PLAN",
            "TYPE OUTLET",
            "VALUE",
            "QTY",
            "YEAR",
            "LOCATION",
            "RAYON",
            "PROVINCE",
            "CITY/REGENCY",
            "DISTRICT",
            "SUBDISTRICT",
          ];
          tempData.push(header);
          for (let index = 0; index < omset_list.length; index++) {
            const element = omset_list[index];
            const { user, master_admin } = this.props.auth;
            const { rayon_list_database } = this.props.data;
            let location = "";
            let rayon_name = "Rayon Not Found";
            const rayon_match = rayon_list_database.find(
              (x) => x._id === omset_list[index].rayon_id
            );
            if (rayon_match) {
              rayon_name = rayon_match.name ?? "Rayon Not Found";
            }
            if (element?.location?.coordinates?.[0] !== 0) {
              const long = element.location.coordinates[0];
              const lat = element.location.coordinates[1];
              location = `${lat},${long}`;
            }
            if (
              master_admin.filter((element) => element._id === user._id)
                .length <= 0
            ) {
              if (element?.location?.coordinates?.[0] !== 0) {
                location = "YES";
              } else {
                location = "NO";
              }
            }
            tempData.push([
              element._id,
              element["ALAMAT"] ?? "-",
              element["Depo"] ?? "-",
              element["ID Outlet"] ?? "-",
              element["MONTH_NUMBER"] ?? "-",
              element["Nama Toko"] ?? "-",
              element["Plan"] ?? "-",
              element["Type Outlet"] ?? "-",
              parseFloat(element["VALUE"]).toString().replace(/\./g, ","),
              parseFloat(element["QTY"]).toString().replace(/\./g, ","),
              element["YEAR_NUMBER"] ?? "-",
              location ?? "-",
              rayon_name ?? "-",
              element["PROPINSI"] ?? "-",
              element["KABUPATEN"] ?? "-",
              element["KECAMATAN"] ?? "-",
              element["KELURAHAN"] ?? "-",
            ]);
          }
        }
        this.setState({
          loading_csv: false,
          dataCSV: tempData,
        });
      } catch (error) {
        this.setState({
          loading: false,
        });
      }
    }
  };

  generateDataEXCEL = async (omset_list) => {
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });
      try {
        const schema = [
          {
            column: "_id",
            type: String,
            value: (target) => target._id,
          },
          {
            column: "DEPO",
            type: String,
            value: (target) => target.Depo,
          },
          {
            column: "ID OUTLET",
            type: String,
            value: (target) => target["ID Outlet"],
          },
          {
            column: "MONTH NUMBER",
            type: String,
            value: (target) => target.MONTH_NUMBER,
          },
          {
            column: "STORE NAME",
            type: String,
            value: (target) => target["Nama Toko"],
          },
          {
            column: "PLAN",
            type: String,
            value: (target) => target.Plan,
          },
          {
            column: "TYPE OUTLET",
            type: String,
            value: (target) => target["Type Outlet"],
          },
          {
            column: "VALUE",
            type: String,
            value: (target) => target.VALUE,
          },
          {
            column: "QTY",
            type: String,
            value: (target) => target.qty,
          },
          {
            column: "RANGE LEGENDA",
            type: String,
            value: (target) => target.legenda,
          },
          {
            column: "YEAR",
            type: String,
            value: (target) => target.YEAR_NUMBER,
          },
          {
            column: "LOCATION",
            type: String,
            value: (target) => target.location,
          },
          {
            column: "Rayon",
            type: String,
            value: (target) => target.rayon_name,
          },
          {
            column: "PROVINCE",
            type: String,
            value: (target) => target.propinsi,
          },
          {
            column: "CITY/REGENCY",
            type: String,
            value: (target) => target.kabupaten,
          },
          {
            column: "DISTRICT",
            type: String,
            value: (target) => target.kecamatan,
          },
          {
            column: "SUBDISTRICT",
            type: String,
            value: (target) => target.kelurahan,
          },
          {
            column: "PRINCIPAL",
            type: String,
            value: (target) => target.principle,
          },
          {
            column: "BUSINESS UNIT",
            type: String,
            value: (target) => target.category,
          },
          {
            column: "BRAND",
            type: String,
            value: (target) => target.brand,
          },
          {
            column: "PRODUCT ID",
            type: String,
            value: (target) => target.productId,
          },
          {
            column: "PRODUCT",
            type: String,
            value: (target) => target.product,
          },
        ];
        let newData = [];

        omset_list.forEach((e, index) => {
          const { user, master_admin } = this.props.auth;
          const { rayon_list_database } = this.props.data;

          let location = "";
          let principle = "";
          let category = "";
          let brand = "";
          let product = "";
          let productId = "";
          let rayon_name = "Rayon Not Found";
          let valueLegenda = "0";
          const rayon_match = rayon_list_database.find(
            (x) => x._id === omset_list[index].rayon_id
          );
          if (rayon_match) {
            rayon_name = rayon_match.name ?? "Rayon Not Found";
          }
          if (e?.location?.coordinates?.[0] !== 0) {
            const long = e.location.coordinates[0];
            const lat = e.location.coordinates[1];
            location = `${lat},${long}`;
          }
          if (master_admin.filter((e) => e._id === user._id).length <= 0) {
            if (e?.location?.coordinates?.[0] !== 0) {
              location = "YES";
            } else {
              location = "NO";
            }
          }
          if (this.state.modeClick === "principle") {
            principle = e.principle;
          }
          if (this.state.modeClick === "bisnis_unit") {
            principle = e.principle;
            category = e.business_unit;
          }
          if (this.state.modeClick === "brand") {
            principle = e.principle;
            category = e.business_unit;
            brand = e.brand;
          }
          if (this.state.modeClick === "product") {
            principle = e.principle;
            category = e.business_unit;
            brand = e.brand;
            product = e.product_parent_name;
            productId = e.product_parent_id;
          }
          if (e.VALUE < 100000) {
            valueLegenda = "0 - 100.000";
          } else if (e.VALUE < 200000) {
            valueLegenda = "100.000 - 200.000";
          } else if (e.VALUE < 300000) {
            valueLegenda = "200.000 - 300.000";
          } else if (e.VALUE < 400000) {
            valueLegenda = "300.000 - 400.000";
          } else if (e.VALUE < 2000000) {
            valueLegenda = "500.000 - 2.000.000";
          } else if (e.VALUE < 30000000) {
            valueLegenda = "2.000.000 - 30.000.000";
          } else if (e.VALUE >= 30000000) {
            valueLegenda = "> 30.000.000";
          }
          let getData = {
            _id: e._id,
            Depo: e.Depo,
            "ID Outlet": e["ID Outlet"],
            MONTH_NUMBER: `${e.MONTH_NUMBER}`,
            "Nama Toko": e["Nama Toko"],
            Plan: e.Plan,
            "Type Outlet": e["Type Outlet"],
            VALUE: `${parseFloat(e.VALUE).toString().replace(/\./g, ",")}`,
            qty: `${parseFloat(e.QTY).toString().replace(/\./g, ",")}`,
            legenda: valueLegenda,
            YEAR_NUMBER: `${e.YEAR_NUMBER}`,
            location: location,
            rayon_name: rayon_name,
            propinsi: e.PROPINSI,
            kabupaten: e.KABUPATEN,
            kecamatan: e.KECAMATAN,
            kelurahan: e.KELURAHAN,
            principle: principle,
            category: category,
            brand: brand,
            product: product,
            productId: productId,
          };
          newData.push(getData);
        });
        await writeXlsxFile(newData, {
          schema,
          fileName: "DataExcelSalesMapping.xlsx",
        });
        this.setState({
          loading_csv: false,
        });
      } catch (error) {
        console.log(error);
        this.setState({
          loading: false,
        });
      }
    }
  };

  changeOption = (e) => {
    this.setState({ optionValue: e.target.value });
  };

  changeMode = (e) => {
    this.setState({ selectMode: e.target.value });
    this.props.resetMode();
  };

  filterCollor = (e) => {
    let data = { start: e.start, end: e.end };
    this.props.filterCollor(data);
  };

  filterCollorSalesman = (e) => {
    let data = { start: e.start, end: e.end };
    this.props.filterCollorSalesman(data);
  };

  reset = (event) => {
    this.handleSubmit(event);
  };

  filterCollorTableBusisness = (e) => {
    this.setState({ modeClick: "bisnis_unit" });
    const { MONTH_NUMBER, YEAR_NUMBER } = this.state;
    let MONTH = `${parseInt(MONTH_NUMBER) + 1}`;
    if (MONTH.length < 2) {
      MONTH = 0 + MONTH;
    }
    const { salesman_filter } = this.props.data;
    const { selectArea, selectedType } = this.state;
    let result_arr = [];
    if (this.state.selectArea === "Depo") {
      this.state.selectedType.forEach((e) => {
        let result = salesman_filter.depo.filter((state) => {
          const depo = new RegExp(`${e}`, "gi");
          return state.Depo.match(depo);
        });
        result.map((e) => ({ depo: e.Depo, plan_id: e.plan_id }));
        result_arr = result_arr.concat(result);
      });
    }
    if (selectArea === "Branch") {
      let depo = salesman_filter.depo.map((e) => e);
      let query = selectedType.map((e) => e);
      query.forEach((e) => {
        let match = depo.filter((i) => i.CABANG === e);
        match = match.map((e) => ({ Depo: e.Depo, plan_id: e.plan_id }));
        result_arr = result_arr.concat(match);
      });
    }
    if (selectArea === "Region") {
      let depo = salesman_filter.depo.map((e) => e);
      let query = selectedType.map((e) => e);
      query.forEach((e) => {
        let match = depo.filter((i) => i.REGION === e);
        match = match.map((e) => ({ Depo: e.Depo, plan_id: e.plan_id }));
        result_arr = result_arr.concat(match);
      });
    }
    const params = {
      MONTH_NUMBER: MONTH,
      YEAR_NUMBER,
      AREA: result_arr.map((e) => e.Depo),
      plan_id: result_arr.map((e) => e.plan_id),
      brand: e.BRAND,
    };
    this.props.get_business_unit(params, this.state.selectMode);
  };

  filterCollorTableBrand = (e) => {
    this.setState({ modeClick: "brand" });
    const { MONTH_NUMBER, YEAR_NUMBER } = this.state;
    let MONTH = `${parseInt(MONTH_NUMBER) + 1}`;
    if (MONTH.length < 2) {
      MONTH = 0 + MONTH;
    }
    const { salesman_filter } = this.props.data;
    const { selectArea, selectedType } = this.state;
    let result_arr = [];
    if (this.state.selectArea === "Depo") {
      this.state.selectedType.forEach((e) => {
        let result = salesman_filter.depo.filter((state) => {
          const depo = new RegExp(`${e}`, "gi");
          return state.Depo.match(depo);
        });
        result.map((e) => ({ depo: e.Depo, plan_id: e.plan_id }));
        result_arr = result_arr.concat(result);
      });
    }
    if (selectArea === "Branch") {
      let depo = salesman_filter.depo.map((e) => e);
      let query = selectedType.map((e) => e);
      query.forEach((e) => {
        let match = depo.filter((i) => i.CABANG === e);
        match = match.map((e) => ({ Depo: e.Depo, plan_id: e.plan_id }));
        result_arr = result_arr.concat(match);
      });
    }
    if (selectArea === "Region") {
      let depo = salesman_filter.depo.map((e) => e);
      let query = selectedType.map((e) => e);
      query.forEach((e) => {
        let match = depo.filter((i) => i.REGION === e);
        match = match.map((e) => ({ Depo: e.Depo, plan_id: e.plan_id }));
        result_arr = result_arr.concat(match);
      });
    }
    const params = {
      MONTH_NUMBER: MONTH,
      YEAR_NUMBER,
      AREA: result_arr.map((e) => e.Depo),
      plan_id: result_arr.map((e) => e.plan_id),
      brand: e.BRAND,
    };
    this.props.get_brand_unit(params, this.state.selectMode);
  };

  filterCollorTableProduct = (e) => {
    this.setState({ modeClick: "product" });
    const { MONTH_NUMBER, YEAR_NUMBER } = this.state;
    let MONTH = `${parseInt(MONTH_NUMBER) + 1}`;
    if (MONTH.length < 2) {
      MONTH = 0 + MONTH;
    }
    const { salesman_filter } = this.props.data;
    const { selectArea, selectedType } = this.state;
    let result_arr = [];
    if (this.state.selectArea === "Depo") {
      this.state.selectedType.forEach((e) => {
        let result = salesman_filter.depo.filter((state) => {
          const depo = new RegExp(`${e}`, "gi");
          return state.Depo.match(depo);
        });
        result.map((e) => ({ depo: e.Depo, plan_id: e.plan_id }));
        result_arr = result_arr.concat(result);
      });
    }
    if (selectArea === "Branch") {
      let depo = salesman_filter.depo.map((e) => e);
      let query = selectedType.map((e) => e);
      query.forEach((e) => {
        let match = depo.filter((i) => i.CABANG === e);
        match = match.map((e) => ({ Depo: e.Depo, plan_id: e.plan_id }));
        result_arr = result_arr.concat(match);
      });
    }
    if (selectArea === "Region") {
      let depo = salesman_filter.depo.map((e) => e);
      let query = selectedType.map((e) => e);
      query.forEach((e) => {
        let match = depo.filter((i) => i.REGION === e);
        match = match.map((e) => ({ Depo: e.Depo, plan_id: e.plan_id }));
        result_arr = result_arr.concat(match);
      });
    }
    const params = {
      MONTH_NUMBER: MONTH,
      YEAR_NUMBER,
      AREA: result_arr.map((e) => e.Depo),
      plan_id: result_arr.map((e) => e.plan_id),
      brand: e.BRAND,
    };
    this.props.get_product_unit(params, this.state.selectMode);
  };

  filterCollorTablePrinciple = (e) => {
    this.setState({ modeClick: "principle" });
    const { MONTH_NUMBER, YEAR_NUMBER } = this.state;
    let MONTH = `${parseInt(MONTH_NUMBER) + 1}`;
    if (MONTH.length < 2) {
      MONTH = 0 + MONTH;
    }
    const { salesman_filter } = this.props.data;
    const { selectArea, selectedType } = this.state;
    let result_arr = [];
    if (this.state.selectArea === "Depo") {
      this.state.selectedType.forEach((e) => {
        let result = salesman_filter.depo.filter((state) => {
          const depo = new RegExp(`${e}`, "gi");
          return state.Depo.match(depo);
        });
        result.map((e) => ({ depo: e.Depo, plan_id: e.plan_id }));
        result_arr = result_arr.concat(result);
      });
    }
    if (selectArea === "Branch") {
      let depo = salesman_filter.depo.map((e) => e);
      let query = selectedType.map((e) => e);
      query.forEach((e) => {
        let match = depo.filter((i) => i.CABANG === e);
        match = match.map((e) => ({ Depo: e.Depo, plan_id: e.plan_id }));
        result_arr = result_arr.concat(match);
      });
    }
    if (selectArea === "Region") {
      let depo = salesman_filter.depo.map((e) => e);
      let query = selectedType.map((e) => e);
      query.forEach((e) => {
        let match = depo.filter((i) => i.REGION === e);
        match = match.map((e) => ({ Depo: e.Depo, plan_id: e.plan_id }));
        result_arr = result_arr.concat(match);
      });
    }
    const params = {
      MONTH_NUMBER: MONTH,
      YEAR_NUMBER,
      AREA: result_arr.map((e) => e.Depo),
      plan_id: result_arr.map((e) => e.plan_id),
      brand: e.BRAND,
    };
    this.props.get_principle_unit(params, this.state.selectMode);
  };

  handle_change = (event) => {
    this.setState({ selectArea: event.value });
  };

  handle_change_type = (event) => {
    let extract = event.map((e) => e.value);
    this.setState({ selectedType: extract, selectedForSalesman: event });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { salesman_filter } = this.props.data;
    const { selectArea, selectedType, selectedForSalesman } = this.state;
    let result_arr = [];
    if (selectArea === "Depo") {
      selectedType.forEach((e) => {
        let match = salesman_filter.depo.filter((i) => i.Depo === e);
        match = match.map((e) => ({ depo: e.Depo, plan_id: e.plan_id }));
        result_arr = result_arr.concat(match);
      });
    }
    if (selectArea === "Branch") {
      let depo = salesman_filter.depo.map((e) => e);
      let query = selectedType.map((e) => e);
      query.forEach((e) => {
        let match = depo.filter((i) => i.CABANG === e);
        match = match.map((e) => ({ depo: e.Depo, plan_id: e.plan_id }));
        result_arr = result_arr.concat(match);
      });
    }
    if (selectArea === "Region") {
      let depo = salesman_filter.depo.map((e) => e);
      let query = selectedType.map((e) => e);
      query.forEach((e) => {
        let match = depo.filter((i) => i.REGION === e);
        match = match.map((e) => ({ depo: e.Depo, plan_id: e.plan_id }));
        result_arr = result_arr.concat(match);
      });
    }
    //get api
    const { MONTH_NUMBER, YEAR_NUMBER, selectedShop } = this.state;
    const params = {
      MONTH_NUMBER,
      YEAR_NUMBER,
      AREA: result_arr.map((e) => e.depo),
      plan_id: result_arr.map((e) => e.plan_id),
      type: selectedShop,
    };

    this.props.get_omset(params);
    //turn on menu & set config
    let name_array = this.props.data.list_menu_active.find(
      (e) => e.key === "omset"
    )?.config?.name_array;
    name_array.push(params);
    this.props.turn_on_menu({ key: "omset", config: { name_array } });

    //conclusion
    let MONTH = `${parseInt(MONTH_NUMBER) + 1}`;
    if (MONTH.length < 2) {
      MONTH = 0 + MONTH;
    }

    const paramsIcon = {
      MONTH,
      YEAR_NUMBER,
      AREA: result_arr.map((e) => e.depo),
      plan_id: result_arr.map((e) => e.plan_id),
      key: "Depo",
      value: selectedForSalesman,
    };
    this.props.getSalesmanOmset(paramsIcon);
    this.props.getDate(paramsIcon);
    this.props.turn_on_menu({ key: "omset_salesman", config: { paramsIcon } });
    const paramCons = {
      MONTH,
      YEAR_NUMBER,
      AREA: result_arr.map((e) => e.depo),
      plan_id: result_arr.map((e) => e.plan_id),
    };
    this.props.getConclusion(paramCons);
    this.setState({ showTable: true, throwParams: params });
  };

  changeShop = (event) => {
    let extract = event.map((e) => e.value);
    this.setState({ selectedShop: extract });
  };

  handleSearch(e) {
    this.setState({ searchToko: e.target.value });
  }
  handleSearchPrinciple(e) {
    this.setState({ searchPrinciple: e.target.value });
  }
  handleSearchBu(e) {
    this.setState({ searchBu: e.target.value });
  }
  handleSearchBrands(e) {
    this.setState({ searchBrands: e.target.value });
  }
  handleSearchProducts(e) {
    this.setState({ searchProducts: e.target.value });
  }

  findSearch = (event) => {
    event.preventDefault();
    const { searchToko } = this.state;
    this.props.searchShop(searchToko);
  };

  findSearchPrinciple = (event) => {
    event.preventDefault();
    const { searchPrinciple } = this.state;
    this.props.searchPrinciple(searchPrinciple);
  };

  findSearchBu = (event) => {
    event.preventDefault();
    const { searchBu } = this.state;
    this.props.searchBu(searchBu);
  };

  findSearchBrands = (event) => {
    event.preventDefault();
    const { searchBrands } = this.state;
    this.props.searchBrands(searchBrands);
  };

  findSearchProducts = (event) => {
    event.preventDefault();
    const { searchProducts } = this.state;
    this.props.searchProducts(searchProducts);
  };

  buttonSelectedMenu = (menu) => {
    this.setState({ selectedMenu: menu });
  };

  clickAction = (event) => {
    this.props.popUpAction(event, "modal_omset_salesman");
  };

  render() {
    //localstorage

    //local state
    let {
      MONTH_NUMBER,
      YEAR_NUMBER,
      // depo,
      loading_csv,
      separator,
      dataCSV,
      selectArea,
      searchToko,
      searchPrinciple,
      searchBu,
      searchBrands,
      searchProducts,
      showTable,
      // selectedType,
      throwParams,
    } = this.state;

    //global props
    const {
      loading_status,
      loading_item,
      omset_list,
      dataBusiness,
      dataBrand,
      dataProduction,
      dataPrinciple,
      get_omset_salesman,
    } = this.props.data;
    const { salesman_filter, listTypeToko } = this.props.data;

    //content
    const month_list = [
      "Januari",
      "Febuary",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const year_list = [2020, 2021, 2022, 2023, 2024];
    let button_content = (
      <>
        <button
          type="submit"
          className="button"
          id="green"
          style={{ marginBottom: "20px" }}
        >
          Get Data
        </button>
      </>
    );
    let tab_nav;
    if (omset_list.length > 0) {
      tab_nav = (
        <div>
          <div>
            <BloopProduct throwParams={throwParams} />
          </div>
          <div className="mb-2 grid grid-cols-2">
            <button
              className={`w-full py-2 border-b-4 hover:bg-gray-50 ${
                this.state.selectedMenu === "omset_table"
                  ? "border-green-500"
                  : "border-gray-200"
              }`}
              onClick={this.buttonSelectedMenu.bind(this, "omset_table")}
            >
              <div className="flex items-center justify-center gap-2">
                OUTLET OMSET
              </div>
            </button>
            <button
              className={`w-full py-2 border-b-4 hover:bg-gray-50 ${
                this.state.selectedMenu === "omset_salesman"
                  ? "border-green-500"
                  : "border-gray-200"
              }`}
              onClick={this.buttonSelectedMenu.bind(this, "omset_salesman")}
            >
              <div className="flex items-center justify-center gap-2">
                SALESMAN OMSET
              </div>
            </button>
          </div>
        </div>
      );
    }

    if (loading_status && loading_item === "get_omset") {
      button_content = (
        <div style={{ marginBottom: "20px" }}>
          <Spinner />
        </div>
      );
    } else if (this.state.selectedType.length === 0) {
      button_content = (
        <div className="button_main" id="grey" style={{ marginBottom: "20px" }}>
          Choose a {this.state.selectArea} Points
        </div>
      );
    }

    let button_slice;
    if (omset_list.length > 0) {
      button_slice = (
        <div className="flex items-center justify-center w-full">
          <button
            className="button_main mt-5"
            onClick={() => {
              let number = this.state.list_column + 50;
              this.setState({ list_column: number });
            }}
          >
            Load More Datas
          </button>
        </div>
      );
    }

    if (this.state.list_column > omset_list.length) {
      button_slice = (
        <div className="flex items-center justify-center mt-5">
          All Data Has Been Loaded
        </div>
      );
    }

    let main_content;
    if (showTable) {
      if (omset_list.length === 0) {
        main_content = <main style={{ marginBottom: "20px" }}>No data</main>;
      }
      main_content = (
        <main style={{ marginBottom: "20px" }}>
          <table className="table_lite" style={{ fontSize: "14px" }}>
            <thead>
              <tr>
                <th style={{ width: "10px" }}>No</th>
                <th>Store Name</th>
                <th>Revenue</th>
                <th>ID Outlet</th>
              </tr>
            </thead>
            <tbody>
              {omset_list.slice(0, this.state.list_column).map((omset, idx) => {
                let omset_salesman = parseInt(omset.VALUE);
                omset_salesman = omset_salesman.toFixed(1);
                omset_salesman = parseFloat(omset_salesman);
                let button_fly = (
                  <ON_FLY item={omset} modal_item="modal_omset" />
                  // <button
                  //   className="button_small"
                  //   id="green"
                  //   onClick={() => this.props.popUpAction(omset, "modal_omset")}
                  // >
                  //   View on map
                  // </button>
                );
                if (!omset?.location?.coordinates?.[0]) {
                  button_fly = (
                    <button className="button" id="red">
                      No location
                    </button>
                  );
                }
                return (
                  <tr key={idx}>
                    <td style={{ width: "10px" }}>{idx + 1}</td>
                    <td>
                      {omset?.["Nama Toko"]} <br />
                      {button_fly}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      Rp{" "}
                      {Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(omset_salesman)}
                    </td>
                    <td>
                      {omset?.["ID Outlet"]} <br />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {button_slice}
        </main>
      );
    }

    let searchId;
    if (showTable) {
      searchId = (
        <form onSubmit={this.findSearch}>
          <div className="flex gap-1">
            <div className="w-full mb-2">
              <p>Filter Store Name or ID Outlet</p>
              <input
                type="text"
                className="w-full"
                placeholder={`Search Store Name or ID Outlet`}
                onChange={this.handleSearch.bind(this)}
                value={searchToko}
                autoComplete="off"
              />
            </div>
            <button className="px-1" type="sumbit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </button>
          </div>
        </form>
      );
    }

    const array_color = [
      {
        name: "0-100.000",
        color: "#000000",
        start: 0,
        end: 100000,
      },
      {
        name: "100.000-200.000",
        color: "#bc2023",
        start: 100000,
        end: 200000,
      },
      {
        name: "200.000-300.000",
        color: "#eb442c",
        start: 200000,
        end: 300000,
      },
      {
        name: "300.000-400.000",
        color: "#f8b324",
        start: 300000,
        end: 400000,
      },
      {
        name: "400.000-500.000",
        color: "#b0ae2d",
        start: 400000,
        end: 500000,
      },
      {
        name: "500.000-2.000.000",
        color: "#4da83a",
        start: 500000,
        end: 2000000,
      },
      {
        name: "2.000.000-30.000.000",
        color: "#069fff",
        start: 2000000,
        end: 30000000,
      },
      {
        name: ">30.000.000",
        color: "#2806ff",
        start: 30000000,
        end: 100000000,
      },
    ];

    let legenda_content;

    if (showTable) {
      legenda_content = (
        <main>
          <p className="text_bold">Legend (Revenue)</p>
          <div
            className="cursor-pointer"
            onClick={this.filterCollor.bind(this, {
              start: 0,
              end: 1000_000_000_000,
            })}
          >
            <div
              style={{
                width: "15px",
                height: "15px",
                backgroundColor: "#FFF",
                display: "inline-block",
                marginRight: "5px",
                border: "solid #000",
              }}
            />
            All
          </div>
          {array_color.map(({ name, color, start, end }, idx) => {
            return (
              <main
                className="cursor-pointer"
                key={idx}
                onClick={this.filterCollor.bind(this, { start, end })}
              >
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: color,
                    display: "inline-block",
                    marginRight: "5px",
                  }}
                />
                {name}
              </main>
            );
          })}

          {omset_list.length > 0 && (
            <>
              <button className="button" id="green" onClick={this.reset}>
                Reset
              </button>
              <div className="my-2">
                <button
                  className="button"
                  id="green"
                  onClick={() => {
                    this.setState({ downloadOutlet: true });
                  }}
                >
                  Download Omset
                </button>
              </div>
            </>
          )}
          <table className="table_lite mb-2">
            <thead>
              <tr>
                <th>Depo Name</th>
                <th>Total Store Mongos</th>
                <th>Total Store PostGress</th>
                <th>Total Quantity</th>
                <th>Total Revenue</th>
              </tr>
            </thead>
            <tbody>
              {this.props.data.getDataBaseOmset.map((e, idx) => {
                let qty = parseFloat(e.qty);
                let value = parseFloat(e.value);
                return (
                  <tr className="text-center" key={idx}>
                    <td className="text-center">{e.depo}</td>
                    <td className="text-center">{e.toko_mongos}</td>
                    <td className="text-center">{e.toko_postgress}</td>
                    <td className="text-center">
                      {Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(qty)}
                    </td>
                    <td className="text-center">
                      {Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(value)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      );
    }

    let legenda_content_salesman;
    if (showTable) {
      legenda_content_salesman = (
        <main>
          <p className="text_bold">Legend (Revenue)</p>
          <div
            className="cursor-pointer"
            onClick={this.filterCollorSalesman.bind(this, {
              start: 0,
              end: 1000_000_000_000,
            })}
          >
            <div
              style={{
                width: "15px",
                height: "15px",
                backgroundColor: "#FFF",
                display: "inline-block",
                marginRight: "5px",
                border: "solid #000",
              }}
            />
            All
          </div>
          {array_color.map(({ name, color, start, end }, idx) => {
            return (
              <main
                className="cursor-pointer"
                key={idx}
                onClick={this.filterCollorSalesman.bind(this, { start, end })}
              >
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: color,
                    display: "inline-block",
                    marginRight: "5px",
                  }}
                />
                {name}
              </main>
            );
          })}
        </main>
      );
    }

    const modalDownloadOutlet = this.state.downloadOutlet && (
      <Modal
        modalSize="small"
        isOpen={this.state.downloadOutlet}
        onClose={() =>
          this.setState({ downloadOutlet: !this.state.downloadOutlet })
        }
      >
        <div className="box-body">
          <h1 className="text-center font-bold">Download Outlet by CSV</h1>
          <section className={`flex justify-center mb-3`}>
            <CSVLink
              className="button_main"
              data={dataCSV}
              separator={separator}
              filename={`Sales Mapping Data.csv`}
              onClick={(e, done) => {
                this.generateDataCSV(omset_list, done);
              }}
            >
              {loading_csv ? "Loading ..." : "Download Outlet CSV"}
            </CSVLink>
          </section>
          <h1 className="text-center font-bold">Download Outlet by EXCEL</h1>
          <section className={`flex justify-center`}>
            <button
              className="button_main"
              onClick={(e, done) => {
                this.generateDataEXCEL(omset_list, done);
              }}
            >
              {loading_csv ? "Loading ..." : "Download Outlet EXCEL"}
            </button>
          </section>
        </div>
      </Modal>
    );

    const area_list = [
      { key: "depo", label: "Depo", value: "Depo", db: "Depo", name: "area" },
      {
        key: "branch",
        label: "Branch",
        value: "Branch",
        db: "CABANG",
        name: "area",
      },
      {
        key: "regional",
        label: "Region",
        value: "Region",
        db: "REGIONAL",
        name: "area",
      },
    ];
    let newDepo = [];
    let newCabang = [];
    let newRegional = [];
    let newType = [{ label: "All Store", value: "" }];
    salesman_filter.depo.map((e) => {
      let newData = { label: e.Depo, value: e.Depo };
      return newDepo.push(newData);
    });
    salesman_filter.cabang.map((e) => {
      let newData = { label: e.CABANG, value: e.CABANG };
      return newCabang.push(newData);
    });
    salesman_filter.regional.map((e) => {
      let newData = { label: e.REGIONAL, value: e.REGIONAL };
      return newRegional.push(newData);
    });
    listTypeToko.map((e) => {
      let newData = { label: e.text, value: e.text, type: e.type };
      return newType.push(newData);
    });

    let selectTypeShop;
    if (this.state.selectedType.length > 0) {
      selectTypeShop = (
        <div className="mt-3">
          <p>Select Store Type</p>
          <Select options={newType} isMulti onChange={this.changeShop} />
        </div>
      );
    }
    let select_content;
    if (selectArea === "Depo") {
      select_content = (
        <section className="mb-4">
          <p>
            Select {""}
            {selectArea === "" ? "Detail Area" : selectArea}
          </p>
          <Select
            options={newDepo}
            isMulti
            onChange={this.handle_change_type}
          />
          {selectTypeShop}
        </section>
      );
    }
    if (selectArea === "Branch") {
      select_content = (
        <section className="mb-4">
          <p>
            Select {""}
            {selectArea === "" ? "Detail Area" : selectArea}
          </p>
          <Select
            options={newCabang}
            isMulti
            onChange={this.handle_change_type}
          />
          {selectTypeShop}
        </section>
      );
    }
    if (selectArea === "Region") {
      select_content = (
        <section className="mb-4">
          <p>
            Select {""}
            {selectArea === "" ? "Detail Area" : selectArea}
          </p>
          <Select
            options={newRegional}
            isMulti
            onChange={this.handle_change_type}
          />
          {selectTypeShop}
        </section>
      );
    }
    let omsetDataBusiness;
    let omsetDataBrand;
    let omsetDataProduct;
    let omsetDataPrinciple;
    if (showTable) {
      let all_array = [];
      all_array = dataPrinciple.map((e) => {
        return {
          VALUE: parseFloat(e.value),
          QTY: parseFloat(e.qty),
          BRAND: e.principle,
        };
      });
      all_array = sort_array(
        all_array,
        `${this.state.optionValue}`,
        this.state.optionValue === "BRAND" ? true : false
      );
      all_array = all_array.map((e, idx) => {
        let index = idx;
        if (idx > 14) {
          index = idx % 15;
        }
        return {
          ...e,
          COLOR: ft_colors[index],
        };
      });
      const options = {
        animation: false,
        maintainAspectRatio: true,
        responsive: true,
        title: {
          display: false,
          text: "BU",
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      };
      const value_array = all_array.map((e) => e.VALUE);
      const name_array = all_array.map((e) => e.BRAND);
      const color_array = all_array.map((e) => e.COLOR);
      const chart_data = {
        labels: name_array,
        datasets: [
          {
            label: "BU",
            data: value_array,
            backgroundColor: color_array,
            hoverOffset: 4,
          },
        ],
      };
      omsetDataPrinciple = (
        <>
          <div className="relative w-full overflow-hidden mb-8 mt-5">
            <input
              type="checkbox"
              className="absolute top-0 inset-x-0 w-full h-12 z-10 cursor-pointer peer opacity-0"
              id={"omsetDataPrinciple"}
            />
            <div className="w-full flex">
              <label
                className="bg-blue-500 w-full text-lg font-semibold text-white cursor-pointer text-center rounded-xl"
                htmlFor="omsetDataPrinciple"
              >
                Principal
              </label>
            </div>
            {/* arrow */}
            <div className="absolute top-1 right-1 text-white transition-transform duration-500 rotate-0 peer-checked:rotate-180">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>
            {/* content */}
            <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-max">
              <section
                style={{ width: "200px", margin: "auto", marginTop: "10px" }}
              >
                <Chart type="pie" options={options} data={chart_data} />
              </section>
              <section className="my-3">
                <p>Sort By Select</p>
                <select name="" id="" onChange={this.changeOption}>
                  <option value="">Select</option>
                  <option value="BRAND">Principal</option>
                  <option value="VALUE"> Revenue (Rp)</option>
                  <option value="QTY">Quantity</option>
                </select>
              </section>
              <section className="my-3">
                <p>Mode Select</p>
                <select id="modeSelect-prints" onChange={this.changeMode}>
                  <option value="single">Single</option>
                  <option value="multi">Multi</option>
                </select>
                <button
                  className="button_main"
                  onClick={() => {
                    this.setState({ selectMode: "single" });
                    this.props.resetMode();
                    document.getElementById(
                      "modeSelect-prints"
                    ).selectedIndex = 0;
                    document.getElementById("pointer-prints").reset();
                  }}
                >
                  Reset Mode
                </button>
              </section>
              <form onSubmit={this.findSearchPrinciple}>
                <div className="flex gap-1">
                  <div className="w-full my-3">
                    <p>Search Principal</p>
                    <input
                      type="text"
                      className="w-full"
                      placeholder={`Search Principal`}
                      onChange={this.handleSearchPrinciple.bind(this)}
                      value={searchPrinciple}
                      autoComplete="off"
                    />
                  </div>
                  <button className="px-1" type="sumbit">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </button>
                </div>
              </form>
              <div>Total Store Selected : {omset_list?.length}</div>
              <section className="text-left">
                <form id="pointer-prints">
                  <div className="flex gap-2">
                    <div>
                      <input
                        type={
                          this.state.selectMode === "single"
                            ? "radio"
                            : "checkbox"
                        }
                        className="cursor-pointer opacity-0"
                      />
                    </div>
                    <div className="flex-1 flex">
                      <div>
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "transparent",
                            display: "inline-block",
                            marginRight: "5px",
                          }}
                        />
                      </div>
                      <div className="flex-1 ml-[4px] font-bold">Principal</div>
                      <div className="flex-1 ml-[8px] font-bold">
                        Revenue (Rp)
                      </div>
                      <div className="flex-1 ml-[6px] font-bold">Quantity</div>
                    </div>
                  </div>
                  {all_array.map(({ COLOR, BRAND, VALUE, QTY }, idx) => (
                    <div
                      key={idx}
                      className="flex gap-2 cursor-pointer hover:underline active:font-bold items-center"
                    >
                      <div className="flex items-center cursor-pointer">
                        <input
                          name="business"
                          className="cursor-pointer"
                          type={
                            this.state.selectMode === "single"
                              ? "radio"
                              : "checkbox"
                          }
                          id={`select-princi${[idx]}`}
                          onClick={this.filterCollorTablePrinciple.bind(this, {
                            BRAND,
                          })}
                        />
                      </div>
                      <label
                        className="flex gap-2 flex-1 text-[13px] cursor-pointer items-center"
                        htmlFor={`select-princi${[idx]}`}
                      >
                        <div className="cursor-pointer">
                          <div
                            className="cursor-pointer"
                            style={{
                              width: "15px",
                              height: "15px",
                              backgroundColor: COLOR,
                              display: "inline-block",
                              marginTop: "5px",
                            }}
                          />
                        </div>
                        <div className="flex-1 mt-[3.2px] cursor-pointer">
                          {BRAND}
                        </div>
                        <div className="flex-1 mt-[3.2px] cursor-pointer">
                          {Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(VALUE)}
                        </div>
                        <div className="flex-1 mt-[3.2px] cursor-pointer">
                          {Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(QTY)}
                        </div>
                      </label>
                    </div>
                  ))}
                </form>
              </section>
            </div>
          </div>
        </>
      );
    }

    if (showTable) {
      let all_array = [];
      all_array = dataBusiness.map((e) => {
        return {
          VALUE: parseFloat(e.value),
          QTY: parseFloat(e.qty),
          BRAND: e.business_unit,
        };
      });
      all_array = sort_array(
        all_array,
        `${this.state.optionValue}`,
        this.state.optionValue === "BRAND" ? true : false
      );
      all_array = all_array.map((e, idx) => {
        let index = idx;
        if (idx > 14) {
          index = idx % 15;
        }
        return {
          ...e,
          COLOR: ft_colors[index],
        };
      });
      const options = {
        animation: false,
        maintainAspectRatio: true,
        responsive: true,
        title: {
          display: false,
          text: "BU",
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      };
      const value_array = all_array.map((e) => e.VALUE);
      const name_array = all_array.map((e) => e.BRAND);
      const color_array = all_array.map((e) => e.COLOR);
      const chart_data = {
        labels: name_array,
        datasets: [
          {
            label: "BU",
            data: value_array,
            backgroundColor: color_array,
            hoverOffset: 4,
          },
        ],
      };
      omsetDataBusiness = (
        <>
          <div className="relative w-full overflow-hidden mb-8 mt-5">
            <input
              type="checkbox"
              className="absolute top-0 inset-x-0 w-full h-12 z-10 cursor-pointer peer opacity-0"
              id={"omsetDataBusiness"}
            />
            <div className="w-full flex">
              <label
                className="bg-blue-500 w-full text-lg font-semibold text-white cursor-pointer text-center rounded-xl"
                htmlFor="omsetDataBusiness"
              >
                Business Units
              </label>
            </div>
            {/* arrow */}
            <div className="absolute top-1 right-1 text-white transition-transform duration-500 rotate-0 peer-checked:rotate-180">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>
            {/* content */}
            <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-max">
              <section
                style={{ width: "200px", margin: "auto", marginTop: "10px" }}
              >
                <Chart type="pie" options={options} data={chart_data} />
              </section>
              <section className="my-3">
                <p>Sort By Select</p>
                <select name="" id="" onChange={this.changeOption}>
                  <option value="">Select</option>
                  <option value="BRAND">Business Units</option>
                  <option value="VALUE"> Revenue (Rp)</option>
                  <option value="QTY">Quantity</option>
                </select>
              </section>
              <section className="my-3">
                <p>Mode Select</p>
                <select id="modeSelect-bu" onChange={this.changeMode}>
                  <option value="single">Single</option>
                  <option value="multi">Multi</option>
                </select>
                <button
                  className="button_main"
                  onClick={() => {
                    this.setState({ selectMode: "single" });
                    this.props.resetMode();
                    document.getElementById("modeSelect-bu").selectedIndex = 0;
                    document.getElementById("pointer-bu").reset();
                  }}
                >
                  Reset Mode
                </button>
              </section>
              <form onSubmit={this.findSearchBu}>
                <div className="flex gap-1">
                  <div className="w-full my-3">
                    <p>Search Business Unit</p>
                    <input
                      type="text"
                      className="w-full"
                      placeholder={`Search Business Unit`}
                      onChange={this.handleSearchBu.bind(this)}
                      value={searchBu}
                      autoComplete="off"
                    />
                  </div>

                  <button className="px-1" type="sumbit">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </button>
                </div>
              </form>
              <div>Total Store Selected : {omset_list?.length}</div>
              <section className="text-left">
                <form id="pointer-bu">
                  <div className="flex gap-2">
                    <div>
                      <input
                        type={
                          this.state.selectMode === "single"
                            ? "radio"
                            : "checkbox"
                        }
                        className="cursor-pointer opacity-0"
                      />
                    </div>
                    <div className="flex-1 flex">
                      <div>
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "transparent",
                            display: "inline-block",
                            marginRight: "5px",
                          }}
                        />
                      </div>
                      <div className="flex-1 ml-[4px] font-bold">
                        Business Units
                      </div>
                      <div className="flex-1 ml-[8px] font-bold">
                        Revenue (Rp)
                      </div>
                      <div className="flex-1 ml-[6px] font-bold">Quantity</div>
                    </div>
                  </div>
                  {all_array.map(({ COLOR, BRAND, VALUE, QTY }, idx) => (
                    <div
                      key={idx}
                      className="flex gap-2 cursor-pointer hover:underline active:font-bold items-center"
                    >
                      <div className="flex items-center cursor-pointer ">
                        <input
                          name="business"
                          className="cursor-pointer"
                          type={
                            this.state.selectMode === "single"
                              ? "radio"
                              : "checkbox"
                          }
                          id={`select-bu${[idx]}`}
                          onClick={this.filterCollorTableBusisness.bind(this, {
                            BRAND,
                          })}
                        />
                      </div>
                      <label
                        className="flex gap-2 flex-1 text-[13px] cursor-pointer items-center"
                        htmlFor={`select-bu${[idx]}`}
                      >
                        <div className="cursor-pointer">
                          <div
                            className="cursor-pointer"
                            style={{
                              width: "15px",
                              height: "15px",
                              backgroundColor: COLOR,
                              display: "inline-block",
                              marginTop: "5px",
                            }}
                          />
                        </div>
                        <div className="flex-1 mt-[3.2px] cursor-pointer">
                          {BRAND}
                        </div>
                        <div className="flex-1 mt-[3.2px] cursor-pointer">
                          {Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(VALUE)}
                        </div>
                        <div className="flex-1 mt-[3.2px] cursor-pointer">
                          {Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(QTY)}
                        </div>
                      </label>
                    </div>
                  ))}
                </form>
              </section>
            </div>
          </div>
        </>
      );
    }
    if (showTable) {
      let all_array = [];
      all_array = dataBrand.map((e) => {
        return {
          VALUE: parseFloat(e.value),
          QTY: parseFloat(e.qty),
          BRAND: e.brand,
        };
      });
      all_array = sort_array(
        all_array,
        `${this.state.optionValue}`,
        this.state.optionValue === "BRAND" ? true : false
      );
      all_array = all_array.map((e, idx) => {
        let index = idx;
        if (idx > 14) {
          index = idx % 15;
        }
        return {
          ...e,
          COLOR: ft_colors[index],
        };
      });
      const options = {
        animation: false,
        maintainAspectRatio: true,
        responsive: true,
        title: {
          display: false,
          text: "BU",
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      };
      const value_array = all_array.map((e) => e.VALUE);
      const name_array = all_array.map((e) => e.BRAND);
      const color_array = all_array.map((e) => e.COLOR);
      const chart_data = {
        labels: name_array,
        datasets: [
          {
            label: "BU",
            data: value_array,
            backgroundColor: color_array,
            hoverOffset: 4,
          },
        ],
      };
      omsetDataBrand = (
        <>
          <div className="relative w-full overflow-hidden mb-8">
            <input
              type="checkbox"
              className="absolute top-0 inset-x-0 w-full h-12 z-10 cursor-pointer peer opacity-0"
              id={"omsetDataBrand"}
            />
            <div className="w-full flex">
              <label
                className="bg-blue-500 w-full text-lg font-semibold text-white cursor-pointer text-center rounded-xl"
                htmlFor="omsetDataBrand"
              >
                Brands
              </label>
            </div>
            {/* arrow */}
            <div className="absolute top-1 right-1 text-white transition-transform duration-500 rotate-0 peer-checked:rotate-180">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>
            {/* content */}
            <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-max">
              <section
                style={{ width: "200px", margin: "auto", marginTop: "10px" }}
              >
                <Chart type="pie" options={options} data={chart_data} />
              </section>
              <section className="my-3">
                <p>Sort By Select</p>
                <select name="" id="" onChange={this.changeOption}>
                  <option value="">Select</option>
                  <option value="BRAND">Brands</option>
                  <option value="VALUE"> Revenue (Rp)</option>
                  <option value="QTY">Quantity</option>
                </select>
              </section>
              <section className="my-3">
                <p>Mode Select</p>
                <select id="modeSelect-brands" onChange={this.changeMode}>
                  <option value="single">Single</option>
                  <option value="multi">Multi</option>
                </select>
              </section>
              <button
                className="button_main"
                onClick={() => {
                  this.setState({ selectMode: "single" });
                  this.props.resetMode();
                  document.getElementById(
                    "modeSelect-brands"
                  ).selectedIndex = 0;
                  document.getElementById("pointer-brands").reset();
                }}
              >
                Reset Mode
              </button>
              <form onSubmit={this.findSearchBrands}>
                <div className="flex gap-1">
                  <div className="w-full my-3">
                    <p>Search Brands</p>
                    <input
                      type="text"
                      className="w-full"
                      placeholder={`Search Brands`}
                      onChange={this.handleSearchBrands.bind(this)}
                      value={searchBrands}
                      autoComplete="off"
                    />
                  </div>
                  <button className="px-1" type="sumbit">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </button>
                </div>
              </form>
              <div>Total Store Selected : {omset_list?.length}</div>
              <section className="text-left">
                <form id="pointer-brands">
                  <div className="flex gap-2">
                    <div>
                      <input
                        type={
                          this.state.selectMode === "single"
                            ? "radio"
                            : "checkbox"
                        }
                        className="cursor-pointer opacity-0"
                      />
                    </div>
                    <div className="flex-1 flex">
                      <div>
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "transparent",
                            display: "inline-block",
                            marginRight: "5px",
                          }}
                        />
                      </div>
                      <div className="flex-1 ml-[4px] font-bold">Brands</div>
                      <div className="flex-1 ml-[8px] font-bold">
                        Revenue (Rp)
                      </div>
                      <div className="flex-1 ml-[6px] font-bold">Quantity</div>
                    </div>
                  </div>
                  {all_array.map(({ COLOR, BRAND, VALUE, QTY }, idx) => (
                    <div
                      key={idx}
                      className="flex gap-2 cursor-pointer hover:underline active:font-bold items-center"
                    >
                      <div className="flex items-center cursor-pointer">
                        <input
                          name="business"
                          className="cursor-pointer"
                          type={
                            this.state.selectMode === "single"
                              ? "radio"
                              : "checkbox"
                          }
                          id={`select-brands${[idx]}`}
                          onClick={this.filterCollorTableBrand.bind(this, {
                            BRAND,
                          })}
                        />
                      </div>
                      <label
                        className="flex gap-2 flex-1 text-[13px] cursor-pointer items-center"
                        htmlFor={`select-brands${[idx]}`}
                      >
                        <div className="cursor-pointer">
                          <div
                            className="cursor-pointer"
                            style={{
                              width: "15px",
                              height: "15px",
                              backgroundColor: COLOR,
                              display: "inline-block",
                              marginTop: "5px",
                            }}
                          />
                        </div>
                        <div className="flex-1 mt-[3.2px] cursor-pointer">
                          {BRAND}
                        </div>
                        <div className="flex-1 mt-[3.2px] cursor-pointer">
                          {Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(VALUE)}
                        </div>
                        <div className="flex-1 mt-[3.2px] cursor-pointer">
                          {Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(QTY)}
                        </div>
                      </label>
                    </div>
                  ))}
                </form>
              </section>
            </div>
          </div>
        </>
      );
    }
    if (showTable) {
      let all_array = [];
      all_array = dataProduction.map((e) => {
        return {
          VALUE: parseFloat(e.value),
          QTY: parseFloat(e.qty),
          BRAND: e.product_parent_name,
          PARENTID: e.product_parent_id,
          NAME: e.brand,
          BU: e.business_unit,
        };
      });
      all_array = sort_array(
        all_array,
        `${this.state.optionValue}`,
        this.state.optionValue === "BRAND" ? true : false
      );
      all_array = all_array.map((e, idx) => {
        let index = idx;
        if (idx > 14) {
          index = idx % 15;
        }
        return {
          ...e,
          COLOR: ft_colors[index],
        };
      });
      const options = {
        animation: false,
        maintainAspectRatio: true,
        responsive: true,
        title: {
          display: false,
          text: "BU",
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      };
      const value_array = all_array.map((e) => e.VALUE);
      const name_array = all_array.map((e) => e.BRAND);
      const color_array = all_array.map((e) => e.COLOR);
      const chart_data = {
        labels: name_array,
        datasets: [
          {
            label: "BU",
            data: value_array,
            backgroundColor: color_array,
            hoverOffset: 4,
          },
        ],
      };
      omsetDataProduct = (
        <>
          <div className="relative w-full overflow-hidden mb-8">
            <input
              type="checkbox"
              className="absolute top-0 inset-x-0 w-full h-12 z-10 cursor-pointer peer opacity-0"
              id={"omsetDataProduct"}
            />
            <div className="w-full flex">
              <label
                className="bg-blue-500 w-full text-lg font-semibold text-white cursor-pointer text-center rounded-xl"
                htmlFor="omsetDataProduct"
              >
                Products
              </label>
            </div>
            {/* arrow */}
            <div className="absolute top-1 right-1 text-white transition-transform duration-500 rotate-0 peer-checked:rotate-180">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                Sort By Select
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>
            {/* content */}
            <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-max">
              <section
                style={{ width: "200px", margin: "auto", marginTop: "10px" }}
              >
                <Chart type="pie" options={options} data={chart_data} />
              </section>
              <section className="my-3">
                <p>Sort By Select</p>
                <select name="" id="" onChange={this.changeOption}>
                  <option value="">Select</option>
                  <option value="BRAND">Products</option>
                  <option value="VALUE"> Revenue (Rp)</option>
                  <option value="QTY">Quantity</option>
                </select>
              </section>
              <section className="my-3">
                <p>Mode Select</p>
                <select id="modeSelect-product" onChange={this.changeMode}>
                  <option value="single">Single</option>
                  <option value="multi">Multi</option>
                </select>
                <button
                  className="button_main"
                  onClick={() => {
                    this.setState({ selectMode: "single" });
                    this.props.resetMode();
                    document.getElementById(
                      "modeSelect-product"
                    ).selectedIndex = 0;
                    document.getElementById("pointer-product").reset();
                  }}
                >
                  Reset Mode
                </button>
              </section>
              <form onSubmit={this.findSearchProducts}>
                <div className="flex gap-1">
                  <div className="w-full my-3">
                    <p>Search Product</p>
                    <input
                      type="text"
                      className="w-full"
                      placeholder={`Search Product`}
                      onChange={this.handleSearchProducts.bind(this)}
                      value={searchProducts}
                      autoComplete="off"
                    />
                  </div>
                  <button className="px-1" type="sumbit">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </button>
                </div>
              </form>
              <div>Total Store Selected : {omset_list?.length}</div>
              <section className="text-left">
                <form id="pointer-product">
                  <div className="flex gap-2">
                    <div>
                      <input
                        type={
                          this.state.selectMode === "single"
                            ? "radio"
                            : "checkbox"
                        }
                        className="cursor-pointer opacity-0"
                      />
                    </div>
                    <div className="flex-1 flex">
                      <div>
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: "transparent",
                            display: "inline-block",
                            marginRight: "5px",
                          }}
                        />
                      </div>
                      <div className="flex-1 ml-[4px] font-bold">
                        Business Unit
                      </div>
                      <div className="flex-1 ml-[8px] font-bold">Brands</div>
                      <div className="flex-1 ml-[8px] font-bold">
                        ID Product
                      </div>
                      <div className="flex-1 ml-[6px] font-bold">Products</div>
                      <div className="flex-1 ml-[6px] font-bold">
                        Revenue (Rp)
                      </div>
                      <div className="flex-1 ml-[6px] font-bold">Quantity</div>
                    </div>
                  </div>
                  {all_array.map(
                    ({ COLOR, BRAND, PARENTID, VALUE, QTY, NAME, BU }, idx) => (
                      <div
                        key={idx}
                        className="flex gap-2 cursor-pointer hover:underline active:font-bold items-center"
                      >
                        <div className="flex items-center cursor-pointer">
                          <input
                            name="business"
                            className="cursor-pointer"
                            type={
                              this.state.selectMode === "single"
                                ? "radio"
                                : "checkbox"
                            }
                            id={`select-product${[idx]}`}
                            onClick={this.filterCollorTableProduct.bind(this, {
                              BRAND,
                            })}
                          />
                        </div>
                        <label
                          className="flex gap-2 flex-1 text-[13px] cursor-pointer items-center"
                          htmlFor={`select-product${[idx]}`}
                        >
                          <div className="cursor-pointer">
                            <div
                              className="cursor-pointer"
                              style={{
                                width: "15px",
                                height: "15px",
                                backgroundColor: COLOR,
                                display: "inline-block",
                                marginTop: "5px",
                              }}
                            />
                          </div>
                          <div className="flex-1 mt-[3.2px] cursor-pointer">
                            {BU}
                          </div>
                          <div className="flex-1 mt-[3.2px] cursor-pointer">
                            {NAME}
                          </div>
                          <div className="flex-1 mt-[3.2px] cursor-pointer">
                            {PARENTID}
                          </div>
                          <div className="flex-1 mt-[3.2px] cursor-pointer">
                            {BRAND}
                          </div>
                          <div className="flex-1 mt-[3.2px] cursor-pointer">
                            {Intl.NumberFormat("id-ID", {
                              style: "decimal",
                            }).format(VALUE)}
                          </div>
                          <div className="flex-1 mt-[3.2px] cursor-pointer">
                            {Intl.NumberFormat("id-ID", {
                              style: "decimal",
                            }).format(QTY)}
                          </div>
                        </label>
                      </div>
                    )
                  )}
                </form>
              </section>
            </div>
          </div>
        </>
      );
    }

    return (
      <main>
        <form className="mb-4" onSubmit={this.handleSubmit}>
          <section className="mb-2">
            <p>Select Depo/Branch/Region</p>
            <Select options={area_list} onChange={this.handle_change} />
          </section>
          {select_content}
          <section className="mb-2">
            <p> Choose Month</p>
            <select
              name="MONTH_NUMBER"
              value={MONTH_NUMBER}
              onChange={this.on_change}
            >
              {month_list.map((text, idx) => {
                return (
                  <option key={idx} value={idx}>
                    {text}
                  </option>
                );
              })}
            </select>
          </section>
          <section className="mb-4">
            <p>Choose Year</p>
            <select
              name="YEAR_NUMBER"
              value={YEAR_NUMBER}
              onChange={this.on_change}
            >
              {year_list.map((text, idx) => {
                return (
                  <option key={idx} value={text}>
                    {text}
                  </option>
                );
              })}
            </select>
          </section>
          <div>{button_content}</div>
        </form>
        {tab_nav}
        {this.state.selectedMenu === "omset_table" ? (
          <>
            {legenda_content}
            {omsetDataPrinciple}
            {omsetDataBusiness}
            {omsetDataBrand}
            {omsetDataProduct}
            {searchId}
            {main_content}
            {modalDownloadOutlet}
          </>
        ) : (
          <>
            {this.state.selectedMenu === "omset_salesman" ? (
              <main style={{ marginBottom: "20px" }}>
                {legenda_content_salesman}
                <table className="table_lite" style={{ fontSize: "14px" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "10px" }}>No</th>
                      <th>Salesman Name</th>
                      <th>Salesman ID</th>
                      <th>Quantity</th>
                      <th>Revenue</th>
                    </tr>
                  </thead>
                  <tbody>
                    {get_omset_salesman.map((e, idx) => {
                      let qty_salesman = parseFloat(e.qty_salesman);
                      qty_salesman = qty_salesman.toFixed(1);
                      qty_salesman = parseFloat(qty_salesman);
                      let omset_salesman = parseInt(e.omset_salesman);
                      omset_salesman = omset_salesman.toFixed(1);
                      omset_salesman = parseFloat(omset_salesman);
                      let button_fly = (
                        <ON_FLY item={e} modal_item="modal_omset_salesman" />
                      );
                      if (!e?.location?.coordinates?.[0]) {
                        button_fly = (
                          <button className="button" id="red">
                            No location
                          </button>
                        );
                      }
                      return (
                        <tr key={idx}>
                          <td style={{ width: "10px" }}>{idx + 1}</td>
                          <td>
                            {e["Nama Salesman"]} <br />
                            {button_fly}
                          </td>
                          <td>
                            {e?.kd_sales_arr[0] ? e?.kd_sales_arr[0] : 0} <br />
                          </td>
                          <td>
                            {Intl.NumberFormat("id-ID", {
                              style: "decimal",
                            }).format(qty_salesman)}
                          </td>
                          <td>
                            Rp.{" "}
                            {Intl.NumberFormat("id-ID", {
                              style: "decimal",
                            }).format(omset_salesman)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </main>
            ) : null}
          </>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  get_omset,
  turn_on_menu,
  turn_off_menu,
  turn_on_menu_status,
  getSalesmanFilter,
  popUpAction,
  getConclusion,
  filterCollor,
  filterCollorSalesman,
  get_business_unit,
  get_brand_unit,
  get_product_unit,
  get_principle_unit,
  getListTypeToko,
  searchShop,
  searchPrinciple,
  searchBu,
  searchBrands,
  searchProducts,
  resetMode,
  getSalesmanOmset,
  getDate,
  getDataSalesman,
})(OMSET_MENU);
