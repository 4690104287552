import React, { Component } from "react";
import { connect } from "react-redux";
import { edit_status } from "../../actions/dataActions";
import { get_salesman_nonactive } from "../../actions/salesmanActions";

class ListInActive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      search: "",
      key: "Nama Salesman",
    };
  }
  componentDidMount() {
    this.get_salesman_nonactive("", "Nama Salesman");
  }
  get_salesman_nonactive = async (query, key) => {
    try {
      const result = await get_salesman_nonactive({ search: query, key: key });
      this.setState({ lists: result });
    } catch (error) {
      console.log(error);
    }
  };
  handle_submit = (event) => {
    event.preventDefault();
    const { search, key } = this.state;
    this.get_salesman_nonactive(search, key);
  };
  handle_input = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handle_select = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handle_checkbox = (event) => {
    const index = parseInt(event.target.getAttribute("data-index"));
    const id = event.target.id;
    const checked = event.target.checked;

    let status = "nonactive";
    if (checked) {
      status = "off";
    }
    let lists = [...this.state.lists];
    let list = { ...lists[index], status: status };
    lists[index] = list;

    this.setState({
      lists,
    });

    if (checked) {
      this.props.edit_status({
        salesman_id: id,
        status: "off",
      });
    } else {
      this.props.edit_status({
        salesman_id: id,
        status: "nonactive",
      });
    }
  };
  render() {
    const { lists } = this.state;
    return (
      <React.Fragment>
        <form onSubmit={this.handle_submit}>
          <div className="flex gap-1">
            <div className="flex-1">
              <input
                className="w-full"
                name="search"
                onChange={this.handle_input}
              />
            </div>
            <div className="">
              <select
                className="w-full"
                required
                name="key"
                onChange={this.handle_select}
              >
                <option value={"Nama Salesman"}>NAME</option>
                <option value={"NIK"}>EMPLOYEE ID</option>
                <option value={"Depo"}>DEPO</option>
              </select>
            </div>
            <div className="h-full">
              <button className="button_main h-10" type="submit">
                Find Salesman
              </button>
            </div>
          </div>
        </form>
        <div className="mt-2 text-sm">
          Showing {lists.length} Inactive Salesmen
        </div>
        <div>
          {lists.map((e, i) => (
            <div key={i} className="hover:bg-gray-100 py-4 px-2 cursor-pointer">
              <div className="grid grid-cols-1">
                <p className="font-semibold">{e["Nama Only"]}</p>
              </div>
              <hr className="my-2" />
              <div className="grid grid-cols-2">
                <small>Salesman Name: {e["Nama Salesman"]}</small>
                <small>Employee ID: {e.NIK}</small>
                <small>Depo: {e.Depo}</small>
                <small>Branch: {e.CABANG}</small>
                <small>Region: {e.REGIONAL}</small>
                <small>
                  Status:{" "}
                  {e.status === "nonactive" ? (
                    <span className="text-red-700 font-semibold">
                      Not Active
                    </span>
                  ) : (
                    <span className="text-green-700 font-semibold">Active</span>
                  )}
                </small>
              </div>
              <div className="flex gap-1 mt-4">
                <p className="text-gray-700">Activate Salesman</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={this.handle_checkbox}
                    id={e._id}
                    data-index={i}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { edit_status })(ListInActive);
