/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { push_merch } from "../../actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/
const months = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
];

const target_array = ["A", "B", "C", "D", "-"];

class ASSIGN_OUTLET_MERCH extends Component {
  state = {
    merch_object: {
      FLAGGING: "MERCHANDISING PROGRAM",
      "FLAG FOTO": "",
      "NOMOR BULAN": "",
      "NOMOR TAHUN": "",
      "TARGET KELAS": "",
      "REALISASI KELAS": "",
      "BUDGET PROGRAM": "",
      "RASIO BUDGET PER OMSET": "",
      OMSET: "",
      QTY: "",
    },
  };

  on_submit = () => {
    const { current_outlet } = this.props;
    const object = {
      outlet_id: current_outlet._id,
      "ID SFA": current_outlet["ID SFA"],
      Plan: current_outlet["Plan"],
      ...this.state.merch_object,
    };
    const array = [object];
    const body = { array: array };
    this.props.push_merch(body);
    this.props.toggle_merch();
  };

  on_change_merch_object = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    let merch_object = this.state.merch_object;
    merch_object[name] = value;
    this.setState({ merch_object });
  };

  render() {
    //local storage

    //local state
    const { merch_object } = this.state;

    //global props
    const { loading_item, loading_status } = this.props.properties;

    //content
    return (
      <main className="max_height">
        <h1 className="text_header">
          Please enter outlet merchandising details
        </h1>

        <section className="mb-3">
          <p className="text_small">MONTH</p>
          <select
            name="NOMOR BULAN"
            value={merch_object["NOMOR BULAN"]}
            onChange={this.on_change_merch_object}
          >
            <option value="">
              Select the month of the merchandising period
            </option>
            {months.map((item, idx) => {
              return (
                <option key={idx} value={idx + 1}>
                  {item}
                </option>
              );
            })}
          </select>
        </section>

        <section className="mb-3">
          <p className="text_small">YEAR</p>
          <input
            name="NOMOR TAHUN"
            value={merch_object["NOMOR TAHUN"]}
            onChange={this.on_change_merch_object}
            placeholder="Enter the year, for example 2020, 2021, 2022"
            type="number"
          />
        </section>

        <section className="mb-3">
          <p className="text_small">CLASS TARGET</p>
          <select
            name="TARGET KELAS"
            value={merch_object["TARGET KELAS"]}
            onChange={this.on_change_merch_object}
          >
            <option value="">Select target merchandising class</option>
            {target_array.map((item, idx) => {
              return (
                <option key={idx} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </section>

        <section className="mb-3">
          <p className="text_small">CLASS REALIZATION</p>
          <select
            name="REALISASI KELAS"
            value={merch_object["REALISASI KELAS"]}
            onChange={this.on_change_merch_object}
          >
            <option value="">Select realization merchandising class</option>
            {target_array.map((item, idx) => {
              return (
                <option key={idx} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </section>

        <section className="mb-3">
          <p className="text_small">TURNOVER (OMSET)</p>
          <input
            name="OMSET"
            value={merch_object["OMSET"]}
            onChange={this.on_change_merch_object}
            placeholder="Enter the turnover(omset) value in rupiah, for example 1.000.000"
            type="number"
          />
          {merch_object["OMSET"] && (
            <div className="container_light">
              Desimal: Rp{" "}
              {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                merch_object["OMSET"]
              )}
            </div>
          )}
        </section>

        <section className="mb-3">
          <p className="text_small">QTY</p>
          <input
            name="QTY"
            value={merch_object["QTY"]}
            onChange={this.on_change_merch_object}
            placeholder="Enter QTY value in unit, for example 10"
            type="number"
          />
        </section>

        <section className="mb-3">
          <p className="text_small">BUDGET PROGRAM</p>
          <input
            name="BUDGET PROGRAM"
            value={merch_object["BUDGET PROGRAM"]}
            onChange={this.on_change_merch_object}
            placeholder="Enter the program budget value in rupiah, for example 200.000"
            type="number"
          />
          {merch_object["BUDGET PROGRAM"] && (
            <div className="container_light">
              Desimal: Rp{" "}
              {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                merch_object["BUDGET PROGRAM"]
              )}
            </div>
          )}
        </section>

        <section className="mb-3">
          <p className="text_small">BUDGET PER TURNOVER RATIO</p>
          <input
            name="RASIO BUDGET PER OMSET"
            value={merch_object["RASIO BUDGET PER OMSET"]}
            onChange={this.on_change_merch_object}
            placeholder="Enter the budget per turnover ratio value, for example 0.001"
            type="number"
          />
        </section>
        {loading_status && loading_item === "push_merch" ? (
          <div className="button background_grey">SUBMITING...</div>
        ) : (
          <button className="button background_green" onClick={this.on_submit}>
            SUBMIT MERCHANDISING OUTLET
          </button>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties,
});

export default connect(mapStateToProps, { push_merch })(ASSIGN_OUTLET_MERCH);
