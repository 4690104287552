//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import {
  set_kunjungan_arr_new,
  push_kunjungan_arr_new,
  set_plan_list,
} from "../../actions/rayonActions";
import { set_value } from "../../actions/mapActions";

//Picture Asset

//General Function
import rayonisasi_general from "../../validation/rayonisasi_general";
import st_colors from "../../validation/st_colors";

class STEP_7_DAY_RAYON_OVERWRITE extends Component {
  state = {};

  rayon_by_cycle = async (salesman_id, salesman_index, cycle) => {
    try {
      let {
        rayon_select,
        rayon_list_database,
        outlet_list_filter_0,
        jumlah_hari,
      } = this.props.data;
      let rayon_object = {};
      if (rayon_list_database.length > 0) {
        rayon_object = rayon_list_database.find((r) => r._id === rayon_select);
      }

      const { salesman_index_process } = this.props.map;
      const plan_list = rayon_object?.plan_list || [];

      this.props.set_value({ key: "salesman_id_process", value: salesman_id });
      this.props.set_value({
        key: "salesman_index_process",
        value: salesman_index,
      });

      let salesman_id_array = [];
      //step 1: ambil array plan_list secara keseluruhan & map plan_list menjadi salesman_id_array,
      salesman_id_array = [
        ...new Set(plan_list.map((item) => item.salesman_id)),
      ]; //jadikan unique

      const plan_index = plan_list.findIndex(
        (item) => item.salesman_id === salesman_id && item.cycle === cycle
      );

      let outlet_by_cycle = outlet_list_filter_0.filter(
        (t) => t.cycle === cycle
      );

      if (plan_index === 0 || plan_index === 1) {
        //jika salesman pertama, maka lakukan set supaya kunjungan ditoko dimulai dari nol
        //posisi 0 & 1 kemungkinan menandakan cycle 1 & cycle 2, toko pada cycle 1 tidak akan berisian dengan toko cycle 2

        const body_rayon = {
          toko_list_main: outlet_by_cycle,
          text: "day",
          jumlah_rayon: jumlah_hari,
        };
        const result = rayonisasi_general(body_rayon);

        const body_outlet_set = {
          day_list: result.rayon_list_complete,
          outlet_list_filter_0: outlet_by_cycle,
          cycle_select: cycle,
          salesman_id,
        };

        await this.props.set_kunjungan_arr_new(body_outlet_set);

        const body_plan = {
          rayon_id: rayon_select,
          plan_index,
          day_list: result.rayon_list_complete,
        };

        await this.props.set_plan_list(body_plan);
      } else {
        //setelah salesman pertama, maka lakukan push seperti biasa, dan lakukan rotate
        //rotate dilakukan dengan patokan day_list adalah satu salesman sebelum salesman yang akan dirotate dengan cycle yang sama

        //FIRST SALESMAN
        //ambil salesman pada plan pertama, asumsikan ini adalah salesman pertama yang diplanning di cycle yang sedang aktif
        const plan_list_c = plan_list.filter((p) => p.cycle === cycle);
        const plan_first = plan_list_c?.[0] ? plan_list_c?.[0] : {};
        const day_list_first = plan_first?.day_list ? plan_first?.day_list : []; // const salesman_first = plan_first.salesman_id;

        //LAST SALESMAN

        //step 2: find index untuk current salesman (salesman_index_current)
        const salesman_index_current = salesman_id_array.findIndex(
          (item) => item === salesman_id
        );

        //step 3: ambil salesman_id_array dengan nomor index = salesman_index_current - 1 (salesman_id_before)
        const salesman_id_before =
          salesman_id_array[salesman_index_current - 1];

        //step 4: find plan_last dengan find plan_list dengan paramater cycle === cycle current && salesman_id === salesman_id_before
        const plan_last = plan_list.find(
          (item) =>
            item.salesman_id === salesman_id_before && item.cycle === cycle
        );
        let day_list_new = plan_last?.day_list ? plan_last?.day_list : [];
        const length = day_list_new.length; //get 2 last day

        const last_2 = day_list_new.slice(length - 2, length);
        //angka 2 karena sekarang bisnis proses dari nabati bergeser 2, jika berubah maka angka 2 bisa dirubah

        //get 4 first day
        const first_4 = day_list_new.slice(0, length - 2);
        //angka 2 karena sekarang bisnis proses dari nabati bergeser 2, jika berubah maka angka 2 bisa dirubah

        //place the (2) last day on the first and (4) first day on the last
        day_list_new = last_2.concat(first_4);
        day_list_new = day_list_new.map((d, idx) => {
          let name_old = day_list_first.find(
            (item) => item.index === d.index
          )?.name;
          let index = idx;
          if (idx > 15) {
            index = idx % 16;
          }
          return {
            ...d,
            name: `day ${idx + 1}`,
            color: st_colors[index],
            name_old,
            date_created: Date.now(),
            date_updated: Date.now(),
          };
        });

        outlet_by_cycle = outlet_by_cycle.map((t) => {
          //mengeluarkan day sehingga bisa difilter dengan mudah di rayonActions.js
          const kunjungan_arr_new = t?.kunjungan_arr_new || [];

          const kunjungan = kunjungan_arr_new[0];

          const day = day_list_new.find(
            (d) => d?.name_old === kunjungan?.day
          )?.name;

          return { ...t, day };
        });

        const { salesman_list } = this.props.data;
        const salesman_select = salesman_list.find(
          (s) => s._id === salesman_id
        ); //push kunjungan to outlet collection

        const body_outlet_push = {
          day_list: day_list_new,
          outlet_list_filter_0: outlet_by_cycle,
          cycle_select: cycle,
          salesman_select: salesman_select,
        };
        await this.props.push_kunjungan_arr_new(body_outlet_push); //set plan list yang sudah diupdate day list nya

        const body_plan = {
          rayon_id: rayon_select,
          plan_index,
          day_list: day_list_new,
        };
        await this.props.set_plan_list(body_plan);
      }

      if (salesman_index_process === salesman_id_array.length - 1) {
        //cek apakah ini merupakan salesman terakhir untuk dilooping
        //jika iya maka reset nilai salesman_id_process & salesman_index_process
        //untuk mencegah button ditekan double
        this.props.set_value({ key: "salesman_id_process", value: "" });
        this.props.set_value({
          key: "salesman_index_process",
          value: -1,
        });
      }

      await this.props.set_value({ key: "plan_current", value: plan_index });
    } catch (error) {}
  };

  rayon_by_salesman_id = async (salesman_id, salesman_index) => {
    this.props.set_value({ key: "loading_overwrite_hari", value: true });
    const cycle_array = ["cycle 1", "cycle 2"];

    const delay = () => new Promise((res) => setTimeout(res, 0));
    const parent_function = () => {
      return cycle_array.reduce(
        (last_promise, cycle_select) =>
          last_promise.then((result_sum) =>
            child_function(cycle_select).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (cycle_select) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            await this.rayon_by_cycle(
              salesman_id,
              salesman_index,
              cycle_select
            );
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(() => {
      this.props.set_value({ key: "loading_overwrite_hari", value: false });
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const {
      rayon_select,
      rayon_list_database,
      salesman_list,
      outlet_list_filter_0,
      // loading_status,
      // loading_item,
    } = this.props.data;
    const {
      salesman_id_process,
      salesman_index_process,
      plan_current,
      loading_overwrite_hari,
    } = this.props.map;

    //content
    let rayon_object = {};
    if (rayon_list_database.length > 0) {
      rayon_object = rayon_list_database.find((r) => r._id === rayon_select);
    }

    const plan_list = rayon_object?.plan_list ? rayon_object?.plan_list : [];

    const outlet_list_filter_0_no_day = outlet_list_filter_0.filter((toko) => {
      const kunjungan_arr_new = toko?.kunjungan_arr_new || [];
      return kunjungan_arr_new.length < plan_list.length / 2;
    });

    const outlet_list_filter_0_no_cycle = outlet_list_filter_0.filter(
      (toko) => !toko.cycle
    );

    const salesman_id_array = [
      ...new Set(plan_list.map((item) => item.salesman_id)),
    ];

    const salesman_object_array = salesman_id_array.map((_id) => {
      const object = salesman_list.find((s) => s._id === _id);
      return object;
    });

    let rayon_ulang_content;

    if (plan_list.length === 0) {
      rayon_ulang_content = (
        <main>
          <p className="text_bold">
            There are no salesmen plotted in this rayon yet.
          </p>
          <div className="button" id="red">
            ↑
          </div>
        </main>
      );
    } else if (outlet_list_filter_0_no_cycle.length > 0) {
      rayon_ulang_content = (
        <main>
          <p className="text_bold">
            To re-run day rayonization, please run overwrite cycle first (Step
            4).
          </p>
          <div className="button" id="red">
            ↑
          </div>
        </main>
      );
    } else if (outlet_list_filter_0_no_day.length > 0) {
      rayon_ulang_content = (
        <section>
          <p className="text_inferior">
            There are <b>{outlet_list_filter_0_no_day.length}</b> stores that
            have not plotted their days
          </p>
          <br />
          {salesman_object_array.map((s, idx) => {
            let button;

            if (salesman_id_process === s._id && loading_overwrite_hari) {
              button = (
                <button className="button_small" id="grey">
                  Processing...
                </button>
              );
            } else if (idx <= salesman_index_process) {
              button = (
                <button className="button_small" id="grey">
                  Done
                </button>
              );
            } else if (idx - salesman_index_process > 1) {
              button = (
                <button className="button_small" id="grey">
                  Wait
                </button>
              );
            } else if (loading_overwrite_hari) {
              button = (
                <button className="button_small" id="grey">
                  Wait
                </button>
              );
            } else {
              button = (
                <button
                  onClick={this.rayon_by_salesman_id.bind(this, s._id, idx)}
                  className="button_small"
                  id="green"
                >
                  Start
                </button>
              );
            }
            return (
              <div key={idx}>
                <p className="text_inferior">{s["Nama Salesman"]}</p>
                {button}
              </div>
            );
          })}

          <br />

          <p className="text_inferior">
            Day overwrite progress ({plan_current + 1}/{plan_list.length})
          </p>
        </section>
      );
    } else {
      rayon_ulang_content = (
        <main>
          <p className="text_bold">
            All stores already have JKS for all salesmen on the list
          </p>
          <div className="button" id="green">
            ✔
          </div>
        </main>
      );
    }

    return (
      <main className="main_container" id="white">
        {rayon_ulang_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
});

export default connect(mapStateToProps, {
  set_kunjungan_arr_new,
  push_kunjungan_arr_new,
  set_plan_list,
  set_value,
})(STEP_7_DAY_RAYON_OVERWRITE);
