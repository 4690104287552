import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";
//Personal Component
//Redux function
//Picture Asset
//General Function

class DriverLayer extends Component {
  render() {
    let salesman_mode = this.props.data.salesman_mode;
    const is_visible = this.props.data.list_menu_active.find(
      (e) => e.key === "driver"
    )?.active;
    const { driver_list_with_location } = this.props.tracking;
    let geojson = {
      type: "FeatureCollection",
      features: [],
    };
    if (driver_list_with_location.length > 0) {
      const new_features = driver_list_with_location.map((s) => {
        let status_driver = "truck_red";
        if (s.status_bike === "bike_blue") {
          status_driver = "truck_blue";
        }
        if (s.status_bike === "bike_green") {
          status_driver = "truck_green";
        }

        return {
          type: "Feature",
          properties: {
            ...s,
            rad: s.location.coordinates[2],
            driver_name: `${s.DRIVER}-${s.NOPOL}`,
            status_bike: status_driver,
          },
          geometry: {
            type: "Point",
            coordinates: [s.location.coordinates[0], s.location.coordinates[1]],
          },
        };
      });
      geojson = {
        type: "FeatureCollection",
        features: [...new_features],
      };
    }

    return (
      <React.Fragment>
        {salesman_mode === "cluster" ? (
          <main>
            <Source
              key="driver_cluster_src"
              id="driver_cluster_src"
              type="geojson"
              data={geojson}
              generateId={true}
              cluster={true}
              clusterMaxZoom={14}
              clusterRadius={50}
            />
            <Layer
              id="driver_cluster"
              key="driver_cluster"
              source="driver_cluster_src"
              before="hari"
              type="circle"
              paint={{
                "circle-color": [
                  "step",
                  ["get", "point_count"],
                  "#51bbd6",
                  100,
                  "#f1f075",
                  750,
                  "#f28cb1",
                ],
                "circle-radius": [
                  "step",
                  ["get", "point_count"],
                  20,
                  100,
                  30,
                  750,
                  40,
                ],
              }}
            />
            <Layer
              id="cluster_count_driver"
              type="symbol"
              source="driver_cluster_src"
              before="hari"
              filter={["has", "point_count"]}
              layout={{
                "text-field": "{point_count_abbreviated}",
                "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                "text-size": 12,
              }}
            />
            <Layer
              id="uncluster_count_driver"
              type="circle"
              source="driver_cluster_src"
              before="hari"
              filter={["!", ["has", "point_count"]]}
              paint={{
                "circle-color": "#11b4da",
                "circle-radius": 4,
                "circle-stroke-width": 1,
                "circle-stroke-color": "#fff",
              }}
            />
          </main>
        ) : (
          <main>
            <Source
              key="driver_src"
              id="driver_src"
              type="geojson"
              data={geojson}
              generateId={true}
              cluster={false}
            />
            <Layer
              id="driver"
              key="driver"
              source="driver_src"
              before="hari"
              type="symbol"
              layout={{
                visibility: is_visible ? "visible" : "none",
                "icon-size": 0.25,
                "icon-padding": 1,
                "icon-anchor": "center",
                "icon-image":
                  salesman_mode === "status" ? ["get", "status_bike"] : "truck",
                "text-field": ["get", "driver_name"],
                "text-anchor": "top",
                "text-offset": [0, 1.5],
                "text-line-height": 1,
                "icon-rotate": ["get", "rad"],
                "icon-allow-overlap": true,
                "icon-ignore-placement": true,
                "text-allow-overlap": true,
                "text-ignore-placement": true,
                "text-size": 14,
                "text-max-width": 32,
              }}
              paint={{
                "icon-color": "red",
                "icon-opacity": 1,
                "text-opacity": salesman_mode === "name" ? 1 : 0,
                "text-halo-color": "white",
                "text-halo-width": 1,
              }}
              onLeave={this.onLeave}
              onClick={this.props.layerOnClick.bind(this, "modal_driver")}
            />
          </main>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
  tracking: state.tracking,
});
export default connect(mapStateToProps, {})(DriverLayer);
