/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

/*NON IMPORT*/

class OMSET_OUTLET_LAYER extends Component {
  render() {
    //localstorage

    //local state

    //global props
    const is_visible = this.props.data.list_menu_active.find(
      (e) => e.key === "omset"
    )?.active;
    const { omset_list_filter_0, outlet_mode } = this.props.data;

    //content
    let geojson = {
      type: "FeatureCollection",
      features: [],
    };
    if (omset_list_filter_0.length > 0) {
      const new_features = omset_list_filter_0.map((e) => {
        let color = "#000000";
        if (e.VALUE >= 100_000 && e.VALUE < 200_000) {
          color = "#bc2023";
        }
        if (e.VALUE >= 200_000 && e.VALUE < 300_000) {
          color = "#eb442c";
        }
        if (e.VALUE >= 300_000 && e.VALUE < 400_000) {
          color = "#f8b324";
        }
        if (e.VALUE >= 400_000 && e.VALUE < 500_000) {
          color = "#b0ae2d";
        }
        if (e.VALUE >= 500_000 && e.VALUE < 2_000_000) {
          color = "#4da83a";
        }
        if (e.VALUE >= 2_000_000 && e.VALUE < 30_000_000) {
          color = "#069fff";
        }
        if (e.VALUE >= 30_000_000) {
          color = "#2806ff";
        }
        let properties = {
          ...e,
          color,
        };

        delete properties.location;
        return {
          type: "Feature",
          properties,
          geometry: e?.location,
        };
      });
      geojson = {
        type: "FeatureCollection",
        features: [...new_features],
      };
    }
    return (
      omset_list_filter_0.length > 0 && (
        <React.Fragment>
          {outlet_mode === "cluster" ? (
            <main>
              <Source
                key="omset_cluster_src"
                id="omset_cluster_src"
                type="geojson"
                data={geojson}
                generateId={true}
                cluster={true}
                clusterMaxZoom={14}
                clusterRadius={50}
              />
              <Layer
                id="omset_cluster"
                key="omset_cluster"
                source="omset_cluster_src"
                type="circle"
                paint={{
                  "circle-color": [
                    "step",
                    ["get", "point_count"],
                    "#51bbd6",
                    100,
                    "#f1f075",
                    750,
                    "#f28cb1",
                  ],
                  "circle-radius": [
                    "step",
                    ["get", "point_count"],
                    20,
                    100,
                    30,
                    750,
                    40,
                  ],
                }}
              />
              <Layer
                id="cluster_count_omset"
                type="symbol"
                source="omset_cluster_src"
                filter={["has", "point_count"]}
                layout={{
                  "text-field": "{point_count_abbreviated}",
                  "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                  "text-size": 12,
                }}
              />
              <Layer
                id="uncluster_count_omset"
                type="circle"
                source="omset_cluster_src"
                filter={["!", ["has", "point_count"]]}
                paint={{
                  "circle-color": "#11b4da",
                  "circle-radius": 4,
                  "circle-stroke-width": 1,
                  "circle-stroke-color": "#fff",
                }}
              />
            </main>
          ) : (
            <main>
              <Source
                key={"omset-src"}
                id="omset-src"
                type="geojson"
                data={geojson}
              />
              <Layer
                id="omset"
                key="omset"
                type="circle"
                source={"omset-src"}
                layout={{
                  visibility: is_visible ? "visible" : "none",
                }}
                paint={{
                  "circle-color": ["get", "color"],
                  "circle-radius": 5,
                  "circle-stroke-width": 1,
                  "circle-stroke-color": "white",
                  "circle-opacity": 1,
                }}
                onLeave={this.onLeave}
                onClick={this.props.layerOnClick.bind(this, "modal_omset")}
              />
            </main>
          )}
        </React.Fragment>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, {})(OMSET_OUTLET_LAYER);
