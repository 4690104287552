import React, { Component } from "react";
import { CSVLink } from "react-csv";
import writeXlsxFile from "write-excel-file";
import { connect } from "react-redux";
import {
  get_log_multiple,
  // get_log_salesman_active,
} from "../../actions/tracking";
// import format_tanggal_bulan_tahun from "../validation/format_tanggal_bulan_tahun";

class DownloadTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: 0,
      end_date: 0,
      not_all: true,
      target_hour: 0,
      target_percent: 100,
      time_array_depo: [],
      loading_process: false,
      dataCSV: [],
      loading_csv: false,
      separator: ",",
      start_time_hour: "08",
      start_time_minutes: "00",
      end_time_hour: "16",
      end_time_minutes: "00",
      hari: 0,
      loading_depo: "",
      loading_depo_length: 0,
      loading_depo_index: 0,
      loading_persent: 0,
      startOnline: `${this.props.data.beriWaktu.startOnline}`,
      endOnline: `${this.props.data.beriWaktu.endOnline}`,
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeDate(event) {
    let unixDate = 0;
    if (event.target.name === "start_date") {
      unixDate = new Date(`${event.target.value} 00:00:00`).getTime();
      this.setState({ start_: unixDate });
    }
    if (event.target.name === "end_date") {
      unixDate = new Date(`${event.target.value} 23:59:00`).getTime();
      this.setState({ end_: unixDate });
    }
    this.setState({
      [event.target.name]: unixDate,
    });
  }
  handleChangeNumber(event) {
    this.setState({ [event.target.name]: parseInt(event.target.value) });
  }
  handleChecked(event) {
    this.setState({ not_all: event.target.checked });
  }
  handleChangeTime(event) {
    const name = event.target.name;
    const value = event.target.value.split(":");
    this.setState({
      [`${name}_hour`]: value[0],
      [`${name}_minutes`]: value[1],
    });
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const hari = Math.ceil(
      (this.state.end_date - this.state.start_date) / (3600 * 24 * 1000)
    );
    this.setState({
      loading_process: !this.state.loading_process,
      hari: hari,
      loading_depo: "",
      loading_depo_length: 0,
      loading_persent: 0,
    });
    try {
      let body_object = {
        key: this.props.redux_data.selectedFilter1Obj.value,
        value: this.props.redux_data.selectedFilter2Obj
          .map((e) => e.label)
          .join(),
        start: this.state.start_date,
        end: this.state.end_date,
        not_all: !this.state.not_all,
        divisi_arr: this.props.redux_tracking.filter_divisi_child.join(),
      };
      if (this.props.redux_data.selectedFilter1Obj.key === "status") {
        body_object = {
          key: "status",
          value: "on",
          start: this.state.start_date,
          end: this.state.end_date,
          not_all: !this.state.not_all,
          divisi_arr: this.props.redux_tracking.filter_divisi_child.join(),
        };
      }
      let list_depo = [];
      const data_hirarki_depo = this.props.redux_data.salesman_filter.depo;
      if (body_object.key === "REGIONAL") {
        const region_arr = body_object.value.split(",");
        for (let index = 0; index < region_arr.length; index++) {
          const element = region_arr[index];
          const listDepo = data_hirarki_depo
            .filter((e) => e.REGION === element)
            .map((e) => e.Depo);
          list_depo = list_depo.concat(listDepo);
        }
      }
      if (body_object.key === "CABANG") {
        const cabang_arr = body_object.value.split(",");
        for (let index = 0; index < cabang_arr.length; index++) {
          const element = cabang_arr[index];
          const listDepo = data_hirarki_depo
            .filter((e) => e.CABANG === element)
            .map((e) => e.Depo);
          list_depo = list_depo.concat(listDepo);
        }
      }
      if (body_object.key === "Depo") {
        list_depo = body_object.value.split(",");
      }
      if (body_object.key === "All Salesman") {
        if (this.props.auth?.country_selected === "INDONESIA") {
          list_depo = this.props.data.salesman_filter.depo
            .filter((e) => e.folder_id === "63a4341e303d716c2f70bf04")
            .map((x) => {
              return x.Depo;
            });
        } else {
          list_depo = this.props.data.salesman_filter.depo
            .filter((e) => e.country === this.props.auth?.country_selected)
            .map((x) => {
              return x.Depo;
            });
        }
      }
      this.setState({
        loading_depo_length: list_depo.length,
      });
      let result = [];
      for (let index = 0; index < list_depo.length; index++) {
        const element = list_depo[index];
        body_object.key = "Depo";
        body_object.value = element;
        const res = await get_log_multiple(body_object);
        result = [...result, ...res];
        this.setState({
          loading_depo: element,
          loading_depo_index: index + 1,
          loading_persent: ((index + 1) / list_depo.length) * 100,
        });
      }

      // const result = await get_log_multiple(body_object, data_hirarki_depo);
      let data = [];
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        const generate_time = this.generate_time(element);
        element.log = generate_time;
        if (generate_time.length > 0) {
          element.total_hours = this.sum(generate_time, "total_hours").toFixed(
            1
          );
          element.percent_hour = this.sum(
            generate_time,
            "percent_hour"
          ).toFixed(1);
          const total_data = element.log
            .map((e) => e.total_data)
            .reduce((partialSum, a) => partialSum + a, 0);
          element.total_jam_kal = this.rumusHitungPrediksiJam(
            element.total_hours,
            total_data
          );
        } else {
          element.total_hours = "0";
          element.percent_hour = "0";
        }
        element.target_hour = this.state.target_hour;
        element.target_percent = this.state.target_percent;
        data.push(element);
      }
      this.setState({
        time_array_depo: data,
        loading_process: !this.state.loading_process,
      });
    } catch (error) {
      this.setState({ loading_process: !this.state.loading_process });
      console.log(error);
    }
  };
  handleSubmitSalesmanActive = async (event) => {
    event.preventDefault();
    this.setState({ loading_process: !this.state.loading_process });
    try {
      const nowDate = new Date();
      const month =
        nowDate.getMonth() + 1 > 9
          ? nowDate.getMonth() + 1
          : `0${nowDate.getMonth() + 1}`;
      const date =
        nowDate.getDate() > 9 ? nowDate.getDate() : `0${nowDate.getDate()}`;
      let body_object = {};
      if (
        this.props.redux_data.selectedFilter1Obj.label === "Online Salesman"
      ) {
        const nowDate = new Date();
        let endTime = nowDate.getTime();
        let startTime = nowDate.getTime() - 3_600_000;
        body_object = {
          start: startTime,
          end: endTime,
        };
      } else {
        body_object = {
          start: new Date(
            `${nowDate.getFullYear()}-${month}-${date} 00:00:00`
          ).getTime(),
          end: new Date(
            `${nowDate.getFullYear()}-${month}-${date} 23:59:00`
          ).getTime(),
          hour: nowDate.getHours(),
        };
      }

      let list_depo = [];
      if (this.props.auth?.country_selected === "INDONESIA") {
        list_depo = this.props.data.salesman_filter.depo
          .filter((e) => e.folder_id === "63a4341e303d716c2f70bf04")
          .map((x) => {
            return x.Depo;
          });
      } else {
        list_depo = this.props.data.salesman_filter.depo
          .filter((e) => e.country === this.props.auth?.country_selected)
          .map((x) => {
            return x.Depo;
          });
      }
      // const result = await get_log_multiple(body_object, list_depo);
      // const result = await get_log_salesman_active(body_object, list_depo);
      this.setState({
        loading_depo_length: list_depo.length,
      });
      let result = [];
      for (let index = 0; index < list_depo.length; index++) {
        const element = list_depo[index];
        body_object.key = "Depo";
        body_object.value = element;
        body_object.not_all = false;
        body_object.divisi_arr = "";
        body_object.status = this.props.redux_data.selectedFilter1Obj.key;
        const res = await get_log_multiple(body_object);
        result = [...result, ...res];
        this.setState({
          loading_depo: element,
          loading_depo_index: index + 1,
          loading_persent: ((index + 1) / list_depo.length) * 100,
        });
      }

      let data = [];
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        const generate_time = this.generate_time(element);
        element.log = generate_time;
        if (generate_time.length > 0) {
          element.total_hours = this.sum(generate_time, "total_hours").toFixed(
            1
          );
          element.percent_hour = this.sum(
            generate_time,
            "percent_hour"
          ).toFixed(1);
          const total_data = element.log
            .map((e) => e.total_data)
            .reduce((partialSum, a) => partialSum + a, 0);
          element.total_jam_kal = this.rumusHitungPrediksiJam(
            element.total_hours,
            total_data
          );
        } else {
          element.total_hours = "0";
          element.percent_hour = "0";
        }
        element.target_hour = this.state.target_hour;
        element.target_percent = this.state.target_percent;
        data.push(element);
      }
      this.setState({
        time_array_depo: data,
        loading_process: !this.state.loading_process,
      });
    } catch (error) {
      this.setState({ loading_process: !this.state.loading_process });
      console.log(error);
    }
  };

  //   event.preventDefault();
  //   this.setState({ loading_process: !this.state.loading_process });
  //   try {
  //     const nowDate = new Date();
  //     const month =
  //       nowDate.getMonth() + 1 > 9
  //         ? nowDate.getMonth() + 1
  //         : `0${nowDate.getMonth() + 1}`;
  //     const date =
  //       nowDate.getDate() > 9 ? nowDate.getDate() : `0${nowDate.getDate()}`;
  //     let body_object = {
  //       start: new Date(
  //         `${nowDate.getFullYear()}-${month}-${date} 00:00:00`
  //       ).getTime(),
  //       end: new Date(
  //         `${nowDate.getFullYear()}-${month}-${date} 23:59:00`
  //       ).getTime(),
  //       hour: nowDate.getHours(),
  //     };
  //     let list_depo = [];
  //     if (this.props.auth?.country_selected === "INDONESIA") {
  //       list_depo = this.props.data.salesman_filter.depo
  //         .filter((e) => e.folder_id === "63a4341e303d716c2f70bf04")
  //         .map((x) => {
  //           return x.Depo;
  //         });
  //     } else {
  //       list_depo = this.props.data.salesman_filter.depo
  //         .filter((e) => e.country === this.props.auth?.country_selected)
  //         .map((x) => {
  //           return x.Depo;
  //         });
  //     }
  //     const result = await get_log_salesman_online(body_object, list_depo);
  //     let data = [];
  //     for (let index = 0; index < result.length; index++) {
  //       const element = result[index];
  //       const generate_time = this.generate_time(element);
  //       element.log = generate_time;
  //       if (generate_time.length > 0) {
  //         element.total_hours = this.sum(generate_time, "total_hours").toFixed(
  //           1
  //         );
  //         element.percent_hour = this.sum(
  //           generate_time,
  //           "percent_hour"
  //         ).toFixed(1);
  //         const total_data = element.log
  //           .map((e) => e.total_data)
  //           .reduce((partialSum, a) => partialSum + a, 0);
  //         element.total_jam_kal = this.rumusHitungPrediksiJam(
  //           element.total_hours,
  //           total_data
  //         );
  //       } else {
  //         element.total_hours = "0";
  //         element.percent_hour = "0";
  //       }
  //       element.target_hour = this.state.target_hour;
  //       element.target_percent = this.state.target_percent;
  //       data.push(element);
  //     }
  //     this.setState({
  //       time_array_depo: data,
  //       loading_process: !this.state.loading_process,
  //     });
  //   } catch (error) {
  //     this.setState({ loading_process: !this.state.loading_process });
  //     console.log(error);
  //   }
  // };
  generate_time = (params = []) => {
    let lineString = params.log;
    let time_array = lineString.map((e) => e.time_stamp).flat();
    //time stamp generate date_iso & month iso untuk pembagian perbulan dan perhari
    time_array = time_array.map((t) => {
      const t_date = new Date(t);
      //Format date iso 2022-06-16
      // let month_iso = t_date.toISOString().split("T")[0];
      // month_iso = month_iso.slice(0, -2) + "01";
      // return {
      //   unix: t,
      //   date_iso: t_date.toISOString().split("T")[0],
      //   month_iso,
      // };
      const month =
        t_date.getMonth() < 10 ? `0${t_date.getMonth()}` : t_date.getMonth();
      const date = "01";
      let month_iso = `${t_date.getFullYear()}-${month}-${date}`;
      return {
        unix: t,
        date_iso: `${t_date.getFullYear()}-${
          t_date.getMonth() + 1
        }-${t_date.getDate()}`,
        month_iso,
      };
    });
    //generate bulan unik, supaya bisa dipisahkan berdasarkan bulan
    let month_array_unique = time_array.map((t) => t.month_iso);
    month_array_unique = [...new Set(month_array_unique)];
    //looping dibulan unik
    time_array = month_array_unique.map((m) => {
      //generate date unik supaya bisa dipisahkan berdasarkan tanggal
      let date_array_unique = time_array.filter((t) => t.month_iso === m);
      date_array_unique = date_array_unique.map((t) => t.date_iso);
      date_array_unique = [...new Set(date_array_unique)];
      // date_array_unique = time_array.map((t) => t.date_iso);
      // //looping di date unik
      let hour_start = parseInt(this.state.start_time_hour);
      const min_start = parseInt(this.state.start_time_minutes);
      hour_start = hour_start + min_start / 60;

      let hour_end = parseInt(this.state.end_time_hour);
      const min_end = parseInt(this.state.end_time_minutes);
      hour_end = hour_end + min_end / 60;

      date_array_unique = date_array_unique.map((d) => {
        let hour_array = time_array.filter((t) => t.date_iso === d);
        hour_array = hour_array.map((t) => {
          let jam_desimal = parseInt(new Date(t.unix).getHours());
          const min_desimal = parseInt(new Date(t.unix).getMinutes());
          jam_desimal = jam_desimal + min_desimal / 60;
          return {
            ...t,
            jam_desimal,
          };
        });
        hour_array = hour_array.filter(
          (t) => t.jam_desimal >= hour_start && t.jam_desimal <= hour_end
        );
        //get first hour
        const first_hour = hour_array?.[0]?.unix ? hour_array?.[0]?.unix : 0;
        //get last hour
        const last_hour = hour_array?.[hour_array.length - 1]?.unix
          ? hour_array?.[hour_array.length - 1]?.unix
          : 0;
        //get delta unix
        const delta_unix = parseInt(last_hour) - parseInt(first_hour);
        let seconds = parseFloat(delta_unix / 1000).toFixed(3);
        let minutes = parseFloat(seconds / 60).toFixed(3);
        let delta_hours = Number(parseFloat(minutes / 60).toFixed(3));
        //get the amount of data in one day
        const total_data = hour_array.length;
        //filter hari minggu
        let day_index = new Date(d);
        day_index = day_index.getDay();
        return {
          day_index,
          date_iso: d,
          hour_array,
          first_hour,
          last_hour,
          delta_unix,
          delta_hours,
          total_data,
        };
      });
      //0 = minggu
      date_array_unique = date_array_unique.filter((d) => d.day_index !== 0);
      let total_hours = date_array_unique.map((d) => d.delta_hours);
      total_hours = total_hours.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
      const percent_hour = (total_hours / this.state.target_hour) * 100;
      const target_percent = this.state.target_percent;

      let total_data = 0;
      date_array_unique.forEach((d) => {
        total_data += d.total_data;
      });
      return {
        month_iso: m,
        date_array_unique,
        total_hours,
        percent_hour,
        target_percent,
        total_data,
      };
    });
    return time_array;
  };
  sum = (obj, key) => {
    return obj.reduce((a, b) => a + (b[key] || 0), 0);
  };
  generateDataCSV = (data, done) => {
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });

      try {
        let tempData = [];
        const header = [
          "Salesman",
          "Employee ID",
          "Depo",
          "Total Days",
          "Total Hours",
          "Hours Target",
          "Percentation of achievement (%)",
          "Achievement Target (%)",
          "Achievement Status",
          "Kode Sales",
          "Plan",
          "Divisi",
        ];
        tempData.push(header);
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let percent_hour = "0";
          let kodeSales = element?.kd_sales_arr?.[0]
            ? element?.kd_sales_arr?.[0]
            : "0";
          let divisi = element.divisi_arr[0];
          if (element["total_jam_kal"]) {
            percent_hour = (
              (element["total_jam_kal"] / element["target_hour"]) *
              100
            ).toFixed(1);
          } else {
            percent_hour = "0";
          }
          tempData.push([
            element["Nama Only"].toUpperCase(),
            element["NIK"],
            element["Depo"],
            this.cal(element["log"]) > this.state.hari
              ? this.state.hari
              : this.cal(element["log"]),
            // element["total_hours"],
            element["total_jam_kal"] ? element["total_jam_kal"] : "0",
            element["target_hour"] ? element["target_hour"] : "0",
            // element["percent_hour"],
            percent_hour,
            element["target_percent"] ? element["target_percent"] : "0",
            percent_hour > element["target_percent"] ? "Yes" : "No",
            kodeSales,
            element["Kode Plan"],
            divisi,
          ]);
        }
        // tempData.push(["Tanggal Awal", new Date(this.state.start_date)]);
        // tempData.push(["Tanggal Akhir", new Date(this.state.end_date)]);
        // tempData.push(["Target Jam", this.state.target_hour]);
        // tempData.push(["Total Salesman", data.length]);
        // tempData.push([
        //   "Depo",
        //   this.props.redux_data.selectedFilter2Obj.map((e) => e.label).join(),
        // ]);
        this.setState({
          loading_csv: false,
          dataCSV: tempData,
        });
      } catch (error) {
        console.log(error);
        this.setState({
          loading: false,
        });
      }
    }
  };
  generateDataExcel = async (data, done) => {
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });
      try {
        const schema = [
          {
            column: "Salesman",
            type: String,
            value: (driver) => driver["Nama Salesman"],
          },
          {
            column: "Employee ID",
            type: String,
            value: (driver) => driver.NIK,
          },
          {
            column: "Depo",
            type: String,
            value: (driver) => driver.Depo,
          },
          {
            column: "Total Days",
            type: String,
            value: (driver) => driver.total_days,
          },
          {
            column: "Total Hours",
            type: String,
            value: (driver) => driver.total_hours,
          },
          {
            column: "Hours Target",
            type: String,
            value: (driver) => driver.target_hour,
          },
          {
            column: "Percentation of archievment (%)",
            type: String,
            value: (driver) => driver.percent_hour,
          },
          {
            column: "Achievement Target (%)",
            type: String,
            value: (driver) => driver.target_percent,
          },
          {
            column: "Achievement Status",
            type: String,
            value: (driver) => driver.archivement_status,
          },
          {
            column: "Kode Sales",
            type: String,
            value: (salesman) => salesman.kodeSales,
          },
          {
            column: "Plan",
            type: String,
            value: (salesman) => salesman["Kode Plan"],
          },
          {
            column: "Divisi",
            type: String,
            value: (salesman) => salesman.divisi,
          },
        ];
        let newData = [];
        let percent_hour = "0";
        data?.forEach((e) => {
          let kodeSales = e?.kd_sales_arr?.[0] ? e?.kd_sales_arr?.[0] : "0";
          let divisi = e?.divisi_arr?.[0];
          if (e?.["total_jam_kal"]) {
            percent_hour = (
              (e?.["total_jam_kal"] / e?.["target_hour"]) *
              100
            ).toFixed(1);
          } else {
            percent_hour = "0";
          }
          let getData = {
            "Nama Salesman": e?.["Nama Salesman"].toUpperCase(),
            NIK: e?.NIK,
            Depo: e?.Depo,
            total_days: `${
              this.cal(e?.["log"]) > this.state.hari
                ? this.state.hari
                : this.cal(e?.["log"])
            }`,
            total_hours: e?.["total_jam_kal"] ? `${e?.["total_jam_kal"]}` : "0",
            target_hour: `${e?.target_hour}`,
            percent_hour: `${percent_hour}`,
            target_percent: `${e?.target_percent}`,
            archivement_status:
              e?.percent_hour > e?.target_percent ? "Yes" : "No",
            kodeSales: `${kodeSales}`,
            "Kode Plan": e?.["Kode Plan"],
            divisi: divisi,
          };
          newData.push(getData);
        });
        await writeXlsxFile(newData, {
          schema,
          fileName: "DataExcelSalesman.xlsx",
        });
        this.setState({
          loading_csv: false,
        });
      } catch (error) {
        console.log(error);
        this.setState({
          loading: false,
        });
      }
    }
  };
  cal = (data) => {
    let total_hari = 0;
    data.forEach((element) => {
      total_hari += element.date_array_unique.length;
    });
    return total_hari;
  };
  rumusHitungPrediksiJam = (totalJam, totalData) => {
    if (
      totalJam === "-" ||
      totalJam === "0.0" ||
      totalJam === "0" ||
      parseFloat(totalJam) === 0.0
    ) {
      return 0;
    }
    const totalMenit = parseFloat(totalJam) * 60;
    const interval = totalMenit / totalData;
    let part = 1 / interval;
    if (part > 1) {
      part = 1;
    }
    const result = parseFloat((part * totalMenit) / 60).toFixed(1);
    return result;
  };
  render() {
    const {
      time_array_depo,
      dataCSV,
      separator,
      loading_csv,
      start_time_hour,
      start_time_minutes,
      end_time_hour,
      end_time_minutes,
      startOnline,
      endOnline,
    } = this.state;
    const { redux_data } = this.props;
    let header_content = (
      <div className="mt-4">
        Selected {redux_data.selectedFilter1Obj.label}:{" "}
        {redux_data.selectedFilter1Obj.label === "All Salesman" ? (
          <span className="font-semibold">ALL SALESMAN</span>
        ) : (
          <span className="font-semibold">
            {redux_data.selectedFilter2Obj.map((e) => e.label).join()}
          </span>
        )}
        {/* <span className="font-semibold">
          {redux_data.selectedFilter2Obj.map((e) => e.label).join()}
        </span> */}
      </div>
    );
    if (redux_data.selectedFilter1Obj.label === "Active Salesman") {
      header_content = (
        <div className="mt-4">{redux_data.selectedFilter1Obj.label}</div>
      );
    }
    if (redux_data.selectedFilter1Obj.label === "Online Salesman") {
      header_content = (
        <div className="mt-4">{redux_data.selectedFilter1Obj.label}</div>
      );
    }
    if (redux_data.selectedFilter1Obj.label === "Salesman Registration") {
      header_content = (
        <div className="mt-4">{redux_data.selectedFilter1Obj.label}</div>
      );
    }

    let filter_content = (
      <form className="mt-4" onSubmit={this.handleSubmit}>
        <div className="grid grid-cols-2 gap-2">
          <div className="flex flex-col">
            <label htmlFor="start_date">Choose Start Date</label>
            <input
              type="date"
              className="w-full"
              id="start_date"
              name="start_date"
              onChange={this.handleChangeDate}
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="end_date">Choose End Date</label>
            <input
              type="date"
              className="w-full"
              id="end_date"
              name="end_date"
              onChange={this.handleChangeDate}
              required
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 mt-1 items-end">
          <div className="flex flex-col">
            <label htmlFor="target_hour">
              Total of the work hours at the selected range
            </label>
            <input
              type="number"
              id="target_hour"
              name="target_hour"
              className="w-full"
              onChange={this.handleChangeNumber}
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="target_percent">
              Limit of achievement percentation (0 - 100%)
            </label>
            <input
              type="number"
              id="target_percent"
              name="target_percent"
              className="w-full"
              onChange={this.handleChangeNumber}
              defaultValue={"100"}
              required
              min="0"
              max="100"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 mt-1 items-end">
          <div className="flex flex-col">
            <label htmlFor="start_time">Choose Start Hour</label>
            <input
              type="time"
              className="w-full"
              id="start_time"
              name="start_time"
              required
              defaultValue={`${start_time_hour}:${start_time_minutes}`}
              onChange={this.handleChangeTime}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="end_time">Choose End Hour</label>
            <input
              type="time"
              className="w-full"
              id="end_time"
              name="end_time"
              required
              defaultValue={`${end_time_hour}:${end_time_minutes}`}
              onChange={this.handleChangeTime}
            />
          </div>
        </div>
        <div className="mt-4">
          <button className="button_main w-full" type="submit">
            {this.state.loading_process
              ? "Loading..."
              : "Process Salesman Data"}
          </button>
        </div>
      </form>
    );
    if (
      redux_data.selectedFilter1Obj.label === "Active Salesman" ||
      redux_data.selectedFilter1Obj.label === "Online Salesman"
    ) {
      filter_content = (
        <form className="mt-4" onSubmit={this.handleSubmitSalesmanActive}>
          <div className="grid grid-cols-2 gap-2 mt-1 items-end">
            <div className="flex flex-col">
              <label htmlFor="target_hour">
                Total of the work hours target at the selected range
              </label>
              <input
                type="number"
                id="target_hour"
                name="target_hour"
                className="w-full"
                onChange={this.handleChangeNumber}
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="target_percent">
                Limit of achievement percentation (0 - 100%)
              </label>
              <input
                type="number"
                id="target_percent"
                name="target_percent"
                className="w-full"
                onChange={this.handleChangeNumber}
                defaultValue={"100"}
                required
                min="0"
                max="100"
              />
            </div>
          </div>
          {redux_data.selectedFilter1Obj.label === "Active Salesman" ? (
            <div className="grid grid-cols-2 gap-2 mt-1 items-end">
              <div className="flex flex-col">
                <label htmlFor="start_time">Choose Start Hour</label>
                <input
                  type="time"
                  className="w-full"
                  id="start_time"
                  name="start_time"
                  required
                  defaultValue={`${start_time_hour}:${start_time_minutes}`}
                  onChange={this.handleChangeTime}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="end_time">Choose End Hour</label>
                <input
                  type="time"
                  className="w-full"
                  id="end_time"
                  name="end_time"
                  required
                  defaultValue={`${end_time_hour}:${end_time_minutes}`}
                  onChange={this.handleChangeTime}
                />
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-2 mt-1 items-end">
              <div className="flex flex-col">
                <label htmlFor="start_time">Choose Start Hour</label>
                <input
                  type="time"
                  className="w-full"
                  id="start_time"
                  name="start_time"
                  required
                  defaultValue={`${endOnline}:${start_time_minutes}`}
                  onChange={this.handleChangeTime}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="end_time">Choose End Hour</label>
                <input
                  type="time"
                  className="w-full"
                  id="end_time"
                  name="end_time"
                  required
                  defaultValue={`${startOnline}:${end_time_minutes}`}
                  onChange={this.handleChangeTime}
                />
              </div>
            </div>
          )}
          <div className="mt-4">
            <button className="button_main w-full" type="submit">
              {this.state.loading_process
                ? "Loading..."
                : "Process Salesman Data"}
            </button>
          </div>
        </form>
      );
    }

    if (redux_data.selectedFilter1Obj.label === "All Salesman") {
      filter_content = (
        <form className="mt-4" onSubmit={this.handleSubmit}>
          <div className="grid grid-cols-2 gap-2">
            <div className="flex flex-col">
              <label htmlFor="start_date">Choose Start Date</label>
              <input
                type="date"
                className="w-full"
                id="start_date"
                name="start_date"
                onChange={this.handleChangeDate}
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="end_date">Choose End Date</label>
              <input
                type="date"
                className="w-full"
                id="end_date"
                name="end_date"
                onChange={this.handleChangeDate}
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 mt-1 items-end">
            <div className="flex flex-col">
              <label htmlFor="target_hour">
                Total of the work hours at the selected range
              </label>
              <input
                type="number"
                id="target_hour"
                name="target_hour"
                className="w-full"
                onChange={this.handleChangeNumber}
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="target_percent">
                Limit of achievement percentation (0 - 100%)
              </label>
              <input
                type="number"
                id="target_percent"
                name="target_percent"
                className="w-full"
                onChange={this.handleChangeNumber}
                defaultValue={"100"}
                required
                min="0"
                max="100"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 mt-1 items-end">
            <div className="flex flex-col">
              <label htmlFor="start_time">Choose Start Hour</label>
              <input
                type="time"
                className="w-full"
                id="start_time"
                name="start_time"
                required
                defaultValue={`${start_time_hour}:${start_time_minutes}`}
                onChange={this.handleChangeTime}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="end_time">Choose End Hour</label>
              <input
                type="time"
                className="w-full"
                id="end_time"
                name="end_time"
                required
                defaultValue={`${end_time_hour}:${end_time_minutes}`}
                onChange={this.handleChangeTime}
              />
            </div>
          </div>
          <div className="mt-4">
            <button className="button_main w-full" type="submit">
              {this.state.loading_process
                ? "Loading..."
                : "Process Salesman Data"}
            </button>
          </div>
        </form>
      );
    }
    if (redux_data.selectedFilter1Obj.label === "Salesman Registration") {
      filter_content = (
        <div className="mt-4">
          Download feature of long tracking for{" "}
          <b>{redux_data.selectedFilter1Obj.label}</b> under construction
        </div>
      );
    }
    const {
      loading_depo,
      loading_persent,
      loading_depo_length,
      loading_depo_index,
    } = this.state;
    const loading_content = (
      <div className="mt-4">
        <div className="flex">
          <div className="flex-1">{loading_depo}</div>
          <div>
            {loading_depo_index}/{loading_depo_length}
          </div>
        </div>
        <div className="h-2 bg-gray-400 rounded-full relative">
          <div
            className={`h-2 bg-green-600 rounded-full`}
            style={{ width: `${loading_persent}%` }}
          ></div>
        </div>
      </div>
    );
    return (
      <React.Fragment>
        {header_content}
        {filter_content}
        {loading_content}
        <div className="mt-2">
          {time_array_depo.length === 0 ? "" : "Preview data csv:"}
        </div>
        <div
          className={`overflow-x-auto overflow-y-auto max-h-40 mt-2 ${
            time_array_depo.length === 0 && "hidden"
          }`}
        >
          <table className="mt-2 w-full table-auto border-collapse border mb-4">
            <thead>
              <tr>
                <th className="border text-left whitespace-nowrap px-1">
                  Salesman
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  Employee ID
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  Depo
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  Total Days
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  Total Hours
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  Hours Target
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  Percentation of achievement (%)
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  Achievement Target (%)
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  Achievement Status
                </th>
              </tr>
            </thead>
            <tbody>
              {time_array_depo.length > 0 &&
                time_array_depo.map((d, i) => (
                  <tr key={i}>
                    <td className="border whitespace-nowrap px-1">
                      {d["Nama Only"].toUpperCase()}
                    </td>
                    <td className="border whitespace-nowrap px-1">
                      {d["NIK"]}
                    </td>
                    <td className="border whitespace-nowrap px-1">
                      {d["Depo"]}
                    </td>
                    <td className="border whitespace-nowrap px-1">
                      {/* {d["log"].length} */}
                      {this.cal(d["log"]) > this.state.hari
                        ? this.state.hari
                        : this.cal(d["log"])}
                    </td>
                    <td className="border whitespace-nowrap px-1">
                      {/* {d["total_hours"]} */}
                      {d["total_jam_kal"] ? d["total_jam_kal"] : "0"}
                    </td>
                    <td className="border whitespace-nowrap px-1">
                      {d["target_hour"]}
                    </td>
                    {/* {d["percent_hour"]} */}
                    {d["total_jam_kal"] ? (
                      <td className="border whitespace-nowrap px-1">
                        {(
                          (d["total_jam_kal"] / d["target_hour"]) *
                          100
                        ).toFixed(1)}
                      </td>
                    ) : (
                      <td className="border whitespace-nowrap px-1">0</td>
                    )}
                    <td className="border whitespace-nowrap px-1">
                      {d["target_percent"]}
                    </td>
                    <td className="border whitespace-nowrap px-1">
                      {(d["total_jam_kal"] / d["target_hour"]) * 100 >=
                      d["target_percent"]
                        ? "Yes"
                        : "No"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className={`${time_array_depo.length === 0 && "hidden"} mt-2`}>
          {this.props.mode === "excel" ? (
            <button
              className="button_main w-full"
              onClick={(e, done) => {
                this.generateDataExcel(time_array_depo, done);
              }}
            >
              {loading_csv
                ? "Download data . . ."
                : "Download Target vs Actual Working Hours"}
            </button>
          ) : (
            <CSVLink
              className="button_main w-full"
              data={dataCSV}
              separator={separator}
              filename={`Salesman Data.csv`}
              onClick={(e, done) => {
                this.generateDataCSV(time_array_depo, done);
              }}
            >
              {loading_csv
                ? "Download data . . ."
                : "Download Target vs Actual Working Hours"}
            </CSVLink>
          )}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
  redux_data: state.data,
  redux_tracking: state.tracking,
  auth: state.auth,
});
export default connect(mapStateToProps, {})(DownloadTracking);
