import list_menu_active from "./localData/list_menu_active.json";
import hirarkiFilter from "./localData/hirarkiFilter.json";
import sort_array from "../validation/sort_array";
import st_colors from "../validation/st_colors.json";

const initial_state = {
  outlet_merch_list: [],
  outlet_merch_list_filter_0: [],
  outlet_merch_list_back_up: [],
  outlet_merch_list_filter_0_back_up: [],

  /*ATRIBUT RAYONISASI*/
  jumlah_toko: 300,
  jumlah_rayon: 0,
  mode_pembagian_rayon: "jumlah_toko", // jumlah_toko | jumlah_omset | jumlah_qty
  mode_alg: "box", //box | group
  mode_grouping: "KELURAHAN", //KELURAHAN | KECAMATAN
  jumlah_hari: 6,

  /*BASIC & LOADING*/
  fly_status: true, //apakah ketika ada perubahan list mau zoom atau tidak
  version: process.env.REACT_APP_VERSION,
  loading_status: false,
  loading_item: "",
  loading_top: false,
  progress_edit_hari: 0,
  tool_status: false, //tool sidebar di kanan untuk download KPI salesman atau rayonisasi
  filter_status: false, //filter sidebar di kanan untuk filter toko
  bulk_add_salesman_status: [],
  bulk_add_outlet_status: [],
  total: 1,
  current: 0,
  current_array_add: [],
  current_array_edit: [],

  agg_list: [], //aggregate outlet list

  template_list: [],
  apk_version_list: [],
  depo_list: [],
  preview_rayon_list: [],

  competitor_code_list: [],
  type_outlet_list: [],
  segment_list: [],
  other_list_hide: {},
  all_hide: {},
  id_toko_selected: [],

  competitor_count: 0,
  competitor_national_count: 0,
  competitor_national_count_not_done: 0,
  toko_rayon_count: 0,
  rayon_list_database: [],
  rayon_check_status: "not_yet", // not_yet | checked | saved
  city_rayon: [],
  rayon_list_local: [],
  rayon_select: "",

  cycle_select: "",
  salesman_select: {},
  day_list: [],

  /*PARAM POLIGON*/
  filter_mode: "kel",
  filter_param: [],
  pick_status: "form",
  polygon_adm: {},
  kabupaten_list: [],
  provinsi_list: [],
  kecamatan_list: [],
  kelurahan_list: [],

  /*PARAM SALESMAN*/
  selected_salesman: "",
  salesman_mode: "status", // cluster,icon,name,status,
  outlet_mode: "point", // cluster,point
  route_mode: false,
  salesman_filter: {
    depo: [],
    cabang: [],
    regional: [],
  },
  salesman_filter_mode: "",
  salesman_filter_val: "",
  salesman_total_count: 0,
  selected_hari: "semua",
  salesman_filter_original_cabang: [],
  salesman_filter_original_regional: [],

  // edit salesman
  current_salesman: {},

  // Divisi
  selection_divisi: hirarkiFilter.map((e) => e.DETAIL),

  /*DATA LIST TIAP MENU*/
  /*String satu menu yang sedang aktif*/
  selected_menu: "",
  mode_toko_hari: "sebelum",
  legend_color_toko_by_salesman: [],

  /*Distribution*/
  outlet_list: [],
  outlet_list_backup: [],
  outlet_list_filter_0_back_up: [],
  outlet_list_filter_0: [],

  outlet_list_filter_0_back_up_plan: [],
  outlet_list_select_0: [],
  outlet_list_table: [],
  outlet_list_table_backup: [],
  outlet_multi_select_data: false,

  /*Merchandising*/
  merch_list: [],
  merch_list_filter_0: [],
  statusWarna: false,

  /*Promotion*/
  promo_list: [],
  promo_list_filter_0: [],

  /*Stock*/
  stock_list: [],
  stock_list_filter_0: [],

  /*Sales Value Average Monhtly*/
  omset_list_backup: [],
  omset_list: [],
  omset_list_filter_0: [],
  omset_list_filter_0_backup: [],

  omset_history: [],
  omset_satu_bulan: [],
  omset_product: [],
  omset_product_bu: [],
  omset_product_brand: [],
  omset_product_sku: [],
  omset_split: [],

  /*Salesman Omset*/
  get_omset_salesman: [],
  salesman_list_omset: [],
  backup_salesman_list_omset: [],
  salesman_list_omset_filter_0: [],

  /*Service Level*/
  service_list: [],
  service_list_filter_0: [],

  /*Call vs effective call*/
  call_list: [],
  call_list_filter_0: [],

  /*Competitor Outlet*/

  competitor_success_list: [],
  competitor_success_list_origin: [],
  competitor_failed_list: [],
  competitor_failed_list_origin: [],

  /*Market Intelligence*/
  market_list: [],
  market_list_filter_0: [],

  /*Insentif Scheme*/
  insentif_list: [],
  insentif_list_filter_0: [],

  /*Planning & Tracking Salesman*/
  salesman_list: [],
  salesman_list_step_5: [],
  salesman_list_step_7: [],
  salesman_list_filter_0: [],
  backup_salesman_list: [],
  selectedFilter1Obj: { label: "Select", value: "Pilih" },
  selectedFilter2Obj: [],
  listSelectFilter2: [],
  selectedFilter2MultiLength: 0,
  withOutFilter2: true,
  dataPopupToko: {},
  dataPopupText: "",

  driver_list: [],
  driver_list_filter_0: [],

  /*Outlet list*/
  outlet_list_by_salesman: [],
  outlet_list_by_salesman_filter_0: [],
  backup_outlet_list_by_salesman: [],
  outlet_filter_hari_kunjungan: [],
  outlet_filter_freq_kunjungan: [],

  omset_by_array_outlet_id: [],

  /*Admin Dashboard*/
  admin_list: [],

  /*plotting*/
  toko_plotting: [],
  backup_toko_plotting: [],

  /*depo_loc_list*/
  depo_loc_list: [],
  depo_loc_list_back_up: [],

  /*STATUS AKTIF TIAP MENU*/
  list_menu_active: list_menu_active,
  //sidebar
  sidebarBreakpoints: "sm", //sm, md, lg,
  div_toko: "",
  siklus: "",
  total_toko: 0,
  progres_toko: 0,

  //conclusion
  dataBusiness: [],
  dataBrand: [],
  dataProduction: [],
  dataPrinciple: [],

  dataPrincipleBackup: [],
  dataBrandBackup: [],
  dataProductionBackup: [],
  dataBusinessBackup: [],

  //listTypeToko
  listTypeToko: [],

  //getDate Salesman
  getDate: [],
  productSalesman: [],
  monthSalesman: [],

  //getData Mongos And Postgress Omset
  getDataBaseOmset: [],

  //depo_folder
  depo_folder_list: [],

  //ambilWaktu
  beriWaktu: {},

  //array parameter filter untuk outlet terbaru
  depo_filter_array: [], //1
  system_filter_array: [], //2
  segment_filter_array: [], //3
  type_filter_array: [], //4
  rayon_filter_array: [], //5
  day_filter_array: [], //6
  cycle_filter_array: [], //7
  provinsi_filter_array: [], //8
  kabupaten_filter_array: [], //9
  kecamatan_filter_array: [], //10
  kelurahan_filter_array: [], //11

  show_all_status: {
    depo_filter_array: true,
    system_filter_array: true,
    segment_filter_array: true,
    type_filter_array: true,
    rayon_filter_array: true,
    day_filter_array: true,
    cycle_filter_array: true,
    provinsi_filter_array: true,
    kabupaten_filter_array: true,
    kecamatan_filter_array: true,
    kelurahan_filter_array: true,
  },

  filter_outlet_object: {
    depo_filter_array: {
      properties_name: "Depo",
    },
    system_filter_array: {
      properties_name: "SYSTEM",
    },
    segment_filter_array: {
      properties_name: "SEGMENT",
    },
    type_filter_array: {
      properties_name: "Type Outlet",
    },
    rayon_filter_array: {
      properties_name: "rayon",
    },
    day_filter_array: {
      properties_name: "HARIKunjungan",
    },
    cycle_filter_array: {
      properties_name: "FreqVisit",
    },
    provinsi_filter_array: {
      properties_name: "PROPINSI",
    },
    kabupaten_filter_array: {
      properties_name: "KABUPATEN",
    },
    kecamatan_filter_array: {
      properties_name: "KECAMATAN",
    },
    kelurahan_filter_array: {
      properties_name: "KELURAHAN",
    },
  },
};

const filter_outlet_object = {
  depo_filter_array: {
    properties_name: "Depo",
  },
  system_filter_array: {
    properties_name: "SYSTEM",
  },
  segment_filter_array: {
    properties_name: "SEGMENT",
  },
  type_filter_array: {
    properties_name: "Type Outlet",
  },
  rayon_filter_array: {
    properties_name: "rayon",
  },
  day_filter_array: {
    properties_name: "HARIKunjungan",
  },
  cycle_filter_array: {
    properties_name: "FreqVisit",
  },
  provinsi_filter_array: {
    properties_name: "PROPINSI",
  },
  kabupaten_filter_array: {
    properties_name: "KABUPATEN",
  },
  kecamatan_filter_array: {
    properties_name: "KECAMATAN",
  },
  kelurahan_filter_array: {
    properties_name: "KELURAHAN",
  },
};

//outlet_list_filter_0
//outlet_list_filter_0_back_up

const filter_outlet_list = ({
  outlet_list,
  only_show_array,
  properties_name,
}) => {
  let outlet_result = [];
  outlet_list.forEach((outlet) => {
    if (only_show_array.includes(outlet[properties_name]))
      outlet_result.push(outlet);
  });
  return outlet_result;
};

const filter_merch_list = ({
  outlet_list,
  only_show_array,
  properties_name,
}) => {
  let outlet_result = [];
  outlet_list.forEach((item) => {
    if (only_show_array.includes(item?.outlet_id?.[properties_name]))
      outlet_result.push(item);
  });
  return outlet_result;
};

export default function dataReducer(state = initial_state, action) {
  switch (action.type) {
    case "set_value_data":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case "hide_all_filter_array":
      return {
        ...state,
        depo_filter_array: hide_all_filter_array(state.depo_filter_array), //1
        system_filter_array: hide_all_filter_array(state.system_filter_array), //2
        segment_filter_array: hide_all_filter_array(state.segment_filter_array), //3
        type_filter_array: hide_all_filter_array(state.type_filter_array), //4
        rayon_filter_array: hide_all_filter_array(state.rayon_filter_array), //5
        day_filter_array: hide_all_filter_array(state.day_filter_array), //6
        cycle_filter_array: hide_all_filter_array(state.cycle_filter_array), //7
        provinsi_filter_array: hide_all_filter_array(
          state.provinsi_filter_array
        ), //8
        kabupaten_filter_array: hide_all_filter_array(
          state.kabupaten_filter_array
        ), //9
        kecamatan_filter_array: hide_all_filter_array(
          state.kecamatan_filter_array
        ), //10
        kelurahan_filter_array: hide_all_filter_array(
          state.kelurahan_filter_array
        ), //11

        outlet_list_filter_0: [],
        outlet_merch_list: [],
        outlet_merch_list_filter_0: [],
        omset_list: [],
        omset_list_filter_0: [],
      };
    case "show_all_filter_array":
      return {
        ...state,
        ...show_all_filter_array({
          array_state: state[action.payload.array_name],
          array_name: action.payload.array_name,

          outlet_list: state.outlet_list_filter_0_back_up,
          outlet_list_survey: state.backup_toko_plotting,
          outlet_merch_list: state.outlet_merch_list_back_up,
          outlet_merch_list_filter_0: state.outlet_merch_list_filter_0_back_up,
          omset_list: state.omset_list_backup,
          omset_list_filter_0: state.omset_list_filter_0_backup,
        }),
      };
    case "hide_filter_array":
      return {
        ...state,
        ...hide_filter_array({
          array_state: state[action.payload.array_name],
          element_name: action.payload.element_name,
          array_name: action.payload.array_name,

          outlet_list: state.outlet_list_filter_0_back_up,
          outlet_list_survey: state.backup_toko_plotting,
          outlet_merch_list: state.outlet_merch_list_back_up,
          outlet_merch_list_filter_0: state.outlet_merch_list_filter_0_back_up,
          omset_list: state.omset_list_backup,
          omset_list_filter_0: state.omset_list_filter_0_backup,
        }),
      };
    case "show_filter_array":
      return {
        ...state,
        ...show_filter_array({
          array_state: state[action.payload.array_name],
          element_name: action.payload.element_name,
          array_name: action.payload.array_name,

          outlet_list: state.outlet_list_filter_0_back_up,
          outlet_list_survey: state.backup_toko_plotting,
          outlet_merch_list: state.outlet_merch_list_back_up,
          outlet_merch_list_filter_0: state.outlet_merch_list_filter_0_back_up,
          omset_list: state.omset_list_backup,
          omset_list_filter_0: state.omset_list_filter_0_backup,
        }),
      };
    case "push_unique_items_to_array":
      return {
        ...state,
        [action.payload.key]: push_unique_items_to_array(
          state[action.payload.key],
          action.payload.value
        ),
      };
    case "edit_rayon_location":
      return {
        ...state,
        rayon_list_database: edit_rayon_location(
          state.rayon_list_database,
          action.payload
        ),
      };
    case "hide_outlet_without_rayon":
      return {
        ...state,
        outlet_list_filter_0: state.outlet_list_filter_0_back_up.filter(
          (t) => t.rayon_id
        ),
      };
    case "show_all_outlet":
      return {
        ...state,
        outlet_list_filter_0: state.outlet_list_filter_0_back_up,
      };
    case "push_rayon_list":
      return {
        ...state,
        rayon_list_database: [...state.rayon_list_database, ...action.payload],
      };
    case "edit_preview_rayon_list_mode":
      return {
        ...state,
        preview_rayon_list: edit_preview_rayon_list_mode(
          state.preview_rayon_list,
          action.payload
        ),
      };
    case "edit_preview_rayon_list_v_manual":
      return {
        ...state,
        preview_rayon_list: edit_preview_rayon_list_v_manual(
          state.preview_rayon_list,
          action.payload
        ),
      };
    case "ow_salesman_rayon": //ow = overwrite
      return {
        ...state,
        rayon_list_database: ow_salesman_rayon(
          state.rayon_list_database,
          action.payload
        ),
      };
    case "ow_salesman_outlet":
      return {
        ...state,
        outlet_list_filter_0_back_up: ow_salesman_outlet(
          state.outlet_list_filter_0_back_up,
          action.payload
        ),
      };
    case "edit_many_toko_rayon":
      return {
        ...state,
        outlet_list_filter_0_back_up: edit_many_toko_rayon(
          state.outlet_list_filter_0_back_up,
          action.payload
        ),
      };
    case "create_item":
      return {
        ...state,
        depo_folder_list: state.depo_folder_list.concat(action.payload),
      };
    case "get_item_list":
      return {
        ...state,
        depo_folder_list: action.payload,
      };
    case "edit_item":
      return {
        ...state,
        depo_folder_list: edit_item(state.depo_folder_list, action.payload),
      };
    case "delete_item":
      return {
        ...state,
        depo_folder_list: state.depo_folder_list.filter(
          (e) => e._id !== action.payload.item_id
        ),
      };
    case "get_cons_business":
      return {
        ...state,
        dataBusiness: action.payload,
        dataBusinessBackup: [...state.dataBusiness, ...action.payload],
      };
    case "get_cons_brand":
      return {
        ...state,
        dataBrand: action.payload,
        dataBrandBackup: [...state.dataBrand, ...action.payload],
      };
    case "get_cons_production":
      return {
        ...state,
        dataProduction: action.payload,
        dataProductionBackup: [...state.dataProduction, ...action.payload],
      };
    case "get_cons_principle":
      return {
        ...state,
        dataPrinciple: action.payload,
        dataPrincipleBackup: [...state.dataPrinciple, ...action.payload],
      };
    case "edit_depo_local":
      return {
        ...state,
        depo_loc_list: edit_depo_local(state.depo_loc_list, action.payload),
      };
    case "manual_add_depo":
      return {
        ...state,
        depo_loc_list: manual_add_depo(state.depo_loc_list, action.payload),
      };
    case "reset_rayon_outlet":
      return {
        ...state,
        outlet_list_filter_0: reset_rayon_outlet(
          state.outlet_list_filter_0_back_up,
          action.payload
        ),
      };
    case "push_plan_list":
      return {
        ...state,
        rayon_list_database: [
          ...push_plan_list(state.rayon_list_database, action.payload),
        ],
      };
    case "set_plan_list":
      return {
        ...state,
        rayon_list_database: [
          ...set_plan_list(state.rayon_list_database, action.payload),
        ],
      };
    case "set_cycle_list_to_rayon":
      return {
        ...state,
        rayon_list_database: set_cycle_list_to_rayon(
          state.rayon_list_database,
          action.payload
        ),
      };
    case "set_kunjungan_arr_new":
      return {
        ...state,
        outlet_list_filter_0_back_up: set_kunjungan_arr_new(
          state.outlet_list_filter_0_back_up,
          action.payload
        ),
      };

    case "push_kunjungan_arr_new":
      return {
        ...state,
        outlet_list_filter_0_back_up: push_kunjungan_arr_new(
          state.outlet_list_filter_0_back_up,
          action.payload
        ),
      };
    case "create_rayon_edit_outlet":
      return {
        ...state,
        outlet_list_filter_0_back_up: edit_rayon_id_bulk(
          state.outlet_list_filter_0_back_up,
          action.payload
        ),
      };
    case "edit_cycle_outlet":
      return {
        ...state,
        outlet_list_filter_0_back_up: edit_cycle_outlet(
          state.outlet_list_filter_0_back_up,
          action.payload
        ),
      };
    case "set_list":
      return {
        ...state,
        [action.payload.text]: action.payload.list,
      };
    case "push_list":
      return {
        ...state,
        [action.payload.text]: [
          ...state[action.payload.text],
          action.payload.item,
        ],
      };

    case "select_rayon":
      return {
        ...state,
        rayon_list_local: select_rayon(state.rayon_list_local, action.payload),
      };
    case "set_total_toko":
      return {
        ...state,
        total_toko: action.payload,
      };
    case "reset_total_toko":
      return {
        ...state,
        total_toko: 0,
      };
    case "reset_progres_toko":
      return {
        ...state,
        progres_toko: 0,
      };
    case "add_progres_toko":
      return {
        ...state,
        progres_toko: state.progres_toko + action.payload,
      };

    case "reset_bulk_add_salesman":
      return {
        ...state,
        bulk_add_salesman_status: [],
      };
    case "bulk_add_salesman":
      return {
        ...state,
        bulk_add_salesman_status: action.payload,
      };
    case "get_all_city_rayon":
      return {
        ...state,
        city_rayon: action.payload,
      };
    case "get_toko_rayon_count":
      return {
        ...state,
        toko_rayon_count: action.payload,
      };
    case "delete_salesman_rayon_id":
      return {
        ...state,
        salesman_list: delete_salesman_rayon_id(
          state.salesman_list,
          action.payload
        ),
      };
    case "update_salesman_rayon":
      return {
        ...state,
        salesman_list: update_salesman_rayon(
          state.salesman_list,
          action.payload
        ),
      };
    case "update_many_toko_rayon":
      return {
        ...state,
        outlet_list_filter_0: update_many_toko_rayon(
          state.outlet_list_filter_0,
          action.payload
        ),
      };
    case "insert_many_rayon":
      return {
        ...state,
        rayon_list_database: action.payload,
        rayon_check_status: "saved",
      };
    case "get_rayon_multi_depo":
      return {
        ...state,
        rayon_list_database: action.payload,
        rayon_check_status: "checked",
      };
    case "get_competitor_count":
      return {
        ...state,
        competitor_count: action.payload,
      };

    //template
    case "create_template":
      return {
        ...state,
        template_list: [...state.template_list, action.payload],
      };
    case "edit_template":
      return {
        ...state,
        template_list: edit_template(state.template_list, action.payload),
      };
    case "delete_template":
      return {
        ...state,
        template_list: state.template_list.filter(
          (t) => t._id !== action.payload._id
        ),
      };
    case "get_template_list":
      return {
        ...state,
        template_list: action.payload,
      };

    //apk_version
    case "create_apk_version":
      return {
        ...state,
        apk_version_list: [...state.apk_version_list, action.payload],
      };
    case "edit_apk_version":
      return {
        ...state,
        apk_version_list: edit_apk_version(
          state.apk_version_list,
          action.payload
        ),
      };
    case "delete_apk_version":
      return {
        ...state,
        apk_version_list: state.apk_version_list.filter(
          (t) => t._id !== action.payload._id
        ),
      };

    /*GET METHODE*/
    case "get_apk_version_list":
      return {
        ...state,
        apk_version_list: action.payload,
      };
    case "get_type_outlet_list":
      return {
        ...state,
        type_outlet_list: action.payload,
      };
    case "get_segment_list":
      return {
        ...state,
        segment_list: action.payload,
      };
    case "get_competitor_code_list":
      return {
        ...state,
        competitor_code_list: action.payload,
      };

    case "toggle_depo_loc_list":
      return {
        ...state,
        depo_loc_list: toggle_depo_loc_list(
          state.depo_loc_list,
          action.payload
        ),
      };
    case "set_depo_loc_list":
      return {
        ...state,
        depo_loc_list: action.payload,
      };
    case "change_radius_depo":
      return {
        ...state,
        depo_loc_list: change_radius_depo(state.depo_loc_list, action.payload),
        depo_loc_list_back_up: change_radius_depo(
          state.depo_loc_list_back_up,
          action.payload
        ),
      };
    case "change_radius_all_depo":
      return {
        ...state,
        depo_loc_list: change_radius_all_depo(
          state.depo_loc_list,
          action.payload
        ),
        depo_loc_list_back_up: change_radius_all_depo(
          state.depo_loc_list_back_up,
          action.payload
        ),
      };
    case "init_mode_radius_depo":
      return {
        ...state,
        depo_loc_list: init_mode_radius_depo(state.depo_loc_list),
        depo_loc_list_back_up: init_mode_radius_depo(
          state.depo_loc_list_back_up
        ),
      };
    case "set_tool_status":
      return {
        ...state,
        tool_status: action.payload,
      };
    case "set_competitor_list_filter_0":
      return {
        ...state,
        outlet_list_filter_0: action.payload,
      };
    case "edit_depo_toko_competitor_bulk":
      return {
        ...state,
        outlet_list_filter_0: edit_depo_toko_competitor_bulk(
          state.outlet_list_filter_0,
          action.payload
        ),
        outlet_list: edit_depo_toko_competitor_bulk(
          state.outlet_list,
          action.payload
        ),
      };
    case "edit_depo_toko_competitor":
      return {
        ...state,
        outlet_list_filter_0: edit_depo_toko_competitor(
          state.outlet_list_filter_0,
          action.payload
        ),
        outlet_list: edit_depo_toko_competitor(
          state.outlet_list,
          action.payload
        ),
      };
    case "get_all_submitted_competitor":
      return {
        ...state,
        competitor_success_list: action.payload,
        competitor_success_list_origin: action.payload,
      };
    case "get_all_failed_competitor":
      return {
        ...state,
        competitor_failed_list: action.payload,
        competitor_failed_list_origin: action.payload,
      };
    case "delete_depo":
      return {
        ...state,
        depo_loc_list: state.depo_loc_list.filter(
          (d) => d.Depo !== action.payload.Depo
        ),
      };
    case "edit_depo":
      return {
        ...state,
        depo_loc_list: edit_depo(state.depo_loc_list, action.payload),
        depo_loc_list_back_up: edit_depo(
          state.depo_loc_list_back_up,
          action.payload
        ),
      };
    case "get_depo_by_name":
      return {
        ...state,
        depo_loc_list: [...state.depo_loc_list, action.payload],
        depo_loc_list_back_up: [...state.depo_loc_list_back_up, action.payload],
      };
    case "get_all_depo":
      return {
        ...state,
        depo_loc_list: action.payload,
        depo_loc_list_back_up: action.payload,
      };
    case "filter_siklus_toko":
      return {
        ...state,
        siklus: action.payload,
        outlet_list_filter_0: filter_siklus_toko(
          state.outlet_list_filter_0_back_up_plan,
          action.payload
        ),
      };
    case "filter_siklus_toko_all":
      return {
        ...state,
        siklus: action.payload,
        outlet_list_filter_0: state.outlet_list_filter_0_back_up_plan,
      };

    case "get_omset_by_array_outlet_id":
      return {
        ...state,
        omset_by_array_outlet_id: action.payload,
      };

    case "reset_distribution_toko":
      return {
        ...state,
        div_toko: "",
        outlet_list_filter_0: state.outlet_list_filter_0_back_up,
        list_menu_active: set_config_mode_menu(state.list_menu_active, {
          menu: "distribution",
          mode: "",
        }),
      };

    case "set_config_mode_menu":
      return {
        ...state,
        list_menu_active: set_config_mode_menu(
          state.list_menu_active,
          action.payload
        ),
      };

    case "set_config_menu":
      return {
        ...state,
        list_menu_active: set_config_menu(
          state.list_menu_active,
          action.payload
        ),
      };
    case "set_active":
      const idx = state.list_menu_active.findIndex(
        (e) => e.key === action.payload.key_menu
      );
      let match = state.list_menu_active;
      match[idx] = { ...match[idx], active: action.payload.active_menu };
      return {
        ...state,
        list_menu_active: match,
      };

    case "set_legend_color_toko_by_salesman":
      return {
        ...state,
        legend_color_toko_by_salesman: action.payload.array,
        div_toko: action.payload.div,
        outlet_list_filter_0: set_legend_color_toko_by_salesman(
          state.outlet_list_filter_0_back_up,
          action.payload
        ),
        outlet_list_filter_0_back_up_plan: set_legend_color_toko_by_salesman(
          state.outlet_list_filter_0_back_up,
          action.payload
        ),
      };
    case "get_toko_edited_by_apk":
      return {
        ...state,
        toko_plotting: [...state.toko_plotting, ...action.payload],
        backup_toko_plotting: [...state.toko_plotting, ...action.payload],
      };
    case "get_toko_edited_by_apk_clear":
      return {
        ...state,
        toko_plotting: [],
      };
    case "add_progress_edit_hari":
      return {
        ...state,
        progress_edit_hari: state.progress_edit_hari + 1,
      };
    case "set_mode_hari_sebelum_sesudah":
      return {
        ...state,
        mode_toko_hari: action.payload,
      };
    case "set_toko_temporary":
      return {
        ...state,
        outlet_list_by_salesman_filter_0: action.payload,
      };

    case "timpa_outlet":
      return {
        ...state,
        outlet_list: action.payload,
        outlet_list_backup: action.payload,
        outlet_list_filter_0: filter_location_0_outside(action.payload),
        outlet_list_filter_0_back_up: filter_location_0_outside(action.payload),
        outlet_list_table: action.payload,
        outlet_list_table_backup: action.payload,
      };

    case "timpa_outlet_filter":
      return {
        ...state,
        outlet_list: action.payload.outlet_list,
        outlet_list_filter_0: action.payload.outlet_list_filter_0,
      };

    case "clear_get_outlet_list_by_area":
      return {
        ...state,
        outlet_list: [],
        outlet_list_backup: [],
        outlet_list_filter_0: [],
        outlet_list_filter_0_back_up: [],
      };
    case "get_outlet_list_by_area":
      return {
        ...state,
        outlet_list: [...state.outlet_list, ...action.payload],
        outlet_list_backup: [...state.outlet_list, ...action.payload],
        outlet_list_filter_0: [
          ...state.outlet_list_filter_0,
          ...filter_location_0_outside(action.payload),
        ],
        outlet_list_filter_0_back_up: [
          ...state.outlet_list_filter_0_back_up,
          ...filter_location_0_outside(action.payload),
        ],
        outlet_list_table: [...state.outlet_list_table, ...action.payload],
        outlet_list_table_backup: [
          ...state.outlet_list_table,
          ...action.payload,
        ],
      };
    case "filter_outlet_by_search":
      return {
        ...state,
        outlet_list: filter_outlet_by_search(
          state.outlet_list_backup,
          action.payload.find_keyword,
          action.payload.find_select
        ),
        outlet_list_filter_0: filter_outlet_by_search(
          state.outlet_list_filter_0_back_up,
          action.payload.find_keyword,
          action.payload.find_select
        ),
      };
    case "filter_toko_by_param":
      return {
        ...state,
        outlet_list_filter_0: filter_toko_by_param(
          state.outlet_list_filter_0_back_up,
          action.payload
        ),
      };
    case "multi_select_toko_by_params":
      return {
        ...state,
        outlet_list_select_0: multi_select_toko_by_params(
          state.outlet_list_select_0,
          state.outlet_list_filter_0_back_up,
          action.payload
        ),
      };

    case "multi_reset_toko_by_params":
      return {
        ...state,
        outlet_list_select_0: action.payload,
      };

    case "outlet_multi_select_data":
      return {
        ...state,
        outlet_multi_select_data: action.payload,
      };

    case "get_omset":
      return {
        ...state,
        omset_list: [...action.payload, ...state.omset_list],
        omset_list_backup: [...action.payload, ...state.omset_list_backup],

        omset_list_filter_0: [
          ...filter_location_omset(action.payload),
          ...state.omset_list_filter_0,
        ],
        omset_list_filter_0_backup: [
          ...filter_location_omset(action.payload),
          ...state.omset_list_filter_0_backup,
        ],
      };

    case "get_omset_detail_by_month_toko":
      return {
        ...state,
        omset_satu_bulan: action.payload,
      };
    case "get_omset_by_toko_by_month_product":
      return {
        ...state,
        omset_product: action.payload,
      };
    case "reset_omset":
      return {
        ...state,
        omset_product: [],
        omset_product_bu: [],
        omset_product_brand: [],
        omset_product_sku: [],
      };
    case "get_omset_by_toko_by_month_product_bu":
      return {
        ...state,
        omset_product_bu: action.payload,
      };
    case "get_omset_by_toko_by_month_product_brand":
      return {
        ...state,
        omset_product_brand: action.payload,
      };
    case "get_omset_by_toko_by_month_product_sku":
      return {
        ...state,
        omset_product_sku: action.payload,
      };
    case "get_history_omset_by_toko_aggregate_month":
      return {
        ...state,
        omset_history: action.payload,
      };
    case "turn_on_menu":
      return {
        ...state,
        list_menu_active: turn_on_menu(state.list_menu_active, action.payload),
      };
    case "turn_on_menu_status":
      return {
        ...state,
        list_menu_active: turn_on_menu_status(
          state.list_menu_active,
          action.payload
        ),
      };
    case "turn_off_menu":
      return {
        ...state,
        list_menu_active: turn_off_menu(state.list_menu_active, action.payload),
      };
    case "SELECTED_HARI":
      return {
        ...state,
        selected_hari: action.payload,
      };
    case "edit_hari_kunjungan":
      return {
        ...state,
        outlet_list_by_salesman: edit_hari_kunjungan(
          state.outlet_list_by_salesman,
          action.payload
        ),
        backup_outlet_list_by_salesman: edit_hari_kunjungan(
          state.backup_outlet_list_by_salesman,
          action.payload
        ),
        outlet_filter_hari_kunjungan: edit_hari_kunjungan(
          state.outlet_filter_hari_kunjungan,
          action.payload
        ),
        outlet_filter_freq_kunjungan: edit_hari_kunjungan(
          state.outlet_filter_freq_kunjungan,
          action.payload
        ),
      };
    case "edit_hari_kunjungan_new":
      return {
        ...state,
        outlet_list_by_salesman: edit_hari_kunjungan_new(
          state.outlet_list_by_salesman,
          action.payload
        ),
        backup_outlet_list_by_salesman: edit_hari_kunjungan_new(
          state.backup_outlet_list_by_salesman,
          action.payload
        ),
        outlet_filter_hari_kunjungan: edit_hari_kunjungan_new(
          state.outlet_filter_hari_kunjungan,
          action.payload
        ),
        outlet_filter_freq_kunjungan: edit_hari_kunjungan_new(
          state.outlet_filter_freq_kunjungan,
          action.payload
        ),
      };
    case "get_merch_list":
      return {
        ...state,
        merch_list: action.payload,
        merch_list_filter_0: filter_location_0(action.payload),
      };
    case "get_outlet_nabati":
      return {
        ...state,
        layer_id: action.payload._id,
        layer_list: get_outlet_nabati(state.layer_list, action.payload),
      };
    case "SET_PICK_STATUS":
      return { ...state, pick_status: action.payload };
    case "GET_POLYGON_ADM":
      return { ...state, polygon_adm: action.payload };
    case "SET_LOADING":
      return { ...state, loading_status: true, loading_item: action.payload };
    case "CLEAR_LOADING":
      return { ...state, loading_status: false };
    case "SET_LOADING_TOP":
      return { ...state, loading_top: true };
    case "CLEAR_LOADING_TOP":
      return { ...state, loading_top: false };
    case "SET_MAP":
      return { ...state, map_status: action.payload };
    case "GET_OUTLET_LIST_OPEN":
      return { ...state, outlet_list_open: action.payload };
    case "SET_FILTER_MODE":
      return { ...state, filter_mode: action.payload };
    case "FILTER_DATA":
      return filterData(action.payload, state);
    case "SET_ADM_LIST": {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }
    case "set_selected_menu": {
      return {
        ...state,
        selected_menu: action.payload,
      };
    }
    case "SET_SELECTED_SALESMAN": {
      return {
        ...state,
        selected_salesman: action.payload,
      };
    }
    case "get_driver": {
      return {
        ...state,
        driver_list: action.payload,
        driver_list_filter_0: filter_location_0_salesman(action.payload),
      };
    }
    case "GET_SALESMAN_LIST": {
      return {
        ...state,
        salesman_list: action.payload,
        salesman_list_step_5: action.payload,
        salesman_list_step_7: action.payload,
        backup_salesman_list: action.payload,
        salesman_list_filter_0: filter_location_0_salesman(action.payload),
      };
    }
    case "GET_SALESMAN_OMSET_LIST": {
      return {
        ...state,
        get_omset_salesman: [...state.get_omset_salesman, ...action.payload],
        salesman_list_omset: [...state.salesman_list_omset, ...action.payload],
        backup_salesman_list_omset: [
          ...state.backup_salesman_list_omset,
          ...action.payload,
        ],
        salesman_list_omset_filter_0: filter_location_0_salesman(
          action.payload
        ),
      };
    }
    case "CLEAR_SALESMAN_LIST": {
      return {
        ...state,
        salesman_list: [],
        backup_salesman_list: [],
      };
    }
    case "GET_OUTLET_LIST_BY_SALESMAN": {
      return {
        ...state,
        outlet_list_by_salesman: action.payload,
        outlet_list_by_salesman_filter_0: filter_location_0_outside(
          action.payload
        ),
        backup_outlet_list_by_salesman: action.payload,
        outlet_filter_hari_kunjungan: getSelectionFilterHari(action.payload),
        outlet_filter_freq_kunjungan: getSelectionFilterFreq(action.payload),
      };
    }
    case "GET_OUTLET_FILTER": {
      return {
        ...state,
        outlet_list_by_salesman: getOutletFilter(
          state.backup_outlet_list_by_salesman,
          action.payload
        ),
      };
    }
    case "SEARCH_OUTLET_LIST_BY_SALESMAN": {
      return {
        ...state,
        outlet_list_by_salesman: searchOutletBySalesman(
          action.payload,
          state.outlet_list_by_salesman,
          state.backup_outlet_list_by_salesman
        ),
      };
    }

    case "SEARCH_SALESMAN_LIST": {
      return {
        ...state,
        salesman_list: searchSalesman(
          action.payload,
          state.salesman_list,
          state.backup_salesman_list
        ),
      };
    }
    case "SEARCH_SALESMAN_LIST_STEP_5": {
      return {
        ...state,
        salesman_list_step_5: searchSalesman(
          action.payload,
          state.salesman_list,
          state.backup_salesman_list
        ),
      };
    }
    case "SEARCH_SALESMAN_LIST_STEP_7": {
      return {
        ...state,
        salesman_list: searchSalesman(
          action.payload,
          state.salesman_list,
          state.backup_salesman_list
        ),
        salesman_list_step_5: searchSalesman(
          action.payload,
          state.salesman_list,
          state.backup_salesman_list
        ),
        salesman_list_step_7: searchSalesman(
          action.payload,
          state.salesman_list,
          state.backup_salesman_list
        ),
      };
    }

    case "search_id_toko": {
      return {
        ...state,
        omset_list: searchIdOutlet(
          action.payload,
          state.omset_list,
          state.omset_list_backup
        ),
      };
    }
    case "SEARCH_SALESMAN_BY_DIVISI": {
      return {
        ...state,
        salesman_list: searchSalesmanDivisi(
          action.payload,
          state.backup_salesman_list,
          state.backup_salesman_list
        ),
      };
    }
    case "SET_SALESMAN_MODE": {
      return {
        ...state,
        salesman_mode: action.payload,
      };
    }
    case "SET_OUTLET_MODE": {
      return {
        ...state,
        outlet_mode: action.payload,
      };
    }
    case "SET_TOGGLE_ROUTE": {
      return {
        ...state,
        route_mode: action.payload,
      };
    }
    case "SET_SALESMAN_FILTER": {
      return {
        ...state,
        salesman_filter: action.payload,
        salesman_filter_original_cabang: action.payload.original_cabang,
        salesman_filter_original_regional: action.payload.original_regional,
        // depo_list: set_depo(action.payload),
      };
    }
    case "SET_SALESMAN_FILTER_MODE": {
      return {
        ...state,
        salesman_filter_mode: action.payload,
      };
    }
    case "SET_SALESMAN_FILTER_VALUE": {
      return {
        ...state,
        salesman_filter_val: action.payload,
      };
    }
    case "GET_TOTAL_SALESMAN": {
      return {
        ...state,
        salesman_total_count: action.payload.length,
      };
    }
    case "CHANGE_BREAKPOINTS": {
      return {
        ...state,
        sidebarBreakpoints: action.payload,
      };
    }
    case "CHANGE_SELECTED_FILTER_1": {
      return {
        ...state,
        selectedFilter1Obj: action.payload,
      };
    }
    case "CHANGE_SELECTED_FILTER_2": {
      return {
        ...state,
        selectedFilter2Obj: action.payload,
      };
    }
    case "CHANGE_LIST_SELECTED_FILTER_2": {
      return {
        ...state,
        listSelectFilter2: action.payload,
      };
    }
    case "CHANGE_SELECTED_FILTER_2_MULTI_LENGTH": {
      return {
        ...state,
        selectedFilter2MultiLength: action.payload,
      };
    }
    case "CHANGE_WITHOUT_FILTER_2": {
      return {
        ...state,
        withOutFilter2: action.payload,
      };
    }
    case "CHANGE_COLOUR": {
      return {
        ...state,
        statusWarna: action.payload,
      };
    }
    case "GET_CURRENT_SALESMAN": {
      return {
        ...state,
        current_salesman: action.payload,
      };
    }
    case "SET_CURRENT_SALESMAN": {
      return {
        ...state,
        current_salesman: action.payload,
      };
    }
    case "CLEAR_CURRENT_SALESMAN": {
      return {
        ...state,
        current_salesman: {},
      };
    }
    case "DATA_POPUP_TOKO": {
      return {
        ...state,
        dataPopupToko: action.payload,
        dataPopupText: action.text,
      };
    }
    case "MANUAL_ADD_REGIONAL": {
      return {
        ...state,
        salesman_filter_original_regional: [
          ...state.salesman_filter_original_regional,
          action.payload,
        ],
      };
    }
    case "MANUAL_ADD_CABANG": {
      return {
        ...state,
        salesman_filter_original_cabang: [
          ...state.salesman_filter_original_cabang,
          action.payload,
        ],
      };
    }
    case "filter_collor_salesman":
      return {
        ...state,
        salesman_list_omset: filter_Color_Salesman(
          action.payload,
          state.salesman_list_omset,
          state.backup_salesman_list_omset
        ),
      };
    case "filter_collor":
      return {
        ...state,
        omset_list: filter_Color(
          action.payload,
          state.omset_list,
          state.omset_list_backup
        ),
        omset_list_filter_0: filter_location_omset(
          filter_Color(
            action.payload,
            state.omset_list,
            state.omset_list_backup
          )
        ),
      };
    case "filter_business":
      return {
        ...state,
        omset_list: filter_color_table(action.payload, state.omset_list_backup),
        omset_list_filter_0: filter_location_omset(
          filter_color_table(action.payload, state.omset_list_backup)
        ),
      };
    case "filter_business_multi":
      return {
        ...state,
        omset_list: filter_color_multi_table(
          action.payload,
          state.omset_list_backup,
          state.omset_list
        ),
        omset_list_filter_0: state.omset_list_filter_0.concat(
          filter_location_omset(
            filter_color_table(action.payload, state.omset_list_backup)
          )
        ),
      };
    case "get_omset_clear":
      return {
        ...state,
        omset_list_backup: [],
        omset_list: [],
        omset_list_filter_0: [],
        get_omset_salesman: [],
        salesman_list_omset: [],
        backup_salesman_list_omset: [],
        getDataBaseOmset: [],
      };
    case "clear_multi":
      return {
        ...state,
        omset_list: [],
        omset_list_filter_0: [],
      };
    case "listTypeToko":
      return {
        ...state,
        listTypeToko: action.payload,
      };
    case "SEARCH_PRINCIPLE_LIST": {
      return {
        ...state,
        dataPrinciple: searchPrinciple(
          action.payload,
          state.dataPrinciple,
          state.dataPrincipleBackup
        ),
      };
    }
    case "SEARCH_BU_LIST": {
      return {
        ...state,
        dataBusiness: searchBu(
          action.payload,
          state.dataBusiness,
          state.dataBusinessBackup
        ),
      };
    }
    case "SEARCH_BRANDS_LIST": {
      return {
        ...state,
        dataBrand: searchBrand(
          action.payload,
          state.dataBrand,
          state.dataBrandBackup
        ),
      };
    }
    case "SEARCH_PRODUCTS_LIST": {
      return {
        ...state,
        dataProduction: searchProducts(
          action.payload,
          state.dataProduction,
          state.dataProductionBackup
        ),
      };
    }
    case "getDateSalesman": {
      return {
        ...state,
        getDate: action.payload,
      };
    }
    case "getAllProductSalesman": {
      return {
        ...state,
        productSalesman: action.payload,
      };
    }
    case "getMonthSalesman": {
      return {
        ...state,
        monthSalesman: action.payload,
      };
    }
    case "getDataBaseOmset": {
      return {
        ...state,
        getDataBaseOmset: state.getDataBaseOmset.concat(
          matchDatabase(
            action.payloadMongos,
            action.payloadPostgress,
            state.salesman_filter
          )
        ),
      };
    }
    case "search_plotted": {
      return {
        ...state,
        toko_plotting: searchPlotted(
          action.payload,
          state.toko_plotting,
          state.backup_toko_plotting
        ),
      };
    }
    case "search_competitor": {
      return {
        ...state,
        outlet_list_table: search_competitor(
          action.payload,
          state.outlet_list_table,
          state.outlet_list_table_backup
        ),
      };
    }
    case "search_rayon": {
      return {
        ...state,
        outlet_list_filter_0: search_rayon(
          action.payload,
          state.outlet_list_filter_0,
          state.outlet_list_filter_0_back_up
        ),
      };
    }
    case "ambilWaktu": {
      return {
        ...state,
        beriWaktu: action.payload,
      };
    }
    case "omset_split": {
      return {
        ...state,
        omset_split: action.payload,
      };
    }
    default:
      return state;
  }
}

//hide_all_filter_array
const hide_all_filter_array = (array_state) => {
  array_state = array_state.map((item) => {
    item.is_hide = true;
    return item;
  });
  return array_state;
};

//show_all_filter_array
const show_all_filter_array = ({
  array_state,
  array_name,
  outlet_list,
  outlet_list_survey,
  outlet_merch_list,
  outlet_merch_list_filter_0,
  omset_list,
  omset_list_filter_0,
}) => {
  array_state = array_state.map((item) => {
    item.is_hide = false;
    return item;
  });
  return {
    [array_name]: array_state,
    outlet_list_filter_0: outlet_list,
    toko_plotting: outlet_list_survey,
    outlet_merch_list,
    outlet_merch_list_filter_0,
    omset_list,
    omset_list_filter_0,
  };
};

//hide_filter_array
const hide_filter_array = ({
  array_state,
  element_name,
  array_name,
  outlet_list,
  outlet_list_survey,
  outlet_merch_list,
  outlet_merch_list_filter_0,
  omset_list,
  omset_list_filter_0,
}) => {
  const index = array_state.findIndex((item) => item.name === element_name);
  array_state[index].is_hide = true;

  let only_show_array = array_state.filter((item) => !item.is_hide);
  only_show_array = only_show_array.map((item) => item.name);
  const properties_name = filter_outlet_object[array_name].properties_name;

  const outlet_list_filter_0 = filter_outlet_list({
    outlet_list,
    only_show_array,
    properties_name,
  });

  const toko_plotting = filter_outlet_list({
    outlet_list: outlet_list_survey,
    only_show_array,
    properties_name,
  });

  outlet_merch_list = filter_merch_list({
    outlet_list: outlet_merch_list,
    only_show_array,
    properties_name,
  });

  outlet_merch_list_filter_0 = filter_merch_list({
    outlet_list: outlet_merch_list_filter_0,
    only_show_array,
    properties_name,
  });

  omset_list = filter_outlet_list({
    outlet_list: omset_list,
    only_show_array,
    properties_name,
  });

  omset_list_filter_0 = filter_outlet_list({
    outlet_list: omset_list_filter_0,
    only_show_array,
    properties_name,
  });

  return {
    [array_name]: array_state,
    outlet_list_filter_0: outlet_list_filter_0,
    toko_plotting: toko_plotting,
    outlet_merch_list,
    outlet_merch_list_filter_0,
    omset_list,
  };
};

//show_filter_array
const show_filter_array = ({
  array_state,
  element_name,
  array_name,
  outlet_list,
  outlet_list_survey,
  outlet_merch_list,
  outlet_merch_list_filter_0,
  omset_list,
  omset_list_filter_0,
}) => {
  const index = array_state.findIndex((item) => item.name === element_name);
  array_state[index].is_hide = false;

  let only_show_array = array_state.filter((item) => !item.is_hide);
  only_show_array = only_show_array.map((item) => item.name);
  const properties_name = filter_outlet_object[array_name].properties_name;

  const outlet_list_filter_0 = filter_outlet_list({
    outlet_list,
    only_show_array,
    properties_name,
  });

  const toko_plotting = filter_outlet_list({
    outlet_list: outlet_list_survey,
    only_show_array,
    properties_name,
  });

  outlet_merch_list = filter_merch_list({
    outlet_list: outlet_merch_list,
    only_show_array,
    properties_name,
  });

  outlet_merch_list_filter_0 = filter_merch_list({
    outlet_list: outlet_merch_list_filter_0,
    only_show_array,
    properties_name,
  });

  omset_list = filter_outlet_list({
    outlet_list: omset_list,
    only_show_array,
    properties_name,
  });

  omset_list_filter_0 = filter_outlet_list({
    outlet_list: omset_list_filter_0,
    only_show_array,
    properties_name,
  });

  return {
    [array_name]: array_state,
    outlet_list_filter_0: outlet_list_filter_0,
    toko_plotting: toko_plotting,
    outlet_merch_list,
    outlet_merch_list_filter_0,
    omset_list,
    omset_list_filter_0,
  };
};

//push_unique_items_to_array
const push_unique_items_to_array = (array_state, array_value) => {
  array_value.forEach((item) => {
    //looping array_value jika belum ada pada array_state maka masukkan valuenya ke dalam array of object
    if (array_state.findIndex((e) => e.name === item) === -1)
      array_state.push({ name: item || "", is_hide: false });
  });
  array_state = sort_array(array_state, "name", true);
  array_state = array_state.map((item, idx) => {
    let index = idx;
    /*
    misal ada array warna memiliki 3 elemen [0,1,2],
    jika idx lebih besar dari index terakhir (3-1 = 2) maka diambil sisa hasil bagi dari idx%2

    misal idx = 3 --> index menjadi 0
    misal idx = 4 --> index menjadi 1
    misal idx = 5 --> index menjadi 2

    idx%3 --> idx%st_colors.length
    */
    if (idx > st_colors.length - 1) {
      index = idx % st_colors.length;
    }
    item.color = st_colors[index];
    return item;
  });
  return array_state;
};

const edit_rayon_location = (list, body) => {
  const { rayon_id, location, feature_polygon } = body;
  const index = list.findIndex((item) => item._id === rayon_id);
  list[index].location = location;
  list[index].feature_polygon = feature_polygon;
  return list;
};

//edit_preview_rayon_list_mode
/*
body: {
  index: Number,
  mode: String, manual || auto
}
*/
const edit_preview_rayon_list_mode = (list, body) => {
  const { index, mode } = body;
  list[index].mode = mode;
  return list;
};

//edit_preview_rayon_list_v_manual
/*
body: {
  index: Number,
  v_manual : Number
}
*/
const edit_preview_rayon_list_v_manual = (list, body) => {
  const { index, v_manual } = body;
  list[index].v_manual = v_manual;
  return list;
};

//ow_salesman_rayon
const ow_salesman_rayon = (list, body) => {
  const { rayon_id, plan_index, salesman_id } = body;

  let rayon = list.find((r) => r._id === rayon_id);
  rayon.plan_list[plan_index].salesman_id = salesman_id;

  return list;
};

//ow_salesman_outlet
const ow_salesman_outlet = (list, body) => {
  const { outlet_update_array, salesman_id } = body;

  outlet_update_array.forEach((object) => {
    const { outlet_id, k_index } = object;
    let outlet = list.find((t) => t._id === outlet_id);
    outlet.kunjungan_arr_new[k_index].salesman_id = salesman_id;
  });

  return list;
};

const edit_many_toko_rayon = (list, body) => {
  // let new_list = list.slice();
  const { rayon_id, outlet_id_array } = body;
  outlet_id_array.forEach((outlet_id) => {
    const index = list.findIndex((t) => t._id === outlet_id);
    list[index].rayon_id = rayon_id;
    list[index].kunjungan_arr_new = [];
  });
  return list;
};

const edit_item = (list, body) => {
  const { item_id, text } = body;
  const index = list.findIndex((e) => e._id === item_id);
  list[index].text = text;
  return list;
};

const matchDatabase = (dataMongos, dataPostgress, dataSalesman) => {
  let result = [];
  // let depo = dataMongos[0].Depo;
  let plan = dataMongos[0].Plan;
  let findDepo = dataSalesman.depo.find((e) => plan === e.plan_id);

  let totalQty = 0;
  let totalValue = 0;
  dataPostgress.forEach((e) => {
    let getDataQty = parseFloat(e.qty);
    totalQty += getDataQty;
    let getDataValue = parseFloat(e.value);
    totalValue += getDataValue;
  });
  result = [
    {
      depo: findDepo.Depo,
      toko_mongos: dataMongos.length,
      toko_postgress: dataPostgress.length,
      qty: totalQty,
      value: totalValue,
      plan: plan,
    },
  ];
  return result;
};

const filter_color_table = (dataApi, dataToko) => {
  let result = [];
  for (let idx = 0; idx < dataToko.length; idx++) {
    let outlet = dataToko[idx];
    const match = dataApi.filter((e) => e.customer_id === outlet["ID Outlet"]);
    if (match.length > 0) {
      const omsArr = match
        .map((e) => parseFloat(e.value))
        .reduce((a, b) => a + b, 0);
      outlet.business_unit = match[0].business_unit;
      outlet.brand = match[0].brand;
      outlet.product_parent_name = match[0].product_parent_name;
      outlet.product_parent_id = match[0].product_parent_id;
      outlet.principle = match[0].principle;
      outlet.VALUE = omsArr;
      result.push(outlet);
    }
  }
  for (let idx = 0; idx < dataApi.length; idx++) {
    let outlet = dataApi[idx];
    const match = dataToko.filter((e) => e["ID Outlet"] === outlet.customer_id);
    if (match.length === 0) {
      const omsArr = parseFloat(outlet.value);
      result.push({
        "Nama Toko": outlet.customer_name,
        location: { type: "Point", coordinates: [0, 0] },
        VALUE: omsArr,
        business_unit: outlet.business_unit,
        brand: outlet.brand,
        product_parent_name: outlet.product_parent_name,
        product_parent_id: outlet.product_parent_id,
        principle: outlet.principle,
        "ID Outlet": outlet.customer_id,
      });
    }
  }
  return result;
};
const filter_color_multi_table = (dataApi, dataToko, omset_list) => {
  let result = omset_list;
  for (let idx = 0; idx < dataToko.length; idx++) {
    let outlet = dataToko[idx];
    const match = dataApi.filter((e) => e.customer_id === outlet["ID Outlet"]);
    if (match.length > 0) {
      const omsArr = match
        .map((e) => parseFloat(e.value))
        .reduce((a, b) => a + b, 0);
      result.push({
        ...outlet,
        business_unit: match[0].business_unit,
        brand: match[0].brand,
        product_parent_name: match[0].product_parent_name,
        product_parent_id: match[0].product_parent_id,
        principle: match[0].principle,
        VALUE: omsArr,
      });
    }
  }
  for (let idx = 0; idx < dataApi.length; idx++) {
    let outlet = dataApi[idx];
    const match = dataToko.filter((e) => e["ID Outlet"] === outlet.customer_id);
    if (match.length === 0) {
      const omsArr = parseFloat(outlet.value);
      result.push({
        "Nama Toko": outlet.customer_name,
        location: { type: "Point", coordinates: [0, 0] },
        VALUE: omsArr,
        business_unit: outlet.business_unit,
        brand: outlet.brand,
        product_parent_name: outlet.product_parent_name,
        product_parent_id: outlet.product_parent_id,
        principle: outlet.principle,
        "ID Outlet": outlet.customer_id,
      });
    }
  }
  return result;
};

const filter_Color = (query, data, backup) => {
  if (query.start === 0 && query.end === 1000_000_000_000) {
    return backup.filter((e) => e.VALUE >= query.start && e.VALUE < query.end);
  } else {
    return data.filter((e) => e.VALUE >= query.start && e.VALUE < query.end);
  }
};
const filter_Color_Salesman = (query, data, backup) => {
  if (query.start === 0 && query.end === 1000_000_000_000) {
    return backup.filter(
      (e) =>
        parseFloat(e.omset_salesman) >= query.start &&
        parseFloat(e.omset_salesman) < query.end
    );
  } else {
    return data.filter(
      (e) =>
        parseFloat(e.omset_salesman) >= query.start &&
        parseFloat(e.omset_salesman) < query.end
    );
  }
};

//edit_depo_local
const edit_depo_local = (list, body) => {
  const { id, Depo, country } = body;
  let new_list = list.slice();
  const index = new_list.findIndex((e) => e.id === id);
  new_list[index].Depo = Depo;
  new_list[index].country = country;
  return new_list;
};

//manual_add_depo
const manual_add_depo = (list, body) => {
  const { id, Depo, country } = body;
  let new_list = list.slice();
  const index = new_list.findIndex((e) => e.id === id);
  new_list[index].is_local = false;
  new_list[index].Depo = Depo;
  new_list[index].country = country;
  return new_list;
};

//reset_rayon_outlet
const reset_rayon_outlet = (list, body) => {
  let new_list = list.slice();
  const { outlet_id_array } = body;
  outlet_id_array.forEach((outlet_id) => {
    const index = list.findIndex((t) => t._id === outlet_id);
    new_list[index].rayon_id = "";
    new_list[index].cycle = "";
    new_list[index].kunjungan_arr_new = [];
  });
  return new_list;
};

const push_plan_list = (list, body) => {
  const { rayon_id, plan } = body;
  const index = list.findIndex((e) => e._id === rayon_id);
  let plan_list = list?.[index]?.plan_list ? list?.[index]?.plan_list : [];
  plan_list.push(plan);
  list[index].plan_list = plan_list;
  return list;
};

const set_plan_list = (list, body) => {
  const { rayon_id, day_list, plan_index } = body;

  const index = list.findIndex((e) => e._id === rayon_id);
  let plan_list = list?.[index]?.plan_list ? list?.[index]?.plan_list : [];

  plan_list[plan_index].day_list = day_list;
  list[index].plan_list = plan_list;
  return list;
};

const set_cycle_list_to_rayon = (list, body) => {
  const { rayon_id, cycle_list } = body;
  const index = list.findIndex((e) => e._id === rayon_id);
  list[index].cycle_list = cycle_list;
  return list;
};

const push_kunjungan_arr_new = (list, body) => {
  let new_list = list.slice();
  const { outlet_id_array, kunjungan_object } = body;
  outlet_id_array.forEach((outlet_id) => {
    const index = list.findIndex((t) => t._id === outlet_id);
    let kunjungan_arr_new = list?.[index]?.kunjungan_arr_new
      ? list?.[index]?.kunjungan_arr_new
      : [];
    kunjungan_arr_new.push(kunjungan_object);
    new_list[index].kunjungan_arr_new = kunjungan_arr_new;
  });
  return new_list;
};

const set_kunjungan_arr_new = (list, body) => {
  const { outlet_id_array, kunjungan_object } = body;
  outlet_id_array.forEach((outlet_id) => {
    const index = list.findIndex((t) => t._id === outlet_id);
    list[index].kunjungan_arr_new = [kunjungan_object];
  });
  return list;
};

const edit_rayon_id_bulk = (list, body) => {
  // let new_list = list.slice();
  const { rayon_db, outlet_id_array } = body;
  outlet_id_array.forEach((outlet_id) => {
    const index = list.findIndex((t) => t._id === outlet_id);
    list[index].rayon_id = rayon_db._id;
  });
  return list;
};

const edit_cycle_outlet = (list, body) => {
  const { cycle, outlet_id_array } = body;
  outlet_id_array.forEach((outlet_id) => {
    const index = list.findIndex((t) => t._id === outlet_id);
    list[index].cycle = cycle;
  });
  return list;
};

const select_rayon = (list, rayon) => {
  let new_list = list.slice();
  if (rayon) {
    new_list = new_list.map((r) => {
      if (r._id !== rayon) {
        r.is_hide = true;
      } else {
        r.is_hide = false;
      }
      return r;
    });
  } else {
    new_list = new_list.map((r) => {
      r.is_hide = false;
      return r;
    });
  }
  return new_list;
};

const filter_toko_by_param = (list, body) => {
  let new_list = list.slice();
  const { key, value } = body;
  key.forEach((e, idx) => {
    if (value[idx]) {
      new_list = new_list.filter((t) => t[e] === value[idx]);
    }
  });
  return new_list;
};

const multi_select_toko_by_params = (data, list, body) => {
  const { key, value } = body;
  let new_list = list.slice();
  let new_data = [...data];

  key.forEach((d, idx) => {
    const find_data = new_data.find((t) => t[d] === value[idx]);
    if (find_data === undefined) {
      new_list = new_list.filter((t) => t[d] === value[idx]);
      new_data.push(...new_list);
    } else {
      new_data = [...data];
    }
  });
  return new_data;
};

const delete_salesman_rayon_id = (list, body) => {
  const { salesman_id, rayon_id } = body;
  let new_list = list.slice();
  const index = new_list.findIndex((e) => e._id === salesman_id);
  let rayon_id_list = new_list[index]?.rayon_id_list
    ? new_list[index]?.rayon_id_list
    : [];
  rayon_id_list = rayon_id_list.filter((r) => r !== rayon_id);
  new_list[index].rayon_id_list = rayon_id_list;
  return new_list;
};

const update_salesman_rayon = (list, body) => {
  const { salesman_id, rayon_id } = body;
  let new_list = list.slice();
  const index = new_list.findIndex((e) => e._id === salesman_id);
  let rayon_id_list = new_list[index]?.rayon_id_list
    ? new_list[index]?.rayon_id_list
    : [];
  rayon_id_list.push(rayon_id);
  new_list[index].rayon_id_list = rayon_id_list;
  return new_list;
};

//update_many_toko_rayon
const update_many_toko_rayon = (list, body) => {
  const { toko_id_array, depo_id, depo_string, rayon_id, rayon_string } = body;
  let new_list = list.slice();
  toko_id_array.forEach((_id) => {
    const index = new_list.findIndex((e) => e._id === _id);
    new_list[index].saved = true;
    new_list[index].depo_id = depo_id;
    new_list[index].Depo = depo_string;
    new_list[index].rayon_id = rayon_id;
    new_list[index].rayon_string = rayon_string;
  });
  return new_list;
};

const edit_depo_toko_competitor_bulk = (list, body) => {
  const { toko_id_array, depo } = body;
  let new_list = list.slice();
  toko_id_array.forEach((_id) => {
    const index = new_list.findIndex((e) => e._id === _id);
    new_list[index].Depo = depo;
  });
  return new_list;
};

const edit_depo_toko_competitor = (list, body) => {
  const { toko_id, depo } = body;
  let new_list = list.slice();
  const index = new_list.findIndex((e) => e._id === toko_id);
  new_list[index].Depo = depo;
  return new_list;
};

const edit_depo = (list, body) => {
  let new_list = list.slice();
  const index = new_list.findIndex((e) => e.Depo === body.Depo);
  new_list[index] = body;
  return new_list;
};

const edit_template = (list, body) => {
  const { _id, text, ada_kemungkinan_disurvei_kembali, color } = body;
  let new_list = list.slice();
  const index = new_list.findIndex((e) => e._id === _id);
  new_list[index].text = text;
  new_list[index].color = color;
  new_list[index].ada_kemungkinan_disurvei_kembali =
    ada_kemungkinan_disurvei_kembali;
  return new_list;
};

const edit_apk_version = (list, body) => {
  const { _id, text, apk_version, apk_interval, url_apk, is_newest } = body;
  let new_list = list.slice();
  const index = new_list.findIndex((e) => e._id === _id);
  new_list[index].text = text;
  new_list[index].apk_version = apk_version;
  new_list[index].apk_interval = apk_interval;
  new_list[index].url_apk = url_apk;
  new_list[index].is_newest = is_newest;
  return new_list;
};

const toggle_depo_loc_list = (list, body) => {
  const { depo_name } = body;
  let new_list = list.slice();
  const index = new_list.findIndex((e) => e.Depo === depo_name);
  new_list[index].is_hide = !new_list[index].is_hide;
  return new_list;
};

const change_radius_all_depo = (list, body) => {
  const { value } = body;
  let new_list = list.map((d) => {
    d.radius = value;
    return d;
  });
  return new_list;
};

const change_radius_depo = (list, body) => {
  const { idx, value } = body;
  let new_list = list.slice();
  new_list[idx].radius = value;
  return new_list;
};

const init_mode_radius_depo = (list) => {
  let new_list = list.slice();
  new_list = new_list.map((e) => {
    e.radius = 3;
    return { ...e };
  });
  return new_list;
};

const filter_siklus_toko = (list, body) => {
  const siklus = body;
  let new_list = list.slice();
  new_list = new_list.map((e) => {
    let arr = e.kunjungan_arr.map((salesman) => salesman.FreqVisit);
    e.arr = arr;
    return { ...e };
  });
  new_list = new_list.filter((e) => e.arr.includes(siklus));
  return new_list;
};

const set_config_mode_menu = (state, body) => {
  const { menu, mode } = body;

  let list_menu_active = state.slice();
  const index = list_menu_active.findIndex((e) => e.key === menu);
  list_menu_active[index].active = true;
  list_menu_active[index].config.mode = mode;
  return list_menu_active;
};

const set_config_menu = (state, body) => {
  const { menu, config } = body;

  let list_menu_active = state.slice();
  const index = list_menu_active.findIndex((e) => e.key === menu);
  list_menu_active[index].active = true;
  list_menu_active[index].config = config;
  return list_menu_active;
};

const set_legend_color_toko_by_salesman = (list, body) => {
  const { div, array } = body;
  let new_list = list.slice();
  new_list = new_list.map((e) => {
    let salesman_color = e.kunjungan_arr.find((k) => k.NamaSales.includes(div));
    salesman_color = `${salesman_color?.KodeSales}_${e.Depo}`;
    e.salesman_color = salesman_color;
    return { ...e };
  });
  const array_salesman_color_active = array.map((e) => e.kode);
  new_list = new_list.filter((e) =>
    array_salesman_color_active.includes(e.salesman_color)
  );
  return new_list;
};

const turn_on_menu = (state, body) => {
  const { key, config } = body;
  let list_menu_active = state.slice();
  const index = list_menu_active.findIndex((e) => e.key === key);
  list_menu_active[index].active = true;
  list_menu_active[index].config = config;
  return list_menu_active;
};

const turn_on_menu_status = (state, body) => {
  const { key } = body;
  let list_menu_active = state.slice();
  const index = list_menu_active.findIndex((e) => e.key === key);
  list_menu_active[index].active = true;
  return list_menu_active;
};

const turn_off_menu = (state, body) => {
  const { key } = body;
  let list_menu_active = state.slice();
  const index = list_menu_active.findIndex((e) => e.key === key);
  list_menu_active[index].active = false;
  return list_menu_active;
};

const filter_location_omset = (list) => {
  let list_filter_0 = list.slice();
  list_filter_0 = list.filter((e) => e?.location?.coordinates?.[0] !== 0);
  return list_filter_0;
};

const filter_location_0 = (merch_list) => {
  let merch_list_filter_0 = merch_list.slice();
  merch_list_filter_0 = merch_list.filter(
    (e) => e?.outlet_id?.location?.coordinates?.[0] !== 0
  );
  return merch_list_filter_0;
};

const filter_location_0_outside = (list) => {
  let list_filter_0 = list.slice();
  list_filter_0 = list.filter(
    (e) =>
      e?.location?.coordinates?.[0] !== 0 &&
      e?.location?.coordinates?.[1] >= -90 &&
      e?.location?.coordinates?.[1] <= 90
  );
  return list_filter_0;
};

const filter_location_0_salesman = (list) => {
  let salesman_list_filter_0 = list.slice();
  salesman_list_filter_0 = list.filter(
    (e) =>
      e?.location?.coordinates?.[0] !== 0 &&
      e?.location?.coordinates?.[1] >= -90 &&
      e?.location?.coordinates?.[1] <= 90
  );
  return salesman_list_filter_0;
};

const edit_hari_kunjungan_new = (list, body) => {
  let list_slice = list.slice();
  const { _id, hari_new } = body;
  let outlet = list_slice.find((element) => element._id === _id);
  outlet.hari_new = hari_new;
  const outlet_index = list_slice.findIndex((element) => element._id === _id);
  list_slice.splice(outlet_index, 1, outlet);
  return list_slice;
};

const edit_hari_kunjungan = (list, body) => {
  let list_slice = list.slice();
  const { _id, HARIKunjungan } = body;
  let outlet = list_slice.find((element) => element._id === _id);
  outlet.HARIKunjungan = HARIKunjungan;
  const outlet_index = list_slice.findIndex((element) => element._id === _id);
  list_slice.splice(outlet_index, 1, outlet);
  return list_slice;
};

const get_outlet_nabati = (layer_list, layer_new) => {
  layer_list.push(layer_new);
  return layer_list;
};

const filterData = (coord, state) => {
  return state;
};

const searchOutletBySalesman = (
  query,
  outlet_list_by_salesman,
  backup_outlet_list_by_salesman
) => {
  let result;
  if (query !== "") {
    result = outlet_list_by_salesman.filter((state) => {
      const regex = new RegExp(`${query}`, "gi");
      return state["Nama Toko"].match(regex);
    });
  } else {
    result = backup_outlet_list_by_salesman;
  }
  return result;
};

const searchSalesman = (query, salesmans, backup_salesman_list) => {
  let result;
  if (query !== "") {
    result = salesmans.filter((state) => {
      const regex = new RegExp(`${query}`, "gi");
      return state["NIK"].match(regex) || state["Nama Only"].match(regex);
    });
  } else {
    result = backup_salesman_list;
  }
  return result;
};

const searchIdOutlet = (query, omset_list, omset_list_backup) => {
  let result;
  if (query !== "") {
    result = omset_list.filter((state) => {
      const regex = new RegExp(`${query}`, "gi");
      return state["ID Outlet"].match(regex) || state["Nama Toko"].match(regex);
    });
  } else {
    result = omset_list_backup;
  }
  return result;
};

const searchPrinciple = (query, dataPrinciple, dataPrincipleBackup) => {
  let result;
  if (query !== "") {
    result = dataPrinciple.filter((state) => {
      const regex = new RegExp(`${query}`, "gi");
      return state["principle"].match(regex);
    });
  } else {
    result = dataPrincipleBackup;
  }
  return result;
};
const searchBu = (query, dataBusiness, dataBusinessBackup) => {
  let result;
  if (query !== "") {
    result = dataBusiness.filter((state) => {
      const regex = new RegExp(`${query}`, "gi");
      return state["business_unit"].match(regex);
    });
  } else {
    result = dataBusinessBackup;
  }
  return result;
};
const searchBrand = (query, dataBrand, dataBrandBackup) => {
  let result;
  if (query !== "") {
    result = dataBrand.filter((state) => {
      const regex = new RegExp(`${query}`, "gi");
      return state["brand"].match(regex);
    });
  } else {
    result = dataBrandBackup;
  }
  return result;
};
const searchProducts = (query, dataProduction, dataProductionBackup) => {
  let result;
  if (query !== "") {
    result = dataProduction.filter((state) => {
      const regex = new RegExp(`${query}`, "gi");
      return state["business_unit"].match(regex);
    });
  } else {
    result = dataProductionBackup;
  }
  return result;
};

const searchSalesmanDivisi = (query, salesmans, backup_salesman_list) => {
  let result = [];
  if (query.length !== 0) {
    for (let index = 0; index < salesmans.length; index++) {
      const element = salesmans[index];
      for (let idx = 0; idx < query.length; idx++) {
        const el = query[idx];

        if (element.divisi_arr.includes(`${el}`)) {
          result.push(element);
        }
      }
    }
  } else {
    result = backup_salesman_list;
  }
  return result;
};

const getSelectionFilterHari = (payload) => {
  let listHari = [];
  payload.forEach((element) => {
    if (listHari.indexOf(element["HARIKunjungan"]) < 0) {
      listHari.push(element["HARIKunjungan"]);
    }
  });
  return listHari;
};
const getSelectionFilterFreq = (payload) => {
  let listFreq = [];
  payload.forEach((element) => {
    if (listFreq.indexOf(element["FreqVisit"]) < 0) {
      listFreq.push(element["FreqVisit"]);
    }
  });
  return listFreq;
};

const getOutletFilter = (list, filter) => {
  const { filterFreq, filterHari } = filter;
  let result = [];
  if (filterFreq === "semua" && filterHari !== "semua") {
    list.forEach((element) => {
      if (element["HARIKunjungan"] === filterHari) {
        result.push(element);
      }
    });
  }
  if (filterFreq !== "semua" && filterHari === "semua") {
    list.forEach((element) => {
      if (element["FreqVisit"] === filterFreq) {
        result.push(element);
      }
    });
  }
  if (filterFreq !== "semua" && filterHari !== "semua") {
    list.forEach((element) => {
      if (
        element["FreqVisit"] === filterFreq &&
        element["HARIKunjungan"] === filterHari
      ) {
        result.push(element);
      }
    });
  }
  if (filterFreq === "semua" && filterHari === "semua") {
    result = list;
  }
  return result;
};

const filter_outlet_by_search = (
  outlet_list_backup,
  find_keyword,
  find_select
) => {
  let result;
  if (find_keyword !== "") {
    result = outlet_list_backup.filter((state) => {
      const regex = new RegExp(`${find_keyword}`, "gi");
      if (find_select === "outlet_id") {
        return state["ID Outlet"].match(regex);
      } else {
        return state["Nama Toko"].match(regex);
      }
    });
  } else {
    result = outlet_list_backup;
  }
  return result;
};

const searchPlotted = (query, plotteds, backup_plotted_list) => {
  let result;
  if (query !== "") {
    result = plotteds.filter((state) => {
      const regex = new RegExp(`${query}`, "gi");
      return state["Nama Toko"].match(regex);
    });
  } else {
    result = backup_plotted_list;
  }
  return result;
};
const search_rayon = (query, rayons, backup_rayon_list) => {
  let result;
  if (query !== "") {
    result = rayons.filter((state) => {
      const regex = new RegExp(`${query}`, "gi");
      return state["Nama Toko"].match(regex);
    });
  } else {
    result = backup_rayon_list;
  }
  return result;
};

const search_competitor = (query, outlet, outlet_list_table_backup) => {
  let result;
  if (query !== "") {
    result = outlet.filter((state) => {
      const regex = new RegExp(`${query}`, "gi");
      return state["Nama Toko"].match(regex);
    });
  } else {
    result = outlet_list_table_backup;
  }
  return result;
};
