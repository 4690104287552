//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";
import { bbox } from "@turf/turf";

//Personal Component

//Redux function

//Picture Asset

//General Function
import is_equal_array from "../../validation/is_equal_array";

class COMPETITOR_SUCCESS_LAYER extends Component {
  state = {
    content: null,
    is_visible: false,
  };

  componentDidUpdate(prevProps) {
    const is_visible_before = this.state.is_visible;

    const list_menu_active_after = this.props.data.list_menu_active;

    const is_visible_after = list_menu_active_after.find(
      (e) => e.key === "competitor_success"
    )?.active;

    const competitor_success_list_before =
      prevProps.data.competitor_success_list;

    const competitor_success_list_after =
      this.props.data.competitor_success_list;

    const competitor_success_list_origin =
      this.props.data.competitor_success_list_origin;

    if (
      (is_visible_before !== is_visible_after ||
        !is_equal_array(
          competitor_success_list_before,
          competitor_success_list_after
        )) &&
      competitor_success_list_origin.length > 0
    ) {
      let geojson = {
        type: "FeatureCollection",
        features: [],
      };
      //coloring
      let circle_color = "#8931ef";
      let circle_outline_color = "white";
      if (competitor_success_list_after.length > 0) {
        circle_color = ["match", ["get", "SYSTEM"], "IF", "#000CF3", "#E70000"];
        circle_outline_color = [
          "match",
          ["get", "SYSTEM"],
          "IF",
          "#ffffff",
          "#ffffff",
        ];

        const new_features = competitor_success_list_after.map((e) => {
          return {
            type: "Feature",
            properties: { ...e },
            geometry: e.location,
          };
        });
        geojson = {
          type: "FeatureCollection",
          features: [...new_features],
        };
      }

      circle_color = "#3333FF";
      const content = (
        <>
          <Source
            key="competitor_success_src"
            id="competitor_success_src"
            type="geojson"
            data={geojson}
          />
          <Layer
            id="competitor_success"
            key="competitor_success"
            source="competitor_success_src"
            before="competitor_failed"
            type="circle"
            layout={{
              visibility: is_visible_after ? "visible" : "none",
            }}
            filter={["all", ["!=", "id", -1]]}
            paint={{
              "circle-color": circle_color,
              "circle-radius": 5,
              "circle-stroke-width": 1,
              "circle-stroke-color": circle_outline_color,
              "circle-opacity": 1,
            }}
            onClick={this.props.layerOnClick.bind(this, "competitor_success")}
          />
        </>
      );
      this.setState({ content, is_visible: is_visible_after });

      if (is_visible_after && competitor_success_list_after.length > 0) {
        //fly to data in map
        const sidebarW = parseInt(localStorage.getItem("sidebar-width") || 500);
        const { map } = this?.props?.map;
        let [minLng, minLat, maxLng, maxLat] = bbox(geojson);
        if (map) {
          map?.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat],
            ],
            {
              padding: { top: 100, bottom: 0, left: sidebarW + 50, right: 500 },
            }
          );
        }
      }
    }
  }

  render() {
    const { content } = this.state;
    return content;
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
});

export default connect(mapStateToProps, {})(COMPETITOR_SUCCESS_LAYER);
