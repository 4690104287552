/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal";
import ASSIGN_OUTLET_MERCH from "./ASSIGN_OUTLET_MERCH";

/*REDUX FUNCTION*/
import { post_revisi } from "../../actions/outletActions";
import {
  get_merch_by_outlet_id,
  push_merch,
} from "../../actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL*/
import format_date_formal from "../../validation/format_date_formal";

/*NON IMPORT*/

class OUTLET_POPUP extends Component {
  state = {
    message_revisi: "",
    modal_merch: false,
  };

  componentDidMount() {
    const pop_up_properties = this?.props?.properties?.pop_up_properties;
    const outlet_id = pop_up_properties?._id;
    if (outlet_id) {
      const params = { outlet_id };
      this.props.get_merch_by_outlet_id(params);
    }
  }

  toggle_merch = () => {
    this.setState({ modal_merch: !this.state.modal_merch });
  };

  on_change = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handle_radio_revisi = (event) => {
    this.setState({
      message_revisi: event.target.value,
    });
  };

  handle_submit_revisi = (event) => {
    event.preventDefault();
    this.props.post_revisi({
      message_revisi: this?.state?.message_revisi,
      outlet_id: this?.state?.properties?._id,
    });
  };

  render() {
    //local storage

    //local state
    const { modal_merch } = this.state;

    //global props
    const { merchandising_object } = this.props.properties;
    const pop_up_properties = this?.props?.properties?.pop_up_properties || {};

    //content
    let images;
    if (typeof pop_up_properties.images === "string") {
      images = JSON.parse(pop_up_properties.images);
    } else {
      images = pop_up_properties.images;
    }

    const modal_merch_content = modal_merch && (
      <Modal
        modalSize="medium"
        isOpen={modal_merch}
        onClose={this.toggle_merch}
      >
        <div className="box-body" id="box-body">
          <ASSIGN_OUTLET_MERCH
            current_outlet={pop_up_properties}
            toggle_merch={this.toggle_merch}
          />
        </div>
      </Modal>
    );

    return (
      <main style={{ fontFamily: `"Inter", sans-serif` }}>
        {modal_merch_content}

        <section className="container_light mb-3">
          <p className="text_bold">Outlet Photo</p>
          <div className="grid grid-cols-2 gap-1 mb-4">
            <div className="text-center">
              <h1 className="font-semibold">Outlet Image</h1>
              <img src={images?.[0]?.Location} alt="Not yet uploaded" />
            </div>
            <div className="text-center">
              <h1 className="font-semibold">Display Image</h1>
              <img src={images?.[1]?.Location} alt="Not yet uploaded" />
            </div>
          </div>
        </section>

        <section className="container_light mb-3">
          <p className="text_bold">Merchandising Status</p>
          {merchandising_object?._id ? (
            <>
              <p className="text_small mb-5">MERCHANDISING ACTIVE</p>

              <p className="text_small">MONTH</p>
              <p className="text_bold mb-3 paragraph">
                {merchandising_object?.["NOMOR BULAN"]}
              </p>

              <p className="text_small">YEAR</p>
              <p className="text_bold mb-3 paragraph">
                {merchandising_object?.["NOMOR TAHUN"]}
              </p>

              <p className="text_small">CLASS TARGET</p>
              <p className="text_bold mb-3 paragraph">
                {merchandising_object?.["TARGET KELAS"]}
              </p>

              <p className="text_small">CLASS REALIZATION</p>
              <p className="text_bold mb-3 paragraph">
                {merchandising_object?.["REALISASI KELAS"]}
              </p>

              <p className="text_small">BUDGET PROGRAM</p>
              <p className="text_bold mb-3 paragraph">
                Rp.
                {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                  merchandising_object?.["BUDGET PROGRAM"]
                )}
              </p>

              <p className="text_small">RASIO BUDGET PER TURNOVER (OMSET)</p>
              <p className="text_bold mb-3 paragraph">
                {merchandising_object?.["RASIO BUDGET PER OMSET"]}
              </p>

              <p className="text_small">TURNOVER (OMSET)</p>
              <p className="text_bold mb-3 paragraph">
                Rp.
                {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                  merchandising_object?.["OMSET"]
                )}
              </p>

              <p className="text_small">QTY</p>
              <p className="text_bold mb-3 paragraph">
                {merchandising_object?.["QTY"]}
              </p>

              <button
                className="button background_green"
                onClick={this.toggle_merch}
              >
                Assign new merchandising period
              </button>
            </>
          ) : (
            <>
              <p className="text_small mb-5">NOT MERCHANDISING</p>
              <button
                className="button background_green"
                onClick={this.toggle_merch}
              >
                Assign merchandising
              </button>
            </>
          )}
        </section>

        <p className="text_small">SYSTEM</p>
        <p className="button background_blue mb-3">
          {pop_up_properties?.["SYSTEM"] || "-"}
        </p>

        <p className="text_small">OUTLET NAME</p>
        <p className="text_bold mb-3 paragraph">
          {pop_up_properties?.["Nama Toko"] || "-"}
        </p>

        <p className="text_small">OUTLET ID</p>
        <p className="text_bold mb-3 paragraph">
          {pop_up_properties?.["ID Outlet"] || "-"}
        </p>

        <p className="text_small">PLAN DEPO</p>
        <p className="text_bold mb-3 paragraph">
          {pop_up_properties?.["Plan"] || "-"}
        </p>

        <p className="text_small">DEPO NAME</p>
        <p className="text_bold mb-3 paragraph">
          {pop_up_properties?.["Depo"] || "-"}
        </p>

        <p className="text_small">BRANCH</p>
        <p className="text_bold mb-3 paragraph">
          {pop_up_properties?.["CABANG"] || "-"}
        </p>

        <p className="text_small">REGION</p>
        <p className="text_bold mb-3 paragraph">
          {pop_up_properties?.["REGIONAL"] || "-"}
        </p>

        <p className="text_small">ADDRESS</p>
        <p className="text_bold mb-3 paragraph">
          {pop_up_properties?.["ALAMAT"] || "-"}
        </p>

        <p className="text_small">PHONE NUMBER</p>
        <p className="text_bold mb-3 paragraph">
          {pop_up_properties?.["NO Tlpon"] || "-"}
        </p>

        <p className="text_small">TYPE</p>
        <p className="text_bold mb-3 paragraph">
          {pop_up_properties?.["Type Outlet"] || "-"}
        </p>

        <p className="text_small">SEGMENT</p>
        <p className="text_bold mb-3 paragraph">
          {pop_up_properties?.["SEGMENT"] || "-"}
        </p>

        <p className="text_small">SUBDISTRICT/KELURAHAN</p>
        <p className="text_bold mb-3 paragraph">
          {pop_up_properties?.["KELURAHAN"] || "-"}
        </p>

        <p className="text_small">DISTRICT/KECAMATAN</p>
        <p className="text_bold mb-3 paragraph">
          {pop_up_properties?.["KECAMATAN"] || "-"}
        </p>

        <p className="text_small">CITY/KOTA</p>
        <p className="text_bold mb-3 paragraph">
          {pop_up_properties?.["KABUPATEN"] || "-"}
        </p>

        <p className="text_small">PROVINCE/PROVINSI</p>
        <p className="text_bold mb-3 paragraph">
          {pop_up_properties?.["PROPINSI"] || "-"}
        </p>

        <p className="text_small">BRANCH ID</p>
        <p className="text_bold mb-3 paragraph">
          {pop_up_properties?.["ID BRANCH"] || "-"}
        </p>

        <p className="text_small">SFA ID</p>
        <p className="text_bold mb-3 paragraph">
          {pop_up_properties?.["ID SFA"] || "-"}
        </p>

        <section className="container_light">
          <div className="badge background_blue mb-3">Competitor section</div>
          <p className="text_small">ACQUISITION DATE</p>
          <p className="text_bold mb-3 paragraph">
            {pop_up_properties?.["date_acquisition"]
              ? format_date_formal(pop_up_properties?.["date_acquisition"])
              : "-"}
          </p>

          <p className="text_small">CLOSE REASON</p>
          <p className="text_bold mb-3 paragraph">
            {pop_up_properties?.["failed_reason"]}
          </p>

          <p className="text_small">ID NABATI BY ACQUISITION</p>
          <p className="text_bold mb-3 paragraph">
            {pop_up_properties?.["id_nabati"] || "-"}
          </p>

          <p className="text_small">LAST DEPO RAYONIZATION</p>
          <p className="text_bold mb-3 paragraph">
            {pop_up_properties?.["date_last_depo_updated"]
              ? format_date_formal(
                  pop_up_properties?.["date_last_depo_updated"]
                )
              : "-"}
          </p>

          <p className="text_small">task_depo_rayon_status</p>
          <p className="text_bold mb-3 paragraph">
            {pop_up_properties?.["task_depo_rayon_status"] || "-"}
          </p>

          <p className="text_small">DATABASE ID</p>
          <p className="text_bold mb-3 paragraph">
            {pop_up_properties?.["_id"] || "-"}
          </p>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  post_revisi,
  get_merch_by_outlet_id,
  push_merch,
})(OUTLET_POPUP);
