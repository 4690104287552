/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal";

/*REDUX FUNCTION*/
import { get_item_list } from "../../actions/dataActions";
import {
  get_depo_list_by_country,
  set_value_properties,
} from "../../actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL*/
import sort_array from "../../validation/sort_array";

class RAYON_DEPO_STEP_2 extends Component {
  state = {
    modal_full_depo_list: false,
  };

  componentDidMount() {
    this.props.get_item_list("depo_folder");
    this.props.get_depo_list_by_country({
      country: "INDONESIA",
    });
    this.on_set_depo_list_filtered();
  }

  componentDidUpdate(prevProps) {
    if (
      this?.props?.properties?.competitor_rayon_task_newest?.depo_folder !==
        prevProps?.properties?.competitor_rayon_task_newest?.depo_folder ||
      this?.props?.properties?.depo_list?.length !==
        prevProps?.properties?.depo_list?.length
    ) {
      this.on_set_depo_list_filtered();
    }
  }

  on_remove_depo_list_filtered = (depo_id) => {
    let { depo_list_filtered } = this.props.properties;
    depo_list_filtered = depo_list_filtered.filter(
      (item) => item._id !== depo_id
    );
    this.props.set_value_properties({
      key: "depo_list_filtered",
      value: depo_list_filtered,
    });
  };

  toggle_full_depo_list = () => {
    this.setState({ modal_full_depo_list: !this.state.modal_full_depo_list });
  };

  on_set_depo_list_filtered = () => {
    const { competitor_rayon_task_newest, depo_list } = this.props.properties;
    const depo_folder = competitor_rayon_task_newest?.depo_folder;
    let depo_list_filtered = depo_list.filter(
      (depo) => depo.folder_id === depo_folder
    );
    depo_list_filtered = sort_array(depo_list_filtered, "Depo", true);
    this.props.set_value_properties({
      key: "depo_list_filtered",
      value: depo_list_filtered,
    });
  };

  on_sort_depo_list_filtered = (field) => {
    let { depo_list_filtered } = this.props.properties;
    depo_list_filtered = sort_array(depo_list_filtered, field, true);
    this.props.set_value_properties({
      key: "depo_list_filtered",
      value: depo_list_filtered,
    });
  };

  render() {
    //local storage

    //local state
    const { modal_full_depo_list } = this.state;

    //global props
    const { depo_folder_list } = this.props.data;
    const {
      competitor_rayon_task_newest,
      // depo_list,
      depo_list_filtered,
    } = this.props.properties;

    //content
    const depo_folder = competitor_rayon_task_newest?.depo_folder;
    const folder_detail = depo_folder_list.find(
      (item) => item._id === depo_folder
    );

    const folder_content = (
      <section className="mb-5">
        <table className="table">
          <tbody>
            <tr>
              <td>Folder depo</td>
              <td>{folder_detail?.text}</td>
              <td>
                <button
                  className="button_small"
                  id="grey"
                  onClick={this.on_set_depo_list_filtered}
                >
                  Reset remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    );

    const depo_list_content = (
      <main>
        <p className="mb-3">Jumlah depo: {depo_list_filtered.length}</p>
        <table className="table">
          <thead>
            <tr>
              <th>No</th>
              <th
                onClick={this.on_sort_depo_list_filtered.bind(this, "Depo")}
                className="cursor_pointer"
              >
                NAMA DEPO ⇅
              </th>
              <th
                onClick={this.on_sort_depo_list_filtered.bind(this, "plan_id")}
                className="cursor_pointer"
              >
                PLAN ID ⇅
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {depo_list_filtered.map((depo, idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{depo?.Depo}</td>
                  <td>{depo?.plan_id}</td>
                  <td>
                    <button
                      className="button_small"
                      id="red"
                      style={{ marginRight: "20px" }}
                      onClick={this.on_remove_depo_list_filtered.bind(
                        this,
                        depo._id
                      )}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </main>
    );

    const modal_full_depo_list_content = modal_full_depo_list && (
      <Modal
        modalSize="large"
        isOpen={modal_full_depo_list}
        onClose={this.toggle_full_depo_list}
      >
        <div className="box-body">
          <h1 className="text_bold">
            List of depots that will be involved in the rayonization
          </h1>
          {folder_content}
          <section
            style={{
              height: "calc(( 80vh - 20px ))",
              overflowY: "scroll",
              width: "fit-content",
            }}
            className="mb-5"
          >
            {depo_list_content}
          </section>
        </div>
      </Modal>
    );

    return (
      <main className="container_light mb-10">
        {modal_full_depo_list_content}
        <h1 className="text_bold">
          List of depots that will be involved in the rayonization
        </h1>

        {folder_content}

        <section
          style={{
            height: "200px",
            overflowY: "scroll",
            width: "fit-content",
          }}
          className="mb-5"
        >
          {depo_list_content}
        </section>

        <button
          className="button"
          id="green"
          onClick={this.toggle_full_depo_list}
        >
          Full screen
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  get_item_list,
  get_depo_list_by_country,
  set_value_properties,
})(RAYON_DEPO_STEP_2);
