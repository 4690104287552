/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/
import isEmpty from "../../validation/is-empty";

/*NON IMPORT*/

class OMSET_SALESMAN_LAYER extends Component {
  render() {
    const salesman_list = this.props.data.salesman_list_omset.filter(
      (s) => !isEmpty(s.location?.coordinates)
    );
    let geojson = {
      type: "FeatureCollection",
      features: [],
    };
    let is_visible_list_salesman,
      salesman_mode,
      is_visible_toko_salesman,
      selected_salesman;
    if (salesman_list.length > 0) {
      const new_features = salesman_list.map((s) => {
        let VALUE = parseFloat(s.omset_salesman);
        let color = "#000000";
        if (VALUE >= 100_000 && VALUE < 200_000) {
          color = "#bc2023";
        }
        if (VALUE >= 200_000 && VALUE < 300_000) {
          color = "#eb442c";
        }
        if (VALUE >= 300_000 && VALUE < 400_000) {
          color = "#f8b324";
        }
        if (VALUE >= 400_000 && VALUE < 500_000) {
          color = "#b0ae2d";
        }
        if (VALUE >= 500_000 && VALUE < 2_000_000) {
          color = "#4da83a";
        }
        if (VALUE >= 2_000_000 && VALUE < 30_000_000) {
          color = "#069fff";
        }
        if (VALUE >= 30_000_000) {
          color = "#2806ff";
        }
        return {
          type: "Feature",
          properties: {
            ...s,
            color,
            rad: s.location.coordinates[2],
          },
          geometry: {
            type: "Point",
            coordinates: [s.location.coordinates[0], s.location.coordinates[1]],
          },
        };
      });
      geojson = {
        type: "FeatureCollection",
        features: [...new_features],
      };
      selected_salesman = this.props.data.selected_salesman;
      salesman_mode = this.props.data.salesman_mode;
      const { list_menu_active } = this.props.data;
      // const active_menu = list_menu_active
      //   .filter((m) => m.active)
      //   .map((m) => m.key);
      // is_visible_toko_salesman = active_menu.includes("toko_salesman");
      is_visible_list_salesman = list_menu_active.find(
        (e) => e.key === "omset_salesman"
      )?.active;
    }
    return (
      salesman_list.length > 0 && (
        <React.Fragment>
          {salesman_mode === "cluster" ? (
            <main>
              <Source
                key="salesman_omset_bike_cluster_src"
                id="salesman_omset_bike_cluster_src"
                type="geojson"
                data={geojson}
                generateId={true}
                cluster={true}
                clusterMaxZoom={14}
                clusterRadius={50}
              />
              <Layer
                id="salesman_omset_bike_cluster"
                key="salesman_omset_bike_cluster"
                source="salesman_omset_bike_cluster_src"
                filter={["has", "point_count"]}
                type="circle"
                paint={{
                  "circle-color": [
                    "step",
                    ["get", "point_count"],
                    "#51bbd6",
                    100,
                    "#f1f075",
                    750,
                    "#f28cb1",
                  ],
                  "circle-radius": [
                    "step",
                    ["get", "point_count"],
                    20,
                    100,
                    30,
                    750,
                    40,
                  ],
                }}
              />
              <Layer
                id="cluster_omset_count_salesman"
                type="symbol"
                source="salesman_omset_bike_cluster_src"
                filter={["has", "point_count"]}
                layout={{
                  "text-field": "{point_count_abbreviated}",
                  "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                  "text-size": 12,
                }}
              />
              <Layer
                id="uncluster_omset_count_salesman"
                type="circle"
                source="salesman_omset_bike_cluster_src"
                filter={["!", ["has", "point_count"]]}
                paint={{
                  "circle-color": "#11b4da",
                  "circle-radius": 4,
                  "circle-stroke-width": 1,
                  "circle-stroke-color": "#fff",
                }}
              />
            </main>
          ) : (
            <main>
              <Source
                key="salesman_omset_bike_src"
                id="salesman_omset_bike_src"
                type="geojson"
                data={geojson}
                generateId={true}
                cluster={false}
              />
              <Layer
                id="salesman_omset_bike"
                key="salesman_omset_bike"
                source="salesman_omset_bike_src"
                type="symbol"
                layout={{
                  visibility:
                    is_visible_list_salesman || is_visible_toko_salesman
                      ? "visible"
                      : "none",
                  "icon-size": 0.25,
                  // "icon-size": salesman_mode === "status" ? 0.25 : 0.075,
                  "icon-padding": 0,
                  "icon-anchor": "center",
                  "icon-image": "motor_omset",
                  // "icon-image":
                  //   salesman_mode === "status"
                  //     ? ["get", "status_bike"]
                  //     : "motor",
                  "text-field": ["get", "Nama Only"],
                  "text-anchor": "top",
                  "text-offset": [0, 1.5],
                  "text-line-height": 1,
                  "icon-rotate": ["get", "rad"],
                  "icon-allow-overlap": true,
                  "icon-ignore-placement": true,
                  "text-allow-overlap": true,
                  "text-ignore-placement": true,
                  "text-size": 14,
                  "text-max-width": 32,
                }}
                paint={{
                  "icon-color": ["get", "color"],
                  // "icon-color": "red",
                  "icon-opacity": 1,
                  "text-opacity": salesman_mode === "name" ? 1 : 0,
                  "text-halo-color": "white",
                  "text-halo-width": 1,
                }}
                filter={
                  is_visible_toko_salesman && !isEmpty(selected_salesman)
                    ? ["==", "_id", selected_salesman]
                    : ["all", ["!=", "id", -1]]
                }
                onLeave={this.onLeave}
                onClick={this.props.layerOnClick.bind(
                  this,
                  "modal_omset_salesman"
                )}
              />
            </main>
          )}
        </React.Fragment>
      )
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, {})(OMSET_SALESMAN_LAYER);
