import React, { Component } from "react";
import { connect } from "react-redux";
import {
  turn_off_menu,
  turn_on_menu_status,
  polygonUnplot,
} from "../../actions/dataActions";
import {
  statusDraw,
  setDrawMode,
  set_unplot_polygon,
} from "../../actions/mapActions";
import pointsWithinPolygon from "@turf/points-within-polygon";
import writeXlsxFile from "write-excel-file";
export class ToolDristribution extends Component {
  state = {
    show: false,
    dataPoints: [],
    dataProperties: { type: "FeatureCollection", features: [] },
  };

  componentWillUnmount() {
    this.props.statusDraw("");
  }

  activePolygon = () => {
    this.props.statusDraw("unplot_poligon");
    this.props.setDrawMode("simple_select");
    setTimeout(() => {
      this.props.setDrawMode("draw_polygon");
    }, 50);
    this.setState({ show: true });
  };
  cekPoint = () => {
    const { polygon_unplot } = this.props.map;
    const { outlet_list_filter_0 } = this.props.data;
    const features = outlet_list_filter_0.map((c) => {
      const feature = {
        type: "Feature",
        properties: { ...c },
        geometry: c.location,
      };
      return feature;
    });
    const competitor_list_geojson = {
      type: "FeatureCollection",
      features,
    };
    let points = pointsWithinPolygon(competitor_list_geojson, polygon_unplot);
    this.setState({ dataProperties: points });
    points = points.features.map((p) => p.properties._id);
    this.setState({ dataPoints: points });
  };
  startUnplot = () => {
    this.props.set_unplot_polygon({
      type: "FeatureCollection",
      features: [],
    });
    this.setState({ show: false });
    this.props.polygonUnplot(this.state.dataPoints);
  };
  generateDownloadDepoEXCEL = async () => {
    const schema = [
      {
        column: "_id",
        type: String,
        value: (target) => target._id,
      },
      {
        column: "ID OUTLET",
        type: String,
        value: (target) => target.idOutlet,
      },
      {
        column: "ID SFA",
        type: String,
        value: (target) => target.idSfa,
      },
      {
        column: "PLAN",
        type: String,
        value: (target) => target.plan,
      },
      {
        column: "STORE NAME",
        type: String,
        value: (target) => target.namaToko,
      },
      {
        column: "TELEPHONE NUMBER",
        type: String,
        value: (target) => target.noTelepon,
      },
      {
        column: "TYPE OUTLET",
        type: String,
        value: (target) => target.typeOutlet,
      },
      {
        column: "DEPO",
        type: String,
        value: (target) => target.depo,
      },
      {
        column: "ADDRESS",
        type: String,
        value: (target) => target.alamat,
      },
      {
        column: "OTHER ADDRESS",
        type: String,
        value: (target) => target.alamatLain,
      },
      {
        column: "CITY/REGENCY",
        type: String,
        value: (target) => target.kabupaten,
      },
      {
        column: "DISTRICT",
        type: String,
        value: (target) => target.kecamatan,
      },
      {
        column: "SUBDISTRICT",
        type: String,
        value: (target) => target.kelurahan,
      },
      {
        column: "UPDATE DESCRIPTION",
        type: String,
        value: (target) => target.ketUpdate,
      },
      // {
      //   column: "RAYON",
      //   type: String,
      //   value: (target) => target.rayon,
      // },
      {
        column: "VALUE",
        type: Number,
        value: (target) => target.value,
      },
      {
        column: "LOCATION",
        type: String,
        value: (target) => target.location,
      },
    ];
    let newData = [];
    const { salesman_filter } = this.props.data;
    let loopData = this.state.dataProperties.features;
    loopData.forEach((e) => {
      let result = 0;
      for (let i = 0; i < e.properties.omset_arr.length; i++) {
        const element = e.properties.omset_arr[i];
        result = result + element.value;
      }
      const { user, master_admin } = this.props.auth;
      let location = "";
      if (e.properties?.location?.coordinates?.[0] !== 0) {
        const long = e.properties.location.coordinates[0];
        const lat = e.properties.location.coordinates[1];
        location = `${lat},${long}`;
      }
      if (master_admin.filter((e) => e._id === user._id).length <= 0) {
        if (e.properties?.location?.coordinates?.[0] !== 0) {
          location = "YES";
        } else {
          location = "NO";
        }
      }
      let newDepo = salesman_filter.depo.find(
        (x) => x.plan_id === e.properties.Plan
      );
      let getData = {
        _id: e.properties._id,
        idOutlet: e.properties["ID Outlet"],
        idSfa: e.properties.Plan + e.properties["ID Outlet"],
        plan: e.properties.Plan,
        namaToko: e.properties["Nama Toko"],
        noTelepon: e.properties["NO Tlpon"],
        typeOutlet: e.properties["Type Outlet"],
        depo: newDepo?.Depo,
        alamat: e.properties.ALAMAT,
        alamatLain: e.properties.ALAMATLAIN,
        kabupaten: e.properties.KABUPATEN,
        kecamatan: e.properties.KECAMATAN,
        kelurahan: e.properties.KELURAHAN,
        ketUpdate: e.properties.location_status,
        value: result,
        location: location,
      };
      newData.push(getData);
    });
    await writeXlsxFile(newData, {
      schema,
      fileName: "DataExcelPolygon.xlsx",
    });
  };
  setCreatePolygon = () => {
    this.props.statusDraw("unplot_poligon");
    this.props.setDrawMode("simple_select");
    setTimeout(() => {
      this.props.setDrawMode("draw_polygon");
    }, 50);
  };

  render() {
    const create_polygon_button = (
      <>
        {this.state.show === true ? (
          <>
            <button
              className="button_main mt-5"
              onClick={this.setCreatePolygon}
            >
              Create Again Polygon
            </button>
            <button className="button_main mt-5" onClick={this.cekPoint}>
              Check how many outlets inside polygon
            </button>
          </>
        ) : (
          <button className="button_main mt-5" onClick={this.activePolygon}>
            Start create unplot polygon
          </button>
        )}
      </>
    );
    const submit_button = this.state.dataPoints.length >= 1 && (
      <div className="flex flex-col">
        <button
          className="button_main mt-5 w-1/2"
          onClick={this.generateDownloadDepoEXCEL}
        >
          Download Checking Datas
        </button>
        <button className="button_main mt-5 w-1/2" onClick={this.startUnplot}>
          Submit Unplot Point
        </button>
      </div>
    );
    return (
      <section>
        <div className="mb-5 bold">ToolDristribution</div>
        <p>Unploting Points</p>
        <p>Point Selected: {this.state.dataPoints.length}</p>
        {create_polygon_button}
        {submit_button}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  turn_on_menu_status,
  turn_off_menu,
  statusDraw,
  setDrawMode,
  set_unplot_polygon,
  polygonUnplot,
})(ToolDristribution);
