//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function

//Picture Asset

//General Function

class ProgressBar extends Component {
  state = {};
  render() {
    const current_number = new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(parseInt(this.props.current_number));
    const total_number = new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(parseInt(this.props.total_number));

    const total = this.props.total_number || 1;

    let percent = parseInt((this.props.current_number / total) * 100);

    if (percent > 100) {
      percent = 100;
    }

    return (
      <main>
        <div
          style={{
            height: "11px",
            backgroundColor: "#ffffff",
            border: "1px solid rgb(154, 154, 154)",
            borderRadius: "2rem",
            padding: "1px",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              height: "100%",
              width: `${percent}%`,
              backgroundColor: "#0ca5eb",
              borderRadius: "2rem",
              transition: "width 0.5s ease-in",
            }}
          />
        </div>
        <section className="two_container_auto">
          <div className="text_left text_inferior">
            {this.props.name || "Proses"}: {current_number}/{total_number}
          </div>

          <div className="text_right text_medium">{percent}%</div>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ProgressBar);
