import React, { Component } from "react";
import { connect } from "react-redux";

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div
          className={`fixed z-50 overflow-hidden top-0 left-0 right-0 bg-red-100`}
          style={{ display: `${this.props.loading ? "block" : "none"}` }}
        >
          <div className="h-1 bg-red-600 w-0 animate-[getwidth_3s_ease-in_infinite]"></div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.data.loading_top,
});
export default connect(mapStateToProps, {})(Loading);
