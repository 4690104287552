import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";
import st_colors from "../validation/st_colors";
import { generate_filter_item } from "./filter_outlet_actions";

const SERVER_URL = geoServerBaseUrl;

const generate_omset = (toko_list) => {
  toko_list = toko_list.map((t) => {
    let omset = t?.omset_arr ? t?.omset_arr : [];
    let value = omset.map((o) => o.value);
    value = parseInt(value.reduce((a, b) => a + b, 0) / value.length);
    let qty = omset.map((o) => o.qty);
    qty = parseInt(qty.reduce((a, b) => a + b, 0) / qty.length);
    t.value = value && !isNaN(value) ? value : 0;
    t.qty = qty && !isNaN(qty) ? qty : 0;
    return t;
  });
  return toko_list;
};

/*PROTOTYPE*/

// create_item
/*
api: /nabati_per/create_item
methode: post
body: { text, type:"depo_folder" }
respon: { _id, text, type}
*/
export const create_item = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let res = await axios.post(
      `${SERVER_URL}/nabati_per/create_item`,
      body,
      config
    );
    dispatch({
      type: "create_item",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

// get_item_list
/*
api: /nabati_per/get_item_list?type={type}
methode: get
param:type
respon: [ list item]
*/
export const get_item_list = (type) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let res = await axios.get(
      `${SERVER_URL}/nabati_per/get_item_list?type=${type}`,
      config
    );
    dispatch({
      type: "get_item_list",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

// edit_item
/*
api: /nabati_per/edit_item
methode: post
body: { item_id, text }
respon: { status: "edit_item success" }
*/
export const edit_item = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(`${SERVER_URL}/nabati_per/edit_item`, body, config);
    dispatch({
      type: "edit_item",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

// delete_item
/*
api: /nabati_per/delete_item
methode: post
body: {item_id}
respon: { status: "delete_item success" }
*/
export const delete_item = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(`${SERVER_URL}/nabati_per/delete_item`, body, config);
    dispatch({
      type: "delete_item",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//conclusion
export const getConclusion = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let business = [];
    let brand = [];
    let product = [];
    let principle = [];
    for (let i = 0; i < params.plan_id.length; i++) {
      let element = params.plan_id[i];
      let conclusionBusiness = await axios.get(
        `${SERVER_URL}/integrations/conclusion_business_unit?AREA=${element}&MONTH_NUMBER=${params.MONTH}&YEAR_NUMBER=${params.YEAR_NUMBER}`,
        config
      );
      let conclusionBrand = await axios.get(
        `${SERVER_URL}/integrations/conclusion_brand?AREA=${element}&MONTH_NUMBER=${params.MONTH}&YEAR_NUMBER=${params.YEAR_NUMBER}`,
        config
      );
      let consclusionProduct = await axios.get(
        `${SERVER_URL}/integrations/conclusion_product_name?AREA=${element}&MONTH_NUMBER=${params.MONTH}&YEAR_NUMBER=${params.YEAR_NUMBER}`,
        config
      );
      let consclusionPrinciple = await axios.get(
        `${SERVER_URL}/integrations/conclusion_principle?AREA=${element}&MONTH_NUMBER=${params.MONTH}&YEAR_NUMBER=${params.YEAR_NUMBER}`,
        config
      );
      business = business.concat(conclusionBusiness.data);
      brand = brand.concat(conclusionBrand.data);
      product = product.concat(consclusionProduct.data);
      principle = principle.concat(consclusionPrinciple.data);
    }
    dispatch({
      type: "get_cons_business",
      payload: business,
    });
    dispatch({
      type: "get_cons_brand",
      payload: brand,
    });
    dispatch({
      type: "get_cons_production",
      payload: product,
    });
    dispatch({
      type: "get_cons_principle",
      payload: principle,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_business_unit = (params, mode) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let business = [];
    for (let i = 0; i < params.plan_id.length; i++) {
      let element = params.plan_id[i];
      let byBusinessUnit = await axios.get(
        `${SERVER_URL}/integrations/outlet_by_business_unit?AREA=${element}&MONTH_NUMBER=${params.MONTH_NUMBER}&YEAR_NUMBER=${params.YEAR_NUMBER}&BUSINESS_UNIT=${params.brand}`,
        config
      );
      business = business.concat(byBusinessUnit.data);
    }

    if (mode === "single") {
      dispatch({
        type: "filter_business",
        payload: business,
        choice: "businessUnit",
      });
    } else if (mode === "multi") {
      dispatch({
        type: "filter_business_multi",
        payload: business,
        choice: "businessUnit",
      });
    }

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_brand_unit = (params, mode) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let brandUnit = [];
    for (let i = 0; i < params.plan_id.length; i++) {
      let element = params.plan_id[i];
      let byBrandUnit = await axios.get(
        `${SERVER_URL}/integrations/outlet_by_brand?AREA=${element}&MONTH_NUMBER=${params.MONTH_NUMBER}&YEAR_NUMBER=${params.YEAR_NUMBER}&BRAND=${params.brand}`,
        config
      );
      brandUnit = brandUnit.concat(byBrandUnit.data);
    }

    if (mode === "single") {
      dispatch({
        type: "filter_business",
        payload: brandUnit,
        choice: "brandUnit",
      });
    } else if (mode === "multi") {
      dispatch({
        type: "filter_business_multi",
        payload: brandUnit,
        choice: "brandUnit",
      });
    }

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_product_unit = (params, mode) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let filterProduct = [];
    for (let i = 0; i < params.plan_id.length; i++) {
      let element = params.plan_id[i];
      let byFilterProduct = await axios.get(
        `${SERVER_URL}/integrations/outlet_by_product_name?AREA=${element}&MONTH_NUMBER=${params.MONTH_NUMBER}&YEAR_NUMBER=${params.YEAR_NUMBER}&PRODUCT_NAME=${params.brand}`,
        config
      );
      filterProduct = filterProduct.concat(byFilterProduct.data);
    }

    if (mode === "single") {
      dispatch({
        type: "filter_business",
        payload: filterProduct,
        choice: "productUnit",
      });
    } else if (mode === "multi") {
      dispatch({
        type: "filter_business_multi",
        payload: filterProduct,
        choice: "productUnit",
      });
    }

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_principle_unit = (params, mode) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let filterPrinciple = [];
    for (let i = 0; i < params.plan_id.length; i++) {
      let element = params.plan_id[i];
      let byFilterPrinciple = await axios.get(
        `${SERVER_URL}/integrations/outlet_by_principle?AREA=${element}&MONTH_NUMBER=${params.MONTH_NUMBER}&YEAR_NUMBER=${params.YEAR_NUMBER}&PRINCIPLE=${params.brand}`,
        config
      );
      filterPrinciple = filterPrinciple.concat(byFilterPrinciple.data);
    }

    if (mode === "single") {
      dispatch({
        type: "filter_business",
        payload: filterPrinciple,
        choice: "principleUnit",
      });
    } else if (mode === "multi") {
      dispatch({
        type: "filter_business_multi",
        payload: filterPrinciple,
        choice: "principleUnit",
      });
    }

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const resetMode = () => (dispatch) => {
  dispatch({
    type: "clear_multi",
  });
};

//get_competitor_no_depo_stream
export const get_competitor_no_depo_stream = (params) => async (dispatch) => {
  try {
    dispatch(set_loading("get_competitor_no_depo_stream"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati-outlet/get_competitor_no_depo_stream?COUNTRY=${params.COUNTRY}`,
      config
    );
    let toko_list = res.data;
    dispatch(generate_filter_item(toko_list));
    toko_list = generate_omset(toko_list);
    dispatch({
      type: "get_outlet_list_by_area",
      payload: toko_list,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//edit_outlet_bulk
export const edit_outlet_bulk = (body) => async (dispatch) => {
  dispatch(set_loading("edit_outlet_bulk"));
  dispatch({
    type: "set_value_properties",
    payload: { key: "current_array_edit", value: [] },
  });
  dispatch({
    type: "outlet_upload_changes",
  });

  try {
    const { outlet_array } = body;
    const total = outlet_array.length;
    const limit = 500;
    const iteration = Math.ceil(total / limit);
    let array = [];
    for (let i = 0; i < iteration; i++) {
      array.push({
        start: i * limit,
        end: i * limit + limit,
      });
    }
    const delay_c = 0;
    const delay = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function = () => {
      return array.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const { start, end } = object;
            const outlet_array_i = outlet_array.slice(start, end);
            const body_new = {
              outlet_array: outlet_array_i,
            };
            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };
            const res = await axios.post(
              `${SERVER_URL}/nabati-outlet/edit_outlet_bulk`,
              body_new,
              config
            );

            const current_array_edit = res.data;

            const success_array = current_array_edit.filter(
              (s) => s.status === "success"
            );
            const not_found_array = current_array_edit.filter(
              (s) => s.status === "outlet_not_yet_created"
            );
            const error_array = current_array_edit.filter(
              (s) => !["outlet_not_yet_created", "success"].includes(s.status)
            );

            dispatch({
              type: "push_list_bulk",
              payload: { key: "current_array_edit", value: current_array_edit },
            });
            dispatch({
              type: "push_list_bulk",
              payload: { key: "success_array", value: success_array },
            });
            dispatch({
              type: "push_list_bulk",
              payload: { key: "not_found_array", value: not_found_array },
            });
            dispatch({
              type: "push_list_bulk",
              payload: { key: "error_array", value: error_array },
            });

            dispatch({
              type: "outlet_upload_changes",
            });
          } catch (error) {
            console.log("error_child=", error);
          }
        };
        return core_function();
      });
    };
    parent_function().then(() => {
      dispatch(clear_loading());
      dispatch({
        type: "set_value_properties",
        payload: {
          key: "edit_outlet_finish_status",
          value: true,
        },
      });
      dispatch({
        type: "outlet_upload_changes",
      });
    });
  } catch (error) {
    console.log("error_parent=", error);
    dispatch(clear_loading());
  }
};

//add_outlet_bulk
export const add_outlet_bulk = (body) => async (dispatch) => {
  dispatch(set_loading("add_outlet_bulk"));
  dispatch({
    type: "set_list",
    payload: { text: "current_array_add", list: [] },
  });
  let current_array = [];
  try {
    const outlet_array = body;
    const total = outlet_array.length;
    const limit = 1_000;
    const iteration = Math.ceil(total / limit);
    let array = [];
    for (let i = 0; i < iteration; i++) {
      array.push({
        start: i * limit,
        end: i * limit + limit,
      });
    }
    const delay_c = 0;
    const delay = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function = () => {
      return array.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const { start, end } = object;
            const outlet_array_i = outlet_array.slice(start, end);
            const body_new = outlet_array_i;
            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };
            await axios.post(
              `${SERVER_URL}/nabati-outlet/add_outlet_bulk`,
              body_new,
              config
            );
            current_array = [...current_array, ...outlet_array_i];
            dispatch({
              type: "set_list",
              payload: { text: "current_array_add", list: current_array },
            });

            dispatch({
              type: "outlet_upload_changes",
            });
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(() => {
      dispatch(clear_loading());
      dispatch({
        type: "set_value_properties",
        payload: {
          key: "add_outlet_finish_status",
          value: true,
        },
      });
      dispatch({
        type: "outlet_upload_changes",
      });
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

//reset_rayon_outlet
export const reset_rayon_outlet = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("reset_rayon_outlet"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(
      `${SERVER_URL}/nabati_per/reset_rayon_outlet`,
      body,
      config
    );
    dispatch({
      type: "reset_rayon_outlet",
      payload: body,
    });
    dispatch(clear_loading());
    dispatch({
      type: "status_action",
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_aggregate_outlet
export const get_aggregate_outlet = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("get_aggregate_outlet"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.post(
      `${SERVER_URL}/nabati-outlet/get_aggregate_outlet`,
      body,
      config
    );
    const item = {
      body,
      result: res.data,
    };
    dispatch({
      type: "push_list",
      payload: { text: "agg_list", item },
    });
    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//update_many_outlet_depo
export const update_many_outlet_depo = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("update_many_outlet_depo"));
    const { depo_list, toko_list } = body;
    let current = 0;
    setTimeout(() => {
      const delay_c = 0;
      let array = depo_list;
      const delay = () => new Promise((res) => setTimeout(res, delay_c));
      const parent_function = () => {
        return array.reduce(
          (last_promise, object) =>
            last_promise.then((result_sum) =>
              child_function(object).then((result_current) => [
                ...result_sum,
                result_current,
              ])
            ),
          Promise.resolve([])
        );
      };
      const child_function = async (object) => {
        return delay().then(() => {
          const core_function = async () => {
            try {
              const { Depo, _id } = object;
              const outlet_id_array = toko_list
                .filter((t) => t.Depo === Depo)
                .map((t) => t._id);
              const body = {
                outlet_id_array,
                depo_id: _id,
                depo_string: Depo,
              };
              const config = {
                headers: {
                  accesstoken: localStorage.jwtTokenNabati,
                },
              };
              await axios.post(
                `${SERVER_URL}/nabati_per/update_many_outlet_depo`,
                body,
                config
              );
              dispatch({
                type: "update_many_outlet_depo",
                payload: body,
              });
              current = current + 1;
              dispatch({
                type: "set_list",
                payload: { text: "current", list: current },
              });
            } catch (error) {}
          };
          return core_function();
        });
      };
      parent_function().then(() => {
        dispatch({
          type: "status_action",
        });

        if (current === depo_list.length) {
          dispatch(clear_loading());
        }
      });
    }, 1000);
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_count_competitor_national
export const get_count_competitor_national = () => async (dispatch) => {
  try {
    dispatch(set_loading("get_count_competitor_national"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati-outlet/get_count_competitor_national`,
      config
    );
    dispatch({
      type: "set_list",
      payload: { text: "competitor_national_count", list: res.data },
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_count_competitor_national_not_done
export const get_count_competitor_national_not_done =
  () => async (dispatch) => {
    try {
      dispatch(set_loading("get_count_competitor_national_not_done"));
      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenNabati,
        },
      };
      const res = await axios.get(
        `${SERVER_URL}/nabati-outlet/get_count_competitor_national_not_done`,
        config
      );
      dispatch({
        type: "set_list",
        payload: { text: "competitor_national_count_not_done", list: res.data },
      });
      dispatch({
        type: "status_action",
      });
      dispatch(clear_loading());
    } catch (e) {
      dispatch(clear_loading());
    }
  };

//get_competitor_national_stream
export const get_competitor_national_stream = (params) => async (dispatch) => {
  try {
    dispatch(set_loading("get_competitor_by_area"));
    const { total, skip } = params;
    const limit = 5_000;
    const delay_c = 0;
    const iteration = Math.ceil(total / limit);
    let array = [];
    for (let i = 0; i < iteration; i++) {
      array.push({
        skip: skip + i * limit,
        limit: limit,
      });
    }
    const delay = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function = () => {
      return array.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const { skip, limit } = object;
            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };
            const res = await axios.get(
              `${SERVER_URL}/nabati-outlet/get_competitor_national_stream?skip=${skip}&limit=${limit}`,
              config
            );
            let toko_list = res.data;
            dispatch(generate_filter_item(toko_list));
            toko_list = generate_omset(toko_list);
            dispatch({
              type: "get_outlet_list_by_area",
              payload: toko_list,
            });
            dispatch({
              type: "add_progres_toko",
              payload: res?.data?.length ? res?.data?.length : 0,
            });
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(() => {
      dispatch({
        type: "status_action",
      });

      dispatch(clear_loading());
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

// //set_day_list_to_cycle
// export const set_day_list_to_cycle = (body) => async (dispatch) => {
//   try {
//     dispatch(set_loading("set_day_list_to_cycle"));
//     await axios.post(`${SERVER_URL}/nabati_per/set_day_list_to_cycle`, body);
//     dispatch({
//       type: "set_day_list_to_cycle",
//       payload: body,
//     });
//     dispatch(clear_loading());
//   } catch (e) {
//     dispatch(clear_loading());
//   }
// };

//set_cycle_list_to_rayon
export const set_cycle_list_to_rayon = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("set_cycle_list_to_rayon"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(
      `${SERVER_URL}/nabati_per/set_cycle_list_to_rayon`,
      body,
      config
    );
    dispatch({
      type: "set_cycle_list_to_rayon",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

/*POST METHODE*/

//bulk_add_toko_nabati
export const bulk_add_toko_nabati = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("bulk_add_toko_nabati"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(
      `${SERVER_URL}/nabati_per/bulk_add_toko_nabati`,
      body,
      config
    );
    // dispatch({
    //   type: "set_list",
    //   payload: { text: "bulk_add_outlet_status", list: res.data },
    // });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//bulk_add_salesman
export const bulk_add_salesman = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("bulk_add_salesman"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.post(
      `${SERVER_URL}/nabati_per/bulk_add_salesman`,
      body,
      config
    );
    dispatch({
      type: "bulk_add_salesman",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//create_segment
export const create_segment = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("create_segment"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.post(
      `${SERVER_URL}/nabati_per/create_segment`,
      body,
      config
    );
    dispatch({
      type: "create_segment",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//edit_segment
export const edit_segment = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("edit_segment"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(`${SERVER_URL}/nabati_per/edit_segment`, body, config);
    dispatch({
      type: "edit_segment",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//delete_segment
export const delete_segment = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("delete_segment"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(`${SERVER_URL}/nabati_per/delete_template`, body, config);
    dispatch({
      type: "delete_segment",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//create_type_outlet
export const create_type_outlet = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("create_type_outlet"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.post(
      `${SERVER_URL}/nabati_per/create_type_outlet`,
      body,
      config
    );
    dispatch({
      type: "create_type_outlet",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//edit_type_outlet
export const edit_type_outlet = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("edit_type_outlet"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(`${SERVER_URL}/nabati_per/edit_type_outlet`, body, config);
    dispatch({
      type: "edit_type_outlet",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//delete_type_outlet
export const delete_type_outlet = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("delete_type_outlet"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(`${SERVER_URL}/nabati_per/delete_template`, body, config);
    dispatch({
      type: "delete_type_outlet",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//create_apk_version
export const create_apk_version = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("create_apk_version"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.post(
      `${SERVER_URL}/nabati_per/create_apk_version`,
      body,
      config
    );
    dispatch({
      type: "create_apk_version",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//edit_apk_version
export const edit_apk_version = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("edit_apk_version"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(`${SERVER_URL}/nabati_per/edit_apk_version`, body, config);
    dispatch({
      type: "edit_apk_version",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//delete_apk_version
export const delete_apk_version = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("delete_apk_version"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(`${SERVER_URL}/nabati_per/delete_template`, body, config);
    dispatch({
      type: "delete_apk_version",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_apk_version_list
export const get_apk_version_list = () => async (dispatch) => {
  try {
    dispatch(set_loading("get_apk_version_list"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati_per/get_apk_version_list`,
      config
    );
    dispatch({
      type: "get_apk_version_list",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const delete_salesman_rayon_id = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("delete_salesman_rayon_id"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(
      `${SERVER_URL}/nabati_per/delete_salesman_rayon_id`,
      body,
      config
    );
    dispatch({
      type: "delete_salesman_rayon_id",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const update_salesman_rayon = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("update_salesman_rayon"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(
      `${SERVER_URL}/nabati_per/update_salesman_rayon`,
      body,
      config
    );
    dispatch({
      type: "update_salesman_rayon",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const save_depo_kec = (body) => async (dispatch) => {
  try {
    // dispatch(set_loading("save_depo_kec"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.post(
      `${SERVER_URL}/nabati_per/save_depo_kec`,
      body,
      config
    );
    dispatch({
      type: "save_depo_kec",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    // dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//update_many_toko_rayon
export const update_many_toko_rayon = (array) => async (dispatch) => {
  try {
    dispatch(set_loading("update_many_toko_rayon"));
    const delay = () => new Promise((res) => setTimeout(res, 1));
    const parent_function = () => {
      return array.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const {
              toko_id_array,
              depo_id,
              depo_string,
              rayon_id,
              rayon_string,
            } = object;
            const body = {
              toko_id_array,
              depo_id,
              depo_string,
              rayon_id,
              rayon_string,
            };
            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };
            await axios.post(
              `${SERVER_URL}/nabati_per/update_many_toko_rayon`,
              body,
              config
            );
            dispatch({
              type: "update_many_toko_rayon",
              payload: body,
            });
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(() => {
      dispatch({
        type: "status_action",
      });
    });
    // dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//insert_many_rayon
export const insert_many_rayon = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("insert_many_rayon"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.post(
      `${SERVER_URL}/nabati_per/insert_many_rayon`,
      body,
      config
    );
    dispatch({
      type: "insert_many_rayon",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//create_competitor_code
export const create_competitor_code = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("create_competitor_code"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.post(
      `${SERVER_URL}/nabati_per/create_competitor_code`,
      body,
      config
    );
    dispatch({
      type: "create_competitor_code",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//edit_competitor_code
export const edit_competitor_code = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("edit_competitor_code"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(
      `${SERVER_URL}/nabati_per/edit_competitor_code`,
      body,
      config
    );
    dispatch({
      type: "edit_competitor_code",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//create_template
export const create_template = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("create_template"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.post(
      `${SERVER_URL}/nabati_per/create_template`,
      body,
      config
    );
    dispatch({
      type: "create_template",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//edit_template
export const edit_template = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("edit_template"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(`${SERVER_URL}/nabati_per/edit_template`, body, config);
    dispatch({
      type: "edit_template",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//delete_template
export const delete_template = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("delete_template"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(`${SERVER_URL}/nabati_per/delete_template`, body, config);
    dispatch({
      type: "delete_template",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//delete_competitor_code
export const delete_competitor_code = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("delete_competitor_code"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(`${SERVER_URL}/nabati_per/delete_template`, body, config);
    dispatch({
      type: "delete_competitor_code",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

/*GET METHODE*/

// //get_type_outlet_list
// export const get_type_outlet_list = () => async (dispatch) => {
//   try {
//     dispatch(set_loading("get_type_outlet_list"));
//     const config = {
//       headers: {
//         accesstoken: localStorage.jwtTokenNabati,
//       },
//     };
//     const res = await axios.get(
//       `${SERVER_URL}/nabati_per/get_type_outlet_list`,
//       config
//     );
//     dispatch({
//       type: "get_type_outlet_list",
//       payload: res.data,
//     });

//     dispatch({
//       type: "status_action",
//     });

//     dispatch(clear_loading());
//   } catch (e) {
//     dispatch(clear_loading());
//   }
// };

//get_toko_rayon_count
export const get_toko_rayon_count = () => async (dispatch) => {
  try {
    dispatch(set_loading("get_toko_rayon_count"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati_per/get_toko_rayon_count`,
      config
    );
    dispatch({
      type: "get_toko_rayon_count",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_all_city_rayon
export const get_all_city_rayon = () => async (dispatch) => {
  try {
    dispatch(set_loading("get_all_city_rayon"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati_per/get_all_city_rayon`,
      config
    );
    dispatch({
      type: "get_all_city_rayon",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_depo_list_by_multi_name
export const get_depo_list_by_multi_name = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("get_depo_list_by_multi_name"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.post(
      `${SERVER_URL}/nabati_per/get_depo_list_by_multi_name`,
      body,
      config
    );
    dispatch({
      type: "set_depo_loc_list",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_rayon_list_by_multi_name
export const get_rayon_list_by_multi_id = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("get_rayon_list_by_multi_id"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.post(
      `${SERVER_URL}/nabati_per/get_rayon_list_by_multi_id`,
      body,
      config
    );
    dispatch({
      type: "get_rayon_multi_depo",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_rayon_multi_depo
export const get_rayon_multi_depo = (params) => async (dispatch) => {
  try {
    const { depo_id_array } = params;
    dispatch(set_loading("get_rayon_multi_depo"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati_per/get_rayon_multi_depo?depo_id_array=${depo_id_array}`,
      config
    );
    dispatch({
      type: "get_rayon_multi_depo",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_salesman_by_depo_id_array
export const get_salesman_by_depo_id_array = (params) => async (dispatch) => {
  try {
    const { depo_id_array } = params;
    dispatch(set_loading("get_salesman_by_depo_id_array"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati_per/get_salesman_by_depo_id_array?depo_id_array=${depo_id_array}`,
      config
    );
    dispatch({
      type: "GET_SALESMAN_LIST",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_competitor_count
export const get_competitor_count = () => async (dispatch) => {
  try {
    dispatch(set_loading("get_competitor_count"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati_per/get_competitor_count`,
      config
    );
    dispatch({
      type: "get_competitor_count",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

// //get_competitor_code_list
// export const get_competitor_code_list = () => async (dispatch) => {
//   try {
//     dispatch(set_loading("get_competitor_code_list"));
//     const config = {
//       headers: {
//         accesstoken: localStorage.jwtTokenNabati,
//       },
//     };
//     const res = await axios.get(
//       `${SERVER_URL}/nabati_per/get_competitor_code_list`,
//       config
//     );
//     dispatch({
//       type: "get_competitor_code_list",
//       payload: res.data,
//     });

//     dispatch({
//       type: "status_action",
//     });

//     dispatch(clear_loading());
//   } catch (e) {
//     dispatch(clear_loading());
//   }
// };

//get_template_list
export const get_template_list = () => async (dispatch) => {
  try {
    dispatch(set_loading("get_template_list"));

    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };

    const res = await axios.get(
      `${SERVER_URL}/nabati_per/get_template_list`,
      config
    );
    dispatch({
      type: "get_template_list",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_driver = () => async (dispatch) => {
  try {
    dispatch(set_loading("get_driver"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(`${SERVER_URL}/nabati/get_driver`, config);
    dispatch({
      type: "get_driver",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_all_depo = (params) => async (dispatch) => {
  try {
    dispatch(set_loading("get_all_depo"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati_per/get_all_depo?MODE=${params?.MODE}`,
      config
    );
    let list = res.data;
    const key = "Depo";
    list = [...new Map(list.map((item) => [item[key], item])).values()];
    dispatch({
      type: "get_all_depo",
      payload: list,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_all_depo_country = (params) => async (dispatch) => {
  try {
    dispatch(set_loading("get_all_depo_country"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati_per/get_all_depo_country?COUNTRY=${params?.COUNTRY}`,
      config
    );
    let list = res.data;
    const key = "Depo";
    list = [...new Map(list.map((item) => [item[key], item])).values()];
    dispatch({
      type: "get_all_depo",
      payload: list,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_depo_by_name = (depo_name) => async (dispatch) => {
  try {
    dispatch(set_loading("get_depo_by_name"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati_per/get_depo_by_name?depo_name=${depo_name}`,
      config
    );
    dispatch({
      type: "get_depo_by_name",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_omset_by_array_outlet_id =
  (outlet_id_array) => async (dispatch) => {
    try {
      dispatch(set_loading("get_omset_by_array_outlet_id"));
      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenNabati,
        },
      };
      const res = await axios.get(
        `${SERVER_URL}/nabati_per/get_omset_by_array_outlet_id?outlet_id_array=${outlet_id_array}`,
        config
      );
      dispatch({
        type: "get_omset_by_array_outlet_id",
        payload: res.data,
      });

      dispatch({
        type: "status_action",
      });

      dispatch(clear_loading());
    } catch (e) {
      dispatch(clear_loading());
    }
  };

export const get_toko_edited_by_apk = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let getData = params;
    dispatch({
      type: "get_toko_edited_by_apk_clear",
    });

    dispatch(set_loading_top("get_toko_edited_by_apk"));
    dispatch({ type: "SET_LOADING", payload: "get_toko_edited_by_apk" });

    for (let i = 0; i < getData.length; i++) {
      let element = getData[i];
      let res = await axios.get(
        `${SERVER_URL}/nabati-outlet/get_unplot_outlet?plan_id=${element}`,
        config
      );
      const toko_list = res.data;
      dispatch({
        type: "get_toko_edited_by_apk",
        payload: toko_list,
      });
      dispatch(generate_filter_item(toko_list));
      if (i === getData.length - 1) {
        dispatch({
          type: "status_action",
        });
        dispatch(clear_loading());
        dispatch(clear_loading_top());
      }
    }
  } catch (error) {
    dispatch(clear_loading());
    dispatch(clear_loading_top());
  }
};

export const get_competitor_list_by_area = (params) => async (dispatch) => {
  try {
    dispatch(set_loading("get_competitor_list_by_area"));
    const { area, name_arr } = params;
    const delay_c = 0;
    const delay = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function = () => {
      return name_arr.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };
            const res = await axios.get(
              `${SERVER_URL}/nabati-outlet/get_competitor_list_by_area?area=${area}&name=${object.value}`,
              config
            );
            let toko_list = res.data;
            dispatch(generate_filter_item(toko_list));
            toko_list = generate_omset(toko_list);
            dispatch({
              type: "get_outlet_list_by_area",
              payload: toko_list,
            });
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(async () => {
      dispatch({
        type: "status_action",
      });

      dispatch(clear_loading());
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_outlet_list_by_area = (params) => async (dispatch) => {
  try {
    dispatch(set_loading("get_outlet_list_by_area"));
    const {
      area,
      is_plan,
      name_arr,
      country,
      mode_closed, //without_closed | with_closed
      mode_omset, //with_omset | without_omset
      // eventSelect,
    } = params;

    let result = [];
    const delay_c = 0;
    const delay = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function = () => {
      return name_arr.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };

    const child_function = async (object) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };
            let res = [];

            if (mode_closed === "without_closed") {
              res = await axios.get(
                `${SERVER_URL}/nabati-outlet/get_outlet_list_by_area?area=${area}&name=${object.value}&country=${country}&status=without_closed`,
                config
              );
            } else {
              res = await axios.get(
                `${SERVER_URL}/nabati-outlet/get_outlet_list_by_area?area=${area}&name=${object.value}&country=${country}`,
                config
              );
            }

            let toko_list = res.data;
            dispatch(generate_filter_item(toko_list));

            if (mode_omset !== "without_omset") {
              // GET AVG OMSET TOKO
              // LOOP DENGAN RES
              const resOmset = await axios.get(
                `https://outdigserver.nabatisnack.co.id/integrations/outlet_avg_3_month_depo_name?AREA=${object.value}`,
                config
              );
              const omsetToko = resOmset.data;

              for (let index = 0; index < toko_list.length; index++) {
                const element = toko_list[index];
                const match = omsetToko.find(
                  (e) => e.customer_id === element["ID Outlet"]
                );
                if (match) {
                  toko_list[index].omset_arr = [
                    {
                      period: `${new Date().getFullYear()}${
                        new Date().getMonth() + 1
                      }`,
                      year: new Date().getFullYear(),
                      month: new Date().getMonth(),
                      qty: parseFloat(match.qty),
                      value: parseFloat(match.value),
                    },
                  ];
                } else {
                  toko_list[index].omset_arr = [
                    {
                      period: `${new Date().getFullYear()}${
                        new Date().getMonth() + 1
                      }`,
                      year: new Date().getFullYear(),
                      month: new Date().getMonth(),
                      qty: 0,
                      value: 0,
                    },
                  ];
                }
              }
              toko_list = generate_omset(toko_list);
              dispatch(generate_filter_item(toko_list));

              const resOmsetSplit = await axios.get(
                `https://outdigserver.nabatisnack.co.id/integrations/outlet_avg_3_month_depo_name_split_omset?AREA=${object.value}`,
                config
              );
              const omsetTokoSplit = resOmsetSplit.data;
              dispatch({
                type: "omset_split",
                payload: omsetTokoSplit,
              });
            }
            dispatch({
              type: "get_outlet_list_by_area",
              payload: toko_list,
            });
            result = [...result, ...res.data];
          } catch (error) {}
        };
        return core_function();
      });
    };

    parent_function().then(async () => {
      dispatch(clear_loading());
      //jika toko sudah memiliki rayon, load database rayon
      const rayon_id_array = [
        ...new Set(result.map((e) => e?.rayon_id)),
      ].filter((e) => !!e);
      if (rayon_id_array.length > 0 && is_plan) {
        const body = { rayon_id_array };

        const config = {
          headers: {
            accesstoken: localStorage.jwtTokenNabati,
          },
        };
        const res = await axios.post(
          `${SERVER_URL}/nabati_per/get_rayon_by_array_id`,
          body,
          config
        );
        dispatch({
          type: "set_list",
          payload: { text: "rayon_list_database", list: res.data },
        });
        dispatch({
          type: "set_config_mode_menu",
          payload: { menu: "distribution", mode: "rayon" },
        });
        dispatch({
          type: "turn_on_menu_status",
          payload: { key: "rayon" },
        });
      } else {
        dispatch({
          type: "set_config_mode_menu",
          payload: { menu: "distribution", mode: "Depo" },
        });
      }

      dispatch({
        type: "status_action",
      });
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_outlet_list_by_all_area = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let loop = true;
    let skip = 0;
    while (loop) {
      const res = await axios.get(
        `${SERVER_URL}/nabati-outlet/get_outlet_list_all?country=${params}&skip=${skip}&limit=1000`,
        config
      );
      if (res.data.length === 0) {
        loop = false;
      }
      let toko_list = res.data;
      dispatch(generate_filter_item(toko_list));
      toko_list = generate_omset(toko_list);
      dispatch({
        type: "get_outlet_list_by_area",
        payload: toko_list,
      });
      skip += 1000;
    }

    dispatch({
      type: "status_action",
    });
  } catch (error) {}
};

export const clear_get_outlet_list_by_area = () => (dispatch) => {
  dispatch({ type: "clear_get_outlet_list_by_area" });
  dispatch({
    type: "status_action",
  });
};

export const get_omset = (params) => async (dispatch) => {
  try {
    dispatch(set_loading("get_omset"));
    const { MONTH_NUMBER, YEAR_NUMBER, plan_id, type } = params;

    // versi integrasi
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    dispatch({
      type: "get_omset_clear",
    });
    for (let i = 0; i < plan_id.length; i++) {
      const element = plan_id[i];
      let outlets = await axios.get(
        `${SERVER_URL}/nabati-outlet/outlet_by_plan_id?plan_id=${element}&type=${type}`,
        config
      );
      const rayon_id_array = [
        ...new Set(outlets.data.map((e) => e.rayon_id)),
      ].filter((e) => !!e);
      if (rayon_id_array.length > 0) {
        const body = { rayon_id_array };
        const config = {
          headers: {
            accesstoken: localStorage.jwtTokenNabati,
          },
        };
        const res = await axios.post(
          `${SERVER_URL}/nabati_per/get_rayon_by_array_id`,
          body,
          config
        );
        dispatch({
          type: "set_list",
          payload: { text: "rayon_list_database", list: res.data },
        });
      }
      let omsets = await axios.get(
        `${SERVER_URL}/integrations/by_depo?MONTH_NUMBER=${
          parseInt(MONTH_NUMBER) + 1
        }&YEAR_NUMBER=${YEAR_NUMBER}&AREA=${element}`,
        config
      );
      outlets = outlets.data;
      omsets = omsets.data;

      let newOutlets = outlets.map((e) => e["ID Outlet"]);
      let newOmsets = omsets.map((e) => e.customer_id);
      let newArray = newOutlets.concat(newOmsets);
      const filter_arr = [...new Set(newArray)];
      let match = [];
      for (let i = 0; i < filter_arr.length; i++) {
        const element = filter_arr[i];
        let outletMatch = outlets.findIndex((e) => e["ID Outlet"] === element);
        let omsetMatch = omsets.findIndex((e) => e.customer_id === element);
        if (outletMatch !== -1 && omsetMatch !== -1) {
          match.push({
            ...outlets[outletMatch],
            VALUE: parseFloat(omsets[omsetMatch].value),
            QTY: parseFloat(omsets[omsetMatch].qty),
            "Nama Toko": outlets[outletMatch]["Nama Toko"],
            MONTH_NUMBER: parseInt(MONTH_NUMBER) + 1,
            YEAR_NUMBER: parseInt(YEAR_NUMBER),
          });
        }
        if (outletMatch === -1 && omsetMatch !== -1) {
          match.push({
            ...omsets[omsetMatch],
            VALUE: parseFloat(omsets[omsetMatch].value),
            QTY: parseFloat(omsets[omsetMatch].qty),
            location: { type: "Point", coordinates: [0, 0] },
            "Nama Toko": "tidak ada nama",
            "ID Outlet": omsets[omsetMatch].customer_id,
            MONTH_NUMBER: parseInt(MONTH_NUMBER) + 1,
            YEAR_NUMBER: parseInt(YEAR_NUMBER),
          });
        }
        if (outletMatch !== -1 && omsetMatch === -1) {
          match.push({
            ...outlets[outletMatch],
            VALUE: 0,
            QTY: 0,
            "Nama Toko": outlets[outletMatch]["Nama Toko"],
            MONTH_NUMBER: parseInt(MONTH_NUMBER) + 1,
            YEAR_NUMBER: parseInt(YEAR_NUMBER),
          });
        }
      }

      dispatch({
        type: "get_omset",
        payload: match,
      });
      dispatch({
        type: "getDataBaseOmset",
        payloadMongos: outlets,
        payloadPostgress: omsets,
      });

      const toko_list = match;
      dispatch(generate_filter_item(toko_list));
    }

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_omset_detail_by_month_toko = (params) => async (dispatch) => {
  try {
    dispatch(set_loading("get_omset_detail_by_month_toko"));
    const { MONTH_NUMBER, YEAR_NUMBER, outlet_id } = params;
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati_per/get_omset_detail_by_month_toko?MONTH_NUMBER=${MONTH_NUMBER}&YEAR_NUMBER=${YEAR_NUMBER}&outlet_id=${outlet_id}`,
      config
    );
    dispatch({
      type: "get_omset_detail_by_month_toko",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_omset_by_toko_by_month_product =
  (params) => async (dispatch) => {
    try {
      // const { MONTH_NUMBER, YEAR_NUMBER, outlet_id, mode, CUSTOMER_ID, AREA } = params;
      const { MONTH_NUMBER, YEAR_NUMBER, mode, CUSTOMER_ID, AREA } = params;
      let url = "";
      const NEW_MONTH_NUMBER = parseInt(MONTH_NUMBER);
      let api = "brand_per_outlet";
      if (mode === "bu") {
        dispatch(set_loading("get_omset_by_toko_by_month_product_bu"));
        api = "business_unit_per_outlet";
      } else if (mode === "brand") {
        dispatch(set_loading("get_omset_by_toko_by_month_product_brand"));
        api = "brand_per_outlet";
      } else if (mode === "sku") {
        dispatch(set_loading("get_omset_by_toko_by_month_product_sku"));
        api = "mark_new_per_outlet";
      } else {
        dispatch(set_loading("get_omset_by_toko_by_month_product"));
      }
      url = `${SERVER_URL}/integrations/${api}?MONTH_NUMBER=${NEW_MONTH_NUMBER}&YEAR_NUMBER=${YEAR_NUMBER}&CUSTOMER_ID=${CUSTOMER_ID}&AREA=${AREA}`;
      // const res = await axios.get(
      //   `${SERVER_URL}/nabati_per/get_omset_by_toko_by_month_product?MONTH_NUMBER=${MONTH_NUMBER}&YEAR_NUMBER=${YEAR_NUMBER}&outlet_id=${outlet_id}&mode=${mode}`
      // );
      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenNabati,
        },
      };
      const res = await axios.get(url, config);
      if (mode === "bu") {
        dispatch({
          type: "get_omset_by_toko_by_month_product_bu",
          payload: res.data,
        });
        dispatch(clear_loading());
      } else if (mode === "brand") {
        dispatch({
          type: "get_omset_by_toko_by_month_product_brand",
          payload: res.data,
        });
        dispatch(clear_loading());
      } else if (mode === "sku") {
        dispatch({
          type: "get_omset_by_toko_by_month_product_sku",
          payload: res.data,
        });
        dispatch(clear_loading());
      } else {
        dispatch({
          type: "get_omset_by_toko_by_month_product",
          payload: res.data,
        });
        dispatch(clear_loading());
      }

      dispatch({
        type: "status_action",
      });
    } catch (e) {
      dispatch(clear_loading());
    }
  };

export const get_history_omset_by_toko_aggregate_month =
  (params) => async (dispatch) => {
    try {
      dispatch(set_loading("get_history_omset_by_toko_aggregate_month"));
      const { outlet_id } = params;
      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenNabati,
        },
      };
      const res = await axios.get(
        `${SERVER_URL}/nabati_per/get_history_omset_by_toko_aggregate_month?outlet_id=${outlet_id}`,
        config
      );
      dispatch({
        type: "get_history_omset_by_toko_aggregate_month",
        payload: res.data,
      });

      dispatch({
        type: "status_action",
      });

      dispatch(clear_loading());
    } catch (e) {
      dispatch(clear_loading());
    }
  };

export const get_history_omset_by_toko_aggregate_month_new =
  (params) => async (dispatch) => {
    try {
      dispatch(set_loading("get_history_omset_by_toko_aggregate_month"));
      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenNabati,
        },
      };
      const res = await axios.get(
        `${SERVER_URL}/integrations/omset_monthly_per_outlet?AREA=${params.AREA}&CUSTOMER_ID=${params.CUSTOMER_ID}`,
        config
      );
      dispatch({
        type: "get_history_omset_by_toko_aggregate_month",
        payload: res.data,
      });

      dispatch({
        type: "status_action",
      });

      dispatch(clear_loading());
    } catch (error) {
      dispatch(clear_loading());
    }
  };

export const get_all_submitted_competitor = () => async (dispatch) => {
  try {
    dispatch(set_loading("get_all_submitted_competitor"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati-outlet/get_all_submitted_competitor`,
      config
    );
    dispatch({
      type: "get_all_submitted_competitor",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_all_failed_competitor = () => async (dispatch) => {
  try {
    dispatch(set_loading("get_all_failed_competitor"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati-outlet/get_all_failed_competitor`,
      config
    );
    dispatch({
      type: "get_all_failed_competitor",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_competitor_by_area
export const get_competitor_by_area = (params) => async (dispatch) => {
  try {
    dispatch(set_loading("get_competitor_by_area"));
    const { KELURAHAN, KECAMATAN, KABUPATEN, PROPINSI, MODE, COUNTRY } = params;
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati-outlet/get_competitor_by_area?KELURAHAN=${KELURAHAN}&KECAMATAN=${KECAMATAN}&KABUPATEN=${KABUPATEN}&PROPINSI=${PROPINSI}&COUNTRY=${COUNTRY}&MODE=${MODE}`,
      config
    );
    let toko_list = res.data;
    dispatch(generate_filter_item(toko_list));
    toko_list = generate_omset(toko_list);
    dispatch({
      type: "get_outlet_list_by_area",
      payload: toko_list,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//get_by_country
export const get_by_country = (params) => async (dispatch) => {
  try {
    dispatch(set_loading("get_competitor_by_area"));
    const { COUNTRY } = params;
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati-outlet/get_by_country?COUNTRY=${COUNTRY}`,
      config
    );
    let toko_list = res.data;
    dispatch(generate_filter_item(toko_list));
    toko_list = generate_omset(toko_list);
    dispatch({
      type: "get_outlet_list_by_area",
      payload: toko_list,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_merch_list = (params) => async (dispatch) => {
  try {
    dispatch(set_loading("get_merch_list"));
    if (params === null) {
      dispatch({
        type: "get_merch_list",
        payload: [],
      });
      dispatch(clear_loading());
      return;
    }
    const { month, year } = params;
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati-merchandise/get_by_month_year?month=${month}&year=${year}`,
      config
    );
    dispatch({
      type: "get_merch_list",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const getSalesman = (props) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const hour = new Date().getHours();
    dispatch(set_loading_top());
    let payload = [];
    for (let index = 0; index < props.value.length; index++) {
      const element = props.value[index];
      const res = await axios.get(
        `${SERVER_URL}/nabati/filter?hour=${hour}&key=${props.key}&value=${element.value}`,
        config
      );
      payload = [...payload, ...res.data];
    }
    dispatch({
      type: "GET_SALESMAN_LIST",
      payload: payload,
    });
    // if (props.tambah_kurang === "tambah") {
    //   const res = await axios.get(
    //     `${SERVER_URL}/nabati/filter?hour=${hour}&key=${props.key}&value=${
    //       props.value[props.value.length - 1].value
    //     }`,
    //     config
    //   );
    //   let payload = [...props.last_value, ...res.data];
    //   dispach({
    //     type: "GET_SALESMAN_LIST",
    //     payload: payload,
    //   });
    // }
    // if (props.tambah_kurang === "kurang") {
    //   let payload = [];
    //   for (let index = 0; index < props.value.length; index++) {
    //     const element = props.value[index];
    //     const res = await axios.get(
    //       `${SERVER_URL}/nabati/filter?hour=${hour}&key=${props.key}&value=${element.value}`,
    //       config
    //     );
    //     payload = [...payload, ...res.data];
    //   }
    //   dispach({
    //     type: "GET_SALESMAN_LIST",
    //     payload: payload,
    //   });
    // }

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading_top());
  } catch (error) {
    dispatch(clear_loading_top());
  }
};

// const timer = (ms) => new Promise((res) => setTimeout(res, ms));
export const getSalesmanAll = (props, status) => async (dispatch) => {
  try {
    // const config = {
    //   headers: {
    //     accesstoken: localStorage.jwtTokenNabati,
    //   },
    // };
    const hour = new Date().getHours();
    dispatch(set_loading_top());
    let getData = [];
    for (let i = 0; i < props.length; i++) {
      // await timer(2000);
      const element = props[i];
      const res = await axios.get(
        `https://outdigserver2.nabatisnack.co.id/nabati/filter?hour=${hour}&key=Depo&value=${element}`
      );
      getData = [...getData, ...res.data];
    }
    if (status === "All Salesman") {
      dispatch({
        type: "GET_SALESMAN_LIST",
        payload: getData,
      });
      dispatch({
        type: "status_action",
      });
    } else if (status === "Salesman Online") {
      const onlineSalesman = getData.filter(
        (e) => e.status_bike === "bike_green"
      );
      dispatch({
        type: "GET_SALESMAN_LIST",
        payload: onlineSalesman,
      });
      dispatch({
        type: "status_action",
      });
    } else if (status === "status") {
      const activeSalesman = getData.filter(
        (e) => e.status_bike === "bike_blue" || e.status_bike === "bike_green"
      );
      dispatch({
        type: "GET_SALESMAN_LIST",
        payload: activeSalesman,
      });
      dispatch({
        type: "status_action",
      });
    } else {
      dispatch({
        type: "GET_SALESMAN_LIST",
        payload: getData,
      });
      dispatch({
        type: "status_action",
      });
    }

    dispatch(clear_loading_top());
  } catch (error) {
    dispatch(clear_loading_top());
  }
};

export const getSalesmanOmset = (props) => async (dispatch) => {
  try {
    dispatch(set_loading("get_omset"));
    const { MONTH, YEAR_NUMBER, plan_id } = props;
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const hour = new Date().getHours();
    dispatch(set_loading_top());

    for (let index = 0; index < props.AREA.length; index++) {
      const element = props.AREA[index];
      let iconSalesman = await axios.get(
        `${SERVER_URL}/nabati/filter?hour=${hour}&key=${props.key}&value=${element}`,
        config
      );
      let omsetSalesman = await axios.get(
        `${SERVER_URL}/integration_salesman/omset_salesman_per_depo?AREA=${plan_id[index]}&MONTH_NUMBER=${MONTH}&YEAR_NUMBER=${YEAR_NUMBER}`,
        config
      );
      iconSalesman = iconSalesman.data;
      omsetSalesman = omsetSalesman.data;
      let newIconSalesman = iconSalesman.map((e) => e.kd_sales_arr[0]);
      let newOmsetSalesman = omsetSalesman.map((e) => e.salesman_id);
      let newArray = newIconSalesman.concat(newOmsetSalesman);
      const filter_arr = [...new Set(newArray)];
      // let result = [];
      let getMatch = [];
      for (let i = 0; i < filter_arr.length; i++) {
        const element = filter_arr[i];
        let salesMatch = iconSalesman.findIndex(
          (e) => e.kd_sales_arr[0] === element
        );
        let omsetMatch = omsetSalesman.findIndex(
          (e) => e.salesman_id === element
        );
        if (salesMatch !== -1 && omsetMatch !== -1) {
          getMatch.push({
            ...iconSalesman[salesMatch],
            omset_salesman: parseFloat(omsetSalesman[omsetMatch].value),
            qty_salesman: parseFloat(omsetSalesman[omsetMatch].qty),
          });
        }
        if (salesMatch === -1 && omsetMatch !== -1) {
          getMatch.push({
            ...iconSalesman[omsetMatch],
            omset_salesman: parseFloat(omsetSalesman[omsetMatch].value),
            qty_salesman: parseFloat(omsetSalesman[omsetMatch].qty),
            "Nama Salesman": "tidak ada nama",
            location: { type: "Point", coordinates: [0, 0] },
            kd_sales_arr: [omsetSalesman[omsetMatch].salesman_id],
          });
        }
        if (salesMatch !== -1 && omsetMatch === -1) {
          getMatch.push({
            ...iconSalesman[salesMatch],
            omset_salesman: 0,
            qty_salesman: 0,
            "Nama Salesman": iconSalesman[salesMatch]["Nama Salesman"],
          });
        }
      }
      // for (let i = 0; i < iconSalesman.length; i++) {
      //   const element = iconSalesman[i];
      //   const listSalesman = element.kd_sales_arr[0];
      //   const match = omsetSalesman.find((e) => e.salesman_id === listSalesman);
      //   if (match !== undefined) {
      //     result.push({
      //       ...element,
      //       omset_salesman: match.value,
      //       qty_salesman: match.qty,
      //     });
      //   } else {
      //     result.push({ ...element, omset_salesman: 0, qty_salesman: 0 });
      //   }
      // }
      dispatch({
        type: "GET_SALESMAN_OMSET_LIST",
        payload: getMatch,
      });
    }

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading_top());
  } catch (error) {
    dispatch(clear_loading_top());
  }
};

export const getOutlets = (props) => async (dispatch) => {
  try {
    dispatch(set_loading_top());
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati-outlet/outlet_by_kode_sales?kode=${props.kd_sales_arr.join()}&depo=${
        props.Depo
      }`,
      config
    );
    dispatch({
      type: "GET_OUTLET_LIST_BY_SALESMAN",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading_top());
  } catch (error) {
    dispatch(clear_loading_top());
  }
};

export const getSalesmanRegister = (country) => async (dispatch) => {
  try {
    dispatch(set_loading_top());
    const hour = new Date().getHours();
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati/salesman_register?hour=${hour}&country=${country}`,
      config
    );
    dispatch({
      type: "GET_SALESMAN_LIST",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading_top());
  } catch (error) {
    dispatch(clear_loading_top());
  }
};

export const getSalesmanFilter = (country) => async (dispatch) => {
  try {
    dispatch(set_loading_top());
    const user_admin = JSON.parse(localStorage.nabatiUser);
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(`${SERVER_URL}/nabati/select`, config);

    let depoHirarki = res.data.depo;
    let listDepoCountry = [];
    depoHirarki.forEach((e) => {
      if (e.country === country) {
        listDepoCountry.push(e);
      }
    });
    depoHirarki = listDepoCountry;

    let depoFilter = [],
      cabangFilter = [],
      regionalFilter = [];
    let depo = [],
      cabang = [],
      regional = [];
    depoHirarki.forEach((element) => {
      if (user_admin.cakupan_wilayah_kerja === "regional") {
        const userRegion = res.data.regional.find(
          (e) => e._id === user_admin.regional_list[0]
        )?.REGIONAL;
        if (element.REGION === userRegion) {
          if (!depoFilter.includes(element.Depo)) {
            depoFilter.push(element.Depo);
            depo.push(element);
          }
          if (!cabangFilter.includes(element.CABANG)) {
            cabangFilter.push(element.CABANG);
            cabang.push({ CABANG: element.CABANG, _id: element.CABANG_id });
          }
          if (!regionalFilter.includes(element.REGION)) {
            regionalFilter.push(element.REGION);
            regional.push({
              REGIONAL: element.REGION,
              _id: element.REGION_id,
            });
          }
        }
      }
      if (user_admin.cakupan_wilayah_kerja === "cabang") {
        const userCabang = res.data.cabang.find(
          (e) => e._id === user_admin.cabang_list[0]
        )?.CABANG;
        if (element.CABANG === userCabang) {
          if (!depoFilter.includes(element.Depo)) {
            depoFilter.push(element.Depo);
            depo.push(element);
          }
          if (!cabangFilter.includes(element.CABANG)) {
            cabangFilter.push(element.CABANG);
            cabang.push({ CABANG: element.CABANG, _id: element.CABANG_id });
          }
          if (!regionalFilter.includes(element.REGION)) {
            regionalFilter.push(element.REGION);
            regional.push({
              REGIONAL: element.REGION,
              _id: element.REGION_id,
            });
          }
        }
      }
      if (user_admin.cakupan_wilayah_kerja === "depo") {
        let userDepoId = "";
        if (typeof user_admin.depo === "object") {
          userDepoId = user_admin.depo._id;
        } else {
          userDepoId = user_admin.depo;
        }
        const userNamaDepo = res.data.depo.find(
          (e) => e._id === userDepoId
        ).Depo;
        if (element.Depo === userNamaDepo) {
          if (!depoFilter.includes(element.Depo)) {
            depoFilter.push(element.Depo);
            depo.push(element);
          }
          if (!cabangFilter.includes(element.CABANG)) {
            cabangFilter.push(element.CABANG);
            cabang.push({ CABANG: element.CABANG, _id: element.CABANG_id });
          }
          if (!regionalFilter.includes(element.REGION)) {
            regionalFilter.push(element.REGION);
            regional.push({
              REGIONAL: element.REGION,
              _id: element.REGION_id,
            });
          }
        }
      }

      if (user_admin.cakupan_wilayah_kerja === "nasional") {
        if (!depoFilter.includes(element.Depo)) {
          depoFilter.push(element.Depo);
          depo.push(element);
        }
        if (!cabangFilter.includes(element.CABANG)) {
          cabangFilter.push(element.CABANG);
          cabang.push({ CABANG: element.CABANG, _id: element.CABANG_id });
        }
        if (!regionalFilter.includes(element.REGION)) {
          regionalFilter.push(element.REGION);
          regional.push({ REGIONAL: element.REGION, _id: element.REGION_id });
        }
      }
    });
    let depo_list = res.data.depo;
    depo_list = depo_list.map((e, idx) => {
      let index = idx;
      if (idx > st_colors.length - 1) {
        index = idx % st_colors.length;
      }
      return { _id: e.Depo, text: e.Depo, color: st_colors[index] };
    });

    dispatch({
      type: "set_list",
      payload: { text: "depo_list", list: depo_list },
    });

    dispatch({
      type: "SET_SALESMAN_FILTER",
      payload: {
        depo,
        cabang,
        regional,
        original_cabang: res.data.original_cabang,
        original_regional: res.data.original_regional,
        original_depo: res.data.depo,
      },
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading_top());
  } catch (error) {
    dispatch(clear_loading_top());
  }
};

export const getSalesmanFilterRegister = (country) => async (dispatch) => {
  try {
    dispatch(set_loading_top());

    const res = await axios.get(`${SERVER_URL}/nabati/select`);

    let depoHirarki = res.data.depo;
    let listDepoCountry = [];
    depoHirarki.forEach((e) => {
      if (e.country === country) {
        listDepoCountry.push(e);
      }
    });
    depoHirarki = listDepoCountry;

    let depoFilter = [],
      cabangFilter = [],
      regionalFilter = [];
    let depo = [],
      cabang = [],
      regional = [];
    depoHirarki.forEach((element) => {
      if (!depoFilter.includes(element.Depo)) {
        depoFilter.push(element.Depo);
        depo.push(element);
      }
      if (!cabangFilter.includes(element.CABANG)) {
        cabangFilter.push(element.CABANG);
        cabang.push({ CABANG: element.CABANG, _id: element.CABANG_id });
      }
      if (!regionalFilter.includes(element.REGION)) {
        regionalFilter.push(element.REGION);
        regional.push({ REGIONAL: element.REGION, _id: element.REGION_id });
      }
    });

    dispatch({
      type: "SET_SALESMAN_FILTER",
      payload: {
        depo,
        cabang,
        regional,
        original_cabang: res.data.original_cabang,
        original_regional: res.data.original_regional,
        original_depo: res.data.depo,
      },
    });

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading_top());
  } catch (error) {
    dispatch(clear_loading_top());
  }
};

export const getOutletFilter = (param) => (dispatch) => {
  try {
    dispatch({
      type: "GET_OUTLET_FILTER",
      payload: param,
    });
    //set filter hari
    dispatch({
      type: "SELECTED_HARI",
      payload: param.filterHari,
    });

    dispatch({
      type: "status_action",
    });
  } catch (error) {}
};

export const getTotalSalesman = (country) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      `${SERVER_URL}/nabati?country=${country}`,
      config
    );
    dispatch({
      type: "GET_TOTAL_SALESMAN",
      payload: res.data,
    });

    dispatch({
      type: "status_action",
    });
  } catch (error) {}
};

export const get_salesman_by_nik = (nik) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenNabati,
        },
      };
      const res = await axios.get(`${SERVER_URL}/nabati/nik?q=${nik}`, config);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const get_salesman_by_id = (id) => async (dispach) => {
  try {
    dispach(set_loading_top());
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(`${SERVER_URL}/nabati/${id}`, config);
    dispach({
      type: "GET_CURRENT_SALESMAN",
      payload: res.data,
    });
    dispach(clear_loading_top());
  } catch (error) {}
};

/*POSTY*/
export const edit_toko = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    dispatch(set_loading("edit_toko"));
    await axios.post(SERVER_URL + "/nabati-outlet/edit_toko", body, config);

    dispatch({
      type: "status_action",
    });

    dispatch(clear_loading());
    // dispatch({
    //   type: "edit_toko",
    //   payload: body,
    // });
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const edit_password = (e) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenMapid,
        },
      };
      const body = {
        password: e.password1,
        password2: e.password2,
        user_id: e.user_id,
      };
      const res = await axios.post(
        `${SERVER_URL}/nabati/edit-password`,
        body,
        config
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const edit_hari_kunjungan_bulk = (toko_list) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    dispatch(set_loading("edit_hari_kunjungan_bulk"));
    const delay = () => new Promise((res) => setTimeout(res, 0.001));
    const func = async (e, idx) => {
      try {
        //fungsi axios & reducer dispatch di sini
        const body_new = {
          _id: e._id,
          KodeSales: e.KodeSales,
          NamaSales: e.NamaSales,
          hari_new: e.hari_new,
        };
        await axios.post(
          SERVER_URL + "/nabati-outlet/edit_hari_kunjungan_new",
          body_new,
          config
        );
        dispatch({
          type: "add_progress_edit_hari",
        });
        if (idx === toko_list.length - 1) {
          dispatch(clear_loading());
        }
      } catch (error) {}
    };
    const parent_function = () => {
      return toko_list.reduce(
        (last_prom, e, idx) =>
          last_prom.then((result_sum) =>
            child_function(e, idx).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (e, idx) => {
      if (idx === 0) {
        return func(e, idx);
      } else {
        return delay().then(() => {
          return func(e, idx);
        });
      }
    };

    dispatch({
      type: "status_action",
    });
    parent_function();
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const edit_hari_kunjungan_new = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    dispatch(set_loading("edit_hari_kunjungan_new"));
    await axios.post(
      SERVER_URL + "/nabati-outlet/edit_hari_kunjungan_new",
      body,
      config
    );
    dispatch(clear_loading());
    dispatch({
      type: "edit_hari_kunjungan_new",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const edit_hari_kunjungan = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    dispatch(set_loading("edit_hari_kunjungan"));
    await axios.post(
      SERVER_URL + "/nabati-outlet/edit_hari_kunjungan",
      body,
      config
    );
    dispatch(clear_loading());
    dispatch({
      type: "edit_hari_kunjungan",
      payload: body,
    });

    dispatch({
      type: "status_action",
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const edit_salesman = (params) => async (dispach) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    dispach(set_loading_top());
    const body = {
      email: params.email,
      phone_number: params.phone_number,
      salesman_id: params._id,
      user_id: params.user_id._id,
      NIK: params.NIK,
      Depo: params.Depo,
      Depo_id: params.Depo_id,
      CABANG: params.CABANG,
      CABANG_Id: params.CABANG_Id,
      REGIONAL: params.REGIONAL,
      REGIONAL_Id: params.REGIONAL_Id,
      kd_sales_arr: params.kd_sales_arr,
      divisi_arr: params.divisi_arr,
      "Nama Only": params["Nama Only"],
      "Nama Salesman": params["Nama Salesman"],
      username: params.user_id.name,
      merchandising_status: params.merchandising_status,
    };
    await axios.post(SERVER_URL + "/nabati/edit-profile-web", body, config);
    alert("Salesman update successfull");
    dispach(clear_loading_top());
  } catch (error) {
    alert("Salesman update failed");
    dispach(clear_loading_top());
  }
};

export const edit_status = (params) => async (dispach) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(
      SERVER_URL + "/nabati/edit-status",
      {
        salesman_id: params.salesman_id,
        status: params.status,
      },
      config
    );
  } catch (error) {
    alert("Salesman update failed");
  }
};

export const get_check_username = (username) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenNabati,
        },
      };
      const res = await axios.get(
        `${SERVER_URL}/nabati/check-username?username=${username}`,
        config
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

/*NONAPI*/

export const edit_depo_toko_competitor_bulk = (body) => async (dispatch) => {
  dispatch({
    type: "edit_depo_toko_competitor_bulk",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const edit_depo_toko_competitor = (body) => async (dispatch) => {
  dispatch({
    type: "edit_depo_toko_competitor",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const delete_depo = (body) => async (dispatch) => {
  dispatch({
    type: "delete_depo",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const edit_depo = (body) => async (dispatch) => {
  dispatch({
    type: "edit_depo",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const add_depo = (body) => async (dispatch) => {
  body = { ...body, is_local: true };
  dispatch({
    type: "get_depo_by_name",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const toggle_depo_loc_list = (body) => async (dispatch) => {
  dispatch({
    type: "toggle_depo_loc_list",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const set_depo_loc_list = (body) => async (dispatch) => {
  dispatch({
    type: "set_depo_loc_list",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const change_radius_all_depo = (body) => async (dispatch) => {
  dispatch({
    type: "change_radius_all_depo",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const change_radius_depo = (body) => async (dispatch) => {
  dispatch({
    type: "change_radius_depo",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const init_mode_radius_depo = (body) => async (dispatch) => {
  dispatch({
    type: "init_mode_radius_depo",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const set_tool_status = (body) => async (dispatch) => {
  dispatch({
    type: "set_tool_status",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const set_competitor_list_filter_0 = (body) => async (dispatch) => {
  dispatch({
    type: "set_competitor_list_filter_0",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const filter_siklus_toko = (body) => async (dispatch) => {
  dispatch({
    type: "filter_siklus_toko",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const filter_siklus_toko_all = (body) => async (dispatch) => {
  dispatch({
    type: "filter_siklus_toko_all",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const reset_omset = (body) => async (dispatch) => {
  dispatch({
    type: "reset_omset",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const reset_distribution_toko = (body) => async (dispatch) => {
  dispatch({
    type: "reset_distribution_toko",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const set_config_mode_menu = (menu, mode) => async (dispatch) => {
  dispatch({
    type: "set_config_mode_menu",
    payload: { menu, mode },
  });
  dispatch({
    type: "status_action",
  });
};

/*
body ={
  menu,
  config
}
*/
export const set_config_menu = (body) => async (dispatch) => {
  dispatch({
    type: "set_config_menu",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const set_active = (key_menu, active_menu) => async (dispatch) => {
  dispatch({
    type: "set_active",
    payload: { key_menu, active_menu },
  });
  dispatch({
    type: "status_action",
  });
};

export const set_legend_color_toko_by_salesman = (body) => async (dispatch) => {
  dispatch({
    type: "set_legend_color_toko_by_salesman",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const set_mode_hari_sebelum_sesudah = (body) => async (dispatch) => {
  dispatch({
    type: "set_mode_hari_sebelum_sesudah",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const set_toko_temporary = (body) => async (dispatch) => {
  dispatch({
    type: "set_toko_temporary",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const turn_on_menu = (body) => async (dispatch) => {
  dispatch({
    type: "turn_on_menu",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const turn_on_menu_status = (key) => async (dispatch) => {
  dispatch({
    type: "turn_on_menu_status",
    payload: { key },
  });
  dispatch({
    type: "status_action",
  });
};

export const turn_off_menu = (key) => async (dispatch) => {
  dispatch({
    type: "turn_off_menu",
    payload: { key },
  });
  dispatch({
    type: "status_action",
  });
};

export const set_pick_status = (body) => async (dispatch) => {
  dispatch({
    type: "SET_PICK_STATUS",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const set_loading = (loading_item) => {
  return {
    type: "SET_LOADING",
    payload: loading_item,
  };
};

export const clear_loading = () => {
  return {
    type: "CLEAR_LOADING",
  };
};

export const set_loading_top = () => {
  return {
    type: "SET_LOADING_TOP",
  };
};

export const clear_loading_top = () => {
  return {
    type: "CLEAR_LOADING_TOP",
  };
};

export const set_performance_status = (status) => {
  return {
    type: "set_performance_status",
    payload: status,
  };
};

export const set_map = (map) => {
  return {
    type: "SET_MAP",
    payload: map,
  };
};

export const filter_data = (body) => async (dispatch) => {
  dispatch({
    type: "FILTER_DATA",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const set_filter_mode = (body) => async (dispatch) => {
  dispatch({
    type: "SET_FILTER_MODE",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const set_filter_param = (body) => async (dispatch) => {
  dispatch({
    type: "SET_FILTER_PARAM",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const filter_outlet = (mode, value) => async (dispatch) => {
  dispatch({
    type: "FILTER_OUTLET",
    payload: {
      mode,
      value,
    },
  });
  dispatch({
    type: "status_action",
  });
};

export const reset_filter = (body) => async (dispatch) => {
  dispatch({
    type: "RESET_FILTER",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const set_outlet_list = (body) => async (dispatch) => {
  dispatch({
    type: "SET_OUTLET_LIST",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const set_adm_list = (param) => {
  return {
    type: "SET_ADM_LIST",
    payload: param,
  };
};

export const set_selected_menu = (menu) => {
  return {
    type: "set_selected_menu",
    payload: menu,
  };
};

export const set_selected_salesman = (name) => {
  return {
    type: "SET_SELECTED_SALESMAN",
    payload: name,
  };
};

export const clearOutletSalesman = () => {
  return {
    type: "GET_OUTLET_LIST_BY_SALESMAN",
    payload: [],
  };
};

export const setSalesmanMode = (value) => (dispatch) => {
  dispatch({
    type: "status_action",
  });
  dispatch({
    type: "SET_SALESMAN_MODE",
    payload: value,
  });
};

export const setOutletMode = (value) => (dispatch) => {
  dispatch({
    type: "status_action",
  });
  dispatch({
    type: "SET_OUTLET_MODE",
    payload: value,
  });
};

export const setToggleRoute = (value) => {
  return {
    type: "SET_TOGGLE_ROUTE",
    payload: value,
  };
};

export const setSalesmanFilterMode = (value) => {
  return {
    type: "SET_SALESMAN_FILTER_MODE",
    payload: value,
  };
};

export const setSalesmanFilterVal = (value) => {
  return {
    type: "SET_SALESMAN_FILTER_VALUE",
    payload: value,
  };
};

export const searchSalesman = (query) => (dispach) => {
  dispach({
    type: "SEARCH_SALESMAN_LIST",
    payload: query,
  });
};
export const searchSalesmanStep5 = (query) => (dispach) => {
  dispach({
    type: "SEARCH_SALESMAN_LIST_STEP_5",
    payload: query,
  });
};

export const searchPrinciple = (query) => (dispach) => {
  dispach({
    type: "SEARCH_PRINCIPLE_LIST",
    payload: query,
  });
};
export const searchBu = (query) => (dispach) => {
  dispach({
    type: "SEARCH_BU_LIST",
    payload: query,
  });
};
export const searchBrands = (query) => (dispach) => {
  dispach({
    type: "SEARCH_BRANDS_LIST",
    payload: query,
  });
};
export const searchProducts = (query) => (dispach) => {
  dispach({
    type: "SEARCH_PRODUCTS_LIST",
    payload: query,
  });
};

export const clearSalesman = () => (dispach) => {
  dispach({
    type: "GET_SALESMAN_LIST",
    payload: [],
  });
  dispach(clearOutletSalesman());
};

export const search_salesman_by_divisi = (params) => (dispach) => {
  dispach({
    type: "SEARCH_SALESMAN_BY_DIVISI",
    payload: params,
  });
};

export const changeBreakpoints = (size) => (dispach) => {
  dispach({
    type: "CHANGE_BREAKPOINTS",
    payload: size,
  });
};

export const selectedFilter1Obj = (props) => (dispach) => {
  dispach({
    type: "CHANGE_SELECTED_FILTER_1",
    payload: props,
  });
};

export const selectedFilter2Obj = (props) => (dispach) => {
  dispach({
    type: "CHANGE_SELECTED_FILTER_2",
    payload: props,
  });
};

export const listSelectFilter2 = (props) => (dispach) => {
  dispach({
    type: "CHANGE_LIST_SELECTED_FILTER_2",
    payload: props,
  });
};

export const selectedFilter2MultiLength = (props) => (dispach) => {
  dispach({
    type: "CHANGE_SELECTEDtext_FILTER_2_MULTI_LENGTH",
    payload: props,
  });
};

export const withOutFilter2 = (props) => (dispach) => {
  dispach({
    type: "CHANGE_WITHOUT_FILTER_2",
    payload: props,
  });
};

export const colourStatus = (value) => {
  return {
    type: "CHANGE_COLOUR",
    payload: value,
  };
};

export const clear_salesman_list = () => {
  return {
    type: "CLEAR_SALESMAN_LIST",
  };
};

export const set_current_salesman = (p) => (dispach) => {
  dispach({
    type: "SET_CURRENT_SALESMAN",
    payload: p,
  });
};

export const clear_current_salesman = () => (dispach) => {
  dispach({
    type: "CLEAR_CURRENT_SALESMAN",
  });
};

export const popUpAction = (value, text) => (dispatch) => {
  // dispatch({
  //   type: "set_value_properties",
  //   payload: {
  //     key: "pop_up_properties",
  //     value: value,
  //   },
  // });
  dispatch({
    type: "DATA_POPUP_TOKO",
    payload: value,
    text: text,
  });
};

export const searchOutletBySalesman = (query) => (dispatch) => {
  dispatch({
    type: "SEARCH_OUTLET_LIST_BY_SALESMAN",
    payload: query,
  });
};

export const changePointCompetitor = (data) => {
  return {
    type: "edit_depo",
    payload: data,
  };
};

export const hide = (body) => async (dispatch) => {
  dispatch({
    type: "hide",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const view = (body) => async (dispatch) => {
  dispatch({
    type: "view",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const hide_other = (body) => async (dispatch) => {
  dispatch({
    type: "hide_other",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const view_other = (body) => async (dispatch) => {
  dispatch({
    type: "view_other",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const hide_all = (body) => async (dispatch) => {
  dispatch({
    type: "hide_all",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const view_all = (body) => async (dispatch) => {
  dispatch({
    type: "view_all",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const reset_bulk_add_salesman = () => {
  return {
    type: "reset_bulk_add_salesman",
  };
};

export const reset_total_toko = () => {
  return {
    type: "reset_total_toko",
  };
};

//set_list
/*
body = {
  text,
  list
}
*/
export const set_list = (body) => {
  return {
    type: "set_list",
    payload: body,
  };
};

export const select_rayon = (rayon) => {
  return {
    type: "select_rayon",
    payload: rayon,
  };
};

// export const filter_toko_by_param = (body) => {
//   return {
//     type: "filter_toko_by_param",
//     payload: body,
//   };
// };

export const filter_toko_by_param = (body) => (dispatch) => {
  dispatch({
    type: "filter_toko_by_param",
    payload: body,
  });
};

export const multi_select_toko_by_params = (body) => (dispatch) => {
  dispatch({
    type: "multi_select_toko_by_params",
    payload: body,
  });
};

export const multi_reset_toko_by_params = (body) => (dispatch) => {
  dispatch({
    type: "multi_reset_toko_by_params",
    payload: body,
  });
};

export const outlet_multi_select_data = (body) => (dispatch) => {
  dispatch({
    type: "outlet_multi_select_data",
    payload: body,
  });
};

export const filter_outlet_by_search = (keyword, select) => (dispatch) => {
  dispatch({
    type: "filter_outlet_by_search",
    payload: {
      find_keyword: keyword,
      find_select: select,
    },
  });
  dispatch({
    type: "status_action",
  });
};

export const filterCollor = (params) => {
  return {
    type: "filter_collor",
    payload: params,
  };
};

export const filterCollorSalesman = (params) => {
  return {
    type: "filter_collor_salesman",
    payload: params,
  };
};

export const polygonUnplot = (params) => async (dispach) => {
  try {
    let body = { outlet_id_array: params };
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(
      `${SERVER_URL}/nabati-outlet/unplot_many_outlet`,
      body,
      config
    );
    alert("Polygon Unplot Success");
  } catch (error) {
    alert("Polygon Unplot Failed");
  }
};

export const getPolygonUnplot = (params) => async (dispach) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.get(`${SERVER_URL}/nabati-outlet/get_unplot_outlet`, config);
    alert("Salesman update success");
  } catch (error) {
    alert("Salesman update failed");
  }
};

export const getListTypeToko = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const result = await axios.get(
      `${SERVER_URL}/nabati_per/get_type_outlet_list`,
      config
    );
    dispatch({
      type: "listTypeToko",
      payload: result.data,
    });
  } catch (error) {}
};

export const searchShop = (params) => (dispatch) => {
  dispatch({
    type: "search_id_toko",
    payload: params,
  });
};

export const getDate = (params) => (dispatch) => {
  dispatch({
    type: "getDateSalesman",
    payload: params,
  });
};

export const getDataSalesman =
  ({ kdSales, kodePlan, year, month }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenNabati,
        },
      };
      let getSalesman = await axios.get(
        `${SERVER_URL}/integration_salesman/omset_salesman_per_salesman?AREA=${kodePlan}&MONTH_NUMBER=${month}&YEAR_NUMBER=${year}&SALESMAN_ID=${kdSales}`,
        config
      );
      getSalesman = getSalesman.data;

      let getMonthSalesman = await axios.get(
        `${SERVER_URL}/integration_salesman/omset_salesman_3_month?AREA=${kodePlan}&MONTH_NUMBER=${month}&YEAR_NUMBER=${year}&SALESMAN_ID=${kdSales}`,
        config
      );
      getMonthSalesman = getMonthSalesman.data;

      dispatch({
        type: "getAllProductSalesman",
        payload: getSalesman,
      });

      dispatch({
        type: "getMonthSalesman",
        payload: getMonthSalesman,
      });

      dispatch(clear_loading_top());
    } catch (error) {
      dispatch(clear_loading_top());
    }
  };

export const search_plotted = (params) => (dispatch) => {
  dispatch({
    type: "search_plotted",
    payload: params,
  });
};
export const search_competitor = (params) => (dispatch) => {
  dispatch({
    type: "search_competitor",
    payload: params,
  });
};
export const search_rayon = (params) => (dispatch) => {
  dispatch({
    type: "search_rayon",
    payload: params,
  });
  dispatch({
    type: "status_action",
  });
};

export const ambilWaktu = (params) => (dispatch) => {
  let newTime = {};
  let start = "00";
  let end = "00";
  if (params.startOnline <= 9) {
    start = `0${params.startOnline}`;
  } else {
    start = params.startOnline;
  }
  if (params.endOnline <= 9) {
    end = `0${params.endOnline}`;
  } else {
    end = params.endOnline;
  }
  newTime = {
    startOnline: start,
    endOnline: end,
  };
  dispatch({
    type: "ambilWaktu",
    payload: newTime,
  });
  dispatch({
    type: "status_action",
  });
};
