import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;

export const get_salesman_nonactive = ({ search, key }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(
        `${SERVER_URL}/nabati/nonactive?search=${search}&key=${key}`
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const get_salesman_online = (country) => async (dispach) => {
  try {
    dispach(set_loading_top());
    const res = await axios.get(
      `${SERVER_URL}/nabati/salesman_online?country=${country}`
    );
    dispach({
      type: "GET_SALESMAN_LIST",
      payload: res.data,
    });
    dispach(clear_loading_top());
  } catch (error) {
    dispach(clear_loading_top());
  }
};

export const get_salesman_active = (country) => async (dispach) => {
  try {
    dispach(set_loading_top());
    const hour = new Date().getHours();
    const res = await axios.get(
      `${SERVER_URL}/nabati/salesman_active?country=${country}&hour=${hour}`
    );
    dispach({
      type: "GET_SALESMAN_LIST",
      payload: res.data,
    });
    dispach(clear_loading_top());
  } catch (error) {
    dispach(clear_loading_top());
  }
};

export const edit_salesman_bulk = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("edit_salesman_bulk"));
    const { outlet_array } = body;
    const total = outlet_array.length;
    const limit = 500;
    const iteration = Math.ceil(total / limit);

    let sliceStart = 0;
    let sliceEnd = limit;

    const timer = (ms) => new Promise((res) => setTimeout(res, ms));
    for (let index = 0; index < iteration; index++) {
      // console.log(index);
      const body = outlet_array.slice(sliceStart, sliceEnd);
      const res = await axios.post(
        `${SERVER_URL}/nabati/edit_multiple_salesman`,
        body
      );

      dispatch({
        type: "UPLOAD_SALESMAN_SUCCESS",
        payload: res.data.arr_success,
      });
      dispatch({
        type: "UPLOAD_SALESMAN_ERROR",
        payload: res.data.arr_failed,
      });
      dispatch({
        type: "UPLOAD_SALESMAN_CURRENT",
        payload: res.data.arr_success.length + res.data.arr_failed.length,
      });

      sliceStart = sliceEnd;
      sliceEnd += limit;
      await timer(1000);
    }

    dispatch(clear_loading());
  } catch (error) {
    dispatch(clear_loading());
  }
};

// non API
export const set_loading_top = () => {
  return {
    type: "SET_LOADING_TOP",
  };
};

export const clear_loading_top = () => {
  return {
    type: "CLEAR_LOADING_TOP",
  };
};

export const set_loading = (loading_item) => {
  return {
    type: "SET_LOADING",
    payload: loading_item,
  };
};

export const clear_loading = () => {
  return {
    type: "CLEAR_LOADING",
  };
};

export const upload_salesman_clear = () => {
  return {
    type: "UPLOAD_SALESMAN_CLEAR",
  };
};
