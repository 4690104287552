import { bbox } from "@turf/turf";
import generate_v_max from "./generate_v_max";

const generate_preview_rayon_list = (body) => {
  let {
    //NUMBER
    jumlah_toko, //jumlah_toko = jumlah toko yang diingikan untuk setiap satu rayon

    //STRING
    mode_sisa_toko_rayon, //mode sisa toko jika total toko dibagi jumlah rayon tidak habis dibag

    //ARRAY
    toko_list_main, // array toko list yang kan dirayonisasi
    depo_loc_list, //list depo yang terlibat dalam rayonisasi
  } = body;

  jumlah_toko = Number(jumlah_toko);

  let rayon_list_complete = [];

  depo_loc_list.forEach((d) => {
    //jumlah_toko_depo : jumlah toko yang ada pada depo yang akan dilooping

    // const jumlah_toko_depo = toko_list_main.filter(
    //   (t) => t.Depo === d.value
    // )?.length;

    const jumlah_toko_depo = toko_list_main.filter((t) => t)?.length;

    //untuk dua metode jumlah rayon tetap sama untuk simplifikasi
    let jumlah_rayon;
    jumlah_rayon = Math.ceil(jumlah_toko_depo / jumlah_toko);

    if (jumlah_rayon === 0) {
      jumlah_rayon = 1;
    }
    for (let index = 0; index < jumlah_rayon; index++) {
      const element = `${d.value}_Rayon ${index + 1}`;
      const object = {
        name: element,
        depo_id: d._id,
        depo_string: d.value,
        date_created: Date.now(),
        date_updated: Date.now(),
      };

      rayon_list_complete.push(object);
    }
  });

  //looping algoritma untuk tiap depo (karena bisa multi depo)
  depo_loc_list = depo_loc_list.map((d) => {
    //hanya mengambil toko pada depo yang sedang diloop

    // let toko_list = toko_list_main.filter((t) => t.Depo === d.value) || [];

    let toko_list = toko_list_main.filter((t) => t) || [];

    const features = toko_list.map((t) => {
      return { type: "Feature", properties: {}, geometry: t.location };
    });
    const geojson = {
      type: "FeatureCollection",
      features: [...features],
    };

    //mengambil jumlah toko pada depo yang sedang diloop

    // const jumlah_toko_depo = toko_list.filter(
    //   (t) => t.Depo === d.value
    // )?.length;

    const jumlah_toko_depo = toko_list.filter((t) => t)?.length;

    //untuk dua metode jumlah rayon tetap sama untuk simplifikasi
    let jumlah_rayon;
    jumlah_rayon = Math.ceil(jumlah_toko_depo / jumlah_toko);
    if (jumlah_rayon === 0) {
      jumlah_rayon = 1;
    }

    const toko_perloop = Math.ceil(toko_list.length / jumlah_rayon);
    let jumlah_toko_final;
    if (mode_sisa_toko_rayon === "rata") {
      //jika menggunakan metode rata maka jumlah toko menjadi lebih rata dari 300 (misal      304)
      jumlah_toko_final = toko_perloop;
    } else if (mode_sisa_toko_rayon === "akhir") {
      //jika menggunakan metode akhir maka jumlah toko dipaksa 300 (misal)
      jumlah_toko_final = jumlah_toko;
    }

    /*STEP 1 Deklarasi variabel penting*/

    const [minLng, minLat, maxLng, maxLat] = bbox(geojson);
    const delta_long = maxLng - minLng;
    const delta_lat = maxLat - minLat;
    const ratio = delta_lat / delta_long;

    let v_max; //v_max : jumlah baris vertikal untuk pembagian rayon

    const v_auto = generate_v_max(jumlah_rayon, ratio);
    const v_manual = d?.v_manual ? d?.v_manual : 1;

    const mode = d.mode; //mode pembagian v_max manual || auto

    if (mode === "manual") {
      v_max = v_manual;
    } else {
      //mode === auto || undefined
      v_max = v_auto;
    }

    v_max = parseInt(v_max);

    //pengali: pengali untuk setiap v_index
    const pengali = Math.floor(jumlah_rayon / v_max);

    //mod : sisa pembagian dari 3 untuk menentukan v_index mana yang akan dilebihkan 1 pengali nya
    const mod = jumlah_rayon % v_max;

    //vertical_range : array looping pertama, untuk kasus ini akan dilooping vertikal dulu
    let vertical_range = [];

    /*STEP 2 Looping untuk generate pengali_n (bagian dari total toko) untuk masing baris vertikal*/

    for (let n = 0; n < v_max; n++) {
      //pengali_n : bagian untuk masing-masing baris vertikal, misal jika jumlah_rayon = 3 , maka [1/3, 1/3, 1/3]
      const pengali_n =
        mod >= n + 1
          ? (1 / jumlah_rayon) * (pengali + 1)
          : (1 / jumlah_rayon) * pengali;

      //jumlah_toko_v : jumlah toko untuk masing-masing baris vertikal, misal jika jumlah_rayon = 3, jumlah total toko = 300 , maka [100, 100, 100]
      let jumlah_toko_v = Math.floor(pengali_n * jumlah_toko_depo);

      if (jumlah_toko_v > jumlah_toko_final) {
        jumlah_toko_v = jumlah_toko_final;
      }

      //jumlah_rayon_horisontal : berapa rayon yang dibagi secara horisontal pada suatu baris vertikal
      const jumlah_rayon_horisontal = mod >= n + 1 ? pengali + 1 : pengali;
      vertical_range.push({
        pengali_n,
        jumlah_rayon_horisontal,
        jumlah_toko_v,
      });
    }

    /*STEP 3 Modifikasi vertical_range untuk mendapatkan range start & end dari baris vertikal, membutuhkan map dari vertical_range original karena membutuhkan nilai dari elemen sebelumnya*/
    vertical_range = vertical_range.map(
      ({ jumlah_toko_v, jumlah_rayon_horisontal }, idx) => {
        //start: index awal dari range pembagian vertikal, end: index akhir dari range pembagian vertikal
        let start, end;
        if (idx === 0) {
          start = 0;
        } else {
          //sum dari semua toko sebelum index yang sedang looping
          start = vertical_range.slice(0, idx).map((r) => r.jumlah_toko_v);
          start = start.reduce((a, b) => a + b, 0);
        }
        if (idx === v_max - 1) {
          end = jumlah_toko_depo;
        } else {
          end = start + jumlah_toko_v;
        }

        //horisontal_range: array range untuk pembagian horisonal, isinya start & end dari rayon yang akan dibuat
        let horisontal_range = [];
        for (let h = 0; h < jumlah_rayon_horisontal; h++) {
          let start_h, end_h;
          start_h = h * jumlah_toko_final;
          if (idx === v_max - 1 && h === jumlah_rayon_horisontal - 1) {
            end_h = jumlah_toko_depo;
          } else {
            end_h = start_h + jumlah_toko_final;
          }
          horisontal_range.push({ start_h, end_h });
        }
        return {
          // beberapa atribut yang sudah tidak dibutuhkan lagi:
          // pengali_n,
          // jumlah_toko_v,
          jumlah_rayon_horisontal,

          //atribut yang pada akhirnya digunakan:
          start,
          end,
          horisontal_range,
        };
      }
    );

    d.vertical_range = vertical_range;
    d.v_max = v_max;
    d.v_auto = v_auto;
    d.ratio = ratio;
    d.jumlah_rayon = jumlah_rayon;

    return d;
  });

  return depo_loc_list;
};

export default generate_preview_rayon_list;
