const initialState = {
  map: null,
  outletView: true,
  trackingView: true,
  drawMode: "simple_select",
  polygonList: [],
  pointByPolygon: [],
  selectedDrawFeature: {},
  draw_point_depo_status: false,
  edit_point_depo_status: false,
  statusDraw: "",
  depo: {},
  polygon_depo: {
    type: "FeatureCollection",
    features: [],
  },
  rayon_polygon: {
    type: "FeatureCollection",
    features: [],
  },
  depo_selected: {},
  status_action: 0,
  plan_current: 0,
  salesman_id_process: "",
  salesman_index_process: -1,
  grouping_list: [],
  rayon_list_complete: [],
  loading_overwrite_hari: false, //butuh state loading khusus karena ada looping yang loadingnya sulit dikontrol
  modal_pop_up: false,
  type_pop_up: "",
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case "set_value":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "status_action":
      return {
        ...state,
        status_action: state.status_action + 1,
      };
    case "set_depo_selected":
      return {
        ...state,
        depo_selected: action.payload,
      };
    case "set_polygon_depo":
      return {
        ...state,
        polygon_depo: action.payload,
      };
    case "set_unplot_polygon":
      return {
        ...state,
        polygon_unplot: action.payload,
      };
    case "rayon_polygon":
      return {
        ...state,
        rayon_polygon: action.payload,
      };
    case "activate_edit_point_depo":
      return {
        ...state,
        edit_point_depo_status: true,
        depo: action.payload,
      };
    case "inactivate_edit_point_depo":
      return {
        ...state,
        edit_point_depo_status: false,
        depo: {},
      };
    case "activate_draw_point_depo":
      return {
        ...state,
        draw_point_depo_status: true,
      };
    case "inactivate_draw_point_depo":
      return {
        ...state,
        draw_point_depo_status: false,
      };
    case "SET_MAP":
      return {
        ...state,
        map: action.payload,
      };
    case "SET_OUTLET_VIEW":
      return {
        ...state,
        outletView: action.payload,
      };
    case "SET_TRACKING_VIEW":
      return {
        ...state,
        trackingView: action.payload,
      };
    case "SET_DRAW_MODE":
      return {
        ...state,
        drawMode: action.payload,
      };
    case "SET_DRAW_MODE_COMPETITOR":
      return {
        ...state,
        drawMode: action.payload,
      };
    case "STATUS_DRAW":
      return {
        ...state,
        statusDraw: action.payload,
      };
    case "ADD_POLYGON":
      return {
        ...state,
        polygonList: [action.payload, ...state.polygonList],
        drawMode: "simple_select",
      };
    case "ADD_POINT_RESULT":
      return {
        ...state,
        pointByPolygon: [...state.pointByPolygon, action.payload],
      };
    case "CHANGE_POLYGON":
      return {
        ...state,
        polygonList: action.payload,
      };
    case "SELECTED_DRAW_FEATURE":
      return {
        ...state,
        selectedDrawFeature: action.payload,
      };

    case "REPLACE_POINT":
      return {
        ...state,
        pointByPolygon: replace_point(action.payload, state.pointByPolygon),
      };

    case "DELETE_DATA_POINT":
      return {
        ...state,
        polygonList: replace_id(action.payload, state.polygonList),
      };

    case "CHANGE_HARI":
      return {
        ...state,
        polygonList: replace_hari(action.payload, state.polygonList),
      };

    default:
      return state;
  }
}

const replace_point = ({ idx, feature }, pointByPolygon) => {
  let newPointByPolygon = [...pointByPolygon];
  newPointByPolygon[idx] = feature;
  return newPointByPolygon;
};

const replace_id = (id, polygonList) => {
  let cloneId = [...polygonList];
  const newId = cloneId.filter((e) => e.properties.hari !== id);
  return newId;
};

const replace_hari = ({ old, newHari }, polygonList) => {
  let cloneId = [...polygonList];
  const oldId = cloneId.findIndex((e) => e.properties.hari === old);
  const newId = cloneId.findIndex((e) => e.properties.hari === newHari);
  let tempo = cloneId[oldId];
  cloneId[oldId] = {
    ...polygonList[newId],
    properties: { ...polygonList[newId].properties, hari: newHari },
  };
  cloneId[newId] = {
    ...tempo,
    properties: { ...tempo.properties, hari: tempo.properties.hari },
  };
  return cloneId;
};
