//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import writeXlsxFile from "write-excel-file";
//Personal Component
//Redux function
//Picture Asset
//General Function
// const template_csv = [
//   ["DEPO", "CABANG", "REGIONAL", "LOCATION", "COUNTRY"],
//   [
//     "CONTOH NAMA DEPO",
//     "CONTOH NAMA CABANG",
//     "CONTOH NAMA REGIONAL",
//     "-6.9965714309083245, 107.5297339384666",
//     "INDONESIA",
//   ],
//   [
//     "CONTOH NAMA DEPO",
//     "CONTOH NAMA CABANG",
//     "CONTOH NAMA REGIONAL",
//     "-6.9965714309083245, 107.5297339384666",
//     "VIETNAM",
//   ],
//   [
//     "CONTOH NAMA DEPO",
//     "CONTOH NAMA CABANG",
//     "CONTOH NAMA REGIONAL",
//     "-6.9965714309083245, 107.5297339384666",
//     "PHILIPPINE",
//   ],
//   [
//     "CONTOH NAMA DEPO",
//     "CONTOH NAMA CABANG",
//     "CONTOH NAMA REGIONAL",
//     "-6.9965714309083245, 107.5297339384666",
//     "MYANMAR",
//   ],
// ];
class DownloadUnsavedDepo extends Component {
  state = {
    loading_csv: false,
    separator: ";",
    dataCSV: [],
    downloadMerch: false,
    negara: "",
  };
  on_csv = () => {
    const { depo_loc_list } = this.props.data;
    const depo_list = depo_loc_list.filter((d) => d.is_local);

    console.log(depo_list);

    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });
      try {
        let tempData = [];
        const header = ["DEPO", "CABANG", "REGIONAL", "LOCATION", "COUNTRY"];
        tempData.push(header);
        for (let index = 0; index < depo_list.length; index++) {
          const element = depo_list[index];
          let location;
          if (element?.location?.coordinates?.[0] !== 0) {
            const long = element.location.coordinates[0];
            const lat = element.location.coordinates[1];
            location = `${lat},${long}`;
          }
          console.log(location);
          tempData.push([
            element["Depo"] ?? "-",
            this.state.negara ?? "-",
            this.state.negara ?? "-",
            location ?? "-",
            this.state.negara ?? "-",
          ]);
        }
        this.setState({
          loading_csv: false,
          dataCSV: tempData,
        });
      } catch (err) {
        this.setState({
          loading_csv: false,
        });
        console.log(err);
      }
    }
  };
  on_excel = async () => {
    const { depo_loc_list } = this.props.data;
    const depo_list = depo_loc_list.filter((d) => d.is_local);
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });
      try {
        const schema = [
          {
            column: "DEPO",
            type: String,
            value: (target) => target.Depo,
          },
          {
            column: "BRANCH",
            type: String,
            value: (target) => target.Branch,
          },
          {
            column: "REGION",
            type: String,
            value: (target) => target.Region,
          },
          {
            column: "LOCATION",
            type: String,
            value: (target) => target.coordinates,
          },
          {
            column: "COUNTRY",
            type: String,
            value: (target) => target.country,
          },
        ];
        let newData = [];
        depo_list.forEach((e) => {
          let location;
          if (e?.location?.coordinates?.[0] !== 0) {
            const long = e.location.coordinates[0];
            const lat = e.location.coordinates[1];
            location = `${lat},${long}`;
          }

          let getData = {
            Depo: e.Depo,
            Branch: this.state.negara,
            Region: this.state.negara,
            coordinates: location,
            country: this.state.negara,
          };
          newData.push(getData);
        });
        await writeXlsxFile(newData, {
          schema,
          fileName: "DataExceUnSavedDepo.xlsx",
        });
        this.setState({
          loading_csv: false,
        });
      } catch (err) {
        this.setState({
          loading_csv: false,
        });
        console.log(err);
      }
    }
  };
  on_change = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value });
  };
  render() {
    const { loading_csv, separator, dataCSV } = this.state;
    const { country_select } = this.props.auth;
    return (
      <div>
        <label className="text_bold">Choose Country</label>
        <select
          className="w-full"
          name="negara"
          onChange={this.on_change}
          required
          value={this.state.negara}
        >
          <option value="">Choose Country</option>
          {country_select.map((e, i) => (
            <option title={e.label} key={i} value={e.value}>
              {e.label}
            </option>
          ))}
        </select>
        <CSVLink
          className="button_main"
          data={dataCSV}
          separator={separator}
          filename={`DataUnSavedDepo.csv`}
          onClick={this.on_csv}
        >
          {loading_csv ? "Loading ..." : "CSV"}
        </CSVLink>
        <button className="button_main" onClick={this.on_excel}>
          {loading_csv ? "Loading ..." : "EXCEL"}
        </button>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
  auth: state.auth,
});
export default connect(mapStateToProps, {})(DownloadUnsavedDepo);
