import React from "react";
import icon_password_hide from "../../img/icon_password_hide.png";
import icon_password_view from "../../img/icon_password_view.png";
import isEmpty from "../../validation/is-empty";
const styleObjectNormal = {
  overflow: "hidden",
  boxSizing: "border-box",

  borderRadius: "4px",
  border: "1px solid #aeb0b1ff",

  padding: "10px 10px 10px 10px",
  fontSize: "16px",
  color: "#000000",
  textAlign: "left",
  width: "100%",
};
const styleObjectError = {
  overflow: "hidden",
  boxSizing: "border-box",

  borderRadius: "4px",
  borderBottom: "1px solid red",

  padding: "10px 10px 10px 10px",
  fontSize: "16px",
  color: "#000000",
  textAlign: "left",
  width: "100%",
};
const styleObjectPassword = {
  overflow: "hidden",
  boxSizing: "border-box",

  borderRadius: "4px",

  padding: "10px 10px 10px 10px",
  fontSize: "16px",
  color: "#000000",
  textAlign: "left",
  width: "100%",
};
const stylePasswordNormal = {};
const stylePasswordError = {
  borderBottom: "1px solid red",
};
const TextFieldGroup = ({
  name,
  placeholder,
  value,
  error,
  info,
  type,
  onChange,
  disabled,
  list,
  id,
  toggleShowPassword,
  isPassword,
  list_array,
  list_selection_object,
  name_list,
  value_list,
}) => {
  let content;
  if (type === "text_list") {
    content = (
      <main style={{ marginBottom: "20px" }}>
        <section
          style={{
            textAlign: "left",
            color: error ? "red" : "#000000",
          }}
          className="text_bold"
        >
          {error ? error : placeholder}
        </section>
        <input
          type="text"
          placeholder={placeholder}
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
          style={error ? styleObjectError : styleObjectNormal}
          list={list}
        />
        <datalist id={list}>
          {list_selection_object.map((e, idx) => {
            const name = e[name_list ? name_list : "name"];
            const value = e[value_list ? value_list : "value"];
            return (
              <option key={idx} value={value}>
                {name}
              </option>
            );
          })}
        </datalist>
        {info && <small>{info}</small>}
      </main>
    );
  } else if (type === "selection") {
    content = (
      <main style={{ marginBottom: "20px" }}>
        <section
          style={{
            textAlign: "left",
            color: error ? "red" : "#000000",
          }}
          className="text_bold"
        >
          {error ? error : placeholder}
        </section>
        <select
          type="text"
          placeholder={placeholder}
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
          style={error ? styleObjectError : styleObjectNormal}
          autoComplete="off"
        >
          {list_selection_object.map((e, idx) => {
            const name = e[name_list ? name_list : "name"];
            const value = e[value_list ? value_list : "value"];
            return (
              <option key={idx} value={value}>
                {name}
              </option>
            );
          })}
        </select>
        {info && <small>{info}</small>}
      </main>
    );
  } else {
    if (isPassword) {
      content = (
        <main style={{ marginBottom: "20px" }}>
          <section
            style={{ textAlign: "left", color: error ? "red" : "#000000" }}
            className="text_bold"
          >
            {error ? error : placeholder}
          </section>
          <section
            className="input_icon"
            style={error ? stylePasswordError : stylePasswordNormal}
          >
            <input
              type={type}
              placeholder={placeholder}
              name={name}
              id={id}
              value={value}
              onChange={onChange}
              disabled={disabled}
              style={styleObjectPassword}
              list={list ? list : ""}
              autoComplete="off"
            />
            <img
              alt="show password"
              src={
                type === "password" ? icon_password_view : icon_password_hide
              }
              width="25px"
              onClick={toggleShowPassword}
            />
          </section>
          {info && <small>{info}</small>}
        </main>
      );
    } else {
      if (isEmpty(list_array)) {
        content = (
          <main style={{ marginBottom: "20px" }}>
            <section
              style={{
                textAlign: "left",
                color: error ? "red" : "#000000",
              }}
              className="text_bold"
            >
              {error ? error : placeholder}
            </section>
            <input
              type={type}
              placeholder={placeholder}
              name={name}
              id={id}
              value={value}
              onChange={onChange}
              disabled={disabled}
              style={error ? styleObjectError : styleObjectNormal}
              list={list ? list : ""}
              autoComplete="off"
            />
            {info && <small>{info}</small>}
          </main>
        );
      } else {
        content = (
          <main style={{ marginBottom: "20px" }}>
            <section
              style={{
                textAlign: "left",
                color: error ? "red" : "#000000",
              }}
              className="text_bold"
            >
              {error ? error : placeholder}
            </section>
            <input
              type={type}
              placeholder={placeholder}
              name={name}
              id={id}
              value={value}
              onChange={onChange}
              disabled={disabled}
              style={error ? styleObjectError : styleObjectNormal}
              list={list}
              autoComplete="off"
            />
            <datalist id={list}>
              {list_array.map((element, idx) => {
                return <option value={element} key={idx} />;
              })}
            </datalist>
            {info && <small>{info}</small>}
          </main>
        );
      }
    }
  }
  return content;
};
export default TextFieldGroup;
