import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import writeXlsxFile from "write-excel-file";
import Select from "react-select";
import {
  delete_depo,
  manual_add_cabang,
  manual_add_depo,
  manual_add_regional,
  manual_edit_depo,
} from "../../actions/authActions";
import {
  getSalesmanFilter,
  set_depo_loc_list,
  turn_on_menu_status,
  turn_off_menu,
  get_item_list,
} from "../../actions/dataActions";
import isEqual from "../../validation/is-equal";
import Modal from "../common/Modal";
import DepoBulk from "./DepoBulk";
import icon_view from "../../img/icon_view_grey.svg";
import icon_view_active from "../../img/icon_view_green.svg";

class HirarkiDepo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list_depo: [],
      list_cabang: [],
      list_regional: [],
      depo: "",
      cabang: "",
      regional: "",
      modalTambahDepo: false,
      modalTambahCabang: false,
      modalTambahRegional: false,
      modalEditTambahBulk: false,
      tambah_regional: "",
      tambah_cabang: "",
      tambah_depo: "",
      tambah_plan_id: "",
      depoInCabang: "",
      depoInRegional: "",
      depoLatitude: 0,
      depoLongitude: 0,
      dataCSV: [],
      current_depo_edit_manual: {},
      modal_depo_edit_manual: false,
      list_original_cabang: [],
      list_original_regional: [],
      downloadAdminDepo: false,
      selectArea: "",
      selectedDepo: [],
      selectedBranch: [],
      selectedRegion: [],
    };
  }
  componentDidMount() {
    this.setState({
      backup_list_depo: this.props.filterStruktur.depo,
      list_depo: this.props.filterStruktur.depo,
      list_cabang: this.props.filterStruktur.cabang,
      list_regional: this.props.filterStruktur.regional,
      list_original_cabang: this.props.original_cabang,
      list_original_regional: this.props.original_regional,
    });
    this.props.set_depo_loc_list(this.props.filterStruktur.depo);
    this.props.turn_on_menu_status("depo_loc_list");
    this.props.get_item_list("depo_folder");
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.original_regional.length !== this.props.original_regional.length
    ) {
      this.setState({ list_original_regional: this.props.original_regional });
    }
    if (
      prevProps.original_cabang.length !== this.props.original_cabang.length
    ) {
      this.setState({ list_original_cabang: this.props.original_cabang });
    }
    if (!isEqual(prevProps.filterStruktur, this.props.filterStruktur)) {
      this.setState({
        backup_list_depo: this.props.filterStruktur.depo,
        list_depo: this.props.filterStruktur.depo,
        list_cabang: this.props.filterStruktur.cabang,
        list_regional: this.props.filterStruktur.regional,
        list_original_cabang: this.props.original_cabang,
        list_original_regional: this.props.original_regional,
      });
      this.props.set_depo_loc_list(this.props.filterStruktur.depo);
      this.props.turn_on_menu_status("depo_loc_list");
    }
  }
  // componentWillUnmount() {
  //   this.props.set_depo_loc_list([]);
  // }

  handleSearch = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    let result_arr = [];
    if (this.state.selectArea === "Depo") {
      this.state.selectedDepo.forEach((e) => {
        let result = this.state.backup_list_depo.filter((state) => {
          const depo = new RegExp(`${e}`, "gi");
          return state.Depo.match(depo);
        });
        result_arr = result_arr.concat(result);
      });
      if (this.state.selectedDepo.length === 0) {
        result_arr = this.state.backup_list_depo;
      }
    }
    if (this.state.selectArea === "Branch") {
      this.state.selectedBranch.forEach((e) => {
        let result = this.state.backup_list_depo.filter((state) => {
          const cabang = new RegExp(`${e}`, "gi");
          return state.CABANG.match(cabang);
        });
        result_arr = result_arr.concat(result);
      });
      if (this.state.selectedBranch.length === 0) {
        result_arr = this.state.backup_list_depo;
      }
    }
    if (this.state.selectArea === "Region") {
      this.state.selectedRegion.forEach((e) => {
        let result = this.state.backup_list_depo.filter((state) => {
          const regional = new RegExp(`${e}`, "gi");
          return state.REGION.match(regional);
        });
        result_arr = result_arr.concat(result);
      });
      if (this.state.selectedRegion.length === 0) {
        result_arr = this.state.backup_list_depo;
      }
    }

    this.setState({ list_depo: result_arr });
    this.props.set_depo_loc_list(result_arr);
  };
  handleRefresh = () => {
    this.setState({
      backup_list_depo: this.props.filterStruktur.depo,
      list_depo: this.props.filterStruktur.depo,
      list_cabang: this.props.filterStruktur.cabang,
      list_regional: this.props.filterStruktur.regional,
      list_original_cabang: this.props.original_cabang,
      list_original_regional: this.props.original_regional,
    });
    this.props.set_depo_loc_list(this.props.filterStruktur.depo);
  };
  handleOnChange = (event) => {
    this.setState({
      [event.target.name]:
        event.target.type === "number"
          ? parseFloat(event.target.value)
          : event.target.value,
    });
  };
  handleOnChangeCurrentDepo = (event) => {
    this.setState((prev) => ({
      current_depo_edit_manual: {
        ...prev.current_depo_edit_manual,
        [event.target.name]:
          event.target.type === "number"
            ? parseFloat(event.target.value)
            : event.target.value,
      },
    }));
  };
  submitTambahregional = (event) => {
    event.preventDefault();
    this.props.manual_add_regional({ REGIONAL: this.state.tambah_regional });
    this.setState({
      modalTambahRegional: false,
    });
  };
  submitTambahCabang = (event) => {
    event.preventDefault();
    this.props.manual_add_cabang({ CABANG: this.state.tambah_cabang });
    this.setState({
      modalTambahCabang: false,
    });
  };
  submitTambahDepo = (event) => {
    event.preventDefault();
    const cabangId = this.state.list_original_cabang.find(
      (x) => x.CABANG === this.state.depoInCabang
    )._id;
    const regionId = this.state.list_original_regional.find(
      (x) => x.REGIONAL === this.state.depoInRegional
    )._id;
    this.props.manual_add_depo({
      Depo: this.state.tambah_depo,
      // Depo: this.state.tambah_depo.toUpperCase(),
      location: {
        type: "Point",
        coordinates: [this.state.depoLongitude, this.state.depoLatitude],
      },
      CABANG: this.state.depoInCabang,
      REGION: this.state.depoInRegional,
      CABANG_id: cabangId,
      REGION_id: regionId,
      country: this.state.negara,
      plan_id: this.state.tambah_plan_id,
    });
    this.setState({
      modalTambahDepo: false,
    });
  };
  generateDownloadDepoCSV = (data, done) => {
    const header = [
      "DEPO",
      "PLAN ",
      "FOLDER DEPO",
      "CABANG",
      "REGION",
      "LOCATION",
      "COUNTRY",
    ];
    let body = [];
    body.push(header);
    data.forEach((element) => {
      let matchFolder = this.props.data.depo_folder_list.filter(
        (x) => x._id === element.folder_id
      );
      matchFolder = matchFolder.map((e) => {
        return e.text;
      });
      const { user, master_admin } = this.props.auth;
      let location = "";
      if (element?.location?.coordinates?.[0] !== 0) {
        const long = element.location.coordinates[0];
        const lat = element.location.coordinates[1];
        location = `${lat},${long}`;
      }
      if (
        master_admin.filter((element) => element._id === user._id).length <= 0
      ) {
        if (element?.location?.coordinates?.[0] !== 0) {
          location = "YES";
        } else {
          location = "NO";
        }
      }
      body.push([
        element.Depo,
        element.plan_id,
        matchFolder[0],
        element.CABANG,
        element.REGION,
        location,
        element.country,
      ]);
    });
    this.setState({ dataCSV: body });
  };
  generateDownloadDepoEXCEL = async (data, done) => {
    const schema = [
      {
        column: "Depo",
        type: String,
        value: (admin) => admin.Depo,
      },
      {
        column: "Plan",
        type: String,
        value: (admin) => admin.plan_id,
      },
      {
        column: "Folder Depo",
        type: String,
        value: (admin) => admin.folder_id,
      },
      {
        column: "Branch",
        type: String,
        value: (admin) => admin.CABANG,
      },
      {
        column: "Region",
        type: String,
        value: (admin) => admin.REGION,
      },
      {
        column: "Location",
        type: String,
        value: (admin) => admin.location,
      },
      {
        column: "Country",
        type: String,
        value: (admin) => admin.country,
      },
    ];
    let newData = [];
    data.forEach((e) => {
      let matchFolder = this.props.data.depo_folder_list.filter(
        (x) => x._id === e.folder_id
      );
      matchFolder = matchFolder.map((e) => {
        return e.text;
      });
      const { user, master_admin } = this.props.auth;
      let location = "";
      if (e?.location?.coordinates?.[0] !== 0) {
        const long = e.location.coordinates[0];
        const lat = e.location.coordinates[1];
        location = `${lat},${long}`;
      }
      if (master_admin.filter((e) => e._id === user._id).length <= 0) {
        if (e?.location?.coordinates?.[0] !== 0) {
          location = "YES";
        } else {
          location = "NO";
        }
      }
      let getData = {
        plan_id: e.plan_id,
        folder_id: matchFolder[0],
        Depo: e.Depo,
        CABANG: e.CABANG,
        REGION: e.REGION,
        location: location,
        country: e.country,
      };
      newData.push(getData);
    });
    await writeXlsxFile(newData, {
      schema,
      fileName: "DataExcelBranchDepo.xlsx",
    });
  };

  handleEditDepoManual = (el) => {
    this.setState({
      current_depo_edit_manual: el,
      modal_depo_edit_manual: true,
    });
  };
  submitDepoEditManual = (event) => {
    event.preventDefault();
    const current = this.state.current_depo_edit_manual;
    const cabangId = this.state.list_original_cabang.find(
      (x) => x.CABANG === current.CABANG
    )._id;
    const regionId = this.state.list_original_regional.find(
      (x) => x.REGIONAL === current.REGION
    )._id;
    const lat = current.depoLatitude
      ? current.depoLatitude
      : current.location.coordinates[1];
    const lng = current.depoLongitude
      ? current.depoLongitude
      : current.location.coordinates[0];
    this.props.manual_edit_depo({
      _id: current._id,
      Depo: current.Depo,
      // Depo: current.Depo.toUpperCase(),
      location: {
        type: "Point",
        coordinates: [lng, lat],
      },
      CABANG: current.CABANG,
      REGION: current.REGION,
      CABANG_id: cabangId,
      REGION_id: regionId,
      country: current.negara,
      plan_id: current.plan_id,
      folder_id: current.folder_id,
      gaji: current.gaji,
      bensin: current.bensin,
      sewaMotor: current.sewaMotor,
      uangMakan: current.uangMakan,
      insentif: current.insentif,
      totalBenefit: current.totalBenefit,
    });
    this.stateUpdateListDepo(current._id, {
      Depo: current.Depo,
      // Depo: current.Depo.toUpperCase(),
      location: {
        type: "Point",
        coordinates: [lng, lat],
      },
      CABANG: current.CABANG,
      REGION: current.REGION,
      CABANG_id: cabangId,
      REGION_id: regionId,
      country: current.negara,
      plan_id: current.plan_id,
      folder_id: current.folder_id,
      gaji: current.gaji,
      bensin: current.bensin,
      sewaMotor: current.sewaMotor,
      uangMakan: current.uangMakan,
      insentif: current.insentif,
      totalBenefit: current.totalBenefit,
    });
    this.stateUpdateBackUpListDepo(current._id, {
      Depo: current.Depo,
      // Depo: current.Depo.toUpperCase(),
      location: {
        type: "Point",
        coordinates: [lng, lat],
      },
      CABANG: current.CABANG,
      REGION: current.REGION,
      CABANG_id: cabangId,
      REGION_id: regionId,
      country: current.negara,
      plan_id: current.plan_id,
      folder_id: current.folder_id,
      gaji: current.gaji,
      bensin: current.bensin,
      sewaMotor: current.sewaMotor,
      uangMakan: current.uangMakan,
      insentif: current.insentif,
      totalBenefit: current.totalBenefit,
    });
    this.setState({
      modal_depo_edit_manual: false,
    });
  };
  deleteDepo = () => {
    const id = this.state.current_depo_edit_manual._id;
    this.props.delete_depo(this.state.current_depo_edit_manual._id);
    this.setState({
      modal_depo_edit_manual: false,
      list_depo: this.state.list_depo.filter((e) => {
        return e._id !== id;
      }),
      backup_list_depo: this.state.backup_list_depo.filter((e) => {
        return e._id !== id;
      }),
    });
  };
  stateUpdateListDepo = (id, itemAttributes) => {
    var index = this.state.list_depo.findIndex((x) => x._id === id);
    if (index !== -1)
      this.setState({
        list_depo: [
          ...this.state.list_depo.slice(0, index),
          Object.assign({}, this.state.list_depo[index], itemAttributes),
          ...this.state.list_depo.slice(index + 1),
        ],
      });
  };
  stateUpdateBackUpListDepo = (id, itemAttributes) => {
    var index = this.state.backup_list_depo.findIndex((x) => x._id === id);
    if (index !== -1)
      this.setState({
        backup_list_depo: [
          ...this.state.backup_list_depo.slice(0, index),
          Object.assign({}, this.state.backup_list_depo[index], itemAttributes),
          ...this.state.backup_list_depo.slice(index + 1),
        ],
      });
  };
  handleForceRefresh = () => {
    this.props.getSalesmanFilter();
  };
  on_toggle_view_log = (item) => {
    const { list_menu_active } = this.props.data;
    const is_visible = list_menu_active.find((e) => e.key === item).active;
    if (is_visible) {
      this.props.turn_off_menu(item);
    } else {
      this.props.turn_on_menu_status(item);
    }
  };
  handle_change = (event) => {
    this.setState({ selectArea: event.value });
  };
  handle_change_depo = (event) => {
    let extract = event.map((e) => e.value);
    this.setState({ selectedDepo: extract });
  };
  handle_change_branch = (event) => {
    let extract = event.map((e) => e.value);
    this.setState({ selectedBranch: extract });
  };
  handle_change_region = (event) => {
    let extract = event.map((e) => e.value);
    this.setState({ selectedRegion: extract });
  };
  render() {
    // const { filterStruktur } = this.props;
    const {
      list_depo,
      // list_cabang,
      // list_regional,
      modalTambahCabang,
      modalTambahDepo,
      modalTambahRegional,
      modalEditTambahBulk,
      modal_depo_edit_manual,
      list_original_cabang,
      list_original_regional,
      selectArea,
    } = this.state;
    const { dataCSV } = this.state;
    const { current_depo_edit_manual } = this.state;
    const { country_select } = this.props.auth;
    const is_visible_depo = this.props.data.list_menu_active.find(
      (e) => e.key === "depo_loc_list"
    ).active;

    const area_list = [
      { key: "depo", label: "Depo", value: "Depo", db: "Depo", name: "area" },
      {
        key: "branch",
        label: "Branch",
        value: "Branch",
        db: "CABANG",
        name: "area",
      },
      {
        key: "regional",
        label: "Region",
        value: "Region",
        db: "REGIONAL",
        name: "area",
      },
    ];

    let newDepo = [];
    let newBranch = [];
    let newRegion = [];

    this.props.filterStruktur.depo.map((e) => {
      let newData = { label: e.Depo, value: e.Depo };
      return newDepo.push(newData);
    });
    this.props.filterStruktur.cabang.map((e) => {
      let newData = { label: e.CABANG, value: e.CABANG };
      return newBranch.push(newData);
    });
    this.props.filterStruktur.regional.map((e) => {
      let newData = { label: e.REGIONAL, value: e.REGIONAL };
      return newRegion.push(newData);
    });

    let select_content;
    if (selectArea === "Depo") {
      select_content = (
        <section className="mb-4">
          <p>
            Select {""}
            {selectArea === "" ? "Detail Area" : selectArea}
          </p>
          <Select
            options={newDepo}
            isMulti
            onChange={this.handle_change_depo}
          />
        </section>
      );
    }
    if (selectArea === "Branch") {
      select_content = (
        <section className="mb-4">
          <p>
            Select {""}
            {selectArea === "" ? "Detail Area" : selectArea}
          </p>
          <Select
            options={newBranch}
            isMulti
            onChange={this.handle_change_branch}
          />
        </section>
      );
    }
    if (selectArea === "Region") {
      select_content = (
        <section className="mb-4">
          <p>
            Select {""}
            {selectArea === "" ? "Detail Area" : selectArea}
          </p>
          <Select
            options={newRegion}
            isMulti
            onChange={this.handle_change_region}
          />
        </section>
      );
    }

    const modalDownloadAdminDepo = this.state.downloadAdminDepo && (
      <Modal
        modalSize="small"
        isOpen={this.state.downloadAdminDepo}
        onClose={() =>
          this.setState({ downloadAdminDepo: !this.state.downloadAdminDepo })
        }
      >
        <div className="box-body">
          <h1 className="text-center font-bold">Download Depo List by CSV</h1>
          <section className={`flex justify-center mb-3`}>
            <CSVLink
              data={dataCSV}
              filename="Daftar Depo"
              onClick={(e, done) => {
                this.generateDownloadDepoCSV(list_depo, done);
              }}
              className="button_main"
            >
              Download Depo List CSV
            </CSVLink>
          </section>
          <h1 className="text-center font-bold">Download Depo List by EXCEL</h1>
          <section className={`flex justify-center`}>
            <button
              onClick={(e, done) => {
                this.generateDownloadDepoEXCEL(list_depo, done);
              }}
              className="button_main"
            >
              Download Depo List EXCEL
            </button>
          </section>
        </div>
      </Modal>
    );
    return (
      <div>
        <div className="flex gap-1 mb-4">
          <button
            className="button_main"
            onClick={() => this.setState({ modalTambahRegional: true })}
          >
            Add Region
          </button>
          <button
            className="button_main"
            onClick={() => this.setState({ modalTambahCabang: true })}
          >
            Add Branch
          </button>
          <button
            className="button_main"
            onClick={() => this.setState({ modalTambahDepo: true })}
          >
            Add Depo
          </button>
          <button
            className="button_main"
            onClick={() => this.setState({ modalEditTambahBulk: true })}
          >
            Add & Change Multiple
          </button>
          {modalTambahRegional && (
            <Modal
              modalSize="small"
              isOpen={modalTambahRegional}
              onClose={() => this.setState({ modalTambahRegional: false })}
            >
              <div className="box-body">
                <form onSubmit={this.submitTambahregional}>
                  <label>Region Name</label>
                  <input
                    autoComplete="off"
                    type="text"
                    className="w-full"
                    name="tambah_regional"
                    onChange={this.handleOnChange}
                  />
                  <button type="submit" className="button_main">
                    Add Region
                  </button>
                </form>
              </div>
            </Modal>
          )}
          {modalTambahCabang && (
            <Modal
              modalSize="small"
              isOpen={modalTambahCabang}
              onClose={() => this.setState({ modalTambahCabang: false })}
            >
              <div className="box-body">
                <form onSubmit={this.submitTambahCabang}>
                  <label>Branch Name</label>
                  <input
                    autoComplete="off"
                    type="text"
                    className="w-full"
                    name="tambah_cabang"
                    onChange={this.handleOnChange}
                  />
                  <button type="submit" className="button_main">
                    Add Branch
                  </button>
                </form>
              </div>
            </Modal>
          )}
          {modalTambahDepo && (
            <Modal
              modalSize="small"
              isOpen={modalTambahDepo}
              onClose={() => this.setState({ modalTambahDepo: false })}
            >
              <div className="box-body">
                <form onSubmit={this.submitTambahDepo}>
                  <label>Plan</label>
                  <input
                    autoCapitalize="words"
                    autoComplete="off"
                    type="text"
                    className="w-full"
                    name="tambah_plan_id"
                    onChange={this.handleOnChange}
                    required
                  />
                  <label>Depo Name</label>
                  <input
                    autoCapitalize="words"
                    autoComplete="off"
                    type="text"
                    className="w-full"
                    name="tambah_depo"
                    onChange={this.handleOnChange}
                    required
                  />
                  <label>Choose Branch</label>
                  <select
                    className="w-full"
                    name="depoInCabang"
                    onChange={this.handleOnChange}
                    required
                  >
                    <option value="">Choose Branch</option>
                    {list_original_cabang.map((e, i) => (
                      <option title={e.CABANG} key={i} value={e.CABANG}>
                        {e.CABANG}
                      </option>
                    ))}
                  </select>
                  <label>Choose Region</label>
                  <select
                    className="w-full"
                    name="depoInRegional"
                    onChange={this.handleOnChange}
                    required
                  >
                    <option value="">Choose Region</option>
                    {list_original_regional.map((e, i) => (
                      <option title={e.REGIONAL} key={i} value={e.REGIONAL}>
                        {e.REGIONAL}
                      </option>
                    ))}
                  </select>
                  <label>Latitude</label>
                  <input
                    type="number"
                    className="w-full"
                    name="depoLatitude"
                    onChange={this.handleOnChange}
                    required
                    step="any"
                  />
                  <label>Longitude</label>
                  <input
                    type="number"
                    className="w-full"
                    name="depoLongitude"
                    onChange={this.handleOnChange}
                    required
                    step="any"
                  />
                  <div className="mb-1">
                    <small>* Use dot for decimal point</small>
                  </div>
                  <label>Choose Country</label>
                  <select
                    className="w-full"
                    name="negara"
                    onChange={this.handleOnChange}
                    required
                  >
                    <option value="">Choose Country</option>
                    {country_select.map((e, i) => (
                      <option title={e.label} key={i} value={e.value}>
                        {e.label}
                      </option>
                    ))}
                  </select>
                  <br />
                  <br />
                  <button type="submit" className="button_main">
                    Add Depo
                  </button>
                </form>
              </div>
            </Modal>
          )}
          {modalEditTambahBulk && (
            <Modal
              modalSize="large"
              isOpen={modalEditTambahBulk}
              onClose={() => this.setState({ modalEditTambahBulk: false })}
            >
              <div className="box-body">
                <DepoBulk />
              </div>
            </Modal>
          )}
          {modal_depo_edit_manual && (
            <Modal
              modalSize="small"
              isOpen={modal_depo_edit_manual}
              onClose={() => this.setState({ modal_depo_edit_manual: false })}
            >
              <div className="box-body">
                <form onSubmit={this.submitDepoEditManual}>
                  <label>Plan</label>
                  <input
                    autoCapitalize="words"
                    type="text"
                    className="w-full"
                    name="plan_id"
                    onChange={this.handleOnChangeCurrentDepo}
                    required
                    defaultValue={current_depo_edit_manual.plan_id}
                  />
                  <label>Depo Name</label>
                  <input
                    autoCapitalize="words"
                    type="text"
                    className="w-full"
                    name="Depo"
                    onChange={this.handleOnChangeCurrentDepo}
                    required
                    defaultValue={current_depo_edit_manual.Depo}
                  />
                  <label>Choose Branch</label>
                  <select
                    className="w-full"
                    name="CABANG"
                    onChange={this.handleOnChangeCurrentDepo}
                    required
                    defaultValue={current_depo_edit_manual.CABANG}
                  >
                    <option value="">Choose Branch</option>
                    {list_original_cabang.map((e, i) => (
                      <option title={e.CABANG} key={i} value={e.CABANG}>
                        {e.CABANG}
                      </option>
                    ))}
                  </select>
                  <label>Choose Region</label>
                  <select
                    className="w-full"
                    name="REGION"
                    onChange={this.handleOnChangeCurrentDepo}
                    required
                    defaultValue={current_depo_edit_manual.REGION}
                  >
                    <option value="">Choose Region</option>
                    {list_original_regional.map((e, i) => (
                      <option title={e.REGIONAL} key={i} value={e.REGIONAL}>
                        {e.REGIONAL}
                      </option>
                    ))}
                  </select>
                  <label>Choose Folder Depo</label>
                  <select
                    className="w-full"
                    name="folder_id"
                    onChange={this.handleOnChangeCurrentDepo}
                    required
                    defaultValue={current_depo_edit_manual.folder_id}
                  >
                    <option value="">Choose Folder Depo</option>
                    {this.props.data.depo_folder_list.map((e, i) => (
                      <option title={e.text} key={i} value={e._id}>
                        {e.text}
                      </option>
                    ))}
                  </select>
                  <label>Latitude</label>
                  <input
                    type="number"
                    className="w-full"
                    name="depoLatitude"
                    onChange={this.handleOnChangeCurrentDepo}
                    required
                    defaultValue={
                      current_depo_edit_manual.location.coordinates[1]
                    }
                    step="any"
                  />
                  <label>Longitude</label>
                  <input
                    type="number"
                    className="w-full"
                    name="depoLongitude"
                    onChange={this.handleOnChangeCurrentDepo}
                    required
                    defaultValue={
                      current_depo_edit_manual.location.coordinates[0]
                    }
                    step="any"
                  />
                  <div className="mb-1">
                    <small>* Use dot for decimal point</small>
                  </div>
                  <label>Choose Country</label>
                  <select
                    className="w-full"
                    name="negara"
                    onChange={this.handleOnChangeCurrentDepo}
                    required
                    defaultValue={current_depo_edit_manual.country}
                  >
                    <option value="">Choose Country</option>
                    {country_select.map((e, i) => (
                      <option title={e.label} key={i} value={e.value}>
                        {e.label}
                      </option>
                    ))}
                  </select>
                  <label>Gaji Pokok</label>
                  <input
                    autoCapitalize="words"
                    type="text"
                    className="w-full"
                    name="gaji"
                    onChange={this.handleOnChangeCurrentDepo}
                    required
                    defaultValue={current_depo_edit_manual.gaji}
                  />
                  <label>Bensin</label>
                  <input
                    autoCapitalize="words"
                    type="text"
                    className="w-full"
                    name="bensin"
                    onChange={this.handleOnChangeCurrentDepo}
                    required
                    defaultValue={current_depo_edit_manual.bensin}
                  />
                  <label>Sewa Motor</label>
                  <input
                    autoCapitalize="words"
                    type="text"
                    className="w-full"
                    name="sewaMotor"
                    onChange={this.handleOnChangeCurrentDepo}
                    required
                    defaultValue={current_depo_edit_manual.sewaMotor}
                  />
                  <label>Uang Makan</label>
                  <input
                    autoCapitalize="words"
                    type="text"
                    className="w-full"
                    name="uangMakan"
                    onChange={this.handleOnChangeCurrentDepo}
                    required
                    defaultValue={current_depo_edit_manual.uangMakan}
                  />
                  <label>Insentif</label>
                  <input
                    autoCapitalize="words"
                    type="text"
                    className="w-full"
                    name="insentif"
                    onChange={this.handleOnChangeCurrentDepo}
                    required
                    defaultValue={current_depo_edit_manual.insentif}
                  />
                  <label>Total Benefit</label>
                  <input
                    autoCapitalize="words"
                    type="text"
                    className="w-full"
                    name="totalBenefit"
                    onChange={this.handleOnChangeCurrentDepo}
                    required
                    defaultValue={current_depo_edit_manual.totalBenefit}
                  />
                  <br />
                  <br />
                  <button type="submit" className="button_main w-full">
                    Edit Depo
                  </button>
                </form>
                <hr className="my-4" />
                <div>
                  <button
                    className="text-red-600 font-semibold w-full"
                    onClick={this.deleteDepo}
                  >
                    Delete Depo
                  </button>
                </div>
              </div>
            </Modal>
          )}
        </div>
        <form className="mb-4" onSubmit={this.handleSubmit}>
          <section className="mb-2">
            <p>Select Depo/Branch/Region</p>
            <Select options={area_list} onChange={this.handle_change} />
          </section>
          {select_content}
          {/* <div className="flex gap-1">
            <div>
              <label>Filter Depo</label>
              <div>
                <input
                  className="w-full"
                  list="depos"
                  name="depo"
                  id="depo"
                  onChange={this.handleSearch}
                  autoComplete="off"
                />
                <datalist id="depos">
                  {list_depo.map((e, i) => (
                    <option value={e.Depo} title={e.Depo} key={i} />
                  ))}
                </datalist>
              </div>
            </div>
            <div>
              <label>Filter Branch</label>
              <div>
                <input
                  className="w-full"
                  list="cabangs"
                  name="cabang"
                  id="cabang"
                  onChange={this.handleSearch}
                  autoComplete="off"
                />
                <datalist id="cabangs">
                  {list_cabang.map((e, i) => (
                    <option value={e.CABANG} title={e.CABANG} key={i} />
                  ))}
                </datalist>
              </div>
            </div>
            <div>
              <label>Filter Region</label>
              <div>
                <input
                  className="w-full"
                  list="regionals"
                  name="regional"
                  id="regional"
                  onChange={this.handleSearch}
                  autoComplete="off"
                />
                <datalist id="regionals">
                  {list_regional.map((e, i) => (
                    <option value={e.REGIONAL} title={e.REGIONAL} key={i} />
                  ))}
                </datalist>
              </div>
            </div>
          </div> */}
          <div>
            <button className="button_main" type="submit">
              Find
            </button>
          </div>
        </form>
        <hr className="mb-2 border-2" />
        {list_depo.length > 0 && (
          <div className="mb-2 flex gap-1 text-right">
            <img
              src={is_visible_depo ? icon_view_active : icon_view}
              alt="View icon"
              className="h-[28px]"
              onClick={this.on_toggle_view_log.bind(this, "depo_loc_list")}
            />
            {/* <button className="button_main" onClick={this.handleForceRefresh}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                  clipRule="evenodd"
                />
              </svg>
            </button> */}
            <button
              className="button ml-1"
              id="green"
              onClick={() => {
                this.setState({ downloadAdminDepo: true });
              }}
            >
              Download Depo List
            </button>
          </div>
        )}
        {list_depo.length > 0 && (
          <table className="table-auto border-2">
            <thead className="sticky -top-5">
              <tr className="text-left bg-gray-300">
                <th>No</th>
                <th>Plan</th>
                <th>Depo</th>
                <th>Branch</th>
                <th>Region</th>
                <th>Country</th>
                <th>Gaji Pokok</th>
                <th>Bensin</th>
                <th>Sewa Motor</th>
                <th>Uang Makan</th>
                <th>Insentif</th>
                <th>Total Benefit</th>
              </tr>
            </thead>
            <tbody>
              {list_depo.map((el, inc) => {
                return (
                  <tr
                    onClick={this.handleEditDepoManual.bind(this, el)}
                    className="even:bg-gray-100 cursor-pointer hover:bg-gray-200"
                    key={inc}
                  >
                    <td className="py-2 font-semibold">{inc + 1}</td>
                    <td className="py-2 pr-4">{el.plan_id}</td>
                    <td className="py-2 pr-4">{el.Depo}</td>
                    <td className="py-2 pr-4">{el.CABANG}</td>
                    <td className="py-2 pr-4">{el.REGION}</td>
                    <td className="py-2 pr-4">{el.country}</td>
                    <td className="py-2 pr-4">{el.gaji ? el.gaji : "-"}</td>
                    <td className="py-2 pr-4">{el.bensin ? el.bensin : "-"}</td>
                    <td className="py-2 pr-4">
                      {el.sewaMotor ? el.sewaMotor : "-"}
                    </td>
                    <td className="py-2 pr-4">
                      {el.uangMakan ? el.uangMakan : "-"}
                    </td>
                    <td className="py-2 pr-4">
                      {el.insentif ? el.insentif : "-"}
                    </td>
                    <td className="py-2 pr-4">
                      {el.totalBenefit ? el.totalBenefit : "-"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {/* {list_depo.length === 0 && (
          <div className="text-center mt-4">
            No Depo, Click{" "}
            <span
              onClick={this.handleRefresh}
              className="text-sky-600 cursor-pointer"
            >
              here
            </span>{" "}
            try again
          </div>
        )} */}
        {modalDownloadAdminDepo}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  filterStruktur: state.data.salesman_filter,
  original_cabang: state.data.salesman_filter_original_cabang,
  original_regional: state.data.salesman_filter_original_regional,
  auth: state.auth,
  data: state.data,
});
export default connect(mapStateToProps, {
  set_depo_loc_list,
  manual_add_regional,
  manual_add_cabang,
  manual_add_depo,
  turn_on_menu_status,
  turn_off_menu,
  manual_edit_depo,
  delete_depo,
  getSalesmanFilter,
  get_item_list,
})(HirarkiDepo);
