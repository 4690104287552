/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/
import generate_color_map from "../../validation/generate_color_map";

class CycleLayer extends Component {
  render() {
    //local storage

    //local state

    //global props
    let list_menu_active_after = this.props.data.list_menu_active;

    //content
    const menu_status_after = list_menu_active_after.find(
      (e) => e.key === "distribution"
    );
    const mode = menu_status_after?.config?.mode;
    let text_final, array_name, array_color, color_map;
    const is_visible = this.props.data.list_menu_active.find(
      (e) => e.key === "cycle"
    )?.active;
    const { rayon_list_database, rayon_select, cycle_select } = this.props.data;
    const rayon_object = rayon_list_database.find(
      (r) => r._id === rayon_select
    );
    let cycle_list = rayon_object?.cycle_list ? rayon_object?.cycle_list : [];

    if (mode === "day") {
      cycle_list = cycle_list.filter((c) => c === cycle_select);
    }
    const list = cycle_list;
    let geojson = {
      type: "FeatureCollection",
      features: [],
    };
    if (list?.length > 0) {
      array_name = list.map((e) => e.name);
      if (array_name?.length > 0) {
        array_color = list.map((e) => e.color);
        array_color.push("#000000");
        text_final = "name";
        color_map = generate_color_map(array_name, array_color, text_final);
      }
      const new_features = list.map((e) => {
        return {
          type: "Feature",
          properties: { ...e },
          geometry: e.location,
        };
      });
      geojson = {
        type: "FeatureCollection",
        features: [...new_features],
      };
    }
    return (
      list?.length > 0 && (
        <main>
          <Source
            key="cycle-src"
            id="cycle-src"
            type="geojson"
            data={geojson}
          />
          <Layer
            id="cycle"
            key="cycle"
            before="depo_loc_list"
            type="symbol"
            source="cycle-src"
            layout={{
              visibility: is_visible ? "visible" : "none",
              "icon-image": "none",
              "text-field": ["get", "name"],
              "text-font": ["Open Sans Bold"],
              "text-anchor": "top",
              "text-offset": [0, -2.5],
              "text-line-height": 1,
              "text-allow-overlap": false,
              "text-ignore-placement": false,
              "text-size": 12,
              "text-max-width": 10,
            }}
            paint={{
              "text-color": color_map,
              "text-halo-color": "white",
              "text-halo-width": 4,
            }}
            onLeave={this.onLeave}
            onClick={this.props.layerOnClick.bind(this, "modal_plotting")}
          />
        </main>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, {})(CycleLayer);
