import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;

//test
/*
body = {
  
}
*/
export const clear_deleted_rayon = (body) => async (dispach) => {
  try {
    dispach(set_loading());
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(
      `${SERVER_URL}/nabati_deliver/clear_deleted_rayon`,
      body,
      config
    );

    dispach(clear_loading());
  } catch (error) {
    console.log(error);
    dispach(clear_loading());
  }
};

export const uploadAddDriver = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      delete body._id;
      delete body._status;

      const params = {
        "DRIVER": body.DRIVER,
        "HELPER": body.HELPER,
        "NOPOL": body["LICENSE PLATE NUMBER"],
        "Depo": body.DEPO,
        "JENIS": body["CAR TYPE"],
        "KAPASITAS_CTN": body["CARTON CAPACITY"],
        "KAPASITAS_M3": body["VOLUME CAPACITY"],
        "COUNTRY": body.COUNTRY,
      };

      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenNabati,
        },
      };
      const response = await axios.post(
        `${SERVER_URL}/nabati_deliver/add_driver`,
        params,
        config
      );
      resolve(response);
    } catch (error) {
      reject("error upload add driver");
    }
  });
};

export const uploadEditDriver = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      delete body._status;
      
      const params = {
        "_id": body._id,
        "DRIVER": body.DRIVER,
        "HELPER": body.HELPER,
        "NOPOL": body["LICENSE PLATE NUMBER"],
        "Depo": body.DEPO,
        "JENIS": body["CAR TYPE"],
        "KAPASITAS_CTN": body["CARTON CAPACITY"],
        "KAPASITAS_M3": body["VOLUME CAPACITY"],
        "COUNTRY": body.COUNTRY,
      };

      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenNabati,
        },
      };
      const response = await axios.post(
        `${SERVER_URL}/nabati_deliver/edit`,
        params,
        config
      );
      resolve(response);
    } catch (error) {
      reject("error upload edit driver");
    }
  });
};

// Non API
export const set_loading_top = () => (dispach) => {
  dispach({
    type: "SET_LOADING_TOP",
  });
};

export const clear_loading_top = () => (dispach) => {
  dispach({
    type: "CLEAR_LOADING_TOP",
  });
};

export const set_loading = (loading_item) => {
  return {
    type: "SET_LOADING",
    payload: loading_item,
  };
};

export const clear_loading = () => {
  return {
    type: "CLEAR_LOADING",
  };
};
