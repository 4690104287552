/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal";
import EditSalesman from "../parent/EditSalesman";

/*REDUX FUNCTION*/
import { get_salesman_by_id } from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL*/

/*NON IMPORT*/

class SALESMAN_POPUP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state_modal_edit_salesman: false,
    };
  }

  handle_edit_salesman = (e) => {
    this.setState({
      state_modal_edit_salesman: !this.state_modal_edit_salesman,
    });
    this.props.get_salesman_by_id(e._id);
  };

  render() {
    //localstorage

    //local state
    const { state_modal_edit_salesman } = this.state;

    //global props
    const { user } = this.props.auth;

    //content
    const pop_up_properties = this?.props?.properties?.pop_up_properties || {};

    const modal_edit_salesman = state_modal_edit_salesman && (
      <Modal
        modalSize="small"
        isOpen={state_modal_edit_salesman}
        onClose={() => this.setState({ state_modal_edit_salesman: false })}
      >
        <div className="box-body">
          <EditSalesman />
        </div>
      </Modal>
    );

    return (
      <main style={{ fontFamily: `"Inter", sans-serif` }}>
        <div
          className="text_bold mb-3"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          Salesman Details
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Salesman Name
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {pop_up_properties?.["Nama Salesman"] || "-"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Employee ID
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {pop_up_properties?.["NIK"] || "-"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Color Status
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {(() => {
            let text = "";
            if (pop_up_properties?.["status_bike"]) {
              if (pop_up_properties?.["status_bike"] === "bike_red")
                text = "Merah";
              if (pop_up_properties?.["status_bike"] === "bike_blue")
                text = "Biru";
              if (pop_up_properties?.["status_bike"] === "bike_green")
                text = "Hijau";
            } else {
              text = "Unfilled";
            }
            return text;
          })()}
        </div>
        <div>
          {user.badge === "super_admin" && (
            <button
              className="button_main mr-2"
              onClick={this.handle_edit_salesman.bind(this, pop_up_properties)}
            >
              Edit Salesman
            </button>
          )}
        </div>
        {modal_edit_salesman}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  auth: state.auth,
  properties: state.properties,
});

export default connect(mapStateToProps, { get_salesman_by_id })(SALESMAN_POPUP);
