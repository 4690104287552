const initial_state = {
  loading_status: false,
  loading_item: "",
  segment_outlet: [],
  segment_list_select: [],
  outlet_id_test: [],
  id_sfa_test: [],
  id_sfa_test_status: "pending",
  merch_status_upload: [],

  class_array: ["A", "B", "C", "D", "E"],
  omset_array: [
    0, 100_000, 200_000, 300_000, 400_000, 500_000, 2_000_000, 30_000_000,
  ],
  omset_color_array: [
    "#000000", //"#000000", butuh dua kali untuk logic else dijadikan "#000000", juga
    "#000000",
    "#bc2023",
    "#eb442c",
    "#f8b324",
    "#b0ae2d",
    "#4da83a",
    "#069fff",
    "#2806ff",
  ],
  progress_get_id_sfa: 0,
  total_get_id_sfa: 1,

  progress_outlet_new: 0,
  progress_outlet_edit: 0,
  total_check_id_sfa: 1,

  progress_edit_many_toko_rayon_bulk: 0,
  total_edit_many_toko_rayon_bulk: 1,

  //konvert dari state ke props komponen UploadAddToko
  file_header: [],
  file_body: [],
  sfa_list: [],
  outlet_new: [], //json data will be added
  outlet_edit: [], //json data will be edited
  is_sfa_checked: false, //status apakah sfa sudah dicheck atau belum
  is_outlet_add_done: false, //status apakah semua outlet sudah diupload

  current_array_edit: [],
  success_array: [],
  not_found_array: [],
  error_array: [],
  error_array_csv: [],

  add_outlet_finish_status: false,
  edit_outlet_finish_status: false,

  modal_edit: false,
  modal_error_edit_outlet: false,
  mode_competitor: "nabati",
  mode_download: "",
  is_checked: false,
  loadingExcel: false,
  loading_csv: false,
  modal_error: false,
  modal_add: false,
  outlet_upload_changes: 0, //indikator terjadi perubahan untuk komponen add toko untuk mencegah re render ketika scroll
  depo_list: [],
  depo_list_filtered: [],

  competitor_rayon_task_newest: {},
  task_id_current: "",
  child_task_finish_index: 0,
  child_task_current: 0,
  rayon_body_api: [],
  cycle_body_api: [],

  pop_up_properties: {},
  pop_up_key: "",
  merchandising_object: {},

  parent_task_finish_index: 1,
  parent_task_current: 0,

  pop_up_data: {},
  modal_pop_up: false,
  pop_up_map: null,
  type_pop_up: "",

  driver_city: [],
  rayon_city: [],
};

export default function propertiesReducer(state = initial_state, action) {
  switch (action.type) {
    case "hide_all_filter_array":
      return {
        ...state,
        outlet_merch_list: [],
        outlet_merch_list_filter_0: [],
      };
    case "show_all_filter_array":
      return {
        ...state,
        outlet_merch_list: state.outlet_merch_list_back_up,
        outlet_merch_list_filter_0: state.outlet_merch_list_filter_0_back_up,
      };

    case "on_progress_child_task":
      return {
        ...state,
        competitor_rayon_task_newest: on_progress_child_task(
          state.competitor_rayon_task_newest,
          action.payload
        ),
      };

    case "finish_child_task":
      return {
        ...state,
        competitor_rayon_task_newest: finish_child_task(
          state.competitor_rayon_task_newest,
          action.payload
        ),
      };
    case "generate_outlet_new":
      return {
        ...state,
        outlet_new: action.payload,
        is_sfa_checked: true,
      };
    case "generate_outlet_edit":
      return {
        ...state,
        outlet_edit: action.payload,
        is_sfa_checked: true,
      };
    case "outlet_upload_changes":
      return {
        ...state,
        outlet_upload_changes: state.outlet_upload_changes + 1,
      };
    case "set_value_properties":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
        outlet_upload_changes: state.outlet_upload_changes + 1,
      };
    case "push_list_bulk":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          ...action.payload.value,
        ],
      };
    case "incriment_properties":
      return {
        ...state,
        [action.payload.key]: state[action.payload.key] + action.payload.value,
      };
    case "aggregate_segment_outlet":
      return {
        ...state,
        segment_outlet: action.payload,
      };
    case "set_loading_properties":
      return {
        ...state,
        loading_status: true,
        loading_item: action.payload,
      };
    case "clear_loading_properties":
      return {
        ...state,
        loading_status: false,
      };
    default:
      return state;
  }
}

const on_progress_child_task = (competitor_rayon_task_newest, body) => {
  const { child_task_id, user } = body;
  let child_task_array = competitor_rayon_task_newest.child_task_array;
  const child_task_index = child_task_array.findIndex(
    (item) => item._id === child_task_id
  );
  child_task_array[child_task_index].status = "on_progress";
  child_task_array[child_task_index].user = user;
  competitor_rayon_task_newest.child_task_array = child_task_array;
  return competitor_rayon_task_newest;
};

const finish_child_task = (competitor_rayon_task_newest, body) => {
  const { child_task_id } = body;
  let child_task_array = competitor_rayon_task_newest.child_task_array;
  const child_task_index = child_task_array.findIndex(
    (item) => item._id === child_task_id
  );
  child_task_array[child_task_index].status = "finish";
  competitor_rayon_task_newest.child_task_array = child_task_array;
  return competitor_rayon_task_newest;
};
