/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import ProgressBar from "../common/ProgressBar";

/*REDUX FUNCTION*/
import { start_rayon_depo_competitor } from "../../actions/algoActions";
import { get_count_competitor_national_not_done } from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL*/

class RAYON_DEPO_STEP_3 extends Component {
  state = {};

  componentDidMount() {
    this.props.get_count_competitor_national_not_done();
  }

  on_get_count_competitor_national_not_done = () => {
    this.props.get_count_competitor_national_not_done();
  };

  on_start_rayon_depo_competitor = (task_object) => {
    const { user } = this.props.auth;
    const { depo_list_filtered, competitor_rayon_task_newest } =
      this.props.properties;
    this.props.start_rayon_depo_competitor({
      ...task_object,
      task_id: competitor_rayon_task_newest._id,
      depo_list_filtered,
      user,
      child_task_id: task_object._id,
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const {
      competitor_rayon_task_newest,
      loading_status,
      loading_item,
      task_id_current,
      child_task_finish_index,
      child_task_current,
    } = this.props.properties;

    const { competitor_national_count, competitor_national_count_not_done } =
      this.props.data;

    //content
    let content;

    const child_task_array =
      competitor_rayon_task_newest?.child_task_array || [];

    if (child_task_array.length > 0) {
      content = (
        <main>
          <h1 className="text_bold">TASK LIST</h1>
          <table className="table">
            <tbody>
              <tr>
                <td>COMPETITOR TOTAL</td>
                <td>
                  {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                    competitor_national_count
                  )}
                </td>
              </tr>
              <tr>
                <td>PENDING TOTAL</td>
                <td>
                  {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                    competitor_national_count_not_done
                  )}
                  <br />
                  <button
                    className="button_small background_green mr-1"
                    onClick={this.on_get_count_competitor_national_not_done}
                  >
                    Refresh
                  </button>
                  {this.props.data.loading_status &&
                    this.props.data.loading_item ===
                      "get_count_competitor_national_not_done" && (
                      <div className="button_small background_green">
                        Loading
                      </div>
                    )}
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          {loading_status && loading_item === "create_competitor_rayon_task" ? (
            <div className="button background_green">
              Creating task list, this will take some time.
            </div>
          ) : (
            <section>
              <table className="table">
                <thead>
                  <tr>
                    <th>Step</th>
                    <th colSpan={2}>
                      <p className="text_center">
                        PLAN
                        <br />
                        START-END
                      </p>
                    </th>
                    <th>
                      <p className="text_center">
                        REAL
                        <br />
                        TOTAL OUTLET
                      </p>
                    </th>
                    <th>EXECUTOR</th>
                    <th>STATUS</th>
                    <th style={{ width: "200px" }}>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {child_task_array.map((task, idx) => {
                    const start = new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(task.start);
                    const end = new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(task.end);
                    return (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{start}</td>
                        <td>{end}</td>
                        <td>
                          {new Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(task?.jumlah_outlet)}
                        </td>
                        <td>{task?.user?.full_name}</td>
                        <td>{task.status}</td>
                        <td>
                          {task_id_current === task._id ? (
                            <main>
                              <div
                                className="button_small margin_bottom"
                                id="grey"
                              >
                                {loading_status &&
                                loading_item === "start_rayon_depo_competitor"
                                  ? "On process.."
                                  : "Done"}
                              </div>
                              <br />
                              <br />
                              <ProgressBar
                                current_number={child_task_current}
                                total_number={child_task_finish_index}
                                name="Updating outlets"
                              />
                              <section></section>
                            </main>
                          ) : loading_status &&
                            loading_item === "start_rayon_depo_competitor" ? (
                            <div className="button_small background_grey">
                              START DEPO RAYONIZATION
                            </div>
                          ) : (
                            <button
                              className="button_small"
                              id="green"
                              onClick={this.on_start_rayon_depo_competitor.bind(
                                this,
                                task
                              )}
                            >
                              START DEPO RAYONIZATION
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <p className="text_small mt-5">
                Catatan: jumlah outlet REAL akan berbeda dengan PLAN pada step
                terakhir karena total outlet kompetitor tidak habis dibagi
                dengan range yang diplaning
              </p>
              <p className="text_small">
                Note: the REAL number of outlets will be different from the PLAN
                in the last step because the total number of competitor outlets
                is not divisible by the planned range
              </p>
            </section>
          )}
        </main>
      );
    }

    return <main className="container_light">{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.data,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  start_rayon_depo_competitor,
  get_count_competitor_national_not_done,
})(RAYON_DEPO_STEP_3);
