import sort_array from "../validation/sort_array";
import st_colors from "../validation/st_colors.json";

const initial_state = {
  loading_status: false,
  loading_item: "",
  outlet_upload_changes: 0,

  outlet_by_salesman_list: [],
  outlet_by_salesman_list_backup: [],

  outlet_by_salesman_list_filter_0: [],
  outlet_by_salesman_list_filter_0_back_up: [],

  //array parameter filter untuk outlet terbaru
  depo_filter_array: [], //1
  system_filter_array: [], //2
  segment_filter_array: [], //3
  type_filter_array: [], //4
  rayon_filter_array: [], //5
  day_filter_array: [], //6
  cycle_filter_array: [], //7
  provinsi_filter_array: [], //8
  kabupaten_filter_array: [], //9
  kecamatan_filter_array: [], //10
  kelurahan_filter_array: [], //11

  show_all_status: {
    depo_filter_array: true,
    system_filter_array: true,
    segment_filter_array: true,
    type_filter_array: true,
    rayon_filter_array: true,
    day_filter_array: true,
    cycle_filter_array: true,
    provinsi_filter_array: true,
    kabupaten_filter_array: true,
    kecamatan_filter_array: true,
    kelurahan_filter_array: true,
  },

  filter_outlet_object: {
    depo_filter_array: {
      properties_name: "Depo",
    },
    system_filter_array: {
      properties_name: "SYSTEM",
    },
    segment_filter_array: {
      properties_name: "SEGMENT",
    },
    type_filter_array: {
      properties_name: "Type Outlet",
    },
    rayon_filter_array: {
      properties_name: "rayon",
    },
    day_filter_array: {
      properties_name: "HARIKunjungan",
    },
    cycle_filter_array: {
      properties_name: "FreqVisit",
    },
    provinsi_filter_array: {
      properties_name: "PROPINSI",
    },
    kabupaten_filter_array: {
      properties_name: "KABUPATEN",
    },
    kecamatan_filter_array: {
      properties_name: "KECAMATAN",
    },
    kelurahan_filter_array: {
      properties_name: "KELURAHAN",
    },
  },
};

const filter_outlet_object = {
  depo_filter_array: {
    properties_name: "Depo",
  },
  system_filter_array: {
    properties_name: "SYSTEM",
  },
  segment_filter_array: {
    properties_name: "SEGMENT",
  },
  type_filter_array: {
    properties_name: "Type Outlet",
  },
  rayon_filter_array: {
    properties_name: "rayon",
  },
  day_filter_array: {
    properties_name: "HARIKunjungan",
  },
  cycle_filter_array: {
    properties_name: "FreqVisit",
  },
  provinsi_filter_array: {
    properties_name: "PROPINSI",
  },
  kabupaten_filter_array: {
    properties_name: "KABUPATEN",
  },
  kecamatan_filter_array: {
    properties_name: "KECAMATAN",
  },
  kelurahan_filter_array: {
    properties_name: "KELURAHAN",
  },
};

const filter_outlet_by_salesman_list = ({
  list,
  only_show_array,
  properties_name,
}) => {
  let outlet_by_salesman_list_filter_0 = [];
  list.forEach((outlet) => {
    if (only_show_array.includes(outlet[properties_name]))
      outlet_by_salesman_list_filter_0.push(outlet);
  });

  return outlet_by_salesman_list_filter_0;
};

export default function outletReducer(state = initial_state, action) {
  switch (action.type) {
    case "hide_all_filter_array":
      return {
        ...state,
        depo_filter_array: hide_all_filter_array(state.depo_filter_array), //1
        system_filter_array: hide_all_filter_array(state.system_filter_array), //2
        segment_filter_array: hide_all_filter_array(state.segment_filter_array), //3
        type_filter_array: hide_all_filter_array(state.type_filter_array), //4
        rayon_filter_array: hide_all_filter_array(state.rayon_filter_array), //5
        day_filter_array: hide_all_filter_array(state.day_filter_array), //6
        cycle_filter_array: hide_all_filter_array(state.cycle_filter_array), //7
        provinsi_filter_array: hide_all_filter_array(
          state.provinsi_filter_array
        ), //8
        kabupaten_filter_array: hide_all_filter_array(
          state.kabupaten_filter_array
        ), //9
        kecamatan_filter_array: hide_all_filter_array(
          state.kecamatan_filter_array
        ), //10
        kelurahan_filter_array: hide_all_filter_array(
          state.kelurahan_filter_array
        ), //11

        outlet_by_salesman_list_filter_0: [],
        outlet_by_salesman_list: [],
      };
    case "show_all_filter_array":
      return {
        ...state,
        outlet_by_salesman_list: state.outlet_by_salesman_list_backup,
        outlet_by_salesman_list_filter_0:
          state.outlet_by_salesman_list_filter_0_back_up,
        ...show_all_filter_array({
          array_state: state[action.payload.array_name],
          array_name: action.payload.array_name,
        }),
      };

    case "hide_filter_array":
      return {
        ...state,
        ...hide_filter_array({
          array_state: state[action.payload.array_name],
          element_name: action.payload.element_name,
          array_name: action.payload.array_name,
          outlet_by_salesman_list: state.outlet_by_salesman_list_backup,
          outlet_by_salesman_list_filter_0:
            state.outlet_by_salesman_list_filter_0_back_up,
        }),
      };

    case "show_filter_array":
      return {
        ...state,
        ...show_filter_array({
          array_state: state[action.payload.array_name],
          element_name: action.payload.element_name,
          array_name: action.payload.array_name,
          outlet_by_salesman_list: state.outlet_by_salesman_list_backup,
          outlet_by_salesman_list_filter_0:
            state.outlet_by_salesman_list_filter_0_back_up,
        }),
      };

    case "push_unique_items_to_array":
      return {
        ...state,
        [action.payload.key]: push_unique_items_to_array(
          state[action.payload.key],
          action.payload.value
        ),
      };

    case "set_value_outlet":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
        outlet_upload_changes: state.outlet_upload_changes + 1,
      };
    case "set_loading_outlet":
      return {
        ...state,
        loading_status: true,
        loading_item: action.payload,
      };
    case "clear_loading_outlet":
      return {
        ...state,
        loading_status: false,
        loading_item: "",
      };

    default:
      return state;
  }
}

//hide_filter_array
const hide_all_filter_array = (array_state) => {
  array_state = array_state.map((item) => {
    item.is_hide = true;
    return item;
  });
  return array_state;
};

//show_all_filter_array
const show_all_filter_array = ({ array_state, array_name }) => {
  array_state = array_state.map((item) => {
    item.is_hide = false;
    return item;
  });
  return {
    [array_name]: array_state,
  };
};

//hide_filter_array
const hide_filter_array = ({
  array_state,
  element_name,
  array_name,
  outlet_by_salesman_list,
  outlet_by_salesman_list_filter_0,
}) => {
  const index = array_state.findIndex((item) => item.name === element_name);
  array_state[index].is_hide = true;

  let only_show_array = array_state.filter((item) => !item.is_hide);
  only_show_array = only_show_array.map((item) => item.name);
  const properties_name = filter_outlet_object[array_name].properties_name;

  const outlet_by_salesman_list_new = filter_outlet_by_salesman_list({
    list: outlet_by_salesman_list,
    only_show_array,
    properties_name,
  });

  const outlet_by_salesman_list_filter_0_new = filter_outlet_by_salesman_list({
    list: outlet_by_salesman_list_filter_0,
    only_show_array,
    properties_name,
  });

  return {
    [array_name]: array_state,
    outlet_by_salesman_list: outlet_by_salesman_list_new,
    outlet_by_salesman_list_filter_0: outlet_by_salesman_list_filter_0_new,
  };
};

//show_filter_array
const show_filter_array = ({
  array_state,
  element_name,
  array_name,
  outlet_by_salesman_list,
  outlet_by_salesman_list_filter_0,
}) => {
  const index = array_state.findIndex((item) => item.name === element_name);
  array_state[index].is_hide = false;

  let only_show_array = array_state.filter((item) => !item.is_hide);
  only_show_array = only_show_array.map((item) => item.name);
  const properties_name = filter_outlet_object[array_name].properties_name;

  const outlet_by_salesman_list_new = filter_outlet_by_salesman_list({
    list: outlet_by_salesman_list,
    only_show_array,
    properties_name,
  });

  const outlet_by_salesman_list_filter_0_new = filter_outlet_by_salesman_list({
    list: outlet_by_salesman_list_filter_0,
    only_show_array,
    properties_name,
  });

  return {
    [array_name]: array_state,
    outlet_by_salesman_list: outlet_by_salesman_list_new,
    outlet_by_salesman_list_filter_0: outlet_by_salesman_list_filter_0_new,
  };
};

//push_unique_items_to_array
const push_unique_items_to_array = (array_state, array_value) => {
  array_value.forEach((item) => {
    //looping array_value jika belum ada pada array_state maka masukkan valuenya ke dalam array of object
    if (array_state.findIndex((e) => e.name === item) === -1)
      array_state.push({ name: item || "", is_hide: false });
  });
  array_state = sort_array(array_state, "name", true);
  array_state = array_state.map((item, idx) => {
    let index = idx;
    /*
    misal ada array warna memiliki 3 elemen [0,1,2],
    jika idx lebih besar dari index terakhir (3-1 = 2) maka diambil sisa hasil bagi dari idx%2

    misal idx = 3 --> index menjadi 0
    misal idx = 4 --> index menjadi 1
    misal idx = 5 --> index menjadi 2

    idx%3 --> idx%st_colors.length
    */
    if (idx > st_colors.length - 1) {
      index = idx % st_colors.length;
    }
    item.color = st_colors[index];
    return item;
  });
  return array_state;
};
