/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Title,
  SubTitle,
} from "chart.js";
import Select from "react-select";

/*PERSONAL COMPONENT*/
import CompetitorSuccessDownload from "./CompetitorSuccessDownload";
import ON_FLY from "../main/ON_FLY";

/*REDUX FUNCTION*/
import {
  get_all_submitted_competitor,
  get_competitor_count,
  turn_on_menu_status,
  set_config_menu,
  set_list,
} from "../../actions/dataActions";
import sort_array from "../../validation/sort_array";

/*PICTURE ASSET*/

/*GENERAL*/
import st_colors from "../../validation/st_colors.json";

/*NON IMPORT*/
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Title,
  SubTitle
);

class COMPETITOR_SUCCESS_MENU extends Component {
  state = {
    view_list_status: false,
    sort_value: "text",
  };

  on_change_select(e) {
    const list_menu_active_after = this.props.data.list_menu_active;
    let config = list_menu_active_after.find(
      (e) => e.key === "competitor_success"
    )?.config;
    let depo_filter = config?.depo_filter ? config?.depo_filter : [];

    let list = [];
    let is_hide_all_depo = config?.is_hide_all_depo
      ? config?.is_hide_all_depo
      : false;

    //1. is_hide_all_depo
    is_hide_all_depo = true;
    //2. depo_list
    depo_filter = e.map((e) => e.value);
    //3. toko_list
    list = this.props.data.competitor_success_list_origin.filter((t) =>
      depo_filter.includes(t.Depo)
    );

    config.is_hide_all_depo = is_hide_all_depo;
    config.depo_filter = depo_filter;

    this.props.set_config_menu("competitor_success", config);
    this.props.set_list({
      text: "competitor_success_list",
      list,
    });
  }

  componentDidMount() {
    let { competitor_success_list } = this.props.data;
    if (competitor_success_list.length === 0) {
      this.props.get_all_submitted_competitor();
    }
    this.props.turn_on_menu_status("competitor_success");
  }

  toggle_view_list_status = () => {
    this.setState({ view_list_status: !this.state.view_list_status });
  };

  on_change = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  on_click = (item) => {
    const list_menu_active_after = this.props.data.list_menu_active;
    let config = list_menu_active_after.find(
      (e) => e.key === "competitor_success"
    )?.config;
    let depo_filter = config?.depo_filter ? config?.depo_filter : [];

    let list = [];
    let is_hide_all_depo = config?.is_hide_all_depo
      ? config?.is_hide_all_depo
      : false;

    if (item === "all") {
      const { competitor_success_list_origin } = this.props.data;
      //1. is_hide_all_depo
      is_hide_all_depo = false;
      //2. depo list
      depo_filter = [
        ...new Set(competitor_success_list_origin.map((t) => t.Depo)),
      ];
      //3. toko_list
      list = competitor_success_list_origin;
    } else if (item === "not_all") {
      //1. is_hide_all_depo
      is_hide_all_depo = true;
      //2. depo_list
      depo_filter = [];
      //3. toko_list
      list = [];
    } else {
      //1. is_hide_all_depo
      is_hide_all_depo = true;
      //2. depo_list
      if (depo_filter.includes(item)) {
        depo_filter = depo_filter.filter((d) => d !== item);
      } else {
        depo_filter.push(item);
      }
      //3. toko_list
      list = this.props.data.competitor_success_list_origin.filter((t) =>
        depo_filter.includes(t.Depo)
      );
    }

    config.is_hide_all_depo = is_hide_all_depo;
    config.depo_filter = depo_filter;

    this.props.set_config_menu("competitor_success", config);
    this.props.set_list({
      text: "competitor_success_list",
      list,
    });
  };

  render() {
    const { view_list_status } = this.state;

    let {
      competitor_success_list,
      competitor_success_list_origin,
      competitor_count,
    } = this.props.data;
    let percentage = 0;

    const list_menu_active_after = this.props.data.list_menu_active;

    const config = list_menu_active_after.find(
      (e) => e.key === "competitor_success"
    )?.config;
    let is_hide_all_depo = config?.is_hide_all_depo
      ? config?.is_hide_all_depo
      : false;

    if (competitor_count > 0 && competitor_success_list_origin.length > 0) {
      percentage = parseFloat(
        (competitor_success_list_origin.length / competitor_count) * 100
      ).toFixed(2);
    }

    let depo_list = [
      ...new Set(competitor_success_list_origin.map((t) => t.Depo)),
    ];

    depo_list = depo_list.map((t, idx) => {
      let index = idx;
      if (idx > st_colors.length - 1) {
        index = idx % st_colors.length;
      }
      const jumlah_toko = competitor_success_list_origin.filter(
        (toko) => toko?.Depo === t
      )?.length;
      const color = st_colors[index];

      return { text: t, color, jumlah_toko, value: t, label: t };
    });

    if (this.state.sort_value === "jumlah_toko") {
      depo_list = sort_array(depo_list, this.state.sort_value, false);
    } else {
      depo_list = sort_array(depo_list, this.state.sort_value, true);
    }

    const name_array_depo = depo_list.map((e) => e.text);
    const value_array_depo = depo_list.map((e) => e.jumlah_toko);
    const color_array_depo = depo_list.map((e) => e.color);

    const chart_data_depo = {
      labels: name_array_depo,
      datasets: [
        {
          label: "BU",
          data: value_array_depo,
          backgroundColor: color_array_depo,
          hoverOffset: 4,
        },
      ],
    };

    const options = {
      animation: false,
      maintainAspectRatio: true,
      responsive: true,
      title: {
        display: false,
        text: "BU",
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    let list_content = (
      <section>
        Click open to view outlet list, the list is closed by default to
        maintain good website performance.
      </section>
    );
    if (view_list_status) {
      list_content = (
        <main>
          <section className="text_bold">
            Total outlet after multiple filter above:{" "}
            {competitor_success_list.length}
          </section>
          <table className="table">
            <thead>
              <tr>
                <th>No</th>
                <th>Outlet name</th>
                <th>Segment</th>
                <th>Address</th>
                <th>PT</th>
              </tr>
            </thead>
            <tbody>
              {competitor_success_list.map((outlet, idx) => {
                //competitor_success
                let button_fly = (
                  <ON_FLY item={outlet} modal_item="competitor_success" />
                );
                if (!outlet?.location?.coordinates?.[0]) {
                  button_fly = (
                    <button className="button" id="red">
                      No location
                    </button>
                  );
                }
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>
                      {outlet?.["Nama Toko"]} <br />
                      {button_fly}
                    </td>
                    <td>{outlet?.["SEGMENT"]}</td>
                    <td>{outlet?.["ALAMAT"]}</td>
                    <td>{outlet?.["SYSTEM"]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      );
    }

    let final_content = (
      <main>
        <section className="main_container mb-5">
          <p className="text_inferior">Total all competitor outlets</p>
          <p className="text_bold">
            {new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(competitor_count)}
          </p>

          <br />
          <br />
          <p className="text_inferior">Total successfully acquired outlets</p>
          <p className="text_bold">
            {new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(competitor_success_list_origin.length)}
          </p>

          <p className="text_inferior">Percent</p>
          <p className="text_bold">{percentage} %</p>
        </section>

        <section>
          <h1 className="text_header">By Depo</h1>
          <p className="text_inferior">
            Only show {depo_list.length} Depo that has outlet acq.
          </p>
          <Select
            options={depo_list}
            isMulti
            onChange={this.on_change_select.bind(this)}
          />
          <section>
            <button
              className="button_pill mb-2"
              id={is_hide_all_depo ? "grey" : "green"}
              onClick={this.on_click.bind(
                this,
                is_hide_all_depo ? "all" : "not_all"
              )}
            >
              ✓
            </button>{" "}
            All
          </section>
          <section className="mb-5">
            <div style={{ width: "200px", margin: "auto" }}>
              <Chart type="pie" options={options} data={chart_data_depo} />
            </div>
          </section>
        </section>

        <CompetitorSuccessDownload />

        <section>
          <button
            className="button mb-3"
            onClick={this.toggle_view_list_status}
            id="green"
          >
            {view_list_status ? "Close Outlet List" : "Open Outlet List"}
          </button>
        </section>

        {list_content}
      </main>
    );
    let loading_content;
    if (competitor_success_list_origin.length === 0) {
      loading_content = (
        <main className="main_container mb-5">Loading... </main>
      );
    }
    return (
      <div style={{ marginBottom: "200px" }}>
        {final_content}
        {loading_content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, {
  get_all_submitted_competitor,
  get_competitor_count,
  turn_on_menu_status,
  set_config_menu,
  set_list,
})(COMPETITOR_SUCCESS_MENU);
