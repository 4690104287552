const mode = "online"; // online || offline

// const geoServer = "https://nabatiserver.mapid.io";
const geoServerLocal = "http://localhost:4000";
const nabatiServer1 = "https://outdigserver.nabatisnack.co.id";
const nabatiServer2 = "https://outdigserver2.nabatisnack.co.id";

let nabati = nabatiServer1;
let badge;
if (localStorage?.nabatiUser) {
  const user = JSON.parse(localStorage.nabatiUser);
  if (user?.badge) {
    badge = user.badge;
  }
}

if (badge === "super_admin") {
  nabati = nabatiServer2;
}

if (mode === "offline") {
  module.exports = {
    geoServerBaseUrl: geoServerLocal,
    nabatiServer2: geoServerLocal,
  };
} else if (mode === "nabati") {
  module.exports = {
    geoServerBaseUrl: nabati,
    nabatiServer2: nabatiServer2,
  };
} else {
  module.exports = {
    geoServerBaseUrl: nabati,
    nabatiServer2: nabatiServer2,
  };
}
