const format_tanggal_bulan_tahun = function (timestamp) {
  const date = new Date(timestamp);
  const months_ina = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agu",
    "Sep",
    "Okt",
    "Nov",
    "Des",
  ];
  const tanggal = date.getDate();
  let month_final = months_ina[date.getMonth()];
  let date_final = tanggal + " " + month_final + " " + date.getFullYear();
  if (date.getFullYear() === "1970") {
    return "-";
  }
  return date_final;
};

export default format_tanggal_bulan_tahun;
