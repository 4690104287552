import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";
// import format_jam_menit from "../../validation/format_jam_menit";
//Personal Component
//Redux function
//Picture Asset
//General Function

class DriverLogLayer extends Component {
  render() {
    const { route_mode } = this.props.data;
    const is_visible = this.props.data.list_menu_active.find(
      (e) => e.key === "driver"
    )?.active;
    let content;
    if (route_mode) {
      content = this.props.tracking.driver_geojsonLineString?.features?.length >
        0 && (
        <React.Fragment>
          <Source
            key="driver_dataLineString"
            id="driver_dataLineString"
            type="geojson"
            data={this.props.tracking.driver_geojsonLineString}
            generateId={true}
            cluster={false}
          />

          <Layer
            id="driver_lineString"
            key="driver_lineString"
            type="line"
            source="driver_dataLineString"
            before="hari"
            layout={{
              visibility: is_visible ? "visible" : "none",
            }}
            paint={{
              "line-color": "#DF4F4F",
              "line-width": 3,
              "line-opacity": 1,
            }}
            filter={["all", ["!=", "id", -1]]}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {this.props.tracking.driver_geojsonPoint?.features?.length > 0 && (
          <React.Fragment>
            <Source
              key="driver_dataPoint"
              id="driver_dataPoint"
              type="geojson"
              data={this.props.tracking.driver_geojsonPoint}
              generateId={true}
              cluster={false}
            />
            <Layer
              id="driver_Point"
              key="driver_Point"
              before="hari"
              source="driver_dataPoint"
              type="symbol"
              layout={{
                visibility: is_visible ? "visible" : "none",
                "icon-size": 0.25,
                "icon-padding": 1,
                "icon-anchor": "center",
                "icon-image": "truck",
                "text-field": ["get", "driver_name"],
                "text-anchor": "top",
                "text-offset": [0, 1.5],
                "text-line-height": 1,
                "icon-rotate": ["get", "rad"],
                "icon-allow-overlap": true,
                "icon-ignore-placement": true,
                "text-allow-overlap": true,
                "text-ignore-placement": true,
                "text-size": 14,
                "text-max-width": 32,
              }}
              paint={{
                "icon-color": "red",
                "icon-opacity": 1,
                "text-opacity": 1,
                "text-halo-color": "white",
                "text-halo-width": 1,
              }}
              onLeave={this.onLeave}
              // onClick={this.props.layerOnClick.bind(this, "modal_driver")}
            />
          </React.Fragment>
        )}
        {content}

        {this.props.tracking.driver_geojsonMultiPoint?.features?.length > 0 && (
          <React.Fragment>
            <Source
              key="driver_dataMultiPoint"
              id="driver_dataMultiPoint"
              type="geojson"
              data={this.props.tracking.driver_geojsonMultiPoint}
              generateId={true}
              cluster={false}
            />

            <Layer
              id="driver_MultiPoint"
              key="driver_MultiPoint"
              source="driver_dataMultiPoint"
              type="symbol"
              before="hari"
              layout={{
                visibility: is_visible ? "visible" : "none",
                "icon-image": "titik",
                "text-field": ["get", "simple_timestamp"],
                "text-font": ["Arial Unicode MS Bold"],
                "icon-size": 0.5,
                "icon-padding": 0,
                "icon-anchor": "center",
                "text-anchor": "top",
                "text-offset": [0, 1.5],
                "text-line-height": 1,
                "icon-allow-overlap": true,
                "icon-ignore-placement": true,
                "text-allow-overlap": true,
                "text-ignore-placement": true,
                "text-size": 14,
                "text-max-width": 32,
              }}
              paint={{
                "icon-color": "#DF4F4F",
                "icon-opacity": 1,
                "text-color": "black",
                "text-halo-color": "white",
                "text-halo-width": 2,
                "text-halo-blur": 1,
                "icon-halo-color": "black",
                "icon-halo-width": 2.2,
              }}
              onLeave={this.onLeave}
              // onClick={this.props.layerOnClick.bind(this, "modal_driver")}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
  tracking: state.tracking,
});
export default connect(mapStateToProps, {})(DriverLogLayer);
