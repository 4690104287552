//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
import TextFieldGroup from "../common/TextFieldGroup";
//Redux function
import { edit_depo_local } from "../../actions/authActions";
//Picture Asset
//General Function
class EditDepo extends Component {
  state = {
    depo: {},
    depo_longitude: 0,
    depo_latitude: 0,
    negara: "",
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const depo = nextProps.depo;
    let negara = depo.country;
    if (!negara) {
      negara = nextProps.auth.country_selected
    }
    if (depo.id !== prevState.depo.id) {
      return {
        depo_name: depo?.Depo,
        depo,
        depo_longitude: depo.location.coordinates[0],
        depo_latitude: depo.location.coordinates[1],
        negara
      };
    } else return null;
  }
  submit_edit_depo_local = () => {
    const { depo_name, depo_longitude, depo_latitude, depo } = this.state;
    const body = {
      id: depo.id,
      Depo: depo_name.toUpperCase(),
      location: {
        type: "Point",
        coordinates: [depo_longitude, depo_latitude],
      },
      CABANG: "",
      REGION: "",
      CABANG_id: "634f60685e2fdde524c3eb17",
      REGION_id: "634f602d5e2fdde524c3eb14",
      country: this.state.negara,
    };
    // console.log(depo);
    this.props.edit_depo_local(body);
    this.props.toggle_edit_depo();
  };
  on_change = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value });
  };
  render() {
    const { country_select } = this.props.auth;
    return (
      <div>
        <h1 className="text_bold">Edit Temporary Depo Data</h1>
        <TextFieldGroup
          type="text"
          placeholder="Depo name"
          name="depo_name"
          id="depo_name"
          value={this.state.depo_name}
          onChange={this.on_change}
          error=""
        />
        <TextFieldGroup
          type="text"
          placeholder="Latitude"
          name="depo_latitude"
          id="depo_lattude"
          value={this.state.depo_latitude}
          onChange={this.on_change}
          error=""
        />
        <TextFieldGroup
          type="text"
          placeholder="Longitude"
          name="depo_longitude"
          id="depo_longitude"
          value={this.state.depo_longitude}
          onChange={this.on_change}
          error=""
        />
        <label className="text_bold">Choose Country</label>
        <select
          className="w-full"
          name="negara"
          onChange={this.on_change}
          required
          value={this.state.negara}
        >
          <option value="">Choose Country</option>
          {country_select.map((e, i) => (
            <option title={e.label} key={i} value={e.value}>
              {e.label}
            </option>
          ))}
        </select>
        <button
          className="button"
          id="green"
          onClick={this.submit_edit_depo_local}
        >
          Submit
        </button>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  edit_depo_local,
})(EditDepo);
