import isEmpty from "../validation/is-empty";
import countrys from "./localData/countrys.json";
import job_positions from "./localData/job_positions.json";
import master_admin from "./localData/master_admin.json";

const initialState = {
  isAuthenticated: false,
  isRegistered: false,
  user: {},
  loading: false,
  item_loading: "",
  pathname: window.location.pathname,
  user_reset: {},
  user_list: [],
  back_up_user_list: [],
  country_select: countrys,
  country_selected: "INDONESIA",
  job_positions: job_positions,
  master_admin: master_admin,
};
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case "verify_reset_code":
      return {
        ...state,
        user_reset: action.payload,
      };
    case "generate_secret_reset_password":
      return {
        ...state,
        user_list: generate_secret_reset_password(
          state.user_list,
          action.payload
        ),
      };
    case "get_user_list":
      return {
        ...state,
        user_list: action.payload,
        back_up_user_list: action.payload,
      };

    // case "backup_get_user_list":
    //   return {
    //     ...state,
    //     user_list: searchAdmin(
    //       action.payload,
    //       state.user_list,
    //       state.back_up_user_list
    //     ),
    //   };
    // case "backup_value_get_user_list":
    //   return {
    //     ...state,
    //     user_list: searchValueAdmin(
    //       action.payload,
    //       state.user_list,
    //       state.back_up_user_list
    //     ),
    //   };
    case "backup_submit_get_user_list":
      return {
        ...state,
        user_list: submitValueAdmin(
          action.payload,
          state.user_list,
          state.back_up_user_list
        ),
      };

    case "SET_PATHNAME":
      return {
        ...state,
        pathname: action.payload,
      };
    case "AUTH_LOADING":
      return {
        ...state,
        item_loading: action.payload,
        loading: true,
      };
    case "CLEAR_AUTH_LOADING":
      return {
        ...state,
        loading: false,
      };
    case "SET_REGISTER_SUCCESS":
      return {
        ...state,
        isRegistered: true,
      };
    case "SET_CURRENT_USER":
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case "SET_COUNTRY":
      return { ...state, country_selected: action.payload };
    default:
      return state;
  }
}

const generate_secret_reset_password = (list, body) => {
  let list_slice = list.slice();
  const { _id, reset_code } = body;
  let item = list_slice.find((i) => i._id === _id);
  item.reset_code = reset_code;
  const item_index = list_slice.findIndex((i) => i._id === _id);
  list_slice.splice(item_index, 1, item);
  console.log(item);
  return list_slice;
};

// const searchAdmin = (query, user_list, back_up_user_list) => {
//   let result;
//   if (query !== "") {
//     result = user_list.filter((state) => {
//       const regex = new RegExp(`${query}`, "gi");
//       return state["full_name"].match(regex);
//       // &&
//       // state["depo"]?.Depo.match("BANDUNG BARAT")
//     });
//   } else {
//     result = back_up_user_list;
//   }
//   return result;
// };

// const searchValueAdmin = (query, user_list, back_up_user_list) => {
//   let result;
//   if (query !== "semua depo") {
//     result = back_up_user_list.filter((state) => {
//       const regex = new RegExp(`${query}`, "gi");
//       return state["depo"]?.Depo.match(regex);
//     });
//   } else {
//     result = back_up_user_list;
//   }
//   // console.log(result);
//   return result;
// };

const submitValueAdmin = (query, user_list, back_up_user_list) => {
  let result;
  back_up_user_list.forEach((e, i) => {
    // if (e.depo === null) {
    //   back_up_user_list[i].depo = { Depo: "" };
    // }
    if (!e.depo) {
      back_up_user_list[i].depo = { Depo: "" };
    }
    if (!e.jabatan) {
      back_up_user_list[i].jabatan = "";
    }
    if (!e.status) {
      back_up_user_list[i].status = "";
    }
    if (!e.role) {
      back_up_user_list[i].role = "";
    }
  });

  back_up_user_list.forEach((element, index) => {
    if (typeof element.depo !== "string") {
      back_up_user_list[index].depo = "";
    }
  });

  result = back_up_user_list.filter((state) => {
    const nama = new RegExp(`${query.nama}`, "gi");
    // const depo = new RegExp(`${query.depo}`, "gi");
    // const depo = query.depo;
    // const jabatan = new RegExp(`${query.jabatan}`, "gi");
    const jabatan = query.jabatan !== "semua" ? query.jabatan : "";
    const depo = new RegExp(`${query.depo}`, "gi");
    const status = new RegExp(`${query.status}`, "gi");
    const role = new RegExp(`${query.role}`, "gi");
    if (jabatan === "") {
      return (
        state["status_badge"]?.match(query.status !== "semua" ? status : "") &&
        state["badge"]?.match(query.role !== "semua" ? role : "") &&
        state["depo"]?.match(query.depo !== "semua" ? depo : "") &&
        state["full_name"].match(query.nama !== "" ? nama : "")
      );
    }
    return (
      state["depo"]?.match(query.depo !== "semua" ? depo : "") &&
      state["jabatan"] === jabatan &&
      state["status_badge"]?.match(query.status !== "semua" ? status : "") &&
      state["badge"]?.match(query.role !== "semua" ? role : "") &&
      state["full_name"].match(query.nama !== "" ? nama : "")
    );
  });
  return result;
};
