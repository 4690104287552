//Library modul
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import writeXlsxFile from "write-excel-file";

//Personal Component
import Modal from "../common/Modal";
import DownloadTrackingDriver from "../parent/DownloadTrackingDriver";

//Redux function

//Picture Asset

//General Function
import formatDateFormal from "../../validation/format_date_formal";

export class ToolDriver extends Component {
  state = {
    separator: ",",
    dataCSV: [],
    loading: false,
    state_modal_download_log_by_depo: false,
    downloadDaily: false,
    downloadTarget: false,
    downloadDriver: false,
    mode: "",
  };

  on_change = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({
      [name]: value,
    });
  };

  generateDataCSV = () => {
    const headerCSV = [
      "DRIVER",
      "HELPER",
      "LICENSE PLATE NUMBER",
      "DEPO",
      "CAR TYPE",
      "CARTON CAPACITY",
      "VOLUME CAPACITY",
      "STATUS",
    ];
    let bodyCSV = [];
    bodyCSV.push(headerCSV);

    const {
      // driver_daerah_kerja_value,
      driver_list,
      driver_list_with_location,
    } = this.props.tracking;

    let online = 0,
      offline = 0,
      online_few_hours = 0;

    driver_list.forEach((element) => {
      let status = "";
      if (element.status_bike === "bike_red") {
        status = "Offline";
        offline++;
      }
      if (element.status_bike === "bike_green") {
        status = "Online";
        online++;
      }
      if (element.status_bike === "bike_blue") {
        status = "Online a few hours ago";
        online_few_hours++;
      }

      bodyCSV.push([
        element.DRIVER,
        element.HELPER,
        element.NOPOL,
        element.Depo,
        element.JENIS,
        element.KAPASITAS_CTN,
        element.KAPASITAS_M3,
        status,
      ]);
    });

    bodyCSV.push(["Sum Driver", driver_list.length]);
    bodyCSV.push([
      "Sum Driver With Location",
      driver_list_with_location.length,
    ]);
    bodyCSV.push(["Online", online]);
    bodyCSV.push(["Online a few hours ago", online_few_hours]);
    bodyCSV.push(["Offline", offline]);

    this.setState({
      dataCSV: bodyCSV,
    });
  };

  generateDataEXCEL = async () => {
    const { driver_list, driver_list_with_location } = this.props.tracking;
    try {
      const schema = [
        {
          column: "Name",
          type: String,
          value: (driver) => driver.DRIVER,
        },
        {
          column: "Helper",
          type: String,
          value: (driver) => driver.HELPER,
        },
        {
          column: "License Plate Number",
          type: String,
          value: (driver) => driver.NOPOL,
        },
        {
          column: "Depo",
          type: String,
          value: (driver) => driver.Depo,
        },
        {
          column: "Car Type",
          type: String,
          value: (driver) => driver.JENIS,
        },
        {
          column: "Carton Capacity",
          type: String,
          value: (driver) => driver.KAPASITAS_CTN,
        },
        {
          column: "Volume Capacity",
          type: String,
          value: (driver) => driver.KAPASITAS_M3,
        },
        {
          column: "Status",
          type: String,
          value: (driver) => driver.status,
        },
      ];
      let newData = [];
      let online = 0,
        offline = 0,
        online_few_hours = 0;
      driver_list.forEach((e) => {
        let status = "";
        if (e.status_bike === "bike_red") {
          status = "Offline";
          offline++;
        }
        if (e.status_bike === "bike_green") {
          status = "Online";
          online++;
        }
        if (e.status_bike === "bike_blue") {
          status = "Online a few hours ago";
          online_few_hours++;
        }

        let getData = {
          DRIVER: e.DRIVER,
          HELPER: e.HELPER,
          NOPOL: e.NOPOL,
          Depo: e.Depo,
          JENIS: e.JENIS,
          KAPASITAS_CTN: e.KAPASITAS_CTN,
          KAPASITAS_M3: e.KAPASITAS_M3,
          status: status,
        };
        newData.push(getData);
      });
      newData = [
        ...newData,
        { DRIVER: `Sum Driver: ${driver_list.length}` },
        {
          DRIVER: `Sum Driver With Location: ${driver_list_with_location.length}`,
        },
        { DRIVER: `Online : ${online}` },
        { DRIVER: `Online a few hours ago: ${online_few_hours}` },
        { DRIVER: `Offline: ${offline}` },
      ];
      await writeXlsxFile(newData, {
        schema,
        fileName: "DataExcelDailyDriver.xlsx",
      });
    } catch (e) {
      console.log(e);
    }
  };

  generateDataDriverCSV = () => {
    this.setState({
      loading: true,
    });
    const headerCSV = [
      "_id",
      "DRIVER",
      "HELPER",
      "LICENSE PLATE NUMBER",
      "DEPO",
      "PLAN_ID",
      "CAR TYPE",
      "CARTON CAPACITY",
      "VOLUME CAPACITY",
      "COUNTRY",
    ];
    let bodyCSV = [];
    bodyCSV.push(headerCSV);
    const { driver_list } = this.props.tracking;
    driver_list.forEach((element) => {
      const plan = this.props.depos.find((e) => e.Depo === element.Depo);
      bodyCSV.push([
        element._id,
        element.DRIVER,
        element.HELPER,
        element.NOPOL,
        element.Depo,
        plan?.plan_id ?? "-",
        element.JENIS,
        element.KAPASITAS_CTN,
        element.KAPASITAS_M3,
        element.COUNTRY,
      ]);
    });
    console.log(bodyCSV);
    this.setState({
      dataCSV: bodyCSV,
      loading: false,
    });
  };

  generateDataDriverEXCEL = async () => {
    const { driver_list } = this.props.tracking;
    try {
      const schema = [
        {
          column: "_id",
          type: String,
          value: (driver) => driver._id,
        },
        {
          column: "DRIVER",
          type: String,
          value: (driver) => driver.DRIVER,
        },
        {
          column: "HELPER",
          type: String,
          value: (driver) => driver.HELPER,
        },
        {
          column: "LICENSE PLATE NUMBER",
          type: String,
          value: (driver) => driver.NOPOL,
        },
        {
          column: "DEPO",
          type: String,
          value: (driver) => driver.Depo,
        },
        {
          column: "PLAN_ID",
          type: String,
          value: (driver) => driver.plan_id,
        },
        {
          column: "CAR TYPE",
          type: String,
          value: (driver) => driver.JENIS,
        },
        {
          column: "CARTON CAPACITY",
          type: String,
          value: (driver) => driver.KAPASITAS_CTN,
        },
        {
          column: "VOLUME CAPACITY",
          type: String,
          value: (driver) => driver.KAPASITAS_M3,
        },
        {
          column: "COUNTRY",
          type: String,
          value: (driver) => driver.COUNTRY,
        },
      ];
      let newData = [];
      driver_list.forEach((e) => {
        const plan = this.props.depos.find((x) => x.Depo === e.Depo);
        let getData = {
          _id: e._id,
          DRIVER: e.DRIVER,
          HELPER: e.HELPER,
          NOPOL: e.NOPOL,
          Depo: e.Depo,
          plan_id: plan?.plan_id,
          JENIS: e.JENIS,
          KAPASITAS_CTN: e.KAPASITAS_CTN,
          KAPASITAS_M3: e.KAPASITAS_M3,
          COUNTRY: e.COUNTRY,
        };
        newData.push(getData);
      });
      await writeXlsxFile(newData, {
        schema,
        fileName: "DriverExcel.xlsx",
      });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      driver_daerah_kerja_value,
      driver_list,
      driver_list_with_location,
    } = this.props.tracking;

    const { separator, loading, dataCSV, state_modal_download_log_by_depo } =
      this.state;

    let bike_green = 0,
      bike_blue = 0,
      bike_red = 0;
    driver_list.forEach((e) => {
      if (e.status_bike === "bike_green") bike_green++;
      if (e.status_bike === "bike_blue") bike_blue++;
      if (e.status_bike === "bike_red") bike_red++;
    });

    const legenda_list = (
      <main className="text-xs">
        <section className="flex my-0.5">
          <div className={`bg-green-600 w-5 h-5 mr-0.5`}></div>
          <p>
            <span className="align-middle">Online: {bike_green}</span>
          </p>
        </section>
        <section className="flex my-0.5">
          <div className={`bg-blue-600 w-5 h-5 mr-0.5`}></div>
          <p>
            <span className="align-middle">
              Online a few hours ago: {bike_blue}
            </span>
          </p>
        </section>
        <section className="flex my-0.5">
          <div className={`bg-red-600 w-5 h-5 mr-0.5`}></div>
          <p>
            <span className="align-middle">Offline: {bike_red}</span>
          </p>
        </section>
      </main>
    );

    const modalDownloadDaily = this.state.downloadDaily && (
      <Modal
        modalSize="small"
        isOpen={this.state.downloadDaily}
        onClose={() =>
          this.setState({ downloadDaily: !this.state.downloadDaily })
        }
      >
        <div className="box-body">
          <h1 className="text-center font-bold">
            Download Daily Active by CSV
          </h1>
          <section className={`flex justify-center mb-3`}>
            <CSVLink
              className="button_main"
              data={dataCSV}
              filename={`Data Driver.csv`}
              onClick={(e, done) => {
                this.generateDataCSV({}, done);
              }}
              separator={separator}
            >
              {loading ? "Downloading data . . ." : "Download Daily CSV"}
            </CSVLink>
          </section>
          <h1 className="text-center font-bold">
            Download Daily Active by EXCEL
          </h1>
          <section className={`flex justify-center`}>
            <button
              className="button_main"
              onClick={(e, done) => {
                this.generateDataEXCEL({}, done);
              }}
            >
              {loading ? "Downloading data . . ." : "Download Daily EXCEL"}
            </button>
          </section>
        </div>
      </Modal>
    );

    const modalDownloadTarget = this.state.downloadTarget && (
      <Modal
        modalSize="small"
        isOpen={this.state.downloadTarget}
        onClose={() =>
          this.setState({ downloadTarget: !this.state.downloadTarget })
        }
      >
        <div className="box-body">
          <h1 className="text-center font-bold">
            Download Target vs Actual Working Hours by CSV
          </h1>
          <section className={`flex justify-center mb-3`}>
            <button
              className="button_main"
              onClick={() =>
                this.setState({
                  state_modal_download_log_by_depo: true,
                  mode: "csv",
                })
              }
            >
              Download Target vs Actual CSV
            </button>
          </section>
          <h1 className="text-center font-bold">
            Download Target vs Actual Working Hours by EXCEL
          </h1>
          <section className={`flex justify-center`}>
            <button
              className="button_main"
              onClick={() =>
                this.setState({
                  state_modal_download_log_by_depo: true,
                  mode: "excel",
                })
              }
            >
              Download Target vs Actual EXCEL
            </button>
          </section>
        </div>
      </Modal>
    );

    const modalDownloadDriver = this.state.downloadDriver && (
      <Modal
        modalSize="small"
        isOpen={this.state.downloadDriver}
        onClose={() =>
          this.setState({ downloadDriver: !this.state.downloadDriver })
        }
      >
        <div className="box-body">
          <h1 className="text-center font-bold">Download Driver CSV</h1>
          <section className={`flex justify-center mb-3`}>
            <CSVLink
              className="button_main"
              data={dataCSV}
              filename={"Driver.csv"}
              onClick={(e, done) => {
                this.generateDataDriverCSV({}, done);
              }}
            >
              {loading ? "Downloading data..." : "Download Driver CSV"}
            </CSVLink>
          </section>
          <h1 className="text-center font-bold">Download Driver EXCEL</h1>
          <section className={`flex justify-center mb-3`}>
            <button
              className="button_main"
              onClick={(e, done) => {
                this.generateDataDriverEXCEL({}, done);
              }}
            >
              {loading ? "Downloading data..." : "Download Driver EXCEL"}
            </button>
          </section>
        </div>
      </Modal>
    );
    // let downloadButton = "";
    // if (
    //   this.props.tracking.driver_daerah_kerja_value.label === "Active Driver" ||
    //   this.props.tracking.driver_daerah_kerja_value.label === "Online Driver"
    // ) {
    //   downloadButton = (
    //     <section className="text-center">
    //       Online Driver And Active Driver didn't have buttons download anymore
    //     </section>
    //   );
    // } else {
    //   downloadButton = (
    //     <>
    //       <section className="flex justify-center mb-3 mt-2">
    //         <button
    //           className="button_main"
    //           onClick={() => this.setState({ downloadDaily: true })}
    //         >
    //           Download Daily Active Status
    //         </button>
    //       </section>
    //       <section className="flex justify-center mb-3">
    //         <button
    //           className="button_main"
    //           onClick={() => this.setState({ downloadTarget: true })}
    //         >
    //           Download Target vs Actual Working Hours
    //         </button>
    //       </section>
    //       <section className="flex justify-center">
    //         <button
    //           className="button_main"
    //           onClick={() => this.setState({ downloadDriver: true })}
    //         >
    //           Download Driver
    //         </button>
    //       </section>
    //     </>
    //   );
    // }

    const driverLocationPercent =
      (driver_list_with_location.length / driver_list.length) * 100;
    const content = (
      <div className="mb-20">
        <section className="text-lg">
          <h4 className="text-2xl font-bold">
            {driver_daerah_kerja_value.label}
          </h4>
        </section>
        <section className="text-xs mb-7">
          Access Date: {formatDateFormal(Date.now())}
        </section>
        <section className="mb-5">
          <div>Total Driver</div>
          <div className="text-5xl">{driver_list.length}</div>
        </section>
        <section>
          <div className="text-s">Total existing driver with location</div>
          <div className="text-2xl">{driver_list_with_location.length}</div>
          <div
            className="w-full rounded-full h-1.5"
            style={{ backgroundColor: "#dedede" }}
          >
            <div
              className=" h-1.5 rounded-full"
              style={{
                width: `${driverLocationPercent}%`,
                backgroundColor: "#16a34aff",
              }}
            />
          </div>
        </section>
        <section className="mt-5">{legenda_list}</section>
        <>
          <section className="flex justify-center mb-3 mt-2">
            <button
              className="button_main"
              onClick={() => this.setState({ downloadDaily: true })}
            >
              Download Daily Active Status
            </button>
          </section>
          <section className="flex justify-center mb-3">
            <button
              className="button_main"
              onClick={() => this.setState({ downloadTarget: true })}
            >
              Download Target vs Actual Working Hours
            </button>
          </section>
          <section className="flex justify-center">
            <button
              className="button_main"
              onClick={() => this.setState({ downloadDriver: true })}
            >
              Download Driver
            </button>
          </section>
        </>
        {/* {downloadButton} */}
      </div>
    );

    const modal_download_log_by_depo = state_modal_download_log_by_depo && (
      <Modal
        modalSize="small"
        isOpen={state_modal_download_log_by_depo}
        onClose={() =>
          this.setState({ state_modal_download_log_by_depo: false })
        }
      >
        <div className="box-body">
          <DownloadTrackingDriver mode={this.state.mode} />
        </div>
      </Modal>
    );

    return (
      <div>
        {driver_daerah_kerja_value.value !== "Pilih"
          ? content
          : "Select Working Area Filter First"}
        {modal_download_log_by_depo}
        {modalDownloadDaily}
        {modalDownloadTarget}
        {modalDownloadDriver}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tracking: state.tracking,
  depos: state.data.salesman_filter.depo,
});

export default connect(mapStateToProps, {})(ToolDriver);
