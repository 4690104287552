import React, { Component } from "react";
import { connect } from "react-redux";
import Papa from "papaparse";
import {
  edit_salesman_bulk,
  upload_salesman_clear,
} from "../../actions/salesmanActions";
import { CSVLink } from "react-csv";
import readXlsxFile from "read-excel-file";
import writeXlsxFile from "write-excel-file";

const template_csv = [
  [
    "_id",
    "DISTRIBUTOR/DEPO",
    "NIK",
    "KODE SALESMAN",
    "KODE PLAN",
    "NAMA SALESMAN",
    "KONTAK HP",
    "DIVISI",
    "COUNTRY",
    "USERNAME",
  ],
  [
    "63292799e89f0083ac3fa5ff",
    "DEPO TEST",
    "12345678",
    "123456",
    "P000",
    "TEST SALESMAN",
    "081123123123",
    "TX1D1",
    "INDONESIA",
    "test_salesman",
  ],
];

const template_csv_with_password = [
  [
    "_id",
    "DISTRIBUTOR/DEPO",
    "NIK",
    "KODE SALESMAN",
    "KODE PLAN",
    "NAMA SALESMAN",
    "KONTAK HP",
    "DIVISI",
    "COUNTRY",
    "USERNAME",
    "PASSWORD",
  ],
  [
    "63292799e89f0083ac3fa5ff",
    "DEPO TEST",
    "12345678",
    "123456",
    "P000",
    "TEST SALESMAN",
    "081123123123",
    "TX1D1",
    "INDONESIA",
    "test_salesman",
    "password123",
  ],
];

export class UploadEditSalesman extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      file_header: [],
      file_body: [],
      mode_download: "",
      loadingExcel: false,
    };
  }
  handleFile = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  };
  processFile = async () => {
    this.on_reset_bulk_add_salesman();
    try {
      const file = this.state.file;
      const result = () => {
        return new Promise((resolve, reject) => {
          Papa.parse(file, {
            complete: function (results) {
              resolve(results);
            },
            error: function (err) {
              reject(err);
            },
          });
        });
      };
      const final_result = await result();
      let file_header = final_result.data.shift();
      file_header = file_header.map((el) => el.replace(/\s*$/, ""));
      const file_body = final_result.data;

      let arrOfObject = [];
      file_body.forEach((element) => {
        let obj = {};
        file_header.forEach((el, idx) => {
          const header = el.replace(/\s*$/, "");
          let value = element[idx];
          if (value === "#N/A") {
            value = "";
          }

          obj[header] = value;
        });
        if (file_header.length === element.length) {
          arrOfObject.push(obj);
        }
      });

      this.setState({
        file_header,
        file_body: arrOfObject,
      });
    } catch (error) {
      alert("Error process file!");
      console.log(error);
    }
  };
  on_change_download = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({
      [name]: value,
      file_body: [],
      file_header: [],
      is_checked: false,
    });
  };
  handleFileExcel = (event) => {
    this.setState({ loadingexcel: true });
    this.on_reset_bulk_add_salesman();
    try {
      readXlsxFile(event.target.files[0]).then((rows) => {
        const HEADER = rows.shift();
        const DATA = [];
        rows.forEach((element) => {
          let obj = {};
          HEADER.forEach((el, idx) => {
            const header = el.replace(/\s*$/, "");
            let value = element[idx];
            const value_test = String(value);
            if (value_test?.includes("#N/A") || !value_test || !value) {
              value = "";
            }
            obj[header] = value;
          });
          if (HEADER.length === element.length) {
            DATA.push(obj);
          }
        });
        this.setState({
          file_header: HEADER,
          file_body: DATA,
          loadingExcel: false,
        });
      });
    } catch (err) {
      alert("Error process file!");
    }
  };

  saveBulk = () => {
    let outlet_array = this.state.file_body;
    const body = {
      outlet_array,
    };
    this.props.edit_salesman_bulk(body);
  };

  on_reset_bulk_add_salesman = () => {
    this.props.upload_salesman_clear();
    this.setState({ file_header: [], file_body: [] });
  };

  downloadExampleExcel = async () => {
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });
      try {
        const schema = [
          {
            column: "_id",
            type: String,
            value: (target) => target._id,
          },
          {
            column: "DISTRIBUTOR/DEPO",
            type: String,
            value: (target) => target.depo,
          },
          {
            column: "NIK",
            type: String,
            value: (target) => target.nik,
          },
          {
            column: "KODE SALESMAN",
            type: String,
            value: (target) => target.kodeSalesman,
          },
          {
            column: "KODE PLAN",
            type: String,
            value: (target) => target.kodePlan,
          },
          {
            column: "NAMA SALESMAN",
            type: String,
            value: (target) => target.namaSalesman,
          },
          {
            column: "KONTAK HP",
            type: String,
            value: (target) => target.kontakHp,
          },
          {
            column: "DIVISI",
            type: String,
            value: (target) => target.divisi,
          },
          {
            column: "COUNTRY",
            type: String,
            value: (target) => target.country,
          },
          {
            column: "USERNAME",
            type: String,
            value: (target) => target.USERNAME,
          },
        ];
        let newData = [
          {
            _id: "63292799e89f0083ac3fa5ff",
            depo: "DEPO TEST",
            nik: "12345678",
            kodeSalesman: "123456",
            kodePlan: "P000",
            namaSalesman: "TEST SALESMAN",
            kontakHp: "081123123123",
            divisi: "TX1D1",
            country: "INDONESIA",
            USERNAME: "test_salesman",
          },
        ];
        await writeXlsxFile(newData, {
          schema,
          fileName: "ExampleUploadSalesman.xlsx",
        });
        this.setState({
          loading_csv: false,
        });
      } catch (error) {
        console.log(error);
        this.setState({
          loading: false,
        });
      }
    }
  };

  render() {
    const { file_header, file_body, mode_download } = this.state;

    const table_priview = (
      <div
        className="w-full overflow-auto n-scroll pb-2 pr-2"
        style={{ maxHeight: "500px" }}
      >
        <p className="text_bold mt-5">Data Preview</p>
        <table>
          <thead>
            <tr className="text-left bg-gray-300">
              <th>No</th>
              {file_header.map((el, idx) => (
                <th key={idx}>{el}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {file_body.slice(0, 50).map((el, idx) => {
              return (
                <tr key={idx} className={`even:bg-gray-50`}>
                  <td className="font-semibold">{idx + 1}</td>
                  {file_header.map((elHeader, idx) => (
                    <td key={idx}>{el[elHeader]}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );

    const syarat_header =
      mode_download === "csv_with_password"
        ? template_csv_with_password[0]
        : template_csv[0];
    const syarat_content = (
      <table className="table" style={{ fontSize: "11px" }}>
        <tbody>
          {syarat_header.map((e, idx) => {
            let status = (
              <td id="green" style={{ width: "70px", textAlign: "center" }}>
                V
              </td>
            );
            if (!file_header.includes(e))
              status = (
                <td id="red" style={{ width: "70px", textAlign: "center" }}>
                  X
                </td>
              );
            return (
              <tr key={idx}>
                <td>{e}</td>
                {status}
              </tr>
            );
          })}
        </tbody>
      </table>
    );

    const {
      upload_salesman_current,
      upload_salesman_success,
      upload_salesman_error,
    } = this.props.salesman;
    let percent = 0;
    if (file_body.length > 0) {
      percent = parseInt((upload_salesman_current / file_body.length) * 100);
    }

    const { loading_status, loading_item } = this.props.data;
    let button = (
      <button className={`button_main w-full mt-2`} onClick={this.saveBulk}>
        START UPLOAD EDIT OUTLETS BULK
      </button>
    );
    if (loading_status && loading_item === "edit_salesman_bulk") {
      button = (
        <button className={`button w-full mt-2`} id="green">
          Uploading...
        </button>
      );
    } else if (percent === 100) {
      button = (
        <section className="text_center">
          <button className={`button w-full mt-2 mb-2`} id="green">
            Uploaded ✔
          </button>
          <br />
          <button
            className="button"
            id="green"
            onClick={this.on_reset_bulk_add_salesman}
          >
            Upload more
          </button>
        </section>
      );
    }

    const progress_content = (
      <main className="main_container">
        <section
          className="mt-3"
          style={{
            height: "20px",
            backgroundColor: "#ffffff",
            borderRadius: "2rem",
            padding: "2px",
          }}
        >
          <div
            style={{
              height: "100%",
              width: `${percent}%`,
              backgroundColor: "#269d42ff",
              borderRadius: "2rem",
            }}
          />
        </section>
        <p className="text_inferior">{percent}%</p>
        <h1>
          Progress edit outlets: {upload_salesman_current}/{file_body.length}
        </h1>
        <h1>
          Success:
          {upload_salesman_success.length}
        </h1>
        <h1>
          Error:
          {upload_salesman_error.length}
          {upload_salesman_error.length > 0 && (
            <p>
              download error data{" "}
              <CSVLink
                className="text-blue-600"
                data={upload_salesman_error}
                filename="DATA ERROR UPLOAD MULTI SALESMAN.csv"
                separator=";"
              >
                here
              </CSVLink>
            </p>
          )}
        </h1>
      </main>
    );
    let renderData = <React.Fragment></React.Fragment>;
    if (mode_download === "excel") {
      renderData = (
        <React.Fragment>
          {/* {mode_download === "csv" ? (
            <section>
              <h1 className="text_inferior mb-5">Editing Salesman using _id</h1>
              <div className="border p-4 my-4 bg-yellow-100 rounded">
                Warning! for using this feature please follow the instructions
                according to the downloadable template{" "}
                <CSVLink
                  className="text-blue-600"
                  data={template_csv}
                  filename="EDIT MULTI SALESMAN TEMPLATE.csv"
                  separator=";"
                >
                  here
                </CSVLink>
              </div>
              <div className="grid grid-cols-1 gap-1">
                <label htmlFor="file">Select CSV file</label>
                <input
                  type="file"
                  id="file"
                  className="w-fit"
                  accept=".csv"
                  onChange={this.handleFile}
                />
              </div>
              <button className="button_main" onClick={this.processFile}>
                Processing File
              </button>
              <section className="mt-4">
                <p>
                  Number of Salesmans:{" "}
                  <b>
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(file_body.length)}
                  </b>
                </p>
              </section>
              {file_body.length !== 0 && (
                <main>
                  <p className="text_bold mt-2">Data Verification</p>
                  {syarat_content}
                </main>
              )}
              {file_body.length !== 0 && table_priview}

              {file_body.length !== 0 && <div>{button}</div>}
              {file_body.length !== 0 && progress_content}
            </section>
          ) : (
          )} */}
          <section>
            <h1 className="text_inferior mb-5">Editing Salesman using _id</h1>
            <div className="border p-4 my-4 bg-yellow-100 rounded">
              Warning! for using this feature please follow the instructions
              according to the downloadable template{" "}
              <button
                className="text-blue-600"
                onClick={this.downloadExampleExcel}
              >
                here
              </button>
            </div>
            <div className="grid grid-cols-1 gap-1">
              <label htmlFor="file">Select EXCEL file</label>
              <input
                type="file"
                className="w-fit"
                onChange={this.handleFileExcel}
              />
              {this.state.loadingExcel && (
                <p
                  className="text_bold bg-[#51B13D] rounded w-1/5 text-white text-center
                        "
                >
                  Loading Data...
                </p>
              )}
            </div>
            <section className="mt-4">
              <p>
                Number of Salesmans:{" "}
                <b>
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(file_body.length)}
                </b>
              </p>
            </section>
            {file_body.length !== 0 && (
              <main>
                <p className="text_bold mt-2">Data Verification</p>
                {syarat_content}
              </main>
            )}
            {file_body.length !== 0 && table_priview}

            {file_body.length !== 0 && <div>{button}</div>}
            {file_body.length !== 0 && progress_content}
          </section>
        </React.Fragment>
      );
    }
    if (mode_download === "csv") {
      renderData = (
        <React.Fragment>
          <section>
            <h1 className="text_inferior mb-5">Editing Salesman using _id</h1>
            <div className="border p-4 my-4 bg-yellow-100 rounded">
              Warning! for using this feature please follow the instructions
              according to the downloadable template{" "}
              <CSVLink
                className="text-blue-600"
                data={template_csv}
                filename="EDIT MULTI SALESMAN TEMPLATE.csv"
                separator=";"
              >
                here
              </CSVLink>
            </div>
            <div className="grid grid-cols-1 gap-1">
              <label htmlFor="file">Select CSV file</label>
              <input
                type="file"
                id="file"
                className="w-fit"
                accept=".csv"
                onChange={this.handleFile}
              />
            </div>
            <button className="button_main" onClick={this.processFile}>
              Processing File
            </button>
            <section className="mt-4">
              <p>
                Number of Salesmans:{" "}
                <b>
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(file_body.length)}
                </b>
              </p>
            </section>
            {file_body.length !== 0 && (
              <main>
                <p className="text_bold mt-2">Data Verification</p>
                {syarat_content}
              </main>
            )}
            {file_body.length !== 0 && table_priview}
            {file_body.length !== 0 && <div>{button}</div>}
            {file_body.length !== 0 && progress_content}
          </section>
        </React.Fragment>
      );
    }
    if (mode_download === "csv_with_password") {
      renderData = (
        <React.Fragment>
          <section>
            <h1 className="text_inferior mb-5">
              Editing Salesman using _id with Password
            </h1>
            <div className="border p-4 my-4 bg-yellow-100 rounded">
              Warning! for using this feature please follow the instructions
              according to the downloadable template{" "}
              <CSVLink
                className="text-blue-600"
                data={template_csv_with_password}
                filename="EDIT MULTI SALESMAN TEMPLATE WITH PASSWORD.csv"
                separator=";"
              >
                here
              </CSVLink>
            </div>
            <div className="grid grid-cols-1 gap-1">
              <label htmlFor="file">Select CSV file</label>
              <input
                type="file"
                id="file"
                className="w-fit"
                accept=".csv"
                onChange={this.handleFile}
              />
            </div>
            <button className="button_main" onClick={this.processFile}>
              Processing File
            </button>
            <section className="mt-4">
              <p>
                Number of Salesmans:{" "}
                <b>
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(file_body.length)}
                </b>
              </p>
            </section>
            {file_body.length !== 0 && (
              <main>
                <p className="text_bold mt-2">Data Verification</p>
                {syarat_content}
              </main>
            )}
            {file_body.length !== 0 && table_priview}

            {file_body.length !== 0 && <div>{button}</div>}
            {file_body.length !== 0 && progress_content}
          </section>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <>
          <h1 className="text_bold">Edit Multiple Salesman By Upload CSV</h1>
          <section className="main_container mb-3">
            <p className="text_bold">Select Mode Upload</p>
            <select
              name="mode_download"
              id="mode_download"
              value={mode_download}
              onChange={this.on_change_download}
            >
              <option value="pilih">Select</option>
              <option value="csv">CSV</option>
              <option value="excel">EXCEL</option>
              <option value="csv_with_password">CSV WITH PASSWORD</option>
            </select>
          </section>
        </>
        {renderData}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  salesman: state.tracking,
});

const mapDispatchToProps = {
  edit_salesman_bulk,
  upload_salesman_clear,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadEditSalesman);
