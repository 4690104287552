/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import writeXlsxFile from "write-excel-file";

/*PERSONAL COMPONENT*/
import ON_FLY from "../main/ON_FLY";

/*REDUX FUNCTION*/
import {
  get_toko_edited_by_apk,
  get_aggregate_outlet,
  turn_on_menu_status,
  turn_off_menu,
  popUpAction,
  set_loading,
  search_plotted,
} from "../../actions/dataActions";
import sort_array from "../../validation/sort_array";
import Spinner from "../parent/Spinner";

/*PICTURE ASSET*/

/*GENERAL*/

/*NON IMPORT*/

class OUTLET_SURVEY_MENU extends Component {
  state = {
    mode_detail: false,
    selectArea: "",
    selectedDepo: [],
    selectedBranch: [],
    selectedRegion: [],
    idx_c: 0,
    findSearch: "",
  };

  componentDidMount() {
    const { agg_list } = this.props.data;
    if (agg_list.length === 0) {
      this.on_get_aggregate_outlet();
    }
  }

  on_get_aggregate_outlet = () => {
    let body = {
      object_query: {
        COUNTRY: "INDONESIA",
      },
      param_agg: "location_status",
    };

    this.props.get_aggregate_outlet(body);
  };

  toggle_visible = () => {
    const is_visible = this.props.data.list_menu_active.find(
      (e) => e.key === "toko_plot"
    )?.active;
    if (is_visible) {
      this.props.turn_off_menu("toko_plot");
    } else {
      this.props.turn_on_menu_status("toko_plot");
    }
  };

  toggle_detail = () => {
    this.setState({ mode_detail: !this.state.mode_detail });
  };

  handle_change = (event) => {
    this.setState({ selectArea: event.value });
  };

  handle_change_depo = (event) => {
    let extract = event.map((e) => e.value);
    this.setState({ selectedDepo: extract });
  };

  handle_change_branch = (event) => {
    let extract = event.map((e) => e.value);
    this.setState({ selectedBranch: extract });
  };

  handle_change_region = (event) => {
    let extract = event.map((e) => e.value);
    this.setState({ selectedRegion: extract });
  };

  generateData = (event) => {
    event.preventDefault();
    let result_arr = [];
    const { salesman_filter } = this.props.data;
    if (this.state.selectArea === "Depo") {
      result_arr = this.state.selectedDepo;
    }
    if (this.state.selectArea === "Branch") {
      this.state.selectedBranch.forEach((e) => {
        let result = salesman_filter.depo.filter((state) => state.CABANG === e);
        result = result.map((x) => x.plan_id);
        result_arr = result_arr.concat(result);
      });
    }
    if (this.state.selectArea === "Region") {
      this.state.selectedRegion.forEach((e) => {
        let result = salesman_filter.depo.filter((state) => state.REGION === e);
        result = result.map((x) => x.plan_id);
        result_arr = result_arr.concat(result);
      });
    }
    if (this.state.selectArea === "Nasional") {
      for (let i = 0; i < salesman_filter.depo.length; i++) {
        const element = salesman_filter.depo[i];
        if (element.plan_id !== undefined) {
          result_arr = result_arr.concat(element.plan_id);
        }
      }
    }
    this.props.get_toko_edited_by_apk(result_arr);
    this.props.turn_on_menu_status("toko_plot");
    // this.props.set_loading();
  };

  generateDownloadDepoEXCEL = async () => {
    const schema = [
      {
        column: "_id",
        type: String,
        value: (target) => target._id,
      },
      {
        column: "STORE NAME",
        type: String,
        value: (target) => target.namaToko,
      },
      {
        column: "PLAN",
        type: String,
        value: (target) => target.plan,
      },
      {
        column: "ID Outlet",
        type: String,
        value: (target) => target.idOutlet,
      },
      {
        column: "ID SFA",
        type: String,
        value: (target) => target.idSfa,
      },
      {
        column: "TELEPHONE NUMBER",
        type: String,
        value: (target) => target.noTelepon,
      },
      {
        column: "DEPO",
        type: String,
        value: (target) => target.depo,
      },
      {
        column: "ADDRESS",
        type: String,
        value: (target) => target.alamat,
      },
      {
        column: "STATUS OUTLET",
        type: String,
        value: (target) => target.status,
      },
      // {
      //   column: "ALAMAT LAIN",
      //   type: String,
      //   value: (target) => target.alamatLain,
      // },
      // {
      //   column: "KABUPATEN",
      //   type: String,
      //   value: (target) => target.kabupaten,
      // },
      // {
      //   column: "KECAMATAN",
      //   type: String,
      //   value: (target) => target.kecamatan,
      // },
      // {
      //   column: "KELURAHAN",
      //   type: String,
      //   value: (target) => target.kelurahan,
      // },
      {
        column: "UPDATE DESCRIPTION",
        type: String,
        value: (target) => target.ketUpdate,
      },
      {
        column: "LOCATION",
        type: String,
        value: (target) => target.location,
      },
    ];
    let newData = [];
    const { salesman_filter } = this.props.data;
    this.props.data.toko_plotting.forEach((e) => {
      const { user, master_admin } = this.props.auth;
      let location = "";
      if (e?.location?.coordinates?.[0] !== 0) {
        const long = e.location.coordinates[0];
        const lat = e.location.coordinates[1];
        location = `${lat},${long}`;
      }
      if (master_admin.filter((e) => e._id === user._id).length <= 0) {
        if (e?.location?.coordinates?.[0] !== 0) {
          location = "YES";
        } else {
          location = "NO";
        }
      }
      let newDepo = salesman_filter.depo.find((x) => x.plan_id === e.Plan);
      let getData;
      if (location !== "") {
        getData = {
          _id: e._id,
          namaToko: e["Nama Toko"],
          noTelepon: e["NO Tlpon"],
          depo: newDepo?.Depo,
          plan: e.Plan,
          idOutlet: e["ID Outlet"],
          idSfa: e["ID SFA"],
          alamat: e.ALAMAT,
          status: e["Status Outlet"],
          // alamatLain: e.ALAMATLAIN,
          // kabupaten: e.KABUPATEN,
          // kecamatan: e.KECAMATAN,
          // kelurahan: e.KELURAHAN,
          ketUpdate: e.location_status,
          location: location,
        };
      }
      if (getData !== undefined) {
        newData.push(getData);
      }
    });
    await writeXlsxFile(newData, {
      schema,
      fileName: "DataExcelTokoPlottingDepo.xlsx",
    });
  };
  search = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  findSearch = (event) => {
    event.preventDefault();
    const { findSearch } = this.state;
    this.props.search_plotted(findSearch);
  };
  render() {
    //local storage

    //local state
    const { mode_detail, selectArea } = this.state;

    //global props
    const { agg_list, loading_item, loading_status, toko_plotting } =
      this.props.data;
    const is_visible = this.props.data.list_menu_active.find(
      (e) => e.key === "toko_plot"
    )?.active;

    //content
    const button_visible = (
      <button className="button_main" onClick={this.toggle_visible}>
        {is_visible ? "Hide point" : "Show point"}
      </button>
    );

    let button_detail = (
      <button className="button_main" onClick={this.toggle_detail}>
        {mode_detail ? "Hide outlet list" : "Show outlet list"}
      </button>
    );

    let searchBar = "";
    if (mode_detail) {
      searchBar = (
        <form onSubmit={this.findSearch}>
          <div className="flex gap-1">
            <div className="w-full my-2">
              <input
                type="text"
                placeholder={`Search store name`}
                className="w-full"
                name="findSearch"
                onChange={this.search}
                autoComplete="off"
              />
            </div>
            <button className="px-1" type="sumbit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </button>
          </div>
        </form>
      );
    }

    let content;
    if (toko_plotting.length > 0) {
      if (loading_status && loading_item === "get_toko_edited_by_apk") {
        content = (
          <main className="main_container text_center">
            <Spinner />
            <p>Load the resulted store plotting by salesman via APK..</p>
          </main>
        );
      } else {
        let depo_unik = toko_plotting.map((t) => t.Plan);
        depo_unik = [...new Set(depo_unik)];
        depo_unik = depo_unik.map((d) => {
          const toko_depo = toko_plotting.filter((t) => t.Plan === d);
          let newToko = [];
          toko_depo.forEach((e) => {
            if (e.location.coordinates[0] !== 0) {
              newToko.push(e);
            }
          });
          // let findPlan = toko_plotting.find((t) => t.Plan === d);
          // let depoName = "";
          // if (findPlan) {
          //   depoName = toko_plotting.Depo;
          // }
          const { salesman_filter } = this.props.data;
          let newDepo = salesman_filter.depo.find((x) => x.plan_id === d);
          return {
            depo: newDepo.Depo,
            newToko,
            toko_length: newToko,
          };
        });
        depo_unik = sort_array(depo_unik, "toko_length", false);
        const depo_content = depo_unik.map(({ depo, newToko }, idx) => {
          let detail_toko = null;
          if (mode_detail) {
            detail_toko = (
              <table className="table_lite">
                <tbody>
                  {newToko.map((toko, idx) => {
                    let button_fly = (
                      <ON_FLY item={toko} modal_item="modal_plotting" />
                    );
                    if (!toko?.location?.coordinates?.[0]) {
                      button_fly = (
                        <button className="button" id="red">
                          No Location
                        </button>
                      );
                    }
                    return (
                      <tr key={idx} className="mb-5">
                        <td>
                          {toko.images.map((i, idx) => {
                            return (
                              <img
                                key={idx}
                                alt="Nabati mapid"
                                src={i.Location}
                                style={{ width: "200px", height: "auto" }}
                                className="mb-3"
                              />
                            );
                          })}
                        </td>
                        <td>
                          <p className="text_medium mb-2">
                            {toko["Nama Toko"]}
                          </p>
                          <p className="text_inferior">
                            {toko["ALAMAT"]}, {toko["KELURAHAN"]},{" "}
                            {toko["KECAMATAN"]}, {toko["KABUPATEN"]},{" "}
                            {toko["PROPINSI"]}
                          </p>
                          <p className="text_inferior">
                            Outlet Type: {toko["Type Outlet"]}
                          </p>
                          {button_fly}
                          {toko?.revisi?.status_revisi && (
                            <div className="border p-4 my-2 bg-yellow-100 rounded">
                              Store <b>{toko["Nama Toko"]}</b> is doing
                              revision/unplot for{" "}
                              <b>{toko?.revisi?.message_revisi}</b>.
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          }
          return (
            <main key={idx} className="main_container mb-5">
              <p className="text_bold">{depo}</p>
              <p className="text_medium">
                Plotted with filter location: <b>{newToko.length}</b>
              </p>
              {detail_toko}
            </main>
          );
        });
        content = (
          <main>
            <button
              onClick={() => {
                this.generateDownloadDepoEXCEL();
              }}
              className="button_main mb-5"
            >
              Download OUTLET_SURVEY_MENU List EXCEL
            </button>
            <section className="main_container mb-10">
              <p className="text_bold">National</p>
              <p>
                Plotted total store via apk: <b>{toko_plotting.length}</b>
              </p>
              {button_detail}
            </section>
            {button_visible}
            {searchBar}
            <p>Distributed by depo:</p>
            {depo_content}
          </main>
        );
      }
    }

    const area_list = [
      { key: "depo", label: "Depo", value: "Depo", db: "Depo", name: "area" },
      {
        key: "branch",
        label: "Branch",
        value: "Branch",
        db: "CABANG",
        name: "area",
      },
      {
        key: "regional",
        label: "Region",
        value: "Region",
        db: "REGIONAL",
        name: "area",
      },
      {
        key: "nasional",
        label: "Nasional",
        value: "Nasional",
        db: "NASIONAL",
        name: "area",
      },
    ];

    let newDepo = [];
    let newBranch = [];
    let newRegion = [];

    this.props.data.salesman_filter.depo.map((e) => {
      let newData = { label: e.Depo, value: e.plan_id };
      return newDepo.push(newData);
    });
    this.props.data.salesman_filter.cabang.map((e) => {
      let newData = { label: e.CABANG, value: e.CABANG };
      return newBranch.push(newData);
    });
    this.props.data.salesman_filter.regional.map((e) => {
      let newData = { label: e.REGIONAL, value: e.REGIONAL };
      return newRegion.push(newData);
    });
    let select_content;

    if (selectArea === "Depo") {
      select_content = (
        <section className="mb-4">
          <p>
            Select {""}
            {selectArea === "" ? "Detail Area" : selectArea}
          </p>
          <Select
            options={newDepo}
            isMulti
            onChange={this.handle_change_depo}
          />
        </section>
      );
    }

    if (selectArea === "Branch") {
      select_content = (
        <section className="mb-4">
          <p>
            Select {""}
            {selectArea === "" ? "Detail Area" : selectArea}
          </p>
          <Select
            options={newBranch}
            isMulti
            onChange={this.handle_change_branch}
          />
        </section>
      );
    }

    if (selectArea === "Region") {
      select_content = (
        <section className="mb-4">
          <p>
            Select {""}
            {selectArea === "" ? "Detail Area" : selectArea}
          </p>
          <Select
            options={newRegion}
            isMulti
            onChange={this.handle_change_region}
          />
        </section>
      );
    }

    const { idx_c } = this.state;
    const agg_object = agg_list[idx_c];
    let result = agg_object?.result ? agg_object.result : [];
    const array_no_location = result.filter((item) =>
      ["no_location", "revisi", null, "via_integrasi"].includes(item._id)
    );

    const sum_no_location = array_no_location
      .map((item) => item?.SUM || 0)
      .reduce((accumulator, current_value) => accumulator + current_value, 0);

    const array_with_location = result.filter(
      (item) =>
        !["no_location", "revisi", null, "via_integrasi"].includes(item._id)
    );

    const sum_with_location = array_with_location
      .map((item) => item?.SUM || 0)
      .reduce((accumulator, current_value) => accumulator + current_value, 0);

    const list_content = (
      <main>
        <h1 className="text_bold">
          Summary of Nabati outlet location status
          <br />
          (SAP S/4Hana & SCYLLA)
        </h1>
        <br />
        <hr />
        <br />
        {result.length === 0 ? (
          <p className="container_light">Loading aggregate data...</p>
        ) : (
          <section>
            <h1 className="text_bold text_left">Outlet with no location</h1>
            <section className="text_left">
              {array_no_location.map((r, idx) => {
                let newName = r?._id;
                if (r?._id === "no_location") {
                  newName = "Has no location coordinates";
                }
                if (r?._id === "revisi") {
                  newName = "Marked for REVISION";
                }
                if (r?._id === "via_manual") {
                  newName = "Added before the upload feature";
                }
                if (r?._id === "via_salesman") {
                  newName = "Location inputted by salesman via APK";
                }
                if (r?._id === "via_upload_add") {
                  newName = "Location from ADDED store via admin upload";
                }
                if (r?._id === "via_upload_edit") {
                  newName = "Location from EDITED store via admin upload";
                }
                if (r?._id === "via_integrasi") {
                  newName = "Outlet added via integration";
                }
                if (r?._id === null) {
                  newName = "Has no location status";
                }
                return (
                  <main
                    key={idx}
                    style={{
                      width: "200px",
                      height: "80px",
                      display: "inline-block",
                      verticalAlign: "top",
                      textAlign: "left",
                      marginBottom: "20px",
                    }}
                    className="mt-1 mb-1 ml-1 mr-1"
                  >
                    <main className="main_container" style={{ height: "100%" }}>
                      <h1 className="text_inferior">
                        {newName ? newName : "-"}
                      </h1>
                      <p className="text_bold">
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(r?.SUM)}
                      </p>
                    </main>
                  </main>
                );
              })}
              <p className="text_bold">
                Total outlet without location:{" "}
                {new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(sum_no_location)}
              </p>
            </section>
            <br />
            <hr />
            <br />
            <h1 className="text_bold text_left">Outlet with location</h1>
            <section className="text_left">
              {array_with_location.map((r, idx) => {
                let newName = r?._id;
                if (r?._id === "no_location") {
                  newName = "Has no location coordinates";
                }
                if (r?._id === "revisi") {
                  newName = "Marked for REVISION";
                }
                if (r?._id === "via_manual") {
                  newName = "Added before the upload feature";
                }
                if (r?._id === "via_salesman") {
                  newName = "Location inputted by salesman via APK";
                }
                if (r?._id === "via_upload_add") {
                  newName = "Location from ADD store via admin upload";
                }
                if (r?._id === "via_upload_edit") {
                  newName = "Location from EDIT store via admin upload";
                }
                if (r?._id === "via_integrasi") {
                  newName = "Location from ADD store via integration";
                }
                if (r?._id === null) {
                  newName = "Has no location status";
                }
                return (
                  <main
                    key={idx}
                    style={{
                      width: "200px",
                      height: "80px",
                      display: "inline-block",
                      verticalAlign: "top",
                      textAlign: "left",
                      marginBottom: "20px",
                    }}
                    className="mt-1 mb-1 ml-1 mr-1"
                  >
                    <main className="main_container" style={{ height: "100%" }}>
                      <h1 className="text_inferior">
                        {newName ? newName : "-"}
                      </h1>
                      <p className="text_bold">
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(r?.SUM)}
                      </p>
                    </main>
                  </main>
                );
              })}
              <p className="text_bold">
                Total outlet with location:{" "}
                {new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(sum_with_location)}
              </p>
            </section>
          </section>
        )}
        <br />
        <hr />
        <br />
      </main>
    );

    return (
      <main style={{ marginBottom: "200px" }}>
        <form className="mb-4" onSubmit={this.generateData}>
          <section className="mb-2">
            <p>Select Depo/Branch/Region</p>
            <Select options={area_list} onChange={this.handle_change} />
          </section>
          {select_content}
          {loading_status && loading_item === "get_toko_edited_by_apk" ? (
            <div className="button background_green">Loading...</div>
          ) : (
            <button className="button background_green" type="submit">
              Find
            </button>
          )}
        </form>
        {list_content}
        {content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  get_toko_edited_by_apk,
  turn_on_menu_status,
  turn_off_menu,
  popUpAction,
  set_loading,
  get_aggregate_outlet,
  search_plotted,
})(OUTLET_SURVEY_MENU);
