/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import ProgressBar from "../common/ProgressBar";

/*REDUX FUNCTION*/
import { set_value_properties } from "../../actions/propertiesActions";
import { edit_cycle_outlet } from "../../actions/rayonActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import calc_distance from "../../validation/calc_distance";
import sort_array from "../../validation/sort_array";

/*NON IMPORT*/

class STEP_4_2_EXPAND_CYCLE extends Component {
  state = {};

  on_expand_cycle = () => {
    this.props.set_value_properties({
      key: "cycle_body_api",
      value: [],
    });

    const { outlet_list_filter_0 } = this.props.data;
    const { rayon_select, rayon_list_database } = this.props.data;
    const outlet_without_cycle = outlet_list_filter_0.filter((t) => !t.cycle);

    let rayon_object = {};
    if (rayon_list_database.length > 0) {
      rayon_object = rayon_list_database.find((r) => r._id === rayon_select);
    }
    const cycle_list = rayon_object?.cycle_list ? rayon_object?.cycle_list : [];

    let cycle_body_api = cycle_list.map((item) => {
      item.outlet_id_array = [];
      return item;
    });

    const total = outlet_without_cycle.length;
    const limit = 20;
    const iteration = Math.ceil(total / limit);
    let array_loop = [];
    for (let i = 0; i < iteration; i++) {
      array_loop.push({
        start: i * limit,
        end: i * limit + limit,
      });
    }
    const delay_c = 0;
    const delay = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function = () => {
      return array_loop.reduce(
        (last_promise, object, index) =>
          last_promise.then((result_sum) =>
            child_function(object, index).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object, index) => {
      return delay().then(async () => {
        try {
          const { start, end } = object;
          const outlet_list = outlet_without_cycle.slice(start, end);
          outlet_list.forEach((outlet) => {
            //looping outlet, ukur jarak dari setiap titik rayon, ambil yang terdekat, simpan rayon_id ke dalam atribut outlet
            const long_1 = outlet.location.coordinates[0];
            const lat_1 = outlet.location.coordinates[1];
            let cycle_list_test = cycle_list.map((rayon) => {
              const long_2 = rayon.location.coordinates[0];
              const lat_2 = rayon.location.coordinates[1];
              const distance = calc_distance(lat_1, long_1, lat_2, long_2);
              rayon.distance = distance;
              return rayon;
            });
            cycle_list_test = sort_array(cycle_list_test, "distance", true);
            const item_nearest_name = cycle_list_test[0].name;
            const item_nearest_index = cycle_body_api.findIndex(
              (item) => item.name === item_nearest_name
            );
            cycle_body_api[item_nearest_index].outlet_id_array.push(outlet._id);
          });
        } catch (error) {}
      });
    };
    parent_function().then(() => {
      cycle_body_api = cycle_body_api.filter(
        (item) => item.outlet_id_array.length > 0
      );
      const body = {
        cycle_list: cycle_body_api,
      };
      this.props.edit_cycle_outlet(body);

      this.props.set_value_properties({
        key: "cycle_body_api",
        value: cycle_body_api,
      });
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const { outlet_list_filter_0, outlet_list_filter_0_back_up, rayon_select } =
      this.props.data;
    const { loading_status, loading_item, cycle_body_api } =
      this.props.properties;

    //content
    const outlet_without_cycle = outlet_list_filter_0.filter((t) => !t.cycle);

    let button_content;
    let result_content;

    if (!rayon_select) {
      button_content = (
        <>
          <div className="button" id="grey">
            Run cycle expansion
          </div>
          <p className="text_small">Please select rayon first.</p>
        </>
      );
    } else if (outlet_list_filter_0_back_up.length === 0) {
      button_content = (
        <>
          <div className="button" id="grey">
            Run cycle expansion
          </div>
          <p className="text_small">Please load outlet data first.</p>
        </>
      );
    } else if (outlet_without_cycle.length === 0) {
      button_content = (
        <>
          <div className="button" id="grey">
            Run cycle expansion
          </div>
          <p className="text_small">All outlets already have cycle.</p>
        </>
      );
    } else if (loading_status && loading_item === "edit_cycle_outlet") {
      button_content = (
        <>
          <div className="button" id="grey">
            Saving...
          </div>
          <ProgressBar current_number={0} total_number={100} />
        </>
      );
    } else {
      button_content = (
        <>
          <button className="button" id="green" onClick={this.on_expand_cycle}>
            Run cycle expansion
          </button>
        </>
      );
    }

    if (cycle_body_api.length > 0) {
      result_content = (
        <section className="mt-3">
          <p className="text_small">Result summary:</p>
          <table className="table full_width">
            <thead>
              <tr>
                <th>No</th>
                <th>Cycle</th>
                <th>New outlet</th>
              </tr>
            </thead>
            <tbody>
              {cycle_body_api.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.outlet_id_array.length}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      );
    }

    return (
      <main>
        {button_content}
        {result_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.data,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_properties,
  edit_cycle_outlet,
})(STEP_4_2_EXPAND_CYCLE);
