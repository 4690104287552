/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_config_mode_menu } from "../../actions/dataActions";
import {
  hide_filter_array,
  show_filter_array,
  hide_all_filter_array,
  show_all_filter_array,
} from "../../actions/filter_outlet_actions";

/*PICTURE ASSET*/

/*GENERAL*/

class FilterCompetitor extends Component {
  state = {
    render_content: null,
  };

  toggle_filter_array = (array_name, element_name, is_hide) => {
    const body = { array_name, element_name };
    if (is_hide) {
      this.props.show_filter_array(body);
    } else {
      this.props.hide_filter_array(body);
    }
  };

  on_hide_all_filter_array = () => {
    this.props.hide_all_filter_array();
  };

  on_show_all_filter_array = (array_name) => {
    this.props.show_all_filter_array({ array_name });
  };

  on_set_config_mode_menu = (mode) => {
    this.props.set_config_mode_menu("distribution", mode);
    this.props.set_config_mode_menu("outlet_by_salesman", mode);
  };

  on_change = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value });
    if (name === "filter_mode") {
      localStorage.setItem("filter_mode", value);
    }
  };

  render_data = () => {
    //local storage

    //local state

    //global props
    const {
      depo_filter_array,
      system_filter_array,
      segment_filter_array,
      type_filter_array,
      list_menu_active,
      rayon_filter_array,
      day_filter_array,
      cycle_filter_array,
      provinsi_filter_array,
      kabupaten_filter_array,
      kecamatan_filter_array,
      kelurahan_filter_array,
    } = this.props.data;

    //content
    const menu_status = list_menu_active.find((e) => e.key === "distribution");
    const mode = menu_status?.config?.mode;
    const main_array = [
      {
        name: "DEPO",
        key: "depo_filter_array",
        all_array: depo_filter_array,
      },
      {
        name: "SYSTEM",
        key: "system_filter_array",
        all_array: system_filter_array,
      },
      {
        name: "SEGMENT",
        key: "segment_filter_array",
        all_array: segment_filter_array,
      },
      {
        name: "TYPE",
        key: "type_filter_array",
        all_array: type_filter_array,
      },
      {
        name: "RAYON",
        key: "rayon_filter_array",
        all_array: rayon_filter_array,
      },

      {
        name: "DAY",
        key: "day_filter_array",
        all_array: day_filter_array,
      },
      {
        name: "CYCLE",
        key: "cycle_filter_array",
        all_array: cycle_filter_array,
      },
      {
        name: "PROVINSI",
        key: "provinsi_filter_array",
        all_array: provinsi_filter_array,
      },
      {
        name: "KABUPATEN",
        key: "kabupaten_filter_array",
        all_array: kabupaten_filter_array,
      },
      {
        name: "KECAMATAN",
        key: "kecamatan_filter_array",
        all_array: kecamatan_filter_array,
      },
      {
        name: "KELURAHAN",
        key: "kelurahan_filter_array",
        all_array: kelurahan_filter_array,
      },
    ];

    const render_content = (
      <>
        {main_array.map((item, idx) => {
          return (
            <main key={idx} className="mb-10">
              <h1 className="text_bold">{item.name}</h1>
              <button
                className="button_small mr-1 mb-1"
                id="green"
                onClick={this.on_show_all_filter_array.bind(this, item.key)}
              >
                Show all
              </button>
              <button
                className="button_small mr-1 mb-1"
                id="green"
                onClick={this.on_hide_all_filter_array}
              >
                Hide all
              </button>
              <button
                className="button_small"
                id={mode === item.key ? "green" : "grey"}
                onClick={this.on_set_config_mode_menu.bind(this, item.key)}
              >
                Apply color
              </button>
              {/* <input /> */}
              <section
                style={{ maxHeight: "300px", overflowY: "auto" }}
                className="container_light"
              >
                <table className="table full_width">
                  <tbody>
                    {item.all_array.map((element, idx) => {
                      return (
                        <tr key={idx}>
                          <td style={{ width: "30px" }}>
                            <button
                              className="button_pill"
                              id={element?.is_hide ? "grey" : "green"}
                              onClick={this.toggle_filter_array.bind(
                                this,
                                item.key,
                                element.name,
                                element.is_hide
                              )}
                            >
                              v
                            </button>
                          </td>
                          <td style={{ width: "30px" }}>
                            <button
                              className="button_pill"
                              style={{ backgroundColor: element.color }}
                              onClick={this.toggle_filter_array.bind(
                                this,
                                item.key,
                                element.name,
                                element.is_hide
                              )}
                            >
                              {" "}
                            </button>
                          </td>
                          <td>{element.name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </section>
            </main>
          );
        })}
      </>
    );

    this.setState({ render_content });
  };

  componentDidMount() {
    this.render_data();
  }

  //hanya memantau perubahan props saja
  componentDidUpdate(prevProps) {
    const status_action_after = this.props.map.status_action;
    const status_action_before = prevProps.map.status_action;
    if (status_action_after !== status_action_before) {
      this.render_data();
    }
  }

  render() {
    const { render_content } = this.state;

    return (
      <main className="absolute inset-y-0 right-11 w-[400px] h-full bg-white py-5 pl-5 pr-11 z-[2] overflow-y-auto">
        <h1 className="text_header">OUTLET FILTER</h1>
        {render_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
});

export default connect(mapStateToProps, {
  set_config_mode_menu,
  hide_filter_array,
  show_filter_array,
  hide_all_filter_array,
  show_all_filter_array,
})(FilterCompetitor);
