//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import STEP_2_RUN_MODE_SQUARE from "./STEP_2_RUN_MODE_SQUARE";

//Redux function
import { set_list } from "../../actions/dataActions";
import {
  edit_preview_rayon_list_mode,
  edit_preview_rayon_list_v_manual,
} from "../../actions/mapActions";

//Picture Asset

//General Function
import st_colors from "../../validation/st_colors.json";
import generate_preview_rayon_list from "../../validation/generate_preview_rayon_list";

class Step2Preview extends Component {
  state = {};

  preview_rayon = () => {
    const { list_menu_active, outlet_list_filter_0, jumlah_toko } =
      this.props.data;

    const config_plan =
      list_menu_active.find((e) => e.key === "salesman")?.config?.value || []; //config_plan isinya depo yg sudah jadi label dll

    const body = {
      jumlah_toko,
      mode_sisa_toko_rayon: "rata",
      toko_list_main: outlet_list_filter_0,
      depo_loc_list: config_plan,
      mode_first: "v_first",
    };

    const result = generate_preview_rayon_list(body);

    this.props.set_list({ text: "preview_rayon_list", list: result });
  };

  on_edit_preview_rayon_list_v_manual = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    const body = {
      index: id,
      v_manual: value,
    };
    this.props.edit_preview_rayon_list_v_manual(body);
  };

  on_edit_preview_rayon_list_mode = (index, mode) => {
    const body = {
      index,
      mode,
    };
    this.props.edit_preview_rayon_list_mode(body);
  };

  on_change_nama_rayon_custom = (e) => {
    const value = e.target.value;
    this.props.set_list({ text: "nama_rayon_custom", list: value });
  };

  on_set_mode_nama_rayon = (mode_nama_rayon) => {
    this.props.set_list({ text: "mode_nama_rayon", list: mode_nama_rayon });

    //set defaultnya adalah nama depo
    this.props.set_list({
      text: "nama_rayon_custom",
      list: `kustom`,
    });
  };

  generate_filter_status = () => {
    let {
      outlet_list,
      outlet_list_filter_0,

      depo_list,
      competitor_code_list,
      type_outlet_list,
      segment_list,
    } = this.props.data;

    let array_item = [
      {
        title: "TYPE OUTLET",
        item: "Type Outlet",
        variable: "type_outlet_list",
        list: type_outlet_list,
      },
      {
        title: "DEPO",
        item: "Depo",
        variable: "depo_list",
        list: depo_list,
      },
      {
        title: "SEGMENT",
        item: "SEGMENT",
        variable: "segment_list",
        list: segment_list,
      },
      {
        title: "SYSTEM",
        item: "SYSTEM",
        variable: "competitor_code_list",
        list: competitor_code_list,
      },
    ];

    //mengambil list final generate angka jumlah toko
    array_item = array_item.map((e) => {
      const list = e.list;
      const item = e.item;
      let list_final = list.map((code) => {
        let number = outlet_list.filter(
          (e) => e?.[item] === code?.text
        )?.length;
        let number_filtered = outlet_list_filter_0.filter(
          (e) => e?.[item] === code?.text
        )?.length;
        return { ...code, number, number_filtered };
      });
      list_final = list_final.filter(
        (item) => item.number > 0 && !item.is_hide
      );
      return { ...e, list: list_final };
    });

    //mengambil number other
    array_item = array_item.map((object) => {
      const list = object.list;
      const item = object.item;
      const list_string = list.map((l) => l.text);
      const number_other = outlet_list.filter(
        (t) => !list_string?.includes(t?.[item])
      )?.length;
      const number_other_filtered = outlet_list_filter_0.filter(
        (t) => !list_string?.includes(t?.[item])
      )?.length;
      return { ...object, number_other, number_other_filtered };
    });
    return array_item;
  };

  render() {
    //local state

    //global props
    const { preview_rayon_list, mode_nama_rayon, nama_rayon_custom } =
      this.props.data;

    //content
    const array_filter = this.generate_filter_status();

    return (
      <main>
        <section className="main_container mt-5 mb-5" id="white">
          {preview_rayon_list?.length === 0 && (
            <button className="button" id="green" onClick={this.preview_rayon}>
              Preview Ilustrasi Rayon
            </button>
          )}

          {preview_rayon_list.map((p, idx) => {
            const vertical_range = p?.vertical_range || [];
            let rayon_index = 0;
            let color_index = 0;

            const panjang = Math.ceil(
              Number(p?.jumlah_rayon) / Number(p?.v_max)
            );

            return (
              <main key={idx}>
                <p className="text_bold">{p?.label}</p>
                {p?.jumlah_rayon && (
                  <section>
                    <table
                      className="table text_medium"
                      style={{ width: "100%" }}
                    >
                      <tbody>
                        <tr>
                          <td>Number of rayons</td>
                          <td>{p?.jumlah_rayon}</td>
                        </tr>

                        <tr>
                          <td>Distribution ratio of stores in the Depo</td>
                          <td>{p?.ratio?.toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td>
                            Length <br />
                            (number of horizontal rayons)
                          </td>
                          <td>{panjang}</td>
                        </tr>

                        <tr>
                          <td>
                            Width <br />
                            (number of vertical rayons)
                            <br />
                            <b>system recommendations</b>
                          </td>
                          <td>{p?.v_auto}</td>
                        </tr>

                        <tr>
                          <td colSpan={2}>
                            <p>Select the vertical downward rayon count mode</p>
                            <br />
                            <table>
                              <tbody>
                                <tr className="text_inferior">
                                  <td>
                                    <div
                                      id={
                                        p?.mode === "auto" ||
                                        p?.mode === undefined
                                          ? "checklist_active"
                                          : "checklist_not"
                                      }
                                      onClick={this.on_edit_preview_rayon_list_mode.bind(
                                        this,
                                        idx,
                                        "auto"
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <p
                                      onClick={this.on_edit_preview_rayon_list_mode.bind(
                                        this,
                                        idx,
                                        "auto"
                                      )}
                                    >
                                      System recommendations{" "}
                                      <span
                                        className="button_small_pill"
                                        id="blue"
                                      >
                                        {p?.v_auto}
                                      </span>
                                    </p>
                                  </td>
                                </tr>
                                <tr className="text_inferior">
                                  <td>
                                    <div
                                      id={
                                        p?.mode === "manual"
                                          ? "checklist_active"
                                          : "checklist_not"
                                      }
                                      onClick={this.on_edit_preview_rayon_list_mode.bind(
                                        this,
                                        idx,
                                        "manual"
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <p
                                      onClick={this.on_edit_preview_rayon_list_mode.bind(
                                        this,
                                        idx,
                                        "manual"
                                      )}
                                    >
                                      Set your own
                                    </p>
                                    <br />
                                    {p?.mode === "manual" && (
                                      <input
                                        type="number"
                                        id={idx}
                                        value={p?.v_manual || 1}
                                        onChange={
                                          this
                                            .on_edit_preview_rayon_list_v_manual
                                        }
                                      />
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={2} className="text_center">
                            <button
                              className="button"
                              id="green"
                              onClick={this.preview_rayon}
                            >
                              Update preview
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />

                    <section>
                      {vertical_range.map((v, idx) => {
                        const jumlah_rayon_horisontal =
                          v.jumlah_rayon_horisontal;
                        let content = [];

                        for (
                          let index = 0;
                          index < jumlah_rayon_horisontal;
                          index++
                        ) {
                          rayon_index = parseInt(rayon_index + 1);
                          color_index = parseInt(rayon_index - 1);

                          if (rayon_index > st_colors.length) {
                            color_index = (rayon_index % st_colors.length) - 1;
                          }

                          content.push(
                            <div
                              className="box mr-1 mb-1"
                              key={index}
                              style={{
                                borderColor: st_colors[color_index],
                              }}
                            >
                              {rayon_index}
                            </div>
                          );
                        }

                        return <div key={idx}>{content}</div>;
                      })}
                    </section>
                  </section>
                )}
              </main>
            );
          })}
        </section>

        {preview_rayon_list?.length > 0 && (
          <section className="main_container mt-5 mb-5" id="white">
            <p className="text_medium">Active filters:</p>
            <main className="grid_container_4">
              {array_filter.map((element, idx) => {
                const list = element.list;
                return (
                  <div className={`item_${idx + 1} main_container`} key={idx}>
                    <p className="text_inferior">{element.title}</p>
                    <p className="text_inferior">
                      {list.length === 0 ? (
                        "-"
                      ) : (
                        <b className="button_small_pill" id="blue">
                          {list.length}
                        </b>
                      )}
                      {list.length > 0 && (
                        <> ({list.map((item) => `${item.text}, `)})</>
                      )}
                    </p>
                  </div>
                );
              })}
            </main>
            <br />
            <p className="text_medium">Set rayon naming:</p>
            <table className="table" style={{ width: "100%" }}>
              <tbody>
                <tr
                  onClick={this.on_set_mode_nama_rayon.bind(this, "original")}
                >
                  <td>
                    <div
                      id={
                        mode_nama_rayon !== "custom"
                          ? "checklist_active"
                          : "checklist_not"
                      }
                    />
                  </td>
                  <td className="text_inferior">
                    Original (depo name_Rayon_number)
                  </td>
                </tr>
                <tr onClick={this.on_set_mode_nama_rayon.bind(this, "custom")}>
                  <td>
                    <div
                      id={
                        mode_nama_rayon === "custom"
                          ? "checklist_active"
                          : "checklist_not"
                      }
                    />
                  </td>
                  <td className="text_inferior">
                    Customize (depo name_custom_number)
                  </td>
                </tr>
              </tbody>
            </table>

            {mode_nama_rayon === "custom" && (
              <input
                style={{ width: "100%", marginTop: "5px" }}
                name="nama_rayon_custom"
                id="nama_rayon_custom"
                value={nama_rayon_custom}
                onChange={this.on_change_nama_rayon_custom}
              />
            )}

            <br />
            <p className="text_inferior">
              Rayonization will be performed <b>only</b> on filtered outlets
            </p>
            <br />
            <STEP_2_RUN_MODE_SQUARE />
          </section>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, {
  set_list,
  edit_preview_rayon_list_mode,
  edit_preview_rayon_list_v_manual,
})(Step2Preview);
