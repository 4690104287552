/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { bbox } from "@turf/turf";
import axios from "axios";
import Select from "react-select";
import writeXlsxFile from "write-excel-file";
import { Link } from "react-router-dom/cjs/react-router-dom";

/*PERSONAL COMPONENT*/
import EditTemplate from "./EditTemplate";
import EditCompetitorCode from "./EditCompetitorCode";
import EditSegment from "./EditSegment";
import Modal from "../common/Modal";
import Left from "../common/Left";
import COMPETITOR_SUCCESS_MENU from "./COMPETITOR_SUCCESS_MENU";
import COMPETITOR_FAILED_MENU from "./COMPETITOR_FAILED_MENU";
import EditTypeOutlet from "./EditTypeOutlet";
import EditFolderDepo from "./EditFolderDepo";
import UploadAddToko from "../upload/UploadAddToko";
import ON_FLY from "../main/ON_FLY";

/*REDUX FUNCTION*/
import {
  turn_on_menu,
  turn_on_menu_status,
  turn_off_menu,
  popUpAction,
  reset_total_toko,
  set_list,
  get_by_country,
  get_count_competitor_national,
  get_competitor_national_stream,
  get_competitor_list_by_area,
  get_competitor_no_depo_stream,
  set_config_mode_menu,
  getSalesmanFilter,
  search_competitor,
} from "../../actions/dataActions";
import {
  aggregate_segment_outlet,
  aggregate_type_outlet,
  aggregate_system_outlet,
} from "../../actions/propertiesActions";
import { get_competitor_by_area_stream } from "../../actions/outletGetActions";

/*PICTURE ASSET*/

/*GENERAL*/
import isEqual from "../../validation/is-equal";
import hirarki_administrasi from "../../data/hirarki_administrasi.json";

/*NON IMPORT*/

class OUTLET_EXPANSION_MENU extends Component {
  state = {
    col: "",
    status: "start",
    isInit: true,
    list_column: 50,

    mode_menu: "area", // area | success | failed
    modal_tambah_bulk: false,

    modal_edit: false,

    modal_edit_template: false,
    modal_edit_competitor_code: false,
    modal_edit_type_outlet: false,
    modal_edit_segment: false,
    modal_national: false,
    modal_edit_folder_depo: false,
    limit: 100_000,
    idx_c: 0,

    mode_query: "depo", //depo | city

    area: "",
    name: "",
    salesman_filter: [],
    name_arr: [],

    segment_list_state: [],
    activeSearch: false,

    provinsi: "",
    kabupaten: "",
    kecamatan: "",
    kelurahan: "",

    findSearch: "",
  };

  componentDidMount() {
    //get aggregate_segment_outlet supaya bisa mendapatkan list segment terbaru yang ada di collection nabati_tokos
    this.props.aggregate_segment_outlet();
    this.props.aggregate_type_outlet();
    this.props.aggregate_system_outlet();

    if (this.props.data.salesman_filter.depo.length === 0) {
      this.props.getSalesmanFilter();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { outlet_list_filter_0 } = this.props.data;
    const sidebarW = parseInt(localStorage.getItem("sidebar-width") || 500);
    if (
      !isEqual(
        prevProps.data.outlet_list_filter_0,
        this.props.data.outlet_list_filter_0
      ) &&
      outlet_list_filter_0.length > 0
    ) {
      const map = this?.props?.map?.map;
      const new_features = outlet_list_filter_0.map((e) => {
        return {
          type: "Feature",
          properties: { ...e },
          geometry: e.location,
        };
      });
      const geojson = {
        type: "FeatureCollection",
        features: [...new_features],
      };
      let [minLng, minLat, maxLng, maxLat] = bbox(geojson);
      if (map) {
        map?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: { top: 200, bottom: 200, left: sidebarW + 50, right: 500 },
          }
        );
      }
    }
  }

  handle_change_segment = (e) => {
    this.setState({ segment_list_state: e });
  };

  on_get_competitor_by_area_stream = () => {
    let { provinsi, kabupaten, kecamatan, kelurahan, segment_list_state } =
      this.state;
    const segment_list = segment_list_state.map((e) => e.value).join();

    const COUNTRY = this.props.auth.country_selected;

    const params = {
      KELURAHAN: kelurahan,
      KECAMATAN: kecamatan,
      KABUPATEN: kabupaten,
      PROPINSI: provinsi,
      COUNTRY,
      segment_list,
    };

    this.props.get_competitor_by_area_stream(params);
    this.props.turn_on_menu_status("distribution");
    this.setState({ activeSearch: true });
  };

  on_get_competitor_no_depo_stream = () => {
    const params = {
      COUNTRY: this.props.auth.country_selected,
    };
    this.props.get_competitor_no_depo_stream(params);
    this.props.turn_on_menu_status("distribution");
    this.props.set_config_mode_menu("distribution", "Depo");
  };

  set_mode_query = (mode_query) => {
    this.setState({ mode_query });
  };

  handle_change = (e) => {
    if (Array.isArray(e)) {
      this.setState({
        name_arr: e,
      });
    } else {
      let newArea = "";
      switch (e.value) {
        case "depo":
          newArea = "Depo";
          break;
        case "cabang":
          newArea = "Branch";
          break;
        case "region":
          newArea = "Region";
          break;
        default:
          break;
      }
      this.setState({
        [e.name]: newArea,
        name_arr: [],
        salesman_filter: this.props.data.salesman_filter[`${e.key}`].map(
          (params) => {
            return {
              key: params[`${e.db}`],
              label: params[`${e.db}`],
              value: params[`${e.db}`],
              name: "name",
            };
          }
        ),
      });
    }
  };

  on_submit = () => {
    if (
      this.props.auth.country_selected !== "INDONESIA" &&
      this.props.auth.country_selected
    ) {
      const params = { COUNTRY: this.props.auth.country_selected };
      this.props.get_by_country(params);
      this.props.turn_on_menu_status("distribution");
    } else {
      const { salesman_filter } = this.props.data;
      const { area, name_arr } = this.state;
      let newArea = "";
      switch (area) {
        case "Depo":
          newArea = "depo";
          break;
        case "Branch":
          newArea = "cabang";
          break;
        case "Region":
          newArea = "region";
          break;
        default:
          break;
      }
      const original_depo = salesman_filter?.original_depo
        ? salesman_filter?.original_depo
        : [];
      let name_arr_new = name_arr;
      if (newArea === "cabang") {
        const array = name_arr.map((n) => n.value);
        name_arr_new = original_depo.filter((d) => array.includes(d.CABANG));
        name_arr_new = name_arr_new.map((d) => {
          return { value: d.Depo };
        });
      }
      if (newArea === "region") {
        const array = name_arr.map((n) => n.value);
        name_arr_new = original_depo.filter((d) => array.includes(d.REGION));
        name_arr_new = name_arr_new.map((d) => {
          return { value: d.Depo };
        });
      }
      const params = {
        area: "depo",
        name: name_arr_new.map((e) => e.value),
        name_arr: name_arr_new,
        mode: "depo_color",
      };
      this.props.get_competitor_list_by_area(params);
      this.props.turn_on_menu({ key: "distribution", config: params });
      this.setState({
        list_column: 50,
      });
    }
    this.props.set_config_mode_menu("distribution", "Depo");
  };

  on_get_competitor_national_stream = (skip, idx) => {
    this.props.turn_on_menu({
      key: "distribution",
      config: { mode: "SYSTEM" },
    });
    this.setState({ idx_c: idx });
    const params = {
      total: this.state.limit,
      skip: skip,
    };
    this.props.get_competitor_national_stream(params);
    this.props.set_config_mode_menu("distribution", "Depo");
  };

  toggle_national = () => {
    this.setState({ modal_national: !this.state.modal_national });
  };

  on_reset_total_toko = () => {
    this.props.reset_total_toko();
  };

  toggle_edit = () => {
    this.setState({ modal_edit: !this.state.modal_edit });
  };

  toggle_edit_type_outlet = () => {
    this.setState({
      modal_edit_type_outlet: !this.state.modal_edit_type_outlet,
    });
  };

  toggle_edit_segment = () => {
    this.setState({
      modal_edit_segment: !this.state.modal_edit_segment,
    });
  };
  toggle_edit_folder_depo = () => {
    this.setState({
      modal_edit_folder_depo: !this.state.modal_edit_folder_depo,
    });
  };

  on_set_mode_menu = (mode_menu) => {
    this.props.turn_on_menu_status("distribution");
    this.setState({ mode_menu });
  };

  toggle_edit_template = () => {
    this.setState({ modal_edit_template: !this.state.modal_edit_template });
  };

  toggle_edit_competitor_code = () => {
    this.setState({
      modal_edit_competitor_code: !this.state.modal_edit_competitor_code,
    });
  };

  on_turn_on_menu_status = () => {
    this.props.turn_on_menu_status("distribution");
  };

  on_turn_off_menu = () => {
    this.props.turn_off_menu("distribution");
  };

  getKabupatenList = (idProv) => {
    try {
      const self = this;
      axios.get(`/kabupaten/${idProv}.json`).then((res) => {
        const kab = res.data.map((d) => ({
          ...d,
        }));
        this.setState({ kabupaten_list: kab }, () => {
          if (this.state.isInit) {
            self.setState({
              kabupaten: { id: "3273", nama: "KOTA BANDUNG" },
              isInit: false,
            });
          }
        });
      });
    } catch (e) {}
  };

  getKecamatanList = (idKab) => {
    try {
      axios.get(`/kecamatan/${idKab}.json`).then((res) => {
        const kec = res.data.map((d) => ({
          ...d,
        }));
        this.setState({ kecamatan_list: kec });
      });
    } catch (e) {}
  };

  getKelurahanList = (idKec) => {
    try {
      axios.get(`/kelurahan/${idKec}.json`).then((res) => {
        const kel = res.data.map((d) => ({
          ...d,
        }));
        this.setState({ kelurahan_list: kel });
      });
    } catch (e) {}
  };

  on_change = (e) => {
    const { provinsi_list, kabupaten_list, kecamatan_list, kelurahan_list } =
      this.state;
    const val = e.target.value;
    const name = e.target.name;
    const new_val =
      name === "provinsi"
        ? provinsi_list.find((el) => el.id === val)
        : name === "kabupaten"
        ? kabupaten_list.find((el) => el.id === val)
        : name === "kecamatan"
        ? kecamatan_list.find((el) => el.id === val)
        : kelurahan_list.find((el) => el.id === val);
    this.setState({
      [name]: new_val,
    });
  };

  on_click_col = (col) => {
    this.setState({
      status: "process",
    });
    this.setState({ col });
    setTimeout(
      function () {
        this.setState({
          status: "done",
        });
      }.bind(this),
      1000
    );
  };

  on_change_general = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({
      [name]: value,
    });
  };

  downloadFile = async () => {
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });
      try {
        const schema = [
          {
            column: "DETECTED LOCATION",
            type: String,
            value: (target) => target.detect,
          },
          {
            column: "_id",
            type: String,
            value: (target) => target.id,
          },
          {
            column: "ID SFA",
            type: String,
            value: (target) => target.sfa,
          },
          {
            column: "PLAN",
            type: String,
            value: (target) => target.plan,
          },
          {
            column: "ID OUTLET",
            type: String,
            value: (target) => target.idOutlet,
          },
          {
            column: "DEPO",
            type: String,
            value: (target) => target.depo,
          },
          {
            column: "STATUS DEPO",
            type: String,
            value: (target) => target.statusDepo,
          },
          {
            column: "SYSTEM",
            type: String,
            value: (target) => target.system,
          },
          {
            column: "OUTLET NAME",
            type: String,
            value: (target) => target.namaOutlet,
          },
          {
            column: "ADDRESS 1",
            type: String,
            value: (target) => target.alamat1,
          },
          {
            column: "ADDRESS 2",
            type: String,
            value: (target) => target.alamat2,
          },
          {
            column: "TELEPHONE NUMBER",
            type: String,
            value: (target) => target.notelp,
          },
          {
            column: "TYPE OUTLET",
            type: String,
            value: (target) => target.typeOutlet,
          },
          {
            column: "SEGMENT",
            type: String,
            value: (target) => target.segment,
          },
          {
            column: "SUBDISTRICT",
            type: String,
            value: (target) => target.kelurahan,
          },
          {
            column: "DISTRICT",
            type: String,
            value: (target) => target.kecamatan,
          },
          {
            column: "CITY/REGENCY",
            type: String,
            value: (target) => target.kabupaten,
          },
          {
            column: "PROVINCE",
            type: String,
            value: (target) => target.propinsi,
          },
          {
            column: "FLAG DISTRICT",
            type: String,
            value: (target) => target.flagDistrict,
          },
          {
            column: "UPDATE DESCRIPTION",
            type: String,
            value: (target) => target.ketUpdate,
          },
          {
            column: "POSTCODE",
            type: String,
            value: (target) => target.kdpos,
          },
          {
            column: "OUTLET STATUS",
            type: String,
            value: (target) => target.statusOutlet,
          },
          {
            column: "ID BRANCH",
            type: String,
            value: (target) => target.idBranch,
          },
          {
            column: "COUNTRY",
            type: String,
            value: (target) => target.country,
          },
          {
            column: "COORDINATE STATUS",
            type: String,
            value: (target) => target.statusKoordinate,
          },
          {
            column: "PMA CODE",
            type: String,
            value: (target) => target.kodePma,
          },
          {
            column: "UNIQ CODE",
            type: String,
            value: (target) => target.kodeUnik,
          },
          {
            column: "DEPO ID OUTLET",
            type: String,
            value: (target) => target.depoIdOutlet,
          },
          {
            column: "PLAN ID OUTLET",
            type: String,
            value: (target) => target.planIdOutlet,
          },
          {
            column: "OMSET",
            type: Number,
            value: (target) => target.OMSET,
          },
          {
            column: "CP",
            type: String,
            value: (target) => target.CP,
          },
        ];
        let { outlet_list_filter_0 } = this.props.data;
        let newData = [];
        outlet_list_filter_0.forEach((e) => {
          const { user, master_admin } = this.props.auth;
          let location = "";
          // let status_koordinat = "No";
          if (e?.location?.coordinates?.[0] !== 0) {
            const long = e.location.coordinates[0];
            const lat = e.location.coordinates[1];
            location = `${lat},${long}`;
            // status_koordinat = "Yes";
          }
          if (master_admin.filter((e) => e._id === user._id).length <= 0) {
            if (e?.location?.coordinates?.[0] !== 0) {
              location = "YES";
            } else {
              location = "NO";
            }
          }
          let getData = {
            detect: location ?? "-",
            _id: e._id,
            sfa: e["ID SFA"],
            plan: e.Plan,
            idOutlet: e["ID Outlet"],
            depo: e.Depo,
            //     statusDepo: "CB",
            system: e.SYSTEM,
            namaOutlet: e["Nama Toko"],
            alamat1: e.ALAMAT,
            alamat2: e.ALAMATLAIN,
            notelp: e["NO Tlpon"],
            typeOutlet: e["Type Outlet"],
            segment: e.SEGMENT,
            kelurahan: e.KELURAHAN,
            kecamatan: e.KECAMATAN,
            kabupaten: e.KABUPATEN,
            propinsi: e.PROPINSI,
            flagDistrict: e.FLAG,
            // ketUpdate: "UPDATE 1-H",
            kdpos: e.KDPOS,
            statusOutlet: e["Status Outlet"],
            idBranch: e["ID BRANCH"],
            country: e.COUNTRY,
            statusKoordinate: e["cek koordinat"],
            // kodePma: "1000713",
            // kodeUnik: "72796520",
            // depoIdOutlet: "CIREBON KOTA796520",
            // planIdOutlet: "P178796520",
            // OMSET: 1000000,
            // CP: "WAHYU",
          };
          newData.push(getData);
        });
        await writeXlsxFile(newData, {
          schema,
          fileName: "dataCompetitor.xlsx",
        });
        this.setState({
          loading_csv: false,
        });
      } catch (error) {
        this.setState({
          loading: false,
        });
      }
    }
  };

  search = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  findSearch = (event) => {
    event.preventDefault();
    const { findSearch } = this.state;
    this.props.search_competitor(findSearch);
  };

  render() {
    //local storage

    //local state
    let {
      mode_menu,
      modal_edit,
      modal_edit_type_outlet,
      modal_edit_template,
      modal_edit_competitor_code,
      modal_edit_segment,
      modal_tambah_bulk,
      modal_edit_folder_depo,
      limit,
      mode_query,
      area,
      salesman_filter,
      provinsi,
      kabupaten,
      kecamatan,
      kelurahan,
    } = this.state;

    //global props
    let {
      loading_status,
      loading_item,
      outlet_list,
      outlet_list_table,
      list_menu_active,
      total_toko,
      progres_toko,
      competitor_national_count,
      outlet_list_filter_0,
    } = this.props.data;
    const { segment_list_select } = this.props.properties;

    //local file
    const provinsi_list = [
      ...new Set(hirarki_administrasi.map((item) => item.PROVINSI)),
    ].sort();
    const kabupaten_list = [
      ...new Set(
        hirarki_administrasi
          .filter((item) => item.PROVINSI === provinsi)
          .map((item) => item.KOTA)
      ),
    ].sort();
    const kecamatan_list = [
      ...new Set(
        hirarki_administrasi
          .filter(
            (item) => item.PROVINSI === provinsi && item.KOTA === kabupaten
          )
          .map((item) => item.KECAMATAN)
      ),
    ].sort();
    const kelurahan_list = [
      ...new Set(
        hirarki_administrasi
          .filter(
            (item) =>
              item.PROVINSI === provinsi &&
              item.KOTA === kabupaten &&
              item.KECAMATAN === kecamatan
          )
          .map((item) => item.KELURAHAN)
      ),
    ].sort();

    //content
    let array_iteration = [];

    if (competitor_national_count > 0) {
      const iteration = Math.ceil(competitor_national_count / limit);
      for (let i = 0; i < iteration; i++) {
        array_iteration.push({
          skip: i * limit,
          limit: limit,
        });
      }
    }

    let button_slice;
    if (outlet_list.length > 0) {
      button_slice = (
        <div className="flex items-center justify-center w-full">
          <button
            className="button_main mt-5"
            onClick={() => {
              let number = this.state.list_column + 50;
              this.setState({ list_column: number });
            }}
          >
            Load More Datas
          </button>
        </div>
      );
    }
    if (this.state.list_column > outlet_list.length) {
      button_slice = (
        <div className="flex items-center justify-center mt-5">
          All Data Has Been Loaded
        </div>
      );
    }

    let progres_get_toko_content;
    if (total_toko > 0) {
      const percentage = parseInt((progres_toko / total_toko) * 100);
      progres_get_toko_content = (
        <div className="main_container">
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td className="text_inferior">Progress from this batch:</td>
                <td className="text_bold">{percentage}%</td>
              </tr>
              <tr>
                <td className="text_inferior">Total outlet from this batch:</td>
                <td className="text_bold">
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(total_toko)}
                </td>
              </tr>
              <tr>
                <td className="text_inferior">
                  Current loaded outlet from this batch:
                </td>
                <td className="text_bold">
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(progres_toko)}
                </td>
              </tr>
            </tbody>
          </table>
          <button
            className="button"
            id="green"
            onClick={this.on_reset_total_toko}
          >
            Clear/Reset Progress
          </button>
        </div>
      );
    }

    const menu_status = list_menu_active.find((e) => e.key === "distribution");
    const name_array = menu_status?.config?.name_array
      ? menu_status?.config?.name_array
      : [];
    let menu_button;
    if (menu_status.active === true) {
      menu_button = (
        <button onClick={this.on_turn_off_menu} className="button_main">
          Hide
        </button>
      );
    } else {
      menu_button = (
        <button onClick={this.on_turn_on_menu_status} className="button_main">
          View
        </button>
      );
    }

    const menu_content = (
      <main className="mb-5">
        <section>Active parameter areas : {outlet_list.length}</section>
        <section>
          Filtered parameter areas : {outlet_list_filter_0.length}
        </section>
        {name_array.map(
          ({ KELURAHAN, KECAMATAN, KABUPATEN, PROPINSI }, idx) => {
            return (
              <main className="main_container mb-3" key={idx}>
                <table
                  style={{
                    tableLayout: "fixed",
                    width: "100%",
                    fontSize: "13px",
                  }}
                >
                  <tbody>
                    <tr>
                      <td>Province</td>
                      <td>
                        <b>{PROPINSI ? PROPINSI : "-"}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>City</td>
                      <td>
                        <b>{KABUPATEN ? KABUPATEN : "-"}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>District</td>
                      <td>
                        <b>{KECAMATAN ? KECAMATAN : "-"}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Subdistrict</td>
                      <td>
                        <b>{KELURAHAN ? KELURAHAN : "-"}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </main>
            );
          }
        )}
      </main>
    );

    let button_content = (
      <button
        onClick={this.on_submit}
        className="button"
        style={{ marginBottom: "20px" }}
        id="green"
      >
        Get outlet in this country
      </button>
    );

    if (loading_status && loading_item === "get_competitor_by_area") {
      button_content = (
        <main>
          <div className="button" style={{ marginBottom: "20px" }} id="black">
            Loading data...
          </div>
        </main>
      );
    }

    let list_content = <main style={{ marginBottom: "20px" }}>No Data</main>;
    let searchBar = "";
    if (this.state.activeSearch) {
      searchBar = (
        <>
          {outlet_list.length > 0 ? (
            <div className="mb-5">
              {loading_status &&
              loading_item === "get_competitor_no_depo_stream" ? (
                <button className="button" id="grey">
                  Loading...
                </button>
              ) : (
                <button
                  onClick={this.downloadFile}
                  className="button"
                  id="green"
                >
                  Download data competitor
                </button>
              )}
            </div>
          ) : null}
          <div>Find store name</div>
          <form onSubmit={this.findSearch}>
            <div className="flex gap-1">
              <div className="w-full my-2">
                <input
                  type="text"
                  placeholder={`Search store name`}
                  className="w-full"
                  name="findSearch"
                  onChange={this.search}
                  autoComplete="off"
                />
              </div>
              <button className="px-1" type="sumbit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </button>
            </div>
          </form>
        </>
      );
    }

    if (outlet_list.length > 0) {
      list_content = (
        <main style={{ marginBottom: "20px" }}>
          <table className="table">
            <thead>
              <tr>
                <th>No</th>
                <th>Outlet name</th>
                <th>Segment</th>
                <th>Address</th>
                <th>PT</th>
              </tr>
            </thead>
            <tbody>
              {outlet_list_table
                .slice(0, this.state.list_column)
                .map((outlet, idx) => {
                  //modal_competitor
                  let button_fly = (
                    <ON_FLY item={outlet} modal_item="modal_competitor" />
                  );
                  if (!outlet?.location?.coordinates?.[0]) {
                    button_fly = (
                      <button className="button" id="red">
                        No location
                      </button>
                    );
                  }
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>
                        {outlet?.["Nama Toko"]} <br />
                        {button_fly}
                      </td>
                      <td>{outlet?.["SEGMENT"]}</td>
                      <td>{outlet?.["ALAMAT"]}</td>
                      <td>{outlet?.["SYSTEM"]}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {button_slice}
        </main>
      );
    }

    const toggle_content = (
      <main className="main_container mb-5">
        <button
          className="button mr-1 mb-1"
          id={mode_menu === "area" ? "green" : "grey_outline"}
          onClick={this.on_set_mode_menu.bind(this, "area")}
        >
          Outlets
        </button>
        <button
          className="button mr-1 mb-1"
          id={mode_menu === "success" ? "green" : "grey_outline"}
          onClick={this.on_set_mode_menu.bind(this, "success")}
        >
          Acquisition Success
        </button>
        <button
          className="button mr-1 mb-1"
          id={mode_menu === "failed" ? "green" : "grey_outline"}
          onClick={this.on_set_mode_menu.bind(this, "failed")}
        >
          Acquisition Failed
        </button>
      </main>
    );

    const area_list = [
      { key: "depo", label: "Depo", value: "depo", db: "Depo", name: "area" },
      {
        key: "cabang",
        label: "Branch",
        value: "cabang",
        db: "CABANG",
        name: "area",
      },
      {
        key: "regional",
        label: "Region",
        value: "region",
        db: "REGIONAL",
        name: "area",
      },
    ];

    let select_content;
    if (mode_query === "depo") {
      select_content = (
        <main>
          <section className="mb-2">
            <p>Select Depo/Branch/Region</p>
            <Select options={area_list} onChange={this.handle_change} />
          </section>
          <section className="mb-4">
            <p>
              Select {""}
              {area === "" ? "Area Name" : area}
            </p>
            <Select
              options={salesman_filter}
              isMulti
              onChange={this.handle_change}
            />
          </section>

          <section className="mb-5">
            {loading_status &&
            loading_item === "get_competitor_list_by_area" ? (
              <button className="button" id="grey">
                Loading...
              </button>
            ) : (
              <button onClick={this.on_submit} className="button" id="green">
                Get outlets
              </button>
            )}
          </section>

          <section className="mb-5">
            {loading_status &&
            loading_item === "get_competitor_no_depo_stream" ? (
              <button className="button" id="grey">
                Loading...
              </button>
            ) : (
              <button
                onClick={this.on_get_competitor_no_depo_stream}
                className="button"
                id="green"
              >
                Get outlets without depo
              </button>
            )}
          </section>
        </main>
      );
    } else {
      select_content = (
        <>
          <section>
            <p>Province</p>
            <select
              id="provinsi"
              name="provinsi"
              value={provinsi}
              onChange={this.on_change_general}
            >
              <option value="">Select a province</option>
              {provinsi_list.map((item, idx) => {
                return (
                  <option value={item} key={idx}>
                    {item}
                  </option>
                );
              })}
            </select>
          </section>
          {provinsi && (
            <section>
              <p>City</p>
              <select
                id="kabupaten"
                name="kabupaten"
                value={kabupaten}
                onChange={this.on_change_general}
              >
                <option value="">Select a city</option>
                {kabupaten_list.map((item, idx) => {
                  return (
                    <option value={item} key={idx}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </section>
          )}
          {kabupaten && (
            <section>
              <p>District/Kecamatan</p>
              <select
                id="kecamatan"
                name="kecamatan"
                value={kecamatan}
                onChange={this.on_change_general}
              >
                <option value="">Select a District/Kecamatan</option>
                {kecamatan_list.map((item, idx) => {
                  return (
                    <option value={item} key={idx}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </section>
          )}

          {kecamatan && (
            <section>
              <p>Subdistrict/Kelurahan</p>
              <select
                id="kelurahan"
                name="kelurahan"
                value={kelurahan}
                onChange={this.on_change_general}
              >
                <option value="">Select a Subdistrict/Kelurahan</option>
                {kelurahan_list.map((item, idx) => {
                  return (
                    <option value={item} key={idx}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </section>
          )}

          <section className="mb-2">
            <p>
              Add outlet segment filter (leave it blank if you want to show all)
            </p>
            <Select
              name="segment_list_state"
              options={segment_list_select}
              isMulti
              onChange={this.handle_change_segment}
            />
          </section>

          {loading_status && loading_item === "get_competitor_by_area" ? (
            <div className="button" id="grey">
              Loading...
            </div>
          ) : (
            <button
              className="button"
              id="green"
              onClick={this.on_get_competitor_by_area_stream}
            >
              Get outlet data
            </button>
          )}
        </>
      );
    }

    if (this.props.auth.country_selected !== "INDONESIA")
      select_content = (
        <main>
          <section className="mb-2">
            <p>Province</p>
            <input type="text" id="lname" name="lname" disabled />
          </section>

          <section className="mb-2">
            <p>City</p>
            <input type="text" id="lname" name="lname" disabled />
          </section>

          <section className="mb-2">
            <p>District/Kecamatan</p>
            <input type="text" id="lname" name="lname" disabled />
          </section>

          <section className="mb-2">
            <p>Subdistrict/Kelurahan</p>
            <input type="text" id="lname" name="lname" disabled />
          </section>

          <section className="mb-2">
            <p>
              There is no administrative region hierarchy for{" "}
              <b>{this.props.auth.country_selected}</b> yet. Query all stores in
              this country.
            </p>
          </section>

          {button_content}
        </main>
      );

    let final_content = (
      <main>
        <section className={`text_left transition-transform`}>
          <p className="text_medium">Choose area mode to query outlet data:</p>
          <select onChange={this.on_change_general} name="mode_query">
            <option value="depo">Depo Nabati</option>
            <option value="city">Administative boundaries (Indonesia)</option>
          </select>
          <table className="n-w-full">
            <tbody className="n-w-full">
              <tr>
                <td className="text_medium">Country</td>
                <td className="n-text-right">
                  <b>{this.props.auth.country_selected}</b>
                </td>
              </tr>
            </tbody>
          </table>
          <section className="main_container">{select_content}</section>
          {progres_get_toko_content}
          {menu_content}
          {searchBar}
          {list_content}
        </section>
      </main>
    );

    if (mode_menu === "success") {
      final_content = <COMPETITOR_SUCCESS_MENU />;
    } else if (mode_menu === "failed") {
      final_content = <COMPETITOR_FAILED_MENU />;
    }

    const modal_edit_template_content = modal_edit_template && (
      <Modal
        modalSize="small"
        isOpen={modal_edit_template}
        onClose={this.toggle_edit_template}
      >
        <div className="box-body" style={{ minHeight: "50vh" }}>
          <EditTemplate />
        </div>
      </Modal>
    );

    const modal_edit_competitor_code_content = modal_edit_competitor_code && (
      <Modal
        modalSize="small"
        isOpen={modal_edit_competitor_code}
        onClose={this.toggle_edit_competitor_code}
      >
        <div className="box-body" style={{ minHeight: "50vh" }}>
          <EditCompetitorCode />
        </div>
      </Modal>
    );

    const modal_edit_type_outlet_content = modal_edit_type_outlet && (
      <Modal
        modalSize="small"
        isOpen={modal_edit_type_outlet}
        onClose={this.toggle_edit_type_outlet}
      >
        <div className="box-body" style={{ minHeight: "50vh" }}>
          <EditTypeOutlet />
        </div>
      </Modal>
    );

    const modal_edit_segment_content = modal_edit_segment && (
      <Modal
        modalSize="small"
        isOpen={modal_edit_segment}
        onClose={this.toggle_edit_segment}
      >
        <div className="box-body" style={{ minHeight: "50vh" }}>
          <EditSegment />
        </div>
      </Modal>
    );

    const modal_edit_folder_depo_content = modal_edit_folder_depo && (
      <Modal
        modalSize="small"
        isOpen={modal_edit_segment}
        onClose={this.toggle_edit_folder_depo}
      >
        <div className="box-body" style={{ minHeight: "50vh" }}>
          <EditFolderDepo />
        </div>
      </Modal>
    );

    const modal_edit_content = modal_edit && (
      <Left isOpen={modal_edit} onClose={this.toggle_edit}>
        <div className="box-body">
          <main style={{ minHeight: "100vh" }}>
            <p className="text_bold mb-5">ADMINISTRATOR MENU</p>
            <button
              className="button mb-2"
              id="green"
              onClick={this.toggle_edit_template}
            >
              Edit the reasons of unsuccessful store acquisition
            </button>
            <br />
            <button
              className="button mb-2"
              id="green"
              onClick={this.toggle_edit_competitor_code}
            >
              Edit outlet system
            </button>
            <br />
            <button
              onClick={this.toggle_edit_type_outlet}
              className="button mb-2"
              id="green"
            >
              Edit outlet type
            </button>
            <br />
            <button
              onClick={this.toggle_edit_segment}
              className="button mb-2"
              id="green"
            >
              Edit Segment
            </button>
            <br />
            <button
              onClick={this.toggle_edit_folder_depo}
              className="button mb-2"
              id="green"
            >
              Edit Folder Depo
            </button>
            <br />
            <button
              className="button mb-2"
              id="green"
              onClick={() => this.setState({ modal_tambah_bulk: true })}
            >
              Add competitor outlet bulk
            </button>
          </main>
        </div>
      </Left>
    );

    const modal_tambah_bulk_content = modal_tambah_bulk && (
      <Modal
        modalSize="large"
        isOpen={modal_tambah_bulk}
        onClose={() => this.setState({ modal_tambah_bulk: false })}
      >
        <div className="box-body">
          <UploadAddToko mode_competitor="competitor" />
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_edit_folder_depo_content}
        {modal_edit_content}
        {modal_edit_competitor_code_content}
        {modal_edit_template_content}
        {modal_tambah_bulk_content}
        {modal_edit_type_outlet_content}
        {modal_edit_segment_content}
        <Link
          className="button"
          id="green"
          to="/dashboard_rayonisasi_competitor"
        >
          COMPETITOR NATIONAL RAYONIZATION
        </Link>
        <br />
        <br />
        <button className="button mb-2" id="green" onClick={this.toggle_edit}>
          Admin menu
        </button>
        {toggle_content}
        <section className="mb-5">{menu_button}</section>
        {final_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
  auth: state.auth,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  turn_on_menu,
  turn_on_menu_status,
  turn_off_menu,
  popUpAction,
  reset_total_toko,
  set_list,
  set_config_mode_menu,
  get_by_country,
  get_competitor_by_area_stream,
  get_count_competitor_national,
  get_competitor_national_stream,
  get_competitor_list_by_area,
  get_competitor_no_depo_stream,
  getSalesmanFilter,
  aggregate_segment_outlet,
  aggregate_type_outlet,
  aggregate_system_outlet,
  search_competitor,
})(OUTLET_EXPANSION_MENU);
