//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
import Modal from "../common/Modal";
//Redux function
import {
  get_apk_version_list,
  create_apk_version,
  edit_apk_version,
  delete_apk_version,
} from "../../actions/dataActions";
//Picture Asset
//General Function
class EditApkVersion extends Component {
  state = {
    //state value input create
    text: "",
    apk_version: 1,
    apk_interval: 60000,
    url_apk: "",
    //modals
    modal_add: false,
    modal_delete: false,
    modal_edit: false,
    //edit state
    template: {},
    text_before: "",
    text_after: "",
    apk_version_after: 1,
    apk_interval_after: 60000,
    url_apk_after: "",
  };
  componentDidMount() {
    this.props.get_apk_version_list();
  }
  toggle_edit = (template) => {
    this.setState({ modal_edit: !this.state.modal_edit });
    if (template) {
      this.setState({
        template,
        text_before: template.text,
        text_after: template.text,
        apk_version_after: template.apk_version,
        apk_interval_after: template.apk_interval,
        url_apk_after: template.url_apk,
        is_newest_after: template.is_newest,
      });
    }
  };
  toggle_delete = (template) => {
    this.setState({ modal_delete: !this.state.modal_delete });
    if (template) {
      this.setState({ template });
    }
  };
  on_change = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value });
  };
  on_change_toggle = (e) => {
    const value = e.target.checked;
    const name = e.target.name;
    this.setState({ [name]: value });
  };
  toggle_add = () => {
    this.setState({ modal_add: !this.state.modal_add });
  };
  on_submit_add = () => {
    const { text, apk_version, url_apk } = this.state;
    const body = {
      text,
      apk_version,
      url_apk,
    };
    this.props.create_apk_version(body);
    this.setState({ modal_add: false, text: "", apk_version: 1, url_apk: "" });
  };
  on_submit_delete = () => {
    const { template } = this.state;
    const body = {
      _id: template._id,
    };
    this.props.delete_apk_version(body);
    this.setState({ modal_delete: false });
  };
  on_submit_edit = () => {
    const {
      template,
      text_after,
      apk_version_after,
      apk_interval_after,
      url_apk_after,
      is_newest_after,
    } = this.state;
    const body = {
      _id: template._id,
      text: text_after,
      apk_version: apk_version_after,
      apk_interval: apk_interval_after,
      url_apk: url_apk_after,
      is_newest: is_newest_after,
    };
    this.props.edit_apk_version(body);
    this.setState({ modal_edit: false });
  };
  render() {
    const {
      modal_add,
      modal_edit,
      modal_delete,
      template,
      text,
      apk_version,
      apk_interval,
      url_apk,
      text_before,
      text_after,
      apk_version_after,
      apk_interval_after,
      url_apk_after,
      is_newest_after,
    } = this.state;
    const { apk_version_list } = this.props.data;
    const list_content = (
      <table style={{ width: "100%" }} className="table_lite mt-10">
        <tbody>
          {apk_version_list.map((item, idx) => {
            return (
              <tr key={idx}>
                <td>{idx + 1}.</td>
                <td
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {item.text}
                </td>
                <td>{item.apk_version}</td>
                <td>{item.apk_interval}</td>
                <td>{item.url_apk}</td>
                <td>newest: {String(item.is_newest)}</td>
                <td>
                  <button
                    className="button_small"
                    id="blue"
                    onClick={this.toggle_edit.bind(this, item)}
                  >
                    Edit
                  </button>
                </td>
                <td>
                  <button
                    className="button_small"
                    id="red"
                    onClick={this.toggle_delete.bind(this, item)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
    let button_content = (
      <button className="button" id="green" onClick={this.on_submit_add}>
        Add
      </button>
    );
    const modal_add_content = modal_add && (
      <Modal modalSize="small" isOpen={modal_add} onClose={this.toggle_add}>
        <div className="box-body">
          <p className="text_bold">New Description</p>
          <textarea
            name="text"
            id="text"
            value={text}
            onChange={this.on_change}
            type="text"
            style={{ width: "100%" }}
          />
          <p className="text_bold">New Version</p>
          <input
            name="apk_version"
            id="apk_version"
            value={apk_version}
            onChange={this.on_change}
            type="text"
            style={{ width: "100%" }}
          />
          <input
            name="apk_interval"
            id="apk_interval"
            value={apk_interval}
            onChange={this.on_change}
            type="number"
            style={{ width: "100%" }}
          />
          <input
            name="url_apk"
            id="url_apk"
            value={url_apk}
            onChange={this.on_change}
            type="text"
            style={{ width: "100%" }}
          />
          {button_content}
        </div>
      </Modal>
    );
    const modal_delete_content = modal_delete && (
      <Modal
        modalSize="small"
        isOpen={modal_delete}
        onClose={this.toggle_delete}
      >
        <div className="box-body text_center">
          <p className="text_medium mb-5">Log version delete confirmation</p>
          <p className="text_bold mb-5">{template.text}</p>
          <button className="button" id="red" onClick={this.on_submit_delete}>
            Delete
          </button>
        </div>
      </Modal>
    );
    const modal_edit_content = modal_edit && (
      <Modal modalSize="small" isOpen={modal_edit} onClose={this.toggle_edit}>
        <div className="box-body text_center">
          <p className="text_medium mb-5">Edit Description</p>
          <p className="text_bold mb-5">Before: {text_before}</p>
          <textarea
            name="text_after"
            id="text_after"
            value={text_after}
            onChange={this.on_change}
            type="text"
            style={{ width: "100%" }}
          />
          <input
            name="apk_version_after"
            id="apk_version_after"
            value={apk_version_after}
            onChange={this.on_change}
            type="text"
            style={{ width: "100%" }}
          />
          <input
            name="apk_interval_after"
            id="apk_interval_after"
            value={apk_interval_after}
            onChange={this.on_change}
            type="text"
            style={{ width: "100%" }}
          />
          <input
            name="url_apk_after"
            id="url_apk_after"
            value={url_apk_after}
            onChange={this.on_change}
            type="text"
            style={{ width: "100%" }}
          />
          Newest
          <input
            name="is_newest_after"
            id="is_newest_after"
            // value={is_newest_after}
            defaultChecked={is_newest_after}
            onChange={this.on_change_toggle}
            type="checkbox"
            style={{ width: "100%" }}
          />
          <button className="button" id="blue" onClick={this.on_submit_edit}>
            Submit Edit
          </button>
        </div>
      </Modal>
    );
    return (
      <div>
        {modal_add_content}
        {modal_delete_content}
        {modal_edit_content}
        <p className="text_bold">APK version editor list</p>
        <p className="text_bold">
          Can only be filled by Nabati Outdig APK Developers
        </p>
        <section className="text_center">
          <button
            className="button_circle"
            id="green"
            onClick={this.toggle_add}
          >
            +
          </button>
          <div className="mt-2">Add log version</div>
        </section>
        {list_content}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, {
  get_apk_version_list,
  create_apk_version,
  edit_apk_version,
  delete_apk_version,
})(EditApkVersion);
