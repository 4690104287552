//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";

//Personal Component

//Redux function

//Picture Asset

//General Function
import generate_color_map from "../../validation/generate_color_map";

class RayonLayer extends Component {
  state = {
    color_map: "#575757",
    outlet_list_filter_0_length: 0,
    new_features: [],
    list: [],

    content_render: null,
    active_before: false,
  };

  render_data = (body) => {
    const { list, outlet_list_filter_0, active_after } = body;

    let color_map = "#575757";
    let text_final, array_name, array_color;
    let new_features = [];
    if (list?.length > 0) {
      array_name = list.map((e) => e.name);
      if (array_name?.length > 0) {
        array_color = list.map((e) => e.color);
        array_color.push("#000000");
        text_final = "name";
        color_map = generate_color_map(array_name, array_color, text_final);
      }
      new_features = list.map((e) => {
        const toko_list = outlet_list_filter_0.filter(
          (t) => t.rayon_id === e._id
        );
        let sum_value = toko_list.map((t) => (t?.value ? t?.value : 0));
        sum_value = parseInt(sum_value.reduce((a, b) => a + b, 0));
        sum_value = new Intl.NumberFormat("id-ID", {
          style: "decimal",
        }).format(sum_value);
        let sum_qty = toko_list.map((t) => (t?.qty ? t?.qty : 0));
        sum_qty = parseInt(sum_qty.reduce((a, b) => a + b, 0));
        sum_qty = new Intl.NumberFormat("id-ID", {
          style: "decimal",
        }).format(sum_qty);
        const name_number = `${e.name}
          Outlet: ${toko_list.length}
          Omset: Rp.${sum_value}
          Qty: ${sum_qty}`;
        return {
          type: "Feature",
          properties: { ...e, name_number },
          geometry: e.location,
        };
      });
    }
    const geojson = {
      type: "FeatureCollection",
      features: [...new_features],
    };

    let features_polygon = [];

    list.forEach((item) => {
      if (item?.feature_polygon) {
        const object = {
          ...item?.feature_polygon,
          properties: item,
        };
        features_polygon.push(object);
      }
    });

    const geojson_polygon = {
      type: "FeatureCollection",
      features: features_polygon,
    };

    const content_render = (
      <main>
        <Source key="rayon-src" id="rayon-src" type="geojson" data={geojson} />
        <Layer
          id="rayon"
          key="rayon"
          source="rayon-src"
          type="symbol"
          layout={{
            visibility: active_after ? "visible" : "none",
            "icon-image": "none",
            "text-field": ["get", "name_number"],
            "text-font": ["Open Sans Bold"],
            "text-anchor": "top",
            "text-offset": [0, -2.5],
            "text-line-height": 1,
            "text-allow-overlap": true,
            "text-ignore-placement": false,
            "text-size": 12,
            "text-max-width": 4,
          }}
          paint={{
            "text-color": color_map,
            "text-halo-color": "white",
            "text-halo-width": 10,
            "text-halo-blur": 0,
          }}
          onLeave={this.onLeave}
          onClick={this.props.layerOnClick.bind(this, "modal_plotting")}
        />
        <Source
          key="rayon_polygon"
          id="rayon_polygon"
          type="geojson"
          data={geojson_polygon}
        />
        <Layer
          id="rayon_polygon"
          before="rayon"
          source="rayon_polygon"
          type="fill"
          paint={{
            "fill-color": color_map,
            "fill-opacity": 0.3,
          }}
          layout={{
            visibility: active_after ? "visible" : "none",
          }}
          onLeave={this.onLeave}
          onClick={this.props.layerOnClick.bind(this, "modal_plotting")}
        />
      </main>
    );
    this.setState({ content_render, active_before: active_after });
  };

  componentDidMount() {
    //AFTER
    let list_menu_active_after = this.props.data.list_menu_active;
    let outlet_list_filter_0_after = this.props.data.outlet_list_filter_0;
    const list_after = this.props.data.rayon_list_database.filter(
      (r) => !r.is_hide
    );
    //inside config
    const menu_status_after = list_menu_active_after.find(
      (e) => e.key === "rayon"
    );
    const active_after = menu_status_after?.active;
    if (list_after.length > 0 && active_after) {
      const body = {
        list: list_after,
        outlet_list_filter_0: outlet_list_filter_0_after,
        active_after,
      };
      this.render_data(body);
    }
  }

  componentDidUpdate(prevProps) {
    //AFTER
    let list_menu_active_after = this.props.data.list_menu_active;
    let outlet_list_filter_0_after = this.props.data.outlet_list_filter_0;
    const list_after = this.props.data.rayon_list_database.filter(
      (r) => !r.is_hide
    );
    //inside config
    const menu_status_after = list_menu_active_after.find(
      (e) => e.key === "rayon"
    );
    const active_after = menu_status_after?.active;

    const status_action_after = this.props.map.status_action;
    const status_action_before = prevProps.map.status_action;

    if (status_action_after !== status_action_before) {
      const body = {
        list: list_after,
        outlet_list_filter_0: outlet_list_filter_0_after,
        active_after,
      };
      this.render_data(body);
    }
  }

  render() {
    const { content_render } = this.state;
    return content_render;
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
});

export default connect(mapStateToProps, {})(RayonLayer);
