import React, { Component } from "react";
import { connect } from "react-redux";
import {
  edit_driver_inactive,
  get_driver_inactive,
} from "../../actions/tracking";

export class ListInActiveDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      search: "",
      key: "DRIVER",
    };
  }
  componentDidMount() {
    this.get_driver_inactive("", "DRIVER");
  }
  get_driver_inactive = async (query, key) => {
    try {
      const result = await get_driver_inactive({ search: query, key: key });
      this.setState({ lists: result });
    } catch (error) {
      console.log(error);
    }
  };
  handle_input = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handle_select = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handle_submit = (event) => {
    event.preventDefault();
    const { search, key } = this.state;
    this.get_driver_inactive(search, key);
  };
  handle_checkbox = async (event) => {
    const index = parseInt(event.target.getAttribute("data-index"));
    const id = event.target.id;
    const checked = event.target.checked;

    let result;
    if (checked) {
      result = await edit_driver_inactive({
        _id: id,
        inactive: "active",
      });
    } else {
      result = await edit_driver_inactive({
        _id: id,
        inactive: "inactive",
      });
    }
    if (result.message === "success") {
      let inactive = "inactive";
      if (checked) {
        inactive = "active";
      }
      let lists = [...this.state.lists];
      let list = { ...lists[index], inactive: inactive };
      lists[index] = list;

      this.setState({
        lists,
      });
    }
  };
  render() {
    const { lists } = this.state;

    return (
      <React.Fragment>
        <form onSubmit={this.handle_submit}>
          <div className="flex gap-1">
            <div className="flex-1">
              <input
                className="w-full"
                name="search"
                onChange={this.handle_input}
                autoComplete={"off"}
              />
            </div>
            <div className="">
              <select
                className="w-full"
                required
                name="key"
                onChange={this.handle_select}
              >
                <option value={"DRIVER"}>DRIVER NAME</option>
                <option value={"NOPOL"}>License Plat Number</option>
                <option value={"Depo"}>DEPO</option>
              </select>
            </div>
            <div className="h-full">
              <button className="button_main h-10" type="submit">
                Find Driver
              </button>
            </div>
          </div>
        </form>
        <div className="mt-2 text-sm">
          Showing {lists.length} Inactive Driver
        </div>
        <div>
          {lists.map((e, i) => (
            <div key={i} className="hover:bg-gray-100 py-4 px-2 cursor-pointer">
              <div className="grid grid-cols-1">
                <p className="font-semibold">{e["DRIVER"]}</p>
              </div>
              <hr className="my-2" />
              <div className="grid grid-cols-2">
                {/* 
                  License plate number: TEST
                  Depo: DEPO TEST
                  Branch: CABANG TEST
                  Region: REGIONAL TEST
                  Car Type: BLIND VAN
                  Carton Capacity: 100
                  Volume Capacity: 2,38 m³ */}
                <small>License plate number: {e["NOPOL"]}</small>
                <small>Helper: {e["HELPER"]}</small>
                <small>Depo: {e.Depo}</small>
                <small>Car Type: {e.JENIS}</small>
                <small>Carton Capacity: {e.KAPASITAS_CTN}</small>
                <small>Volume Capacity: {e.KAPASITAS_M3}</small>
                <small>
                  Status:{" "}
                  {e.inactive === "inactive" ? (
                    <span className="text-red-700 font-semibold">
                      Not Active
                    </span>
                  ) : (
                    <span className="text-green-700 font-semibold">Active</span>
                  )}
                </small>
              </div>
              <div className="flex gap-1 mt-4">
                <p className="text-gray-700">Activate Driver</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={this.handle_checkbox}
                    id={e._id}
                    data-index={i}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListInActiveDriver);
