//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";

//Personal Component
import Modal from "../common/Modal";
import AddDriver from "../parent/AddDriver";
import DriverHistory from "./DriverHistory";

//Redux function
import { get_driver, turn_on_menu_status } from "../../actions/dataActions";
import {
  api_force_logout,
  edit_driver,
  // get_driver_all,
  get_driver_multi_depo,
  search_driver,
  set_current_driver,
  set_driver_daerah_kerja,
  set_driver_daerah_kerja_2,
  set_driver_daerah_kerja_2_value,
  set_driver_daerah_kerja_value,
  set_modal_current_driver,
  set_modal_add_driver,
  get_online_driver,
  get_active_driver,
  clear_driver_with_location,
} from "../../actions/tracking";
import formatDateLengkap from "../../validation/format-date-lengkap";

//Picture Asset

//General Function

class Driver extends Component {
  state = {
    list_column: 50,
    interval_realtime: 5,
    intervalvalid: {},
    realtimeStatus: "Inactive",
    findSearch: "",
    buttonActive: false,
  };
  componentDidMount() {
    this.filterBerdasarkanAdmin();
  }
  // componentDidUpdate() {
  //   if (
  //     this.props.tracking.driver_list.length > 0 &&
  //     this.state.list_column < this.props.tracking.driver_list.length
  //   ) {
  //     document.getElementById("sidebar").addEventListener("scroll", (e) => {
  //       console.log("scroll", this.state.list_column);
  //       if (
  //         e.target.scrollTop ===
  //         e.target.scrollHeight - e.target.clientHeight
  //       ) {
  //         let number = this.state.list_column;
  //         number = this.state.list_column + 10;
  //         this.setState({
  //           list_column: number,
  //         });
  //       }
  //     });
  //   }
  // }
  componentWillUnmount() {
    clearInterval(this.state.intervalvalid);
  }
  filterBerdasarkanAdmin() {
    this.props.set_driver_daerah_kerja([
      {
        key: "Semua Driver",
        label: "All Drivers",
        value: "Semua Driver",
      },
      { key: "Region", label: "Region", value: "regional" },
      { key: "Branch", label: "Branch", value: "cabang" },
      { key: "Depo", label: "Depo", value: "depo" },
      { key: "Online Driver", label: "Online Driver", value: "Online Driver" },
      { key: "Active Driver", label: "Active Driver", value: "Active Driver" },
    ]);
    const cwk = this.props.auth.job_positions.filter(
      (e) => e.value === this.props.auth.user.jabatan
    )[0].cakupan_wilayah_kerja;
    if (cwk === "depo") {
      this.props.set_driver_daerah_kerja([
        { key: "Depo", label: "Depo", value: "depo" },
      ]);
    }
    if (cwk === "cabang") {
      this.props.set_driver_daerah_kerja([
        { key: "Branch", label: "Branch", value: "cabang" },
        { key: "Depo", label: "Depo", value: "depo" },
      ]);
    }
    if (cwk === "regional") {
      this.props.set_driver_daerah_kerja([
        { key: "Region", label: "Region", value: "regional" },
        { key: "Branch", label: "Branch", value: "cabang" },
        { key: "Depo", label: "Depo", value: "depo" },
      ]);
    }
  }
  handle_filter_daerah_kerja = (event) => {
    if (
      event.value === "Semua Driver" ||
      event.value === "Online Driver" ||
      event.value === "Active Driver"
    ) {
      this.props.set_driver_daerah_kerja_value(event);
      this.props.set_driver_daerah_kerja_2([]);
      this.props.set_driver_daerah_kerja_2_value([]);
    } else {
      this.props.set_driver_daerah_kerja_value(event);
      const daerah_kerja_2 = this.props.data.salesman_filter[event.value].map(
        (e) => {
          if (event.value === "regional") {
            return { key: e.REGIONAL, label: e.REGIONAL, value: e.REGIONAL };
          }
          if (event.value === "cabang") {
            return { key: e.CABANG, label: e.CABANG, value: e.CABANG };
          }
          if (event.value === "depo") {
            return { key: e.Depo, label: e.Depo, value: e.Depo };
          }
          return {};
        }
      );
      this.props.set_driver_daerah_kerja_2(daerah_kerja_2);
    }
  };
  handle_filter_daerah_kerja_2 = (event) => {
    this.props.set_driver_daerah_kerja_2_value(event);
  };
  submitFilter = (event) => {
    event.preventDefault();
    this.fun_get_driver();
    this.setState({
      buttonActive: true,
    });
  };
  fun_get_driver = () => {
    const { driver_daerah_kerja_2_value, driver_daerah_kerja_value } =
      this.props.tracking;
    const country = this.props.auth.country_selected;
    // if (driver_daerah_kerja_value.value === "Semua Driver") {
    //   this.props.get_driver_all();
    // }
    if (
      driver_daerah_kerja_value.value === "Semua Driver" ||
      driver_daerah_kerja_value.value === "Online Driver" ||
      driver_daerah_kerja_value.value === "Active Driver"
    ) {
      if (country === "INDONESIA") {
        const depos = this.props.data.salesman_filter.depo
          .filter((e) => e.folder_id === "63a4341e303d716c2f70bf04")
          .map((x) => {
            return x.Depo;
          });
        this.props.get_driver_multi_depo(
          depos,
          driver_daerah_kerja_value.value
        );
      } else {
        const depos = this.props.data.salesman_filter.depo
          .filter((e) => e)
          .map((x) => {
            return x.Depo;
          });
        this.props.get_driver_multi_depo(depos);
      }
    }
    // if (driver_daerah_kerja_value.value === "Online Driver") {
    //   if (country === "INDONESIA") {
    //     const depo = this.props.data.salesman_filter.depo
    //       .filter((e) => e.folder_id === "63a4341e303d716c2f70bf04")
    //       .map((x) => {
    //         return x.Depo;
    //       });
    //     this.props.get_driver_multi_depo(depo);
    //     // this.props.turn_on_menu({
    //     //   key: "salesman",
    //     //   config: {
    //     //     key: "Depo",
    //     //   },
    //     // });
    //   }
    //   // this.props.get_online_driver(country);
    // }
    // if (driver_daerah_kerja_value.value === "Active Driver") {
    //   if (country === "INDONESIA") {
    //     const depo = this.props.data.salesman_filter.depo
    //       .filter((e) => e.folder_id === "63a4341e303d716c2f70bf04")
    //       .map((x) => {
    //         return x.Depo;
    //       });
    //     this.props.get_driver_multi_depo(depo);
    //     // this.props.turn_on_menu({
    //     //   key: "salesman",
    //     //   config: {
    //     //     key: "Depo",
    //     //   },
    //     // });
    //   }
    //   // this.props.get_active_driver(country);
    // }

    if (driver_daerah_kerja_value.value === "regional") {
      let depos = [];
      driver_daerah_kerja_2_value.forEach((element) => {
        const list_obj_depo = this.props.data.salesman_filter.depo.filter(
          (e) => e.REGION === element.value
        );
        const list_depo = list_obj_depo.map((e) => e.Depo);
        depos = [...depos, ...list_depo];
      });
      this.props.get_driver_multi_depo(depos);
    }
    if (driver_daerah_kerja_value.value === "cabang") {
      let depos = [];
      driver_daerah_kerja_2_value.forEach((element) => {
        const list_obj_depo = this.props.data.salesman_filter.depo.filter(
          (e) => e.CABANG === element.value
        );
        const list_depo = list_obj_depo.map((e) => e.Depo);
        depos = [...depos, ...list_depo];
      });
      this.props.get_driver_multi_depo(depos);
    }
    if (driver_daerah_kerja_value.value === "depo") {
      let depos = driver_daerah_kerja_2_value.map((e) => e.value);
      this.props.get_driver_multi_depo(depos);
    }

    this.props.turn_on_menu_status("driver");
    this.setState({
      list_column: 50,
    });
  };
  detailDriver = (currentDriver) => {
    this.props.set_modal_current_driver(true);
    this.props.set_current_driver(currentDriver);
  };
  onChange = (event) => {
    this.props.set_current_driver({
      ...this.props.tracking.currentDriver,
      [event.target.name]: event.target.value,
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.props.edit_driver(this.props.tracking.currentDriver);
    this.props.set_modal_current_driver(false);
  };
  forceLogout = () => {
    const _id = this.props.tracking.currentDriver._id;
    this.props.api_force_logout(_id);
    this.props.set_modal_current_driver(false);
  };

  search = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  findSearch = (event) => {
    event.preventDefault();
    const { findSearch } = this.state;
    this.props.search_driver(findSearch);
  };

  historyDriver = (currentDriver) => {
    this.props.set_current_driver(currentDriver);
    this.props.clear_driver_with_location();
  };
  realtimeToggle = (p) => {
    const checked = p.target.checked;
    if (checked) {
      this.fun_get_driver();
      let intervalvalid = setInterval(() => {
        this.fun_get_driver();
      }, this.state.interval_realtime * 1000 * 60);
      this.setState({ intervalvalid, realtimeStatus: "Active" });
    } else {
      clearInterval(this.state.intervalvalid);
      this.setState({ realtimeStatus: "Inactive" });
    }
  };
  onSelect = (event) => {
    const value = event.target.value;
    const obj = this.props.tracking.driver_type_car.find(
      (e) => e.JENIS === value
    );
    this.props.set_current_driver({
      ...this.props.tracking.currentDriver,
      JENIS: obj.JENIS ?? "-",
      KAPASITAS_CTN: obj.KAPASITAS_CTN ?? "-",
      KAPASITAS_M3: obj.KAPASITAS_M3 ?? "-",
    });
  };
  on_checked = (event) => {
    const checked = event.target.checked;
    this.props.set_current_driver({
      ...this.props.tracking.currentDriver,
      inactive: checked ? "active" : "inactive",
    });
  };
  render() {
    const { loading_top, salesman_filter } = this.props.data;
    const { country_select } = this.props.auth;
    const {
      driver_daerah_kerja,
      driver_daerah_kerja_value,
      driver_daerah_kerja_2,
      driver_daerah_kerja_2_value,
      driver_list,
      driver_list_with_location,
    } = this.props.tracking;
    const {
      modalDetailDriver,
      currentDriver,
      modalAddDriver,
      driver_type_car,
    } = this.props.tracking;
    const { realtimeStatus } = this.state;
    const { user } = this.props.auth;

    const list_content = driver_list
      .slice(0, this.state.list_column)
      .map((driver, idx) => {
        const onlineStat = driver.status_bike;
        let mssg, bgColor;
        switch (onlineStat) {
          case "bike_green":
            mssg = "Online";
            bgColor = "bg-green-600";
            break;
          case "bike_blue":
            mssg = "Online a few hours ago";
            bgColor = "bg-blue-600";
            break;
          default:
            mssg = "Offline";
            bgColor = "bg-red-600";
        }
        return (
          <div key={idx} className="py-2 cursor-pointer hover:bg-gray-100">
            <div
              className="flex"
              onClick={this.historyDriver.bind(this, driver)}
            >
              <p className="font-semibold flex-1">
                {idx + 1}. {driver["DRIVER"] ? driver["DRIVER"] : "-"}
              </p>
              <div
                className={`${bgColor} text-white rounded-full w-fit px-2 text-xs flex items-center`}
              >
                {mssg}
              </div>
            </div>
            <div
              className="grid grid-cols-2"
              onClick={this.historyDriver.bind(this, driver)}
            >
              <small className="text-sm text-gray-600">
                License plate number: {driver?.["NOPOL"]}
              </small>
              <small className="text-sm text-gray-600">
                Depo: {driver?.["Depo"]}
              </small>
              <small className="text-sm text-gray-600">
                Branch:{" "}
                {
                  salesman_filter.depo.filter(
                    (e) => e.Depo === driver?.Depo
                  )?.[0]?.CABANG
                }
              </small>
              <small className="text-sm text-gray-600">
                Region:{" "}
                {
                  salesman_filter.depo.filter(
                    (e) => e.Depo === driver?.Depo
                  )?.[0]?.REGION
                }
              </small>
              <small className="text-sm text-gray-600">
                Car Type: {driver?.["JENIS"]}
              </small>
              <small className="text-sm text-gray-600">
                Carton Capacity: {driver?.["KAPASITAS_CTN"]}
              </small>
              <small className="text-sm text-gray-600">
                Volume Capacity: {driver?.["KAPASITAS_M3"]} m³
              </small>
            </div>
            <div className="mb-1">
              <small className="text-sm text-gray-600">
                Last Update:{" "}
                {driver?.log.length > 0
                  ? formatDateLengkap(driver.log[0].time_stamp[0])
                  : "-"}
              </small>
            </div>
            <button
              onClick={this.detailDriver.bind(this, driver)}
              className="button_main"
            >
              Edit Driver
            </button>
          </div>
        );
      });
    const filter_navigation = (
      <form onSubmit={this.submitFilter}>
        <p>Working Area Filter</p>
        <Select
          options={driver_daerah_kerja}
          onChange={this.handle_filter_daerah_kerja}
          defaultValue={driver_daerah_kerja_value}
        />
        {driver_daerah_kerja_2.length > 0 && (
          <Select
            className="mt-1"
            options={driver_daerah_kerja_2}
            onChange={this.handle_filter_daerah_kerja_2}
            defaultValue={driver_daerah_kerja_2_value}
            isMulti
          />
        )}
        <div className="flex items-center gap-2">
          <button
            type="onsubmit"
            className="button_main mt-2"
            disabled={loading_top}
          >
            {loading_top ? "Loading" : "Find Driver"}
          </button>
          <button type="onsubmit" title="Refresh Data" className="ml-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-green-700"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </button>
        </div>
      </form>
    );

    let bike_green = 0,
      bike_blue = 0,
      bike_red = 0;
    driver_list.forEach((e) => {
      if (e.status_bike === "bike_green") bike_green++;
      if (e.status_bike === "bike_blue") bike_blue++;
      if (e.status_bike === "bike_red") bike_red++;
    });
    const legenda_list = (
      <div className="text-xs">
        <div className="flex my-0.5">
          <div className={`bg-green-600 w-5 h-5 mr-0.5`}></div>
          <p>
            <span className="align-middle">Online: {bike_green}</span>
          </p>
        </div>
        <div className="flex my-0.5">
          <div className={`bg-blue-600 w-5 h-5 mr-0.5`}></div>
          <p>
            <span className="align-middle">
              Online a few hours ago: {bike_blue}
            </span>
          </p>
        </div>
        <div className="flex my-0.5">
          <div className={`bg-red-600 w-5 h-5 mr-0.5`}></div>
          <p>
            <span className="align-middle">Offline: {bike_red}</span>
          </p>
        </div>
      </div>
    );
    const modalDetailDriverContent = modalDetailDriver && (
      <Modal
        modalSize="small"
        isOpen={modalDetailDriver}
        onClose={() => {
          this.props.set_modal_current_driver(false);
          this.props.set_current_driver({});
        }}
      >
        <div className="box-body grid grid-cols-1 gap-1">
          <form onSubmit={this.handleSubmit}>
            <div className="grid grid-cols-1">
              <label className="font-semibold" htmlFor="DRIVER">
                DRIVER
              </label>
              <input
                type="text"
                id="DRIVER"
                name="DRIVER"
                defaultValue={currentDriver?.DRIVER}
                className="w-full"
                onChange={this.onChange.bind(this)}
              />
            </div>
            <div className="grid grid-cols-1">
              <label className="font-semibold" htmlFor="HELPER">
                HELPER
              </label>
              <input
                type="text"
                id="HELPER"
                name="HELPER"
                defaultValue={currentDriver?.HELPER}
                className="w-full"
                onChange={this.onChange.bind(this)}
              />
            </div>
            <div className="grid grid-cols-1">
              <label className="font-semibold" htmlFor="NOPOL">
                LICENSE PLATE NUMBER
              </label>
              <input
                type="text"
                id="NOPOL"
                name="NOPOL"
                defaultValue={currentDriver?.NOPOL}
                className="w-full"
                onChange={this.onChange.bind(this)}
              />
            </div>
            <div className="grid grid-cols-1">
              <label className="font-semibold" htmlFor="COUNTRY">
                COUNTRY
              </label>
              <select
                id="COUNTRY"
                name="COUNTRY"
                className="w-full"
                onChange={this.onChange.bind(this)}
                defaultValue={currentDriver?.COUNTRY}
                required
              >
                <option value={""}>--Select Country--</option>
                {country_select.map((e, i) => (
                  <option key={i} label={e.label} value={e.value}>
                    {e.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid grid-cols-1">
              <label className="font-semibold" htmlFor="depo">
                DEPO
              </label>
              <select
                id="depo"
                name="Depo"
                defaultValue={currentDriver?.Depo}
                className="w-full"
                onChange={this.onChange.bind(this)}
              >
                <option defaultValue={""} disabled>
                  --Select Depo--
                </option>
                {salesman_filter.depo.map((e, i) => (
                  <option key={i} data-id={e._id} data-value={e.Depo}>
                    {e.Depo}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid grid-cols-2 gap-1">
              <div className="grid grid-cols-1">
                <label className="font-semibold">BRANCH</label>
                <div className="">
                  {
                    salesman_filter?.depo.filter(
                      (e) => e.Depo === currentDriver?.Depo
                    )[0].CABANG
                  }
                </div>
              </div>
              <div className="grid grid-cols-1">
                <label className="font-semibold" htmlFor="regional">
                  REGION
                </label>
                <div className="">
                  {
                    salesman_filter?.depo.filter(
                      (e) => e.Depo === currentDriver?.Depo
                    )[0].REGION
                  }
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 mt-2">
              <label className="font-semibold" htmlFor="JENIS">
                CAR TYPE
              </label>
              <select
                onChange={this.onSelect.bind(this)}
                defaultValue={currentDriver?.JENIS}
                required
              >
                <option defaultValue={""}>--Select Car Type---</option>
                {driver_type_car.map((e, i) => (
                  <option key={i} data-value={e.JENIS}>
                    {e.JENIS}
                  </option>
                ))}
              </select>
              {/* <input
                type="text"
                id="JENIS"
                name="JENIS"
                defaultValue={currentDriver?.JENIS}
                className="w-full"
                onChange={this.onChange.bind(this)}
              /> */}
            </div>
            <div className="grid grid-cols-2">
              <div className="grid grid-cols-1">
                <label className="font-semibold" htmlFor="KAPASITAS_CTN">
                  CARTON CAPACITY
                </label>
                <div>{currentDriver?.KAPASITAS_CTN}</div>
              </div>
              <div className="grid grid-cols-1">
                <label className="font-semibold" htmlFor="KAPASITAS_M3">
                  VOLUME CAPACITY
                </label>
                <div>{currentDriver?.KAPASITAS_M3}</div>
              </div>
            </div>
            <div className="border rounded px-1 py-2 mt-4">
              <div className="flex">
                <p className="font-semibold flex-1">Inactivate Driver</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="inactive"
                    onChange={this.on_checked}
                    checked={
                      currentDriver.inactive === "inactive" ? false : true
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              {currentDriver.inactive === "inactive" ? (
                <div className="border p-4 my-4 bg-red-100 rounded">
                  Driver is <b>inactive</b>!
                </div>
              ) : (
                <div className="border p-4 my-4 bg-green-100 rounded">
                  Driver is <b>Active </b> and can be used
                </div>
              )}
            </div>
            <div className="mt-4">
              <button type="submit" className="button_main w-full">
                EDIT DRIVER
              </button>
            </div>
          </form>
          <hr className="my-4" />
          <div>
            <label className="font-semibold">USAGE STATUS</label>
            {currentDriver.user_devices.length > 0 &&
            currentDriver.user_devices[currentDriver.user_devices.length - 1]
              .device_status === "on" ? (
              <div className="flex items-center">
                <div className="flex-1">
                  <p className="text-green-600 font-semibold">LOGIN</p>
                </div>
                <div>
                  <button
                    className="py-1 px-2 border border-red-600 bg-red-200 rounded text-red-600 font-semibold"
                    onClick={this.forceLogout}
                  >
                    Force Logout
                  </button>
                </div>
              </div>
            ) : (
              <div className="text-red-600 font-semibold">LOGOUT</div>
            )}
          </div>
        </div>
      </Modal>
    );
    let filter_and_find = "";
    if (this.state.buttonActive) {
      filter_and_find = (
        <form onSubmit={this.findSearch}>
          <div className="flex gap-1">
            <div className="w-full my-2">
              <input
                type="text"
                placeholder={`Search Driver Name or License Plate Number`}
                className="w-full"
                name="findSearch"
                autoComplete="off"
                onChange={this.search}
              />
            </div>
            <button className="px-1" type="sumbit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </button>
          </div>
        </form>
      );
    }

    const navigation = (
      <div className="mt-2">
        <button
          className="button_main"
          onClick={() => this.props.set_modal_add_driver(true)}
        >
          Add Driver
        </button>
        <hr className="my-2" />
      </div>
    );
    const modalAddDriverContent = modalAddDriver && (
      <Modal
        modalSize="small"
        isOpen={modalAddDriver}
        onClose={() => this.props.set_modal_add_driver(false)}
      >
        <div className="box-body">
          <AddDriver />
        </div>
      </Modal>
    );
    const realtime_mode = (
      <div className={`grid grid-cols-2 gap-2 mt-2`}>
        <div className="gap-2 flex items-center">
          <label>Interval</label>
          <input
            type="number"
            defaultValue={this.state.interval_realtime}
            onChange={(e) => {
              let value = e.target.value;
              if (value === "") value = 1;
              this.setState({ interval_realtime: parseInt(value) });
            }}
          />
          <label>Minutes</label>
        </div>
        <div className="flex items-center border-l-2 pl-2">
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.realtimeToggle}
              checked={realtimeStatus === "Active" ? true : false}
            />
            <span className="slider round"></span>
          </label>
          <p className="ml-2">Auto {realtimeStatus}</p>
        </div>
      </div>
    );
    if (currentDriver?.DRIVER && !modalDetailDriver) {
      return (
        <div>
          <DriverHistory />
        </div>
      );
    }

    let button_slice;
    if (this.props.tracking.driver_list.length > 0) {
      button_slice = (
        <div className="flex items-center justify-center">
          <button
            className="button_main mt-5"
            onClick={() => {
              let number = this.state.list_column + 50;
              this.setState({ list_column: number });
            }}
          >
            Load More Datas
          </button>
        </div>
      );
    }
    if (
      this.props.tracking.driver_list.length &&
      this.state.list_column > this.props.tracking.driver_list.length
    ) {
      button_slice = (
        <div className="flex items-center justify-center mt-5">
          All Data Has Been Loaded
        </div>
      );
    }
    return (
      <div>
        {!["ASS", "BM"].includes(user.jabatan) && navigation}
        {filter_navigation}
        <p className="my-2 text-gray-600">
          Showing {driver_list_with_location.length} who has the location from{" "}
          {driver_list.length} drivers
        </p>
        {legenda_list}
        {realtime_mode}
        {filter_and_find}
        {list_content}
        {button_slice}
        {modalDetailDriverContent}
        {modalAddDriverContent}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
  auth: state.auth,
  tracking: state.tracking,
});
export default connect(mapStateToProps, {
  get_driver,
  turn_on_menu_status,
  set_driver_daerah_kerja,
  set_driver_daerah_kerja_value,
  set_driver_daerah_kerja_2,
  set_driver_daerah_kerja_2_value,
  get_driver_multi_depo,
  // get_driver_all,
  set_modal_current_driver,
  set_current_driver,
  api_force_logout,
  edit_driver,
  search_driver,
  set_modal_add_driver,
  get_online_driver,
  get_active_driver,
  clear_driver_with_location,
})(Driver);
