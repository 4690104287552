/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { turn_off_menu, turn_on_menu_status } from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL*/

class ShowHide extends Component {
  actived = (event) => {
    const { list_menu_active } = this.props.data;
    const is_visible = list_menu_active.find((e) => e.key === event).active;
    if (is_visible) {
      this.props.turn_off_menu(event);
    } else {
      this.props.turn_on_menu_status(event);
    }
  };

  render() {
    const { list_menu_active } = this.props.data;

    return (
      <main
        className="absolute inset-y-0 right-11 w-[400px] h-full bg-white py-5 pl-5 pr-11 z-[2] overflow-y-auto"
        style={{ scrollbarWidth: "thin" }}
      >
        {/* {renderTest} */}
        <section className="text_bold mb-10">Toggle view shortcut menu</section>
        <section className="flex mb-5">
          <p className="text_medium flex-1">Outlet Management And Expansion</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "distribution")}
              checked={
                list_menu_active.find((e) => e.key === "distribution")
                  .active === true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">
            <span className="circle" id="blue" /> Outlet Competitor Acq. Success
          </p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "competitor_success")}
              checked={
                list_menu_active.find((e) => e.key === "competitor_success")
                  .active === true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">
            <span className="circle" id="red" /> Outlet Competitor Acq. Failed
          </p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "competitor_failed")}
              checked={
                list_menu_active.find((e) => e.key === "competitor_failed")
                  .active === true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">
            <span className="circle" id="yellow" /> Outlet Survey
          </p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "toko_plot")}
              checked={
                list_menu_active.find((e) => e.key === "toko_plot").active ===
                true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">Merchandising</p>
          <label className="switch ">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "merch")}
              checked={
                list_menu_active.find((e) => e.key === "merch").active === true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">Region Branch Depo</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "depo_loc_list")}
              checked={
                list_menu_active.find((e) => e.key === "depo_loc_list")
                  .active === true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">Outlet Omset</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "omset")}
              checked={
                list_menu_active.find((e) => e.key === "omset").active === true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">Salesman Omset</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "omset_salesman")}
              checked={
                list_menu_active.find((e) => e.key === "omset_salesman")
                  .active === true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">Salesman</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "salesman")}
              checked={
                list_menu_active.find((e) => e.key === "salesman").active ===
                true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">Driver</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "driver")}
              checked={
                list_menu_active.find((e) => e.key === "driver").active === true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">Salesman Log</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "salesman_log")}
              checked={
                list_menu_active.find((e) => e.key === "salesman_log")
                  .active === true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">Outlet By Salesman</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "toko_salesman")}
              checked={
                list_menu_active.find((e) => e.key === "toko_salesman")
                  .active === true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">Stock</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "stock")}
              checked={
                list_menu_active.find((e) => e.key === "stock").active === true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">Admin</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "admin")}
              checked={
                list_menu_active.find((e) => e.key === "admin").active === true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">Rayon</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "rayon")}
              checked={
                list_menu_active.find((e) => e.key === "rayon").active === true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">Cycle</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "cycle")}
              checked={
                list_menu_active.find((e) => e.key === "cycle").active === true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">Day</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "day")}
              checked={
                list_menu_active.find((e) => e.key === "day").active === true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        <section className="flex mb-5">
          <p className="text_medium flex-1">Outlet By Salesman</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this.actived.bind(this, "outlet_by_salesman")}
              checked={
                list_menu_active.find((e) => e.key === "outlet_by_salesman")
                  .active === true
                  ? true
                  : false
              }
            />
            <span className="slider round"></span>
          </label>
        </section>

        {/* {getArr.forEach((e) => {
          console.log(e);
          return <h1>test</h1>;
          // <div className="flex mb-5">
          //   <p className="text_medium flex-1">{e.name}</p>
          //   <label className="switch">
          //     <input
          //       type="checkbox"
          //       onChange={this.actived.bind(this, e.key)}
          //       checked={
          //         list_menu_active.find((u) => u.key === e.key).active === true
          //           ? true
          //           : false
          //       }
          //     />
          //     <span className="slider round"></span>
          //   </label>
          // </div>;
        })} */}
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, { turn_on_menu_status, turn_off_menu })(
  ShowHide
);
