const format_date_formal = function (timestamp) {
  const date = new Date(timestamp);
  const months_ina = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agu",
    "Sep",
    "Okt",
    "Nov",
    "Des",
  ];
  const tanggal = date.getDate();
  let month_final = months_ina[date.getMonth()];
  const jam = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const menit =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  let date_final =
    tanggal +
    " " +
    month_final +
    " " +
    date.getFullYear() +
    " " +
    jam +
    ":" +
    menit;
  if (date.getFullYear() === "1970" || !timestamp) {
    return "-";
  }
  return date_final;
};

export default format_date_formal;
