import { bbox } from "@turf/turf";

import sort_lat from "./sort_lat";
import sort_long from "./sort_long";
import st_colors from "./st_colors";
import generate_v_max from "./generate_v_max";

const parameter_selesai = 0.95; //parameter pembagian antara outlet_length temporary looping dan outlet_length yang diharapkan, jika 1 maka sempurna, jika mendekati 1 berarti mendekati sempurna
//jarang akan === 1 sehingga parameternya akan diubah2 sesuai kebutuhan

const rayonisasi_rayon_by_grouping = (body) => {
  let {
    grouping_list,
    jumlah_toko, //number, jumlah toko untuk satu rayon
    jumlah_rayon, //number,  jumlah rayon yang diinginkan untuk dihasilkan
    depo_loc_list,
    mode_nama_rayon,
    nama_rayon_custom,
    total_toko, //number, total toko yang akan dijalankan (dalam satu depo)
    // outlet_list_filter_0,
  } = body;

  grouping_list = grouping_list.map((item, idx) => {
    item.index = idx;
    return item;
  });

  const value_parameter = "outlet_length";

  const total_group = grouping_list.length;
  jumlah_toko = Number(jumlah_toko);
  jumlah_rayon = Number(jumlah_rayon);
  total_toko = Number(total_toko);

  let rayon_list = [];
  let rayon_list_complete = [];

  let imbuhan = "_Rayon_";
  if (mode_nama_rayon === "custom") {
    imbuhan = `_${nama_rayon_custom}_`;
  }

  depo_loc_list.forEach((d) => {
    for (let index = 0; index < jumlah_rayon; index++) {
      const element = `${d.value}${imbuhan}${index + 1}`;
      const object = {
        name: element,
        depo_id: d._id,
        depo_string: d.value,
        date_created: Date.now(),
        date_updated: Date.now(),
      };
      rayon_list.push(element);
      rayon_list_complete.push(object);
    }
  });

  depo_loc_list.forEach((d) => {
    /*STEP 1 Deklarasi variabel penting*/

    //index_loop_rayon: parameter index untuk looping rayon secara keseluruhan, jika index_loop_rayon sudah === maka keluar dari looping

    let index_loop_rayon = 0;

    const features = grouping_list.map((t) => {
      return { type: "Feature", properties: {}, geometry: t.location };
    });
    const geojson = {
      type: "FeatureCollection",
      features: [...features],
    };
    const [minLng, minLat, maxLng, maxLat] = bbox(geojson);
    const delta_long = maxLng - minLng;
    const delta_lat = maxLat - minLat;
    const ratio = delta_lat / delta_long;

    const v_max = generate_v_max(jumlah_rayon, ratio);

    //pengali: pengali untuk setiap v_index
    const pengali = Math.floor(jumlah_rayon / v_max);

    //mod : sisa pembagian dari 3 untuk menentukan v_index mana yang akan dilebihkan 1 pengali nya
    const mod = jumlah_rayon % v_max;

    //vertical_range : array looping pertama, untuk kasus ini akan dilooping vertikal dulu
    let vertical_range = [];

    /*STEP 2 Looping untuk generate pengali_n (bagian dari total toko) untuk masing baris vertikal*/
    for (let n = 0; n < v_max; n++) {
      //pengali_n : bagian untuk masing-masing baris vertikal, misal jika jumlah_rayon = 3 , maka [1/3, 1/3, 1/3]
      const pengali_n =
        mod >= n + 1
          ? (1 / jumlah_rayon) * (pengali + 1)
          : (1 / jumlah_rayon) * pengali;

      //jumlah_item_v : jumlah omset untuk masing-masing baris vertikal, misal
      const jumlah_item_v = Math.floor(pengali_n * total_toko);

      //jumlah_rayon_horisontal : berapa rayon yang dibagi secara horisontal pada suatu baris vertikal
      const jumlah_rayon_horisontal = mod >= n + 1 ? pengali + 1 : pengali;

      vertical_range.push({
        pengali_n,
        jumlah_rayon_horisontal,
        jumlah_item_v,
      });
    }

    //STEP 4 Urutkan toko_list berdasarkan latitude karena setelah ini akan dikelompokan secara vertikal
    grouping_list = sort_lat(grouping_list, false);

    /*STEP 3 Modifikasi vertical_range untuk mendapatkan range start & end dari baris vertikal, membutuhkan map dari vertical_range original karena membutuhkan nilai dari elemen sebelumnya*/
    vertical_range = vertical_range.map(
      ({ jumlah_item_v, jumlah_rayon_horisontal }, idx) => {
        /*
      start: index awal dari range pembagian vertikal, end: index akhir dari range pembagian vertikal

      start_item: sum omset awal dari range pembagian vertikal, end_item: sum omset akhir dari range pembagian vertikal
       */
        let start_item, end_item;

        if (idx === 0) {
          start_item = 0;
        } else {
          //sum omset dari semua toko sebelum index vertikal yang sedang looping
          start_item = vertical_range
            .slice(0, idx)
            .map((r) => r.jumlah_item_v)
            .reduce((a, b) => a + b, 0);
        }

        if (idx === v_max - 1) {
          end_item = total_toko;
        } else {
          end_item = start_item + jumlah_item_v;
        }

        //konversi dari start_item menjadi start_idx
        let start_item_temporary = 0;
        let start_idx = 0;
        let index_param = 0;

        if (idx === 0) {
          start_idx = 0;
        } else {
          for (let index = 0; index_param < parameter_selesai; index++) {
            //save index ke start_idx
            start_idx = index;
            //slice toko yang sudah urut dengan index
            start_item_temporary = grouping_list
              .slice(0, index)
              .map((t) => t[value_parameter]);
            //sum omsetnya
            start_item_temporary = start_item_temporary.reduce(
              (a, b) => a + b,
              0
            );

            index_param = start_item_temporary / start_item;

            // const object = {
            //   index,
            //   start_item_temporary,
            //   index_param,
            // };

            // console.table(object);
          }
        }

        //konversi dari end_item menjadi end_idx
        let end_omset_temporary = 0;
        let end_idx = 0;

        if (idx === v_max - 1) {
          end_idx = total_group;
        } else {
          for (
            let index = 0;
            end_omset_temporary / end_item < parameter_selesai;
            index++
          ) {
            //save index ke start_idx
            end_idx = index;
            //slice toko yang sudah urut dengan index
            end_omset_temporary = grouping_list
              .slice(0, index)
              .map((t) => t[value_parameter]);
            //sum omsetnya
            end_omset_temporary = end_omset_temporary.reduce(
              (a, b) => a + b,
              0
            );
          }
        }
        return {
          start_item,
          end_item,

          start_idx,
          end_idx,

          jumlah_rayon_horisontal,
        };
      }
    );

    vertical_range = vertical_range.map(
      ({ jumlah_rayon_horisontal, start_idx, end_idx }) => {
        let item_list_v = grouping_list.slice(start_idx, end_idx);

        /*STEP XX urutkan toko_list secara longitude karena setelah ini akan dikelompokan secara horisontal*/
        item_list_v = sort_long(item_list_v, true);

        //horisontal_range: array range untuk pembagian horisonal, isinya start & end dari rayon yang akan dibuat
        let horisontal_range = [];

        for (let h = 0; h < jumlah_rayon_horisontal; h++) {
          let start_h_omset, end_h_omset;
          start_h_omset = parseInt(h * jumlah_toko);
          end_h_omset = parseInt(start_h_omset + jumlah_toko);

          //konversi dari start_h_omset menjadi start_h_idx
          let start_h_omset_temporary = 0;
          let start_h_idx = 0;

          if (h === 0) {
            start_h_idx = 0;
          } else {
            for (
              let index = 0;
              start_h_omset_temporary / start_h_omset < parameter_selesai;
              index++
            ) {
              //save index ke start_h_idx
              start_h_idx = index;
              //slice toko yang sudah urut dengan index
              start_h_omset_temporary = item_list_v
                .slice(0, index)
                .map((t) => t[value_parameter]);
              //sum omsetnya
              start_h_omset_temporary = start_h_omset_temporary.reduce(
                (a, b) => a + b,
                0
              );
            }
          }

          //konversi dari end_h_omset menjadi end_h_idx
          let end_h_omset_temporary = 0;
          let end_h_idx = 0;

          if (h === jumlah_rayon_horisontal - 1) {
            end_h_idx = item_list_v.length;
          } else {
            for (
              let index = 0;
              end_h_omset_temporary / end_h_omset < parameter_selesai;
              index++
            ) {
              //save index ke end_h_idx
              end_h_idx = index;
              //slice toko yang sudah urut dengan index
              end_h_omset_temporary = item_list_v
                .slice(0, index)
                .map((t) => t[value_parameter]);
              //sum omsetnya
              end_h_omset_temporary = end_h_omset_temporary.reduce(
                (a, b) => a + b,
                0
              );
            }
          }

          horisontal_range.push({
            start_h_omset,
            end_h_omset,

            start_h_idx,
            end_h_idx,
          });
        }

        return {
          start_idx,
          end_idx,

          jumlah_rayon_horisontal,
          horisontal_range,
        };
      }
    );

    //STEP 5 mulai looping pembagian vertikal
    vertical_range.forEach(({ start_idx, end_idx, horisontal_range }) => {
      //STEP 6 hanya ambil toko dari range start dan end vertikal, karena sudah diurutkan dengan latitudenya
      let item_list_v = grouping_list.slice(start_idx, end_idx);

      /*STEP 7 urutkan toko_list secara longitude karena setelah ini akan dikelompokan secara horisontal*/
      item_list_v = sort_long(item_list_v, true);

      //STEP 8 looping bagikan secara horisontal toko_list ke kotak-kotak sebanyak jumlah_toko_final (sudah ada array horisontal_range di setiap element array range)
      horisontal_range.forEach(({ start_h_idx, end_h_idx }) => {
        //STEP 9 index_loop_rayon bertambah 1 untuk membedakan dengan rayon lain
        index_loop_rayon = index_loop_rayon + 1;

        //STEP 10 value_string: nama rayon diambil dari nama DEPO dan nomor index
        const value_string = `${d.value}${imbuhan}${index_loop_rayon}`;

        //STEP 11 hanya ambil toko dari range start dan end horisontal, karena sudah diurutkan dengan longitudenya
        const item_list_h = item_list_v.slice(start_h_idx, end_h_idx);
        item_list_h.forEach((item) => {
          //STEP 12 masukan nilai rayon ke toko-toko yang sudah dislice
          grouping_list[item.index].rayon = `${value_string}`;
        });
      });
    });

    //LOOPING RAYONISASI SELESAI
  });

  //merapikan hasil rayonisasi dan mengisi atribut yang diperlukan
  rayon_list_complete = rayon_list_complete.map((r, idx) => {
    const list = grouping_list.filter((t) => t.rayon === r.name);

    let long = list.map((t) => t.location.coordinates[0]);
    let lat = list.map((t) => t.location.coordinates[1]);
    long = long.reduce((p, a) => p + a, 0) / long.length; //average long
    lat = lat.reduce((p, a) => p + a, 0) / lat.length; //average lat

    let outlet_length = list.map((t) => t.outlet_length);
    outlet_length = outlet_length.reduce((p, a) => p + a, 0);
    const list_length = list.length;

    const item_list = list.map((t) => t.name);

    r.item_list = item_list;
    r.location = {
      type: "Point",
      coordinates: [long, lat],
    };
    r.list_length = list_length;
    r.outlet_length = outlet_length;

    let index = idx;
    if (idx > 15) {
      index = idx % 16;
    }
    r.color = st_colors[index];
    r.list = list;
    return r;
  });

  return { grouping_list, rayon_list, rayon_list_complete };
};

export default rayonisasi_rayon_by_grouping;
