/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/
import generate_array_color from "../../validation/generate_array_color";
import generate_color_map from "../../validation/generate_color_map";

/*NON IMPORT*/

class OUTLET_LAYER extends Component {
  state = {
    content_render: null,
  };

  render_data = () => {
    let {
      legend_color_toko_by_salesman,
      depo_list,
      div_toko,
      competitor_code_list,
      type_outlet_list,
      segment_list,
      depo_loc_list,
      cycle_select,
      list_menu_active,
      salesman_select,
      outlet_list_filter_0,
      outlet_list_select_0,
      rayon_select,
      rayon_list_database,
      outlet_mode,
      filter_outlet_object,
    } = this.props.data;
    const { grouping_list } = this.props.map;

    const { outlet_multi_select_data } = this.props.data;

    const rayon_object = rayon_list_database.find(
      (r) => r._id === rayon_select
    );
    const plan_list = rayon_object?.plan_list ? rayon_object?.plan_list : [];
    const menu_status = list_menu_active.find((e) => e.key === "distribution");
    const mode = menu_status?.config?.mode;
    const active = menu_status?.active;

    let circle, text_final, array_name, array_color, new_features;

    const data_map = outlet_multi_select_data
      ? outlet_list_select_0
      : outlet_list_filter_0;

    if (data_map?.length > 0) {
      if (mode) {
        if (mode === "salesman_color") {
          //name
          legend_color_toko_by_salesman = [
            ...new Map(
              legend_color_toko_by_salesman.map((item) => [item["kode"], item])
            ).values(),
          ];
          array_name = legend_color_toko_by_salesman.map((e) => e.kode);
          array_name = array_name.length > 0 ? array_name : ["tes"];
          //color
          array_color = generate_array_color(array_name);
          //text
          text_final = "salesman_color";
        } else if (
          [
            "depo_filter_array",
            "system_filter_array",
            "segment_filter_array",
            "type_filter_array",
            "list_menu_active",
            "rayon_filter_array",
            "day_filter_array",
            "cycle_filter_array",
            "provinsi_filter_array",
            "kabupaten_filter_array",
            "kecamatan_filter_array",
            "kelurahan_filter_array",
          ].includes(mode)
        ) {
          const array = this.props.data[mode];
          array_name = array.map((e) => e.name);
          array_color = array.map((e) => e.color);
          array_color.push("#575757");
          text_final = filter_outlet_object[mode].properties_name;
        } else if (mode === "KELURAHAN") {
          //name
          array_name = grouping_list.map((item) => item.name);
          array_name = array_name.length > 0 ? array_name : ["tes"];
          //color
          array_color = generate_array_color(array_name);
          //text
          text_final = "KELURAHAN";
        } else if (mode === "KECAMATAN") {
          //name
          array_name = grouping_list.map((item) => item.name);
          array_name = array_name.length > 0 ? array_name : ["tes"];
          //color
          array_color = generate_array_color(array_name);
          //text
          text_final = "KECAMATAN";
        } else if (mode === "salesman_color_new") {
          //name
          legend_color_toko_by_salesman = [
            ...new Map(
              legend_color_toko_by_salesman.map((item) => [item["kode"], item])
            ).values(),
          ];
          array_name = legend_color_toko_by_salesman.map((e) => e.kode);
          array_name = array_name.length > 0 ? array_name : ["tes"];
          //color
          array_color = legend_color_toko_by_salesman.map((e) => e.color);
          array_color = array_color.length > 0 ? array_color : ["#575757"];
          array_color.push("#575757");
          //text
          text_final = "kode_new";
        } else if (mode === "rayon") {
          //name
          let list = rayon_list_database.filter((r) => !r.is_hide);
          list = [...new Map(list.map((item) => [item["_id"], item])).values()];
          array_name = list.map((e) => e._id);
          array_name = array_name?.length > 0 ? array_name : ["tes"];
          //color
          array_color = list.map((e) => e.color);
          array_color = array_color?.length > 0 ? array_color : ["#575757"];
          array_color.push("#575757");
          //text
          text_final = "rayon_id";
        } else if (mode === "cycle") {
          //name
          array_name = ["cycle 1", "cycle 2"];
          //color
          array_color = generate_array_color(array_name);
          array_color = array_color.length > 0 ? array_color : ["#575757"];
          //text
          text_final = "cycle";
        } else if (mode === "day") {
          //name
          const plan = plan_list.find(
            (p) =>
              // p.cycle === cycle_select &&
              p.salesman_id === salesman_select._id
          );
          let day_list = plan?.day_list ? plan?.day_list : [];

          day_list = day_list.map((d) => {
            const name = `${salesman_select?._id}_${cycle_select}_${d.name}`;
            return { ...d, name };
          });
          let list = day_list;

          list = [
            ...new Map(list.map((item) => [item["name"], item])).values(),
          ];
          array_name = list.map((e) => e.name);
          array_name = array_name.length > 0 ? array_name : ["tes"];
          //color
          array_color = list.map((e) => e.color);
          array_color = array_color.length > 0 ? array_color : ["#575757"];
          array_color.push("#575757");
          //text
          text_final = "plan_value";
        } else if (mode === "SYSTEM") {
          //name
          competitor_code_list = competitor_code_list.filter(
            (item) => item.text
          );
          competitor_code_list = [
            ...new Map(
              competitor_code_list.map((item) => [item["text"], item])
            ).values(),
          ];
          competitor_code_list = competitor_code_list.filter(
            (item) => item.text
          );
          array_name = competitor_code_list.map((e) => e.text);
          array_name = array_name.length > 0 ? array_name : ["test"];
          //color
          array_color = competitor_code_list.map((e) => e.color);
          array_color = array_color.length > 0 ? array_color : ["#575757"];
          array_color.push("#575757");
          //text
          text_final = "SYSTEM";
        } else if (mode === "Type Outlet") {
          //name
          type_outlet_list = type_outlet_list.filter((item) => item.text);
          type_outlet_list = [
            ...new Map(
              type_outlet_list.map((item) => [item["text"], item])
            ).values(),
          ];
          array_name = type_outlet_list.map((e) => e.text);
          array_name = array_name.length > 0 ? array_name : ["test"];

          //color
          array_color = type_outlet_list.map((e) => e.color);
          array_color = array_color.length > 0 ? array_color : ["#575757"];
          array_color.push("#575757");
          //text
          text_final = "Type Outlet";
        } else if (mode === "SEGMENT") {
          //name
          segment_list = segment_list.filter((item) => item.text);
          segment_list = [
            ...new Map(
              segment_list.map((item) => [item["text"], item])
            ).values(),
          ];
          array_name = segment_list.map((e) => e.text);
          array_name = array_name.length > 0 ? array_name : ["test"];
          //color
          array_color = segment_list.map((e) => e.color);
          array_color = array_color.length > 0 ? array_color : ["test"];
          array_color.push("#575757");
          //text
          text_final = "SEGMENT";
        } else if (mode === "depo_loc_list") {
          //name
          depo_loc_list = [
            ...new Map(
              depo_loc_list.map((item) => [item["Depo"], item])
            ).values(),
          ];
          array_name = depo_loc_list.map((d) => d.Depo);
          array_name = array_name.length > 0 ? array_name : ["test"];
          //color
          array_color = generate_array_color(array_name);
          //text
          text_final = "Depo";
        } else if (mode === "depo_color" || mode === "Depo") {
          //name
          depo_list = [
            ...new Map(depo_list.map((item) => [item["text"], item])).values(),
          ];
          array_name = depo_list.map((d) => d.text);
          array_name = array_name.length > 0 ? array_name : ["test"];
          //color
          array_color = generate_array_color(array_name);
          //text
          text_final = "Depo";
        } else {
          //name
          depo_list = [
            ...new Map(depo_list.map((item) => [item["text"], item])).values(),
          ];
          array_name = depo_list.map((d) => d.text);
          array_name = array_name.length > 0 ? array_name : ["test"];
          //color
          array_color = generate_array_color(array_name);
          //text
          text_final = "Depo";
        }
        circle = generate_color_map(array_name, array_color, text_final);
      } else {
        circle = "#575757";
      }
      new_features = data_map.map((e) => {
        let salesman_color = e.kunjungan_arr.find((k) =>
          k.NamaSales.includes(div_toko)
        );
        salesman_color = `${salesman_color?.KodeSales}_${e.Depo}`;
        //generate atribut for planning color
        let kunjungan_arr_new = e?.kunjungan_arr_new
          ? e?.kunjungan_arr_new
          : [];
        const kunjungan_arr_select = kunjungan_arr_new.find(
          (p) => p?.salesman_id === salesman_select?._id
          // && p?.cycle === cycle_select
        );
        //day = satuan paling kecil
        const plan_value = String(
          `${kunjungan_arr_select?.salesman_id}_${kunjungan_arr_select?.cycle}_${kunjungan_arr_select?.day}`
        );
        const cycle = e.cycle ? e.cycle : "";
        const Depo = e.Depo ? e.Depo : "";
        return {
          type: "Feature",
          properties: { ...e, salesman_color, plan_value, cycle, Depo },
          geometry: e.location,
        };
      });
    }
    let content_render = null;
    if (data_map.length > 0) {
      let geojson = {
        type: "FeatureCollection",
        features: new_features,
      };

      if (outlet_mode === "cluster") {
        content_render = (
          <main>
            <Source
              key="distribution_cluster_src"
              id="distribution_cluster_src"
              type="geojson"
              data={geojson}
              generateId={true}
              cluster={true}
              clusterMaxZoom={14}
              clusterRadius={50}
            />
            <Layer
              id="distribution_cluster"
              key="distribution_cluster"
              before="depo_loc_list"
              source="distribution_cluster_src"
              type="circle"
              layout={{
                visibility: active ? "visible" : "none",
              }}
              paint={{
                "circle-color": [
                  "step",
                  ["get", "point_count"],
                  "#51bbd6",
                  100,
                  "#f1f075",
                  750,
                  "#f28cb1",
                ],
                "circle-radius": [
                  "step",
                  ["get", "point_count"],
                  20,
                  100,
                  30,
                  750,
                  40,
                ],
              }}
            />
            <Layer
              id="cluster_count_distribution"
              key="cluster_count_distribution"
              before="depo_loc_list"
              type="symbol"
              source="distribution_cluster_src"
              filter={["has", "point_count"]}
              layout={{
                "text-field": "{point_count_abbreviated}",
                "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                "text-size": 12,
                visibility: active ? "visible" : "none",
              }}
            />
            <Layer
              id="uncluster_count_distribution"
              key="uncluster_count_distribution"
              before="depo_loc_list"
              type="circle"
              source="distribution_cluster_src"
              filter={["!", ["has", "point_count"]]}
              paint={{
                "circle-color": "#11b4da",
                "circle-radius": 4,
                "circle-stroke-width": 1,
                "circle-stroke-color": "#fff",
              }}
            />
          </main>
        );
      } else {
        content_render = (
          <main>
            <Source
              key="distribution_src"
              id="distribution_src"
              type="geojson"
              data={geojson}
            />
            <Layer
              id="distribution"
              key="distribution"
              before="depo_loc_list"
              type="circle"
              source="distribution_src"
              layout={{
                visibility: active ? "visible" : "none",
              }}
              filter={["all", ["!=", "id", -1]]}
              paint={{
                "circle-color": circle,
                "circle-radius": 5,
                "circle-stroke-width": 1,
                "circle-stroke-color": "black",
                "circle-opacity": 1,
              }}
              onLeave={this.onLeave}
              onClick={this.props.layerOnClick.bind(this, "modal_distribution")}
            />
          </main>
        );
      }
    }
    return {
      content_render,
      active_before: active,
      mode_before: mode,
    };
  };

  componentDidMount() {
    const result = this.render_data();
    const { content_render, active_before, mode_before } = result;
    this.setState({ content_render, active_before, mode_before });
  }

  componentDidUpdate(prevProps) {
    const status_action_after = this.props.map.status_action;
    const status_action_before = prevProps.map.status_action;
    const outlet_list_select_0_after =
      this.props.data.outlet_list_select_0.length;
    const outlet_list_select_0_before =
      prevProps.data.outlet_list_select_0.length;
    if (
      status_action_after !== status_action_before ||
      outlet_list_select_0_after !== outlet_list_select_0_before
    ) {
      const result = this.render_data();
      const { content_render, active_before, mode_before } = result;
      this.setState({ content_render, active_before, mode_before });
    }
  }

  render() {
    //local storage

    //local state
    const { content_render } = this.state;

    //global props

    //content
    return content_render;
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(OUTLET_LAYER);
