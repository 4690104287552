//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
//Redux function
import {
  get_toko_rayon_count,
  get_competitor_count,
  get_all_city_rayon,
} from "../../actions/dataActions";
//Picture Asset
//General Function
class ProgresRayon extends Component {
  state = {};
  componentDidMount() {
    this.props.get_toko_rayon_count();
    this.props.get_competitor_count();
    this.props.get_all_city_rayon();
  }
  render() {
    const { toko_rayon_count, competitor_count, city_rayon } = this.props.data;
    let percent = 0;
    if (competitor_count > 0) {
      percent = (toko_rayon_count / competitor_count) * 100;
    }
    return (
      <main>
        <p className="text_bold">
          Progress of competitor store rayonization process
        </p>
        <p className="text_inferior">Total competitor store</p>
        <p className="text_bold">
          {new Intl.NumberFormat("id-ID", {
            style: "decimal",
          }).format(competitor_count)}
        </p>
        <p className="text_inferior">Total rayonized store</p>
        <p className="text_bold">
          {new Intl.NumberFormat("id-ID", {
            style: "decimal",
          }).format(toko_rayon_count)}
        </p>
        <p className="text_inferior">Percentage</p>
        <p className="text_bold">{percent.toFixed(2)} %</p>
        <p className="text_inferior">Rayonized city/regency</p>
        <p className="text_bold">{city_rayon.join(",")}</p>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, {
  get_toko_rayon_count,
  get_competitor_count,
  get_all_city_rayon,
})(ProgresRayon);
