/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Title,
  SubTitle,
} from "chart.js";
import Select from "react-select";

/*PERSONAL COMPONENT*/
import CompetitorFailedDownload from "./CompetitorFailedDownload";
import ON_FLY from "../main/ON_FLY";

/*REDUX FUNCTION*/
import {
  get_all_failed_competitor,
  get_competitor_count,
  get_template_list,
  turn_on_menu_status,
  set_config_menu,
  set_list,
  set_active,
} from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL*/
import sort_array from "../../validation/sort_array";
import st_colors from "../../validation/st_colors.json";

/*NON IMPORT*/
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Title,
  SubTitle
);

class COMPETITOR_FAILED_MENU extends Component {
  state = {
    view_list_status: false,
    sort_value: "jumlah_toko",
    sort_value_reason: "jumlah_toko",
    showTotalToko: [],
  };

  componentDidMount() {
    this.props.turn_on_menu_status("competitor_failed");
    let { competitor_failed_list } = this.props.data;
    if (competitor_failed_list.length === 0) {
      this.props.get_all_failed_competitor();
      this.props.get_template_list();
      this.props.get_competitor_count();
    }
  }

  toggle_view_list_status = () => {
    this.setState({ view_list_status: !this.state.view_list_status });
  };

  on_change = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  on_click = (item, mode) => {
    const list_menu_active_after = this.props.data.list_menu_active;
    let config = list_menu_active_after.find(
      (e) => e.key === "competitor_failed"
    )?.config;
    let depo_filter = config?.depo_filter ? config?.depo_filter : [];
    let reason_filter = config?.reason_filter ? config?.reason_filter : [];

    let list = [];
    let is_hide_all_depo = config?.is_hide_all_depo
      ? config?.is_hide_all_depo
      : false;
    let is_hide_all_reason = config?.is_hide_all_reason
      ? config?.is_hide_all_reason
      : false;

    //filter dengan 2 parameter cukup challenging karena harus menggunakan logika AND untuk menyambungkan keduanya, dua-duanya dijadikan parameter secara bersamaan

    if (mode === "depo") {
      /*DEPO*/

      if (item === "all") {
        const { competitor_failed_list_origin } = this.props.data;
        //1. is_hide_all_depo
        is_hide_all_depo = false;
        //2. depo list
        depo_filter = [
          ...new Set(competitor_failed_list_origin.map((t) => t.Depo)),
        ];
        //3. toko_list
        list = competitor_failed_list_origin;
      } else if (item === "not_all") {
        //1. is_hide_all_depo
        is_hide_all_depo = true;
        //2. depo_list
        depo_filter = [];
        //3. toko_list
        list = [];
      } else {
        //1. is_hide_all_depo
        is_hide_all_depo = true;
        //2. depo_list
        if (depo_filter.includes(item)) {
          depo_filter = depo_filter.filter((d) => d !== item);
        } else {
          depo_filter.push(item);
        }
        //3. toko_list
        list = this.props.data.competitor_failed_list_origin.filter((t) =>
          depo_filter.includes(t.Depo)
        );
      }
      //hanya dijalankan jika array yg lain sudah terisi
      if (reason_filter.length > 0) {
        //toko_list filter kedua
        list = list.filter((t) => reason_filter.includes(t.failed_reason));
      }
    } else if (mode === "reason") {
      /*REASON*/

      if (item === "all") {
        const { competitor_failed_list_origin } = this.props.data;
        //1. is_hide_all_reason
        is_hide_all_reason = false;
        //2. reason_filter
        reason_filter = [
          ...new Set(competitor_failed_list_origin.map((t) => t.failed_reason)),
        ];
        //3. toko_list
        list = competitor_failed_list_origin;
      } else if (item === "not_all") {
        //1. is_hide_all_reason
        is_hide_all_reason = true;
        //2. reason_filter
        reason_filter = [];
        //3. toko_list
        list = [];
      } else {
        //1. is_hide_all_depo
        is_hide_all_reason = true;
        //2. reason_filter
        if (reason_filter.includes(item)) {
          reason_filter = reason_filter.filter((d) => d !== item);
        } else {
          reason_filter.push(item);
        }
        //3. toko_list
        list = this.props.data.competitor_failed_list_origin.filter((t) =>
          reason_filter.includes(t.failed_reason)
        );
      }
      //hanya dijalankan jika array yg lain sudah terisi
      if (depo_filter.length > 0) {
        //toko_list filter kedua
        list = list.filter((t) => depo_filter.includes(t.Depo));
      }
    }

    config.is_hide_all_depo = is_hide_all_depo;
    config.is_hide_all_reason = is_hide_all_reason;
    config.depo_filter = depo_filter;
    config.reason_filter = reason_filter;
    if (item === "all" || item === "not_all") {
      if (item === "all") {
        this.props.set_active("competitor_failed", true);
        this.props.set_list({
          text: "competitor_failed_list",
          list,
        });
      }
      if (item === "not_all") {
        this.props.set_active("competitor_failed", false);
      }
    } else {
      this.props.set_config_menu("competitor_failed", config);
      this.props.set_list({
        text: "competitor_failed_list",
        list,
      });
    }
    // this.props.set_config_menu("competitor_failed", config);
    //   this.props.set_list({
    //     text: "competitor_failed_list",
    //     list,
    //   });
  };

  on_change_select(e) {
    this.setState({ showTotalToko: e });
    const list_menu_active_after = this.props.data.list_menu_active;
    let config = list_menu_active_after.find(
      (e) => e.key === "competitor_failed"
    )?.config;
    let depo_filter = config?.depo_filter ? config?.depo_filter : [];
    let list = [];
    let is_hide_all_depo = config?.is_hide_all_depo
      ? config?.is_hide_all_depo
      : false;

    //1. is_hide_all_depo
    is_hide_all_depo = true;
    //2. depo_list
    depo_filter = e.map((e) => e.value);
    //3. toko_list
    list = this.props.data.competitor_failed_list_origin.filter((t) =>
      depo_filter.includes(t.Depo)
    );

    config.is_hide_all_depo = is_hide_all_depo;
    config.depo_filter = depo_filter;

    this.props.set_config_menu("competitor_failed", config);
    this.props.set_list({
      text: "competitor_failed_list",
      list,
    });
  }

  render() {
    const { view_list_status, showTotalToko } = this.state;

    let {
      competitor_failed_list,
      competitor_failed_list_origin,
      loading_status,
      loading_item,
      competitor_count,
      template_list,
    } = this.props.data;

    const list_menu_active_after = this.props.data.list_menu_active;

    const config = list_menu_active_after.find(
      (e) => e.key === "competitor_failed"
    )?.config;

    let depo_filter = config?.depo_filter ? config?.depo_filter : [];

    const is_hide_all_reason = config.is_hide_all_reason
      ? config.is_hide_all_reason
      : false;
    let reason_filter = config?.reason_filter ? config?.reason_filter : [];

    let percentage = 0;
    if (competitor_count > 0 && competitor_failed_list_origin.length > 0) {
      percentage = parseFloat(
        (competitor_failed_list_origin.length / competitor_count) * 100
      ).toFixed(2);
    }

    template_list = template_list.map((t, idx) => {
      let index = idx;
      if (idx > st_colors.length - 1) {
        index = idx % st_colors.length;
      }
      const jumlah_toko = competitor_failed_list_origin.filter(
        (toko) => toko?.failed_reason === t?.text
      )?.length;

      let jumlah_toko_after = jumlah_toko;

      if (depo_filter.length > 0) {
        jumlah_toko_after = competitor_failed_list_origin.filter(
          (toko) =>
            depo_filter.includes(toko?.Depo) && toko?.failed_reason === t?.text
        )?.length;
      }

      t.color = st_colors[index];
      t.jumlah_toko = jumlah_toko;
      t.jumlah_toko_after = jumlah_toko_after;
      return t;
    });

    if (this.state.sort_value_reason === "jumlah_toko") {
      template_list = sort_array(
        template_list,
        this.state.sort_value_reason,
        false
      );
    } else {
      template_list = sort_array(
        template_list,
        this.state.sort_value_reason,
        true
      );
    }

    const name_array_reason = template_list.map((e) => e.text);
    const value_array_reason = template_list.map((e) => e.jumlah_toko);
    const color_array_reason = template_list.map((e) => e.color);

    const chart_data_reason = {
      labels: name_array_reason,
      datasets: [
        {
          label: "BU",
          data: value_array_reason,
          backgroundColor: color_array_reason,
          hoverOffset: 4,
        },
      ],
    };
    const options = {
      animation: false,
      maintainAspectRatio: true,
      responsive: true,
      title: {
        display: false,
        text: "BU",
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    let depoDropDown = [
      ...new Set(competitor_failed_list_origin.map((t) => t.Depo)),
    ];

    depoDropDown = depoDropDown.map((t, idx) => {
      let index = idx;
      if (idx > st_colors.length - 1) {
        index = idx % st_colors.length;
      }
      const jumlah_toko = competitor_failed_list_origin.filter(
        (toko) => toko?.Depo === t
      )?.length;
      const color = st_colors[index];

      return { text: t, color, jumlah_toko, value: t, label: t };
    });

    if (this.state.sort_value === "jumlah_toko") {
      depoDropDown = sort_array(depoDropDown, this.state.sort_value, false);
    } else {
      depoDropDown = sort_array(depoDropDown, this.state.sort_value, true);
    }
    let resume_content;

    if (showTotalToko.length > 0) {
      resume_content = (
        <table className="table_lite mb-2">
          <thead>
            <tr>
              <th>Depo Name</th>
              <th>Total Store</th>
              <th>Filtered Total Store</th>
              <th>Filtered Total Revenue</th>
            </tr>
          </thead>
          <tbody>
            {showTotalToko.map((e, idx) => {
              const match = competitor_failed_list.filter(
                (x) => x.Depo === e.label
              );
              let depo = e?.text;
              const result = competitor_failed_list.filter(
                (z) => z.Depo === depo
              );
              let omset_arr = result.map((x) => {
                if (!isNaN(x.OMSET)) {
                  return x.OMSET;
                } else {
                  return 0;
                }
              });
              let omsetTotal = omset_arr.reduce((a, b) => a + b, 0);
              // console.log(omsetTotal);
              // omsetTotal = omsetTotal.toFixed(1);
              // omsetTotal = parseFloat(omsetTotal);
              return (
                <tr className="text-center" key={idx}>
                  <td className="text-center">{depo}</td>
                  <td className="text-center">{e?.jumlah_toko}</td>
                  <td className="text-center">{match.length}</td>
                  <td className="text-center">
                    {Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(omsetTotal)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }

    const button_count_reason = (
      <main className="mb-5">
        <section>
          <h1 className="text_bold">BY DEPO</h1>
          <Select
            options={depoDropDown}
            isMulti
            onChange={this.on_change_select.bind(this)}
            className="mb-3"
          />
          <div className="mb-5">
            <div style={{ width: "200px", margin: "auto" }}>
              <Chart type="pie" options={options} data={chart_data_reason} />
            </div>
          </div>
        </section>

        <section>
          <h1 className="text_bold">BY OUTLET CLOSED REASON</h1>
          {competitor_failed_list_origin.length === 0 && (
            <section className="container_light">Loading...</section>
          )}
          {resume_content}
          <table>
            <tbody>
              <tr>
                <td colSpan={2}>Sort by:</td>
                <td>
                  <select
                    name="sort_value_reason"
                    value={this.state.sort_value_reason}
                    onChange={this.on_change}
                  >
                    <option value="jumlah_toko">Total outlet amount</option>
                    <option value="text">Reason text (alphabet)</option>
                  </select>
                </td>
              </tr>
            </tbody>
            <tbody className="text_inferior">
              <tr>
                <td>View</td>
                <td>Reason</td>
              </tr>
            </tbody>
            <tbody>
              <tr className="text_inferior">
                <td>
                  <button
                    className="button_pill mb-2"
                    id={is_hide_all_reason ? "grey" : "green"}
                    onClick={this.on_click.bind(
                      this,
                      is_hide_all_reason ? "all" : "not_all",
                      "reason"
                    )}
                  >
                    ✓
                  </button>
                </td>
                <td style={{ paddingBottom: "7px" }}>All </td>
                <td>
                  <b>
                    :{" "}
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(competitor_failed_list.length)}
                  </b>
                </td>
              </tr>
              {template_list.map((t, idx) => {
                let id = "grey";
                if (reason_filter.includes(t.text)) {
                  id = "green";
                }
                return (
                  <tr key={idx} className="text_inferior">
                    <td>
                      <button
                        className="button_pill mb-2"
                        id={id}
                        onClick={this.on_click.bind(this, t.text, "reason")}
                      >
                        ✓
                      </button>
                    </td>
                    <td style={{ paddingBottom: "7px" }}>{t.text} </td>
                    <td>
                      {/* <b>: ({t?.jumlah_toko})</b> /{" "} */}
                      <b>: {t?.jumlah_toko_after}</b>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      </main>
    );

    let list_content = (
      <section>
        Click open to view outlet list, the list is closed by default to
        maintain good website performance.
      </section>
    );

    if (view_list_status) {
      list_content = (
        <main>
          <section className="text_bold">
            Total outlet after filter: {competitor_failed_list.length}
          </section>

          <table className="table">
            <thead>
              <tr>
                <th>No</th>
                <th>Outlet name</th>
                <th>ID Nabati</th>
                <th>Close reason</th>
                <th>Reported by salesman</th>
              </tr>
            </thead>
            <tbody>
              {competitor_failed_list.map((outlet, idx) => {
                let button_fly = (
                  <ON_FLY item={outlet} modal_item="competitor_failed" />
                );
                if (!outlet?.location?.coordinates?.[0]) {
                  button_fly = (
                    <button className="button" id="red">
                      No location
                    </button>
                  );
                }
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>
                      {outlet?.["Nama Toko"]} <br />
                      {button_fly}
                    </td>
                    <td>{outlet?.id_nabati || "-"}</td>
                    <td>{outlet?.failed_reason}</td>
                    <td>{outlet?.kunjungan_arr?.[0]?.NamaSales || "None"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      );
    }

    let final_content = (
      <main>
        <section className="main_container mb-5">
          <p className="text_inferior">Total all competitor outlets</p>
          <p className="text_bold">
            {competitor_count
              ? new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(competitor_count)
              : "Counting..."}
          </p>
          <br />
          <br />
          <p className="text_inferior">Total reported outlets</p>
          <p className="text_bold">
            {new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(competitor_failed_list_origin.length)}
          </p>

          <p className="text_inferior">Percent</p>
          <p className="text_bold">{percentage} %</p>
        </section>
        {button_count_reason}

        <section>
          <CompetitorFailedDownload />
        </section>

        <section>
          <button
            className="button mb-3"
            onClick={this.toggle_view_list_status}
            id="green"
          >
            {view_list_status ? "Close Outlet List" : "Open Outlet List"}
          </button>
        </section>
        {list_content}
      </main>
    );

    if (
      loading_status &&
      loading_item === "get_all_failed_competitor" &&
      competitor_failed_list_origin.length === 0
    ) {
      final_content = <main className="main_container mb-5">Loading...</main>;
    }

    return (
      <div style={{ marginBottom: "200px" }}>
        <h1 className="text_header">By Depo</h1>
        <p className="text_inferior">
          Only show {depoDropDown.length} Depo that has outlet acq.
        </p>
        {final_content}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, {
  get_all_failed_competitor,
  get_competitor_count,
  get_template_list,
  turn_on_menu_status,
  set_config_menu,
  set_list,
  set_active,
})(COMPETITOR_FAILED_MENU);
