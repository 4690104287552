/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import ON_FLY from "../main/ON_FLY";

/*REDUX FUNCTION*/
import {
  get_outlet_list_by_salesman_id,
  set_value_outlet,
} from "../../actions/outletGetActions";
import {
  set_config_mode_menu,
  popUpAction,
  turn_on_menu,
  turn_off_menu,
} from "../../actions/dataActions";
import {
  hide_filter_array,
  show_filter_array,
  hide_all_filter_array,
  show_all_filter_array,
} from "../../actions/filter_outlet_actions";

/*PICTURE ASSET*/

/*GENERAL*/

class LIST_OUTLET_BY_SALESMAN extends Component {
  state = {
    name_search_params: "",
  };

  componentDidMount() {
    this.props.turn_on_menu({
      key: "outlet_by_salesman",
      config: {
        mode: "day_filter_array",
      },
    });

    this.props.turn_off_menu("distribution");
    this.props.turn_off_menu("depo_loc_list");
    this.props.turn_off_menu("rayon");
    this.props.turn_off_menu("cycle");
    this.props.turn_off_menu("day");

    this.on_get_outlet_list();
  }

  on_get_outlet_list = () => {
    const { salesman_id_active } = this.props.properties;
    const params = {
      salesman_id: salesman_id_active,
    };
    this.props.get_outlet_list_by_salesman_id(params);
  };

  on_enter = (e) => {
    if (e.key === "Enter") {
      this.on_timpa_outlet_filter();
    }
  };

  on_timpa_outlet_filter = () => {
    const { name_search_params } = this.state;
    const {
      outlet_by_salesman_list_backup,
      outlet_by_salesman_list_filter_0_back_up,
    } = this.props.outlet;

    if (name_search_params) {
      const outlet_by_salesman_list = outlet_by_salesman_list_backup.filter(
        (outlet) =>
          outlet["Nama Toko"]
            .toLowerCase()
            .includes(name_search_params.toLowerCase())
      );
      const outlet_by_salesman_list_filter_0 =
        outlet_by_salesman_list_filter_0_back_up.filter((outlet) =>
          outlet["Nama Toko"]
            .toLowerCase()
            .includes(name_search_params.toLowerCase())
        );

      this.props.set_value_outlet({
        key: "outlet_by_salesman_list",
        value: outlet_by_salesman_list,
      });
      this.props.set_value_outlet({
        key: "outlet_by_salesman_list_filter_0",
        value: outlet_by_salesman_list_filter_0,
      });
    } else {
      this.props.set_value_outlet({
        key: "outlet_by_salesman_list",
        value: outlet_by_salesman_list_backup,
      });
      this.props.set_value_outlet({
        key: "outlet_by_salesman_list_filter_0",
        value: outlet_by_salesman_list_filter_0_back_up,
      });
    }
  };

  toggle_filter_array = (array_name, element_name, is_hide) => {
    const body = { array_name, element_name };
    if (is_hide) {
      this.props.show_filter_array(body);
    } else {
      this.props.hide_filter_array(body);
    }
  };

  on_hide_all_filter_array = () => {
    this.props.hide_all_filter_array();
  };

  on_show_all_filter_array = (array_name) => {
    this.props.show_all_filter_array({ array_name });
  };

  on_set_config_mode_menu = (mode) => {
    this.props.set_config_mode_menu("outlet_by_salesman", mode);
  };

  on_change = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value });
    if (name === "filter_mode") {
      localStorage.setItem("filter_mode", value);
    }
  };

  render() {
    //local storage

    //local state
    const { name_search_params } = this.state;

    //global props
    const { loading_item, loading_status } = this.props.properties;
    const {
      outlet_by_salesman_list,
      outlet_by_salesman_list_backup,
      outlet_by_salesman_list_filter_0_back_up,
    } = this.props.outlet;

    //content

    const outlet_list_content = (
      <main>
        <input
          className="full_width"
          type="text"
          name="name_search_params"
          value={name_search_params}
          onChange={this.on_change}
          onKeyDown={this.on_enter}
        />
        <br />
        <button
          className="button mb-5"
          id="green"
          onClick={this.on_timpa_outlet_filter}
        >
          Search by name
        </button>
        <section className="container_light mb-5">
          Search result : {outlet_by_salesman_list.length}
        </section>
        <table className="table">
          <thead>
            <tr>
              <th>NO</th>
              <th>NAME</th>
              <th>ADDRESS</th>
              <th>TYPE</th>
              <th>CYCLE</th>
              <th>DAY</th>
            </tr>
          </thead>
          <tbody>
            {outlet_by_salesman_list.map((outlet, idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>
                    {outlet?.["Nama Toko"]}
                    <br />
                    <ON_FLY item={outlet} modal_item="modal_distribution" />
                  </td>
                  <td>{outlet?.["ALAMAT"]}</td>
                  <td>{outlet?.["Type Outlet"]}</td>
                  <td>{outlet?.["FreqVisit"]}</td>
                  <td>{outlet?.["HARIKunjungan"]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </main>
    );

    return (
      <main style={{ marginBottom: "100px" }}>
        <button
          className="button mt-10 mb-5"
          id="green"
          onClick={this.on_get_outlet_list}
        >
          Get data outlet in this salesman
        </button>
        {loading_status &&
          loading_item === "get_outlet_list_by_salesman_id" && (
            <section className="container_light">
              Loading outlet list...
            </section>
          )}
        <h1 className="text_medium">Outlet List</h1>
        <table className="table_lite full_width mb-10">
          <tbody>
            <tr>
              <td>Total all outlet</td>
              <td>{outlet_by_salesman_list_backup.length}</td>
            </tr>
            <tr>
              <td>Total outlet with location</td>
              <td>{outlet_by_salesman_list_filter_0_back_up.length}</td>
            </tr>
            <tr>
              <td>Total outlet without location</td>
              <td>
                {outlet_by_salesman_list_backup.length -
                  outlet_by_salesman_list_filter_0_back_up.length}
              </td>
            </tr>
          </tbody>
        </table>
        {outlet_list_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  properties: state.properties,
  map: state.map,
  outlet: state.outlet,
});

export default connect(mapStateToProps, {
  get_outlet_list_by_salesman_id,
  set_config_mode_menu,
  hide_filter_array,
  show_filter_array,
  hide_all_filter_array,
  show_all_filter_array,
  popUpAction,
  turn_on_menu,
  turn_off_menu,
  set_value_outlet,
})(LIST_OUTLET_BY_SALESMAN);
