/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  set_list,
  turn_on_menu_status,
  turn_off_menu,
  set_config_mode_menu,
  searchSalesmanStep5,
} from "../../actions/dataActions";
import {
  push_kunjungan_arr_new,
  push_plan_list,
} from "../../actions/rayonActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import st_colors from "../../validation/st_colors";

/*NON IMPORT*/

class STEP_7_ROTATE_SALESMAN extends Component {
  state = {
    div: "",
    searchSalesmanQuery: "",
  };

  activate_day = () => {
    this.props.set_config_mode_menu("distribution", "day");
    this.props.turn_off_menu("cycle");
    this.props.turn_on_menu_status("day");
  };

  inactivate_day = () => {
    this.props.set_config_mode_menu("distribution", "cycle");
    this.props.turn_off_menu("day");
    this.props.turn_on_menu_status("cycle");
  };

  on_click_salesman = (salesman) => {
    this.props.toggle();

    let {
      rayon_list_database,
      rayon_select,
      // cycle_select,
      // salesman_select
      outlet_list_filter_0,
    } = this.props.data;

    const rayon_object = rayon_list_database.find(
      (r) => r._id === rayon_select
    );
    const plan_list = rayon_object?.plan_list || [];
    let outlet_list_process = [];

    const rotate = (cycle_select) => {
      //FIRST SALESMAN

      //ambil salesman pada plan pertama, asumsikan ini adalah salesman pertama yang diplanning di cycle yang sedang aktif
      const plan_list_c = plan_list.filter((p) => p.cycle === cycle_select);
      const plan_first = plan_list_c?.[0] ? plan_list_c?.[0] : {};
      const day_list_first = plan_first?.day_list ? plan_first?.day_list : [];
      const salesman_first = plan_first.salesman_id;

      //LAST SALESMAN
      //ambil salesman terakhir pada plan, akan menjadi acuan untuk rotate (pergeseran hari)
      const plan_last = plan_list_c?.[plan_list_c.length - 1]
        ? plan_list_c?.[plan_list_c.length - 1]
        : {};
      let day_list_new = plan_last?.day_list ? plan_last?.day_list : [];
      const length = day_list_new.length;

      //get 2 last day
      const last_2 = day_list_new.slice(length - 2, length); //angka 2 karena sekarang bisnis proses dari nabati bergeser 2, jika berubah maka angka 2 bisa dirubah

      //get 4 first day
      const first_4 = day_list_new.slice(0, length - 2); //angka 2 karena sekarang bisnis proses dari nabati bergeser 2, jika berubah maka angka 2 bisa dirubah

      //place the (2) last day on the first and (4) first day on the last
      day_list_new = last_2.concat(first_4);
      day_list_new = day_list_new.map((d, idx) => {
        let name_old = day_list_first.find(
          (item) => item.index === d.index
        )?.name;
        let index = idx;
        if (idx > 15) {
          index = idx % 16;
        }
        return {
          ...d,
          name: `day ${idx + 1}`,
          color: st_colors[index],
          name_old,
          date_created: Date.now(),
          date_updated: Date.now(),
        };
      });

      outlet_list_process = outlet_list_filter_0.filter(
        (t) => t.cycle === cycle_select
      );
      outlet_list_process = outlet_list_process.map((t) => {
        //mengeluarkan day sehingga bisa difilter dengan mudah di rayonActions.js
        const kunjungan_arr_new = t?.kunjungan_arr_new || [];
        const kunjungan = kunjungan_arr_new.find(
          (k) => k.cycle === cycle_select && k.salesman_id === salesman_first
        );
        const day = day_list_new.find(
          (d) => d?.name_old === kunjungan?.day
        )?.name;
        return { ...t, day };
      });

      //push kunjungan to outlet collection
      const body_outlet = {
        day_list: day_list_new,
        outlet_list_filter_0: outlet_list_process,
        cycle_select,
        salesman_select: salesman,
      };
      this.props.push_kunjungan_arr_new(body_outlet);

      //push plan list to rayon collection
      const body_plan = {
        rayon_id: rayon_select,
        plan: {
          cycle: cycle_select,
          salesman_id: salesman._id,
          day_list: day_list_new,
        },
      };
      this.props.push_plan_list(body_plan);

      this.props.set_list({
        text: "salesman_select",
        list: salesman,
      });
    };

    const array_cycle = ["cycle 1", "cycle 2"];

    const delay = () => new Promise((res) => setTimeout(res, 0));
    const parent_function = () => {
      return array_cycle.reduce(
        (last_promise, cycle_select) =>
          last_promise.then((result_sum) =>
            child_function(cycle_select).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (cycle_select) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            rotate(cycle_select);
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(() => {});

    this.setState({ searchSalesmanQuery: "" });
    this.props.searchSalesmanStep5("");
  };

  on_change = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value });
  };

  handleSearch(e) {
    this.setState({ searchSalesmanQuery: e.target.value });
    this.props.searchSalesmanStep5(e.target.value);
  }

  render() {
    //local storage

    //local state
    const { div, searchSalesmanQuery } = this.state;

    //global props
    const {
      salesman_list_step_5,
      rayon_list_database,
      rayon_select,
      loading_status,
    } = this.props.data;
    const rayon_object = rayon_list_database.find(
      (r) => r._id === rayon_select
    );
    const plan_list = rayon_object?.plan_list ? rayon_object?.plan_list : [];

    //content
    let salesman_list_id = plan_list.map((p) => p.salesman_id);
    salesman_list_id = salesman_list_id ? salesman_list_id : [];

    let salesman_list_filtered = []; // salesman_list_step_5 digunakan supaya regex tidak bertubrukan dengan salesman_list
    if (div === "") {
      salesman_list_filtered = salesman_list_step_5;
    } else {
      salesman_list_filtered = salesman_list_step_5.filter((s) => {
        let bool = s?.divisi_arr ? s?.divisi_arr : [];
        bool = bool.map((d) => d.includes(div));
        return bool.includes(true);
      });
    }
    return (
      <main>
        <h1 className="text_medium">Choose a salesman</h1>
        <div className="w-full mt-2">
          <input
            type="text"
            className="w-full"
            placeholder={`Search Salesman Name`}
            onChange={this.handleSearch.bind(this)}
            value={searchSalesmanQuery}
          />
        </div>
        <table style={{ width: "100%" }} className="table_lite text_inferior">
          <thead>
            <tr>
              <th>Salesman</th>
              <th style={{ width: "120px" }}>
                <p>DIV:</p>
                <select
                  name="div"
                  id="div"
                  value={div}
                  onChange={this.on_change}
                  className="text_inferior"
                  style={{ width: "80px" }}
                >
                  <option value="">Show all</option>
                  <option value="D1">D1</option>
                  <option value="D2">D2</option>
                  <option value="D3">D3</option>
                  <option value="D4">D4</option>
                  <option value="D5">D5</option>
                  <option value="DA">DA</option>
                </select>
              </th>
            </tr>
          </thead>
          <tbody>
            {salesman_list_filtered.map((s, idx) => {
              const divisi_arr = s?.divisi_arr ? s?.divisi_arr : [];
              return (
                <tr key={idx}>
                  <td>
                    <button className="badge" id="green">
                      {idx + 1}
                    </button>
                    <p>{s?.["Nama Only"]}</p>
                    <div>
                      {divisi_arr.map((d, idx) => (
                        <p key={idx} className="block">
                          {d}
                        </p>
                      ))}
                    </div>
                  </td>
                  <td>
                    {loading_status ? (
                      <button className="button" id="grey">
                        Wait
                      </button>
                    ) : salesman_list_id.includes(s._id) ? (
                      <button className="button" id="grey">
                        Choose
                      </button>
                    ) : (
                      <button
                        className="button"
                        id="green"
                        onClick={this.on_click_salesman.bind(this, s)}
                      >
                        Choose
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, {
  set_list,
  turn_on_menu_status,
  turn_off_menu,
  set_config_mode_menu,
  push_kunjungan_arr_new,
  push_plan_list,
  searchSalesmanStep5,
})(STEP_7_ROTATE_SALESMAN);
