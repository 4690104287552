/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  clear_current_salesman,
  clear_loading_top,
  edit_salesman,
  edit_status,
  edit_password,
  get_check_username,
  get_salesman_by_nik,
  set_current_salesman,
  set_loading_top,
} from "../../actions/dataActions";

/*PICTURE ASSET*/
import IconComponents from "../../img/icon-components/IconComponents";

/*GENERAL*/
import isEqual from "../../validation/is-equal";

/*NON IMPORT*/

class EditSalesman extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nik: "12345679",
      listSalesman: [],
      current_salesman: {},
      detail_salesamn_nav: false,
      password1: {
        value: "",
        view: false,
      },
      password2: {
        value: "",
        view: false,
      },
      validation: {
        nik: {
          status: false,
          message: "",
        },
        kode_sales: {
          status: false,
          message: "",
        },
        username: {
          status: false,
          color: "",
          message: "",
          btn_show: false,
        },
      },
      without_search: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.current_salesman, this.props.current_salesman)) {
      this.setState({
        current_salesman: this.props.current_salesman,
        detail_salesamn_nav: true,
        without_search: true,
      });
    }
  }

  componentWillUnmount() {
    this.props.clear_current_salesman();
  }

  findSalesman = async () => {
    if (this.state.nik !== "") {
      try {
        this.props.set_loading_top();
        const listSalesman = await get_salesman_by_nik(this.state.nik);
        this.setState({
          listSalesman: listSalesman,
          detail_salesamn_nav: false,
        });
        if (listSalesman.length === 0) {
          alert("Salasman cannot be found! Please check the employee ID");
        }
        this.props.clear_loading_top();
      } catch (error) {
        console.log(error);
        this.props.clear_loading_top();
        alert("Salasman cannot be found! Please check the employee ID");
      }
    } else {
      alert("employee ID cannot be empty!");
    }
  };

  on_change = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    let current_salesman = this.state.current_salesman;
    current_salesman[name] = value;
    this.setState({ current_salesman });
  };

  chosse_salesman = (e) => {
    this.props.set_current_salesman(e);
    this.setState({ without_search: false });
  };

  handle_password = (e) => {
    this.setState({
      [e.target.name]: { ...this.state[e.target.name], value: e.target.value },
    });
  };

  submit_forgot_password = async () => {
    try {
      const res = await edit_password({
        password1: this.state.password1.value,
        password2: this.state.password2.value,
        user_id: this.state.current_salesman.user_id._id,
      });
      if (res?.msg === "edit password sukses") {
        alert("Completed to change password");
      }
    } catch (error) {
      console.log(error);
      alert("Failed to change password!");
    }
  };

  eyeClick = (e) => {
    this.setState({
      [e]: { ...this.state[e], view: !this.state[e].view },
    });
  };

  add_kode_sales = () => {
    const current_salesman = this.state.current_salesman;
    current_salesman.kd_sales_arr.push("");
    this.setState({
      current_salesman: current_salesman,
    });
  };

  minus_kode_sales = (e) => {
    const current_salesman = this.state.current_salesman;
    current_salesman.kd_sales_arr.splice(e, 1);
    this.setState({
      current_salesman: current_salesman,
    });
  };

  on_change_kode_sales = (e) => {
    const index = parseInt(e.target.name);
    const current_salesman = this.state.current_salesman;
    current_salesman.kd_sales_arr[index] = e.target.value;
    this.setState({
      current_salesman: current_salesman,
      validation: {
        ...this.state.validation,
        kode_sales: { status: false, message: "" },
      },
    });
    if (e.target.value.length !== 6) {
      this.setState({
        validation: {
          ...this.state.validation,
          kode_sales: { status: true, message: "Insert 6 numbers!" },
        },
      });
    }
    const numberValue = e.target.value.split("");
    numberValue.forEach((element) => {
      if (!Number.isInteger(parseInt(element))) {
        this.setState({
          validation: {
            ...this.state.validation,
            kode_sales: {
              status: true,
              message: "Please remove non numeric characters!",
            },
          },
        });
      }
    });
  };

  add_divisi = () => {
    const current_salesman = this.state.current_salesman;
    current_salesman.divisi_arr.push("");
    this.setState({
      current_salesman: current_salesman,
    });
  };

  minus_divisi = (e) => {
    const current_salesman = this.state.current_salesman;
    current_salesman.divisi_arr.splice(e, 1);
    this.setState({
      current_salesman: current_salesman,
    });
  };

  on_change_divisi = (e) => {
    const index = parseInt(e.target.name);
    const current_salesman = this.state.current_salesman;
    current_salesman.divisi_arr[index] = e.target.value;
    this.setState({
      current_salesman: current_salesman,
    });
  };

  on_change_depo = (e) => {
    if (e.target.value === "") {
      alert("Please Choose Depo");
    } else {
      const selectedIndex = e.target.options.selectedIndex;
      const id = e.target.options[selectedIndex].getAttribute("data-id");
      const value = e.target.options[selectedIndex].getAttribute("data-value");
      this.setState({
        current_salesman: {
          ...this.state.current_salesman,
          Depo: value,
          Depo_id: id,
        },
      });
    }
  };

  on_change_cabang = (e) => {
    if (e.target.value === "") {
      alert("Please Choose Branch");
    } else {
      const selectedIndex = e.target.options.selectedIndex;
      const id = e.target.options[selectedIndex].getAttribute("data-id");
      const value = e.target.options[selectedIndex].getAttribute("data-value");
      this.setState({
        current_salesman: {
          ...this.state.current_salesman,
          CABANG: value,
          CABANG_Id: id,
          Branch: value,
        },
      });
    }
  };

  on_change_region = (e) => {
    if (e.target.value === "") {
      alert("Please Choose Region");
    } else {
      const selectedIndex = e.target.options.selectedIndex;
      const id = e.target.options[selectedIndex].getAttribute("data-id");
      const value = e.target.options[selectedIndex].getAttribute("data-value");
      this.setState({
        current_salesman: {
          ...this.state.current_salesman,
          REGIONAL: value,
          REGIONAL_Id: id,
          Region: value,
        },
      });
    }
  };

  on_change_text_field = (e) => {
    if (e.target.value === "") {
      alert(`${e.target.name} cannot be empty`);
    } else {
      let key = e.target.name;
      if (e.target.name === "NIK") {
        this.setState({
          validation: {
            ...this.state.validation,
            nik: { status: false, message: "" },
          },
        });
        if (e.target.value.length !== 8) {
          this.setState({
            validation: {
              ...this.state.validation,
              nik: { status: true, message: "Insert 8 numbers!" },
            },
          });
        }
        const numberValue = e.target.value.split("");
        numberValue.forEach((element) => {
          if (!Number.isInteger(parseInt(element))) {
            this.setState({
              validation: {
                ...this.state.validation,
                nik: {
                  status: true,
                  message: "Please remove non numeric characters!",
                },
              },
            });
          }
        });
      }
      if (e.target.name === "username") {
        this.setState({
          current_salesman: {
            ...this.state.current_salesman,
            user_id: {
              ...this.state.current_salesman.user_id,
              name: e.target.value,
            },
          },
          validation: {
            ...this.state.validation,
            username: {
              ...this.state.validation.username,
              btn_show: true,
            },
          },
        });
      } else {
        this.setState({
          current_salesman: {
            ...this.state.current_salesman,
            [key]: e.target.value,
          },
        });
      }
    }
  };

  submit_edit_salesman = () => {
    this.props.edit_salesman(this.state.current_salesman);
  };

  on_checked = (p) => {
    const checked = p.target.checked;
    if (checked) {
      this.setState({
        current_salesman: { ...this.state.current_salesman, status: "off" },
      });
      this.props.edit_status({
        salesman_id: this.state.current_salesman._id,
        status: "off",
      });
    } else {
      this.setState({
        current_salesman: {
          ...this.state.current_salesman,
          status: "nonactive",
        },
      });
      this.props.edit_status({
        salesman_id: this.state.current_salesman._id,
        status: "nonactive",
      });
    }
  };

  check_username = async (username) => {
    try {
      const res = await get_check_username(username);
      if (res.status) {
        this.setState({
          validation: {
            ...this.state.validation,
            username: {
              status: true,
              message: res.msg,
              color: "text-green-700",
              btn_show: true,
            },
          },
        });
      } else {
        this.setState({
          validation: {
            ...this.state.validation,
            username: {
              status: true,
              message: res.msg,
              color: "text-red-600",
              btn_show: true,
            },
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      listSalesman,
      password1,
      password2,
      validation,
      detail_salesamn_nav,
      current_salesman,
      without_search,
    } = this.state;
    const { depo_cabang_regional, selection_divisi } = this.props;
    return (
      <React.Fragment>
        <div className="pl-1 mt-4">
          {!without_search && (
            <React.Fragment>
              <p>Please insert salesman employee ID</p>
              <input
                type="text"
                name="nik"
                id="nik"
                className="w-full"
                onChange={(e) => this.setState({ nik: e.target.value })}
              />
              <button
                className="button_main use_margin"
                onClick={this.findSalesman}
              >
                Find Salesman
              </button>
            </React.Fragment>
          )}
          {listSalesman.length > 0 && !detail_salesamn_nav && (
            <div className="border p-4 my-4 bg-yellow-100 rounded">
              Check the salesman <b> valid username </b>
            </div>
          )}
          {!detail_salesamn_nav && (
            <div className="">
              {listSalesman.map((e, i) => (
                <div
                  key={i}
                  className="hover:bg-gray-100 py-4 px-2 cursor-pointer"
                  onClick={this.chosse_salesman.bind(this, e)}
                >
                  <div className="grid grid-cols-2">
                    <p className="font-semibold mb-1">Employee ID: {e.NIK}</p>
                    <p className="font-semibold mb-1">
                      Username: {e.user_id.name}
                    </p>
                    <small>Salesman name: {e["Nama Salesman"]}</small>
                    <small>Depo: {e.Depo}</small>
                    <small>Branch: {e.CABANG}</small>
                    <small>Region: {e.REGIONAL}</small>
                  </div>
                  {e.status === "nonactive" && (
                    <div className="text-right">
                      <small className="rounded-full border bg-red-200 text-red-700 border-red-500 font-bold py-1 px-2">
                        Salesman Inactive
                      </small>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {detail_salesamn_nav && (
            <React.Fragment>
              <div className="mt-4 border border-gray-300 rounded p-4">
                <p className="font-semibold">
                  Username: {current_salesman?.user_id?.name}
                </p>
                <small>Name: {current_salesman["Nama Only"]}</small>
                <br />
                <small>
                  Salesman Name: {current_salesman["Nama Salesman"]}
                </small>
                <br />
                <small>
                  Merchandising status:{" "}
                  <select
                    value={current_salesman?.merchandising_status}
                    name="merchandising_status"
                    onChange={this.on_change}
                  >
                    <option value={false}>not active</option>
                    <option value={true}>active</option>
                  </select>
                </small>

                <hr className="my-2" />
                <div>
                  <p className="mb-1">Change Salesman Data</p>
                  {/* username field */}
                  <div className="flex items-center">
                    <p className="w-1/3 font-bold">Username</p>
                    <div className="flex-1">
                      <input
                        type="text"
                        defaultValue={current_salesman?.user_id?.name}
                        className="w-full"
                        name="username"
                        onChange={this.on_change_text_field.bind(this)}
                      />
                    </div>
                  </div>
                  <div
                    className={`text-right mb-1 ${
                      validation.username.btn_show ? "" : "hidden"
                    }`}
                  >
                    {validation.username.status && (
                      <div>
                        <small className={`${validation.username.color}`}>
                          *{validation.username.message}
                        </small>
                      </div>
                    )}
                    <button
                      className={`button_main use_margin`}
                      onClick={this.check_username.bind(
                        this,
                        current_salesman?.user_id?.name
                      )}
                    >
                      Check Username
                    </button>
                  </div>
                  {/* nama field */}
                  <div className="flex items-center">
                    <p className="w-1/3">Name</p>
                    <div className="flex-1">
                      <input
                        type="text"
                        defaultValue={current_salesman["Nama Only"]}
                        className="w-full"
                        name="Nama Only"
                        onChange={this.on_change_text_field.bind(this)}
                      />
                    </div>
                  </div>
                  {/* nama salesman field */}
                  <div className="flex items-center">
                    <p className="w-1/3">Salesman Name</p>
                    <div className="flex-1">
                      <input
                        type="text"
                        defaultValue={current_salesman["Nama Salesman"]}
                        className="w-full"
                        name="Nama Salesman"
                        onChange={this.on_change_text_field.bind(this)}
                      />
                    </div>
                  </div>
                  {/* nik field */}
                  <div className="flex items-center">
                    <p className="w-1/3 font-bold">Employee ID</p>
                    <div className="flex-1">
                      <input
                        type="text"
                        defaultValue={current_salesman.NIK}
                        className="w-full"
                        name="NIK"
                        onChange={this.on_change_text_field.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="text-right mb-4">
                    {validation.nik.status && (
                      <div>
                        <small className="text-red-700">
                          *{validation.nik.message}
                        </small>
                      </div>
                    )}
                    <small>
                      *Insert Nabati's Employee ID,{" "}
                      <b>Not identity card number</b>
                    </small>
                  </div>
                  {/* Depo field */}
                  <div className="flex items-center">
                    <p className="w-1/3">Depo</p>
                    <select
                      defaultValue={current_salesman.Depo}
                      name="depo"
                      id="depo"
                      className="w-2/3"
                      onChange={this.on_change_depo}
                    >
                      <option defaultValue={""} disabled>
                        --Choose Depo--
                      </option>
                      {depo_cabang_regional.depo.map((e, i) => {
                        return (
                          <option key={i} data-id={e._id} data-value={e.Depo}>
                            {e.Depo}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* Cabang field */}
                  <div className="flex items-center py-2">
                    <p className="w-1/3">Branch</p>
                    {(() => {
                      const result = depo_cabang_regional.depo.find(
                        (obj) => obj.Depo === current_salesman["Depo"]
                      );
                      if (result) {
                        return result.CABANG;
                      } else {
                        return "-";
                      }
                    })()}
                  </div>
                  {/* Regional field */}
                  <div className="flex items-center py-2">
                    <p className="w-1/3">Region</p>
                    {(() => {
                      const result = depo_cabang_regional.depo.find(
                        (obj) => obj.Depo === current_salesman["Depo"]
                      );
                      if (result) {
                        return result.REGION;
                      } else {
                        return "-";
                      }
                    })()}
                  </div>
                  {/* Email field */}
                  <div className="flex items-center">
                    <p className="w-1/3">Email</p>
                    <input
                      type="email"
                      defaultValue={current_salesman.email}
                      className="w-full flex-1"
                      name="email"
                      onChange={this.on_change_text_field.bind(this)}
                    />
                  </div>
                  {/* No. Handphone field */}
                  <div className="flex items-center">
                    <p className="w-1/3">Telephone Number</p>
                    <input
                      type="text"
                      defaultValue={current_salesman.phone_number}
                      className="w-full flex-1"
                      name="phone_number"
                      onChange={this.on_change_text_field.bind(this)}
                    />
                  </div>
                </div>
                <div>
                  <p className="mb-1 mt-2">Change salesman code & Division</p>
                  {current_salesman?.kd_sales_arr?.map((e, i) => (
                    <div className="flex items-center" key={i}>
                      <p className="w-1/3">{i === 0 ? "Salesman Code" : ""}</p>
                      <div className="flex-1 flex items-center">
                        <input
                          type="text"
                          value={e}
                          className="w-full flex-1"
                          name={i}
                          onChange={this.on_change_kode_sales.bind(this)}
                          autoComplete="off"
                        />
                        <IconComponents
                          icon="minus_circle"
                          className="ml-1 cursor-pointer"
                          onClick={this.minus_kode_sales.bind(this, i)}
                        />
                      </div>
                    </div>
                  ))}
                  {current_salesman?.kd_sales_arr?.length === 0 && (
                    <div className="flex items-center">
                      <p className="w-1/3">Salesman Code</p>
                      <input
                        type="text"
                        className="flex-1 w-full "
                        name={"0"}
                        onChange={this.on_change_kode_sales.bind(this)}
                      />
                    </div>
                  )}
                  <div className="flex items-center">
                    <p className="w-1/3"></p>
                    <div className="text-left flex-1">
                      <IconComponents
                        icon="plus_circle"
                        className="mx-auto cursor-pointer mb-2"
                        onClick={this.add_kode_sales}
                      />
                    </div>
                  </div>
                  <div className="text-right mb-4">
                    {validation.kode_sales.status && (
                      <div>
                        <small className="text-red-700">
                          *{validation.kode_sales.message}
                        </small>
                      </div>
                    )}
                    <small>
                      *Salesman code consist of <b>6 numbers</b>
                    </small>
                  </div>
                  {current_salesman.divisi_arr.map((e, i) => (
                    <div className="flex items-center" key={i}>
                      <p className="w-1/3">{i === 0 ? "Divisi" : ""}</p>
                      <div className="flex-1 flex items-center">
                        {/* <input
                        type="text"
                        value={e}
                        className="w-full flex-1"
                        name={i}
                        onChange={this.on_change_divisi.bind(this)}
                      /> */}
                        <select
                          className="w-full"
                          onChange={this.on_change_divisi.bind(this)}
                          name={i}
                          defaultValue={e}
                        >
                          <option defaultValue={""}>--Choose Division--</option>
                          {selection_divisi.map((e, i) => {
                            return <option key={i}>{e}</option>;
                          })}
                        </select>
                        <IconComponents
                          icon="minus_circle"
                          className="ml-1 cursor-pointer"
                          onClick={this.minus_divisi.bind(this, i)}
                        />
                      </div>
                    </div>
                  ))}
                  {current_salesman.divisi_arr.length === 0 && (
                    <div className="flex items-center">
                      <p className="w-1/3">Divisi</p>
                      <select
                        className="w-2/3"
                        defaultValue={""}
                        onChange={this.on_change_divisi.bind(this)}
                        name="0"
                      >
                        <option defaultValue={""}>--Choose Division--</option>
                        {selection_divisi.map((e, i) => {
                          return (
                            <option key={i} defaultValue={e}>
                              {e}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  <div className="flex items-center">
                    <p className="w-1/3"></p>
                    <div className="text-left flex-1">
                      <IconComponents
                        icon="plus_circle"
                        className="mx-auto cursor-pointer mb-2"
                        onClick={this.add_divisi}
                      />
                    </div>
                  </div>
                  <div className="text-right">
                    <button
                      className="button_main use_margin"
                      onClick={this.submit_edit_salesman}
                    >
                      Change Salesman Data
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-4 border border-gray-300 rounded p-4">
                <p className="mb-1">Change Password</p>
                <div className="flex items-center">
                  <input
                    id="password1"
                    name="password1"
                    onChange={this.handle_password.bind(this)}
                    value={this.state.password1.value}
                    type={password1.view ? "text" : "password"}
                    className="w-full"
                    placeholder="Insert Password for at least 5 characters"
                  />
                  <IconComponents
                    icon={`${password1.view ? "eye_off" : "eye"}`}
                    className={"ml-2 cursor-pointer"}
                    onClick={this.eyeClick.bind(this, "password1")}
                  />
                </div>
                <div className="flex items-center">
                  <input
                    id="password2"
                    name="password2"
                    onChange={this.handle_password.bind(this)}
                    value={this.state.password2.value}
                    type={password2.view ? "text" : "password"}
                    className="w-full"
                    placeholder="Retype Password for at least 5 characters"
                  />
                  <IconComponents
                    icon={`${password2.view ? "eye_off" : "eye"}`}
                    className={"ml-2 cursor-pointer"}
                    onClick={this.eyeClick.bind(this, "password2")}
                  />
                </div>
                <div className="text-right">
                  <button
                    className="ml-auto button_main use_margin"
                    onClick={this.submit_forgot_password}
                  >
                    Change Password
                  </button>
                </div>
              </div>
              <div className="mt-4 border border-gray-300 rounded p-4 pb-0">
                <div className="flex">
                  <p className="flex-1">Inactivate Salesman</p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={this.on_checked}
                      checked={
                        current_salesman.status === "nonactive" ? false : true
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                {current_salesman.status === "nonactive" ? (
                  <div className="border p-4 my-4 bg-red-100 rounded">
                    Salesman is <b>inactive</b>!
                  </div>
                ) : (
                  <div className="border p-4 my-4 bg-green-100 rounded">
                    Salesman is <b>Active </b> and can be used
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  depo_cabang_regional: state.data.salesman_filter,
  selection_divisi: state.data.selection_divisi,
  current_salesman: state.data.current_salesman,
});

export default connect(mapStateToProps, {
  edit_salesman,
  set_loading_top,
  clear_loading_top,
  edit_status,
  set_current_salesman,
  clear_current_salesman,
})(EditSalesman);
