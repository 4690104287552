/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { activate_edit_point_depo } from "../../actions/mapActions";

/*PICTURE ASSET*/

/*GENERAL*/

/*NON IMPORT*/

class DEPO_POPUP extends Component {
  componentDidMount() {
    const pop_up_properties = this?.props?.properties?.pop_up_properties || {};

    if (pop_up_properties?.["is_local"]) {
      let generateClick = pop_up_properties;
      generateClick.location = JSON.parse(generateClick.location);
      this.props.activate_edit_point_depo(generateClick);
    }
  }

  render() {
    //localstorage

    //local state

    //global props
    const pop_up_properties = this?.props?.properties?.pop_up_properties || {};

    //content

    return (
      <main style={{ fontFamily: `"Inter", sans-serif` }}>
        {(() => {
          let generate;
          if (pop_up_properties?.["is_local"]) {
            generate = (
              <section>
                <div
                  className="text_bold mb-3"
                  style={{
                    fontSize: "15px",
                    wordBreak: "break-word",
                    marginTop: 2.5,
                  }}
                >
                  Local Depo Details
                </div>
                <div
                  className="text_inferior"
                  style={{ fontSize: "10px", lineHeight: "125%" }}
                >
                  Depo
                </div>
                <div
                  className="text_bold mb-2.5"
                  style={{
                    fontSize: "15px",
                    wordBreak: "break-word",
                    marginTop: 2.5,
                  }}
                >
                  {pop_up_properties?.["Depo"] || "Unfilled"}
                </div>
              </section>
            );
          } else {
            generate = (
              <section>
                <div
                  className="text_bold mb-3"
                  style={{
                    fontSize: "15px",
                    wordBreak: "break-word",
                    marginTop: 2.5,
                  }}
                >
                  Depo Details
                </div>
                <div
                  className="text_inferior"
                  style={{ fontSize: "10px", lineHeight: "125%" }}
                >
                  Depo
                </div>
                <div
                  className="text_bold mb-2.5"
                  style={{
                    fontSize: "15px",
                    wordBreak: "break-word",
                    marginTop: 2.5,
                  }}
                >
                  {pop_up_properties?.["Depo"] || "Unfilled"}
                </div>
                <div
                  className="text_inferior"
                  style={{ fontSize: "10px", lineHeight: "125%" }}
                >
                  Address
                </div>
                <div
                  className="text_bold mb-2.5"
                  style={{
                    fontSize: "15px",
                    wordBreak: "break-word",
                    marginTop: 2.5,
                  }}
                >
                  {pop_up_properties?.["DEPO_ALAMAT"] || "Unfilled"}
                </div>
                <div
                  className="text_inferior"
                  style={{ fontSize: "10px", lineHeight: "125%" }}
                >
                  City/Regency
                </div>
                <div
                  className="text_bold mb-2.5"
                  style={{
                    fontSize: "15px",
                    wordBreak: "break-word",
                    marginTop: 2.5,
                  }}
                >
                  {pop_up_properties?.["DEPO_KABUPATEN"] || "Unfilled"}
                </div>
                <div
                  className="text_inferior"
                  style={{ fontSize: "10px", lineHeight: "125%" }}
                >
                  District
                </div>
                <div
                  className="text_bold mb-2.5"
                  style={{
                    fontSize: "15px",
                    wordBreak: "break-word",
                    marginTop: 2.5,
                  }}
                >
                  {pop_up_properties?.["DEPO_KECAMATAN"] || "Unfilled"}
                </div>
                <div
                  className="text_inferior"
                  style={{ fontSize: "10px", lineHeight: "125%" }}
                >
                  Subdistrict
                </div>
                <div
                  className="text_bold mb-2.5"
                  style={{
                    fontSize: "15px",
                    wordBreak: "break-word",
                    marginTop: 2.5,
                  }}
                >
                  {pop_up_properties?.["DEPO_KELURAHAN"] || "Unfilled"}
                </div>
                <div
                  className="text_inferior"
                  style={{ fontSize: "10px", lineHeight: "125%" }}
                >
                  Province
                </div>
                <div
                  className="text_bold mb-2.5"
                  style={{
                    fontSize: "15px",
                    wordBreak: "break-word",
                    marginTop: 2.5,
                  }}
                >
                  {pop_up_properties?.["DEPO_PROPINSI"] || "Unfilled"}
                </div>
                <div
                  className="text_inferior"
                  style={{ fontSize: "10px", lineHeight: "125%" }}
                >
                  Branch
                </div>
                <div
                  className="text_bold mb-2.5"
                  style={{
                    fontSize: "15px",
                    wordBreak: "break-word",
                    marginTop: 2.5,
                  }}
                >
                  {pop_up_properties?.["CABANG"] || "Unfilled"}
                </div>
                <div
                  className="text_inferior"
                  style={{ fontSize: "10px", lineHeight: "125%" }}
                >
                  Region
                </div>
                <div
                  className="text_bold mb-2.5"
                  style={{
                    fontSize: "15px",
                    wordBreak: "break-word",
                    marginTop: 2.5,
                  }}
                >
                  {pop_up_properties?.["REGION"] || "Unfilled"}
                </div>

                <div
                  className="text_inferior"
                  style={{ fontSize: "10px", lineHeight: "125%" }}
                >
                  System
                </div>
                <div
                  className="text_bold mb-2.5"
                  style={{
                    fontSize: "15px",
                    wordBreak: "break-word",
                    marginTop: 2.5,
                  }}
                >
                  {pop_up_properties?.["system"] || "Unfilled"}
                </div>
              </section>
            );
          }
          return generate;
        })()}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties,
});

export default connect(mapStateToProps, { activate_edit_point_depo })(
  DEPO_POPUP
);
