/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import STEP_2_PREVIEW from "./STEP_2_PREVIEW";
import STEP_2_RUN_MODE_GROUPING from "./STEP_2_RUN_MODE_GROUPING";

/*REDUX FUNCTION*/
import { set_config_menu, set_list } from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class STEP_2_PARENT extends Component {
  state = {};

  on_change_props = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.props.set_list({ text: name, list: value });
    if (name === "jumlah_toko") {
      this.props.set_list({ text: "preview_rayon_list", list: [] });
    }
  };

  on_change_inside = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const id = e.target.id;

    const { list_menu_active } = this.props.data;
    let config = list_menu_active.find((e) => e.key === "salesman")?.config
      ? list_menu_active.find((e) => e.key === "salesman")?.config
      : {};

    if (config?.value?.length > 0) {
      const index = config.value.findIndex((t) => t.value === id);
      config.value[index][name] = value;

      if (name === "jumlah_rayon_by_omset") {
        //avg_toko_rayon_omset
        config.value[index].avg_toko_rayon_omset = Math.round(
          config.value[index].total_outlet / value
        );
        //avg_value_rayon
        config.value[index].avg_value_rayon = Math.round(
          config.value[index].total_value / value
        );
      } else if (name === "avg_value_rayon") {
        //avg_toko_rayon_omset
        config.value[index].avg_toko_rayon_omset = Math.round(
          value / config.value[index].avg_value
        );
        //jumlah_rayon_by_omset
        config.value[index].jumlah_rayon_by_omset = Math.round(
          config.value[index].total_value / value
        );
      } else if (name === "avg_toko_rayon_omset") {
        //jumlah_rayon_by_omset
        config.value[index].jumlah_rayon_by_omset = Math.round(
          config.value[index].total_value /
            (value * config.value[index].avg_value)
        );
        //avg_value_rayon
        config.value[index].avg_value_rayon = Math.round(
          value * config.value[index].avg_value
        );
      }

      if (name === "jumlah_rayon_by_qty") {
        //avg_toko_rayon_qty
        config.value[index].avg_toko_rayon_qty = Math.round(
          config.value[index].total_outlet / value
        );
        //avg_qty_rayon
        config.value[index].avg_qty_rayon = Math.round(
          config.value[index].total_qty / value
        );
      } else if (name === "avg_qty_rayon") {
        //avg_toko_rayon_qty
        config.value[index].avg_toko_rayon_qty = Math.round(
          value / config.value[index].avg_qty
        );
        //jumlah_rayon_by_qty
        config.value[index].jumlah_rayon_by_qty = Math.round(
          config.value[index].total_qty / value
        );
      } else if (name === "avg_toko_rayon_qty") {
        //jumlah_rayon_by_qty
        config.value[index].jumlah_rayon_by_qty = Math.round(
          config.value[index].total_qty / (value * config.value[index].avg_qty)
        );
        //avg_qty_rayon
        config.value[index].avg_qty_rayon = Math.round(
          value * config.value[index].avg_qty
        );
      }
      this.props.set_config_menu("salesman", config);
    }
  };

  render() {
    //local state

    //global props
    const {
      mode_pembagian_rayon, // jumlah_toko | jumlah_omset | jumlah_qty
      mode_alg, //box | group
      jumlah_toko,
      list_menu_active,
      outlet_list_filter_0,
      loading_status,
      loading_item,
    } = this.props.data;

    //content
    const config_plan = list_menu_active.find((e) => e.key === "salesman")
      ?.config?.value
      ? list_menu_active.find((e) => e.key === "salesman")?.config?.value
      : [];

    return (
      <section className="main_container mb-3">
        <p className="badge mb-1" id="blue">
          Step 2
        </p>
        <p className="text_medium">Run depo rayonization</p>
        <hr />

        <section className="main_container mt-5 mb-5" id="white">
          <p className="text_medium">Select algorithm</p>
          <select
            name="mode_alg"
            value={mode_alg}
            onChange={this.on_change_props}
          >
            <option value="box">Squared Rayon</option>
            <option value="group">Classify Subdistrict/District</option>
          </select>
        </section>

        {mode_alg === "box" ? (
          <section>
            <section className="main_container mt-5 mb-5" id="white">
              <p className="text_medium">Select rayon division mode</p>
              <select
                name="mode_pembagian_rayon"
                value={mode_pembagian_rayon}
                onChange={this.on_change_props}
              >
                <option value="jumlah_toko">Same number of stores</option>
                <option value="jumlah_omset">Same amount of revenue</option>
                <option value="jumlah_qty">Same amount of carton</option>
              </select>
              {mode_pembagian_rayon === "jumlah_toko" ? (
                <table className="table text_medium">
                  <tbody>
                    <tr>
                      <td>Set the outlets number per rayon</td>
                      <td>
                        <input
                          className="text_right"
                          name="jumlah_toko"
                          id="jumlah_toko"
                          value={jumlah_toko}
                          onChange={this.on_change_props}
                          type="number"
                          min="1"
                          style={{ width: "100px" }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Rayon number estimation:</td>
                      <td className="text_right text_medium">
                        {Math.ceil(outlet_list_filter_0?.length / jumlah_toko)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : mode_pembagian_rayon === "jumlah_omset" ? (
                <section>
                  {config_plan.map((d, idx) => {
                    return (
                      <section key={idx}>
                        <p className="text_bold">{d.value}</p>
                        <table className="table text_medium">
                          <tbody>
                            <tr>
                              <td>Total revenue in Depo</td>
                              <td className="text_right text_medium">
                                Rp{" "}
                                {new Intl.NumberFormat("id-ID", {
                                  style: "decimal",
                                }).format(d.total_value)}
                              </td>
                            </tr>
                            <tr>
                              <td>Total stores in Depo</td>
                              <td className="text_right text_medium">
                                {new Intl.NumberFormat("id-ID", {
                                  style: "decimal",
                                }).format(d.total_outlet)}
                              </td>
                            </tr>
                            <tr>
                              <td>Average revenue for a store</td>
                              <td className="text_right text_medium">
                                Rp{" "}
                                {new Intl.NumberFormat("id-ID", {
                                  style: "decimal",
                                }).format(d.avg_value)}
                              </td>
                            </tr>
                            <tr className="text_center">
                              <td colSpan={2}>
                                Select one of the parameter numbers for rayon
                                division, other numbers will be adjusted.
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <p className="text_bold">Rayon</p>
                                <p className="text_inferior">
                                  Set the number of rayons in one Depo
                                </p>
                              </td>
                              <td className="text_right text_medium">
                                <input
                                  className="text_right"
                                  name="jumlah_rayon_by_omset"
                                  id={d?.value}
                                  value={
                                    d.jumlah_rayon_by_omset
                                      ? d.jumlah_rayon_by_omset
                                      : 10
                                  }
                                  onChange={this.on_change_inside}
                                  type="number"
                                  min="1"
                                  style={{ minWidth: "100px" }}
                                />
                              </td>
                            </tr>

                            <tr className="text_center">
                              <td colSpan={2}>Or</td>
                            </tr>

                            <tr>
                              <td>
                                <p className="text_bold">Outlet</p>
                                <p>Set the numbers of outlets per rayon</p>
                              </td>
                              <td className="text_right text_medium">
                                <input
                                  className="text_right"
                                  name="avg_toko_rayon_omset"
                                  id={d?.value}
                                  value={
                                    d.avg_toko_rayon_omset
                                      ? d.avg_toko_rayon_omset
                                      : 300
                                  }
                                  onChange={this.on_change_inside}
                                  type="number"
                                  min="1"
                                  style={{ minWidth: "100px" }}
                                />
                              </td>
                            </tr>

                            <tr className="text_center">
                              <td colSpan={2}>Or</td>
                            </tr>

                            <tr>
                              <td>
                                <p className="text_bold">Omset</p>
                                <p>Set the revenue amount per rayon</p>
                              </td>
                              <td className="text_right">
                                <input
                                  className="text_right"
                                  name="avg_value_rayon"
                                  id={d?.value}
                                  value={
                                    d.avg_value_rayon
                                      ? d.avg_value_rayon
                                      : 100_000_000
                                  }
                                  onChange={this.on_change_inside}
                                  type="number"
                                  min="1"
                                  style={{ minWidth: "100px" }}
                                />
                                <br />
                                <p>Decimal for easy reading:</p>
                                <b>
                                  Rp.{" "}
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "decimal",
                                  }).format(d.avg_value_rayon)}
                                </b>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                Note:
                                <br />
                                1. Revenue = average transaction amount of a
                                store in one month.
                                <br />
                                2. The number of stores for each rayon may not
                                be the same because the rayon parameter used is
                                the amount of revenue and the amount of revenue
                                for each store is not the same.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </section>
                    );
                  })}
                </section>
              ) : (
                <section>
                  {config_plan.map((d, idx) => {
                    return (
                      <section key={idx}>
                        <p className="text_bold">{d.value}</p>
                        <table className="table text_medium">
                          <tbody>
                            <tr>
                              <td>Total carton transaction in Depo</td>
                              <td className="text_right text_medium">
                                {new Intl.NumberFormat("id-ID", {
                                  style: "decimal",
                                }).format(d.total_qty)}{" "}
                                Carton
                              </td>
                            </tr>
                            <tr>
                              <td>Total store in Depo</td>
                              <td className="text_right text_medium">
                                {new Intl.NumberFormat("id-ID", {
                                  style: "decimal",
                                }).format(d.total_outlet)}
                              </td>
                            </tr>
                            <tr>
                              <td>Average carton transaction for a store</td>
                              <td className="text_right text_medium">
                                {new Intl.NumberFormat("id-ID", {
                                  style: "decimal",
                                }).format(d.avg_qty)}
                              </td>
                            </tr>
                            <tr className="text_center">
                              <td colSpan={2}>
                                Select one of the parameter numbers for rayon
                                division, other numbers will be adjusted.
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <p className="text_bold">Rayon</p>
                                <p>Set the number of rayons per Depo</p>
                              </td>
                              <td className="text_right text_medium">
                                <input
                                  className="text_right"
                                  name="jumlah_rayon_by_qty"
                                  id={d?.value}
                                  value={
                                    d.jumlah_rayon_by_qty
                                      ? d.jumlah_rayon_by_qty
                                      : 10
                                  }
                                  onChange={this.on_change_inside}
                                  type="number"
                                  min="1"
                                  style={{ minWidth: "100px" }}
                                />
                              </td>
                            </tr>

                            <tr className="text_center">
                              <td colSpan={2}>Or</td>
                            </tr>

                            <tr>
                              <td>
                                <p className="text_bold">Outlet</p>
                                <p>Set the number of outlets per rayon</p>
                              </td>
                              <td className="text_right text_medium">
                                <input
                                  className="text_right"
                                  name="avg_toko_rayon_qty"
                                  id={d?.value}
                                  value={
                                    d.avg_toko_rayon_qty
                                      ? d.avg_toko_rayon_qty
                                      : 300
                                  }
                                  onChange={this.on_change_inside}
                                  type="number"
                                  min="1"
                                  style={{ minWidth: "100px" }}
                                />
                              </td>
                            </tr>

                            <tr className="text_center">
                              <td colSpan={2}>Or</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="text_bold">Carton</p>
                                <p>Set the number of carton units per rayon</p>
                              </td>
                              <td className="text_right">
                                <input
                                  className="text_right"
                                  name="avg_qty_rayon"
                                  id={d?.value}
                                  value={
                                    d.avg_qty_rayon ? d.avg_qty_rayon : 1000
                                  }
                                  onChange={this.on_change_inside}
                                  type="number"
                                  min="1"
                                  style={{ minWidth: "100px" }}
                                />
                                <br />
                                <p>Decimal for easy reading:</p>
                                <b>
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "decimal",
                                  }).format(d.avg_qty_rayon)}{" "}
                                  Carton
                                </b>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                Note:
                                <br />
                                1. Carton = average carton transaction amount of
                                a store in one month.
                                <br />
                                2. The number of stores for each rayon may not
                                be the same because the rayon parameter used is
                                the carton transaction amount and the carton
                                transaction amount for each store is not the
                                same.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </section>
                    );
                  })}
                </section>
              )}
            </section>

            <STEP_2_PREVIEW />
          </section>
        ) : (
          <STEP_2_RUN_MODE_GROUPING />
        )}

        {loading_item && loading_status === "create_rayon_edit_outlet" && (
          <button className="button mt-3" id="grey">
            Running...
          </button>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, {
  set_list,
  set_config_menu,
})(STEP_2_PARENT);
