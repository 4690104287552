//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import {
  set_list,
  turn_on_menu_status,
  turn_off_menu,
  set_config_mode_menu,
  searchSalesmanStep5,
} from "../../actions/dataActions";

//Picture Asset

//General Function
import sort_array from "../../validation/sort_array";

class STEP_5_CHOOSE_SALESMAN_COMPACT extends Component {
  state = {
    div: "",
    searchSalesmanQuery: "",
  };

  activate_day = () => {
    this.props.set_config_mode_menu("distribution", "day");
    this.props.turn_off_menu("cycle");
    this.props.turn_on_menu_status("day");
  };

  inactivate_day = () => {
    this.props.set_config_mode_menu("distribution", "cycle");
    this.props.turn_off_menu("day");
    this.props.turn_on_menu_status("cycle");
  };

  on_click_salesman = (salesman) => {
    const body = {
      list: salesman,
      text: "salesman_select",
    };
    this.props.set_list(body);
    this.props.toggle_choose_salesman();
    const { rayon_list_database, rayon_select, cycle_select } = this.props.data;
    const rayon_object = rayon_list_database.find(
      (r) => r._id === rayon_select
    );
    const plan_list = rayon_object?.plan_list ? rayon_object?.plan_list : [];
    const plan = plan_list.filter(
      (p) => p.cycle === cycle_select && p.salesman_id === salesman._id
    );
    if (plan.length > 0) {
      this.activate_day();
    } else {
      this.inactivate_day();
    }
    this.setState({ searchSalesmanQuery: "" });
    this.props.searchSalesmanStep5("");
  };

  on_change = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value });
  };

  handleSearch(e) {
    this.setState({ searchSalesmanQuery: e.target.value });
    this.props.searchSalesmanStep5(e.target.value);
  }

  render() {
    const { div, searchSalesmanQuery } = this.state;
    // salesman_list_step_5 digunakan supaya regex tidak bertubrukan dengan salesman_list
    const {
      // salesman_list,
      salesman_list_step_5,
    } = this.props.data;
    let salesman_list_filtered = [];
    if (div === "") {
      salesman_list_filtered = salesman_list_step_5;
    } else {
      salesman_list_filtered = salesman_list_step_5.filter((s) => {
        let bool = s?.divisi_arr ? s?.divisi_arr : [];
        bool = bool.map((d) => d.includes(div));
        return bool.includes(true);
      });
    }

    salesman_list_filtered = salesman_list_filtered.map((s) => {
      s.divisi_string = s?.divisi_arr?.[0] || "";
      return s;
    });

    salesman_list_filtered = sort_array(
      salesman_list_filtered,
      "divisi_string",
      true
    );

    return (
      <main>
        <h1 className="text_medium">Choose a salesman</h1>
        <div className="w-full mt-2">
          <input
            type="text"
            className="w-full"
            placeholder={`Search Salesman Name`}
            onChange={this.handleSearch.bind(this)}
            value={searchSalesmanQuery}
          />
        </div>
        <table style={{ width: "100%" }} className="table_lite text_inferior">
          <thead>
            <tr>
              <th>Salesman</th>
              <th style={{ width: "120px" }}>
                <p>DIV:</p>
                <select
                  name="div"
                  id="div"
                  value={div}
                  onChange={this.on_change}
                  className="text_inferior"
                  style={{ width: "80px" }}
                >
                  <option value="">Show all</option>
                  <option value="D1">D1</option>
                  <option value="D2">D2</option>
                  <option value="D3">D3</option>
                  <option value="D4">D4</option>
                  <option value="D5">D5</option>
                  <option value="DA">DA</option>
                </select>
              </th>
            </tr>
          </thead>
          <tbody>
            {salesman_list_filtered.map((s, idx) => {
              const divisi_arr = s?.divisi_arr ? s?.divisi_arr : [];
              return (
                <tr key={idx}>
                  <td>
                    <button className="badge" id="green">
                      {idx + 1}
                    </button>
                    <p>{s?.["Nama Only"]}</p>
                    <div>
                      {divisi_arr.map((d, idx) => (
                        <p key={idx} className="block">
                          {d}
                        </p>
                      ))}
                    </div>

                    {/* <p className="text_inferior">{s?._id}</p> */}
                  </td>
                  <td>
                    <button
                      className="button"
                      id="green"
                      onClick={this.on_click_salesman.bind(this, s)}
                    >
                      Choose
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, {
  set_list,
  turn_on_menu_status,
  turn_off_menu,
  set_config_mode_menu,
  searchSalesmanStep5,
})(STEP_5_CHOOSE_SALESMAN_COMPACT);
