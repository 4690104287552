import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;

/*GET METHODE*/

export const generate_city_name = (params) => async (dispatch) => {
  try {
    const { lat, long } = params;
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/sini/generate_city_name?lat=${lat}&long=${long}`,
      config
    );
    console.log("res.data=", res.data);
  } catch (e) {}
};

//get_all_city
export const get_all_city = (params) => async (dispatch) => {
  try {
    const { rayon_list_database, driver_list } = params;

    let driver_city = [];
    let rayon_city = [];

    const delay_c = 0;
    const delay_rayon = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function_rayon = () => {
      return rayon_list_database.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function_rayon(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };

    const child_function_rayon = async (item) => {
      return delay_rayon().then(() => {
        const core_function = async () => {
          try {
            dispatch({
              type: "set_loading_properties",
              payload: "get_all_city",
            });

            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };
            const long = item?.location?.coordinates?.[0];
            const lat = item?.location?.coordinates?.[1];
            if (long && lat) {
              const res = await axios.get(
                SERVER_URL + `/sini/generate_city_name?lat=${lat}&long=${long}`,
                config
              );
              const city_object = res.data;
              const item_object = { _id: item._id, city_object };
              rayon_city.push(item_object);
            }
          } catch (error) {}
        };
        return core_function();
      });
    };

    const delay_driver = () => new Promise((res) => setTimeout(res, delay_c));

    const parent_function_driver = () => {
      return driver_list.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function_driver(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };

    const child_function_driver = async (item) => {
      return delay_driver().then(() => {
        const core_function = async () => {
          try {
            dispatch({
              type: "set_loading_properties",
              payload: "get_all_city",
            });

            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };
            const long = item?.location?.coordinates?.[0];
            const lat = item?.location?.coordinates?.[1];
            if (long && lat) {
              const res = await axios.get(
                SERVER_URL + `/sini/generate_city_name?lat=${lat}&long=${long}`,
                config
              );
              const city_object = res.data;
              const item_object = { _id: item._id, city_object };
              driver_city.push(item_object);
            }
          } catch (error) {}
        };
        return core_function();
      });
    };

    parent_function_driver().then(() => {
      dispatch({
        type: "set_value_properties",
        payload: { key: "driver_city", value: driver_city },
      });
      parent_function_rayon().then(() => {
        dispatch({
          type: "set_value_properties",
          payload: { key: "rayon_city", value: rayon_city },
        });
        dispatch({
          type: "clear_loading_properties",
        });
      });
    });
  } catch (e) {
    dispatch({
      type: "clear_loading_properties",
    });
  }
};
