import React, { Component } from "react";
import { connect } from "react-redux";
import { add_driver, set_modal_add_driver } from "../../actions/tracking";

export class AddDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SYSTEM: "OUTDIG",
      Depo: "",
      NOPOL: "",
      JENIS: "",
      STATUS: "TO REGULER",
      DRIVER: "",
      HELPER: "",
      KAPASITAS_CTN: "",
      KAPASITAS_M3: "",
    };
  }
  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const body = {
      SYSTEM: this.state.SYSTEM,
      COUNTRY: this.state.COUNTRY,
      Depo: this.state.Depo,
      NOPOL: this.state.NOPOL.split(" ").join(""),
      JENIS: this.state.JENIS,
      STATUS: this.state.STATUS,
      DRIVER: this.state.DRIVER,
      HELPER: this.state.HELPER,
      KAPASITAS_CTN: this.state.KAPASITAS_CTN,
      KAPASITAS_M3: this.state.KAPASITAS_M3,
    };
    this.props.add_driver(body);
    this.props.set_modal_add_driver(false);
  };
  onSelect = (event) => {
    const value = event.target.value;
    const obj = this.props.tracking.driver_type_car.find(
      (e) => e.JENIS === value
    );
    this.setState({
      JENIS: obj.JENIS ?? "-",
      KAPASITAS_CTN: obj.KAPASITAS_CTN ?? "-",
      KAPASITAS_M3: obj.KAPASITAS_M3 ?? "-",
    });
  };
  render() {
    const { loading_top, salesman_filter } = this.props.data;
    const { country_select } = this.props.auth;
    const { driver_type_car, currentDriver } = this.props.tracking;
    return (
      <div>
        <p className="text-lg text-center">Add Driver</p>
        <div>
          <form onSubmit={this.handleSubmit}>
            <div className="grid grid-cols-1">
              <label className="font-semibold" htmlFor="DRIVER">
                DRIVER NAME
              </label>
              <input
                required
                type="text"
                id="DRIVER"
                name="DRIVER"
                className="w-full"
                onChange={this.onChange.bind(this)}
              />
            </div>
            <div className="grid grid-cols-1">
              <label className="font-semibold" htmlFor="HELPER">
                HELPER NAME
              </label>
              <input
                required
                type="text"
                id="HELPER"
                name="HELPER"
                className="w-full"
                onChange={this.onChange.bind(this)}
              />
            </div>
            <div className="grid grid-cols-1">
              <label className="font-semibold" htmlFor="NOPOL">
                LICENSE PLATE NUMBER
              </label>
              <input
                required
                type="text"
                id="NOPOL"
                name="NOPOL"
                className="w-full"
                onChange={this.onChange.bind(this)}
              />
            </div>
            <div className="grid grid-cols-1">
              <label className="font-semibold" htmlFor="COUNTRY">
                COUNTRY
              </label>
              <select
                id="COUNTRY"
                name="COUNTRY"
                className="w-full"
                onChange={this.onChange.bind(this)}
                defaultValue={currentDriver?.COUNTRY}
                required
              >
                <option value={""}>--Select Country--</option>
                {country_select.map((e, i) => (
                  <option key={i} label={e.label} value={e.value}>
                    {e.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid grid-cols-1">
              <label className="font-semibold" htmlFor="depo">
                DEPO
              </label>
              <select
                id="depo"
                name="Depo"
                className="w-full"
                onChange={this.onChange.bind(this)}
                required
              >
                <option value={""}>--Select Depo--</option>
                {salesman_filter.depo.map((e, i) => (
                  <option key={i} data-id={e._id} data-value={e.Depo}>
                    {e.Depo}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid grid-cols-1">
              <label className="font-semibold" htmlFor="JENIS">
                CAR TYPE
              </label>
              <select onChange={this.onSelect.bind(this)} required>
                <option value={""}>--Select Car Type---</option>
                {driver_type_car.map((e, i) => (
                  <option key={i} defaultValue={e.JENIS}>
                    {e.JENIS}
                  </option>
                ))}
              </select>
              {/* <input
                required
                type="text"
                id="JENIS"
                name="JENIS"
                className="w-full"
                onChange={this.onChange.bind(this)}
              /> */}
            </div>
            <div className="grid grid-cols-1">
              <label className="font-semibold" htmlFor="KAPASITAS_CTN">
                CARTON CAPACITY
              </label>
              <input
                required
                type="text"
                id="KAPASITAS_CTN"
                name="KAPASITAS_CTN"
                className="w-full"
                disabled
                onChange={this.onChange.bind(this)}
                defaultValue={this.state.KAPASITAS_CTN}
              />
            </div>
            <div className="grid grid-cols-1">
              <label className="font-semibold" htmlFor="KAPASITAS_M3">
                VOLUME CAPACITY
              </label>
              <input
                required
                type="text"
                id="KAPASITAS_M3"
                name="KAPASITAS_M3"
                className="w-full"
                disabled
                onChange={this.onChange.bind(this)}
                defaultValue={this.state.KAPASITAS_M3}
              />
            </div>
            <div className="mt-2">
              <button type="submit" className="button_main w-full">
                {loading_top ? "Loading..." : "ADD DRIVER"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tracking: state.tracking,
  data: state.data,
  auth: state.auth,
});

const mapDispatchToProps = {
  set_modal_add_driver,
  add_driver,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDriver);
