/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal";

/*REDUX FUNCTION*/
import { searchSalesmanStep5, set_list } from "../../actions/dataActions";
import {
  push_kunjungan_arr_new,
  push_plan_list,
} from "../../actions/rayonActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import rayonisasi_general from "../../validation/rayonisasi_general";

/*NON IMPORT*/

class STEP_6_DAY extends Component {
  state = {
    modal_error: false,
  };

  toggle_error = () => {
    this.setState({ modal_error: !this.state.modal_error });
    this.props.searchSalesmanStep5("");
  };

  on_change = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === "jumlah_hari") {
      this.props.set_list({ text: "jumlah_hari", list: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  rayonisasi_hari = () => {
    //PARAMETER GENERAL UNTUK CYCLE 1 & 2
    const { outlet_list_filter_0, jumlah_hari } = this.props.data;
    let { salesman_select, rayon_select } = this.props.data;

    //RAYONISASI CYCLE 1
    const outlet_cycle_1 = outlet_list_filter_0.filter(
      (t) => t.cycle === "cycle 1"
    );
    const body_1 = {
      toko_list_main: outlet_cycle_1,
      text: "day",
      jumlah_rayon: jumlah_hari,
    };
    const result_1 = rayonisasi_general(body_1);

    //push kunjungan to outlet collection
    const body_outlet_1 = {
      day_list: result_1.rayon_list_complete,
      outlet_list_filter_0: outlet_cycle_1,
      cycle_select: "cycle 1",
      salesman_select,
    };

    //push plan list to rayon collection
    const body_plan_1 = {
      rayon_id: rayon_select,
      plan: {
        cycle: "cycle 1",
        salesman_id: salesman_select._id,
        day_list: result_1.rayon_list_complete,
      },
    };

    this.props.push_plan_list(body_plan_1);
    this.props.push_kunjungan_arr_new(body_outlet_1);

    //RAYONISASI CYCLE 2
    const outlet_cycle_2 = outlet_list_filter_0.filter(
      (t) => t.cycle === "cycle 2"
    );
    const body_2 = {
      toko_list_main: outlet_cycle_2,
      text: "day",
      jumlah_rayon: jumlah_hari,
    };
    const result_2 = rayonisasi_general(body_2);

    //push kunjungan to outlet collection
    const body_outlet_2 = {
      day_list: result_2.rayon_list_complete,
      outlet_list_filter_0: outlet_cycle_2,
      cycle_select: "cycle 2",
      salesman_select,
    };

    //push plan list to rayon collection
    const body_plan_2 = {
      rayon_id: rayon_select,
      plan: {
        cycle: "cycle 2",
        salesman_id: salesman_select._id,
        day_list: result_2.rayon_list_complete,
      },
    };

    this.props.push_plan_list(body_plan_2);
    this.props.push_kunjungan_arr_new(body_outlet_2);
  };

  render() {
    const { modal_error } = this.state;

    const {
      rayon_select,

      rayon_list_database,
      salesman_select,
      jumlah_hari,
    } = this.props.data;

    let rayon_object = {};
    if (rayon_list_database.length > 0) {
      rayon_object = rayon_list_database.find((r) => r._id === rayon_select);
    }

    const plan_list = rayon_object?.plan_list ? rayon_object?.plan_list : [];

    const modal_error_content = modal_error && (
      <Modal modalSize="small" isOpen={modal_error} onClose={this.toggle_error}>
        <div className="box-body text_center">
          Please complete the previous step
        </div>
      </Modal>
    );

    return (
      <section className="main_container mb-3">
        {modal_error_content}
        <p className="badge mb-1" id="blue">
          Step 6
        </p>
        <p className="text_medium">Divide to {jumlah_hari} days</p>
        <hr />
        <table className="table text_inferior">
          <tbody>
            <tr>
              <td>Set how many days in one working week</td>
              <td>
                <input
                  className="text_right"
                  name="jumlah_hari"
                  id="jumlah_hari"
                  value={jumlah_hari}
                  onChange={this.on_change}
                  type="number"
                  style={{ width: "100px" }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {!salesman_select?._id ? (
          <button className="button mt-3" id="grey" onClick={this.toggle_error}>
            Run & Save
          </button>
        ) : plan_list.length > 0 ? (
          <button className="button mt-3" id="grey" onClick={this.toggle_error}>
            Run & Save
          </button>
        ) : (
          <button
            className="button mt-3"
            id="green"
            onClick={this.rayonisasi_hari}
          >
            Run & Save
          </button>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({ data: state.data });

export default connect(mapStateToProps, {
  push_plan_list,
  push_kunjungan_arr_new,
  searchSalesmanStep5,
  set_list,
})(STEP_6_DAY);
