/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { post_revisi } from "../../actions/outletActions";

/*PICTURE ASSET*/

/*GENERAL*/

/*NON IMPORT*/

class OUTLET_SURVEY_POPUP extends Component {
  state = {
    message_revisi: "",
  };

  handle_radio_revisi = (event) => {
    this.setState({
      message_revisi: event.target.value,
    });
  };

  handle_submit_revisi = async (event) => {
    event.preventDefault();
    this.props.post_revisi({
      message_revisi: this.state.message_revisi,
      outlet_id: this.props.properties._id,
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const props = this.props.properties;

    //content
    let images;
    if (typeof props.images === "string") {
      images = JSON.parse(props.images);
    } else {
      images = props.images;
    }

    let revisi;
    if (typeof props.revisi === "string") {
      revisi = JSON.parse(props.revisi);
    } else {
      revisi = props.revisi;
    }
    return (
      <main style={{ fontFamily: `"Inter", sans-serif` }}>
        <div
          className="text_bold mb-3 py-2"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          Store Ploting Details
        </div>
        <div>
          {revisi?.status_revisi && (
            <div className="border p-4 mb-2 mt-5 bg-yellow-100 rounded">
              Store <b>{props["Nama Toko"]}</b> is doing revision/unplot for{" "}
              <b>{revisi?.message_revisi}</b>.
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-1 mb-4">
          <div className="text-center">
            <h1 className="font-semibold">Store Image</h1>
            <img src={images?.[0]?.Location} alt="Not yet uploaded" />
          </div>
          <div className="text-center">
            <h1 className="font-semibold">Display Image</h1>
            <img src={images?.[1]?.Location} alt="Not yet uploaded" />
          </div>
        </div>
        <section className="mb-4">
          <div
            className="text_bold"
            style={{
              fontSize: "15px",
              wordBreak: "break-word",
            }}
          >
            Revision/Unplot Store
          </div>
          <form onSubmit={this.handle_submit_revisi}>
            <input
              type="radio"
              id="lokasi"
              name="unplot"
              value="Lokasi"
              onChange={this.handle_radio_revisi}
            />
            <label htmlFor="lokasi" className="ml-1">
              Location
            </label>
            <br />
            <input
              type="radio"
              id="foto"
              name="unplot"
              value="Foto"
              onChange={this.handle_radio_revisi}
            />
            <label htmlFor="foto" className="ml-1">
              Image
            </label>
            <br />
            <input
              type="radio"
              id="lokasi_foto"
              name="unplot"
              value="Lokasi dan foto"
              onChange={this.handle_radio_revisi}
            />
            <label htmlFor="lokasi_foto" className="ml-1">
              Location and Image
            </label>
            <br />
            <button className="button_main mt-2" type="submit">
              Save Revision
            </button>
          </form>
        </section>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Store Name
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["Nama Toko"] ? props["Nama Toko"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Outlet ID
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["ID Outlet"] ? props["ID Outlet"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Address
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["ALAMAT"] ? props["ALAMAT"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Plan
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["Plan"] ? props["Plan"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Telephone Number
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["NO Tlpon"] ? props["NO Tlpon"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Outlet Type
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["Type Outlet"] ? props["Type Outlet"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Depo
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["Depo"] ? props["Depo"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Branch
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["CABANG"] ? props["CABANG"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Region
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["REGIONAL"] ? props["REGIONAL"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Subdistrict
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["KELURAHAN"] ? props["KELURAHAN"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          District
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["KECAMATAN"] ? props["KECAMATAN"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          City/Regency
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["KABUPATEN"] ? props["KABUPATEN"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Province
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["PROPINSI"] ? props["PROPINSI"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Branch ID
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["ID BRANCH"] ? props["ID BRANCH"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          SFA ID
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["ID SFA"] ? props["ID SFA"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          System
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["SYSTEM"] ? props["SYSTEM"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Visit Day
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["HARIKunjungan"] ? props["HARIKunjungan"] : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Visit Frequency
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {props["FreqVisit"] ? props["FreqVisit"] : "Unfilled"}
        </div>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, { post_revisi })(OUTLET_SURVEY_POPUP);
