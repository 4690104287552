const generate_v_max = (jumlah_rayon, ratio) => {
  let v_max = Math.round(Math.sqrt(jumlah_rayon) * Math.sqrt(ratio));
  if (v_max === 0) {
    v_max = 1;
  }
  if (v_max > jumlah_rayon) {
    v_max = jumlah_rayon;
  }
  return v_max;
};

export default generate_v_max;
