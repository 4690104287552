/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal";
import STEP_8_MODAL_REPLACE_RAYON from "./STEP_8_MODAL_REPLACE_RAYON";
import STEP_8_MODAL_ADD_RAYON from "./STEP_8_MODAL_ADD_RAYON";

//Redux function
import {
  get_driver_multi_depo,
  push_rayon_id,
  pull_rayon_id,
  searchDriverRayon,
  set_rayon_id_bulk,
  set_rayon_id,
} from "../../actions/tracking";
import { clear_deleted_rayon } from "../../actions/driverActions";
import { generate_city_name, get_all_city } from "../../actions/siniActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import is_equal_array from "../../validation/is_equal_array";

/*NON IMPORT*/
const mode_city_array = ["kelurahan", "kecamatan", "kota", "provinsi"];

class STEP_8_MANAGE_DRIVER extends Component {
  state = {
    depos: [],
    rayon_list_database: [],
    rayon_id: "",
    driver_id: "",
    driver_list: [],
    driver_object: {},
    searchDriverQuery: "",
    mode_table: "side", //list || side
    mode_city: ["kecamatan"],
    mode_pairing: "kecamatan",

    modal_rayon: false,
    modal_add_rayon: false,
    modal_replace_rayon: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      list_menu_active,
      outlet_list_filter_0_back_up,
      rayon_list_database,
    } = nextProps.data;
    let { driver_list } = nextProps.tracking;
    let depos = list_menu_active.find((e) => e.key === "salesman")?.config
      ?.value;
    depos = depos ? depos : [];
    if (
      !is_equal_array(depos, prevState.depos) ||
      !is_equal_array(driver_list, prevState.driver_list)
    ) {
      rayon_list_database = rayon_list_database.map((e) => {
        const toko_list = outlet_list_filter_0_back_up.filter(
          (t) => t.rayon_id === e._id
        );
        let sum_value = toko_list.map((t) => (t?.value ? t?.value : 0));
        sum_value = parseInt(sum_value.reduce((a, b) => a + b, 0));
        const sum_value_string = new Intl.NumberFormat("id-ID", {
          style: "decimal",
        }).format(sum_value);
        let sum_qty = toko_list.map((t) => (t?.qty ? t?.qty : 0));
        sum_qty = parseInt(sum_qty.reduce((a, b) => a + b, 0));
        const sum_qty_string = new Intl.NumberFormat("id-ID", {
          style: "decimal",
        }).format(sum_qty);
        e.sum_value = sum_value;
        e.sum_value_string = sum_value_string;
        e.sum_qty = sum_qty;
        e.sum_qty_string = sum_qty_string;
        e.sum_outlet = toko_list.length;
        return e;
      });
      //inserting driver list to depos
      depos = depos.map((e) => {
        //list driver yang ada pada depo
        const driver_depo_list = driver_list.filter(
          (driver) => driver.Depo === e.value
        );
        e.driver_depo_list = driver_depo_list;
        //list rayon yang ada di dalam depo
        const rayon_list = rayon_list_database.filter(
          (rayon) => rayon.depo_string === e.value
        );
        e.rayon_list = rayon_list;
        //rata-rata rayon pada depo
        const rayon_average = (
          rayon_list.length / driver_depo_list.length
        ).toFixed(1);
        e.rayon_average = rayon_average;
        //total omset pada depo
        let sum_value = rayon_list.map((r) => r.sum_value);
        sum_value = parseInt(sum_value.reduce((a, b) => a + b, 0));
        e.sum_value = sum_value;
        //rata-rata omset pada depo
        const value_average = (sum_value / driver_depo_list.length).toFixed(0);
        e.value_average = value_average;
        //omset string dengan format desimal
        const value_average_string = new Intl.NumberFormat("id-ID", {
          style: "decimal",
        }).format(value_average);
        e.value_average_string = value_average_string;
        //total omset dengan format desimal
        const sum_value_string = new Intl.NumberFormat("id-ID", {
          style: "decimal",
        }).format(sum_value);
        e.sum_value_string = sum_value_string;
        //total karton
        let sum_qty = rayon_list.map((r) => r.sum_qty);
        sum_qty = parseInt(sum_qty.reduce((a, b) => a + b, 0));
        e.sum_qty = sum_qty;
        //rata-rata karton
        const qty_average = (sum_qty / driver_depo_list.length).toFixed(1);
        e.qty_average = qty_average;
        //rata-rata karton dengan desimal
        const qty_average_string = new Intl.NumberFormat("id-ID", {
          style: "decimal",
        }).format(qty_average);
        e.qty_average_string = qty_average_string;
        //total karton dengan format desimal
        const sum_qty_string = new Intl.NumberFormat("id-ID", {
          style: "decimal",
        }).format(sum_qty);
        e.sum_qty_string = sum_qty_string;
        return e;
      });
      return {
        depos,
        driver_list,
      };
    } else return null;
  }

  toggle_add_rayon = (driver_object) => {
    this.setState({ modal_add_rayon: !this.state.modal_add_rayon });
    if (driver_object) {
      this.setState({ driver_object });
    }
  };

  toggle_replace_rayon = (driver_object) => {
    this.setState({ modal_replace_rayon: !this.state.modal_replace_rayon });
    if (driver_object) {
      this.setState({ driver_object });
    }
  };

  on_pairing_by_kecamatan = () => {
    const { mode_pairing } = this.state;
    let { driver_city, rayon_city } = this.props.properties;
    //is_used: false || true
    driver_city.forEach((driver, idx) => {
      const value = driver?.city_object?.[mode_pairing];
      const rayon_id = rayon_city.find(
        (rayon) =>
          rayon?.city_object?.[mode_pairing] === value && !rayon?.is_used
      );
      const rayon_index = rayon_city.findIndex(
        (rayon) =>
          rayon?.city_object?.[mode_pairing] === value && !rayon?.is_used
      );
      if (rayon_index > -1) {
        driver_city[idx].rayon_id = rayon_id?._id;
        rayon_city[rayon_index].is_used = true;
      }
    });
    const body = {
      array: driver_city,
    };
    // console.table(driver_city);
    this.props.set_rayon_id_bulk(body);
  };

  toggle_array = (item) => {
    let { mode_city } = this.state;
    if (mode_city.includes(item)) {
      mode_city = mode_city.filter((element) => element !== item);
    } else {
      mode_city.push(item);
    }
    this.setState({ mode_city });
  };

  on_get_all_city = () => {
    const { rayon_list_database } = this.props.data;
    const { driver_list } = this.props.tracking;
    const params = { rayon_list_database, driver_list };
    this.props.get_all_city(params);
  };

  on_generate_city_name = (long, lat) => {
    const params = { long, lat };
    this.props.generate_city_name(params);
  };

  on_set_mode_table = (mode_table) => {
    this.setState({ mode_table });
  };

  on_clear_deleted_rayon = () => {
    const { depos } = this.state;

    /*
    1. Map depo, ambil driver listnya
    2. Jadikan array of object {driver_id, rayon_id: Array}
    3. Cocokan rayon_id dengan rayon yg ada, jika tidak ada ada status is_real: false
    
    {
      driver_id,
      rayon_id_array :[
        { 
          rayon_id,
          is_real: Boolan
        }
      ]
    }
    */

    let rayon_delete = depos.map((depo) => {
      const rayon_list = depo.rayon_list;
      let driver_depo_list = depo?.driver_depo_list || [];
      driver_depo_list = driver_depo_list.map((driver) => {
        let rayon_id_list = driver?.rayon_id_list || [];
        rayon_id_list = rayon_id_list.map((rayon_id) => {
          const rayon = rayon_list.find(
            (rayon_test) => rayon_test?._id === rayon_id
          );
          const is_delete = !rayon?._id;
          return { driver_id: driver._id, rayon_id: rayon_id, is_delete };
        });
        return rayon_id_list;
      });
      return driver_depo_list;
    });
    rayon_delete = rayon_delete.flat(2);
    rayon_delete = rayon_delete.filter((rayon) => rayon.is_delete);

    //map driver_id_ist dan rayon_id_list
    let driver_id_list = rayon_delete.map((rayon) => rayon.driver_id);
    let rayon_id_list = rayon_delete.map((rayon) => rayon.rayon_id);
    //set supaya unik
    driver_id_list = [...new Set(driver_id_list)];
    rayon_id_list = [...new Set(rayon_id_list)];

    const body = {
      driver_id_list,
      rayon_id_list,
    };
    this.props.clear_deleted_rayon(body);

    this.on_load();
  };

  on_click_rayon = (rayon_id) => {
    this.setState({ rayon_id });
    const { driver_id } = this.state;
    const body = {
      driver_id,
      rayon_id,
    };
    this.props.push_rayon_id(body);
  };

  on_pull_rayon = (rayon_id) => {
    this.setState({ rayon_id });
    const { driver_id } = this.state;
    const body = {
      driver_id,
      rayon_id,
    };
    this.props.pull_rayon_id(body);
  };

  on_load = () => {
    const { list_menu_active } = this.props.data;
    const config_plan = list_menu_active.find((e) => e.key === "salesman")
      ?.config?.value;
    const depos = config_plan.map((e) => e.value);
    this.props.get_driver_multi_depo(depos);
  };

  toggle_rayon = (driver_id) => {
    this.setState({ modal_rayon: !this.state.modal_rayon });
    if (driver_id) this.setState({ driver_id });
  };

  on_change = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value });
  };

  handleSearch(e) {
    this.setState({ searchDriverQuery: e.target.value });
    this.props.searchDriverRayon(e.target.value);
  }

  render() {
    //local storage

    //local state
    const {
      depos,
      modal_rayon,
      driver_id,
      rayon_id,
      searchDriverQuery,
      mode_table,
      mode_city,
      mode_pairing,
      modal_add_rayon,
      modal_replace_rayon,
      driver_object,
    } = this.state;

    //global props
    const { loading_status, loading_item } = this.props.data;
    const { driver_city, rayon_city } = this.props.properties;
    const loading_item_properties = this.props.properties.loading_item;
    const loading_status_properties = this.props.properties.loading_status;

    //content

    let list_content;
    list_content = (
      <main>
        {depos.map((depo, idx) => {
          let rayon_id_used = depo.driver_depo_list || [];
          rayon_id_used = rayon_id_used.map((driver) => {
            return driver.rayon_id_list;
          });
          rayon_id_used = rayon_id_used.flat();
          const rayon_list = depo.rayon_list ? depo.rayon_list : [];

          let driver_table;
          if (mode_table === "list") {
            driver_table = (
              <table className="table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th className="width_col_0">NO</th>
                    <th className="width_col_3">DRIVER NAME</th>
                    <th className="width_col_1">PLAT NUMBER</th>
                    <th className="width_col_2">VEHICLE</th>
                    <th className="width_col_1">CAPACITY (M³)</th>
                    <th className="width_col_1">CAPACITY (Carton)</th>
                    <th className="width_col_4">SELECTED RAYON</th>
                    <th>CHOOSE RAYON</th>
                  </tr>
                </thead>
                <tbody>
                  {depo.driver_depo_list.map((driver, idx) => {
                    let rayon_id_list = driver?.rayon_id_list
                      ? driver?.rayon_id_list
                      : [];
                    return (
                      <tr key={idx}>
                        <th>{idx + 1}</th>
                        <td>{driver?.DRIVER}</td>
                        <td>{driver?.NOPOL}</td>
                        <td>{driver?.JENIS}</td>
                        <td>{driver?.KAPASITAS_M3}</td>
                        <td>{driver?.KAPASITAS_CTN}</td>
                        <td>
                          {rayon_id_list.length > 0 ? (
                            <table>
                              <thead>
                                <tr>
                                  <th>RAYON</th>
                                  <th>QTY / Day</th>
                                </tr>
                              </thead>
                              <tbody>
                                {rayon_id_list.map((rayon_id, idx) => {
                                  const rayon = rayon_list.find(
                                    (r) => r?._id === rayon_id
                                  );
                                  let resultQty;
                                  if (rayon !== undefined) {
                                    let getQty = rayon.sum_qty_string
                                      .split(".")
                                      .join("");
                                    resultQty = Number(getQty / 24);
                                    resultQty = resultQty.toFixed(1);
                                  } else {
                                    resultQty = "Rayon has been removed";
                                  }
                                  return (
                                    <tr key={idx}>
                                      <td>
                                        {rayon?._id
                                          ? rayon?.name
                                          : "Rayon has been removed"}
                                      </td>
                                      <td>{resultQty}</td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <td>Total QTY / Day</td>
                                  <td>
                                    {(() => {
                                      let totalQty = 0;
                                      rayon_id_list.forEach((e) => {
                                        const rayon = rayon_list.find(
                                          (r) => r?._id === e
                                        );
                                        let resultQty = 0;
                                        if (rayon !== undefined) {
                                          let getQty = rayon.sum_qty_string
                                            .split(".")
                                            .join("");
                                          resultQty = Number(getQty / 24);
                                          resultQty = resultQty.toFixed(1);
                                          resultQty = parseFloat(resultQty);
                                        }
                                        totalQty += resultQty;
                                      });
                                      return totalQty;
                                    })()}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          ) : (
                            <p>-</p>
                          )}
                        </td>
                        <td>
                          {modal_rayon && driver._id === driver_id ? (
                            <main>
                              <h1>Choose rayon for this delivery driver:</h1>
                              <table className="mb-5">
                                <thead>
                                  <tr>
                                    <th>RAYON</th>
                                    <th>SUM OUTLET</th>
                                    <th>SUM QTY</th>
                                    <th>QTY / DAY</th>
                                    <th>SUM RP</th>
                                    <th>RP / DAY</th>
                                    <th>ACTION</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rayon_list.map((rayon, idx) => {
                                    let getQty = rayon.sum_qty / 24;
                                    let getRp = rayon.sum_value / 24;
                                    let resultQty = 0;
                                    resultQty = getQty.toFixed(1);
                                    resultQty = parseFloat(resultQty);

                                    let resultRp = parseInt(getRp);
                                    resultRp = resultRp.toFixed(1);
                                    resultRp = parseFloat(resultRp);

                                    return (
                                      <tr key={idx}>
                                        <td>{rayon.name}</td>
                                        <td>{rayon.sum_outlet}</td>
                                        <td>{rayon.sum_qty_string}</td>
                                        <td>{resultQty}</td>
                                        <td>{rayon.sum_value_string}</td>
                                        <td>
                                          {Intl.NumberFormat("id-ID", {
                                            style: "decimal",
                                          }).format(resultRp)}
                                        </td>
                                        <td>
                                          {rayon_id === rayon._id &&
                                          loading_status &&
                                          loading_item === "push_rayon_id" ? (
                                            <button
                                              className="button_small"
                                              id="blue"
                                            >
                                              Saving...
                                            </button>
                                          ) : rayon_id === rayon._id &&
                                            loading_status &&
                                            loading_item === "pull_rayon_id" ? (
                                            <button
                                              className="button_small"
                                              id="blue"
                                            >
                                              Deleting...
                                            </button>
                                          ) : rayon_id_list.includes(
                                              String(rayon._id)
                                            ) ? (
                                            <>
                                              <button
                                                className="button_small"
                                                id="red"
                                                onClick={this.on_pull_rayon.bind(
                                                  this,
                                                  rayon._id
                                                )}
                                              >
                                                Undo
                                              </button>
                                              <br />
                                              <button className="text_inferior text_left">
                                                Already stored in this driver
                                              </button>
                                            </>
                                          ) : rayon_id_used.includes(
                                              String(rayon._id)
                                            ) ? (
                                            <>
                                              <button
                                                className="button_small"
                                                id="red"
                                                onClick={this.on_click_rayon.bind(
                                                  this,
                                                  rayon._id
                                                )}
                                              >
                                                Force Save
                                              </button>
                                              <br />
                                              <p className="text_inferior">
                                                Already used by other drivers
                                              </p>
                                            </>
                                          ) : (
                                            <button
                                              className="button_small"
                                              id="green"
                                              onClick={this.on_click_rayon.bind(
                                                this,
                                                rayon._id
                                              )}
                                            >
                                              Select & Save
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                              <button
                                className="button"
                                id="green"
                                onClick={this.toggle_rayon}
                              >
                                Done
                              </button>
                            </main>
                          ) : modal_rayon && driver._id !== driver_id ? (
                            <button
                              className="button"
                              id="grey"
                              onClick={this.toggle_rayon.bind(this, driver._id)}
                            >
                              Choose rayon
                            </button>
                          ) : (
                            <button
                              className="button"
                              id="grey"
                              onClick={this.toggle_rayon.bind(this, driver._id)}
                            >
                              Choose rayon
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          } else {
            driver_table = (
              <main>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        <p className="text_header">DRIVER</p>
                        <table className="table" style={{ width: "100%" }}>
                          <thead>
                            <tr>
                              <th>NO</th>
                              <th>DRIVER NAME</th>
                              <th>PLAT NUMBER</th>
                              <th>LAST POSITION</th>
                              <th>RAYON STATUS</th>
                              <th>MANUAL ACTION</th>
                            </tr>
                          </thead>
                          <tbody>
                            {depo.driver_depo_list.map((driver, idx) => {
                              const city_element = driver_city.find(
                                (city) => city._id === driver._id
                              );
                              return (
                                <tr key={idx}>
                                  <th>{idx + 1}</th>
                                  <td>{driver?.DRIVER}</td>
                                  <td>{driver?.NOPOL}</td>
                                  <td style={{ width: "150px" }}>
                                    <p>
                                      Long: {driver?.location?.coordinates?.[0]}
                                    </p>
                                    <p>
                                      Lat: {driver?.location?.coordinates?.[1]}
                                    </p>

                                    {city_element?.city_object?.kelurahan && (
                                      <>
                                        {mode_city.includes("kelurahan") && (
                                          <p className="badge_pill background_green mr-1 mb-1">
                                            KEL:{" "}
                                            {
                                              city_element?.city_object
                                                ?.kelurahan
                                            }
                                          </p>
                                        )}
                                        {mode_city.includes("kecamatan") && (
                                          <p className="badge_pill background_green mr-1 mb-1">
                                            KEC:{" "}
                                            {
                                              city_element?.city_object
                                                ?.kecamatan
                                            }
                                          </p>
                                        )}
                                        {mode_city.includes("kota") && (
                                          <p className="badge_pill background_green mr-1 mb-1">
                                            KOTA:{" "}
                                            {city_element?.city_object?.kota}
                                          </p>
                                        )}
                                        {mode_city.includes("provinsi") && (
                                          <p className="badge_pill background_green mr-1 mb-1">
                                            PROV:{" "}
                                            {
                                              city_element?.city_object
                                                ?.provinsi
                                            }
                                          </p>
                                        )}
                                      </>
                                    )}
                                  </td>

                                  <td>
                                    <p className="text_small">
                                      {driver?.rayon_mode}
                                    </p>
                                    {driver?.rayon_id_list?.map(
                                      (rayon_id, idx) => {
                                        const rayon_object = rayon_list?.find(
                                          (object) => object._id === rayon_id
                                        );
                                        return (
                                          <p key={idx}>●{rayon_object?.name}</p>
                                        );
                                      }
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="button_small background_grey mb-3"
                                      onClick={this.toggle_add_rayon.bind(
                                        this,
                                        driver
                                      )}
                                    >
                                      Multiple rayon
                                    </button>
                                    <br />
                                    <button
                                      className="button_small background_grey mb-3"
                                      onClick={this.toggle_replace_rayon.bind(
                                        this,
                                        driver
                                      )}
                                    >
                                      One rayon
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <p className="text_header">RAYON</p>
                        <table className="table" style={{ width: "100%" }}>
                          <thead>
                            <tr>
                              <th className="width_col_0">NO</th>
                              <th className="width_col_3">RAYON NAME</th>
                              <th className="width_col_1">CENTER POINT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rayon_list.map((item, idx) => {
                              const city_element = rayon_city.find(
                                (city) => city._id === item._id
                              );
                              return (
                                <tr key={idx}>
                                  <th>{idx + 1}</th>
                                  <td>{item?.name}</td>
                                  <td style={{ minWidthwidth: "150px" }}>
                                    <p>
                                      Long: {item?.location?.coordinates?.[0]}
                                    </p>
                                    <p>
                                      Lat: {item?.location?.coordinates?.[1]}
                                    </p>
                                    {city_element?.city_object?.kelurahan && (
                                      <>
                                        {mode_city.includes("kelurahan") && (
                                          <p className="badge_pill background_green mr-1 mb-1">
                                            KEL:{" "}
                                            {
                                              city_element?.city_object
                                                ?.kelurahan
                                            }
                                          </p>
                                        )}
                                        {mode_city.includes("kecamatan") && (
                                          <p className="badge_pill background_green mr-1 mb-1">
                                            KEC:{" "}
                                            {
                                              city_element?.city_object
                                                ?.kecamatan
                                            }
                                          </p>
                                        )}
                                        {mode_city.includes("kota") && (
                                          <p className="badge_pill background_green mr-1 mb-1">
                                            KOTA:{" "}
                                            {city_element?.city_object?.kota}
                                          </p>
                                        )}
                                        {mode_city.includes("provinsi") && (
                                          <p className="badge_pill background_green mr-1 mb-1">
                                            PROV:{" "}
                                            {
                                              city_element?.city_object
                                                ?.provinsi
                                            }
                                          </p>
                                        )}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <br />

                <section className="container_light mb-5">
                  <div className="badge_pill background_blue mb-1">STEP 1</div>

                  <br />
                  <span className="text_medium mr-5">Show city:</span>
                  <table>
                    <tbody>
                      {mode_city_array.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td onClick={this.toggle_array.bind(this, item)}>
                              <div
                                id={
                                  mode_city.includes(item)
                                    ? "checklist_active"
                                    : "checklist_not"
                                }
                                className="mb-1"
                              />
                            </td>
                            <td>{item}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <br />
                  {loading_status_properties &&
                  loading_item_properties === "get_all_city" ? (
                    <div className="button background_grey mb-5">
                      Loading ...
                    </div>
                  ) : (
                    <button
                      className="button background_green mb-5"
                      onClick={this.on_get_all_city}
                    >
                      Generate city name by coordinates
                    </button>
                  )}
                </section>

                <section className="container_light mb-5">
                  <div className="badge_pill background_blue mb-1">STEP 2</div>
                  <p className="text_medium">Mode pairing</p>
                  <select
                    value={mode_pairing}
                    name="mode_pairing"
                    onChange={this.on_change}
                  >
                    {mode_city_array.map((item, idx) => {
                      return (
                        <option key={idx} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                  <br />
                  {loading_status && loading_item === "set_rayon_id_bulk" ? (
                    <div className="button background_grey">Loading...</div>
                  ) : (
                    <button
                      className="button background_green"
                      onClick={this.on_pairing_by_kecamatan}
                    >
                      START PAIRING BY {mode_pairing}
                    </button>
                  )}
                </section>
              </main>
            );
          }

          return (
            <main key={idx}>
              <h1 className="text_bold">{depo.value}</h1>
              <h2 className="text_medium">Statistic on this Depo:</h2>

              <table className="table mb-5">
                <tbody>
                  <tr>
                    <th>Total driver</th>
                    <td>{depo?.driver_depo_list?.length}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Total rayon</th>
                    <td>{depo?.rayon_list?.length}</td>
                  </tr>
                  <tr>
                    <th>Average rayon (per driver)</th>
                    <td>
                      {depo.rayon_average}
                      {depo.rayon_average < 1 && (
                        <span>
                          {" "}
                          (The number of rayons is <b>less</b> than the number
                          of drivers)
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Total quantity in carton</th>
                    <td>{depo.sum_qty_string}</td>
                  </tr>
                  <tr>
                    <th>Average quantity (per driver)</th>
                    <td>
                      <b>{depo.qty_average_string}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Total omset in Rp</th>
                    <td>{depo.sum_value_string}</td>
                  </tr>
                  <tr>
                    <th>Average omset (per driver)</th>
                    <td>{depo.value_average_string}</td>
                  </tr>
                </tbody>
              </table>

              <p className="text_medium">Choose table mode</p>
              <button
                className={`button mr-2 ${
                  mode_table === "list" ? "background_black" : "background_grey"
                }`}
                onClick={this.on_set_mode_table.bind(this, "list")}
              >
                List
              </button>
              <button
                className={`button ${
                  mode_table === "side" ? "background_black" : "background_grey"
                }`}
                onClick={this.on_set_mode_table.bind(this, "side")}
              >
                Side by side
              </button>

              <div className="w-1/4 mt-2">
                <input
                  type="text"
                  className="w-full"
                  placeholder={`Search Driver Name`}
                  onChange={this.handleSearch.bind(this)}
                  value={searchDriverQuery}
                />
              </div>
              {driver_table}
            </main>
          );
        })}
      </main>
    );

    const modal_add_rayon_content = modal_add_rayon && (
      <Modal
        modalSize="medium"
        isOpen={modal_add_rayon}
        onClose={this.toggle_add_rayon}
      >
        <div className="box-body" id="box-body">
          <STEP_8_MODAL_ADD_RAYON
            driver_object={driver_object}
            toggle_add_rayon={this.toggle_add_rayon}
          />
        </div>
      </Modal>
    );

    const modal_replace_rayon_content = modal_replace_rayon && (
      <Modal
        modalSize="medium"
        isOpen={modal_replace_rayon}
        onClose={this.toggle_replace_rayon}
      >
        <div className="box-body" id="box-body">
          <STEP_8_MODAL_REPLACE_RAYON
            driver_object={driver_object}
            toggle_replace_rayon={this.toggle_replace_rayon}
          />
        </div>
      </Modal>
    );

    return (
      <div style={{ minHeight: "100vh" }}>
        {modal_add_rayon_content}
        {modal_replace_rayon_content}
        <h1 className="text_header">Manage Drivers</h1>
        <button className="button" id="green" onClick={this.on_load}>
          Reload driver data
        </button>
        <br />
        <br />
        <button
          className="button"
          id="red"
          onClick={this.on_clear_deleted_rayon}
        >
          Clear Deleted Rayon
        </button>
        <br />
        <br />
        {list_content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  auth: state.auth,
  tracking: state.tracking,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  get_driver_multi_depo,
  push_rayon_id,
  pull_rayon_id,
  searchDriverRayon,
  clear_deleted_rayon,
  generate_city_name,
  get_all_city,
  set_rayon_id_bulk,
  set_rayon_id,
})(STEP_8_MANAGE_DRIVER);
