/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import pointsWithinPolygon from "@turf/points-within-polygon";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { statusDraw, setDrawMode } from "../../actions/mapActions";
import {
  edit_many_toko_rayon,
  show_all_outlet,
  edit_rayon_location,
} from "../../actions/rayonActions";
import {
  turn_on_menu_status,
  turn_off_menu,
  set_config_mode_menu,
  set_config_menu,
  filter_toko_by_param,
  multi_select_toko_by_params,
  multi_reset_toko_by_params,
  outlet_multi_select_data,
  select_rayon,
  set_list,
} from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import st_colors from "../../validation/st_colors.json";

/*NON IMPORT*/

class STEP_3_CHOOSE_RAYON extends Component {
  state = {};

  on_edit_rayon_location_bulk = () => {
    const { rayon_list_database, outlet_list_filter_0_back_up } =
      this.props.data;
    const body = {
      rayon_list_database,
      outlet_list_filter_0_back_up,
    };
    this.props.edit_rayon_location(body);
  };

  on_show_all_outlet = () => {
    this.props.show_all_outlet();
    this.props.set_config_mode_menu("distribution", "rayon");
    this.props.set_list({
      text: "rayon_select",
      list: "",
    });
  };

  on_reset_all_outlet = () => {
    this.props.multi_reset_toko_by_params([]);
  };

  on_change_mode_outlet = () => {
    const { outlet_multi_select_data } = this.props.data;
    this.on_show_all_outlet();
    this.on_reset_all_outlet();
    if (outlet_multi_select_data === false) {
      this.props.outlet_multi_select_data(true);
    } else if (outlet_multi_select_data === true) {
      this.props.outlet_multi_select_data(false);
    }
  };

  on_filter_toko_by_rayon_state = (rayon_select) => {
    const { outlet_multi_select_data } = this.props.data;

    this.setState({ rayon_select }, () => {
      const body = { key: ["rayon_id"], value: [rayon_select] };
      if (outlet_multi_select_data) {
        this.props.multi_select_toko_by_params(body);
      } else {
        this.props.filter_toko_by_param(body);
      }
      this.props.select_rayon(rayon_select);

      const { rayon_list_database } = this.props.data;
      this.props.set_list({
        text: "rayon_select",
        list: rayon_select,
      });
      const rayon_object = rayon_list_database.find(
        (r) => r._id === rayon_select
      );
      let cycle_list = rayon_object?.cycle_list ? rayon_object?.cycle_list : [];
      if (rayon_select && cycle_list.length > 0) {
        this.props.set_config_mode_menu("distribution", "cycle");
        this.props.turn_on_menu_status("cycle");
      } else {
        this.props.set_config_mode_menu("distribution", "rayon");
        this.props.turn_off_menu("cycle");
      }
    });
  };

  on_filter_toko_by_rayon = () => {
    const { rayon_select } = this.state;
    const body = { key: ["rayon_id"], value: [rayon_select] };
    this.props.filter_toko_by_param(body);
    this.props.select_rayon(rayon_select);
  };

  on_edit_many_toko_rayon = () => {
    const { id_toko_selected, rayon_id_polygon } = this.props.data;
    const body = {
      rayon_id: rayon_id_polygon,
      outlet_id_array: id_toko_selected,
    };

    this.props.statusDraw("");
    this.props.setDrawMode("simple_select");
    this.props.edit_many_toko_rayon(body);
  };

  on_activate_draw = (rayon_id) => {
    this.setState({ rayon_id_polygon: rayon_id });
    this.props.statusDraw("rayon_poligon");
    this.props.setDrawMode("simple_select");
    setTimeout(() => {
      this.props.setDrawMode("draw_polygon");
    }, 50);
  };

  on_check_point_inside = (rayon_id) => {
    const { rayon_polygon } = this.props.map;
    const { outlet_list_filter_0 } = this.props.data;
    const features = outlet_list_filter_0.map((c) => {
      const feature = {
        type: "Feature",
        properties: { ...c },
        geometry: c.location,
      };
      return feature;
    });
    const rayon_point = {
      type: "FeatureCollection",
      features,
    };
    let points = pointsWithinPolygon(rayon_point, rayon_polygon);
    // this.setState({ dataProperties: points });
    points = points.features.map((p) => p.properties._id);
    this.props.set_list({ text: "id_toko_selected", list: points });
    this.props.set_list({ text: "rayon_id_polygon", list: rayon_id });
  };

  on_change = (e) => {
    const { rayon_list_database, rayon_select } = this.props.data;
    const value = e.target.value;
    const name = e.target.name;

    this.setState({ [name]: value }, () => {
      if (name === "rayon_select") {
        this.props.turn_on_menu_status("rayon");
        this.props.turn_off_menu("day");
        this.on_filter_toko_by_rayon();
        this.props.set_list({
          text: name,
          list: value,
        });
        const rayon_object = rayon_list_database.find((r) => r._id === value);
        let cycle_list = rayon_object?.cycle_list
          ? rayon_object?.cycle_list
          : [];
        if (value && cycle_list.length > 0) {
          this.props.set_config_mode_menu("distribution", "cycle");
          this.props.turn_on_menu_status("cycle");
        } else {
          this.props.set_config_mode_menu("distribution", "rayon");
          this.props.turn_off_menu("cycle");
        }
      } else if (name === "cycle_select") {
        this.props.set_list({
          text: name,
          list: value,
        });
        this.on_filter_toko_by_cycle();
        const rayon_object = rayon_list_database.find(
          (r) => r._id === rayon_select
        );
        const plan_list = rayon_object?.plan_list
          ? rayon_object?.plan_list
          : [];
        // const plan_list = plan_list.filter((p) => p.cycle === value);
        if (!value) {
          this.props.turn_off_menu("day");
          this.props.set_config_mode_menu("distribution", "rayon");
          const body = {
            list: "",
            text: "salesman_select",
          };
          this.props.set_list(body);
        } else if (plan_list.length > 0) {
          this.props.turn_on_menu_status("day");
          const { salesman_list } = this.props.data;
          const salesman_id = plan_list?.[0]?.salesman_id;
          const first_salesman = salesman_list.find(
            (s) => s._id === salesman_id
          );
          const body = {
            list: first_salesman,
            text: "salesman_select",
          };
          this.props.set_list(body);
          this.props.set_config_mode_menu("distribution", "day");
        } else {
          this.props.turn_off_menu("day");
          const body = {
            list: "",
            text: "salesman_select",
          };
          this.props.set_list(body);
          this.props.set_config_mode_menu("distribution", "cycle");
        }
      } else if (name === "mode_pembagian_rayon") {
        const { list_menu_active, outlet_list_filter_0 } = this.props.data;

        let config = list_menu_active.find((e) => e.key === "salesman")?.config
          ? list_menu_active.find((e) => e.key === "salesman")?.config
          : {};

        if (config?.value) {
          config.value = config.value.map((depo_element) => {
            //[1, 2, 3, 4].reduce((a, b) => a + b, 0)
            const total_outlet = outlet_list_filter_0.filter(
              (t) => t.Depo === depo_element.value
            ).length;

            let total_value = depo_element.total_value;
            let avg_value = depo_element.avg_value;

            let jumlah_rayon_by_omset = depo_element.jumlah_rayon_by_omset;
            let avg_value_rayon = depo_element.avg_value_rayon;
            let avg_qty_rayon = depo_element.avg_qty_rayon;
            let avg_toko_rayon_omset = depo_element.avg_toko_rayon_omset;
            let avg_toko_rayon_qty = depo_element.avg_toko_rayon_qty;

            if (!total_value) {
              total_value = outlet_list_filter_0
                .filter((t) => t.Depo === depo_element.value)
                .map((t) => t.value)
                .reduce((a, b) => a + b, 0);
              avg_value = Math.round(total_value / total_outlet);
            }

            let total_qty = depo_element.total_qty;
            let avg_qty = depo_element.avg_qty;
            if (!total_qty) {
              total_qty = outlet_list_filter_0
                .filter((t) => t.Depo === depo_element.value)
                .map((t) => t.qty)
                .reduce((a, b) => a + b, 0);

              avg_qty = Math.round(total_qty / total_outlet);
            }

            if (!jumlah_rayon_by_omset) jumlah_rayon_by_omset = 10;
            if (!avg_value_rayon)
              avg_value_rayon = Math.round(total_value / jumlah_rayon_by_omset);
            if (!avg_qty_rayon)
              avg_qty_rayon = Math.round(total_qty / jumlah_rayon_by_omset);
            if (!avg_toko_rayon_omset)
              avg_toko_rayon_omset = Math.round(avg_value_rayon / avg_value);
            if (!avg_toko_rayon_qty)
              avg_toko_rayon_qty = Math.round(avg_qty_rayon / avg_qty);

            return {
              ...depo_element,
              total_value,
              total_qty,
              total_outlet,
              avg_value,
              avg_qty,
              jumlah_rayon_by_omset,
              jumlah_rayon_by_qty: jumlah_rayon_by_omset,
              avg_value_rayon,
              avg_qty_rayon,
              avg_toko_rayon_omset,
              avg_toko_rayon_qty,
            };
          });
          const body = {
            menu: "salesman",
            config,
          };

          this.props.set_config_menu(body);
        }
      }
    });
  };

  render() {
    //local storage

    //local state
    const { rayon_id_polygon } = this.state;

    //global props
    const {
      rayon_select,
      rayon_list_database,
      outlet_list_filter_0,
      outlet_list_select_0,
      outlet_multi_select_data,
      loading_status,
      loading_item,
    } = this.props.data;
    const { id_toko_selected } = this.props.data;
    const rayon_id_polygon_props = this.props.data.rayon_id_polygon;

    //content

    return (
      <main>
        <section className="main_container mb-3">
          <p className="badge mb-1" id="blue">
            Step 3.1
          </p>
          <p className="text_medium">Choose rayon</p>
          <hr />
          <p className="text_inferior">
            Choose one Rayon at a time, then week cycle & day salesman
            rayonization will run one by one sequentially.
          </p>
          <select
            name="rayon_select"
            id="rayon_select"
            value={rayon_select}
            onChange={this.on_change}
            className="text_inferior block"
          >
            <option value="">Choose rayon</option>
            {rayon_list_database.map((r, idx) => {
              return (
                <option key={idx} value={r._id}>
                  {r.name}
                </option>
              );
            })}
          </select>
        </section>

        <section className="main_container mb-3">
          <p className="badge mb-1" id="blue">
            Step 3.2
          </p>
          <p className="text_medium">Adding the store to a rayon</p>
          <hr />
          <p className="text_inferior">
            Adding the stores from one rayon to another using polygons
          </p>
          <br />
          <div className="main_container" id="white">
            {loading_status && loading_item === "edit_rayon_location" ? (
              <div className="button" id="grey">
                Loading...
              </div>
            ) : (
              <button
                className="button"
                id="green"
                onClick={this.on_edit_rayon_location_bulk}
              >
                Update rayon central point
              </button>
            )}
            <p className="text_inferior">
              Update the location if it has been added via polygon because the
              rayon distribution changes
            </p>
          </div>
          <br />
          <button
            className="button"
            id="green"
            onClick={() => this.on_change_mode_outlet()}
          >
            {outlet_multi_select_data ? "Multi Select Data" : "Filter"}
          </button>
          <br />
          <table className="table text_inferior w-full">
            <thead>
              <tr>
                <th>
                  Detail Rayon{" "}
                  <button
                    className="button_small"
                    id={outlet_multi_select_data ? "red" : "green"}
                    onClick={
                      outlet_multi_select_data
                        ? this.on_reset_all_outlet
                        : this.on_show_all_outlet
                    }
                  >
                    {outlet_multi_select_data ? "Reset" : "All"}
                  </button>
                </th>
                <th>Total Store</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {rayon_list_database.map((rayon, idx) => {
                let toko_list = outlet_multi_select_data
                  ? outlet_list_select_0.filter((e) => e.rayon_id === rayon._id)
                  : outlet_list_filter_0.filter(
                      (e) => e.rayon_id === rayon._id
                    );
                let color_index = idx;
                if (idx > st_colors.length - 1) {
                  color_index = idx % st_colors.length;
                }
                return (
                  <tr key={idx}>
                    <td
                      className="text_left text_inferior"
                      onClick={this.on_filter_toko_by_rayon_state.bind(
                        this,
                        rayon._id
                      )}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{
                          width: "10px",
                          height: "10px",
                          display: "inline-block",
                          backgroundColor: st_colors[color_index],
                        }}
                      ></span>
                      {rayon.name}
                    </td>
                    <td className="text_left text_medium">
                      {toko_list.length}
                    </td>
                    <td className="text_left text_medium">
                      <button
                        className="button"
                        id={rayon_id_polygon === rayon._id ? "green" : "grey"}
                        onClick={this.on_activate_draw.bind(this, rayon._id)}
                      >
                        Add store
                      </button>
                      <br />
                      {rayon_id_polygon === rayon._id && (
                        <button
                          className="button mt-1"
                          id="blue"
                          onClick={this.on_check_point_inside.bind(
                            this,
                            rayon._id
                          )}
                        >
                          Cek
                        </button>
                      )}
                      {rayon_id_polygon === rayon._id &&
                        rayon_id_polygon_props === rayon._id && (
                          <main>
                            <p className="text_inferior">
                              <b>{id_toko_selected?.length}</b> Store inside the
                              polygon
                            </p>
                            {id_toko_selected?.length > 0 && (
                              <button
                                className="button mt-1"
                                id="blue"
                                onClick={this.on_edit_many_toko_rayon}
                              >
                                Save
                              </button>
                            )}
                          </main>
                        )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
});

export default connect(mapStateToProps, {
  statusDraw,
  setDrawMode,
  edit_many_toko_rayon,
  turn_off_menu,
  set_config_mode_menu,
  set_config_menu,
  turn_on_menu_status,
  filter_toko_by_param,
  multi_select_toko_by_params,
  multi_reset_toko_by_params,
  outlet_multi_select_data,
  select_rayon,
  set_list,
  show_all_outlet,
  edit_rayon_location,
})(STEP_3_CHOOSE_RAYON);
