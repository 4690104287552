import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";
import { generate_filter_item } from "./filter_outlet_actions";
import filter_outlet_lokasi_kosong from "../validation/filter_outlet_lokasi_kosong";

const SERVER_URL = geoServerBaseUrl;

//get_outlet_list_by_salesman_id
export const get_outlet_list_by_salesman_id = (params) => async (dispatch) => {
  try {
    const { salesman_id } = params;

    dispatch({
      type: "set_loading_properties",
      payload: "get_outlet_list_by_salesman_id",
    });
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let res = await axios.get(
      `${SERVER_URL}/outlets/get_outlet_list_by_salesman_id?salesman_id=${salesman_id}`,
      config
    );

    let outlet_list = res?.data || [];
    outlet_list.forEach((element, idx) => {
      /*
      kunjungan_arr_new: [
        {
          date_submit: Date,
          HARIKunjungan: String,
          FreqVisit: String,
          cycle: String,
          day: String,
          salesman_id: String,
        },
      ]
      */
      const kunjungan = element.kunjungan_arr_new.find(
        (item) => item.salesman_id === salesman_id
      );
      outlet_list[idx].HARIKunjungan = kunjungan.HARIKunjungan;
      outlet_list[idx].FreqVisit = kunjungan.FreqVisit;
      outlet_list[idx].cycle = kunjungan.cycle;
      outlet_list[idx].day = kunjungan.day;
      outlet_list[idx].salesman_id = kunjungan.salesman_id;
    });

    const outlet_list_filter_0 = filter_outlet_lokasi_kosong(outlet_list);

    dispatch(generate_filter_item(outlet_list));

    dispatch({
      type: "set_value_outlet",
      payload: { key: "outlet_by_salesman_list", value: outlet_list },
    });
    dispatch({
      type: "set_value_outlet",
      payload: { key: "outlet_by_salesman_list_backup", value: outlet_list },
    });
    dispatch({
      type: "set_value_outlet",
      payload: {
        key: "outlet_by_salesman_list_filter_0",
        value: outlet_list_filter_0,
      },
    });
    dispatch({
      type: "set_value_outlet",
      payload: {
        key: "outlet_by_salesman_list_filter_0_back_up",
        value: outlet_list_filter_0,
      },
    });

    dispatch({
      type: "set_value_properties",
      payload: { key: "salesman_id_active", value: salesman_id },
    });
    dispatch({
      type: "clear_loading_properties",
    });

    dispatch({
      type: "status_action",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_properties",
    });
  }
};

//get_competitor_by_area_new
export const get_competitor_by_area_stream = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_LOADING",
      payload: "get_competitor_by_area",
    });
    const { KELURAHAN, KECAMATAN, KABUPATEN, PROPINSI, COUNTRY, segment_list } =
      params;

    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };

    //Step 1 get count
    const res = await axios.get(
      `${SERVER_URL}/nabati-outlet/get_count_competitor?KELURAHAN=${KELURAHAN}&KECAMATAN=${KECAMATAN}&KABUPATEN=${KABUPATEN}&PROPINSI=${PROPINSI}&COUNTRY=${COUNTRY}&segment_list=${segment_list}`,
      config
    );
    const count = res.data;
    const limit = 50_000;
    const delay_c = 1; //miliseconds

    //Step 2 looping dengan promise tergantung jumlah count
    if (count > limit) {
      dispatch({
        type: "set_total_toko",
        payload: count,
      });
      dispatch({
        type: "reset_progres_toko",
      });
      // const mod = count % limit;
      const iteration = Math.ceil(count / limit);
      let array = [];
      for (let i = 0; i < iteration; i++) {
        array.push({
          skip: i * limit,
          limit: limit,
        });
      }
      const delay = () => new Promise((res) => setTimeout(res, delay_c));
      const parent_function = () => {
        return array.reduce(
          (last_promise, object) =>
            last_promise.then((result_sum) =>
              child_function(object).then((result_current) => [
                ...result_sum,
                result_current,
              ])
            ),
          Promise.resolve([])
        );
      };
      const child_function = async (object) => {
        return delay().then(() => {
          const core_function = async () => {
            try {
              const { skip, limit } = object;
              const config = {
                headers: {
                  accesstoken: localStorage.jwtTokenNabati,
                },
              };
              const res = await axios.get(
                `${SERVER_URL}/nabati-outlet/get_competitor_by_area_stream?KELURAHAN=${KELURAHAN}&KECAMATAN=${KECAMATAN}&KABUPATEN=${KABUPATEN}&PROPINSI=${PROPINSI}&COUNTRY=${COUNTRY}&skip=${skip}&limit=${limit}&segment_list=${segment_list}`,
                config
              );
              let toko_list = res.data;

              dispatch({
                type: "get_outlet_list_by_area",
                payload: toko_list,
              });
              dispatch({
                type: "add_progres_toko",
                payload: res?.data?.length ? res?.data?.length : 0,
              });
            } catch (error) {}
          };
          return core_function();
        });
      };
      parent_function().then(() => {
        dispatch({
          type: "status_action",
        });

        dispatch({
          type: "CLEAR_LOADING",
        });
      });
    } else {
      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenNabati,
        },
      };
      const res = await axios.get(
        `${SERVER_URL}/nabati-outlet/get_competitor_by_area?KELURAHAN=${KELURAHAN}&KECAMATAN=${KECAMATAN}&KABUPATEN=${KABUPATEN}&PROPINSI=${PROPINSI}&COUNTRY=${COUNTRY}&segment_list=${segment_list}`,
        config
      );
      let toko_list = res.data;

      dispatch(generate_filter_item(toko_list));

      dispatch({
        type: "get_outlet_list_by_area",
        payload: toko_list,
      });
      dispatch({
        type: "status_action",
      });
      dispatch({
        type: "CLEAR_LOADING",
      });
    }
  } catch (e) {
    dispatch({
      type: "CLEAR_LOADING",
    });
  }
};

export const set_value_outlet = (body) => async (dispatch) => {
  //set_value_outlet
  /*
  body={
  key: nama key yang akan diset,
  value: value yang akan dimasukkan
  }
  */
  dispatch({
    type: "set_value_outlet",
    payload: body,
  });

  dispatch({
    type: "status_action",
  });
};
