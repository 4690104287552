import car_type from "./localData/car_type.json";

const initialState = {
  lat: 0,
  lng: 0,
  rad: 0,
  lineString: [],
  point: {},
  indexLineString: 0,
  maxLineString: 0,
  filter_divisi_1: [
    {
      label: "SALESMAN - TAKING ORDER GT",
      value: "SALESMAN - TAKING ORDER GT",
    },
    {
      label: "SALESMAN - KANVAS",
      value: "SALESMAN - KANVAS",
    },
    {
      label: "AREA SALES SUPERVISOR GT",
      value: "AREA SALES SUPERVISOR GT",
    },
  ],
  filter_divisi_2: [],
  filter_divisi_3: [],
  filter_divisi_1_choose: [],
  filter_divisi_2_choose: [],
  filter_divisi_3_choose: [],
  filter_divisi_child: [],
  time_array: [],

  // driver
  driver_total_count: 1958,
  driver_daerah_kerja: [],
  driver_daerah_kerja_value: { label: "Select", value: "Pilih" },
  driver_daerah_kerja_2: [],
  driver_daerah_kerja_2_value: [],
  driver_list: [],
  backup_driver_list: [],
  driver_list_with_location: [],
  currentDriver: {},
  // driver geojson
  driver_geojsonPoint: {},
  driver_geojsonMultiPoint: {},
  driver_geojsonLineString: {},
  // driver type car
  driver_type_car: car_type,

  tracking_menu_selected: "salesman",

  modalDetailDriver: false,
  modalAddDriver: false,

  upload_salesman_success: [],
  upload_salesman_error: [],
  upload_salesman_current: 0,
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case "push_rayon_id": {
      return {
        ...state,
        driver_list: push_rayon_id(state.driver_list, action.payload),
      };
    }
    case "set_rayon_id": {
      return {
        ...state,
        driver_list: set_rayon_id(state.driver_list, action.payload),
      };
    }
    case "set_rayon_id_list": {
      return {
        ...state,
        driver_list: set_rayon_id_list(state.driver_list, action.payload),
      };
    }
    case "pull_rayon_id": {
      return {
        ...state,
        driver_list: pull_rayon_id(state.driver_list, action.payload),
      };
    }
    case "SAVE_LAT_LNG":
      return {
        ...state,
        lat: action.payload.lat,
        lng: action.payload.lng,
        rad: action.payload.rad,
      };
    case "SAVE_GET_LOG":
      return {
        ...state,
        lineString: action.payload,
        point: action.payload[action.payload.length - 1],
        indexLineString: action.payload.length - 1,
        maxLineString: action.payload.length - 1,
      };
    case "PREV_LOG":
      return {
        ...state,
        indexLineString: state.indexLineString - 1,
        point: state.lineString[state.indexLineString],
      };
    case "NEXT_LOG":
      return {
        ...state,
        indexLineString: state.indexLineString + 1,
        point: state.lineString[state.indexLineString],
      };
    case "SLIDER_LOG":
      return {
        ...state,
        indexLineString: parseInt(action.payload),
        point: state.lineString[action.payload],
      };
    case "CLEAR_LOG": {
      return {
        ...initialState,
      };
    }
    case "SET_FILTER_DIVISI": {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }
    case "SET_TIME_ARRAY": {
      return { ...state, time_array: action.payload };
    }
    case "SET_DRIVER_DAERAH_KERJA": {
      return { ...state, driver_daerah_kerja: action.payload };
    }
    case "SET_DRIVER_DAERAH_KERJA_VALUE": {
      return { ...state, driver_daerah_kerja_value: action.payload };
    }
    case "SET_DRIVER_DAERAH_KERJA_2": {
      return { ...state, driver_daerah_kerja_2: action.payload };
    }
    case "SET_DRIVER_DAERAH_KERJA_2_VALUE": {
      return { ...state, driver_daerah_kerja_2_value: action.payload };
    }
    case "SET_DRIVER_LIST": {
      return {
        ...state,
        driver_list: action.payload,
        backup_driver_list: action.payload,
      };
    }
    case "SET_DRIVER_LIST_WITH_LOCATION": {
      return {
        ...state,
        driver_list_with_location: filter_location_0_salesman(action.payload),
      };
    }
    case "SET_MODAL_CURRENT_DRIVER": {
      return {
        ...state,
        modalDetailDriver: action.payload,
      };
    }
    case "SET_CURRENT_DRIVER": {
      return {
        ...state,
        currentDriver: action.payload,
      };
    }
    case "UPDATE_DRIVER_LIST": {
      return {
        ...state,
        driver_list: update_list(action.payload, state.driver_list),
      };
    }
    case "SEARCH_DRIVER": {
      return {
        ...state,
        driver_list: search_driver(
          action.payload,
          state.driver_list,
          state.backup_driver_list
        ),
      };
    }
    case "SET_MODAL_ADD_DRIVER": {
      return {
        ...state,
        modalAddDriver: action.payload,
      };
    }
    case "SET_DRIVER_GEOJSON_POINT": {
      return {
        ...state,
        driver_geojsonPoint: action.payload,
      };
    }
    case "SET_DRIVER_GEOJSON_MULTI_POINT": {
      return {
        ...state,
        driver_geojsonMultiPoint: action.payload,
      };
    }
    case "SET_DRIVER_GEOJSON_LINE_STRING": {
      return {
        ...state,
        driver_geojsonLineString: action.payload,
      };
    }
    case "CLEAR_LIST_DRIVER": {
      return {
        ...state,
        driver_list: [],
      };
    }
    case "SET_TRACKING_MENU_SELECTED": {
      return {
        ...state,
        tracking_menu_selected: action.payload,
      };
    }
    case "CLEAR_DRIVER_WITH_LOCATION": {
      return {
        ...state,
        driver_list_with_location: [],
      };
    }
    case "UNDO_DRIVER_WITH_LOCATION": {
      return {
        ...state,
        driver_list_with_location: filter_location_0_salesman(
          state.driver_list
        ),
      };
    }
    case "UPLOAD_SALESMAN_SUCCESS": {
      return {
        ...state,
        upload_salesman_success: state.upload_salesman_success.concat(
          action.payload
        ),
      };
    }
    case "UPLOAD_SALESMAN_ERROR": {
      return {
        ...state,
        upload_salesman_error: state.upload_salesman_error.concat(
          action.payload
        ),
      };
    }
    case "UPLOAD_SALESMAN_CURRENT": {
      return {
        ...state,
        upload_salesman_current: state.upload_salesman_current + action.payload,
      };
    }
    case "UPLOAD_SALESMAN_CLEAR": {
      return {
        ...state,
        upload_salesman_current: 0,
        upload_salesman_error: [],
        upload_salesman_success: [],
      };
    }
    case "SEARCH_DRIVER_RAYON_LIST": {
      return {
        ...state,
        driver_list: driverSearch(
          action.payload,
          state.driver_list,
          state.backup_driver_list
        ),
      };
    }
    default:
      return state;
  }
}

const driverSearch = (query, driver, backup_driver_list) => {
  let result;
  if (query !== "") {
    result = driver.filter((state) => {
      const regex = new RegExp(`${query}`, "gi");
      return state["DRIVER"].match(regex);
    });
  } else {
    result = backup_driver_list;
  }
  return result;
};

const filter_location_0_salesman = (list) => {
  let salesman_list_filter_0 = list.slice();
  salesman_list_filter_0 = list.filter(
    (e) =>
      e?.location?.coordinates?.[0] !== 0 &&
      e?.location?.coordinates?.[1] >= -90 &&
      e?.location?.coordinates?.[1] <= 90
  );
  return salesman_list_filter_0;
};

const update_list = (new_data, list_data) => {
  const idx = list_data.findIndex((e) => e._id === new_data._id);
  list_data[idx] = new_data;
  return list_data;
};

const search_driver = (query, list_data, backup) => {
  let result;
  if (query !== "") {
    result = list_data.filter((state) => {
      const regex = new RegExp(`${query}`, "gi");
      return state["NOPOL"].match(regex) || state["DRIVER"].match(regex);
    });
  } else {
    result = backup;
  }
  return result;
};

//push_rayon_id
const push_rayon_id = (list, body) => {
  const { driver_id, rayon_id } = body;
  let new_list = list.slice();
  const index = new_list.findIndex((e) => e._id === driver_id);
  let rayon_id_list = new_list?.[index]?.rayon_id_list
    ? new_list?.[index]?.rayon_id_list
    : [];
  rayon_id_list.push(rayon_id);
  new_list[index].rayon_id_list = rayon_id_list;
  return new_list;
};

//set_rayon_id
const set_rayon_id = (list, body) => {
  const { driver_id, rayon_id, rayon_mode } = body;
  let new_list = list.slice();
  const index = new_list.findIndex((e) => e._id === driver_id);
  new_list[index].rayon_id_list = [rayon_id];
  new_list[index].rayon_mode = rayon_mode;
  return new_list;
};

//set_rayon_id_list
const set_rayon_id_list = (list, body) => {
  const { driver_id, rayon_id_list, rayon_mode } = body;
  let new_list = list.slice();
  const index = new_list.findIndex((e) => e._id === driver_id);
  new_list[index].rayon_id_list = rayon_id_list;
  new_list[index].rayon_mode = rayon_mode;
  return new_list;
};

//pull_rayon_id
const pull_rayon_id = (list, body) => {
  const { driver_id, rayon_id } = body;
  let new_list = list.slice();
  const index = new_list.findIndex((e) => e._id === driver_id);
  let rayon_id_list = new_list?.[index]?.rayon_id_list
    ? new_list?.[index]?.rayon_id_list
    : [];
  rayon_id_list = rayon_id_list.filter((_id) => _id !== rayon_id);
  new_list[index].rayon_id_list = rayon_id_list;
  return new_list;
};
