//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
import TextFieldGroup from "../common/TextFieldGroup";
import Spinner from "../parent/Spinner";
//Redux function
import { verify_reset_code, reset_password } from "../../actions/authActions";
//Picture Asset
import logo from "../../img/logo.png";
//General Function
class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      password2: "",
      errors: {},
      show_password: false,
    };
  }
  componentDidMount() {
    const str = this?.props?.location?.search;
    const params = Object.fromEntries(new URLSearchParams(str));
    if (params.username && params.reset_code) {
      this.props.verify_reset_code(params);
    }
  }
  toggleShowPassword = () => {
    this.setState({ show_password: !this.state.show_password });
  };
  onSubmit = (e) => {
    e.preventDefault();
    const { password } = this.state;
    const str = this?.props?.location?.search;
    const params = Object.fromEntries(new URLSearchParams(str));
    this.props.reset_password({
      password,
      ...params,
    });
  };
  onChange = (e) => {
    const target = e.target;
    let value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };
  render() {
    const str = this?.props?.location?.search;
    const params = Object.fromEntries(new URLSearchParams(str));
    const { password, password2, show_password } = this.state;
    const { loading, item_loading, user_reset } = this.props.auth;
    const { errors } = this.props;
    let error_content = null;
    if (errors.reset_password) {
      error_content = (
        <main className="main_container mt-5" id="red_bright">
          {errors.reset_password}
        </main>
      );
    }
    let icon_content;
    if (loading & (item_loading === "reset_password")) {
      icon_content = (
        <div className="button" id="grey">
          Sending data...
        </div>
      );
    } else if (!password) {
      icon_content = (
        <div className="button" id="red">
          Please fill in the new password
        </div>
      );
    } else if (password.length <= 5) {
      icon_content = (
        <div className="button" id="red">
          Password minimum 5 characters
        </div>
      );
    } else if (!password2) {
      icon_content = (
        <div className="button" id="red">
          Pleasee retype the new password
        </div>
      );
    } else if (password !== password2) {
      icon_content = (
        <div className="button" id="red">
          Confirmed password is not the same
        </div>
      );
    } else {
      icon_content = (
        <button className="button" id="green" type="submit">
          Submit a new password
        </button>
      );
    }
    let content;
    if (loading && item_loading === "verify_reset_code") {
      content = (
        <main
          className="flex items-center justify-center min-h-screen bg-gray-50"
          style={{ textAlign: "center" }}
        >
          <main className="shadow w-full mx-4 md:w-1/2 lg:w-1/3 p-4 md:p-12 rounded-md bg-white">
            <img alt="Nabati Logo" src={logo} className="mx-auto mb-8" />
            <Spinner />
            <section>Checking the reset code</section>
          </main>
        </main>
      );
    } else if (!params.reset_code || !params.username) {
      content = (
        <main className="flex items-center justify-center min-h-screen bg-gray-50">
          <main className="shadow w-full mx-4 md:w-1/2 lg:w-1/3 p-4 md:p-12 rounded-md bg-white">
            <img alt="Nabati Logo" src={logo} className="mx-auto mb-8" />
            <section className="main_container">
              The reset code and username are not in the link
            </section>
          </main>
        </main>
      );
    } else if (user_reset === "") {
      content = (
        <main className="flex items-center justify-center min-h-screen bg-gray-50">
          <main className="shadow w-full mx-4 md:w-1/2 lg:w-1/3 p-4 md:p-12 rounded-md bg-white">
            <img alt="Nabati Logo" src={logo} className="mx-auto mb-8" />
            <section className="main_container">
              The reset code <b>{params.reset_code}</b> is no longer valid for
              username <b>{params.username}</b>
            </section>
          </main>
        </main>
      );
    } else {
      content = (
        <main className="flex items-center justify-center min-h-screen bg-gray-50">
          <main className="shadow w-full mx-4 md:w-1/2 lg:w-1/3 p-4 md:p-12 rounded-md bg-white">
            <img alt="Nabati Logo" src={logo} className="mx-auto mb-8" />
            <section className="main_container">
              <h1 className="font-semibold text-xl mb-0">RESET PASSWORD</h1>
              <p className="text-gray-700">Enter a new password</p>
              <p className="text-gray-700">
                For username: <b>{user_reset.name}</b>
              </p>
            </section>
            <form
              noValidate
              className="mt-4"
              autoComplete="off"
              onSubmit={this.onSubmit}
            >
              <TextFieldGroup
                type={show_password ? "text" : "password"}
                isPassword={true}
                placeholder="New Password"
                name="password"
                id="password"
                value={password}
                onChange={this.onChange}
                error={errors.password}
                toggleShowPassword={this.toggleShowPassword}
              />
              <TextFieldGroup
                type={show_password ? "text" : "password"}
                isPassword={true}
                placeholder="Retype a new password"
                name="password2"
                id="password2"
                value={password2}
                onChange={this.onChange}
                error={errors.password2}
                toggleShowPassword={this.toggleShowPassword}
              />
              {icon_content}
            </form>
            {error_content}
          </main>
        </main>
      );
    }
    return content;
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.error,
  data: state.data,
});
export default connect(mapStateToProps, {
  verify_reset_code,
  reset_password,
})(ResetPassword);
