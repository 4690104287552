import axios from "axios";
import his from "./history";
import { geoServerBaseUrl, nabatiServer2 } from "./baseUrl";
const SERVER_URL = geoServerBaseUrl;
const SERVER_URL_2 = nabatiServer2;

/*POSTY*/

//1. REGISTER ADMIN NABATI
export const register_admin_nabati = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    dispatch(set_loading("register_admin_nabati"));
    const res = await axios.post(
      SERVER_URL + "/nabati_users/register_admin_nabati",
      body,
      config
    );
    localStorage.setItem("jwtTokenNabati", res.data.token);
    localStorage.setItem("nabatiusername", res.data.user.name);
    localStorage.setItem("nabatiUser", JSON.stringify(res.data.user));
    dispatch(setCurrentUser(res.data.user));
    dispatch(setCurrentToken(res.data.token));
    dispatch(clear_loading());
    dispatch(clearErrors());
    his.push("/");
  } catch (e) {
    dispatch({
      type: "GET_ERRORS",
      payload: e?.response?.data ? e.response.data : {},
    });
    dispatch(clear_loading());
  }
};

//2. LOGIN ADMIN NABATI
export const loginUser = (userData) => async (dispatch) => {
  try {
    console.log("body=", userData);
    dispatch({
      type: "VERIFY_LINK",
      payload: "pending",
    });
    dispatch(set_loading("sign_in"));
    dispatch({
      type: "GET_ERRORS",
      payload: {},
    });
    const res = await axios.post(
      SERVER_URL_2 + "/nabati_users/login_nabati",
      userData
    );
    localStorage.setItem("jwtTokenNabati", res.data.token);
    localStorage.setItem("is_new", true);
    localStorage.setItem("nabatiusername", res.data.user.name);
    localStorage.setItem("nabatiUser", JSON.stringify(res.data.user));
    dispatch(setCurrentUser(res.data.user));
    dispatch(setCurrentToken(res.data.token));
    dispatch(clear_loading());
    dispatch(clearErrors());
    his.push("/");
  } catch (error) {
    dispatch({
      type: "GET_ERRORS",
      payload: error && error.response ? error.response.data : {},
    });
    dispatch(clear_loading());
  }
};

//3. EDIT STATUS, DEPO & BADGE
export const edit_user = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    dispatch(set_loading("edit_user"));

    await axios.post(SERVER_URL + "/nabati_users/edit_user", body, config);
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//4. GENERATE KODE RAHASIA RESET PASSWORD
export const generate_secret_reset_password = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    dispatch(set_loading("edit_user"));
    const res = await axios.post(
      SERVER_URL + "/nabati_users/generate_secret_reset_password",
      body,
      config
    );
    dispatch({
      type: "generate_secret_reset_password",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//5. RESET PASSWORD
export const reset_password = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("reset_password"));
    setTimeout(async () => {
      try {
        const res = await axios.post(
          SERVER_URL + "/nabati_users/reset_password",
          body
        );
        localStorage.setItem("jwtTokenNabati", res.data.token);
        localStorage.setItem("nabatiusername", res.data.user.name);
        localStorage.setItem("nabatiUser", JSON.stringify(res.data.user));
        dispatch(setCurrentUser(res.data.user));
        dispatch(setCurrentToken(res.data.token));
        dispatch(clear_loading());
        dispatch(clearErrors());
        his.push("/");
        dispatch(clear_loading());
      } catch (error) {
        dispatch(clear_loading());
        dispatch({
          type: "GET_ERRORS",
          payload: error?.response?.data ? error.response.data : {},
        });
      }
    }, "2000");
  } catch (error) {
    dispatch(clear_loading());
    dispatch({
      type: "GET_ERRORS",
      payload: error?.response?.data ? error.response.data : {},
    });
  }
};

//6. POST TAMBAH REGION MANUAL
export const manual_add_regional = (body) => async (dispach) => {
  try {
    dispach(set_loading_top());
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const result = await axios.post(
      SERVER_URL + "/nabati_users/manual_add_regional",
      body,
      config
    );
    dispach({
      type: "MANUAL_ADD_REGIONAL",
      payload: result.data,
    });
    dispach(clear_loading_top());
  } catch (error) {
    // console.log(error);
    dispach(clear_loading_top());
  }
};

//6. POST TAMBAH CABANG MANUAL
export const manual_add_cabang = (body) => async (dispach) => {
  try {
    dispach(set_loading_top());
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const result = await axios.post(
      SERVER_URL + "/nabati_users/manual_add_cabang",
      body,
      config
    );
    dispach({
      type: "MANUAL_ADD_CABANG",
      payload: result.data,
    });
    dispach(clear_loading_top());
  } catch (error) {
    // console.log(error);
    dispach(clear_loading_top());
  }
};

//7. POST TAMBAH DEPO MANUAL
export const manual_add_depo = (body) => async (dispach) => {
  try {
    dispach(set_loading_top());
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(
      SERVER_URL + "/nabati_users/manual_add_depo",
      body,
      config
    );
    // dispach({
    //   type: "manual_add_depo",
    //   payload: body,
    // });
    dispach(clear_loading_top());
  } catch (error) {
    // console.log(error);
    dispach(clear_loading_top());
  }
};

export const edit_depo_local = (body) => async (dispach) => {
  try {
    dispach({
      type: "edit_depo_local",
      payload: body,
    });
  } catch (error) {}
};

//8. POST TAMBAH REGION BULK
export const bulk_add_regional = (body) => async (dispach) => {
  try {
    dispach(set_loading_top());
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(
      SERVER_URL + "/nabati_users/bulk_add_regional",
      body,
      config
    );
    dispach(clear_loading_top());
  } catch (error) {
    // console.log(error);
    dispach(clear_loading_top());
  }
};

//9. POST TAMBAH CABANG BULK
export const bulk_add_cabang = (body) => async (dispach) => {
  try {
    dispach(set_loading_top());
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(
      SERVER_URL + "/nabati_users/bulk_add_cabang",
      body,
      config
    );
    dispach(clear_loading_top());
  } catch (error) {
    // console.log(error);
    dispach(clear_loading_top());
  }
};

//10. POST TAMBAH/EDIT DEPO BULK
export const bulk_add_edit_depo = (body) => async (dispach) => {
  try {
    dispach(set_loading_top());
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(
      SERVER_URL + "/nabati_users/bulk_add_edit_depo",
      body,
      config
    );
    dispach(clear_loading_top());
  } catch (error) {
    // console.log(error);
    dispach(clear_loading_top());
  }
};

//11. POST MANUAL EDIT DEPO
export const manual_edit_depo = (body) => async (dispach) => {
  try {
    dispach(set_loading_top());
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.post(
      SERVER_URL + "/nabati_users/manual_edit_depo",
      body,
      config
    );
    dispach(clear_loading_top());
  } catch (error) {
    // console.log(error);
    dispach(clear_loading_top());
  }
};

/*GETY*/

//1. GET ALL USER ADMIN
export const get_user_list = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage?.jwtTokenNabati,
      },
    };
    dispatch(set_loading("get_user_list"));
    const res = await axios.get(
      SERVER_URL + `/nabati_users/get_user_list`,
      config
    );
    dispatch({
      type: "get_user_list",
      payload: res.data.reverse(),
    });
    dispatch(clear_loading());
  } catch (error) {
    dispatch(clear_loading());
  }
};

//2. GET USER FROM TOKEN
export const get_user_from_token = (token) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: token,
      },
    };
    dispatch(set_loading("get_user_from_token"));
    const res = await axios.get(
      SERVER_URL + `/nabati_users/get_user_from_token`,
      config
    );
    localStorage.setItem("nabatiusername", res.data.name);
    localStorage.setItem("nabatiUser", JSON.stringify(res.data));
    dispatch(setCurrentUser(res.data));
    dispatch(setCurrentToken(token));
    dispatch(clear_loading());
  } catch (error) {
    dispatch(clear_loading());
  }
};

//3. VERIFY RESET CODE
export const verify_reset_code = (params) => async (dispatch) => {
  try {
    dispatch(set_loading("verify_reset_code"));
    const res = await axios.get(
      SERVER_URL +
        `/nabati_users/verify_reset_code?username=${params?.username}&reset_code=${params?.reset_code}`
    );
    dispatch({
      type: "verify_reset_code",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (error) {
    dispatch(clear_loading());
  }
};

/* DELETY */
export const delete_depo = (id) => async (dispatch) => {
  try {
    dispatch(set_loading_top());
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    await axios.delete(
      SERVER_URL + "/nabati_users/delete_depo?id=" + id,
      config
    );
    dispatch(clear_loading_top());
  } catch (error) {
    dispatch(clear_loading_top());
  }
};

/*NON API*/
export const setModeList = (mode_list) => {
  localStorage.setItem("mode_list", mode_list);
  return {
    type: "SET_MODE_LIST",
    payload: mode_list,
  };
};

export const set_loading = (item_loading) => {
  return {
    type: "AUTH_LOADING",
    payload: item_loading,
  };
};

export const clear_loading = () => {
  return {
    type: "CLEAR_AUTH_LOADING",
  };
};
//Set logged in user
export const setCurrentUser = (user) => {
  return {
    type: "SET_CURRENT_USER",
    payload: user,
  };
};

// Set logged in user
export const setCurrentToken = (decoded) => {
  return {
    type: "SET_CURRENT_TOKEN",
    payload: decoded,
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: "CLEAR_ERRORS",
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtTokenNabati");
  // Remove auth header for future requests
  // Set current user to {} which will set isAuthenticated to false

  dispatch(setCurrentUser({}));
};

// export const searchUserList = (query) => (dispach) => {
//   dispach({
//     type: "backup_get_user_list",
//     payload: query,
//   });
// };
// export const valueUserList = (query) => (dispach) => {
//   // console.log(query);
//   dispach({
//     type: "backup_value_get_user_list",
//     payload: query,
//   });
// };

export const submitUserList = (query) => (dispach) => {
  // console.log(query);
  dispach({
    type: "backup_submit_get_user_list",
    payload: query,
  });
};

export const set_loading_top = () => {
  return {
    type: "SET_LOADING_TOP",
  };
};

export const clear_loading_top = () => {
  return {
    type: "CLEAR_LOADING_TOP",
  };
};

export const set_country = (payload) => {
  return {
    type: "SET_COUNTRY",
    payload,
  };
};
