/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Title,
  SubTitle,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-moment";

/*PERSONAL COMPONENT*/
import Spinner from "../parent/Spinner";

/*REDUX FUNCTION*/
import {
  get_omset_by_toko_by_month_product,
  get_history_omset_by_toko_aggregate_month,
  get_history_omset_by_toko_aggregate_month_new,
  reset_omset,
} from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL*/
import ft_colors from "../../validation/ft_colors.json";
import sort_array from "../../validation/sort_array";

/*NON IMPORT*/
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Title,
  SubTitle
);

class OMSET_OUTLET_POPUP extends Component {
  state = {
    modal_history: false,
    modal_detail_satu_bulan: false,
  };

  componentDidMount() {
    // const { MONTH_NUMBER, YEAR_NUMBER, outlet_id, Plan } =
    //   this.props.properties;
    // const CUSTOMER_ID = this.props.properties["ID Outlet"];
    const { MONTH_NUMBER, YEAR_NUMBER, Plan } = this.props.data.dataPopupToko;
    const CUSTOMER_ID = this.props.data.dataPopupToko["ID Outlet"];
    const outlet_id = CUSTOMER_ID;

    const params_product_bu = {
      outlet_id,
      MONTH_NUMBER,
      YEAR_NUMBER,
      mode: "bu",
      CUSTOMER_ID,
      AREA: Plan,
    };
    this.props.get_omset_by_toko_by_month_product(params_product_bu);

    const params_product_brand = {
      outlet_id,
      MONTH_NUMBER,
      YEAR_NUMBER,
      mode: "brand",
      CUSTOMER_ID,
      AREA: Plan,
    };
    this.props.get_omset_by_toko_by_month_product(params_product_brand);

    const params_product_sku = {
      outlet_id,
      MONTH_NUMBER,
      YEAR_NUMBER,
      mode: "sku",
      CUSTOMER_ID,
      AREA: Plan,
    };
    this.props.get_omset_by_toko_by_month_product(params_product_sku);

    // get history omset new
    const params_history = {
      CUSTOMER_ID,
      AREA: Plan,
    };
    this.props.get_history_omset_by_toko_aggregate_month_new(params_history);
  }

  componentWillUnmount() {
    this.props.reset_omset();
  }

  render() {
    //localstorage

    //local state

    //global props
    const {
      loading_item,
      loading_status,
      omset_history,
      omset_product_bu,
      omset_product_brand,
      omset_product_sku,
    } = this.props.data;
    const pop_up_properties = this?.props?.properties?.pop_up_properties || {};

    //content
    let history_content,
      omset_product_bu_content,
      omset_product_brand_content,
      omset_product_sku_content;
    if (
      loading_status &&
      loading_item === "get_history_omset_by_toko_aggregate_month"
    ) {
      history_content = <Spinner />;
    } else {
      const options = {
        animation: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        responsive: true,
        scales: {
          x: {
            type: "time",
            time: {
              unit: "month",
              displayFormats: {
                month: "MMM YYYY",
              },
            },
          },
          y: {
            display: true,
            scaleLabel: {
              display: true,
              labelString: "User sum",
            },
            ticks: {
              beginAtZero: true,
              source: "auto",
            },
          },
        },
      };

      let data_history = omset_history.map((e) => {
        return {
          x: new Date(
            `${e.period.slice(0, 4)}-${e.period.slice(4, 7)}-01`
          ).getTime(),
          y: parseFloat(e.value),
        };
      });
      const data = {
        datasets: [
          {
            data: data_history,
            label: "History_omset",
            backgroundColor: "#0ca5ebff",
            hoverBackgroundColor: "#8fcc1bff",
          },
        ],
      };
      history_content = (
        <main className="mt-5">
          <section className="text_bold text_center mb-1">
            Revenue History (Rp)
          </section>
          <section style={{ width: "300px", margin: "auto" }}>
            <Chart type="bar" data={data} options={options} />
          </section>
        </main>
      );
    }

    //bu
    if (
      loading_status &&
      loading_item === "get_omset_by_toko_by_month_product_bu"
    ) {
      omset_product_bu_content = <Spinner />;
    } else if (omset_product_bu.length > 0) {
      let all_array = omset_product_bu.map((e) => {
        return {
          VALUE: e.VALUE,
          QTY: e.QTY,
          BU: e._id.BU,
        };
      });
      all_array = sort_array(all_array, "VALUE", false);
      all_array = all_array.map((e, idx) => {
        let index = idx;
        if (idx > 14) {
          index = idx % 15;
        }
        return {
          ...e,
          COLOR: ft_colors[index],
        };
      });
      const value_array = all_array.map((e) => e.VALUE);
      const name_array = all_array.map((e) => e.BU);
      const color_array = all_array.map((e) => e.COLOR);
      const chart_data = {
        labels: name_array,
        datasets: [
          {
            label: "BU",
            data: value_array,
            backgroundColor: color_array,
            hoverOffset: 4,
          },
        ],
      };
      const options = {
        animation: false,
        maintainAspectRatio: true,
        responsive: true,
        title: {
          display: false,
          text: "BU",
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      };

      omset_product_bu_content = (
        <main className="mb-5">
          <section className="text_bold text_center mb-1">Brand Units</section>
          <section style={{ width: "200px", margin: "auto" }}>
            <Chart type="pie" options={options} data={chart_data} />
          </section>
          <table style={{ fontFamily: `"Inter", sans-serif` }} className="mt-5">
            <thead>
              <tr>
                <th></th>
                <th>Brand Units</th>
                <th>Revenue (Rp)</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {all_array.map((c, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: c.COLOR,
                          display: "inline-block",
                          marginRight: "5px",
                        }}
                      />
                    </td>
                    <td>{c.BU}</td>
                    <td className="text_right">
                      <b>
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(c.VALUE)}
                      </b>
                    </td>
                    <td>
                      <b>
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(parseFloat(c.QTY).toFixed(2))}
                      </b>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      );
    }

    //brand
    if (
      loading_status &&
      loading_item === "get_omset_by_toko_by_month_product_brand"
    ) {
      omset_product_brand_content = <Spinner />;
    } else if (omset_product_brand.length > 0) {
      let all_array = omset_product_brand.map((e) => {
        return {
          VALUE: e.VALUE,
          QTY: e.QTY,
          BRAND: e._id.BRAND,
        };
      });
      all_array = sort_array(all_array, "VALUE", false);
      all_array = all_array.map((e, idx) => {
        let index = idx;
        if (idx > 14) {
          index = idx % 15;
        }
        return {
          ...e,
          COLOR: ft_colors[index],
        };
      });
      const value_array = all_array.map((e) => e.VALUE);
      const name_array = all_array.map((e) => e.BRAND);
      const color_array = all_array.map((e) => e.COLOR);
      const chart_data = {
        labels: name_array,
        datasets: [
          {
            label: "BRAND",
            data: value_array,
            backgroundColor: color_array,
            hoverOffset: 4,
          },
        ],
      };
      const options = {
        animation: false,
        maintainAspectRatio: true,
        responsive: true,
        title: {
          display: false,
          text: "BRAND",
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      };
      omset_product_brand_content = (
        <main className="mb-5">
          <section className="text_bold text_center mb-1">Brand</section>
          <section style={{ width: "200px", margin: "auto" }}>
            <Chart type="pie" options={options} data={chart_data} />
          </section>
          <table style={{ fontFamily: `"Inter", sans-serif` }} className="mt-5">
            <thead>
              <tr>
                <th></th>
                <th>Brand</th>
                <th>Revenue (Rp)</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {all_array.map((c, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: c.COLOR,
                          display: "inline-block",
                          marginRight: "5px",
                        }}
                      />
                    </td>
                    <td>{c.BRAND}</td>
                    <td className="text_right">
                      <b>
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(c.VALUE)}
                      </b>
                    </td>
                    <td>
                      <b>
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(parseFloat(c.QTY).toFixed(2))}
                      </b>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      );
    }

    //sku
    if (
      loading_status &&
      loading_item === "get_omset_by_toko_by_month_product_sku"
    ) {
      omset_product_sku_content = <Spinner />;
    } else if (omset_product_sku.length > 0) {
      let all_array = omset_product_sku.map((e) => {
        return {
          VALUE: e.VALUE,
          QTY: e.QTY,
          "KODE BARANG": e._id["KODE BARANG"],
          DESCRIPTION: e._id["DESCRIPTION"],
        };
      });
      all_array = sort_array(all_array, "VALUE", false);
      all_array = all_array.map((e, idx) => {
        let index = idx;
        if (idx > 14) {
          index = idx % 15;
        }
        return {
          ...e,
          COLOR: ft_colors[index],
        };
      });
      const value_array = all_array.map((e) => e.VALUE);
      const name_array = all_array.map((e) => e["KODE BARANG"]);
      const color_array = all_array.map((e) => e.COLOR);
      const chart_data = {
        labels: name_array,
        datasets: [
          {
            label: "KODE BARANG",
            data: value_array,
            backgroundColor: color_array,
            hoverOffset: 4,
          },
        ],
      };
      const options = {
        animation: false,
        maintainAspectRatio: true,
        responsive: true,
        title: {
          display: false,
          text: "KODE BARANG",
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      };
      omset_product_sku_content = (
        <main className="mb-5">
          <section className="text_bold text_center mb-1">Item Code</section>
          <section style={{ width: "200px", margin: "auto" }}>
            <Chart type="pie" options={options} data={chart_data} />
          </section>
          <table style={{ fontFamily: `"Inter", sans-serif` }} className="mt-5">
            <thead>
              <tr>
                <th></th>
                <th>Item Code</th>
                <th>Revenue (Rp)</th>
                <th>Quantity </th>
              </tr>
            </thead>
            <tbody>
              {all_array.map((c, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: c.COLOR,
                          display: "inline-block",
                          marginRight: "5px",
                        }}
                      />
                    </td>
                    <td style={{ width: "150px" }}>
                      {c["KODE BARANG"]}
                      <div>{c["DESCRIPTION"]}</div>
                    </td>
                    <td className="text_right">
                      <b>
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(c.VALUE)}
                      </b>
                    </td>
                    <td>
                      <b>
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(parseFloat(c.QTY).toFixed(2))}
                      </b>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      );
    }

    return (
      <main style={{ fontFamily: `"Inter", sans-serif` }}>
        <section className="button" id="yellow">
          Omset
        </section>

        <section className="text_inferior">OUTLET NAME</section>
        <section className="text_bold mb-2.5">
          {pop_up_properties?.["Nama Toko"] || "-"}
        </section>

        <section className="text_inferior">Revenue</section>
        <section className="text_inferior">
          {pop_up_properties["VALUE"] || 0}
        </section>

        <section className="text_bold mb-2.5">
          Rp{" "}
          {new Intl.NumberFormat("id-ID", {
            style: "decimal",
          }).format(pop_up_properties["VALUE"] ?? 0)}
        </section>

        {omset_product_bu_content}
        {omset_product_brand_content}
        {omset_product_sku_content}
        {history_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  get_omset_by_toko_by_month_product,
  get_history_omset_by_toko_aggregate_month,
  get_history_omset_by_toko_aggregate_month_new,
  reset_omset,
})(OMSET_OUTLET_POPUP);
