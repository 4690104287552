import React, { Component } from "react";
import Papa from "papaparse";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import readXlsxFile from "read-excel-file";
import writeXlsxFile from "write-excel-file";
import {
  bulk_add_cabang,
  bulk_add_edit_depo,
  bulk_add_regional,
} from "../../actions/authActions";
import { getSalesmanFilter } from "../../actions/dataActions";
const template_csv = [
  ["PLAN_ID", "DEPO", "CABANG", "REGIONAL", "LOCATION", "COUNTRY"],
  [
    "EXAMPLE PLAN ID",
    "EXAMPLE NAME DEPO",
    "EXAMPLE NAME CABANG",
    "EXAMPLE NAME REGIONAL",
    "-6.9965714309083245, 107.5297339384666",
    "INDONESIA",
  ],
  [
    "EXAMPLE PLAN ID",
    "EXAMPLE NAME DEPO",
    "EXAMPLE NAME CABANG",
    "EXAMPLE NAME REGIONAL",
    "-6.9965714309083245, 107.5297339384666",
    "VIETNAM",
  ],
  [
    "EXAMPLE PLAN ID",
    "EXAMPLE NAME DEPO",
    "EXAMPLE NAME CABANG",
    "EXAMPLE NAME REGIONAL",
    "-6.9965714309083245, 107.5297339384666",
    "PHILIPPINE",
  ],
  [
    "EXAMPLE PLAN ID",
    "EXAMPLE NAME DEPO",
    "EXAMPLE NAME CABANG",
    "EXAMPLE NAME REGIONAL",
    "-6.9965714309083245, 107.5297339384666",
    "MYANMAR",
  ],
];

class DepoBulk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      file_header: [],
      file_body: [],
      cabangs_not_found: [],
      regionals_not_found: [],
      csv_row_error: [],
      mode_download: "",
      loadingExcel: false,
    };
    this.handleFile = this.handleFile.bind(this);
  }
  handleFile = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  };
  processFile = async () => {
    try {
      const { filterStruktur, original_cabang, original_regional } = this.props;
      const list_depo = filterStruktur?.depo
        ? filterStruktur.depo.map((e) => e.Depo)
        : [];
      const list_cabang = original_cabang
        ? original_cabang.map((e) => e.CABANG)
        : [];
      const list_regional = original_regional
        ? original_regional.map((e) => e.REGIONAL)
        : [];

      const file = this.state.file;
      const result = () => {
        return new Promise((resolve, reject) => {
          Papa.parse(file, {
            complete: function (results) {
              resolve(results);
            },
            error: function (err) {
              reject(err);
            },
          });
        });
      };
      const final_result = await result();
      const file_header = final_result.data.shift();
      const file_body = final_result.data;
      let arrOfObject = [];
      let allCabangNotFound = [],
        allRegionNotFound = [];
      let arrError = [];

      file_body.forEach((element) => {
        let obj = {};
        file_header.forEach((el, idx) => {
          obj[el] = element[idx];
        });

        obj["ERROR"] = false;
        let arrKeterangan = [];
        if (!list_depo.includes(obj.DEPO)) {
          arrKeterangan.push("ADD DEPO");
        }

        if (!list_cabang.includes(obj.CABANG)) {
          arrKeterangan.push("CABANG NOT FOUND");
          obj["ERROR"] = true;
          if (!allCabangNotFound.includes(obj.CABANG)) {
            allCabangNotFound.push(obj.CABANG);
          }
        } else {
          obj["CABANG_id"] = original_cabang.find(
            (e) => e.CABANG === obj.CABANG
          )._id;
        }

        if (!list_regional.includes(obj.REGIONAL)) {
          arrKeterangan.push("REGIONAL NOT FOUND");
          obj["ERROR"] = true;
          if (!allRegionNotFound.includes(obj.REGIONAL)) {
            allRegionNotFound.push(obj.REGIONAL);
          }
        } else {
          obj["REGIONAL_id"] = original_regional.find(
            (e) => e.REGIONAL === obj.REGIONAL
          )._id;
        }

        if (arrKeterangan.length === 0) {
          arrKeterangan.push("EDIT DEPO");
          obj["DEPO_id"] = filterStruktur.depo.find(
            (e) => e.Depo === obj.DEPO
          )._id;
        }
        obj["KETERANGAN"] = arrKeterangan;
        if (file_header.length === element.length) {
          arrOfObject.push(obj);
        }
        if (obj["ERROR"]) {
          arrError.push([...element, arrKeterangan.join(" - ")]);
        }
      });

      if (arrError.length > 0) {
        const header_error = [
          "PLAN_ID",
          "DEPO",
          "CABANG",
          "REGIONAL",
          "LOCATION",
          "NEGARA",
          "KETERANGAN",
        ];
        this.setState({
          csv_row_error: [header_error, ...arrError],
        });
      }
      this.setState({
        file_header,
        file_body: arrOfObject,
        cabangs_not_found: allCabangNotFound.filter((x) => x),
        regionals_not_found: allRegionNotFound.filter((x) => x),
      });
    } catch (error) {
      console.log(error);
      alert("Error in processing file!");
    }
  };
  handleBulkCabang = () => {
    const body = this.state.cabangs_not_found.map((x) => {
      return { CABANG: x };
    });
    this.props.bulk_add_cabang({ list_cabang: body });
    this.props.getSalesmanFilter();
    if (this.state.mode_download === "csv") {
      this.processFile();
    }
  };
  handleBulkRegional = () => {
    const body = this.state.regionals_not_found.map((x) => {
      return { REGIONAL: x };
    });
    this.props.bulk_add_regional({ list_regional: body });
    this.props.getSalesmanFilter();
    if (this.state.mode_download === "csv") {
      this.processFile();
    }
  };
  handleBulkDepo = () => {
    this.props.bulk_add_edit_depo(this.state.file_body);
    alert("Process to add and change has done!");
  };
  on_change_download = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({
      [name]: value,
      file_body: [],
      file_header: [],
      is_checked: false,
    });
  };
  handleFileExcel = (event) => {
    try {
      const { filterStruktur, original_cabang, original_regional } = this.props;
      const list_depo = filterStruktur?.depo
        ? filterStruktur.depo.map((e) => e.Depo)
        : [];
      const list_cabang = original_cabang
        ? original_cabang.map((e) => e.CABANG)
        : [];
      const list_regional = original_regional
        ? original_regional.map((e) => e.REGIONAL)
        : [];

      let allCabangNotFound = [],
        allRegionNotFound = [];
      let arrError = [];
      let arrOfObject = [];
      readXlsxFile(event.target.files[0]).then((rows) => {
        const HEADER = rows.shift();
        rows.forEach((element) => {
          let obj = {};
          HEADER.forEach((el, idx) => {
            obj[el] = element[idx];
          });
          obj["ERROR"] = false;
          let arrKeterangan = [];
          if (!list_depo.includes(obj.DEPO)) {
            arrKeterangan.push("TAMBAH DEPO");
          }
          if (!list_cabang.includes(obj.CABANG)) {
            arrKeterangan.push("CABANG TIDAK DITEMUKAN");
            obj["ERROR"] = true;
            if (!allCabangNotFound.includes(obj.CABANG)) {
              allCabangNotFound.push(obj.CABANG);
            }
          } else {
            obj["CABANG_id"] = original_cabang.find(
              (e) => e.CABANG === obj.CABANG
            )._id;
          }
          if (!list_regional.includes(obj.REGIONAL)) {
            arrKeterangan.push("REGIONAL TIDAK DITEMUKAN");
            obj["ERROR"] = true;
            if (!allRegionNotFound.includes(obj.REGIONAL)) {
              allRegionNotFound.push(obj.REGIONAL);
            }
          } else {
            obj["REGIONAL_id"] = original_regional.find(
              (e) => e.REGIONAL === obj.REGIONAL
            )._id;
          }
          if (arrKeterangan.length === 0) {
            arrKeterangan.push("EDIT DEPO");
            obj["DEPO_id"] = filterStruktur.depo.find(
              (e) => e.Depo === obj.DEPO
            )._id;
          }
          obj["KETERANGAN"] = arrKeterangan;
          if (HEADER.length === element.length) {
            arrOfObject.push(obj);
          }
          if (obj["ERROR"]) {
            arrError.push([...element, arrKeterangan.join(" - ")]);
          }
          console.log("arrKeterangan : ", arrKeterangan);
        });

        this.setState({
          file_header: HEADER,
          file_body: arrOfObject,
          loadingExcel: false,
          cabangs_not_found: allCabangNotFound.filter((x) => x),
          regionals_not_found: allRegionNotFound.filter((x) => x),
        });
      });
    } catch (err) {
      console.log(err);
      alert("Error in processing file!");
    }
  };
  downloadExampleExcel = async () => {
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });
      try {
        const schema = [
          {
            column: "PLAN_ID",
            type: String,
            value: (target) => target.plan_id,
          },
          {
            column: "DEPO",
            type: String,
            value: (target) => target.depo,
          },
          {
            column: "CABANG",
            type: String,
            value: (target) => target.cabang,
          },
          {
            column: "REGIONAL",
            type: String,
            value: (target) => target.regional,
          },
          {
            column: "LOCATION",
            type: String,
            value: (target) => target.location,
          },
          {
            column: "COUNTRY",
            type: String,
            value: (target) => target.country,
          },
        ];
        let newData = [
          {
            plan_id: "EXAMPLE PLAN ID",
            depo: "EXAMPLE NAME DEPO",
            cabang: "EXAMPLE NAME CABANG",
            regional: "EXAMPLE NAME REGIONAL",
            location: "-6.9965714309083245, 107.5297339384666",
            country: "INDONESIA",
          },
        ];
        await writeXlsxFile(newData, {
          schema,
          fileName: "ExampleUploadDepo.xlsx",
        });
        this.setState({
          loading_csv: false,
        });
      } catch (error) {
        console.log(error);
        this.setState({
          loading: false,
        });
      }
    }
  };
  render() {
    const { file_header, file_body, csv_row_error, mode_download } = this.state;
    const { cabangs_not_found, regionals_not_found } = this.state;
    let renderData;
    if (mode_download === "excel" || mode_download === "csv") {
      renderData = (
        <React.Fragment>
          {mode_download === "csv" ? (
            <section>
              <div className="border p-4 my-4 bg-yellow-100 rounded">
                Warning! to use this feature please follow the instruction based
                on the downloadable CSV template{" "}
                <CSVLink
                  className="text-blue-600"
                  data={template_csv}
                  filename="Template Tambah & Ubah Depo - Nabati 2022.csv"
                >
                  Here
                </CSVLink>
              </div>
              <div className="grid grid-cols-1 gap-1">
                <label htmlFor="file">Select CSV file</label>
                <input
                  type="file"
                  id="file"
                  className="w-fit"
                  accept=".csv"
                  onChange={this.handleFile}
                />
              </div>
              <button className="button_main" onClick={this.processFile}>
                Process File
              </button>
              <p className={`mt-4 ${file_body.length === 0 && "hidden"}`}>
                Total Row: {file_body.length}
              </p>
              <div
                className={`max-h-60 overflow-auto n-scroll ${
                  file_body.length === 0 && "hidden"
                }`}
              >
                <table className="border-2 border-separate">
                  <thead>
                    <tr className="text-left bg-gray-300">
                      <th>No</th>
                      {file_header.map((el, idx) => (
                        <th key={idx}>{el}</th>
                      ))}
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {file_body.map((el, idx) => (
                      <tr key={idx} className={`${el.ERROR && "bg-red-200"}`}>
                        <td className="font-semibold">{idx + 1}</td>
                        <td>{el.PLAN_ID}</td>
                        <td>{el.DEPO}</td>
                        <td>{el.CABANG}</td>
                        <td>{el.REGIONAL}</td>
                        <td>{el.LOCATION}</td>
                        <td>{el.COUNTRY}</td>
                        <td>{el.KETERANGAN.join(", ")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div
                className={`w-full ${file_body.length === 0 && "hidden"} 
                  ${cabangs_not_found.length !== 0 && "hidden"} ${
                  regionals_not_found.length !== 0 && "hidden"
                }`}
              >
                <button
                  className={`button_main w-full mt-2`}
                  onClick={this.handleBulkDepo}
                >
                  Process to add & change Bulk
                </button>
              </div>
              <div className={`${csv_row_error.length === 0 && "hidden"}`}>
                <CSVLink
                  className={`button_main mt-2`}
                  data={csv_row_error}
                  filename="Unduh Data Yang Memiliki Error - Nabati 2022.csv"
                >
                  Download the data that has an error
                </CSVLink>
              </div>
              <div
                className={`grid grid-cols-2 gap-1 ${
                  cabangs_not_found.length === 0 &&
                  "hidden" &&
                  regionals_not_found.length === 0 &&
                  "hidden"
                }`}
              >
                <div
                  className={`mt-4 ${
                    cabangs_not_found.length === 0 && "hidden"
                  }`}
                >
                  <b>List of branches that are not found</b>
                  <ol className="max-h-40 overflow-auto">
                    {cabangs_not_found.map((e) => (
                      <li key={e}>{e}</li>
                    ))}
                  </ol>
                  <button
                    className="button_main mt-1"
                    onClick={this.handleBulkCabang}
                  >
                    Enter the branches that are not found
                  </button>
                </div>
                <div
                  className={`mt-4 ${
                    regionals_not_found.length === 0 && "hidden"
                  }`}
                >
                  <b>List of regions that are not found</b>
                  <ol className="max-h-40 overflow-auto">
                    {regionals_not_found.map((e) => (
                      <li key={e}>{e}</li>
                    ))}
                  </ol>
                  <button
                    className="button_main mt-1"
                    onClick={this.handleBulkRegional}
                  >
                    Enter the regions that are not found
                  </button>
                </div>
              </div>
            </section>
          ) : (
            <section>
              <div className="border p-4 my-4 bg-yellow-100 rounded">
                Warning! to use this feature please follow the instruction based
                on the downloadable EXCEL template{" "}
                <button
                  className="text-blue-600"
                  onClick={this.downloadExampleExcel}
                >
                  Here
                </button>
              </div>
              <div className="grid grid-cols-1 gap-1">
                <label htmlFor="file">Select EXCEL file</label>
                <input
                  type="file"
                  className="w-fit"
                  onChange={this.handleFileExcel}
                />
                {this.state.loadingExcel && (
                  <p
                    className="text_bold bg-[#51B13D] rounded w-1/5 text-white text-center
                        "
                  >
                    Loading Data...
                  </p>
                )}
              </div>
              <p className={`mt-4 ${file_body.length === 0 && "hidden"}`}>
                Total Row: {file_body.length}
              </p>
              <div
                className={`max-h-60 overflow-auto n-scroll ${
                  file_body.length === 0 && "hidden"
                }`}
              >
                <table className="border-2 border-separate">
                  <thead>
                    <tr className="text-left bg-gray-300">
                      <th>No</th>
                      {file_header.map((el, idx) => (
                        <th key={idx}>{el}</th>
                      ))}
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {file_body.map((el, idx) => (
                      <tr key={idx} className={`${el.ERROR && "bg-red-200"}`}>
                        <td className="font-semibold">{idx + 1}</td>
                        <td>{el.DEPO}</td>
                        <td>{el.CABANG}</td>
                        <td>{el.REGIONAL}</td>
                        <td>{el.LOCATION}</td>
                        <td>{el.COUNTRY}</td>
                        <td>{el.KETERANGAN.join(", ")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div
                className={`w-full ${file_body.length === 0 && "hidden"} 
                      ${cabangs_not_found.length !== 0 && "hidden"} ${
                  regionals_not_found.length !== 0 && "hidden"
                }`}
              >
                <button
                  className={`button_main w-full mt-2`}
                  onClick={this.handleBulkDepo}
                >
                  Process to add & change Bulk
                </button>
              </div>
              <div className={`${csv_row_error.length === 0 && "hidden"}`}>
                <CSVLink
                  className={`button_main mt-2`}
                  data={csv_row_error}
                  filename="Unduh Data Yang Memiliki Error - Nabati 2022.csv"
                >
                  Download the data that has an error
                </CSVLink>
              </div>
              <div
                className={`grid grid-cols-2 gap-1 ${
                  cabangs_not_found.length === 0 &&
                  "hidden" &&
                  regionals_not_found.length === 0 &&
                  "hidden"
                }`}
              >
                <div
                  className={`mt-4 ${
                    cabangs_not_found.length === 0 && "hidden"
                  }`}
                >
                  <b>List of branches that are not found</b>
                  <ol className="max-h-40 overflow-auto">
                    {cabangs_not_found.map((e) => (
                      <li key={e}>{e}</li>
                    ))}
                  </ol>
                  <button
                    className="button_main mt-1"
                    onClick={this.handleBulkCabang}
                  >
                    Enter the branches that are not found
                  </button>
                </div>
                <div
                  className={`mt-4 ${
                    regionals_not_found.length === 0 && "hidden"
                  }`}
                >
                  <b>List of regions that are not found</b>
                  <ol className="max-h-40 overflow-auto">
                    {regionals_not_found.map((e) => (
                      <li key={e}>{e}</li>
                    ))}
                  </ol>
                  <button
                    className="button_main mt-1"
                    onClick={this.handleBulkRegional}
                  >
                    Enter the regions that are not found
                  </button>
                </div>
              </div>
            </section>
          )}
        </React.Fragment>
      );
    }
    return (
      <div>
        <select
          name="mode_download"
          id="mode_download"
          value={mode_download}
          onChange={this.on_change_download}
        >
          <option value="pilih">Select</option>
          <option value="csv">CSV</option>
          <option value="excel">EXCEL</option>
        </select>
        {renderData}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  filterStruktur: state.data.salesman_filter,
  original_cabang: state.data.salesman_filter_original_cabang,
  original_regional: state.data.salesman_filter_original_regional,
});
export default connect(mapStateToProps, {
  bulk_add_cabang,
  bulk_add_regional,
  getSalesmanFilter,
  bulk_add_edit_depo,
})(DepoBulk);
