import React, { Component } from "react";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import { uploadAddDriver, uploadEditDriver } from "../../actions/driverActions";

const template_csv = [
  [
    "_id",
    "DRIVER",
    "HELPER",
    "LICENSE PLATE NUMBER",//"NOPOL",
    "DEPO",//"Depo",
    "CAR TYPE",//"JENIS",
    "CARTON CAPACITY", //"KAPASITAS_CTN",
    "VOLUME CAPACITY",//"KAPASITAS_M3",
    "COUNTRY",
  ],
  [
    "654c477034c0564e868b6960",
    "Driver Testing Upload",
    "-",
    "D1234EG",
    "DEPO TEST",
    "BLIND VAN",
    "100",
    "2,38",
    "INDONESIA",
  ],
  [
    "",
    "Driver Testing Di Tambah",
    "-",
    "D1234EG",
    "DEPO TEST",
    "BLIND VAN",
    "100",
    "2,38",
    "INDONESIA",
  ],
];

export default class UploadDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      file_header: [],
      file_body: [],
      mode_download: "",
      loadingExcel: false,
    };
  }
  on_reset_bulk_driver = () => {
    this.setState({ file_header: [], file_body: [] });
  };

  handleFile = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  };

  processFile = async () => {
    this.on_reset_bulk_driver();
    try {
      const file = this.state.file;
      const result = () => {
        return new Promise((resolve, reject) => {
          Papa.parse(file, {
            complete: function (results) {
              resolve(results);
            },
            error: function (err) {
              reject(err);
            },
          });
        });
      };
      const final_result = await result();
      const file_header = final_result.data.shift();
      const file_body = final_result.data;

      let arrOfObject = [];
      file_body.forEach((element) => {
        let obj = {
          _status: "Waiting for upload",
        };
        file_header.forEach((el, idx) => {
          let value = element[idx];
          if (el === "NOPOL") value = element?.[idx]?.split(" ").join("");
          obj[el] = value;
        });
        if (file_header.length === element.length) {
          arrOfObject.push(obj);
        }
      });
      this.setState({
        file_header,
        file_body: arrOfObject,
      });
    } catch (error) {
      console.log(error);
      alert("Error process file!");
    }
  };

  saveBulk = async () => {
    const { file_body } = this.state;
    for (let index = 0; index < file_body.length; index++) {
      const element = file_body[index];
      if (element._id === "") {
        try {
          this.updateObjectAtIndex(index, { ...element, _status: "add driver" });
          await uploadAddDriver(element);
          this.updateObjectAtIndex(index, { ...element, _status: "done add" });
        } catch (error) {
          this.updateObjectAtIndex(index, { ...element, _status: "failed" });
        }
      } else {
        try {
          this.updateObjectAtIndex(index, { ...element, _status: "edit driver" });
          await uploadEditDriver(element);
          this.updateObjectAtIndex(index, { ...element, _status: "done edit" });
        } catch (error) {
          this.updateObjectAtIndex(index, { ...element, _status: "failed" });
        }
      }
    }
  };

  updateObjectAtIndex = (index, newValue) => {
    this.setState((prevState) => {
      const updatedArray = [...prevState.file_body];
      updatedArray[index] = { ...updatedArray[index], ...newValue };
      return { file_body: updatedArray };
    });
  };

  render() {
    const { file_header, file_body } = this.state;
    const syarat_header = template_csv[0];
    const syarat_content = (
      <table className="table" style={{ fontSize: "11px" }}>
        <tbody>
          {syarat_header.map((e, idx) => {
            let status = (
              <td id="green" style={{ width: "70px", textAlign: "center" }}>
                V
              </td>
            );
            if (!file_header.includes(e))
              status = (
                <td id="red" style={{ width: "70px", textAlign: "center" }}>
                  X
                </td>
              );
            return (
              <tr key={idx}>
                <td>{e}</td>
                {status}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
    let button = (
      <button className={`button_main w-full mt-2`} onClick={this.saveBulk}>
        Start Upload
      </button>
    );
    return (
      <React.Fragment>
        <section>
          <div className="border p-4 my-4 bg-yellow-100 rounded">
            Warning! for using this feature please follow the instructions
            according to the downloadable template{" "}
            <CSVLink
              className="text-blue-600"
              data={template_csv}
              filename="TEMPLATE UPLOAD DRIVER.csv"
              separator=";"
            >
              here
            </CSVLink>
          </div>
          <div className="grid grid-cols-1 gap-1">
            <label htmlFor="file">Select CSV file</label>
            <input
              type="file"
              id="file"
              className="w-1/2"
              accept=".csv"
              onChange={this.handleFile}
            />
          </div>
          <button className="button_main w-1/2" onClick={this.processFile}>
            Processing File
          </button>
          {file_body.length !== 0 && (
            <main>
              <p className="text_bold mt-5">Data Verification</p>
              {syarat_content}
            </main>
          )}
          {file_body.length !== 0 && (
            <p className="text_bold mt-5">Data Preview</p>
          )}
          {file_body.length !== 0 && (
            <div
              className="w-full overflow-auto n-scroll pb-2 pr-2"
              style={{ maxHeight: "calc(( 100vh - 400px ))" }}
            >
              <table className="border-2 border-separate">
                <thead>
                  <tr className="text-left bg-gray-300">
                    <th>No</th>
                    {file_header.map((el, idx) => (
                      <th key={idx}>{el}</th>
                    ))}
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {file_body.map((el, idx) => {
                    let status = el["_status"];
                    let id = "red";
                    if (status === "done add" || status === "done edit") {
                      id = "green";
                    }
                    return (
                      <tr key={idx} className={`even:bg-gray-50`}>
                        <td className="font-semibold">{idx + 1}</td>
                        {file_header.map((elHeader, idx) => (
                          <td key={idx}>{el[elHeader]}</td>
                        ))}
                        <td id={id}>{status}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {file_body.length !== 0 && <div>{button}</div>}
        </section>
      </React.Fragment>
    );
  }
}
