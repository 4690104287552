import styled from "styled-components";

const Modal = styled.div`
  position: fixed;
  top: 15px;
  left: -100px;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
  z-index: 2000;
  width: 485px;
  max-width: calc((100vw - 50px));
  &.fade-in {
    left: 15px;
    opacity: 1;
    -webkit-transition: 0.1s;
    -moz-transition: 0.1s;
    -o-transition: 0.1s;
    transition: 0.1s;
  }
  &.fade-out {
    opacity: 0;
    left: -100px;
    -webkit-transition: 0.1s;
    -moz-transition: 0.1s;
    -o-transition: 0.1s;
    transition: 0.1s;
  }
  .background {
    background: tranparent;
    position: fixed;
    z-index: 1040;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }
  .box-dialog {
    border-radius: 25px;
    border: 2px solid #77c2c4;
    padding-bottom: 20px;
    z-index: 1050;
    width: 100%;
    background-color: ${(props) => {
      if (props.backgroundColor) {
        return props.backgroundColor;
      } else {
        return "#fff";
      }
    }};
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.071);
    .box-body {
      font-size: 14px;
      padding: 0px 10px 10px 10px;
      overflow-y: auto;
      overflow-x: auto;
      max-height: calc((100vh - 100px));
      box-sizing: border-box;
    }
    .header {
      position: absolute;
      top: 10px;
      left: 60px;
      background-color: ${(props) => {
        if (props.backgroundColor) {
          return props.backgroundColor;
        } else {
          return "#fff";
        }
      }};
      box-sizing: border-box;
      font-size: 17px;
      font-weight: 600;
      padding-bottom: 7px;
    }
    .close {
      top: 0px;
      padding: 10px;
    }
  }
`;

export default Modal;
