//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
import Modal from "../common/Modal";
//Redux function
import {
  delete_salesman_rayon_id,
  get_salesman_by_depo_id_array,
  update_salesman_rayon,
} from "../../actions/dataActions";
//Picture Asset
//General Function
import st_colors from "../../validation/st_colors";
import sort_array from "../../validation/sort_array";
class ToolRayonSalesmanCompetitor extends Component {
  state = {
    modal_choose_salesman: false,
    rayon: {},
    divisi: "",
    divisi_before: "",
    depo: "",
  };
  componentDidMount() {
    //get salesman multi depo berdasarkan rayon_list_database
    const { depo_loc_list } = this.props.data;
    let depo_id_array = depo_loc_list.map((d) => d._id);
    depo_id_array = depo_id_array.join(",");
    const params = {
      depo_id_array,
    };
    this.props.get_salesman_by_depo_id_array(params);
  }
  on_delete_salesman_rayon_id = (salesman_id, rayon_id) => {
    const body = {
      salesman_id,
      rayon_id,
    };
    this.props.delete_salesman_rayon_id(body);
  };
  on_update_salesman_rayon = (salesman_id) => {
    const { rayon } = this.state;
    const body = {
      salesman_id,
      rayon_id: rayon._id,
    };
    this.props.update_salesman_rayon(body);
  };
  toggle_choose_salesman = (rayon, divisi, depo) => {
    this.setState({ modal_choose_salesman: !this.state.modal_choose_salesman });
    if (divisi) {
      this.setState({ rayon, divisi, divisi_before: divisi, depo });
    }
  };
  set_divisi = (divisi) => {
    this.setState({ divisi });
  };
  render() {
    const { modal_choose_salesman, rayon, divisi, divisi_before, depo } =
      this.state;
    const {
      rayon_list_database,
      salesman_list,
      depo_loc_list,
      competitor_list_filter_0,
    } = this.props.data;

    let rayon_list_final = [];

    let { list_menu_active } = this.props.data;
    const menu_status = list_menu_active.find((e) => e.key === "depo_loc_list");
    let rayon_list_complete = menu_status.config.rayon_list_complete;

    const mode = "prod";

    if (mode === "dev") {
      rayon_list_final = rayon_list_complete;
    } else {
      rayon_list_final = rayon_list_database;
    }

    let salesman_list_state = [];
    if (divisi === "NO_DIV") {
      salesman_list_state = salesman_list.filter(
        (s) => s?.divisi_arr?.length === 0
      );
    } else if (divisi !== "ALL") {
      salesman_list_state = salesman_list.map((s) => {
        const divisi_arr_bool = s.divisi_arr.map((d) => d.includes(divisi));
        s.divisi_arr_bool = divisi_arr_bool;
        return s;
      });
      salesman_list_state = salesman_list_state.filter((s) =>
        s.divisi_arr_bool.includes(true)
      );
    } else {
      salesman_list_state = salesman_list;
    }
    salesman_list_state = salesman_list_state.filter((s) => s.Depo === depo);
    // const salesman_test = salesman_list.filter(
    //   (s) => s.divisi_arr.length > 0
    // )[0];
    // console.log(salesman_test);

    // console.log(salesman_list[0]);
    // console.log(depo_loc_list);
    const rayon_tertampil_content = (
      <main className="mt-5">
        <p className="mb-3 text_bold text_center">
          Share rayon of competitor store to salesman
        </p>
        {depo_loc_list.map((d, idx) => {
          const rayon_list_current = rayon_list_final.filter(
            (r) => r.depo_string === d.Depo
          );
          const salesman_current = salesman_list.filter(
            (s) => s.Depo === d.Depo
          );
          return (
            <main key={idx}>
              <div className="button" id="blue_bright">
                Depo: {d.Depo}
              </div>
              <section className="text_inferior">
                Total salesman: <b>{salesman_current.length}</b>
              </section>
              <section className="text_inferior">
                Total rayon: <b>{rayon_list_current.length}</b>
              </section>
              <table
                className="table_lite"
                style={{
                  fontSize: "13px",
                  width: "100%",
                  tableLayout: "fixed",
                }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "20px", height: "20px" }}></th>
                    <th className="text_left">Rayon name</th>
                    <th className="text_right" style={{ width: "77px" }}>
                      Total store
                    </th>
                    <th className="text_right" style={{ width: "77px" }}>
                      Total salesman
                    </th>
                    <th className="text_left">DIV 1</th>
                    <th className="text_left">DIV 2</th>
                    <th className="text_left">DIV 3</th>
                    <th className="text_left">DA</th>
                    <th className="text_left">OTHER</th>
                  </tr>
                </thead>
                <tbody>
                  {rayon_list_current.map((r, idx) => {
                    let index = idx;
                    if (idx > 15) {
                      index = idx % 16;
                    }
                    const color = st_colors[index];
                    let jumlah_toko_rayon = 0;
                    if (r._id) {
                      jumlah_toko_rayon = competitor_list_filter_0.filter(
                        (t) => t.rayon_id === r._id
                      ).length;
                    } else {
                      jumlah_toko_rayon = competitor_list_filter_0.filter(
                        (t) => t.rayon === r.name
                      ).length;
                    }

                    const salesman_rayon = salesman_list.filter((s) =>
                      s?.rayon_id_list?.includes(r._id)
                    );
                    const jumlah_salesman_rayon = salesman_rayon?.length;
                    //d1
                    let salesman_list_d1 = salesman_rayon.map((s) => {
                      const divisi_arr_bool = s.divisi_arr.map((d) =>
                        d.includes("D1")
                      );
                      s.divisi_arr_bool = divisi_arr_bool;
                      return s;
                    });
                    salesman_list_d1 = salesman_list_d1.filter((s) =>
                      s.divisi_arr_bool.includes(true)
                    );
                    const salesman_d1 = (
                      <ol>
                        {salesman_list_d1.map((s, idx) => {
                          return (
                            <li key={idx}>
                              {s?.["Nama Only"]} ({s.NIK})
                              <button
                                className="button_small"
                                id="red_lite"
                                onClick={this.on_delete_salesman_rayon_id.bind(
                                  this,
                                  s._id,
                                  r._id
                                )}
                              >
                                x
                              </button>
                            </li>
                          );
                        })}
                      </ol>
                    );

                    //d2
                    let salesman_list_d2 = salesman_rayon.map((s) => {
                      const divisi_arr_bool = s.divisi_arr.map((d) =>
                        d.includes("D2")
                      );
                      s.divisi_arr_bool = divisi_arr_bool;
                      return s;
                    });
                    salesman_list_d2 = salesman_list_d2.filter((s) =>
                      s.divisi_arr_bool.includes(true)
                    );
                    const salesman_d2 = (
                      <ol>
                        {salesman_list_d2.map((s, idx) => {
                          return (
                            <li key={idx}>
                              {s?.["Nama Only"]} ({s.NIK})
                              <button
                                className="button_small"
                                id="red_lite"
                                onClick={this.on_delete_salesman_rayon_id.bind(
                                  this,
                                  s._id,
                                  r._id
                                )}
                              >
                                x
                              </button>
                            </li>
                          );
                        })}
                      </ol>
                    );

                    //d3
                    let salesman_list_d3 = salesman_rayon.map((s) => {
                      const divisi_arr_bool = s.divisi_arr.map((d) =>
                        d.includes("D3")
                      );
                      s.divisi_arr_bool = divisi_arr_bool;
                      return s;
                    });
                    salesman_list_d3 = salesman_list_d3.filter((s) =>
                      s.divisi_arr_bool.includes(true)
                    );
                    const salesman_d3 = (
                      <ol>
                        {salesman_list_d3.map((s, idx) => {
                          return (
                            <li key={idx}>
                              {s?.["Nama Only"]} ({s.NIK}){" "}
                              <button
                                className="button_small"
                                id="red_lite"
                                onClick={this.on_delete_salesman_rayon_id.bind(
                                  this,
                                  s._id,
                                  r._id
                                )}
                              >
                                x
                              </button>
                            </li>
                          );
                        })}
                      </ol>
                    );

                    //da
                    let salesman_list_da = salesman_rayon.map((s) => {
                      const divisi_arr_bool = s.divisi_arr.map((d) =>
                        d.includes("DA")
                      );
                      s.divisi_arr_bool = divisi_arr_bool;
                      return s;
                    });
                    salesman_list_da = salesman_list_da.filter((s) =>
                      s.divisi_arr_bool.includes(true)
                    );
                    const salesman_da = (
                      <ol>
                        {salesman_list_da.map((s, idx) => {
                          return (
                            <li key={idx}>
                              {s?.["Nama Only"]} ({s.NIK})
                              <button
                                className="button_small"
                                id="red_lite"
                                onClick={this.on_delete_salesman_rayon_id.bind(
                                  this,
                                  s._id,
                                  r._id
                                )}
                              >
                                x
                              </button>
                            </li>
                          );
                        })}
                      </ol>
                    );

                    //other
                    const salesman_list_other = salesman_rayon.filter(
                      (s) => s?.divisi_arr?.length === 0
                    );
                    const salesman_other = (
                      <ol>
                        {salesman_list_other.map((s, idx) => {
                          return (
                            <li key={idx}>
                              {s?.["Nama Only"]} ({s.NIK})
                              <button
                                className="button_small"
                                id="red_lite"
                                onClick={this.on_delete_salesman_rayon_id.bind(
                                  this,
                                  s._id,
                                  r._id
                                )}
                              >
                                x
                              </button>
                            </li>
                          );
                        })}
                      </ol>
                    );

                    return (
                      <tr key={idx}>
                        <td
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        >
                          <div
                            style={{
                              width: "17px",
                              height: "17px",
                              backgroundColor: color,
                              display: "inline-block",
                              marginRight: "5px",
                              border: "2px solid #ffffff",
                              borderRadius: "50%",
                              marginBottom: "5px",
                            }}
                          />
                        </td>
                        <td
                          className="text_left"
                          style={{ fontSize: "12px", fontWeight: "600" }}
                        >
                          {r.name}
                        </td>
                        <td className="text_right" style={{ width: "77px" }}>
                          {jumlah_toko_rayon}
                        </td>
                        <td className="text_right" style={{ width: "77px" }}>
                          {jumlah_salesman_rayon}
                        </td>
                        <td>
                          <button
                            className="button"
                            id="green"
                            onClick={this.toggle_choose_salesman.bind(
                              this,
                              r,
                              "D1",
                              d.Depo
                            )}
                          >
                            +
                          </button>
                          {salesman_d1}
                        </td>
                        <td>
                          <button
                            className="button"
                            id="green"
                            onClick={this.toggle_choose_salesman.bind(
                              this,
                              r,
                              "D2",
                              d.Depo
                            )}
                          >
                            +
                          </button>
                          {salesman_d2}
                        </td>
                        <td>
                          <button
                            className="button"
                            id="green"
                            onClick={this.toggle_choose_salesman.bind(
                              this,
                              r,
                              "D3",
                              d.Depo
                            )}
                          >
                            +
                          </button>
                          {salesman_d3}
                        </td>
                        <td>
                          <button
                            className="button"
                            id="green"
                            onClick={this.toggle_choose_salesman.bind(
                              this,
                              r,
                              "DA",
                              d.Depo
                            )}
                          >
                            +
                          </button>
                          {salesman_da}
                        </td>
                        <td>
                          <button
                            className="button"
                            id="green"
                            onClick={this.toggle_choose_salesman.bind(
                              this,
                              r,
                              "NO_DIV",
                              d.Depo
                            )}
                          >
                            +
                          </button>
                          {salesman_other}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <hr style={{ marginBottom: "5px", borderColor: "#aeb0b1ff" }} />
            </main>
          );
        })}
      </main>
    );
    const modal_choose_salesman_content = modal_choose_salesman && (
      <Modal
        modalSize="medium"
        isOpen={modal_choose_salesman}
        onClose={this.toggle_choose_salesman}
      >
        <div className="box-body">
          <main style={{ minHeight: "100vh" }}>
            <section className="text_center mb-5">
              <p className="text_medium mb-3">
                Choose salesman: <b>{rayon.name}</b>
              </p>
              <div className="button  mb-3" id="green">
                Choose division: {divisi_before}
              </div>
              <br />
              <div>Filter:</div>
              <button
                className="button mr-1 mb-1"
                id={divisi === "D1" ? "green" : "grey"}
                onClick={this.set_divisi.bind(this, "D1")}
              >
                D1
              </button>
              <button
                className="button mr-1 mb-1"
                id={divisi === "D2" ? "green" : "grey"}
                onClick={this.set_divisi.bind(this, "D2")}
              >
                D2
              </button>
              <button
                className="button mr-1 mb-1"
                id={divisi === "D3" ? "green" : "grey"}
                onClick={this.set_divisi.bind(this, "D3")}
              >
                D3
              </button>
              <button
                className="button mr-1 mb-1"
                id={divisi === "DA" ? "green" : "grey"}
                onClick={this.set_divisi.bind(this, "DA")}
              >
                DA
              </button>
              <button
                className="button mr-1 mb-1"
                id={divisi === "NO_DIV" ? "green" : "grey"}
                onClick={this.set_divisi.bind(this, "NO_DIV")}
              >
                NO DIVISION
              </button>
              <button
                className="button mr-1 mb-1"
                id={divisi === "ALL" ? "green" : "grey"}
                onClick={this.set_divisi.bind(this, "ALL")}
              >
                ALL
              </button>
            </section>
            <table
              className="table_lite"
              style={{ width: "100%", tableLayout: "fixed" }}
            >
              <tbody>
                {salesman_list_state.map((s, idx) => {
                  const rayon_id_list = s?.rayon_id_list
                    ? s?.rayon_id_list
                    : [];
                  const rayon_salesman_content = (
                    <main>
                      Salesman rayon:
                      <ol>
                        {rayon_id_list.map((id, idx) => {
                          const rayon_object = rayon_list_database.find(
                            (r) => r._id === id
                          );

                          return <li key={idx}>{rayon_object.name}</li>;
                        })}
                      </ol>
                    </main>
                  );

                  let divisi_arr = s?.divisi_arr ? s.divisi_arr : [];
                  divisi_arr = sort_array(divisi_arr, "", true);
                  const divisi_content = divisi_arr.join(" | ");
                  return (
                    <tr key={idx}>
                      <td>
                        {idx + 1}. {s?.["NIK"]}
                      </td>
                      <td>{s?.["Nama Only"]}</td>
                      <td>{divisi_content}</td>
                      <td>
                        <button
                          className="button_small"
                          id="green"
                          onClick={this.on_update_salesman_rayon.bind(
                            this,
                            s._id
                          )}
                        >
                          Add
                        </button>
                        {rayon_salesman_content}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </main>
        </div>
      </Modal>
    );
    return (
      <main>
        {modal_choose_salesman_content}
        {rayon_tertampil_content}
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, {
  delete_salesman_rayon_id,
  get_salesman_by_depo_id_array,
  update_salesman_rayon,
})(ToolRayonSalesmanCompetitor);
