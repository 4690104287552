import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;

export const set_loading_top = () => (dispach) => {
  dispach({
    type: "SET_LOADING_TOP",
  });
};

export const clear_loading_top = () => (dispach) => {
  dispach({
    type: "CLEAR_LOADING_TOP",
  });
};

export const set_loading = (loading_item) => {
  return {
    type: "SET_LOADING",
    payload: loading_item,
  };
};

export const clear_loading = () => {
  return {
    type: "CLEAR_LOADING",
  };
};

export const post_revisi =
  ({ message_revisi, outlet_id }) =>
  async (dispach) => {
    try {
      dispach(set_loading_top());
      const body = {
        outlet_id: outlet_id,
        object_edit: {
          revisi: {
            status_revisi: true,
            message_revisi: message_revisi,
          },
        },
      };
      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenNabati,
        },
      };
      await axios.post(`${SERVER_URL}/nabati-outlet/edit_toko`, body, config);

      dispach(clear_loading_top());
    } catch (error) {
      dispach(clear_loading_top());
    }
  };

export const bulk_add_competitor = (body) => async (dispach) => {
  try {
    dispach(set_loading_top());
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };

    await axios.post(
      `${SERVER_URL}/nabati-outlet/bulk_add_competitor`,
      body,
      config
    );

    dispach(clear_loading_top());
  } catch (error) {
    dispach(clear_loading_top());
  }
};
