//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";
//Personal Component
//Redux function
//Picture Asset
//General Function
import st_colors from "../../validation/st_colors";
class DepoLayer extends Component {
  render() {
    let is_visible;
    is_visible = this.props.data.list_menu_active.find(
      (e) => e.config.mode === "jarak"
    )?.active;
    const { depo_loc_list } = this.props.data;
    let circle = "#8931ef";
    let geojson = {
      type: "FeatureCollection",
      features: [],
    };
    if (depo_loc_list.length > 0) {
      //generate color
      const array_name = depo_loc_list.map((d) => d.Depo);
      let array_name_new = array_name.slice();
      const array_color = array_name_new.map((e, idx) => {
        let index = idx;
        if (idx > 15) {
          index = idx % 16;
        }
        return st_colors[index];
      });
      if (array_color.length > 1) {
        /*
          sample color
          'fill-color': ['match', ['get', 'PROV_ID'], // get the property
          'GP', 'yellow',              // if 'GP' then yellow
          'XX', 'black',               // if 'XX' then black 
          'white']                     // white otherwise
          */
        array_color.push("#000000");
        let array1 = array_name_new,
          array2 = array_color,
          result = [],
          i,
          l = Math.min(array1.length, array2.length);
        for (i = 0; i < l; i++) {
          result.push(array1[i], array2[i]);
        }
        result.push(...array1.slice(l), ...array2.slice(l));
        circle = ["match", ["get", "Depo"]];
        circle = circle.concat(result);
      } else {
        circle = array_color[0];
      }
      const new_features = depo_loc_list.map((e) => {
        return {
          type: "Feature",
          properties: { ...e },
          geometry: {
            type: "Point",
            coordinates: e.location.coordinates,
          },
        };
      });
      geojson = {
        type: "FeatureCollection",
        features: [...new_features],
      };
    }
    return (
      depo_loc_list.length > 0 && (
        <main>
          <Source
            key={"depo_loc_list-src"}
            id="depo_loc_list-src"
            type="geojson"
            data={geojson}
          />
          <Layer
            id="depo_loc_list"
            key="depo_loc_list"
            before=""
            type="symbol"
            source={"depo_loc_list-src"}
            layout={{
              visibility: is_visible ? "visible" : "none",
              "icon-image": "rumah",
              "text-field": ["get", "Depo"],

              "icon-size": 0.7,
              "icon-padding": 0,
              "icon-anchor": "center",
              "text-anchor": "top",
              "text-offset": [0, 1.5],
              "text-line-height": 1,
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
              "text-allow-overlap": true,
              "text-ignore-placement": true,
              "text-size": 12,
              "text-max-width": 32,
            }}
            paint={{
              "icon-color": circle,
              "icon-opacity": 1,
              "text-color": circle,
              "text-halo-color": "white",
              "icon-halo-color": "black",
              "text-halo-width": 10,
              "icon-halo-width": 3.4,
              "text-halo-blur": 1,
            }}
            onLeave={this.onLeave}
            onClick={this.props.layerOnClick.bind(this, "modal_depo")}
          />
        </main>
      )
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, {})(DepoLayer);
