/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/
// import generate_array_color from "../../validation/generate_array_color";
import generate_color_map from "../../validation/generate_color_map";

class OUTLET_BY_SALESMAN_LAYER extends Component {
  state = {
    content_render: null,
  };

  render_data = () => {
    let { div_toko, list_menu_active, salesman_select, filter_outlet_object } =
      this.props.data;
    let { outlet_by_salesman_list_filter_0 } = this.props.outlet;

    const menu_status = list_menu_active.find(
      (e) => e.key === "outlet_by_salesman"
    );
    const mode = menu_status?.config?.mode;
    const active = menu_status?.active;

    let circle, text_final, array_name, array_color, new_features;
    if (outlet_by_salesman_list_filter_0?.length > 0) {
      if (mode) {
        if (
          [
            "depo_filter_array",
            "system_filter_array",
            "segment_filter_array",
            "type_filter_array",
            "list_menu_active",
            "rayon_filter_array",
            "day_filter_array",
            "cycle_filter_array",
            "provinsi_filter_array",
            "kabupaten_filter_array",
            "kecamatan_filter_array",
            "kelurahan_filter_array",
          ].includes(mode)
        ) {
          const array = this.props.data[mode];
          array_name = array.map((e) => e.name);
          array_color = array.map((e) => e.color);
          array_color.push("#575757");
          text_final = filter_outlet_object[mode].properties_name;
        }
        circle = generate_color_map(array_name, array_color, text_final);
      } else {
        circle = "#575757";
      }
      new_features = outlet_by_salesman_list_filter_0.map((e) => {
        let salesman_color = e.kunjungan_arr.find((k) =>
          k.NamaSales.includes(div_toko)
        );
        salesman_color = `${salesman_color?.KodeSales}_${e.Depo}`;
        //generate atribut for planning color
        let kunjungan_arr_new = e?.kunjungan_arr_new
          ? e?.kunjungan_arr_new
          : [];
        const kunjungan_arr_select = kunjungan_arr_new.find(
          (p) => p?.salesman_id === salesman_select?._id
        );
        const plan_value = String(
          `${kunjungan_arr_select?.salesman_id}_${kunjungan_arr_select?.cycle}_${kunjungan_arr_select?.day}`
        );
        const cycle = e.cycle ? e.cycle : "";
        const Depo = e.Depo ? e.Depo : "";
        return {
          type: "Feature",
          properties: { ...e, salesman_color, plan_value, cycle, Depo },
          geometry: e.location,
        };
      });
    }
    let content_render = null;
    if (outlet_by_salesman_list_filter_0.length > 0) {
      let geojson = {
        type: "FeatureCollection",
        features: new_features,
      };

      content_render = (
        <main>
          <Source
            key="outlet_by_salesman_source"
            id="outlet_by_salesman_source"
            type="geojson"
            data={geojson}
          />
          <Layer
            id="outlet_by_salesman"
            before="rayon"
            key="outlet_by_salesman"
            type="circle"
            source="outlet_by_salesman_source"
            layout={{
              visibility: active ? "visible" : "none",
            }}
            filter={["all", ["!=", "id", -1]]}
            paint={{
              "circle-color": circle,
              "circle-radius": 5,
              "circle-stroke-width": 1,
              "circle-stroke-color": "black",
              "circle-opacity": 1,
            }}
            onLeave={this.onLeave}
            onClick={this.props.layerOnClick.bind(this, "modal_distribution")}
          />
        </main>
      );
    }
    return {
      content_render,
    };
  };

  componentDidMount() {
    const result = this.render_data();
    const { content_render } = result;
    this.setState({ content_render });
  }

  componentDidUpdate(prevProps) {
    const status_action_after = this.props.map.status_action;
    const status_action_before = prevProps.map.status_action;
    if (status_action_after !== status_action_before) {
      const result = this.render_data();
      const { content_render } = result;
      this.setState({ content_render });
    }
  }

  render() {
    //local storage

    //local state
    const { content_render } = this.state;

    //global props

    //content
    return <>{content_render}</>;
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
  properties: state.properties,
  outlet: state.outlet,
});

export default connect(mapStateToProps, {})(OUTLET_BY_SALESMAN_LAYER);
