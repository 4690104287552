import React, { Component } from "react";
import { connect } from "react-redux";
class Spinner extends Component {
  render() {
    return (
      <span>
        <main
          style={{ margin: "auto", textAlign: "center", marginTop: "20px" }}
        >
          <section id="wifi_loader">
            <svg className="circle-outer" viewBox="0 0 86 86">
              <circle className="back" cx="43" cy="43" r="40"></circle>
              <circle className="front" cx="43" cy="43" r="40"></circle>
              <circle className="new" cx="43" cy="43" r="40"></circle>
            </svg>
            <svg className="circle-middle" viewBox="0 0 60 60">
              <circle className="back" cx="30" cy="30" r="27"></circle>
              <circle className="front" cx="30" cy="30" r="27"></circle>
            </svg>
            <svg className="circle-inner" viewBox="0 0 34 34">
              <circle className="back" cx="17" cy="17" r="14"></circle>
              <circle className="front" cx="17" cy="17" r="14"></circle>
            </svg>
          </section>
          <section style={{ marginTop: "20px" }}>{this.props.text}</section>
        </main>
      </span>
    );
  }
}
const mapStateToProps = (state) => ({ data: state.data });
export default connect(mapStateToProps, {})(Spinner);
