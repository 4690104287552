/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { bbox } from "@turf/turf";
import writeXlsxFile from "write-excel-file";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  get_merch_newest,
  get_merch_by_date,
  get_merch,
} from "../../actions/propertiesActions";
import { turn_on_menu_status, turn_on_menu } from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL*/
import generate_array_color from "../../validation/generate_array_color";

/*NON IMPORT*/
const months = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
];
const years = [
  2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
];
const area_mode_list = [
  "NATIONAL",
  "DEPO",
  // "BRANCH", "REGIONAL"
];

class MERCH_MENU extends Component {
  state = {
    mode_view: "table",
    month: 1,
    year: 2022,
    date_mode: "by_month", //by_month ||
    filter_list: [],
    area_mode: "NATIONAL",
    depo_selected: "",
    loading: false,
  };

  componentDidMount() {
    const config = {
      color_mode: "REALISASI KELAS",
    };
    this.props.turn_on_menu({ key: "merch", config });
  }

  on_change = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "area_mode") {
      let depo_list = this.props.data.salesman_filter.depo;
      depo_list = depo_list.filter(
        (item) => item.folder_id === "63a4341e303d716c2f70bf04"
      );

      depo_list = depo_list.map((item) => {
        return {
          key: item.Depo,
          label: item.Depo,
          value: item.plan_id,
          name: "name",
        };
      });

      this.setState({
        filter_list: depo_list,
      });
    }
  };

  on_get_merch_by_date = () => {
    let depo_list = this.props.data.salesman_filter.depo;
    const { month, year, date_mode, area_mode, depo_selected } = this.state;
    const params = {
      month,
      year,
      date_mode,
      area_mode,
      depo_selected,
      depo_list,
    };

    this.props.get_merch(params);
  };

  on_change_color_mode = (e) => {
    const value = e.target.value;
    const config = {
      color_mode: value,
    };
    this.props.turn_on_menu({ key: "merch", config });
  };

  on_fly = () => {
    const { map } = this.props.map;
    const { outlet_merch_list_filter_0 } = this.props.data;
    const sidebarW = parseInt(localStorage.getItem("sidebar-width") || 500);
    let geojson = {
      type: "FeatureCollection",
      features: [],
    };
    if (outlet_merch_list_filter_0.length > 0) {
      const new_features = outlet_merch_list_filter_0.map((e) => {
        return {
          type: "Feature",
          properties: { ...e },
          geometry: e.outlet_id.location,
        };
      });
      geojson = {
        type: "FeatureCollection",
        features: [...new_features],
      };
      const [minLng, minLat, maxLng, maxLat] = bbox(geojson);
      map.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: { top: 200, bottom: 200, left: sidebarW + 50, right: 50 },
        }
      );
    }
  };

  set_mode_view = (mode_view) => {
    this.setState({ mode_view });
    localStorage.setItem("mode_view", mode_view);
  };

  downloadMerch = async () => {
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });
      try {
        const schema = [
          {
            column: "ID SFA",
            type: String,
            value: (param) => param.idSfa,
          },
          {
            column: "OUTLET NAME",
            type: String,
            value: (param) => param.outletName,
          },
          {
            column: "PLAN",
            type: String,
            value: (param) => param.plan,
          },
          {
            column: "CLASS TARGET",
            type: String,
            value: (param) => param.classTarget,
          },
          {
            column: "CLASS REAL",
            type: String,
            value: (param) => param.classReal,
          },
          {
            column: "OMSET",
            type: String,
            value: (param) => param.omset,
          },
          {
            column: "PROGRAM BUDGET",
            type: String,
            value: (param) => param.budgetProgram,
          },
          {
            column: "BUDGET-PER-OMSET",
            type: String,
            value: (param) => param.budgetPerOmset,
          },
          {
            column: "SYSTEM",
            type: String,
            value: (param) => param.system,
          },
          {
            column: "MONTH",
            type: String,
            value: (param) => param.month,
          },
          {
            column: "YEAR",
            type: String,
            value: (param) => param.year,
          },
        ];
        let newData = [];
        const { outlet_merch_list } = this.props.data;
        outlet_merch_list.forEach((e) => {
          let getData = {
            idSfa: `${e?.outlet_id?.["ID SFA"]}` ?? "-",
            outletName: `${e?.outlet_id?.["Nama Toko"]}` ?? "-",
            plan: `${e?.["Plan"]} ${e?.["depo_string"]}` ?? "-",
            classTarget: `${e?.["TARGET KELAS"]}` ?? "-",
            classReal: `${e?.["REALISASI KELAS"]}` ?? "-",
            omset: `${e?.["OMSET"]}` ?? "-",
            budgetProgram: `${e?.["BUDGET PROGRAM"]}` ?? "-",
            budgetPerOmset: `${e?.["RASIO BUDGET PER OMSET"]}` ?? "-",
            system: `${e?.outlet_id.SYSTEM}` ?? "-",
            month: `${months?.[e?.["NOMOR BULAN"] - 1]}` ?? "-",
            year: `${e?.["NOMOR TAHUN"]}` ?? "-",
          };
          newData.push(getData);
        });
        await writeXlsxFile(newData, {
          schema,
          fileName: "DataExcelMerchandising.xlsx",
        });
        this.setState({
          loading: false,
        });
      } catch (error) {
        console.log(error);
        this.setState({
          loading: false,
        });
      }
    }
  };

  render() {
    //local storage
    const mode_view = localStorage?.getItem("mode_view") || "table";

    //local state
    const { month, year, date_mode, filter_list, depo_selected, area_mode } =
      this.state;

    //global props
    const {
      loading_item,
      loading_status,
      class_array,
      omset_array,
      omset_color_array,
    } = this.props.properties;
    const { outlet_merch_list } = this.props.data;
    const color_mode = this.props.data.list_menu_active.find(
      (e) => e.key === "merch"
    )?.config?.color_mode;

    //content
    const loading_content = loading_status &&
      loading_item === "get_merch_newest" && (
        <section className="container_light">Loading...</section>
      );

    const color_array = generate_array_color(class_array);

    let final_content;

    const table_mode = (
      <table className="table">
        <thead>
          <tr>
            <th>NO</th>
            <th>ID SFA</th>
            <th>OUTLET NAME</th>
            <th>PLAN</th>
            <th>CLASS TARGET</th>
            <th>CLASS REAL</th>
            <th>OMSET</th>
            <th>PROGRAM BUDGET</th>
            <th>BUDGET-PER-OMSET</th>
            <th>SYSTEM</th>
            <th>MONTH</th>
            <th>YEAR</th>
          </tr>
        </thead>
        <tbody>
          {outlet_merch_list.map((item, idx) => {
            return (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td>{item?.outlet_id?.["ID SFA"]}</td>
                <td>{item?.outlet_id?.["Nama Toko"]}</td>
                <td>
                  {item?.["Plan"]}
                  <br />
                  {item?.["depo_string"]}
                </td>
                <td>{item?.["TARGET KELAS"]}</td>
                <td>{item?.["REALISASI KELAS"]}</td>
                <td>{item?.["OMSET"]}</td>
                <td>{item?.["BUDGET PROGRAM"]}</td>
                <td>{item?.["RASIO BUDGET PER OMSET"]}</td>
                <td>{item?.outlet_id.SYSTEM}</td>
                <td>
                  {item?.["NOMOR BULAN"]} ({months?.[item?.["NOMOR BULAN"] - 1]}
                  )
                </td>
                <td>{item?.["NOMOR TAHUN"]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );

    const card_mode = (
      <main>
        {outlet_merch_list.map((item, idx) => {
          return (
            <section key={idx} className="container_light mb-3">
              <table className="max_width">
                <tbody>
                  <tr>
                    {/* bagian kiri */}
                    <td>
                      <p
                        className="button_pill mb-1 mr-1"
                        id="green"
                        title="ID SFA"
                      >
                        {item?.outlet_id?.["ID SFA"]}
                      </p>
                      {item?.is_test && (
                        <p
                          className="button_pill mb-1"
                          id="orange"
                          title="Menunjukkan bahwa ini adalah data tes"
                        >
                          TEST
                        </p>
                      )}
                      <br />
                      <p className="text_bold" title="NAMA TOKO">
                        {item?.outlet_id?.["Nama Toko"]}
                      </p>
                    </td>
                    {/* bagian kanan */}
                    <td className="text_right">
                      <p
                        className="button_pill grid_item mr-2"
                        id="black_absolute"
                        title="TARGET KELAS"
                      >
                        {item?.["TARGET KELAS"]}
                      </p>
                      <div className="arrow_right grid_item mr-2" />
                      <p
                        className="button_pill grid_item"
                        id="black_absolute"
                        title="REALISASI KELAS"
                      >
                        {item?.["REALISASI KELAS"]}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table max_width">
                <tbody>
                  <tr>
                    {/* bagian kiri */}
                    <td>
                      <section className="mb-1">
                        <p className="text_inferior">QTY</p>
                        <p className="text_bold">
                          {new Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(parseInt(item?.["QTY"]))}
                        </p>
                      </section>
                      <hr />
                      <section className="mb-1">
                        <p className="text_inferior">OMSET/TURNOVER</p>
                        <p className="text_bold">
                          Rp.
                          {new Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(parseInt(item?.["OMSET"]))}
                        </p>
                      </section>
                      <hr />
                      <section className="mb-1">
                        <p className="text_inferior">
                          MERCHANDISING TIME PERIOD
                        </p>
                        <p className="text_bold">
                          {item?.["NOMOR BULAN"]} - {item?.["NOMOR TAHUN"]}
                        </p>
                      </section>
                    </td>
                    {/* bagian kanan */}
                    <td className="text_left">
                      <section className="mb-1">
                        <p className="text_inferior">PROGRAM BUDGETS</p>
                        <p className="text_bold">
                          Rp.
                          {new Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(parseInt(item?.["BUDGET PROGRAM"]))}
                        </p>
                      </section>
                      <hr />
                      <section className="mb-1">
                        <p className="text_inferior">BUDGET-PER-OMSET RATIO</p>
                        <p className="text_bold">
                          {item?.["RASIO BUDGET PER OMSET"]}
                        </p>
                      </section>
                      <hr />
                      <section className="mb-1">
                        <p className="text_inferior">SYSTEM</p>
                        <p className="text_bold">
                          {item?.outlet_id?.["SYSTEM"]}
                        </p>
                      </section>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          );
        })}
      </main>
    );

    final_content = mode_view === "table" ? table_mode : card_mode;

    let legend_content;

    if (color_mode === "REALISASI KELAS" || color_mode === "TARGET KELAS") {
      legend_content = class_array.map((item, idx) => {
        return (
          <tr key={idx}>
            <td>
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  backgroundColor: color_array[idx],
                }}
              />
            </td>
            <td>{item}</td>
          </tr>
        );
      });
    } else if (color_mode === "OMSET") {
      legend_content = omset_array.map((item, idx) => {
        let content = (
          <td>
            {item}-{omset_array?.[idx + 1] + 1}
          </td>
        );
        if (idx === omset_array.length - 1) {
          content = (
            <td>
              {">"}
              {item}
            </td>
          );
        }
        return (
          <tr key={idx}>
            <td>
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  backgroundColor: omset_color_array?.[idx + 1],
                }}
              />
            </td>
            {content}
          </tr>
        );
      });
    } else {
      legend_content = (
        <tr>
          <td>Color legend not available</td>
        </tr>
      );
    }

    return (
      <main>
        <section className="mb-5">
          <button className="button" id="green" onClick={this.on_fly}>
            Fly to
          </button>
        </section>

        <section className="container_light mb-5">
          <p className="text_bold">Query parameters</p>
          <br />
          <section className="mb-2">
            <p className="text_inferior">Select Depo/Branch/Region</p>
            <select
              name="area_mode"
              value={area_mode}
              onChange={this.on_change}
            >
              {area_mode_list.map((item, idx) => {
                return (
                  <option key={idx} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </section>

          {area_mode === "DEPO" ? (
            <section>
              <p className="text_inferior">Depo</p>
              <select
                onChange={this.on_change}
                value={depo_selected}
                name="depo_selected"
              >
                <option value="">Please select depo</option>
                {filter_list.map((item, idx) => {
                  return (
                    <option value={item.value} key={idx}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </section>
          ) : area_mode === "NATIONAL" ? (
            <section>ALL NATIONAL</section>
          ) : (
            <section>Coming soon</section>
          )}
          <br />
          <hr />
          <br />
          <p className="text_inferior">Query date mode</p>
          <select name="date_mode" value={date_mode} onChange={this.on_change}>
            <option value="by_month">MONTH/YEAR</option>
            <option value="is_newest">ALL NEWEST</option>
          </select>

          {date_mode === "by_month" && (
            <>
              <p className="text_inferior">Month</p>
              <select name="month" value={month} onChange={this.on_change}>
                {months.map((item, idx) => {
                  return (
                    <option key={idx} value={idx + 1}>
                      {item}
                    </option>
                  );
                })}
              </select>
              <p className="text_inferior">Year</p>
              <input
                name="year"
                type="number"
                value={year}
                list="years"
                onChange={this.on_change}
              />
              <datalist id="years">
                {years.map((item, idx) => {
                  return <option key={idx} value={item}></option>;
                })}
              </datalist>
            </>
          )}
          <br />
          <br />
          <hr />
          <br />
          {loading_status && loading_item === "get_merch_by_date" ? (
            <div className="button" id="grey">
              LOADING...
            </div>
          ) : (
            <button
              className="button"
              id="green"
              onClick={this.on_get_merch_by_date}
            >
              GET MERCH OUTLET DATA
            </button>
          )}
        </section>

        <section className="container_light mb-5">
          <p>Color mode:</p>
          <select
            onChange={this.on_change_color_mode}
            name="color_mode"
            id="color_mode"
          >
            <option value="TARGET KELAS">TARGET KELAS</option>
            <option value="REALISASI KELAS">REALISASI KELAS</option>
            <option value="OMSET">OMSET</option>
          </select>
          <table>
            <tbody>{legend_content}</tbody>
          </table>
        </section>

        <section className="mb-5">
          <button
            className="button mr-1 mb-1"
            id={mode_view === "table" ? "black_absolute" : "outline_black"}
            onClick={this.set_mode_view.bind(this, "table")}
          >
            Table
          </button>
          <button
            className="button"
            id={mode_view === "card" ? "black_absolute" : "outline_black"}
            onClick={this.set_mode_view.bind(this, "card")}
          >
            Card
          </button>
        </section>

        <button className="button_main mb-5" onClick={this.downloadMerch}>
          {this.state.loading ? "Loading ..." : "Download Merchandising"}
        </button>

        <table className="table">
          <tbody>
            <tr>
              <td>Total outlet</td>
              <td>
                <b>{outlet_merch_list.length}</b>
              </td>
            </tr>
          </tbody>
        </table>

        <br />

        {loading_content}
        {final_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties,
  data: state.data,
  map: state.map,
});

export default connect(mapStateToProps, {
  get_merch_newest,
  get_merch_by_date,
  turn_on_menu_status,
  turn_on_menu,
  get_merch,
})(MERCH_MENU);
