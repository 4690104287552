const sort_lat = function (array, isFromSmall) {
  if (isFromSmall) {
    function compare(a, b) {
      if (a?.location?.coordinates?.[1] < b?.location?.coordinates?.[1])
        return -1;
      if (a?.location?.coordinates?.[1] > b?.location?.coordinates?.[1])
        return 1;
      return 0;
    }
    return array.sort(compare);
  } else {
    function compare(a, b) {
      if (a?.location?.coordinates?.[1] < b?.location?.coordinates?.[1])
        return 1;
      if (a?.location?.coordinates?.[1] > b?.location?.coordinates?.[1])
        return -1;
      return 0;
    }
    return array.sort(compare);
  }
};
export default sort_lat;
