/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import STEP_5_CHOOSE_SALESMAN_COMPACT from "./STEP_5_CHOOSE_SALESMAN_COMPACT";
import Modal from "../common/Modal";

/*REDUX FUNCTION*/
import { searchSalesmanStep5 } from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class STEP_5_FIRST_SALESMAN extends Component {
  state = { modal_error: false, modal_choose_salesman: false };

  toggle_error = () => {
    this.setState({ modal_error: !this.state.modal_error });
    this.props.searchSalesmanStep5("");
  };

  toggle_choose_salesman = () => {
    this.setState({ modal_choose_salesman: !this.state.modal_choose_salesman });
    this.props.searchSalesmanStep5("");
  };

  render() {
    const { modal_choose_salesman, modal_error } = this.state;

    const {
      rayon_select,

      rayon_list_database,
      salesman_select,
    } = this.props.data;

    let salesman_first = salesman_select;
    let rayon_object = {};
    if (rayon_list_database.length > 0) {
      rayon_object = rayon_list_database.find((r) => r._id === rayon_select);
    }
    const cycle_list = rayon_object?.cycle_list ? rayon_object?.cycle_list : [];
    const plan_list = rayon_object?.plan_list ? rayon_object?.plan_list : [];

    const modal_error_content = modal_error && (
      <Modal modalSize="small" isOpen={modal_error} onClose={this.toggle_error}>
        <div className="box-body text_center">
          Please complete the previous step
        </div>
      </Modal>
    );

    return (
      <main className="container_outline">
        {/* <section className="main_container mb-3">
            <p className="text_inferior">Step 5</p>
            <p className="text_medium">Choose cycle</p>
            <hr />
            <p className="text_inferior">
              Choose one cycle at a time, then salesman rayonization will run
              one by one sequentially.
            </p>
            <select
              name="cycle_select"
              id="cycle_select"
              value={cycle_select}
              onChange={this.on_change}
              className="text_inferior block"
            >
              <option value="">Choose week cycle</option>
              {cycle_list.map((r, idx) => {
                return (
                  <option key={idx} value={r.name}>
                    {r.name}
                  </option>
                );
              })}
            </select>
            <section className="text_inferior">
              Note:
              <br />
              cycle 1 : odd week (M2C13)
              <br />
              cycle 2 : even week (M2C24)
            </section>
          </section> */}
        {modal_error_content}
        {cycle_list.length === 0 ? (
          <p className="text_inferior" id="red_text">
            Run cycle first
          </p>
        ) : plan_list.length > 0 ? (
          <p className="text_inferior" id="red_text">
            First salesman already saved on this rayon, skip to step 7
          </p>
        ) : (
          <p className="text_inferior">Allowed to continue</p>
        )}

        <section className="main_container mb-3">
          <p className="badge mb-1" id="blue">
            Step 5
          </p>
          <p className="text_medium">Choose first salesman to plan</p>
          <hr />
          {cycle_list.length === 0 ? (
            <button
              className="button mt-3"
              id="grey"
              onClick={this.toggle_error}
            >
              Choose
            </button>
          ) : plan_list.length > 0 ? (
            <button
              className="button mt-3"
              id="grey"
              onClick={this.toggle_error}
            >
              Choose
            </button>
          ) : (
            <button
              className="button mt-3"
              id="green"
              onClick={this.toggle_choose_salesman}
            >
              Choose
            </button>
          )}
          {modal_choose_salesman && (
            <STEP_5_CHOOSE_SALESMAN_COMPACT
              toggle_choose_salesman={this.toggle_choose_salesman}
            />
          )}
          {salesman_first?._id && (
            <table
              style={{ width: "100%" }}
              className="table_lite text_inferior mt-3"
            >
              <thead>
                <tr>
                  <th>First salesman to be planned</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>{salesman_first?.["Nama Only"]}</p>
                    <div>
                      {salesman_first?.divisi_arr?.map((d, idx) => (
                        <p key={idx} className="block">
                          {d}
                        </p>
                      ))}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </section>

        {!salesman_first?._id ? (
          <p className="text_inferior" id="red_text">
            Choose salesman first
          </p>
        ) : plan_list.length > 0 ? (
          <p className="text_inferior" id="red_text">
            Day rayonization on first salesman already saved, skip to step 7
          </p>
        ) : (
          <p className="text_inferior">
            Allowed to continue planning on:
            <br />
            <b>{salesman_first?.["Nama Only"]}</b>
          </p>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, { searchSalesmanStep5 })(
  STEP_5_FIRST_SALESMAN
);
