//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import readXlsxFile from "read-excel-file";
import writeXlsxFile from "write-excel-file";

//Personal Component
import Modal from "../common/Modal";
import ProgressBar from "../common/ProgressBar";

//Redux function
import {
  add_outlet_bulk,
  set_list,
  edit_outlet_bulk,
} from "../../actions/dataActions";
import {
  set_value_properties,
  get_outlet_by_sfa,
} from "../../actions/propertiesActions";

//Picture Asset

//General Function
const template_csv = [
  [
    "DETECTED LOCATION",
    "_id",
    "ID SFA",
    "PLAN",
    "ID OUTLET",
    "DEPO",
    "STATUS DEPO",
    "SYSTEM",
    "NAMA OUTLET",
    "ALAMAT 1",
    "ALAMAT 2",
    "NO TELP",
    "TYPE OUTLET",
    "SEGMENT",
    "KELURAHAN",
    "KECAMATAN",
    "KABUPATEN",
    "PROPINSI",
    "FLAG DISTRICT",
    "KET UPDATE",
    "KDPOS",
    "STATUS OUTLET",
    "ID BRANCH",
    "COUNTRY",
    "STATUS KOORDINAT",
    "KODE PMA",
    "KODE UNIK",
    "DEPO ID OUTLET",
    "PLAN ID OUTLET",
    "OMSET",
    "CP",
  ],
  [
    "-6.33209436,107.34958763",
    "12321321321312",
    "62C3001054",
    "P138",
    "C3001054",
    "PALEMBANG ILIR",
    "CB",
    "SAP S/4Hana",
    "TK. YULI 32",
    "JL.PERUM BCL KOSAMBI",
    "",
    "6285784758",
    "RT-S",
    "SEGMENT",
    "WONOSARI",
    "KEC KEBUMEN",
    "KAB KEBUMEN",
    "JAWA TENGAH",
    "ADA",
    "UPDATE 1-H",
    "42117",
    "NOO JULY",
    "366",
    "INDONESIA",
    "ADA KOORDINAT",
    "1000713",
    "72796520",
    "CIREBON KOTA796520",
    "P178796520",
    1000000,
    "WAHYU",
  ],
  [
    "",
    "12321321321312",
    "26169885",
    "P301",
    "69885",
    "AMBON",
    "DP",
    "SCYLLA",
    "KS REHAN, KAITETU",
    "JL. PASAR K. BELAKANG LIMBUR DEPAN BAKSO KAYAH",
    "",
    "6285784758",
    "RT-M",
    "SEGMENT",
    "",
    "",
    "",
    "MALUKU",
    "NO",
    "UPDATE 19",
    "42117",
    "NOO JULY",
    "75",
    "",
    "NO KOORDINAT",
    "1000713",
    "72796520",
    "CIREBON KOTA796518",
    "P178796518",
    2000000,
    "NISA",
  ],
];

class UploadAddToko extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content_render: null,
    };
  }

  componentDidMount() {
    this.props.set_list({ text: "current_array_add", list: [] });
    this.props.set_value_properties({
      key: "sfa_list",
      value: [],
    });
    this.render_data();
  }

  componentDidUpdate(prev_props) {
    const outlet_upload_changes_after =
      this.props.properties.outlet_upload_changes;
    const outlet_upload_changes_prev =
      prev_props.properties.outlet_upload_changes;

    if (outlet_upload_changes_after !== outlet_upload_changes_prev) {
      this.render_data();
    }
  }

  componentWillUnmount() {
    this.on_reset();
    this.props.set_value_properties({ key: "mode_download", value: "" });
  }

  render_data = () => {
    //local storage

    //local state

    //global props
    const { loading_status, loading_item, current_array_add } = this.props.data;
    const mode = this.props.mode;
    let {
      progress_get_id_sfa,
      total_get_id_sfa,
      file_header,
      file_body,
      is_checked,

      mode_competitor,
      mode_download,
      loadingExcel,
      sfa_list,
      outlet_new,
      outlet_edit,
      is_sfa_checked,

      progress_outlet_new,
      progress_outlet_edit,
      total_check_id_sfa,

      current_array_edit,
      success_array,
      not_found_array,
      error_array,

      add_outlet_finish_status,
      edit_outlet_finish_status,
      modal_error_edit_outlet,
    } = this.props.properties;

    const loading_status_properties = this.props.properties.loading_status;
    const loading_item_properties = this.props.properties.loading_item;

    //content

    const modal_error_edit_outlet_content = modal_error_edit_outlet && (
      <Modal
        modalSize="large"
        isOpen={modal_error_edit_outlet}
        onClose={this.toggle_error_edit_outlet}
      >
        <div className="box-body" id="box-body">
          <main style={{ minHeight: "100vh" }}>
            <h1 className="text_header mb-5">Error details</h1>
            <CSVLink
              className="button mb-3"
              id="green"
              data={error_array}
              filename="ERROR OUTLET.csv"
              separator=";"
            >
              Download error as CSV
            </CSVLink>
            <table className="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>ID SFA</th>
                  <th>Error message</th>
                </tr>
              </thead>
              <tbody>
                {error_array.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{item?.["ID SFA"]}</td>
                      <td>{item?.status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </main>
        </div>
      </Modal>
    );

    if (mode === "data") {
      file_header = this.props.file_header;
      file_body = this.props.file_body;
    }

    const syarat_header = template_csv[0];

    const content_render = (
      <main style={{ marginBottom: "100px" }}>
        {modal_error_edit_outlet_content}
        <h1 className="text_header mb-5">UPLOAD OUTLET (ADD/EDIT)</h1>
        <p className="badge mb-1" id="blue">
          Step 1
        </p>
        <section className="container_light mb-5">
          <p className="text_bold">Select parameters</p>
          <br />
          <br />
          <p className="text_bold">Select Mode Outlet</p>
          <select
            name="mode_competitor"
            id="mode_competitor"
            value={mode_competitor}
            onChange={this.on_change}
          >
            <option value="nabati">Nabati Outlet</option>
            <option value="competitor">Competitor Outlet </option>
          </select>
          <br />
          <p className="text_bold">Select Mode Upload</p>
          <select
            name="mode_download"
            id="mode_download"
            value={mode_download}
            onChange={this.on_change_download}
          >
            <option value="pilih">Select</option>
            <option value="csv">CSV</option>
            <option value="excel">EXCEL</option>
          </select>
        </section>

        {(mode_download === "excel" || mode_download === "csv") && (
          <>
            {mode !== "data" && (
              <React.Fragment>
                <p className="badge mb-1" id="blue">
                  Step 2
                </p>
                <section className="container_light mb-5">
                  {mode_download === "excel" ? (
                    <p className="text_bold">Select EXCEL from file</p>
                  ) : (
                    <p className="text_bold">Select CSV from file</p>
                  )}
                  {mode_download === "excel" ? (
                    <React.Fragment>
                      <div className="border p-4 my-4 bg-yellow-100 rounded">
                        Warning! for using this feature please follow the
                        instructions according to the downloadable template
                        EXCEL{" "}
                        <button
                          className="text-blue-600"
                          onClick={this.downloadExampleExcel}
                        >
                          here
                        </button>
                      </div>
                      <div className="grid grid-cols-1 gap-1">
                        <label htmlFor="file">Select EXCEL file</label>
                        <input
                          onChange={this.handleFileExcel}
                          type="file"
                          id="input"
                          className="w-fit"
                        />
                        {loadingExcel && (
                          <p
                            className="text_bold bg-[#51B13D] rounded w-1/5 text-white text-center
                    "
                          >
                            Loading Data...
                          </p>
                        )}
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <section className="border p-4 my-4 bg-yellow-100 rounded">
                        Warning! for using this feature please follow the
                        instructions according to the downloadable template CSV{" "}
                        <CSVLink
                          className="text-blue-600"
                          data={template_csv}
                          filename="ADD OUTLET.csv"
                          separator=";"
                        >
                          here
                        </CSVLink>
                      </section>
                      <section className="grid grid-cols-1 gap-1">
                        <label htmlFor="file">Select CSV file</label>
                        <input
                          type="file"
                          id="file"
                          className="w-fit"
                          accept=".csv"
                          onChange={this.handleFileCSV}
                        />
                      </section>
                    </React.Fragment>
                  )}
                </section>
              </React.Fragment>
            )}

            <section className="mb-5">
              <p>
                Number of outlets:{" "}
                <b>
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(file_body.length)}
                </b>
              </p>
              {file_body.length !== 0 && (
                <main>
                  <p className="text_bold mt-5">Data Verification</p>
                  <table className="table">
                    <tbody>
                      {syarat_header.map((e, idx) => {
                        let status = (
                          <td
                            id="green"
                            style={{ width: "70px", textAlign: "center" }}
                          >
                            V
                          </td>
                        );
                        if (!file_header.includes(e))
                          status = (
                            <td
                              id="red"
                              style={{ width: "70px", textAlign: "center" }}
                            >
                              X
                            </td>
                          );
                        return (
                          <tr key={idx}>
                            <td>{e}</td>
                            {status}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </main>
              )}
            </section>

            <p className="badge mb-1" id="blue">
              Step 3
            </p>
            <section className="container_light mb-5">
              <p className="text_bold">
                Check if there is already outlets in the database, to prevent
                double data, query will be done by ID SFA
              </p>

              <ProgressBar
                current_number={progress_get_id_sfa}
                total_number={total_get_id_sfa}
                name="Check ID SFA"
              />

              {loading_status_properties &&
              loading_item_properties === "get_outlet_by_sfa" ? (
                <button className="button mt-5" id="green">
                  Checking SFA ID is in progress...
                </button>
              ) : (
                <button
                  className="button mt-5"
                  id="green"
                  onClick={this.on_get_outlet_by_sfa}
                >
                  Check
                </button>
              )}
            </section>

            <p className="badge mb-1" id="blue">
              Step 4
            </p>
            <section className="container_light mb-5">
              <p className="text_bold">Add new outlets</p>

              <table className="table">
                <tbody>
                  <tr>
                    <td>Number of outlets to add</td>
                    <td>
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(outlet_new.length)}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              {loading_status_properties &&
              loading_item_properties === "get_outlet_by_sfa" ? (
                <section>
                  <ProgressBar
                    current_number={progress_outlet_new}
                    total_number={total_check_id_sfa}
                    name="Separation of outlets to be added"
                  />
                  <div className="badge" id="green">
                    Separation between outlets to edit and add is in progress...
                  </div>
                </section>
              ) : is_sfa_checked ? (
                <>
                  <ProgressBar
                    current_number={progress_outlet_new}
                    total_number={total_check_id_sfa}
                    name="Separation of outlets to be added"
                  />
                  <div className="badge" id="green">
                    SFA ID check complete
                  </div>
                </>
              ) : (
                <div className="badge" id="green">
                  -
                </div>
              )}
              <br />
              <br />

              {is_checked && outlet_new.length === 0 && is_sfa_checked && (
                <p className="text_inferior">
                  There is no new outlet data in this file, please make edits
                  directly in step 5
                </p>
              )}

              {is_checked && outlet_new.length > 0 && is_sfa_checked && (
                <section>
                  <ProgressBar
                    current_number={current_array_add.length}
                    total_number={outlet_new.length}
                    name="Progress add new outlets"
                  />
                  {loading_status && loading_item === "add_outlet_bulk" ? (
                    <div className="button" id="grey">
                      UPLOADING...
                    </div>
                  ) : add_outlet_finish_status ? (
                    <div className="button" id="blue">
                      DONE
                    </div>
                  ) : (
                    <button
                      className="button"
                      id="green"
                      onClick={this.on_add_outlet_bulk}
                    >
                      START ADD
                    </button>
                  )}
                </section>
              )}
            </section>

            <p className="badge mb-1" id="blue">
              Step 5
            </p>
            <section className="container_light mb-5">
              <p className="text_bold">Edit existing outlets</p>

              <table className="table">
                <tbody>
                  <tr>
                    <td>Number of outlets to edit</td>
                    <td>
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(outlet_edit.length)}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              {loading_status_properties &&
              loading_item_properties === "get_outlet_by_sfa" ? (
                <section>
                  <ProgressBar
                    current_number={progress_outlet_edit}
                    total_number={total_check_id_sfa}
                    name="Separation of outlets to be edited"
                  />
                  <div className="badge" id="green">
                    Separation between outlets to edit and add is in progress...
                  </div>
                </section>
              ) : is_sfa_checked ? (
                <>
                  <ProgressBar
                    current_number={progress_outlet_edit}
                    total_number={total_check_id_sfa}
                    name="Separation of outlets to be edited"
                  />
                  <div className="badge" id="green">
                    SFA ID check complete
                  </div>
                </>
              ) : (
                <div className="badge" id="green">
                  -
                </div>
              )}
              <br />
              <br />
              {!is_checked && is_sfa_checked ? (
                <button className="text_inferior">
                  Check the SFA ID in step 3 first
                </button>
              ) : sfa_list.length > 0 && is_sfa_checked ? (
                <>
                  <ProgressBar
                    current_number={current_array_edit.length}
                    total_number={outlet_edit.length}
                    name="Progress edit outlets"
                  />
                  {loading_status && loading_item === "edit_outlet_bulk" ? (
                    <div className="button" id="grey">
                      UPLOADING...
                    </div>
                  ) : edit_outlet_finish_status ? (
                    <div className="button" id="blue">
                      DONE
                    </div>
                  ) : (
                    <button
                      className="button"
                      id="green"
                      onClick={this.on_edit_outlet_bulk}
                    >
                      START EDIT
                    </button>
                  )}
                </>
              ) : is_sfa_checked ? (
                <>
                  <p className="text_inferior">
                    There are no existing outlets in the database, no editing
                    steps are required.
                  </p>
                </>
              ) : (
                <></>
              )}

              <table className="table mt-5">
                <tbody>
                  <tr>
                    <td>Success</td>
                    <td>{success_array.length}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Not found</td>
                    <td>{not_found_array.length}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Error</td>
                    <td>{error_array.length}</td>
                    <td>
                      <button
                        className="button"
                        id="red"
                        onClick={this.toggle_error_edit_outlet}
                      >
                        Detail
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </>
        )}
      </main>
    );

    this.setState({ content_render });
  };

  toggle_error_edit_outlet = () => {
    this.props.set_value_properties({
      key: "modal_error_edit_outlet",
      value: !this.props.properties.modal_error_edit_outlet,
    });
  };

  on_get_outlet_by_sfa = () => {
    let sfa_array = this.props.properties.file_body
      .map((t) => t["ID SFA"])
      .filter((t) => t);
    const file_body = this.props.properties.file_body;
    if (sfa_array.length > 0) {
      const body = { sfa_array, file_body };
      this.props.get_outlet_by_sfa(body);
    }
    this.props.set_value_properties({ key: "is_checked", value: true });
  };

  on_add_outlet_bulk = () => {
    const { outlet_new } = this.props.properties;
    this.props.add_outlet_bulk(outlet_new);
  };

  on_edit_outlet_bulk = () => {
    let { outlet_edit } = this.props.properties;
    const body = {
      outlet_array: outlet_edit,
    };
    this.props.edit_outlet_bulk(body);
  };

  on_reset = () => {
    this.props.set_list({ text: "current_array_add", list: [] });

    this.props.set_value_properties({ key: "file_header", value: [] });
    this.props.set_value_properties({ key: "file_body", value: [] });
    this.props.set_value_properties({ key: "header", value: [] });
    this.props.set_value_properties({ key: "dataEx", value: [] });
    this.props.set_value_properties({ key: "is_checked", value: false });
    this.props.set_value_properties({ key: "progress_get_id_sfa", value: 0 });
    this.props.set_value_properties({ key: "total_get_id_sfa", value: 0 });
    this.props.set_value_properties({ key: "is_sfa_checked", value: false });
    this.props.set_value_properties({
      key: "add_outlet_finish_status",
      value: false,
    });
    this.props.set_value_properties({
      key: "edit_outlet_finish_status",
      value: false,
    });
  };

  handleFileCSV = async (event) => {
    this.props.set_value_properties({ key: "is_checked", value: false });

    this.props.set_list({ text: "current_array_add", list: [] });

    const { mode_competitor } = this.props.properties;
    this.on_reset();
    try {
      const file = event.target.files[0];
      const result = () => {
        return new Promise((resolve, reject) => {
          Papa.parse(file, {
            complete: function (results) {
              resolve(results);
            },
            error: function (err) {
              reject(err);
            },
          });
        });
      };
      const final_result = await result();
      let file_header = final_result.data.shift();
      const file_body = final_result.data;
      let arrOfObject = [];
      file_body.forEach((element) => {
        let obj = {};
        file_header.forEach((el, idx) => {
          const header = el.replace(/\s*$/, "");
          let value = element[idx];
          if (value === "#N/A") {
            value = "";
          }
          if (header === "KONTAK HP")
            value = element?.[idx]?.replace(/\s/g, "");
          obj[header] = value;
        });
        if (mode_competitor === "competitor")
          obj["STATUS OUTLET"] = "competitor";
        if (file_header.length === element.length) {
          arrOfObject.push(obj);
        }
      });
      this.props.set_value_properties({
        key: "file_header",
        value: file_header,
      });
      this.props.set_value_properties({
        key: "file_body",
        value: arrOfObject,
      });
    } catch (error) {
      alert("Error process file!");
    }
  };

  handleFileExcel = async (event) => {
    this.props.set_value_properties({
      key: "loadingExcel",
      value: true,
    });
    this.props.set_list({ text: "current_array_add", list: [] });
    this.props.set_value_properties({
      key: "is_checked",
      value: false,
    });

    const { mode_competitor } = this.props.properties;
    this.on_reset();
    try {
      readXlsxFile(event.target.files[0]).then((rows) => {
        const HEADER = rows.shift();
        const DATA = [];
        rows.forEach((element) => {
          let obj = {};
          HEADER.forEach((el, idx) => {
            const header = el.replace(/\s*$/, "");
            let value = element[idx];
            const value_test = String(value);
            if (value_test?.includes("#N/A") || !value_test || !value) {
              value = "";
            }
            if (header === "KONTAK HP")
              value = element?.[idx]?.replace(/\s/g, "");
            obj[header] = String(value);
          });
          if (mode_competitor === "competitor")
            obj["STATUS OUTLET"] = "competitor";
          if (HEADER.length === element.length) {
            DATA.push(obj);
          }
        });
        this.props.set_value_properties({ key: "file_header", value: HEADER });
        this.props.set_value_properties({ key: "file_body", value: DATA });
        this.props.set_value_properties({ key: "loadingExcel", value: false });
      });
    } catch (err) {
      alert("Error process file!");
    }
  };

  on_change = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.props.set_value_properties({ key: name, value: value });
  };

  on_change_download = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    let sfa_array = this.props.properties.file_body
      .map((t) => t["ID SFA"])
      .filter((t) => t);
    if (sfa_array.length > 0) {
      const body = {};
      this.props.get_outlet_by_sfa(body);
    }
    this.props.set_value_properties({
      key: name,
      value: value,
    });
    this.props.set_value_properties({
      key: "file_body",
      value: [],
    });
    this.props.set_value_properties({
      key: "file_header",
      value: [],
    });
    this.props.set_value_properties({
      key: "is_checked",
      value: false,
    });
  };

  downloadExampleExcel = async () => {
    if (!this.props.properties.loading_csv) {
      this.props.set_value_properties({
        key: "loading_csv",
        value: true,
      });
      try {
        const schema = [
          {
            column: "DETECTED LOCATION",
            type: String,
            value: (target) => target.detect,
          },
          {
            column: "_id",
            type: String,
            value: (target) => target.id,
          },
          {
            column: "ID SFA",
            type: String,
            value: (target) => target.sfa,
          },
          {
            column: "PLAN",
            type: String,
            value: (target) => target.plan,
          },
          {
            column: "ID OUTLET",
            type: String,
            value: (target) => target.idOutlet,
          },
          {
            column: "DEPO",
            type: String,
            value: (target) => target.depo,
          },
          {
            column: "STATUS DEPO",
            type: String,
            value: (target) => target.statusDepo,
          },
          {
            column: "SYSTEM",
            type: String,
            value: (target) => target.system,
          },
          {
            column: "NAMA OUTLET",
            type: String,
            value: (target) => target.namaOutlet,
          },
          {
            column: "ALAMAT 1",
            type: String,
            value: (target) => target.alamat1,
          },
          {
            column: "ALAMAT 2",
            type: String,
            value: (target) => target.alamat2,
          },
          {
            column: "NO TELP",
            type: String,
            value: (target) => target.notelp,
          },
          {
            column: "TYPE OUTLET",
            type: String,
            value: (target) => target.typeOutlet,
          },
          {
            column: "SEGMENT",
            type: String,
            value: (target) => target.segment,
          },
          {
            column: "KELURAHAN",
            type: String,
            value: (target) => target.kelurahan,
          },
          {
            column: "KECAMATAN",
            type: String,
            value: (target) => target.kecamatan,
          },
          {
            column: "KABUPATEN",
            type: String,
            value: (target) => target.kabupaten,
          },
          {
            column: "PROPINSI",
            type: String,
            value: (target) => target.propinsi,
          },
          {
            column: "FLAG DISTRICT",
            type: String,
            value: (target) => target.flagDistrict,
          },
          {
            column: "KET UPDATE",
            type: String,
            value: (target) => target.ketUpdate,
          },
          {
            column: "KDPOS",
            type: String,
            value: (target) => target.kdpos,
          },
          {
            column: "STATUS OUTLET",
            type: String,
            value: (target) => target.statusOutlet,
          },
          {
            column: "ID BRANCH",
            type: String,
            value: (target) => target.idBranch,
          },
          {
            column: "COUNTRY",
            type: String,
            value: (target) => target.country,
          },
          {
            column: "STATUS KOORDINAT",
            type: String,
            value: (target) => target.statusKoordinate,
          },
          {
            column: "KODE PMA",
            type: String,
            value: (target) => target.kodePma,
          },
          {
            column: "KODE UNIK",
            type: String,
            value: (target) => target.kodeUnik,
          },
          {
            column: "DEPO ID OUTLET",
            type: String,
            value: (target) => target.depoIdOutlet,
          },
          {
            column: "PLAN ID OUTLET",
            type: String,
            value: (target) => target.planIdOutlet,
          },
          {
            column: "OMSET",
            type: Number,
            value: (target) => target.OMSET,
          },
          {
            column: "CP",
            type: String,
            value: (target) => target.CP,
          },
        ];
        let newData = [
          {
            detect: "-6.33209436,107.34958763",
            _id: "5ca721e3374b736327a17d3f",
            sfa: "62C3001054",
            plan: "P138",
            idOutlet: "C3001054",
            depo: "PALEMBANG ILIR",
            statusDepo: "CB",
            system: "SAP S/4Hana",
            namaOutlet: "TK. YULI 32",
            alamat1: "JL.PERUM BCL KOSAMBI",
            alamat2: "",
            notelp: "6285784758",
            typeOutlet: "RT-S",
            segment: "TEST",
            kelurahan: "WONOSARI",
            kecamatan: "KEC KEBUMEN",
            kabupaten: "KAB KEBUMEN",
            propinsi: "JAWA TENGAH",
            flagDistrict: "ADA",
            ketUpdate: "UPDATE 1-H",
            kdpos: "42117",
            statusOutlet: "NOO JULY",
            idBranch: "366",
            country: "INDONESIA",
            statusKoordinate: "ADA KOORDINAT",
            kodePma: "1000713",
            kodeUnik: "72796520",
            depoIdOutlet: "CIREBON KOTA796520",
            planIdOutlet: "P178796520",
            OMSET: 1000000,
            CP: "WAHYU",
          },
        ];
        await writeXlsxFile(newData, {
          schema,
          fileName: "ExampleUploadOutlet.xlsx",
        });

        this.props.set_value_properties({
          key: "loading_csv",
          value: false,
        });
      } catch (error) {}
    }
  };

  render() {
    const { content_render } = this.state;
    return content_render;
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  add_outlet_bulk,
  set_list,
  get_outlet_by_sfa,
  set_value_properties,
  edit_outlet_bulk,
})(UploadAddToko);
