//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";
//Personal Component
//Redux function
//Picture Asset
//General Function
class TokoPlotLayer extends Component {
  render() {
    const is_visible = this.props.data.list_menu_active.find(
      (e) => e.key === "toko_plot"
    )?.active;
    const { toko_plotting, outlet_mode } = this.props.data;
    let geojson = {
      type: "FeatureCollection",
      features: [],
    };
    if (toko_plotting.length > 0) {
      const new_features = toko_plotting.map((e) => {
        return {
          type: "Feature",
          properties: { ...e },
          geometry: e.location,
        };
      });
      geojson = {
        type: "FeatureCollection",
        features: [...new_features],
      };
    }
    return (
      toko_plotting.length > 0 && (
        <React.Fragment>
          {outlet_mode === "cluster" ? (
            <main>
              <Source
                key="toko_plot_cluster_src"
                id="toko_plot_cluster_src"
                type="geojson"
                data={geojson}
                generateId={true}
                cluster={true}
                clusterMaxZoom={14}
                clusterRadius={50}
              />
              <Layer
                id="toko_plot_cluster"
                key="toko_plot_cluster"
                source="toko_plot_cluster_src"
                type="circle"
                paint={{
                  "circle-color": [
                    "step",
                    ["get", "point_count"],
                    "#51bbd6",
                    100,
                    "#f1f075",
                    750,
                    "#f28cb1",
                  ],
                  "circle-radius": [
                    "step",
                    ["get", "point_count"],
                    20,
                    100,
                    30,
                    750,
                    40,
                  ],
                }}
              />
              <Layer
                id="cluster_count_toko_plot"
                type="symbol"
                source="toko_plot_cluster_src"
                filter={["has", "point_count"]}
                layout={{
                  "text-field": "{point_count_abbreviated}",
                  "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                  "text-size": 12,
                }}
              />
              <Layer
                id="uncluster_count_toko_plot"
                type="circle"
                source="toko_plot_cluster_src"
                filter={["!", ["has", "point_count"]]}
                paint={{
                  "circle-color": "#11b4da",
                  "circle-radius": 4,
                  "circle-stroke-width": 1,
                  "circle-stroke-color": "#fff",
                }}
              />
            </main>
          ) : (
            <main>
              <Source
                key={"toko_plot-src"}
                id="toko_plot-src"
                type="geojson"
                data={geojson}
              />
              <Layer
                id="toko_plot"
                key="toko_plot"
                type="circle"
                source={"toko_plot-src"}
                layout={{
                  visibility: is_visible ? "visible" : "none",
                }}
                paint={{
                  "circle-color": "#f8b324",
                  "circle-radius": 5,
                  "circle-stroke-width": 1,
                  "circle-stroke-color": "black",
                  "circle-opacity": 1,
                }}
                onLeave={this.onLeave}
                onClick={this.props.layerOnClick.bind(this, "modal_plotting")}
              />
            </main>
          )}
        </React.Fragment>
      )
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, {})(TokoPlotLayer);
