import sort_lat from "./sort_lat";
import sort_long from "./sort_long";
import st_colors from "./st_colors";
import generate_v_max from "./generate_v_max";

const parameter_selesai = 1; //parameter pembagian antara omset temporary looping dan omset yang diharapkan, jika 1 maka sempurna, jika mendekati 1 berarti mendekati sempurna
//jarang akan === 1 sehingga parameternya akan diubah2 sesuai kebutuhan

const rayonisasi_rayon_box_by_value = function (body) {
  const {
    // array toko list yang kan dirayonisasi
    toko_list_main,
    //list depo yang terlibat dalam rayonisasi
    depo_loc_list,
    //ratio = rasio dari bentuk bbox dari array toko
    ratio,

    mode_nama_rayon,
    nama_rayon_custom,
  } = body;

  let imbuhan = "_Rayon_";
  if (mode_nama_rayon === "custom") {
    imbuhan = `_${nama_rayon_custom}_`;
  }

  /*
  mode_first mode pembagian mau horisontal dulu kemudian vertikal atau vertikal dulu kemudian horisontal
  v_first : vertical first
  h_first : horisontal first
  */

  //masukan ke index ke atribut index karena ketika toko difilter berdasarkan depo indexnya akan berubah
  let toko_list_new = toko_list_main.map((t, idx) => {
    t.index = idx;
    t.rayon = "";
    t.rayon_id = "";
    return t;
  });
  let rayon_list = [];
  let rayon_list_complete = [];

  depo_loc_list.forEach((d) => {
    for (let index = 0; index < d.jumlah_rayon; index++) {
      const element = `${d.value}${imbuhan}${index + 1}`;
      const object = {
        name: element,
        depo_id: d._id,
        depo_string: d.value,
        date_created: Date.now(),
        date_updated: Date.now(),
      };
      rayon_list.push(element);
      rayon_list_complete.push(object);
    }
  });

  //looping algoritma untuk tiap depo (karena bisa multi depo)
  depo_loc_list.forEach((d) => {
    const total_outlet = parseInt(d.total_outlet);
    const jumlah_rayon = parseInt(d.jumlah_rayon);
    const avg_value_rayon = parseInt(d.avg_value_rayon);
    const total_value = parseInt(d.total_value);

    //hanya mengambil toko pada depo yang sedang diloop

    // let toko_list = toko_list_new.filter((t) => t.Depo === d.value);

    let toko_list = toko_list_new.filter((t) => t);

    /*STEP 1 Deklarasi variabel penting*/

    //index_loop_rayon: parameter index untuk looping rayon secara keseluruhan, jika index_loop_rayon sudah === maka keluar dari looping

    let index_loop_rayon = 0;
    const v_max = generate_v_max(jumlah_rayon, ratio);

    //pengali: pengali untuk setiap v_index
    const pengali = Math.floor(jumlah_rayon / v_max);

    //mod : sisa pembagian dari 3 untuk menentukan v_index mana yang akan dilebihkan 1 pengali nya
    const mod = jumlah_rayon % v_max;

    //vertical_range : array looping pertama, untuk kasus ini akan dilooping vertikal dulu
    let vertical_range = [];

    /*STEP 2 Looping untuk generate pengali_n (bagian dari total toko) untuk masing baris vertikal*/
    for (let n = 0; n < v_max; n++) {
      //pengali_n : bagian untuk masing-masing baris vertikal, misal jika jumlah_rayon = 3 , maka [1/3, 1/3, 1/3]
      const pengali_n =
        mod >= n + 1
          ? (1 / jumlah_rayon) * (pengali + 1)
          : (1 / jumlah_rayon) * pengali;

      //jumlah_omset_v : jumlah omset untuk masing-masing baris vertikal, misal
      const jumlah_omset_v = Math.floor(pengali_n * total_value);

      //jumlah_rayon_horisontal : berapa rayon yang dibagi secara horisontal pada suatu baris vertikal
      const jumlah_rayon_horisontal = mod >= n + 1 ? pengali + 1 : pengali;

      vertical_range.push({
        pengali_n,
        jumlah_rayon_horisontal,
        jumlah_omset_v,
      });
    }

    //STEP 4 Urutkan toko_list berdasarkan latitude karena setelah ini akan dikelompokan secara vertikal
    toko_list = sort_lat(toko_list, false);

    /*STEP 3 Modifikasi vertical_range untuk mendapatkan range start & end dari baris vertikal, membutuhkan map dari vertical_range original karena membutuhkan nilai dari elemen sebelumnya*/
    vertical_range = vertical_range.map(
      ({ jumlah_omset_v, jumlah_rayon_horisontal }, idx) => {
        /*
        start: index awal dari range pembagian vertikal, end: index akhir dari range pembagian vertikal

        start_omset: sum omset awal dari range pembagian vertikal, end_omset: sum omset akhir dari range pembagian vertikal
         */
        let start_omset, end_omset;

        if (idx === 0) {
          start_omset = 0;
        } else {
          //sum omset dari semua toko sebelum index vertikal yang sedang looping
          start_omset = vertical_range
            .slice(0, idx)
            .map((r) => r.jumlah_omset_v)
            .reduce((a, b) => a + b, 0);
        }

        if (idx === v_max - 1) {
          end_omset = total_value;
        } else {
          end_omset = start_omset + jumlah_omset_v;
        }

        //konversi dari start_omset menjadi start_idx
        let start_omset_temporary = 0;
        let start_idx = 0;

        if (idx === 0) {
          start_idx = 0;
        } else {
          for (
            let index = 0;
            start_omset_temporary / start_omset < parameter_selesai;
            index++
          ) {
            //save index ke start_idx
            start_idx = index;
            //slice toko yang sudah urut dengan index
            start_omset_temporary = toko_list
              .slice(0, index)
              .map((t) => t.value);
            //sum omsetnya
            start_omset_temporary = start_omset_temporary.reduce(
              (a, b) => a + b,
              0
            );
          }
        }

        //konversi dari end_omset menjadi end_idx
        let end_omset_temporary = 0;
        let end_idx = 0;

        if (idx === v_max - 1) {
          end_idx = total_outlet;
        } else {
          for (
            let index = 0;
            end_omset_temporary / end_omset < parameter_selesai;
            index++
          ) {
            //save index ke start_idx
            end_idx = index;
            //slice toko yang sudah urut dengan index
            end_omset_temporary = toko_list.slice(0, index).map((t) => t.value);
            //sum omsetnya
            end_omset_temporary = end_omset_temporary.reduce(
              (a, b) => a + b,
              0
            );
          }
        }
        return {
          start_omset,
          end_omset,

          start_idx,
          end_idx,

          jumlah_rayon_horisontal,
        };
      }
    );

    vertical_range = vertical_range.map(
      (
        { jumlah_omset_v, jumlah_rayon_horisontal, start_idx, end_idx },
        idx
      ) => {
        let toko_list_v = toko_list.slice(start_idx, end_idx);

        /*STEP XX urutkan toko_list secara longitude karena setelah ini akan dikelompokan secara horisontal*/
        toko_list_v = sort_long(toko_list_v, true);

        //horisontal_range: array range untuk pembagian horisonal, isinya start & end dari rayon yang akan dibuat
        let horisontal_range = [];

        for (let h = 0; h < jumlah_rayon_horisontal; h++) {
          let start_h_omset, end_h_omset;
          start_h_omset = parseInt(h * avg_value_rayon);
          end_h_omset = parseInt(start_h_omset + avg_value_rayon);

          //konversi dari start_h_omset menjadi start_h_idx
          let start_h_omset_temporary = 0;
          let start_h_idx = 0;

          if (h === 0) {
            start_h_idx = 0;
          } else {
            for (
              let index = 0;
              start_h_omset_temporary / start_h_omset < parameter_selesai;
              index++
            ) {
              //save index ke start_h_idx
              start_h_idx = index;
              //slice toko yang sudah urut dengan index
              start_h_omset_temporary = toko_list_v
                .slice(0, index)
                .map((t) => t.value);
              //sum omsetnya
              start_h_omset_temporary = start_h_omset_temporary.reduce(
                (a, b) => a + b,
                0
              );
            }
          }

          //konversi dari end_h_omset menjadi end_h_idx
          let end_h_omset_temporary = 0;
          let end_h_idx = 0;

          if (h === jumlah_rayon_horisontal - 1) {
            end_h_idx = toko_list_v.length;
          } else {
            for (
              let index = 0;
              end_h_omset_temporary / end_h_omset < parameter_selesai;
              index++
            ) {
              //save index ke end_h_idx
              end_h_idx = index;
              //slice toko yang sudah urut dengan index
              end_h_omset_temporary = toko_list_v
                .slice(0, index)
                .map((t) => t.value);
              //sum omsetnya
              end_h_omset_temporary = end_h_omset_temporary.reduce(
                (a, b) => a + b,
                0
              );
            }
          }

          horisontal_range.push({
            start_h_omset,
            end_h_omset,

            start_h_idx,
            end_h_idx,
          });
        }

        return {
          start_idx,
          end_idx,

          jumlah_rayon_horisontal,
          horisontal_range,
        };
      }
    );

    //STEP 5 mulai looping pembagian vertikal
    vertical_range.forEach(({ start_idx, end_idx, horisontal_range }) => {
      //STEP 6 hanya ambil toko dari range start dan end vertikal, karena sudah diurutkan dengan latitudenya
      let toko_list_v = toko_list.slice(start_idx, end_idx);

      /*STEP 7 urutkan toko_list secara longitude karena setelah ini akan dikelompokan secara horisontal*/
      toko_list_v = sort_long(toko_list_v, true);

      //STEP 8 looping bagikan secara horisontal toko_list ke kotak-kotak sebanyak jumlah_toko_final (sudah ada array horisontal_range di setiap element array range)
      horisontal_range.forEach(({ start_h_idx, end_h_idx }) => {
        //STEP 9 index_loop_rayon bertambah 1 untuk membedakan dengan rayon lain
        index_loop_rayon = index_loop_rayon + 1;

        //STEP 10 value_string: nama rayon diambil dari nama DEPO dan nomor index
        const value_string = `${d.value}${imbuhan}${index_loop_rayon}`;

        //STEP 11 hanya ambil toko dari range start dan end horisontal, karena sudah diurutkan dengan longitudenya
        const toko_list_h = toko_list_v.slice(start_h_idx, end_h_idx);
        toko_list_h.forEach((toko) => {
          //STEP 12 masukan nilai rayon ke toko-toko yang sudah dislice
          toko_list_new[toko.index].rayon = `${value_string}`;
        });
      });
    });

    //LOOPING RAYONISASI SELESAI
  });

  //merapikan hasil rayonisasi dan mengisi atribut yang diperlukan
  rayon_list_complete = rayon_list_complete.map((r, idx) => {
    const toko_list = toko_list_new.filter((t) => t.rayon === r.name);

    const KELURAHAN_array = [...new Set(toko_list.map((k) => k.KELURAHAN))];
    const KECAMATAN_array = [...new Set(toko_list.map((k) => k.KECAMATAN))];
    const KABUPATEN_array = [...new Set(toko_list.map((k) => k.KABUPATEN))];
    const PROPINSI_array = [...new Set(toko_list.map((k) => k.PROPINSI))];

    const negara = toko_list?.[0]?.COUNTRY
      ? toko_list?.[0]?.COUNTRY
      : "INDONESIA";
    const outlet_id_array = toko_list.map((k) => k._id);

    r.KELURAHAN_array = KELURAHAN_array;
    r.KECAMATAN_array = KECAMATAN_array;
    r.KABUPATEN_array = KABUPATEN_array;
    r.PROPINSI_array = PROPINSI_array;
    r.negara = negara;
    r.outlet_id_array = outlet_id_array;

    let long = toko_list.map((t) => t.location.coordinates[0]);
    let lat = toko_list.map((t) => t.location.coordinates[1]);
    long = long.reduce((p, a) => p + a, 0) / long.length; //average long
    lat = lat.reduce((p, a) => p + a, 0) / lat.length; //average lat
    const outlet_length = toko_list.length;
    r.location = {
      type: "Point",
      coordinates: [long, lat],
    };
    r.outlet_length = outlet_length;
    let index = idx;
    if (idx > 15) {
      index = idx % 16;
    }
    r.color = st_colors[index];
    return r;
  });
  return { toko_list_new, rayon_list, rayon_list_complete };
};

export default rayonisasi_rayon_box_by_value;
