//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";
//Personal Component
//Redux function
//Picture Asset
//General Function
import isEmpty from "../../validation/is-empty";
class SalesmanTokoLayer extends Component {
  render() {
    let content;
    const { outlet_list_by_salesman_filter_0 } = this.props.data;
    if (outlet_list_by_salesman_filter_0.length > 0) {
      const outlet_list_ft = outlet_list_by_salesman_filter_0.map((o) => {
        const coords = o?.location?.coordinates || [0, 0];
        return {
          type: "Feature",
          properties: { ...o },
          geometry: {
            type: "Point",
            coordinates: coords,
          },
        };
      });
      let geojson = {
        type: "FeatureCollection",
        features: [...outlet_list_ft],
      };
      const active_menu = this.props.data.list_menu_active
        .filter((m) => m.active)
        .map((m) => m.key);
      const isTokoSalesman = active_menu.includes("toko_salesman");
      const mode_toko_hari = this.props.data.mode_toko_hari;
      if (isTokoSalesman) {
        content = (
          <main>
            <Source
              key={`outlet-salesman-src`}
              id={`outlet-salesman-src`}
              type="geojson"
              data={geojson}
              generateId={true}
              cluster={false}
            />
            <Layer
              id={`outlet-salesman-lyr`}
              key={`outlet-salesman-lyr`}
              type={"circle"}
              source={`outlet-salesman-src`}
              layout={{
                visibility: "visible",
              }}
              filter={
                isTokoSalesman && this.props.data.selected_hari !== "semua"
                  ? ["==", "HARIKunjungan", this.props.data.selected_hari]
                  : ["all", ["!=", "id", -1]]
              }
              paint={{
                "circle-color": true
                  ? mode_toko_hari === "sebelum"
                    ? [
                        "match",
                        ["get", "HARIKunjungan"],
                        "R01",
                        "#E11845",
                        "R02",
                        "#8931EF",
                        "R03",
                        "#FF00BD",
                        "R04",
                        "#F2CA19",
                        "R05",
                        "#0057E9",
                        "R06",
                        "#87E93F",
                        "#E11845",
                      ]
                    : [
                        "match",
                        ["get", "hari_new"],
                        "R01",
                        "#E11845",
                        "R02",
                        "#8931EF",
                        "R03",
                        "#FF00BD",
                        "R04",
                        "#F2CA19",
                        "R05",
                        "#0057E9",
                        "R06",
                        "#87E93F",
                        "#E11845",
                      ]
                  : isTokoSalesman
                  ? [
                      "match",
                      ["get", "HARIKunjungan"],
                      "R01",
                      "#E11845",
                      "R02",
                      "#8931EF",
                      "R03",
                      "#FF00BD",
                      "R04",
                      "#F2CA19",
                      "R05",
                      "#0057E9",
                      "R06",
                      "#87E93F",
                      "#E11845",
                    ]
                  : "#4DA83A",
                "circle-radius": 5,
                "circle-stroke-width": 1,
                "circle-stroke-color": "black",
                "circle-opacity":
                  isTokoSalesman &&
                  !isEmpty(this.props.data.filter_param) &&
                  !isEmpty(this.props.data.selected_salesman)
                    ? [
                        "case",
                        [
                          "==",
                          ["get", "Nama Salesman"],
                          this.props.data.selected_salesman,
                        ],
                        1,
                        0.1,
                      ]
                    : 1,
                "circle-stroke-opacity":
                  isTokoSalesman &&
                  !isEmpty(this.props.data.filter_param) &&
                  !isEmpty(this.props.data.selected_salesman)
                    ? [
                        "case",
                        [
                          "==",
                          ["get", "Nama Salesman"],
                          this.props.data.selected_salesman,
                        ],
                        1,
                        0,
                      ]
                    : 1,
              }}
              onLeave={this.onLeave}
              onClick={this.props.layerOnClick.bind(this, "modal_toko")}
            />
          </main>
        );
      }
    }
    return <main>{content}</main>;
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
});
export default connect(mapStateToProps, {})(SalesmanTokoLayer);
