//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import Modal from "../common/Modal";

//Redux function
import {
  ow_salesman_outlet,
  ow_salesman_rayon,
} from "../../actions/rayonActions";

//Picture Asset

//General Function
import sort_array from "../../validation/sort_array";

class Step7OverwriteSalesman extends Component {
  state = {
    salesman_id_state: "",
    modal_warn: false,
  };

  toggle_warn = () => {
    this.setState({ modal_warn: !this.state.modal_warn });
  };

  on_set_salesman = (salesman_id_state) => {
    this.setState({ salesman_id_state });
  };

  on_submit = () => {
    const { salesman_id_state } = this.state;
    if (salesman_id_state) {
      let { rayon_select, outlet_list_filter_0, plan_selected, plan_index } =
        this.props.data;

      //step 1 buat array $set untuk update many toko
      //ow_salesman
      const salesman_id = plan_selected?.salesman_id;

      /*
      find: {
      _id: outlet_id
      },
      update: {
      "$set": {`kunjungan_arr_new.${index}.salesman_id` : salesman_id_state}
      }
      */

      const outlet_update_array = outlet_list_filter_0.map((t) => {
        const k_index = t.kunjungan_arr_new.findIndex(
          (k) => k.salesman_id === salesman_id
        );
        return {
          outlet_id: t._id,
          k_index,
        };
      });

      const body_outlet = {
        outlet_update_array,
        salesman_id: salesman_id_state,
      };

      this.props.ow_salesman_outlet(body_outlet);

      //step 2 buat object $set untuk update satu rayon

      /*
      find : {
      _id: rayon_id
      },
      update : {
      "$set": { `plan_list.${plan_index}.salesman_id`: salesman_id_state }
      }

      body_rayon 
      */

      const body_rayon = {
        rayon_id: rayon_select,
        plan_index,
        salesman_id: salesman_id_state,
      };

      this.props.ow_salesman_rayon(body_rayon);
    } else {
      this.toggle_warn();
    }
  };

  render() {
    const { salesman_id_state, modal_warn } = this.state;

    let {
      rayon_select,
      salesman_list,
      outlet_list_filter_0,
      plan_selected,
      rayon_list_database,
      loading_status,
      loading_item,
    } = this.props.data;

    salesman_list = sort_array(salesman_list, "Nama Only", true);

    const rayon_object = rayon_list_database.find(
      (r) => r._id === rayon_select
    );

    const salesman_id = plan_selected?.salesman_id;
    const salesman_object = salesman_list.find((s) => s._id === salesman_id);

    let day_list = plan_selected?.day_list || [];
    day_list = day_list.map((d) => d.name)?.join(", ");

    const cycle_note = [
      {
        name: "cycle 1",
        code: "M2C13",
        note: "Dua kali dalam sebulan, minggu  pertama dan ketiga",
      },
      {
        name: "cycle 2",
        code: "M2C24",
        note: "Dua kali dalam sebulan, minggu  kedua dan keempat",
      },
    ];

    const cycle = cycle_note.find((c) => c.name === plan_selected?.cycle);

    const modal_warn_content = modal_warn && (
      <Modal modalSize="small" isOpen={modal_warn} onClose={this.toggle_warn}>
        <div className="box-body text_center">
          Please select a salesman replacement first
        </div>
      </Modal>
    );

    return (
      <main style={{ minHeight: "85vh" }}>
        {modal_warn_content}
        <h1 className="text_header">Change/Overwrite Current Salesman</h1>
        <br />
        <section className="inline_box" id="grey_outline">
          <h1 className="text_header">Current salesman detail:</h1>
          <table className="table_lite" style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td>Name</td>
                <td>
                  {salesman_object["Nama Only"] ||
                    salesman_object["Nama Salesman"]}
                </td>
              </tr>
              <tr>
                <td>Code</td>
                <td>{salesman_object?.kd_sales_arr?.[0]}</td>
              </tr>
              <tr>
                <td>Div</td>
                <td>{salesman_object?.divisi_arr?.[0]}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h1 className="text_header">Current plan detail:</h1>
          <table className="table_lite" style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td>Rayon</td>
                <td>{rayon_object?.name}</td>
              </tr>
              <tr>
                <td>Cycle</td>
                <td>
                  <b>{cycle?.code}</b>
                  <br />
                  {cycle?.note}
                </td>
              </tr>
              <tr>
                <td>Day</td>
                <td>{day_list}</td>
              </tr>
              <tr>
                <td>Number of outlets</td>
                <td>{outlet_list_filter_0?.length}</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="inline_box" id="grey_outline">
          <h1 className="text_header">Choose a replacement salesman:</h1>
          <section
            style={{ maxHeight: "calc(( 85vh - 150px ))", overflow: "auto" }}
          >
            <table className="table_lite_fix">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Div</th>
                  <th style={{ minWidth: "100px" }}></th>
                </tr>
              </thead>
              <tbody>
                {salesman_list.map((salesman, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>
                        {salesman["Nama Only"] || salesman["Nama Salesman"]}
                      </td>
                      <td>{salesman?.kd_sales_arr?.[0]}</td>
                      <td>{salesman?.divisi_arr?.[0]}</td>
                      <td>
                        <button
                          className="button"
                          id={
                            salesman_id_state === salesman._id ? "blue" : "grey"
                          }
                          onClick={this.on_set_salesman.bind(
                            this,
                            salesman._id
                          )}
                        >
                          {salesman_id_state === salesman._id
                            ? "Selected"
                            : "Select"}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </section>

          <br />
          {loading_status && loading_item === "ow_salesman_outlet" ? (
            <button className="button" id="grey">
              Saving...
            </button>
          ) : salesman_id_state ? (
            <button className="button" id="blue" onClick={this.on_submit}>
              Save
            </button>
          ) : (
            <button className="button" id="grey" onClick={this.on_submit}>
              Save
            </button>
          )}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, {
  ow_salesman_outlet,
  ow_salesman_rayon,
})(Step7OverwriteSalesman);
