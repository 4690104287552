import React, { Component } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import Modal from "../common/Modal";
import writeXlsxFile from "write-excel-file";

export class CompetitorFailedDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataCSV: [],
      loading_csv: false,
      downloadOutlet: false,
    };
  }
  onDownloadCSV = () => {
    let { competitor_failed_list } = this.props.data;
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });

      try {
        let tempData = [];
        const header = [
          "_id",
          "PLAN",
          "ID OUTLET",
          "ID SFA",
          "DEPO",
          "STATUS DEPO",
          "SYSTEM",
          "OUTLET NAME",
          "REVENUE",
          "ADDRESS 1",
          "ADDRESS 2",
          "TELEPHONE NUMBER",
          "TYPE OUTLET",
          "SUBDISTRICT",
          "DISTRICT",
          "CITY/REGENCY",
          "PROVINCE",
          "FLAG DISTRICT",
          "DETECTED LOCATION",
          "UPDATE DESCRIPTION",
          "POSTCODE",
          "STATUS OUTLET",
          "ID BRANCH",
          "COUNTRY",
          "STATUS KOORDINAT",
          "SALESMAN NAME",
          "SALESMAN CODE",
          "ID OUTLET PMA",
          "REASON",
        ];
        tempData.push(header);

        for (let index = 0; index < competitor_failed_list.length; index++) {
          const { user, master_admin } = this.props.auth;
          const element = competitor_failed_list[index];
          const lastIndex = element.kunjungan_arr.length - 1;
          let newName = "";
          let newCode = "";
          if (element.kunjungan_arr[lastIndex].salesman_id) {
            newName =
              element.kunjungan_arr[lastIndex].salesman_id["Nama Salesman"] ??
              "-";
            newCode =
              element.kunjungan_arr[lastIndex].salesman_id["kd_sales_arr"][0] ??
              "-";
          } else {
            newName = "";
            newCode = "";
          }
          let location = "";
          let status_koordinat = "NO";
          if (element?.location?.coordinates?.[0] !== 0) {
            const long = element.location.coordinates[0];
            const lat = element.location.coordinates[1];
            location = `${lat},${long}`;
            status_koordinat = "YES";
          }
          if (
            master_admin.filter((element) => element._id === user._id).length <=
            0
          ) {
            if (element?.location?.coordinates?.[0] !== 0) {
              location = "YES";
            } else {
              location = "NO";
            }
          }
          tempData.push([
            element._id,
            element["Plan"] ?? "-",
            element["ID Outlet"] ?? "-",
            element["ID SFA"] ?? "-",
            element["Depo"] ?? "-",
            element["STATUS DEPO"] ?? "-",
            element["SYSTEM"] ?? "-",
            element["Nama Toko"] ?? "-",
            element["OMSET"] ?? "-",
            element["ALAMAT"] ?? "-",
            element["ALAMATLAIN"] ?? "-",
            element["NO Tlpon"] ?? "-",
            element["Type Outlet"] ?? "-",
            element["KELURAHAN"] ?? "-",
            element["KECAMATAN"] ?? "-",
            element["KABUPATEN"] ?? "-",
            element["PROPINSI"] ?? "-",
            element["FLAG DISTRICT"] ?? "-",
            location,
            element["KET UPDATE"] ?? "-",
            element["KDPOS"] ?? "-",
            element["Status Outlet"] ?? "-",
            element["ID BRANCH"] ?? "-",
            element["COUNTRY"] ?? "-",
            status_koordinat,
            newName,
            newCode,
            element["id_nabati"] ?? "-",
            element["failed_reason"] ?? "-",
          ]);
        }

        this.setState({
          loading_csv: false,
          dataCSV: tempData,
        });
      } catch (error) {
        this.setState({
          loading: false,
        });
      }
    }
  };
  onDownloadEXCEL = async () => {
    let { competitor_failed_list } = this.props.data;
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });
      try {
        const schema = [
          {
            column: "_id",
            type: String,
            value: (target) => target._id,
          },
          {
            column: "PLAN",
            type: String,
            value: (target) => target.Plan,
          },
          {
            column: "ID OUTLET",
            type: String,
            value: (target) => target["ID Outlet"],
          },
          {
            column: "ID SFA",
            type: String,
            value: (target) => target["ID SFA"],
          },
          {
            column: "DEPO",
            type: String,
            value: (target) => target.Depo,
          },
          {
            column: "STATUS DEPO",
            type: String,
            value: (target) => target["STATUS DEPO"],
          },
          {
            column: "SYSTEM",
            type: String,
            value: (target) => target.SYSTEM,
          },
          {
            column: "OUTLET NAME",
            type: String,
            value: (target) => target["Nama Toko"],
          },
          {
            column: "REVENUE",
            type: Number,
            value: (target) => target.OMSET,
          },
          {
            column: "ADDRESS 1",
            type: String,
            value: (target) => target.ALAMAT,
          },
          {
            column: "ADDRESS 2",
            type: String,
            value: (target) => target.ALAMATLAIN,
          },
          {
            column: "TELEPHONE NUMBER",
            type: String,
            value: (target) => target["NO Tlpon"],
          },
          {
            column: "TYPE OUTLET",
            type: String,
            value: (target) => target["Type Outlet"],
          },
          {
            column: "SUBDISTRICT",
            type: String,
            value: (target) => target.KELURAHAN,
          },
          {
            column: "DISTRICT",
            type: String,
            value: (target) => target.KECAMATAN,
          },
          {
            column: "CITY/REGENCY",
            type: String,
            value: (target) => target.KABUPATEN,
          },
          {
            column: "PROVINCE",
            type: String,
            value: (target) => target.PROPINSI,
          },
          {
            column: "FLAG DISTRICT",
            type: String,
            value: (target) => target["FLAG DISTRICT"],
          },
          {
            column: "DETECTED LOCATION",
            type: String,
            value: (target) => target.location,
          },
          {
            column: "UPDATE DESCRIPTION",
            type: String,
            value: (target) => target["KET UPDATE"],
          },
          {
            column: "POSTCODE",
            type: String,
            value: (target) => target.KDPOS,
          },
          {
            column: "STATUS OUTLET",
            type: String,
            value: (target) => target["Status Outlet"],
          },
          {
            column: "ID BRANCH",
            type: String,
            value: (target) => target["ID BRANCH"],
          },
          {
            column: "COUNTRY",
            type: String,
            value: (target) => target.COUNTRY,
          },
          {
            column: "STATUS KOORDINAT",
            type: String,
            value: (target) => target.status_koordinat,
          },
          {
            column: "SALESMAN NAME",
            type: String,
            value: (target) => target.namaSales,
          },
          {
            column: "SALESMAN CODE",
            type: String,
            value: (target) => target.kodeSales,
          },
          {
            column: "ID OUTLET PMA",
            type: String,
            value: (target) => target.id_nabati,
          },
          {
            column: "REASON",
            type: String,
            value: (target) => target.reason,
          },
        ];
        let newData = [];
        competitor_failed_list.forEach((e) => {
          const lastIndex = e.kunjungan_arr.length - 1;
          let newName = "";
          let newCode = "";
          if (e.kunjungan_arr[lastIndex].salesman_id) {
            newName =
              e.kunjungan_arr[lastIndex].salesman_id["Nama Salesman"] ?? "-";
            newCode =
              e.kunjungan_arr[lastIndex].salesman_id["kd_sales_arr"][0] ?? "-";
          } else {
            newName = "";
            newCode = "";
          }

          const { user, master_admin } = this.props.auth;
          let location = "";
          let status_koordinat = "NO";
          if (e?.location?.coordinates?.[0] !== 0) {
            const long = e.location.coordinates[0];
            const lat = e.location.coordinates[1];
            location = `${lat},${long}`;
            status_koordinat = "YES";
          }
          if (master_admin.filter((e) => e._id === user._id).length <= 0) {
            if (e?.location?.coordinates?.[0] !== 0) {
              location = "YES";
            } else {
              location = "NO";
            }
          }

          let getData = {
            _id: e._id ?? "-",
            Plan: e.Plan ?? "-",
            "ID Outlet": e["ID Outlet"] ?? "-",
            "ID SFA": e["ID SFA"] ?? "-",
            Depo: e.Depo ?? "-",
            "STATUS DEPO": e["STATUS DEPO"] ?? "-",
            SYSTEM: e.SYSTEM ?? "-",
            "Nama Toko": e["Nama Toko"] ?? "-",
            OMSET: e.OMSET,
            ALAMAT: e.ALAMAT ?? "-",
            ALAMATLAIN: e.ALAMATLAIN ?? "-",
            "NO Tlpon": e["NO Tlpon"] ?? "-",
            "Type Outlet": e["Type Outlet"] ?? "-",
            KELURAHAN: e.KELURAHAN ?? "-",
            KECAMATAN: e.KECAMATAN ?? "-",
            KABUPATEN: e.KABUPATEN ?? "-",
            PROPINSI: e.PROPINSI ?? "-",
            "FLAG DISTRICT": e["FLAG DISTRICT"] ?? "-",
            location: location ?? "-",
            "KET UPDATE": e["KET UPDATE"] ?? "-",
            KDPOS: e.KDPOS ?? "-",
            "Status Outlet": e["Status Outlet"] ?? "-",
            "ID BRANCH": e["ID BRANCH"] ?? "-",
            COUNTRY: e.COUNTRY ?? "-",
            status_koordinat: status_koordinat ?? "-",
            id_nabati: e.id_nabati ?? "-",
            namaSales: newName ?? "-",
            kodeSales: newCode ?? "-",
            reason: e.failed_reason ?? "-",
          };
          newData.push(getData);
        });
        await writeXlsxFile(newData, {
          schema,
          fileName: "DataExcelFailedAcquisition.xlsx",
        });
        this.setState({
          loading_csv: false,
        });
      } catch (error) {
        console.log(error);
        this.setState({
          loading: false,
        });
      }
    }
  };
  render() {
    const { dataCSV, loading_csv } = this.state;
    const { competitor_failed_list, list_menu_active } = this.props.data;
    const is_visible = list_menu_active.find(
      (e) => e.key === "competitor_failed"
    ).active;
    const modalDownloadOutlet = this.state.downloadOutlet && (
      <Modal
        modalSize="small"
        isOpen={this.state.downloadOutlet}
        onClose={() =>
          this.setState({ downloadOutlet: !this.state.downloadOutlet })
        }
      >
        <div className="box-body">
          <h1 className="text-center font-bold">Download Acquisition by CSV</h1>
          <section className={`flex justify-center mb-3`}>
            <CSVLink
              className="button_main mb-3"
              data={dataCSV}
              separator={";"}
              filename={`Competitor Failed.csv`}
              onClick={(e, done) => {
                this.onDownloadCSV({}, done);
              }}
            >
              {loading_csv ? "Loading ..." : "All Outlet Acquisition Failed"}
            </CSVLink>
          </section>
          <h1 className="text-center font-bold">
            Download Acquisition by EXCEL
          </h1>
          <section className={`flex justify-center`}>
            <button
              className="button_main"
              onClick={(e, done) => {
                this.onDownloadEXCEL({}, done);
              }}
            >
              {loading_csv ? "Loading ..." : "All Outlet Acquisition Failed"}
            </button>
          </section>
        </div>
      </Modal>
    );
    if (is_visible) {
      return (
        <div>
          {/* <button id="green" className="button mb-3" onClick={this.onDownloadCSV}>
            Download All Outlet Acquisition Failed
          </button> */}
          <button
            className="button_main mb-3"
            onClick={() => {
              this.setState({ downloadOutlet: true });
            }}
          >
            Download All Acquisition
          </button>
          {modalDownloadOutlet}
        </div>
      );
    } else {
      return null;
    }
    // if (competitor_failed_list.length > 0) {
    //   return (
    //     <div>
    //       {/* <button id="green" className="button mb-3" onClick={this.onDownloadCSV}>
    //         Download All Outlet Acquisition Failed
    //       </button> */}
    //       <button
    //         className="button_main mb-3"
    //         onClick={() => {
    //           this.setState({ downloadOutlet: true });
    //         }}
    //       >
    //         Download All Acquisition
    //       </button>
    //       {modalDownloadOutlet}
    //     </div>
    //   );
    // } else {
    //   return null;
    // }
    // else {
    //   return <div>Loading for download...</div>;
    // }
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompetitorFailedDownload);
