import React, { Component } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import writeXlsxFile from "write-excel-file";
import {
  // get_all_log_driver,
  // get_log_active_driver,
  get_log_driver_multi_depo,
  // get_log_online_driver,
} from "../../actions/tracking";

export class DownloadTrackingDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: 0,
      end_date: 0,
      not_all: true,
      target_hour: 0,
      target_percent: 100,
      time_array_depo: [],
      loading_process: false,
      dataCSV: [],
      loading_csv: false,
      separator: ",",
      start_time_hour: "08",
      start_time_minutes: "00",
      end_time_hour: "16",
      end_time_minutes: "00",
      hari: 1,
      loading_depo: 0,
      loading_depo_index: 0,
      loading_persent: 0,
      loading_depo_length: 0,
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
  }
  handleChangeDate(event) {
    let unixDate = 0;
    if (event.target.name === "start_date") {
      unixDate = new Date(`${event.target.value} 00:00:00`).getTime();
      this.setState({ start_: unixDate });
    }
    if (event.target.name === "end_date") {
      unixDate = new Date(`${event.target.value} 23:59:00`).getTime();
      this.setState({ end_: unixDate });
    }
    this.setState({
      [event.target.name]: unixDate,
    });
  }
  handleChangeNumber(event) {
    this.setState({ [event.target.name]: parseInt(event.target.value) });
  }
  handleChangeTime(event) {
    const name = event.target.name;
    const value = event.target.value.split(":");
    this.setState({
      [`${name}_hour`]: value[0],
      [`${name}_minutes`]: value[1],
    });
  }
  handleSubmit = async (event) => {
    console.log("diklik");
    event.preventDefault();
    const hari = Math.ceil(
      (this.state.end_date - this.state.start_date) / (3600 * 24 * 1000)
    );
    this.setState({ loading_process: !this.state.loading_process, hari: hari });
    try {
      const { driver_daerah_kerja_2_value, driver_daerah_kerja_value } =
        this.props.tracking;

      let depos = [];

      if (driver_daerah_kerja_value.value === "regional") {
        driver_daerah_kerja_2_value.forEach((element) => {
          const list_obj_depo = this.props.data.salesman_filter.depo.filter(
            (e) => e.REGION === element.value
          );
          const list_depo = list_obj_depo.map((e) => e.Depo);
          depos = [...depos, ...list_depo];
        });
      }
      if (driver_daerah_kerja_value.value === "cabang") {
        driver_daerah_kerja_2_value.forEach((element) => {
          const list_obj_depo = this.props.data.salesman_filter.depo.filter(
            (e) => e.CABANG === element.value
          );
          const list_depo = list_obj_depo.map((e) => e.Depo);
          depos = [...depos, ...list_depo];
        });
      }
      if (driver_daerah_kerja_value.value === "depo") {
        depos = driver_daerah_kerja_2_value.map((e) => e.value);
      }

      let result = [];
      if (driver_daerah_kerja_value.value === "Semua Driver") {
        const depo = this.props.data.salesman_filter.depo
          .filter((e) => e.folder_id === "63a4341e303d716c2f70bf04")
          .map((x) => {
            return x.Depo;
          });
        this.setState({
          loading_depo_length: depo.length,
        });
        for (let i = 0; i < depo.length; i++) {
          const element = depo[i];
          const body = {
            depos: [element],
            start: this.state.start_date,
            end: this.state.end_date,
          };
          const res = await get_log_driver_multi_depo(body);
          result = [...result, ...res];
          this.setState({
            loading_depo: element,
            loading_depo_index: i + 1,
            loading_persent: ((i + 1) / depo.length) * 100,
          });
        }
      } else if (driver_daerah_kerja_value.value === "Online Driver") {
        const depo = this.props.data.salesman_filter.depo
          .filter((e) => e.folder_id === "63a4341e303d716c2f70bf04")
          .map((x) => {
            return x.Depo;
          });
        this.setState({
          loading_depo_length: depo.length,
        });
        for (let i = 0; i < depo.length; i++) {
          const element = depo[i];
          const body = {
            depos: [element],
            start: this.state.start_date,
            end: this.state.end_date,
          };
          const res = await get_log_driver_multi_depo(body);
          result = [...result, ...res];
          this.setState({
            loading_depo: element,
            loading_depo_index: i + 1,
            loading_persent: ((i + 1) / depo.length) * 100,
          });
        }
        // const start = this.state.start_date;
        // const end = this.state.end_date;
        // result = await get_log_driver_multi_depo(start, end);
      } else if (driver_daerah_kerja_value.value === "Active Driver") {
        const depo = this.props.data.salesman_filter.depo
          .filter((e) => e.folder_id === "63a4341e303d716c2f70bf04")
          .map((x) => {
            return x.Depo;
          });
        this.setState({
          loading_depo_length: depo.length,
        });
        for (let i = 0; i < depo.length; i++) {
          const element = depo[i];
          const body = {
            depos: [element],
            start: this.state.start_date,
            end: this.state.end_date,
          };
          const res = await get_log_driver_multi_depo(body);
          result = [...result, ...res];
          this.setState({
            loading_depo: element,
            loading_depo_index: i + 1,
            loading_persent: ((i + 1) / depo.length) * 100,
          });
        }
        // const start = this.state.start_date;
        // const end = this.state.end_date;
        // result = await get_log_driver_multi_depo(start, end);
      } else {
        this.setState({
          loading_depo_length: depos.length,
        });
        for (let i = 0; i < depos.length; i++) {
          const element = depos[i];
          const body = {
            depos: [element],
            start: this.state.start_date,
            end: this.state.end_date,
          };
          const res = await get_log_driver_multi_depo(body);
          result = [...result, ...res];
          this.setState({
            loading_depo: element,
            loading_depo_index: i + 1,
            loading_persent: ((i + 1) / depos.length) * 100,
          });
        }

        // const body = {
        //   depos: depos,
        //   start: this.state.start_date,
        //   end: this.state.end_date,
        // };
        // result = await get_log_driver_multi_depo(body);
      }

      let data = [];
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        const generate_time = this.generate_time(element);
        element.log = generate_time;
        if (generate_time.length > 0) {
          element.total_hours = this.sum(generate_time, "total_hours").toFixed(
            1
          );
          element.percent_hour = this.sum(
            generate_time,
            "percent_hour"
          ).toFixed(1);
          const total_data = element.log
            .map((e) => e.total_data)
            .reduce((partialSum, a) => partialSum + a, 0);
          element.total_jam_kal = this.rumusHitungPrediksiJam(
            element.total_hours,
            total_data
          );
        } else {
          element.total_hours = "0";
          element.percent_hour = "0";
        }
        element.target_hour = this.state.target_hour;
        element.target_percent = this.state.target_percent;
        data.push(element);
      }
      this.setState({
        time_array_depo: data,
      });
    } catch (error) {
      console.log(error);
    }
    this.setState({ loading_process: !this.state.loading_process });
  };
  generate_time = (params = []) => {
    let lineString = params.log;
    if (lineString === null) {
      lineString = [];
    }
    let time_array = lineString.map((e) => e.time_stamp).flat();

    //time stamp generate date_iso & month iso untuk pembagian perbulan dan perhari
    time_array = time_array.map((t) => {
      const t_date = new Date(t);
      //Format date iso 2022-06-16
      // let month_iso = t_date.toISOString().split("T")[0];
      // month_iso = month_iso.slice(0, -2) + "01";
      // return {
      //   unix: t,
      //   date_iso: t_date.toISOString().split("T")[0],
      //   month_iso,
      // };
      const month =
        t_date.getMonth() < 10 ? `0${t_date.getMonth()}` : t_date.getMonth();
      const date = "01";
      let month_iso = `${t_date.getFullYear()}-${month}-${date}`;
      return {
        unix: t,
        date_iso: `${t_date.getFullYear()}-${
          t_date.getMonth() + 1
        }-${t_date.getDate()}`,
        month_iso,
      };
    });
    //generate bulan unik, supaya bisa dipisahkan berdasarkan bulan
    let month_array_unique = time_array.map((t) => t.month_iso);
    month_array_unique = [...new Set(month_array_unique)];
    //looping dibulan unik
    time_array = month_array_unique.map((m) => {
      //generate date unik supaya bisa dipisahkan berdasarkan tanggal
      let date_array_unique = time_array.filter((t) => t.month_iso === m);
      date_array_unique = date_array_unique.map((t) => t.date_iso);
      date_array_unique = [...new Set(date_array_unique)];
      // date_array_unique = time_array.map((t) => t.date_iso);
      // //looping di date unik
      let hour_start = parseInt(this.state.start_time_hour);
      const min_start = parseInt(this.state.start_time_minutes);
      hour_start = hour_start + min_start / 60;

      let hour_end = parseInt(this.state.end_time_hour);
      const min_end = parseInt(this.state.end_time_minutes);
      hour_end = hour_end + min_end / 60;

      date_array_unique = date_array_unique.map((d) => {
        let hour_array = time_array.filter((t) => t.date_iso === d);
        hour_array = hour_array.map((t) => {
          let jam_desimal = parseInt(new Date(t.unix).getHours());
          const min_desimal = parseInt(new Date(t.unix).getMinutes());
          jam_desimal = jam_desimal + min_desimal / 60;
          return {
            ...t,
            jam_desimal,
          };
        });
        hour_array = hour_array.filter(
          (t) => t.jam_desimal >= hour_start && t.jam_desimal <= hour_end
        );
        //get first hour
        const first_hour = hour_array?.[0]?.unix ? hour_array?.[0]?.unix : 0;
        //get last hour
        const last_hour = hour_array?.[hour_array.length - 1]?.unix
          ? hour_array?.[hour_array.length - 1]?.unix
          : 0;
        //get delta unix
        const delta_unix = parseInt(last_hour) - parseInt(first_hour);
        let seconds = parseFloat(delta_unix / 1000).toFixed(3);
        let minutes = parseFloat(seconds / 60).toFixed(3);
        let delta_hours = Number(parseFloat(minutes / 60).toFixed(3));
        //get the amount of data in one day
        const total_data = hour_array.length;
        //filter hari minggu
        let day_index = new Date(d);
        day_index = day_index.getDay();
        return {
          day_index,
          date_iso: d,
          hour_array,
          first_hour,
          last_hour,
          delta_unix,
          delta_hours,
          total_data,
        };
      });
      //0 = minggu
      date_array_unique = date_array_unique.filter((d) => d.day_index !== 0);
      let total_hours = date_array_unique.map((d) => d.delta_hours);
      total_hours = total_hours.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
      const percent_hour = (total_hours / this.state.target_hour) * 100;
      const target_percent = this.state.target_percent;

      let total_data = 0;
      date_array_unique.forEach((d) => {
        total_data += d.total_data;
      });
      return {
        month_iso: m,
        date_array_unique,
        total_hours,
        percent_hour,
        target_percent,
        total_data,
      };
    });
    return time_array;
  };
  sum = (obj, key) => {
    return obj.reduce((a, b) => a + (b[key] || 0), 0);
  };
  cal = (data) => {
    let total_hari = 0;
    data.forEach((element) => {
      total_hari += element.date_array_unique.length;
    });
    return total_hari;
  };
  rumusHitungPrediksiJam = (totalJam, totalData) => {
    if (
      totalJam === "-" ||
      totalJam === "0.0" ||
      totalJam === "0" ||
      parseFloat(totalJam) === 0.0
    ) {
      return 0;
    }
    const totalMenit = parseFloat(totalJam) * 60;
    const interval = totalMenit / totalData;
    let part = 1 / interval;
    if (part > 1) {
      part = 1;
    }
    const result = parseFloat((part * totalMenit) / 60).toFixed(1);
    return result;
  };
  generateDataCSV = (data, done) => {
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });

      try {
        let tempData = [];
        const header = [
          "Driver",
          "Helper",
          "License Plate Number",
          "Depo",
          "Sum Days",
          "Total Hours",
          "Hours Target",
          "Percentation of achievement (%)",
          "Achievement Target (%)",
          "Achievement Status",
        ];
        tempData.push(header);
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let percent_hour = 0;
          if (element["total_jam_kal"]) {
            percent_hour = (
              (element["total_jam_kal"] / element["target_hour"]) *
              100
            ).toFixed(1);
          }
          const driver = element.DRIVER ? element["DRIVER"].toUpperCase() : "-";
          tempData.push([
            driver,
            element["HELPER"],
            element["NOPOL"],
            element["Depo"],
            this.cal(element["log"]) > this.state.hari
              ? this.state.hari
              : this.cal(element["log"]),
            element["total_jam_kal"] ? element["total_jam_kal"] : 0,
            element["target_hour"],
            percent_hour,
            element["target_percent"],
            percent_hour > element["target_percent"] ? "Ya" : "Tidak",
          ]);
        }
        this.setState({
          loading_csv: false,
          dataCSV: tempData,
        });
      } catch (error) {
        console.log(error);
        this.setState({
          loading: false,
        });
      }
    }
  };
  generateDataEXCEL = async (data, done) => {
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });

      try {
        const schema = [
          {
            column: "Name",
            type: String,
            value: (driver) => driver.DRIVER,
          },
          {
            column: "Helper",
            type: String,
            value: (driver) => driver.HELPER,
          },
          {
            column: "License Plate Number",
            type: String,
            value: (driver) => driver.NOPOL,
          },
          {
            column: "Depo",
            type: String,
            value: (driver) => driver.Depo,
          },
          {
            column: "Sum Days",
            type: String,
            value: (driver) => driver.percent_hour,
          },
          {
            column: "Total Hours",
            type: String,
            value: (driver) => driver.total_hours,
          },
          {
            column: "Hours Target",
            type: String,
            value: (driver) => driver.target_hour,
          },
          {
            column: "Percentation of achievement (%)",
            type: String,
            value: (driver) => driver.percent_hour,
          },
          {
            column: "Achievement Target (%)",
            type: String,
            value: (driver) => driver.target_percent,
          },
          {
            column: "Achievement Status",
            type: String,
            value: (driver) => driver.archivement_status,
          },
        ];

        let newData = [];
        data.forEach((e) => {
          let percent_hour = e.percent_hour;
          if (e.percent_hour === "0.0") {
            percent_hour = "0";
          }
          let total_hours = e.total_hours;
          if (e.total_hours === "0.0") {
            total_hours = "0";
          }
          let getData = {
            DRIVER: e.DRIVER,
            HELPER: e.HELPER,
            Depo: e.Depo,
            KAPASITAS_CTN: e.KAPASITAS_CTN,
            KAPASITAS_M3: e.KAPASITAS_M3,
            NOPOL: e.NOPOL,
            percent_hour: percent_hour,
            target_hour: `${e.target_hour}`,
            target_percent: `${e.target_percent}`,
            total_hours: total_hours,
            _id: e._id,
            archivement_status: e.percent_hour > e.target_hour ? "Yes" : "No",
          };
          newData.push(getData);
        });
        await writeXlsxFile(newData, {
          schema,
          fileName: "DataExcelDriver.xlsx",
        });
        this.setState({
          loading_csv: false,
        });
      } catch (e) {
        console.log(e);
      }
    }
  };
  render() {
    const {
      loading_process,
      time_array_depo,
      dataCSV,
      separator,
      loading_csv,
      loading_depo,
      loading_persent,
      loading_depo_length,
      loading_depo_index,
    } = this.state;
    const loading_content = (
      <div className="mt-4">
        <div className="flex">
          <div className="flex-1">{loading_depo}</div>
          <div>
            {loading_depo_index}/{loading_depo_length}
          </div>
        </div>
        <div className="h-2 bg-gray-400 rounded-full relative">
          <div
            className={`h-2 bg-green-600 rounded-full`}
            style={{ width: `${loading_persent}%` }}
          ></div>
        </div>
      </div>
    );
    const filter_content = (
      <form onSubmit={this.handleSubmit}>
        <div className="grid grid-cols-2 gap-2">
          <div className="flex flex-col">
            <label htmlFor="start_date">Choose Start Date</label>
            <input
              type="date"
              className="w-full"
              id="start_date"
              name="start_date"
              onChange={this.handleChangeDate}
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="end_date">Choose End Date</label>
            <input
              type="date"
              className="w-full"
              id="end_date"
              name="end_date"
              onChange={this.handleChangeDate}
              required
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 mt-1 items-end">
          <div className="flex flex-col">
            <label htmlFor="target_hour">
              Total of the work hours target at the selected range
            </label>
            <input
              type="number"
              id="target_hour"
              name="target_hour"
              className="w-full"
              onChange={this.handleChangeNumber}
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="target_percent">
              Limit of achievement percentation (0 - 100%)
            </label>
            <input
              type="number"
              id="target_percent"
              name="target_percent"
              className="w-full"
              onChange={this.handleChangeNumber}
              defaultValue={"100"}
              required
              min="0"
              max="100"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 mt-1 items-end">
          <div className="flex flex-col">
            <label htmlFor="start_time">Choose Start Hour</label>
            <input
              type="time"
              className="w-full"
              id="start_time"
              name="start_time"
              required
              defaultValue={`08:00`}
              onChange={this.handleChangeTime}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="end_time">Choose End Hour</label>
            <input
              type="time"
              className="w-full"
              id="end_time"
              name="end_time"
              required
              defaultValue={`16:00`}
              onChange={this.handleChangeTime}
            />
          </div>
        </div>
        <div className="mt-4">
          <button className="button_main w-full" type="submit">
            {loading_process ? "Loading..." : "Process Driver Data"}
          </button>
        </div>
      </form>
    );
    const preview = (
      <div>
        <div className="mt-2">
          {time_array_depo.length === 0 ? "" : "Preview data :"}
        </div>
        <div
          className={`overflow-x-auto overflow-y-auto max-h-40 mt-2 ${
            time_array_depo.length === 0 && "hidden"
          }`}
        >
          <table className="mt-2 w-full table-auto border-collapse border mb-4">
            <thead>
              <tr>
                <th className="border text-left whitespace-nowrap px-1">No</th>
                <th className="border text-left whitespace-nowrap px-1">
                  Driver
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  Helper
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  License Plate Number
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  Depo
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  Total Days
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  Total Hours
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  Hours Target
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  Percentation of achievement (%)
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  Achievement Target (%)
                </th>
                <th className="border text-left whitespace-nowrap px-1">
                  Achievement Status
                </th>
              </tr>
            </thead>
            <tbody>
              {time_array_depo.length > 0 &&
                time_array_depo.map((d, i) => (
                  <tr key={i} className="even:bg-gray-50">
                    <td className="border whitespace-nowrap px-1">{i + 1}</td>
                    <td className="border whitespace-nowrap px-1">
                      {d.DRIVER ? d?.DRIVER.toUpperCase() : "-"}
                    </td>
                    <td className="border whitespace-nowrap px-1">
                      {d?.HELPER}
                    </td>
                    <td className="border whitespace-nowrap px-1">
                      {d?.NOPOL}
                    </td>
                    <td className="border whitespace-nowrap px-1">{d?.Depo}</td>
                    <td className="border whitespace-nowrap px-1">
                      {this.cal(d["log"]) > this.state.hari
                        ? this.state.hari
                        : this.cal(d["log"])}
                    </td>
                    <td className="border whitespace-nowrap px-1">
                      {d["total_jam_kal"] ? d["total_jam_kal"] : 0}
                    </td>
                    <td className="border whitespace-nowrap px-1">
                      {d["target_hour"]}
                    </td>
                    {d["total_jam_kal"] ? (
                      <td className="border whitespace-nowrap px-1">
                        {(
                          (d["total_jam_kal"] / d["target_hour"]) *
                          100
                        ).toFixed(1)}
                      </td>
                    ) : (
                      <td className="border whitespace-nowrap px-1">0</td>
                    )}
                    <td className="border whitespace-nowrap px-1">
                      {d["target_percent"]}
                    </td>
                    <td className="border whitespace-nowrap px-1">
                      {(d["total_jam_kal"] / d["target_hour"]) * 100 >=
                      d["target_percent"]
                        ? "Yes"
                        : "No"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className={`${time_array_depo.length === 0 && "hidden"} mt-2`}>
          {this.props.mode === "excel" ? (
            <button
              className="button_main w-full"
              onClick={(e, done) => {
                this.generateDataEXCEL(time_array_depo, done);
              }}
            >
              {loading_csv
                ? "Download data . . ."
                : "Download Target vs Actual Working Hours"}
            </button>
          ) : (
            <CSVLink
              className="button_main w-full"
              data={dataCSV}
              separator={separator}
              filename={`Driver Data.csv`}
              onClick={(e, done) => {
                this.generateDataCSV(time_array_depo, done);
              }}
            >
              {loading_csv
                ? "Download data . . ."
                : "Download Target vs Actual Working Hours"}
            </CSVLink>
          )}
        </div>
      </div>
    );
    return (
      <div>
        {filter_content}
        {loading_content}
        {preview}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tracking: state.tracking,
  data: state.data,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadTrackingDriver);
