/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import MapGL, {
  Image,
  NavigationControl,
  GeolocateControl,
  Popup,
} from "@urbica/react-map-gl";
import Draw from "@urbica/react-map-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import Geocoder from "react-map-gl-geocoder";
import { Link } from "react-router-dom";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { pointsWithinPolygon, points, polygon } from "@turf/turf";

/*PERSONAL COMPONENT*/
//popup
import OUTLET_POPUP from "../popup/OUTLET_POPUP";
import OUTLET_SURVEY_POPUP from "../popup/OUTLET_SURVEY_POPUP";
import SALESMAN_POPUP from "../popup/SALESMAN_POPUP";
import DRIVER_POPUP from "../popup/DRIVER_POPUP";
import DEPO_POPUP from "../popup/DEPO_POPUP";
import OMSET_OUTLET_POPUP from "../popup/OMSET_OUTLET_POPUP";
import OMSET_SALESMAN_POPUP from "../popup/OMSET_SALESMAN_POPUP";

//layer
import OUTLET_LAYER from "../layer/OUTLET_LAYER";
import COMPETITOR_SUCCESS_LAYER from "../layer/COMPETITOR_SUCCESS_LAYER";
import COMPETITOR_FAILED_LAYER from "../layer/COMPETITOR_FAILED_LAYER";
import OUTLET_BY_SALESMAN_LAYER from "../layer/OUTLET_BY_SALESMAN_LAYER";
import MERCH_LAYER from "../layer/MERCH_LAYER";
import SalesmanTokoLayer from "../layer/SalesmanTokoLayer";
import TokoPlotLayer from "../layer/TokoPlotLayer";
import SALESMAN_LAYER from "../layer/SALESMAN_LAYER";
import SalesmanLogLayer from "../layer/SalesmanLogLayer";
import DriverLayer from "../layer/DriverLayer";
import DriverLogLayer from "../layer/DriverLogLayer";
import OMSET_SALESMAN_LAYER from "../layer/OMSET_SALESMAN_LAYER";
import OMSET_OUTLET_LAYER from "../layer/OMSET_OUTLET_LAYER";
import DepoLayer from "../layer/DepoLayer";
import RadiusLayer from "../layer/RadiusLayer";
import RayonLayer from "../layer/RayonLayer";
import CycleLayer from "../layer/CycleLayer";
import DayLayer from "../layer/DayLayer";

/*REDUX FUNCTION*/
import {
  setSalesmanMode,
  setOutletMode,
  popUpAction,
  add_depo,
  changePointCompetitor,
} from "../../actions/dataActions";
import {
  setMap,
  addPolygon,
  addPointResult,
  changePolygon,
  selectedDrawFeature,
  setDrawMode,
  replacePoint,
  setDrawModeCompetitor,
  inactivate_draw_point_depo,
  inactivate_edit_point_depo,
  set_polygon_depo,
  set_unplot_polygon,
  rayon_polygon,
} from "../../actions/mapActions";
import { set_value_properties } from "../../actions/propertiesActions";

/*PICTURE ASSET*/
import motor from "../../img/bike.png";
import bike_red from "../../img/bike_red.png";
import bike_green from "../../img/bike_green.png";
import bike_blue from "../../img/bike_blue.png";
import icon_cluster from "../../img/icon_cluster.png";
import icon_nama from "../../img/icon_nama.png";
import icon_bike_mode from "../../img/icon_bike_mode.png";
import icon_status_mode from "../../img/icon_status_mode.png";
import icon_toko_mode from "../../img/icon_toko_mode.png";
import logoStreet from "../../img/icon_map_street.png";
import logoSatellite from "../../img/icon_map_satellite.png";
import logoLight from "../../img/icon_map_light.png";
import logoDark from "../../img/icon_map_dark.png";
import logo from "../../img/logo.png";
import truck from "../../img/truck.png";
import truck_blue from "../../img/truck_blue.png";
import truck_green from "../../img/truck_green.png";
import truck_red from "../../img/truck_red.png";
import rumah from "../../img/icon_rumah.png";
import titik from "../../img/icon_circle.png";
import none from "../../img/none.png";

/*GENERAL*/
import isEqual from "../../validation/is-equal";
import { drawStyle } from "../../editor/DrawStyle";
import { drawStyleDepo } from "../../editor/DrawStyleDepo";

/*NON IMPORT*/
const iconClass =
  "fixed right-[10px] z-[3] bg-white w-[30px] h-[30px] border-solid border-2 border-[#73adde] rounded-md cursor-pointer justify-center";

class MAP_PARENT extends Component {
  _map = React.createRef();
  _draw = React.createRef();
  geocoderContainerRef = React.createRef();
  state = {
    mapStyle: "mapbox://styles/mapbox/streets-v12",
    viewport: {
      latitude: -0.811123,
      longitude: 113.921327,
      zoom: 4,
    },
    outlet_list: [],
    list_marker: [],
    clickedFeature: {},
    drag: {
      cliked: false,
      screenX: 10,
      screenY: 10,
    },
    modalSelectMode: false,
    modalSelectMap: false,
    modalSelectModeOutlet: false,
    modalDrawComponent: false,
    mapMode: "street",
    outlet: {},
    polygonList: [],
    list_menu_active: [],
    is_merch_active: false,
    is_distribution_active: false,
    testState: false,
    depo_geojson: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: [0, 0],
          },
        },
      ],
    },
    polygon_depo: {
      type: "FeatureCollection",
      features: [],
    },
    polygon_unplot: {
      type: "FeatureCollection",
      features: [],
    },
  };

  componentDidMount() {
    this.setState({ outlet_list: this.props.data.outlet_list });
    const map = this._map.current.getMap();
    const self = this;
    try {
      map.on("load", () => {
        map.on("mouseenter", "outlet-list-lyr", () => {
          map.getCanvas().style.cursor = "pointer";
        });
        map.on("mouseleave", "outlet-list-lyr", () => {
          map.getCanvas().style.cursor = "";
        });
        map.on("moveend", () => {
          const { lng, lat } = map.getCenter();
          if (this.props.map.drawMode !== "draw_polygon") {
            this.setState({
              viewport: {
                ...self.state.viewport,
                longitude: lng,
                latitude: lat,
                zoom: map.getZoom(),
              },
            });
          }
        });
        this.props.setMap(this._map.current.getMap());
      });
    } catch (e) {}
    this.setState({
      drag: {
        cliked: false,
        screenX: document.documentElement.clientWidth - 410,
        screenY: document.documentElement.clientHeight - 410,
      },
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { mapMode } = this.state;
    if (!isEqual(prevProps.map.polygonList, this.props.map.polygonList)) {
      this.setState({ polygonList: this.props.map.polygonList });
    }
    if (
      prevProps.map.depo.Depo !== this.props.map.depo.Depo &&
      this.props.map.edit_point_depo_status
    ) {
      const long = this.props.map.depo.location.coordinates[0]
        ? this.props.map.depo.location.coordinates[0]
        : 0;
      const lat = this.props.map.depo.location.coordinates[1]
        ? this.props.map.depo.location.coordinates[1]
        : 0;
      let depo_geojson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              coordinates: [long, lat],
              type: "Point",
            },
          },
        ],
      };
      this.setState({ depo_geojson });
    }
    if (mapMode !== prevState.mapMode) {
      let mapStyle = "";
      switch (mapMode) {
        case "street":
          mapStyle = "mapbox://styles/mapbox/streets-v12";
          break;
        case "light":
          mapStyle = "mapbox://styles/mapbox/light-v10";
          break;
        case "dark":
          mapStyle = "mapbox://styles/mapbox/dark-v10";
          break;
        default:
          mapStyle = "mapbox://styles/mapbox/satellite-streets-v11";
      }
      this.setState({ mapStyle });
    }

    if (this.state.drag.cliked) {
      window.addEventListener("mousemove", this.sideMouseMove);
      window.addEventListener("mouseup", this.sideMouseUp);
    }

    if (
      this.props.tracking.driver_geojsonPoint !==
        prevProps.tracking.driver_geojsonPoint &&
      Object.keys(this.props.tracking.driver_geojsonPoint).length !== 0
    ) {
      const { driver_geojsonPoint } = this.props.tracking;
      const map = this._map.current.getMap();
      let cntrFly = [0, 0];
      cntrFly = [
        driver_geojsonPoint.features[0].geometry.coordinates[0],
        driver_geojsonPoint.features[0].geometry.coordinates[1],
      ];
      map.flyTo({
        center: cntrFly,
        // zoom: 10,
      });
    }

    if (this.props.tracking?.point !== prevProps.tracking.point) {
      if (this.props.tracking.point) {
        const map = this._map.current.getMap();
        let pCoords = [0, 0];
        pCoords = [
          this.props.tracking.point.longitude,
          this.props.tracking.point.latitude,
        ];
        map.flyTo({
          center: pCoords,
          // zoom: 10,
        });
      }
    }

    if (
      this.props.tracking.driver_geojsonMultiPoint !==
        prevProps.tracking.driver_geojsonMultiPoint &&
      Object.keys(this.props.tracking.driver_geojsonPoint).length !== 0
    ) {
      const { driver_geojsonMultiPoint } = this.props.tracking;
      const map = this._map.current.getMap();
      let cntrFly = [0, 0];
      let lastIndex = driver_geojsonMultiPoint.features.length - 1;
      cntrFly = [
        driver_geojsonMultiPoint.features[lastIndex].geometry.coordinates[0],
        driver_geojsonMultiPoint.features[lastIndex].geometry.coordinates[1],
      ];
      map.flyTo({
        center: cntrFly,
        zoom: 12,
      });
    }
    if (this.props.tracking.lineString !== prevProps.tracking.lineString) {
      if (this.props.tracking.lineString.length !== 0) {
        const map = this._map.current.getMap();
        let pCoords = [0, 0];
        let lastIndex = this.props.tracking.lineString.length - 1;
        pCoords = [
          this.props.tracking.lineString[lastIndex].longitude,
          this.props.tracking.lineString[lastIndex].latitude,
        ];
        map.flyTo({
          center: pCoords,
          zoom: 12,
        });
      }
    }
  }

  layerOnClick = (modal_variable, event) => {
    try {
      const location = event?.features?.[0]?.properties?.location;
      let geometry = {};
      if (location) {
        geometry = JSON.parse(location);
      }
      const center_1 = geometry?.coordinates;
      const center_2 = event?.features?.[0]?.geometry?.coordinates;
      const center_3 = [event?.lngLat?.lng, event?.lngLat?.lat];
      let center_final = [];
      if (center_1?.[0]) {
        center_final = center_1;
      } else if (center_2[0]) {
        center_final = center_2;
      } else {
        center_final = center_3;
      }

      const pop_up_map = (
        <Popup
          tipSize={7}
          anchor="bottom"
          longitude={center_final[0]}
          latitude={center_final[1]}
          closebutton={true}
          closeOnClick={false}
          style={{
            zIndex: 2,
          }}
          onClose={() => {
            let depo_geojson = {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: [0, 0],
                  },
                },
              ],
            };
            this.setState(
              {
                depo_geojson,
              },
              () => {
                this.props.set_value_properties({
                  key: "type_pop_up",
                  value: modal_variable,
                });
                this.props.set_value_properties({
                  key: "modal_pop_up",
                  value: false,
                });
                this.props.set_value_properties({
                  key: "pop_up_map",
                  value: null,
                });
                this.props.popUpAction(null);
              }
            );
            this.props.inactivate_edit_point_depo();
          }}
        >
          <div
            style={{
              maxHeight: "45vh",
              overflowY: "scroll",
              overflowX: "hidden",
              padding: "0px 10px 0px 0px",
            }}
          >
            <div
              className="text_inferior"
              style={{ fontSize: "10px", lineHeight: "125%" }}
            >
              {event.features[0]?.properties["Nama Salesman"]
                ? `SALESMAN NAME`
                : event.features[0]?.properties["Nama Toko"]
                ? `NAME`
                : event.features[0]?.properties["NAMA OUTLET"]
                ? `NAME`
                : event.features[0]?.properties["REGION"]
                ? `REGION NAME`
                : event.features[0]?.properties["DRIVER"]
                ? "License plat number"
                : `DEPO NAME`}
            </div>
            <div
              className="text_bold"
              style={{
                fontSize: "15px",
                wordBreak: "break-word",
                marginTop: 2.5,
              }}
            >
              {event.features[0]?.properties["Nama Toko"]
                ? event.features[0]?.properties["Nama Toko"]
                : event.features[0]?.properties["NAMA OUTLET"]
                ? event.features[0]?.properties["NAMA OUTLET"]
                : event.features[0]?.properties["REGION"]
                ? event.features[0]?.properties["REGION"]
                : event.features[0]?.properties["Nama Salesman"]
                ? event.features[0]?.properties["Nama Salesman"]
                : event.features[0]?.properties["NOPOL"]
                ? `${event.features[0]?.properties["NOPOL"]}`
                : event.features[0]?.properties["Depo"]}
            </div>
          </div>
        </Popup>
      );

      const map = this._map.current.getMap();
      map.flyTo({
        center: center_final,
        zoom: 18,
      });

      let pop_up_properties = event?.features?.[0]?.properties || {};
      const pop_up_key = event?.features?.[0]?.properties?._id || "";

      if (modal_variable === "modal_omset_salesman") {
        //kd_sales_arr
        //divisi_arr

        const location = event?.features?.[0]?.properties?.location || "{}";
        const user = event?.features?.[0]?.properties?.user || "{}";
        const kd_sales_arr =
          event?.features?.[0]?.properties?.kd_sales_arr || "{}";
        const divisi_arr = event?.features?.[0]?.properties?.divisi_arr || "{}";

        pop_up_properties.location = JSON.parse(location);
        pop_up_properties.user = JSON.parse(user);
        pop_up_properties.kd_sales_arr = JSON.parse(kd_sales_arr);
        pop_up_properties.divisi_arr = JSON.parse(divisi_arr);
      }

      this.props.set_value_properties({
        key: "type_pop_up",
        value: modal_variable,
      });

      this.props.set_value_properties({
        key: "pop_up_map",
        value: pop_up_map,
      });
      this.props.set_value_properties({
        key: "modal_pop_up",
        value: true,
      });
      this.props.set_value_properties({
        key: "pop_up_properties",
        value: pop_up_properties,
      });
      this.props.set_value_properties({
        key: "pop_up_key",
        value: pop_up_key,
      });
    } catch (error) {
      console.log("error=", error);
    }
  };

  on_close_pop_up = () => {
    this.props.set_value_properties({
      key: "modal_pop_up",
      value: false,
    });
  };

  zoomOnCluster(features, id) {
    const map = this._map.current.getMap();
    const mapSource = map.getSource(id);
    const self = this;
    var clusterId = features[0].properties.cluster_id;
    let point_count = features[0].properties.point_count;
    let isSameCoordinate = false;
    // Get all points under a cluster
    mapSource.getClusterLeaves(
      clusterId,
      point_count,
      0,
      function (err, aFeatures) {
        isSameCoordinate = aFeatures.every((ft) => {
          let statLng =
            ft.geometry.coordinates[0] === aFeatures[0].geometry.coordinates[0];
          let statLat =
            ft.geometry.coordinates[1] === aFeatures[0].geometry.coordinates[1];
          return statLng && statLat;
        });
        if (isSameCoordinate) {
          self.props.setFilteredFeatures(aFeatures);
        }
        mapSource.getClusterExpansionZoom(clusterId, function (err, zoom) {
          if (err) return;
          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: isSameCoordinate ? 13 : zoom,
          });
        });
      }
    );
  }

  getVisibility = (prm) => {
    if (prm) {
      return "visible";
    } else {
      return "none";
    }
  };

  toggleModalSelectMap = () => {
    const { modalSelectMap } = this.state;
    this.setState({ modalSelectMap: !modalSelectMap });
  };

  setSelectedMap = (val) => {
    this.setState({ mapMode: val });
  };

  sideMouseMove = (e) => {
    if (this.state.drag.cliked) {
      this.setState((prev) => ({
        drag: {
          ...prev.drag,
          screenX: e.clientX,
          screenY: e.clientY,
        },
      }));
    }
  };

  sideMouseUp = () => {
    this.setState((prev) => ({
      drag: { ...prev.drag, cliked: false },
    }));
  };

  sideCliked = () => {
    this.setState((prev) => ({
      drag: { ...prev.drag, cliked: true },
    }));
  };

  cekPoint = (data) => {
    const outletList = this.props.data.outlet_list_by_salesman_filter_0;
    const new_features = outletList.map((e) => {
      return e.location.coordinates;
    });
    let pointList = points(new_features);
    let polygonList = polygon(data.geometry.coordinates);
    let result = pointsWithinPolygon(pointList, polygonList);
    this.props.addPointResult(result.features);
  };

  onDrawCreate = (data) => {
    let newFt = { ...data.features[0] };
    const arrHari = ["R01", "R02", "R03", "R04", "R05", "R06"];
    let newId = "R01";
    const idList = this.props.map.polygonList.map(
      (p) => p.properties.hari || []
    );
    for (let i = 0; i < arrHari.length; i++) {
      if (!idList.includes(arrHari[i])) {
        newId = arrHari[i];
        break;
      }
    }
    newFt["properties"]["hari"] = newId;
    this.props.addPolygon(data.features[0]);
  };

  onDrawCreateCompetitor = (data) => {
    const { depo_loc_list } = this.props.data;
    const index = depo_loc_list.length + 1;
    let features = data.features;
    const location = features?.[0]?.geometry;
    this.props.inactivate_draw_point_depo();
    const body = {
      type: "Feature",
      id: features[0].id,
      Depo: `DEPO TES ${index}`,
      location,
    };
    this.props.add_depo(body);
  };

  onDrawUpdate = (data) => {
    if (data.action === "move") {
      let body = this.props.map.depo;
      let newBody = {
        ...body,
        location: {
          coordinates: data.features[0].geometry.coordinates,
        },
      };
      this.props.changePointCompetitor(newBody);
      let depo_geojson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              coordinates: data.features[0].geometry.coordinates,
              type: "Point",
            },
          },
        ],
      };
      this.setState({ depo_geojson });
    }
  };

  replacePoint = (idx) => {
    const outletList = this.props.data.outlet_list_by_salesman_filter_0;
    const new_features = outletList.map((e) => {
      return e.location.coordinates;
    });
    const data = this.props.map.polygonList[idx];
    let pointList = points(new_features);
    let polygonList = polygon(data.geometry.coordinates);
    let result = pointsWithinPolygon(pointList, polygonList);
    this.props.replacePoint({ idx, feature: result.features });
  };

  set_data_poligon_depo = (data) => {
    this.setState({ poligon_depo: data });
  };

  render() {
    //local storage

    //local state
    const {
      drag,
      modalSelectMode,
      modalSelectMap,
      modalSelectModeOutlet,
      modalDrawComponent,
      mapMode,
    } = this.state;

    //global props
    const { pick_status, salesman_mode, outlet_mode } = this.props.data;
    const { selectedDrawFeature } = this.props.map;
    const {
      pop_up_properties,
      pop_up_key,
      modal_pop_up,
      pop_up_map,
      type_pop_up,
    } = this.props.properties;

    //content
    let logoMap;
    switch (mapMode) {
      case "street":
        logoMap = logoStreet;
        break;
      case "light":
        logoMap = logoLight;
        break;
      case "dark":
        logoMap = logoDark;
        break;
      default:
        logoMap = logoSatellite;
    }

    let logoSalesman, logoOutlet;
    switch (salesman_mode) {
      case "cluster":
        logoSalesman = icon_cluster;
        break;
      case "icon":
        logoSalesman = icon_bike_mode;
        break;
      case "status":
        logoSalesman = icon_status_mode;
        break;
      default:
        logoSalesman = icon_nama;
    }

    switch (outlet_mode) {
      case "cluster":
        logoOutlet = icon_cluster;
        break;
      default:
        logoOutlet = icon_toko_mode;
    }

    const selectModeContent = (
      <div className="modal_left_side z-[2] p-[5px]">
        <table style={{ textAlign: "center", width: 150, fontSize: 11 }}>
          <tbody>
            <tr>
              <td
                style={{ width: "50%" }}
                onClick={() => this.props.setSalesmanMode("cluster")}
              >
                <div className="justify-center">
                  <img
                    className={"img-rounded h-[50px] mx-auto"}
                    src={icon_cluster}
                    alt={"Mode cluster"}
                  />
                </div>
                <div>Cluster</div>
              </td>
              <td onClick={() => this.props.setSalesmanMode("icon")}>
                <div className="justify-center">
                  <img
                    className={"img-rounded h-[50px] mx-auto"}
                    src={icon_bike_mode}
                    alt={"Mode icon only"}
                  />
                </div>
                <div>
                  <span>Icon Only</span>
                </div>
              </td>
            </tr>
            <tr>
              <td onClick={() => this.props.setSalesmanMode("name")}>
                <div className="justify-center">
                  <img
                    className={"img-rounded w-[50px] mx-auto"}
                    src={icon_nama}
                    alt={"Mode icon dan teks"}
                  />
                </div>
                <div>Icon + Texts</div>
              </td>
              <td onClick={() => this.props.setSalesmanMode("status")}>
                <div className="justify-center">
                  <img
                    className={"img-rounded w-[50px] mx-auto"}
                    src={icon_status_mode}
                    alt={"Mode status"}
                  />
                </div>
                <div>Status</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );

    const selectOutletModeContent = (
      <div className="modal_left_side z-[2] p-[5px]">
        <table style={{ textAlign: "center", width: 150, fontSize: 11 }}>
          <tbody>
            <tr>
              <td
                style={{ width: "50%" }}
                onClick={() => this.props.setOutletMode("cluster")}
              >
                <div className="justify-center">
                  <img
                    className={"img-rounded h-[50px] mx-auto"}
                    src={icon_cluster}
                    alt={"Mode cluster"}
                  />
                </div>
                <div>Cluster</div>
              </td>
              <td onClick={() => this.props.setOutletMode("point")}>
                <div className="justify-center">
                  <img
                    className={"img-rounded h-[50px] mx-auto"}
                    src={icon_toko_mode}
                    alt={"Mode point only"}
                  />
                </div>
                <div>
                  <span>Point</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );

    const selectMapModeContent = (
      <div className="modal_left_side">
        <table
          style={{ textAlign: "center", width: 150, padding: 5, fontSize: 11 }}
        >
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>
                <div>
                  <img
                    className={"img-rounded h-[50px] mx-auto"}
                    src={logoStreet}
                    width={50}
                    alt={"Street mode"}
                    onClick={() => this.setSelectedMap("street")}
                  />
                </div>
                <div>Street</div>
              </td>
              <td>
                <div>
                  <img
                    className={"img-rounded h-[50px] mx-auto"}
                    src={logoSatellite}
                    width={50}
                    alt={"Satellite mode"}
                    onClick={() => this.setSelectedMap("satellite")}
                  />
                </div>
                <div>
                  <span>Satellite</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <img
                    className={"img-rounded h-[50px] mx-auto"}
                    src={logoDark}
                    width={50}
                    alt={"Dark mode"}
                    onClick={() => this.setSelectedMap("dark")}
                  />
                </div>
                <div>Dark</div>
              </td>
              <td>
                <div>
                  <img
                    className={"img-rounded h-[50px] mx-auto"}
                    src={logoLight}
                    width={50}
                    alt={"Light mode"}
                    onClick={() => this.setSelectedMap("light")}
                  />
                </div>
                <div>Light</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
    return (
      <main
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
        }}
      >
        <Link to="/">
          <img alt="Nabati" src={logo} className="logo" />
        </Link>
        <section
          style={{
            backgroundImage: `url(${logoMap})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="toggle_map_mode"
          onClick={this.toggleModalSelectMap}
        >
          {modalSelectMap && (
            <div className="noselect">{selectMapModeContent}</div>
          )}
        </section>
        <section
          className={`${iconClass} top-[185px]`}
          onClick={() => this.setState({ modalSelectMode: !modalSelectMode })}
        >
          <img
            className={"mx-auto h-[25px]"}
            src={logoSalesman}
            alt={"Dark mode"}
          />
          {modalSelectMode && selectModeContent}
          <div className="text-[0.55rem] w-[30px] bg-white mt-[5px] border-solid border-1 border-[#73adde] text-center">
            ICON
          </div>
        </section>
        <section
          className={`${iconClass} top-[235px]`}
          onClick={() =>
            this.setState({ modalSelectModeOutlet: !modalSelectModeOutlet })
          }
        >
          <img
            className={"mx-auto h-[25px]"}
            src={logoOutlet}
            alt={"Dark mode"}
          />
          {modalSelectModeOutlet && selectOutletModeContent}
          <div className="text-[0.55rem] w-[30px] bg-white mt-[5px] border-solid border-1 border-[#73adde] text-center">
            STORE
          </div>
        </section>

        <MapGL
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
          mapStyle={this.state.mapStyle}
          accessToken="pk.eyJ1IjoiZWdpcHJhdGFtYSIsImEiOiJjbGN2OGEwZDYwNHIyM3JvemFxMmJ0Y3RoIn0.QtsT--oqHJnb3A79a9c_3A"
          latitude={this.state.viewport.latitude}
          longitude={this.state.viewport.longitude}
          zoom={this.state.viewport.zoom}
          maxZoom={20}
          minZoom={2}
          onViewportChange={(e) => {
            return;
          }}
          attributionControl={false}
          {...this.props}
          ref={this._map}
          cursorStyle={pick_status === "map" ? "crosshair" : null}
        >
          <div
            id="geocoder"
            ref={this.geocoderContainerRef}
            className={"absolute top-[10px] right-[45px] z-5"}
          />
          <Image id="motor" image={motor} />
          <Image id="motor_omset" image={bike_green} sdf={true} />
          <Image id="bike_green" image={bike_green} />
          <Image id="bike_blue" image={bike_blue} />
          <Image id="bike_red" image={bike_red} />
          <Image id="truck" image={truck} />
          <Image id="truck_green" image={truck_green} />
          <Image id="truck_blue" image={truck_blue} />
          <Image id="truck_red" image={truck_red} />
          <Image id="rumah" image={rumah} sdf={true} />
          <Image id="titik" image={titik} sdf={true} />
          <Image id="none" image={none} sdf={true} />

          <DriverLogLayer />
          <SALESMAN_LAYER layerOnClick={this.layerOnClick} />
          <OMSET_SALESMAN_LAYER layerOnClick={this.layerOnClick} />
          <DriverLayer layerOnClick={this.layerOnClick} />
          <MERCH_LAYER layerOnClick={this.layerOnClick} />
          <OMSET_OUTLET_LAYER layerOnClick={this.layerOnClick} />
          <OUTLET_LAYER layerOnClick={this.layerOnClick} />
          <COMPETITOR_SUCCESS_LAYER layerOnClick={this.layerOnClick} />
          <COMPETITOR_FAILED_LAYER layerOnClick={this.layerOnClick} />
          <SalesmanTokoLayer layerOnClick={this.layerOnClick} />
          <SalesmanLogLayer layerOnClick={this.layerOnClick} />
          <TokoPlotLayer layerOnClick={this.layerOnClick} />
          <DepoLayer layerOnClick={this.layerOnClick} />
          <RadiusLayer layerOnClick={this.layerOnClick} />
          <RayonLayer layerOnClick={this.layerOnClick} />
          <CycleLayer layerOnClick={this.layerOnClick} />
          <DayLayer layerOnClick={this.layerOnClick} />
          <OUTLET_BY_SALESMAN_LAYER layerOnClick={this.layerOnClick} />

          <NavigationControl showCompass showZoom position="top-right" />
          <GeolocateControl
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />
          <Geocoder
            containerRef={this.geocoderContainerRef}
            mapRef={this._map}
            onViewportChange={(viewport) => {
              const self = this;
              this.setState({ viewport });
              this.setState({ onGeocode: true }, () => {
                setTimeout(() => {
                  self.setState({ onGeocode: false });
                }, 20000);
              });
            }}
            mapboxApiAccessToken="pk.eyJ1IjoiZWdpcHJhdGFtYSIsImEiOiJjbGN2OGEwZDYwNHIyM3JvemFxMmJ0Y3RoIn0.QtsT--oqHJnb3A79a9c_3A"
            placeholder={"Search location"}
            style={{
              position: "absolute",
              top: 5,
              right: 35,
            }}
            transitionDuration={200}
          />

          {!modalDrawComponent && modal_pop_up && (
            <React.Fragment>
              {pop_up_map}
              <main
                className={`detail-info shadow rounded`}
                style={{ top: `${drag.screenY}px`, left: `${drag.screenX}px` }}
              >
                <section className="sticky top-0 left-0 top_bar_pop_up">
                  <div
                    className="w-full border-4 top-0 left-0 cursor-move"
                    style={{ zIndex: 51 }}
                    onMouseDown={this.sideCliked}
                  />
                  <div className="text_right">
                    <button
                      className="button_small background_red text_right"
                      onClick={this.on_close_pop_up}
                    >
                      X
                    </button>
                  </div>
                </section>

                <section className="relative padding">
                  {[
                    "modal_distribution",
                    "modal_competitor",
                    "competitor_success",
                    "competitor_failed",
                  ].includes(type_pop_up) ? (
                    <OUTLET_POPUP
                      properties={pop_up_properties}
                      key={pop_up_key}
                    />
                  ) : type_pop_up === "modal_plotting" ? (
                    <OUTLET_SURVEY_POPUP
                      properties={pop_up_properties}
                      key={pop_up_key}
                    />
                  ) : type_pop_up === "modal_salesman" ? (
                    <SALESMAN_POPUP
                      properties={pop_up_properties}
                      key={pop_up_key}
                    />
                  ) : type_pop_up === "modal_omset_salesman" ? (
                    <OMSET_SALESMAN_POPUP
                      properties={pop_up_properties}
                      key={pop_up_key}
                    />
                  ) : type_pop_up === "modal_driver" ? (
                    <DRIVER_POPUP
                      properties={pop_up_properties}
                      key={pop_up_key}
                    />
                  ) : type_pop_up === "modal_depo" ? (
                    <DEPO_POPUP
                      properties={pop_up_properties}
                      key={pop_up_key}
                    />
                  ) : type_pop_up === "modal_omset" ? (
                    <OMSET_OUTLET_POPUP
                      properties={pop_up_properties}
                      key={pop_up_key}
                    />
                  ) : (
                    <OUTLET_POPUP
                      properties={pop_up_properties}
                      key={pop_up_key}
                    />
                  )}
                </section>
              </main>
            </React.Fragment>
          )}

          {this.props.map.statusDraw === "competitor" && (
            <Draw
              mode={this.props.map.drawMode}
              onDrawCreate={this.onDrawCreateCompetitor}
              data={this.state.depo_geojson}
              onDrawUpdate={(data) => {
                this.onDrawUpdate(data);
              }}
              onDrawModeChange={(data) => {
                this.props.setDrawModeCompetitor(data.mode);
              }}
              combineFeaturesControl={false}
              uncombineFeaturesControl={false}
              pointControl={false}
              lineStringControl={false}
              polygonControl={false}
              trashControl={false}
              ref={this._draw}
              styles={drawStyleDepo}
            />
          )}

          {this.props.map.statusDraw === "salesman" && (
            <Draw
              mode={this.props.map.drawMode}
              onDrawCreate={this.onDrawCreate}
              data={{
                type: "FeatureCollection",
                features: this.state.polygonList,
              }}
              onChange={(data) => {
                this.props.changePolygon(data.features);
              }}
              onDrawSelectionChange={(data) => {
                this.props.selectedDrawFeature(data.features[0]);
              }}
              onDrawModeChange={(data) => {
                this.props.setDrawMode(data.mode);
              }}
              modeOptions={{ featureId: selectedDrawFeature?.id || "" }}
              combineFeaturesControl={false}
              uncombineFeaturesControl={false}
              pointControl={false}
              lineStringControl={false}
              polygonControl={false}
              trashControl={false}
              ref={this._draw}
              styles={drawStyle}
            />
          )}

          {this.props.map.statusDraw === "poligon_depo" && (
            <Draw
              mode={this.props.map.drawMode}
              data={this.props.map.polygon_depo}
              onChange={(data) => {
                this.props.set_polygon_depo(data);
                this.props.setDrawMode("simple_select");
              }}
              position="bottom-right"
              styles={drawStyle}
              combineFeaturesControl={false}
              uncombineFeaturesControl={false}
              pointControl={false}
              lineStringControl={false}
              polygonControl={false}
              trashControl={false}
            />
          )}

          {this.props.map.statusDraw === "unplot_poligon" && (
            <Draw
              className={"z-10"}
              mode={this.props.map.drawMode}
              data={this.props.map.polygon_unplot}
              onChange={(data) => {
                this.props.set_unplot_polygon(data);
              }}
              position="bottom-right"
              styles={drawStyle}
              combineFeaturesControl={false}
              uncombineFeaturesControl={false}
              pointControl={false}
              lineStringControl={false}
              polygonControl={false}
              trashControl={true}
            />
          )}

          {this.props.map.statusDraw === "rayon_poligon" && (
            <Draw
              className={"z-10"}
              mode={this.props.map.drawMode}
              data={this.props.map.polygon_unplot}
              onChange={(data) => {
                this.props.rayon_polygon(data);
              }}
              position="bottom-right"
              styles={drawStyle}
              combineFeaturesControl={false}
              uncombineFeaturesControl={false}
              pointControl={false}
              lineStringControl={false}
              polygonControl={false}
              trashControl={true}
            />
          )}
        </MapGL>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  tracking: state.tracking,
  map: state.map,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  setSalesmanMode,
  setOutletMode,
  setMap,
  addPolygon,
  addPointResult,
  changePolygon,
  selectedDrawFeature,
  setDrawMode,
  replacePoint,
  popUpAction,
  setDrawModeCompetitor,
  changePointCompetitor,
  add_depo,
  inactivate_draw_point_depo,
  inactivate_edit_point_depo,
  set_polygon_depo,
  set_unplot_polygon,
  rayon_polygon,
  set_value_properties,
})(MAP_PARENT);
