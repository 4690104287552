/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import STEP_1_2_EXPAND_RAYON from "./STEP_1_2_EXPAND_RAYON";

/*REDUX FUNCTION*/
import {
  get_outlet_list_by_area,
  turn_on_menu,
  turn_off_menu,
  search_rayon,
  set_config_mode_menu,
  set_list,
} from "../../actions/dataActions";
import {
  hide_outlet_without_rayon,
  show_all_outlet,
} from "../../actions/rayonActions";

/*PICTURE ASSET*/

/*GENERAL*/

/*NON IMPORT*/

class STEP_1_1_GET_DATA_OUTLET extends Component {
  state = {
    mode_closed: "without_closed", //without_closed | with_closed
    mode_omset: "without_omset", //with_omset | without_omset
    findSearch: "",
  };

  on_hide_outlet_without_rayon = () => {
    this.props.hide_outlet_without_rayon();
  };

  on_show_all_outlet = () => {
    this.props.show_all_outlet();
    this.props.set_config_mode_menu("distribution", "rayon");
    this.props.set_list({
      text: "rayon_select",
      list: "",
    });
  };

  on_change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  on_submit_get_toko_list = (event) => {
    this.props.turn_off_menu("outlet_by_salesman");
    const { mode_closed, mode_omset } = this.state;
    const { list_menu_active } = this.props.data;
    const { country_selected } = this.props.auth;
    const config_plan = list_menu_active.find((e) => e.key === "salesman")
      ?.config?.value;
    const name = config_plan.map((e) => e.value);
    const name_arr = config_plan.map((e) => {
      return {
        key: e.value,
        label: e.value,
        value: e.value,
        name: "name",
      };
    });
    const params = {
      area: "depo",
      name,
      name_arr,
      is_plan: true,
      country: country_selected,
      eventSelect: event,
      mode_closed,
      mode_omset,
    };
    this.props.get_outlet_list_by_area(params);

    let config_toko = list_menu_active.find(
      (e) => e.key === "distribution"
    ).config;
    this.props.turn_on_menu({
      key: "distribution",
      config: { ...config_toko, ...params, mode: "rayon" },
    });

    this.props.turn_off_menu("salesman");
  };

  search = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  findSearch = (event) => {
    event.preventDefault();
    const { findSearch } = this.state;
    this.props.search_rayon(findSearch);
  };

  render() {
    //local storage

    //local state
    const { mode_closed, mode_omset } = this.state;

    //global props
    const {
      list_menu_active,
      outlet_list_filter_0_back_up,
      loading_status,
      loading_item,
      outlet_list_filter_0,
    } = this.props.data;

    //content
    const outlet_without_rayon = outlet_list_filter_0_back_up.filter(
      (t) => !t.rayon_id
    );

    const config_plan = list_menu_active.find((e) => e.key === "salesman")
      ?.config?.value
      ? list_menu_active.find((e) => e.key === "salesman")?.config?.value
      : [];

    let search_content = (
      <form onSubmit={this.findSearch}>
        <div className="flex gap-1">
          <div className="w-full my-2">
            <input
              type="text"
              placeholder={`Search store name`}
              className="w-full"
              name="findSearch"
              onChange={this.search}
              autoComplete="off"
            />
          </div>
          <button className="px-1" type="submit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </button>
        </div>
      </form>
    );
    return (
      <main>
        <section className="main_container mb-3">
          <p className="badge mb-1" id="blue">
            Step 1.1
          </p>
          <p className="text_medium">Get outlet data in this Depo</p>
          <hr />

          <table className="full_width table">
            <tbody>
              <tr>
                <td colSpan={2}>
                  {" "}
                  <p className="text_inferior">
                    {config_plan.map((e, idx) => {
                      return <b key={idx}>DEPO: {e?.value}</b>;
                    })}
                  </p>
                </td>
              </tr>
              <tr>
                <td className="text_small" style={{ width: "170px" }}>
                  Total outlet before filter
                  <br />
                  <b>
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(outlet_list_filter_0_back_up.length)}
                  </b>
                </td>
                <td rowSpan={2}>
                  <button
                    className="button"
                    id="green"
                    onClick={this.on_show_all_outlet}
                  >
                    Show all outlet
                  </button>
                </td>
              </tr>
              <tr>
                <td className="text_small">
                  Total outlet after filter
                  <br />
                  <b>
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(outlet_list_filter_0.length)}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>

          <p className="text_inferior">Mode closed</p>
          <select
            name="mode_closed"
            value={mode_closed}
            onChange={this.on_change}
          >
            <option value="without_closed">COVERED ONLY</option>
            <option value="with_closed">ALL OUTLET</option>
          </select>

          <br />

          <p className="text_inferior">Mode omset</p>
          <select
            name="mode_omset"
            value={mode_omset}
            onChange={this.on_change}
          >
            <option value="with_omset">WITH OMSET</option>
            <option value="without_omset">WITHOUT OMSET</option>
          </select>
          <p className="text_inferior">
            Use it <b>without_omset</b> if the PostgreSQL server is slow and you
            plan not to use omset in rayonization.
          </p>

          {loading_status && loading_item === "get_outlet_list_by_area" ? (
            <button className="button mt-3" id="green">
              Loading...
            </button>
          ) : (
            <>
              <button
                className="button mt-3"
                id="green"
                onClick={this.on_submit_get_toko_list}
              >
                Get Nabati Outlets
              </button>
            </>
          )}
          {search_content}
        </section>
        <section className="main_container mb-3">
          <p className="badge mb-1" id="blue">
            Step 1.2
          </p>
          <p className="text_medium">
            Run rayonization of outlets without rayon to the nearest rayon group
          </p>
          <hr />
          <table className="table full_width mb-5">
            <tbody>
              <tr>
                <td className="text_small">
                  Total outlet without rayon
                  <br />
                  <b>
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(outlet_without_rayon.length)}
                  </b>
                </td>
                <td>
                  <p className="text_small">Hide outlet without rayon</p>
                  <button
                    className="button mb-5"
                    id="green"
                    onClick={this.on_hide_outlet_without_rayon}
                  >
                    Hide
                  </button>
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
          <STEP_1_2_EXPAND_RAYON />
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.data,
});

export default connect(mapStateToProps, {
  get_outlet_list_by_area,
  turn_on_menu,
  turn_off_menu,
  search_rayon,
  hide_outlet_without_rayon,
  show_all_outlet,
  set_config_mode_menu,
  set_list,
})(STEP_1_1_GET_DATA_OUTLET);
