//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
//Personal Component
import TextFieldGroup from "../common/TextFieldGroup";
//Redux function
import { register_admin_nabati, set_country } from "../../actions/authActions";
import { getSalesmanFilterRegister } from "../../actions/dataActions";
//Picture Asset
import logo from "../../img/logo.png";
//General Function
class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      full_name: "",
      password: "",
      password2: "",
      email: "",
      phone_number: "",
      badge: "admin",
      jabatan: "ASS",
      cakupan_wilayah_kerja: "depo",
      depo: "",
      depo_id: "",
      errors: {},
      show_password: false,
      depo_list: [],
      cabang_list: [],
      regional_list: [],
      negara: "INDONESIA",
      errors_depo: "",
      errors_cabang: "",
      errors_regiional: "",
    };
  }
  componentDidMount() {
    // this.props.getSalesmanFilter(this.props.auth.country_selected);
    this.props.getSalesmanFilterRegister(this.state.negara);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors) {
      return {
        errors: nextProps.errors,
      };
    } else {
      return null;
    }
  }
  toggleShowPassword = () => {
    this.setState({ show_password: !this.state.show_password });
  };
  onSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      // full_name,
      // password,
      // password2,
      // email,
      // phone_number,
      // badge,
      // cakupan_wilayah_kerja,
      // depo_id,
      jabatan,
      depo,
      cabang_list,
      regional_list,
    } = this.state;
    // const body = {
    //   name: name.toLowerCase().replace(/\s/g, ""),
    //   full_name,
    //   password,
    //   password2,
    //   email,
    //   phone_number,
    //   badge,
    //   cakupan_wilayah_kerja,
    //   depo: depo_id,
    // };

    if (jabatan === "ASS") {
      if (depo === "") {
        this.setState({
          errors_depo: "Please chose your depo first",
        });
      } else {
        this.register(name);
      }
    } else if (jabatan === "BM") {
      if (cabang_list.length === 0) {
        this.setState({
          errors_cabang: "Please chose your branch first",
        });
      } else {
        this.register(name);
      }
    } else if (jabatan === "RBM") {
      if (regional_list.length === 0) {
        this.setState({
          errors_regiional: "Please chose your region first",
        });
      } else {
        this.register(name);
      }
    } else {
      this.register(name);
    }
  };
  register = (name) => {
    this.props.register_admin_nabati({
      ...this.state,
      name: name?.toLowerCase()?.replace(/\s/g, ""),
    });
  };
  onChange = (e) => {
    const target = e.target;
    let value = target.value;
    const name = target.name;
    if (name === "name") {
      this.setState({ [name]: value.replace(/\s/g, "") });
    } else {
      this.setState({ [name]: value });
    }
    const { salesman_filter } = this.props.data;

    if (name === "negara") {
      this.props.set_country(value);
      this.props.getSalesmanFilterRegister(value);
    }
    if (name === "depo") {
      const list_depo = salesman_filter?.depo ? salesman_filter.depo : [];
      const depo_id = list_depo.find((d) => d.Depo === value)?._id;
      this.setState({ depo_id, depo_list: [depo_id] });
    }
    if (name === "cabang_list") {
      const list_cabang = salesman_filter?.cabang ? salesman_filter.cabang : [];
      const cabang_id = list_cabang.find((d) => d.CABANG === value)?._id;
      if (cabang_id) {
        this.setState({
          cabang_list: [cabang_id],
        });
      } else {
        this.setState({
          cabang_list: [],
        });
      }
    }
    if (name === "regional_list") {
      const list_regional = salesman_filter?.regional
        ? salesman_filter.regional
        : [];
      const regional_id = list_regional.find((d) => d.REGIONAL === value)?._id;
      if (regional_id) {
        this.setState({
          regional_list: [regional_id],
        });
      } else {
        this.setState({
          regional_list: [],
        });
      }
    }
    if (name === "jabatan") {
      const cwk = this.props.auth.job_positions.filter(
        (e) => e.value === value
      )[0].cakupan_wilayah_kerja;
      if (cwk) {
        this.setState({ cakupan_wilayah_kerja: cwk });
      } else {
        this.setState({ cakupan_wilayah_kerja: "nasional" });
      }
      // if (value === "ASS") {
      //   this.setState({ cakupan_wilayah_kerja: "depo" });
      // }
      // if (value === "BM") {
      //   this.setState({ cakupan_wilayah_kerja: "cabang" });
      // }
      // if (value === "RBM") {
      //   this.setState({ cakupan_wilayah_kerja: "regional" });
      // }
      // if (value === "AREA OPERATIONS SUPERVISOR") {
      //   this.setState({ cakupan_wilayah_kerja: "depo" });
      // }
    }
  };
  render() {
    const {
      name,
      full_name,
      password,
      password2,
      email,
      phone_number,
      badge,
      jabatan,
      cakupan_wilayah_kerja,
      depo,
      errors,
      show_password,
      negara,
    } = this.state;
    const { errors_depo, errors_cabang, errors_regiional } = this.state;
    const { loading, country_select } = this.props.auth;
    const { salesman_filter } = this.props.data;
    const list_depo = salesman_filter?.depo ? salesman_filter.depo : [];
    const list_cabang = salesman_filter?.cabang ? salesman_filter.cabang : [];
    const list_regional = salesman_filter?.regional
      ? salesman_filter.regional
      : [];
    let iconContent;
    if (loading) {
      iconContent = (
        <div className="uppercase py-3 w-full mt-4 rounded shadow font-bold bg-main-color hover:bg-main-color-dark">
          Sending data...
        </div>
      );
    } else {
      iconContent = (
        <button
          className="uppercase py-3 w-full mt-4 rounded shadow font-bold bg-main-color hover:bg-main-color-dark"
          type="submit"
        >
          Register
        </button>
      );
    }
    const list_badge = [
      {
        value: "super_admin",
        name: "Super admin",
      },
      {
        value: "admin",
        name: "Admin",
      },
      {
        value: "planning",
        name: "Planning",
      },
      {
        value: "reporting",
        name: "Reporting",
      },
    ];
    const list_jabatan = this.props.auth.job_positions;
    const list_negara = country_select.map((e) => ({
      value: e.value,
      name: e.label,
    }));
    return (
      <main className="flex items-center justify-center min-h-screen bg-gray-50">
        <main className="shadow w-full mx-4 md:w-1/2 lg:w-1/3 p-4 md:p-12 rounded-md bg-white">
          <img alt="Nabati Logo" src={logo} className="mx-auto mb-8" />
          <h1 className="font-semibold text-xl mb-0">NABATI OUTDIG</h1>
          <p className="text-gray-700">Nabati Outdig Admin Registration</p>
          <form
            noValidate
            className="mt-4"
            autoComplete="off"
            onSubmit={this.onSubmit}
          >
            <TextFieldGroup
              type="text"
              placeholder="Full name"
              name="full_name"
              id="full_name"
              value={full_name}
              onChange={this.onChange}
              error={errors.full_name}
              info="Use your full formal name (including, given names, family names, etc.)"
            />
            <TextFieldGroup
              type="text"
              placeholder="Mobile phone number"
              name="phone_number"
              id="phone_number"
              value={phone_number}
              onChange={this.onChange}
              error={errors.phone_number}
            />
            <TextFieldGroup
              type="text"
              placeholder="Email"
              name="email"
              id="email"
              value={email}
              onChange={this.onChange}
              error={errors.email}
            />
            <TextFieldGroup
              type="selection"
              placeholder="Country"
              name="negara"
              id="negara"
              value={negara}
              onChange={this.onChange}
              error={errors.negara}
              list_selection_object={list_negara}
              name_list="name"
              value_list="value"
            />
            <TextFieldGroup
              type="selection"
              placeholder="Admin authority role"
              name="badge"
              id="badge"
              value={badge}
              onChange={this.onChange}
              error={errors.badge}
              list_selection_object={list_badge}
              name_list="name"
              value_list="value"
            />
            <TextFieldGroup
              type="selection"
              placeholder="Job position"
              name="jabatan"
              id="jabatan"
              value={jabatan}
              onChange={this.onChange}
              error={errors.jabatan}
              list_selection_object={list_jabatan}
              name_list="name"
              value_list="value"
            />
            {/* <TextFieldGroup
              type="selection"
              placeholder="Cakupan wilayah kerja"
              name="cakupan_wilayah_kerja"
              id="cakupan_wilayah_kerja"
              value={cakupan_wilayah_kerja}
              onChange={this.onChange}
              error={errors.cakupan_wilayah_kerja}
              list_selection_object={list_cakupan_wilayah_kerja}
              name_list="name"
              value_list="value"
            /> */}
            {cakupan_wilayah_kerja === "depo" && (
              <TextFieldGroup
                type="selection"
                placeholder="Choose depo"
                name="depo"
                id="depo"
                value={depo}
                onChange={this.onChange}
                error={errors_depo}
                list_selection_object={list_depo}
                name_list="Depo"
                value_list="Depo"
                // list="depo_list"
              />
            )}
            {cakupan_wilayah_kerja === "cabang" && (
              <TextFieldGroup
                type="selection"
                placeholder="Choose Branch"
                name="cabang_list"
                id="cabang_list"
                onChange={this.onChange}
                error={errors_cabang}
                list_selection_object={list_cabang}
                name_list="CABANG"
                value_list="CABANG"
                // list="list_cabang"
              />
            )}
            {cakupan_wilayah_kerja === "regional" && (
              <TextFieldGroup
                type="selection"
                placeholder="Choose Region"
                name="regional_list"
                id="regional_list"
                onChange={this.onChange}
                error={errors_regiional}
                list_selection_object={list_regional}
                name_list="REGIONAL"
                value_list="REGIONAL"
                // list="list_regional"
              />
            )}
            <TextFieldGroup
              type="text"
              placeholder="Username"
              name="name"
              id="name"
              value={name?.toLowerCase()?.replace(/\s/g, "")}
              onChange={this.onChange}
              error={errors.name}
              info="
              Username will be used for login, use an easy to remember username, minimal 5 characters, small letter only and without space"
            />
            <TextFieldGroup
              type={show_password ? "text" : "password"}
              isPassword={true}
              placeholder="Password"
              name="password"
              id="password"
              value={password}
              onChange={this.onChange}
              error={errors.password}
              toggleShowPassword={this.toggleShowPassword}
            />
            <TextFieldGroup
              type={show_password ? "text" : "password"}
              isPassword={true}
              placeholder="Retype password"
              name="password2"
              id="password2"
              value={password2}
              onChange={this.onChange}
              error={errors.password2}
              toggleShowPassword={this.toggleShowPassword}
            />
            {iconContent}
          </form>
          <Link to="/login">
            <button className="button_main mt-6">
              Don't have an account yet? Login here
            </button>
          </Link>
        </main>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.error,
  data: state.data,
});
export default connect(mapStateToProps, {
  getSalesmanFilterRegister,
  register_admin_nabati,
  set_country,
})(Register);
