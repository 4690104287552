import React, { Component } from "react";
import { connect } from "react-redux";
import {
  set_current_driver,
  set_modal_current_driver,
} from "../../actions/tracking";

export class DRIVER_POPUP extends Component {
  detailDriver = (properties) => {
    this.props.set_modal_current_driver(true);
    this.props.set_current_driver(properties);
  };

  render() {
    const pop_up_properties = this?.props?.properties?.pop_up_properties || {};

    if (typeof pop_up_properties.location === "string") {
      pop_up_properties.location = JSON.parse(pop_up_properties.location);
    }
    if (typeof pop_up_properties.log === "string") {
      pop_up_properties.log = JSON.parse(pop_up_properties.log);
    }
    if (typeof pop_up_properties.user_devices === "string") {
      pop_up_properties.user_devices = JSON.parse(
        pop_up_properties.user_devices
      );
    }
    return (
      <main style={{ fontFamily: `"Inter", sans-serif` }}>
        <div
          className="text_bold mb-3"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          Driver Details
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          DRIVER
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {pop_up_properties?.["DRIVER"] || "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          HELPER
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {pop_up_properties?.["HELPER"] || "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          LICENSE PLATE NUMBER
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {pop_up_properties?.["NOPOL"] || "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          DEPO
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {pop_up_properties?.["Depo"] || "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          CAR TYPE
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {pop_up_properties?.["JENIS"] || "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          CARTON CAPACITY
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {pop_up_properties?.["KAPASITAS_CTN"] || "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          VOLUME CAPACITY
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {pop_up_properties?.["KAPASITAS_M3"] || "Unfilled"}
        </div>
        <button
          className="button_main"
          onClick={this.detailDriver.bind(this, pop_up_properties)}
        >
          Edit Driver
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  tracking: state.tracking,
  properties: state.properties,
});

const mapDispatchToProps = {
  set_current_driver,
  set_modal_current_driver,
};

export default connect(mapStateToProps, mapDispatchToProps)(DRIVER_POPUP);
