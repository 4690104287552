//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import { aggregate_system_outlet } from "../../actions/propertiesActions";

//Picture Asset

//General Function

class EditCompetitorCode extends Component {
  state = {};

  componentDidMount() {
    this.props.aggregate_system_outlet();
  }

  render() {
    const { competitor_code_list } = this.props.data;

    const list_content = (
      <table style={{ width: "100%" }} className="table_lite mt-10">
        <tbody>
          {competitor_code_list.map((item, idx) => {
            return (
              <tr key={idx}>
                <td>{idx + 1}.</td>
                <td>{item.text}</td>
                <td>
                  <div
                    style={{
                      backgroundColor: item.color,
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );

    return (
      <div>
        <p className="text_header">EDIT OUTLET SYSTEM</p>

        {list_content}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, {
  aggregate_system_outlet,
})(EditCompetitorCode);
