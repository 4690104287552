//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import Modal from "../common/Modal";
import STEP_8_MANAGE_DRIVER from "./STEP_8_MANAGE_DRIVER";

//Redux function
import { show_all_outlet } from "../../actions/rayonActions";
import { get_driver_multi_depo } from "../../actions/tracking";

//Picture Asset

//General Function

class STEP_8_MANAGE_DRIVER_PARENT extends Component {
  state = { modal_driver: false };

  toggle_driver = () => {
    this.setState({ modal_driver: !this.state.modal_driver });
    const { list_menu_active } = this.props.data;
    const config_plan = list_menu_active.find((e) => e.key === "salesman")
      ?.config?.value;
    const depos = config_plan.map((e) => e.value);
    this.props.get_driver_multi_depo(depos);
    // this.props.show_all_outlet();
  };

  render() {
    const { modal_driver } = this.state;
    const modal_driver_content = modal_driver && (
      <Modal
        modalSize="large"
        isOpen={modal_driver}
        onClose={this.toggle_driver}
      >
        <div className="box-body">
          <STEP_8_MANAGE_DRIVER />
        </div>
      </Modal>
    );

    return (
      <section className="main_container mb-3">
        {modal_driver_content}
        <p className="badge mb-1" id="blue">
          Step 8
        </p>
        <p className="text_medium">Manage driver</p>
        <hr />
        <button className="button mt-3" id="green" onClick={this.toggle_driver}>
          Open & Manage Driver
        </button>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, {
  get_driver_multi_depo,
  show_all_outlet,
})(STEP_8_MANAGE_DRIVER_PARENT);
