const format_jam_menit = function (timestamp) {
  const date = new Date(timestamp);
  const jam = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const menit =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  let date_final = jam + "." + menit;

  return date_final;
};

export default format_jam_menit;
