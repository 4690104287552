/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import writeXlsxFile from "write-excel-file";
import readXlsxFile from "read-excel-file";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_list } from "../../actions/dataActions";
import {
  check_merch_by_id_sfa,
  push_merch,
  set_value_properties,
} from "../../actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*CONSTANT*/
const template_csv = [
  [
    "ID SFA",
    "NAMA OUTLET (TIDAK WAJIB)",
    "DEPO (TIDAK WAJIB)",
    "TARGET KELAS",
    "REALISASI KELAS",
    "FLAGGING",
    "OMSET",
    "QTY",
    "FLAG FOTO",
    "BUDGET PROGRAM",
    "RASIO BUDGET PER OMSET",
    "NOMOR BULAN",
    "NOMOR TAHUN",
  ],
  [
    "P153C17*****",
    "OUTLET A",
    "DEPO A",
    "A",
    "B",
    "MERCHANDISING PROGRAM",
    1000000,
    200,
    "YES",
    2000000,
    2,
    5,
    2023,
  ],
  [
    "P153C17*****",
    "OUTLET B",
    "DEPO A",
    "A",
    "C",
    "MERCHANDISING PROGRAM",
    1000000,
    200,
    "YES",
    2000000,
    2,
    5,
    2023,
  ],
];

class UploadMerchandising extends Component {
  state = {
    header: [],
    file_body: [],
    is_checked: false,
    modal_edit: false,
    mode_competitor: "nabati",
    mode_download: "csv",
    loadingExcel: false,
  };

  on_push_merch = () => {
    const { file_body } = this.state;
    const { outlet_id_test } = this.props.properties;
    let array = file_body.map((item) => {
      const toko_object = outlet_id_test.find(
        (toko) => toko?.["ID SFA"] === item?.["ID SFA"]
      );
      let final_object = {
        outlet_id: toko_object?._id,
        ...toko_object,
        ...item,
      };
      delete final_object._id; //perlu delete _id supaya lolos verifikasi mongoose
      return final_object;
    });
    array = array.filter((item) => item?.outlet_id);
    const body = { array };
    this.props.push_merch(body);
  };

  on_download_not_found = () => {
    const { file_body } = this.state;
    const { id_sfa_test } = this.props.properties;
    const file_body_not_found = file_body.filter(
      (item) => !id_sfa_test.includes(item?.["ID SFA"])
    );
    const header = Object.keys(file_body_not_found?.[0]);
    const types = {
      "ID SFA": String,
      "NAMA OUTLET (TIDAK WAJIB)": String,
      "DEPO (TIDAK WAJIB)": String,
      "TARGET KELAS": String,
      "REALISASI KELAS": String,
      FLAGGING: String,
      OMSET: Number,
      QTY: Number,
      "FLAG FOTO": String,
      "BUDGET PROGRAM": Number,
      "RASIO BUDGET PER OMSET": Number,
      "NOMOR BULAN": Number,
      "NOMOR TAHUN": Number,
    };
    const schema = header.map((item) => {
      return {
        column: item,
        type: types?.[item] || String,
        value: (target) => target[item],
      };
    });
    const excel_data = file_body_not_found.map((item) => {
      header.forEach((head) => {
        if (types?.[head] === Number) {
          item[head] = Number(item[head]);
        }
      });
      return item;
    });
    writeXlsxFile(excel_data, {
      schema,
      fileName: `MERCH NOT FOUND ${Date.now()}.xlsx`,
    });
  };

  on_check = () => {
    const { file_body } = this.state;
    const id_sfa_array = file_body.map((item) => item?.["ID SFA"]);
    const body = { id_sfa_array };
    this.props.check_merch_by_id_sfa(body);
  };

  on_change = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value });
  };

  on_reset = () => {
    this.setState({ file_header: [], file_body: [], header: [], dataEx: [] });
  };

  handle_file_csv = async (event) => {
    this.props.set_value_properties({ key: "outlet_id_test", value: [] });
    this.props.set_value_properties({ key: "id_sfa_test", value: [] });
    this.props.set_value_properties({
      key: "id_sfa_test_status",
      value: "pending",
    });
    this.props.set_value_properties({ key: "merch_status_upload", value: [] });

    const body = { text: "current_array_add", list: [] };
    this.props.set_list(body);
    this.setState(
      {
        file: event.target.files[0],
      },
      async () => {
        this.setState({ is_checked: false });
        this.on_reset();
        try {
          const file = this.state.file;
          const result = () => {
            return new Promise((resolve, reject) => {
              Papa.parse(file, {
                complete: function (results) {
                  resolve(results);
                },
                error: function (err) {
                  reject(err);
                },
              });
            });
          };
          const final_result = await result();
          let file_header = final_result.data.shift();
          file_header = file_header.map((item) => {
            item = item.replace(/\s*$/, "");
            item = item.trim();
            return item;
          });

          const file_body = final_result.data;
          let arrOfObject = [];
          file_body.forEach((element) => {
            let obj = {};
            file_header.forEach((el, idx) => {
              let header = el.replace(/\s*$/, "");
              header = header.trim();
              let value = element[idx];
              if (value === "#N/A") {
                value = "";
              }
              if (header === "KONTAK HP")
                value = element?.[idx]?.replace(/\s/g, "");
              obj[header] = value;
            });
            if (file_header.length === element.length) {
              arrOfObject.push(obj);
            }
          });
          this.setState({
            file_header,
            file_body: arrOfObject,
          });
        } catch (error) {
          alert("Error process file!");
        }
      }
    );
  };

  handle_file_excel = async (event) => {
    this.props.set_value_properties({ key: "outlet_id_test", value: [] });
    this.props.set_value_properties({ key: "id_sfa_test", value: [] });
    this.props.set_value_properties({
      key: "id_sfa_test_status",
      value: "pending",
    });
    this.props.set_value_properties({ key: "merch_status_upload", value: [] });

    this.setState({ loadingExcel: true });
    this.props.set_list({ text: "current_array_add", list: [] });
    this.setState({ is_checked: false });
    const { mode_competitor } = this.state;
    this.on_reset();
    try {
      readXlsxFile(event.target.files[0]).then((rows) => {
        let HEADER = rows.shift();
        HEADER = HEADER.map((item) => {
          item = item.replace(/\s*$/, "");
          item = item.trim();
          return item;
        });

        const DATA = [];
        rows.forEach((element) => {
          let obj = {};
          HEADER.forEach((el, idx) => {
            let header = el.replace(/\s*$/, "");
            header = header.trim();
            let value = element[idx];
            const value_test = String(value);
            if (value_test?.includes("#N/A") || !value_test || !value) {
              value = "";
            }
            if (header === "KONTAK HP")
              value = element?.[idx]?.replace(/\s/g, "");
            obj[header] = String(value);
          });
          if (mode_competitor === "competitor")
            obj["STATUS OUTLET"] = "competitor";
          if (HEADER.length === element.length) {
            DATA.push(obj);
          }
        });
        this.setState({
          file_header: HEADER,
          file_body: DATA,
          loadingExcel: false,
        });
      });
    } catch (err) {
      alert("Error process file!");
    }
  };

  download_example_excel = async () => {
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });
      try {
        const header = template_csv[0];
        const types = {
          "ID SFA": String,
          "NAMA OUTLET (TIDAK WAJIB)": String,
          "DEPO (TIDAK WAJIB)": String,
          "TARGET KELAS": String,
          "REALISASI KELAS": String,
          FLAGGING: String,
          OMSET: Number,
          QTY: Number,
          "FLAG FOTO": String,
          "BUDGET PROGRAM": Number,
          "RASIO BUDGET PER OMSET": Number,
          "NOMOR BULAN": Number,
          "NOMOR TAHUN": Number,
        };
        const schema = header.map((item) => {
          return {
            column: item,
            type: types?.[item] || String,
            value: (target) => target[item],
          };
        });
        const content = template_csv.slice(1, 3);
        const excel_data = content.map((row) => {
          let object = {};
          header.forEach((item, idx) => {
            object[item] = row[idx];
          });
          return object;
        });
        await writeXlsxFile(excel_data, {
          schema,
          fileName: "MERCH.xlsx",
        });
        this.setState({
          loading_csv: false,
        });
      } catch (error) {
        this.setState({
          loading: false,
        });
      }
    }
  };

  render() {
    //local state
    let { file_header, file_body, mode_download } = this.state;

    //global props
    const {
      id_sfa_test,
      id_sfa_test_status,
      outlet_id_test,
      loading_status,
      loading_item,
      merch_status_upload,
    } = this.props.properties;

    //content
    const count_create = merch_status_upload.filter(
      (item) => item.status === "create"
    ).length;
    const count_update = merch_status_upload.filter(
      (item) => item.status === "update"
    ).length;
    const count_error = merch_status_upload.filter(
      (item) => item.status === "error"
    ).length;

    const syarat_header = template_csv[0];
    const syarat_content = (
      <table style={{ fontSize: "11px" }}>
        <tbody>
          {syarat_header.map((e, idx) => {
            let status = (
              <td id="green" style={{ width: "70px", textAlign: "center" }}>
                V
              </td>
            );
            if (!file_header?.includes(e))
              status = (
                <td id="red" style={{ width: "70px", textAlign: "center" }}>
                  X
                </td>
              );
            return (
              <tr key={idx}>
                <td>{e}</td>
                {status}
              </tr>
            );
          })}
        </tbody>
      </table>
    );

    let file_body_sliced = [];
    let file_body_not_found = [];
    let file_body_found = [];

    if (id_sfa_test_status === "pending") {
      file_body_sliced = file_body.slice(0, 5);
      file_body_not_found = [];
    } else {
      file_body_found = file_body.filter((item) =>
        id_sfa_test.includes(item?.["ID SFA"])
      );
      file_body_sliced = file_body_found.slice(0, 5);

      file_body_not_found = file_body.filter(
        (item) => !id_sfa_test.includes(item?.["ID SFA"])
      );
    }

    let percent = 0;
    if (file_body_found.length > 0) {
      percent = parseInt(
        (merch_status_upload.length / file_body_found.length) * 100
      );
    }

    const rules_content = (
      <main>
        <h1 className="text_bold">Peraturan</h1>
        <table className="table">
          <tbody>
            <tr>
              <td>Bilangan desimal</td>
              <td>
                Harus format angka, TIDAK BOLEH masih dalam bentuk pecahan,
                misal 1/2, 1/5.
              </td>
            </tr>
            <tr>
              <td>NOMOR BULAN</td>
              <td>
                Januari dimulai dari 1 sampai desember 12, TIDAK BOLEH ada 0
                diawal angka, misal 01, 02.
              </td>
            </tr>
            <tr>
              <td>NOMOR TAHUN</td>
              <td>Harus angka, misal 2023, 2024</td>
            </tr>
          </tbody>
        </table>
      </main>
    );

    return (
      <div style={{ marginBottom: "200px" }}>
        <h1 className="text_header">Upload Merchandising</h1>

        <section className="container_outline mb-3">
          <p className="text_inferior">Step 1/3</p>
          <p className="text_bold">Select Mode Upload</p>
          <select
            name="mode_download"
            id="mode_download"
            value={mode_download}
            onChange={this.on_change}
          >
            <option value="csv">CSV</option>
            <option value="excel">EXCEL</option>
          </select>

          {rules_content}

          {mode_download === "excel" ? (
            <main>
              <div className="border p-4 my-4 bg-yellow-100 rounded">
                Warning! for using this feature please follow the instructions
                according to the downloadable template EXCEL{" "}
                <button
                  className="text-blue-600"
                  onClick={this.download_example_excel}
                >
                  here
                </button>
              </div>
              <div className="grid grid-cols-1 gap-1">
                <label htmlFor="file">Select EXCEL file</label>
                <input
                  onChange={this.handle_file_excel}
                  type="file"
                  id="input"
                  className="w-fit"
                />
                {this.state.loadingExcel && (
                  <p
                    className="text_bold bg-[#51B13D] rounded w-1/5 text-white text-center
                        "
                  >
                    Loading Data...
                  </p>
                )}
              </div>
            </main>
          ) : (
            <main>
              <section className="border p-4 my-4 bg-yellow-100 rounded">
                Warning! for using this feature please follow the instructions
                according to the downloadable template CSV{" "}
                <CSVLink
                  className="text-blue-600"
                  data={template_csv}
                  filename="MERCH.csv"
                  separator=";"
                >
                  here
                </CSVLink>
              </section>
              <section className="grid grid-cols-1 gap-1">
                <label htmlFor="file">Select CSV file</label>
                <input
                  type="file"
                  id="file"
                  className="w-fit"
                  accept=".csv"
                  onChange={this.handle_file_csv}
                />
              </section>
            </main>
          )}
        </section>

        {file_body.length > 0 && (
          <section className="container_outline mb-3">
            <p className="text_inferior">Step 2/3</p>
            <p className="text_bold">Verify before upload</p>
            <p>
              Number items:{" "}
              <b>
                {new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(file_body.length)}
              </b>
            </p>

            <section>
              <p className="text_bold mt-5">Data Verification</p>
              {syarat_content}
            </section>

            <section>
              <p className="text_bold mt-5">
                ID SFA Founded({file_body_found.length})
              </p>
              <div
                className="w-full overflow-auto n-scroll pb-2 pr-2"
                style={{ maxHeight: "500px" }}
              >
                <table className="border-2 border-separate">
                  <thead>
                    <tr className="text-left bg-gray-300">
                      <th>No</th>
                      <th>Status</th>
                      {file_header.map((el, idx) => (
                        <th key={idx}>{el}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {file_body_sliced.map((el, idx) => {
                      let status_db = "Not found";
                      let color_id = "red";

                      if (id_sfa_test_status === "pending") {
                        status_db = "Wait for verify";
                        color_id = "grey";
                      } else if (id_sfa_test.includes(el["ID SFA"])) {
                        status_db = "Outlet exist";
                        color_id = "green";
                      }

                      return (
                        <tr key={idx} className={`even:bg-gray-50`}>
                          <td className="font-semibold">{idx + 1}</td>
                          <td id={color_id}>{status_db}</td>
                          {file_header.map((elHeader, idx) => (
                            <td key={idx}>{el[elHeader]}</td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </section>

            {file_body_not_found.length > 0 && (
              <section>
                <hr />
                <br />
                <span className="text_bold mt-5">
                  ID SFA Not Founded ({file_body_not_found.length})
                </span>{" "}
                <button
                  className="button"
                  id="green"
                  onClick={this.on_download_not_found}
                >
                  Download
                </button>
                <div
                  className="w-full overflow-auto n-scroll pb-2 pr-2"
                  style={{ maxHeight: "500px" }}
                >
                  <table className="border-2 border-separate">
                    <thead>
                      <tr className="text-left bg-gray-300">
                        <th>No</th>
                        <th>Status</th>
                        {file_header.map((el, idx) => (
                          <th key={idx}>{el}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {file_body_not_found.map((el, idx) => {
                        let status_db = "Not found";
                        let color_id = "red";

                        if (id_sfa_test_status === "pending") {
                          status_db = "Wait for verify";
                          color_id = "grey";
                        } else if (id_sfa_test.includes(el["ID SFA"])) {
                          status_db = "Outlet exist";
                          color_id = "green";
                        }

                        return (
                          <tr key={idx} className={`even:bg-gray-50`}>
                            <td className="font-semibold">{idx + 1}</td>
                            <td id={color_id}>{status_db}</td>
                            {file_header.map((elHeader, idx) => (
                              <td key={idx}>{el[elHeader]}</td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </section>
            )}

            <hr />
            <br />
            <button className="button" id="green" onClick={this.on_check}>
              Verify
            </button>
          </section>
        )}

        {file_body.length > 0 && outlet_id_test.length > 0 && (
          <section className="container_outline mb-3">
            <p className="text_inferior">Step 3/3</p>
            <p className="text_bold">
              Start upload, only outlets that already exist will be processed,
              download outlet not found above (if necessary).
            </p>
            <hr />
            <br />

            {loading_status && loading_item === "push_merch" ? (
              <div className="button" id="grey">
                Uploading...
              </div>
            ) : (
              <button
                className="button"
                id="green"
                onClick={this.on_push_merch}
              >
                Upload merchandising
              </button>
            )}

            <section
              className="mt-3"
              style={{
                height: "20px",
                backgroundColor: "#ffffff",
                borderRadius: "2rem",
                padding: "2px",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: `${percent}%`,
                  backgroundColor: "#269d42ff",
                  borderRadius: "2rem",
                }}
              />
            </section>
            <section>
              <p>Progress: {percent}%</p>
              <p>
                {merch_status_upload?.length}/{file_body_found?.length} item
              </p>
            </section>
            {merch_status_upload.length > 0 && (
              <section>
                <br />
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Created:</td>
                      <td>{count_create}</td>
                    </tr>
                    <tr>
                      <td>Updated:</td>
                      <td>{count_update}</td>
                    </tr>
                    <tr>
                      <td>Error:</td>
                      <td>{count_error}</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            )}
          </section>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_list,
  check_merch_by_id_sfa,
  push_merch,
  set_value_properties,
})(UploadMerchandising);
