/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal";
import STEP_7_ROTATE_SALESMAN from "./STEP_7_ROTATE_SALESMAN";
import STEP_7_OVERWRITE_SALESMAN from "./STEP_7_OVERWRITE_SALESMAN";
import STEP_7_DAY_RAYON_OVERWRITE from "./STEP_7_DAY_RAYON_OVERWRITE";

/*REDUX FUNCTION*/
import {
  searchSalesmanStep5,
  set_list,
  set_config_mode_menu,
  turn_on_menu_status,
} from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class STEP_7_PARENT extends Component {
  state = {
    modal_rotate: false,
    modal_overwrite_salesman: false,
  };

  on_view_saleman = (salesman, plan) => {
    this.props.set_list({
      text: "salesman_select",
      list: salesman,
    });

    this.props.set_list({
      text: "cycle_select",
      list: plan.cycle,
    });

    this.props.set_config_mode_menu("distribution", "day");
    this.props.turn_on_menu_status("day");
  };

  toggle_rotate = () => {
    this.setState({ modal_rotate: !this.state.modal_rotate });
    this.props.searchSalesmanStep5("");
  };

  toggle_overwrite_salesman = (plan, plan_index) => {
    this.setState({
      modal_overwrite_salesman: !this.state.modal_overwrite_salesman,
    });
    if (plan) {
      this.props.set_list({ text: "plan_selected", list: plan });
      this.props.set_list({ text: "plan_index", list: plan_index });
    }
  };

  render() {
    //local storage

    //local state
    const { modal_rotate, modal_overwrite_salesman } = this.state;

    //global props
    const {
      rayon_select,
      rayon_list_database,
      salesman_select,
      salesman_list,
      cycle_select,
    } = this.props.data;

    //content
    let rayon_object = {};
    if (rayon_list_database.length > 0) {
      rayon_object = rayon_list_database.find((r) => r._id === rayon_select);
    }

    const plan_list = rayon_object?.plan_list ? rayon_object?.plan_list : [];
    const day_list = plan_list?.[0]?.day_list ? plan_list?.[0]?.day_list : [];

    const modal_overwrite_salesman_content = modal_overwrite_salesman && (
      <Modal
        modalSize="medium"
        isOpen={modal_overwrite_salesman}
        onClose={this.toggle_overwrite_salesman}
      >
        <div className="box-body">
          <STEP_7_OVERWRITE_SALESMAN />
        </div>
      </Modal>
    );

    return (
      <section className="main_container">
        {modal_overwrite_salesman_content}
        <p className="badge mb-1" id="blue">
          Step 7
        </p>
        <p className="text_medium">Rotate template</p>
        <hr />
        <p className="text_inferior">
          Choose another salesman & rotate days template to other division.
        </p>

        {plan_list.length > 0 && (
          <section>
            <STEP_7_DAY_RAYON_OVERWRITE />
            <br />

            <table className="text_inferior mt-3 table">
              <thead>
                <tr>
                  <th>Salesman</th>
                  {day_list.map((h, idx) => {
                    return (
                      <td key={idx}>
                        <p style={{ fontSize: "10px" }}>day</p>
                        <div className="button_small" id="grey_bright">
                          {idx + 1}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              </thead>

              <tbody>
                {plan_list.map((plan, idx) => {
                  const salesman_object = salesman_list.find(
                    (s) => s._id === plan.salesman_id
                  );
                  if (salesman_object === undefined) {
                    return null;
                  }
                  const day_list = plan?.day_list ? plan?.day_list : [];
                  return (
                    <tr
                      key={idx}
                      id={plan.cycle === "cycle 1" ? "white" : "green_semi"}
                    >
                      <td style={{ width: "120px" }}>
                        <div className="mb-3">
                          <b>{salesman_object?.["Nama Only"]}</b>
                        </div>
                        <div>
                          {salesman_object.divisi_arr.map((d, idx) => (
                            <p key={idx} className="block">
                              {d}
                            </p>
                          ))}
                        </div>
                        <div>{plan.cycle}</div>
                        <button
                          className="button_small"
                          id={
                            salesman_object?._id === salesman_select?._id &&
                            plan.cycle === cycle_select
                              ? "green"
                              : "grey"
                          }
                          onClick={
                            salesman_object?._id === salesman_select?._id &&
                            plan.cycle === cycle_select
                              ? this.on_view_cycle
                              : this.on_view_saleman.bind(
                                  this,
                                  salesman_object,
                                  plan
                                )
                          }
                          // onClick={this.on_view_saleman.bind(
                          //   this,
                          //   salesman_object,
                          //   plan
                          // )}
                        >
                          {salesman_object?._id === salesman_select?._id &&
                          plan.cycle === cycle_select
                            ? "Viewed"
                            : "View"}
                        </button>
                        <br />
                        <button
                          className="button_small mt-1"
                          id="blue"
                          style={{ whiteSpace: "nowrap" }}
                          onClick={this.toggle_overwrite_salesman.bind(
                            this,
                            plan,
                            idx
                          )}
                        >
                          Change salesman
                        </button>
                      </td>
                      {day_list.map((h, idx) => {
                        return (
                          <td key={idx}>
                            <div
                              style={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: h.color,
                              }}
                            />
                            <p style={{ fontSize: "10px" }}>
                              {h.outlet_length}
                            </p>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </section>
        )}
        {plan_list.length > 0 ? (
          <button
            className="button mt-3"
            id="green"
            onClick={this.toggle_rotate}
          >
            Choose another salesman & save
          </button>
        ) : (
          <button className="button mt-3" id="grey">
            Choose another salesman & save
          </button>
        )}
        {modal_rotate && <STEP_7_ROTATE_SALESMAN toggle={this.toggle_rotate} />}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
});

export default connect(mapStateToProps, {
  searchSalesmanStep5,
  set_list,
  set_config_mode_menu,
  turn_on_menu_status,
})(STEP_7_PARENT);
