import { bbox } from "@turf/turf";

import sort_lat from "./sort_lat";
import sort_long from "./sort_long";
import st_colors from "./st_colors";
import generate_v_max from "./generate_v_max";

const rayonisasi_general = function (body) {
  let { toko_list_main, text, jumlah_rayon } = body;
  jumlah_rayon = Number(jumlah_rayon);

  const features = toko_list_main.map((t) => {
    return { type: "Feature", properties: {}, geometry: t.location };
  });
  const geojson = {
    type: "FeatureCollection",
    features: [...features],
  };

  const [minLng, minLat, maxLng, maxLat] = bbox(geojson);
  const delta_long = maxLng - minLng;
  const delta_lat = maxLat - minLat;
  const ratio = delta_lat / delta_long;

  //jumlah_toko = jumlah toko yang diingikan untuk setiap rayon
  const jumlah_toko = toko_list_main.length;

  const mode_first = "v_first";

  //masukan ke index ke atribut index karena ketika toko difilter berdasarkan suatu atribut indexnya akan berubah
  let toko_list_new = toko_list_main.map((t, idx) => {
    t.index = idx;
    delete t[text]; //delete day atau cycle
    return t;
  });

  let rayon_list = [];
  let rayon_list_complete = [];

  for (let index = 0; index < jumlah_rayon; index++) {
    const element = `${text} ${index + 1}`;
    const object = {
      name: element,
      date_created: Date.now(),
      date_updated: Date.now(),
    };
    rayon_list.push(element);
    rayon_list_complete.push(object);
  }

  let jumlah_toko_per_rayon = Math.ceil(jumlah_toko / jumlah_rayon);

  let toko_list = toko_list_new.slice();

  /*STEP 1 Deklarasi variabel penting*/

  //index_loop_rayon: parameter index untuk looping rayon secara keseluruhan,
  let index_loop_rayon = 0;

  //v_max : jumlah pembagian vertikal, untuk sementara konstan 3
  let v_max = generate_v_max(jumlah_rayon, ratio);

  //pengali: pengali untuk setiap v_index
  const pengali = Math.floor(jumlah_rayon / v_max);

  //sisa_bagi : sisa pembagian dari 3 untuk menentukan v_index mana yang akan dilebihkan 1 pengali nya
  const sisa_bagi = jumlah_rayon % v_max;

  //vertical_range : array looping pertama, untuk kasus ini akan dilooping vertikal dulu
  let vertical_range = [];

  /*STEP 2 Looping untuk generate pengali_n (bagian dari total toko) untuk masing baris vertikal*/

  for (let n = 0; n < v_max; n++) {
    //pengali_n : bagian untuk masing-masing baris vertikal, misal jika jumlah_rayon = 3 , maka [1/3, 1/3, 1/3]
    const pengali_n =
      sisa_bagi >= n + 1
        ? (1 / jumlah_rayon) * (pengali + 1)
        : (1 / jumlah_rayon) * pengali;

    //jumlah_toko_v : jumlah toko untuk masing-masing baris vertikal, misal jika jumlah_rayon = 3, jumlah total toko = 300 , maka [100, 100, 100]
    const jumlah_toko_v = Math.floor(pengali_n * jumlah_toko);

    //jumlah_rayon_horisontal : berapa rayon yang dibagi secara horisontal pada suatu baris vertikal
    const jumlah_rayon_horisontal = sisa_bagi >= n + 1 ? pengali + 1 : pengali;
    vertical_range.push({
      pengali_n,
      jumlah_rayon_horisontal,
      jumlah_toko_v,
    });
  }

  /*STEP 3 Modifikasi vertical_range untuk mendapatkan range start & end dari baris vertikal, membutuhkan map dari vertical_range original karena membutuhkan nilai dari elemen sebelumnya*/
  vertical_range = vertical_range.map(
    ({ jumlah_toko_v, jumlah_rayon_horisontal }, idx) => {
      //start: index awal dari range pembagian vertikal, end: index akhir dari range pembagian vertikal
      let start, end;
      if (idx === 0) {
        start = 0;
      } else {
        //sum dari semua toko sebelum index yang sedang looping
        start = vertical_range.slice(0, idx).map((r) => r.jumlah_toko_v);
        start = start.reduce((a, b) => a + b, 0);
      }
      if (idx === v_max - 1) {
        end = jumlah_toko;
      } else {
        end = start + jumlah_toko_v;
      }

      //horisontal_range: array range untuk pembagian horisonal, isinya start & end dari rayon yang akan dibuat
      let horisontal_range = [];
      for (let h = 0; h < jumlah_rayon_horisontal; h++) {
        let start_h, end_h;
        start_h = h * jumlah_toko_per_rayon;
        if (idx === v_max - 1 && h === jumlah_rayon_horisontal - 1) {
          end_h = jumlah_toko;
        } else {
          end_h = start_h + jumlah_toko_per_rayon;
        }
        horisontal_range.push({ start_h, end_h });
      }
      return {
        // beberapa atribut yang sudah tidak dibutuhkan lagi:
        // pengali_n,
        // jumlah_toko_v,
        // jumlah_rayon_horisontal,

        //atribut yang pada akhirnya digunakan:
        start,
        end,
        horisontal_range,
      };
    }
  );

  //STEP 4 Urutkan toko_list berdasarkan latitude karena setelah ini akan dikelompokan secara vertikal
  if (mode_first === "v_first") {
    toko_list = sort_lat(toko_list, false);
  } else {
    toko_list = sort_long(toko_list, true);
  }

  //STEP 5 mulai looping pembagian vertikal
  vertical_range.forEach(({ start, end, horisontal_range }) => {
    //STEP 6 hanya ambil toko dari range start dan end vertikal, karena sudah diurutkan dengan latitudenya
    let toko_list_v = toko_list.slice(start, end);

    /*STEP 7 urutkan toko_list secara longitude karena setelah ini akan dikelompokan secara horisontal*/
    if (mode_first === "v_first") {
      toko_list_v = sort_long(toko_list_v, true);
    } else {
      toko_list_v = sort_lat(toko_list_v, false);
    }

    //STEP 8 looping bagikan secara horisontal toko_list ke kotak-kotak sebanyak jumlah_toko_per_rayon (sudah ada array horisontal_range di setiap element array range)
    horisontal_range.forEach(({ start_h, end_h }) => {
      //STEP 9 index_loop_rayon bertambah 1 untuk membedakan dengan rayon lain
      index_loop_rayon = index_loop_rayon + 1;

      //STEP 10 value_string: nama rayon diambil dari nama DEPO dan nomor index
      const value_string = `${text} ${index_loop_rayon}`;

      //STEP 11 hanya ambil toko dari range start dan end horisontal, karena sudah diurutkan dengan longitudenya
      const toko_list_h = toko_list_v.slice(start_h, end_h);
      toko_list_h.forEach((toko) => {
        //STEP 12 masukan nilai rayon ke toko-toko yang sudah dislice
        toko_list_new[toko.index][text] = `${value_string}`;
      });
    });
  });
  //LOOPING RAYONISASI SELESAI

  rayon_list_complete = rayon_list_complete.map((r, idx) => {
    const toko_list = toko_list_new.filter((t) => t[text] === r.name);
    let long = toko_list.map((t) => t.location.coordinates[0]);
    let lat = toko_list.map((t) => t.location.coordinates[1]);
    long = long.reduce((p, a) => p + a, 0) / long.length;
    lat = lat.reduce((p, a) => p + a, 0) / lat.length;
    const outlet_length = toko_list.length;
    r.location = {
      type: "Point",
      coordinates: [long, lat],
    };
    r.outlet_length = outlet_length;
    let index = idx;
    if (idx > 15) {
      index = idx % 16;
    }
    r.color = st_colors[index];
    r.index = idx;
    return r;
  });
  return { toko_list_new, rayon_list, rayon_list_complete };
};

export default rayonisasi_general;
