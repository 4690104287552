//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
//Personal Component
//Redux function
import { loginUser } from "../../actions/authActions";
//Picture Asset
import logo from "../../img/logo.png";
//General Function
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      name: "",
      password: "",
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors) {
      return {
        errors: nextProps.errors,
      };
    } else {
      return null;
    }
  }
  onSubmit = (e) => {
    // e.preventDefault();
    const userData = {
      name: this.state.name,
      password: this.state.password,
      isNav: false,
    };
    this.props.loginUser(userData);
  };
  render() {
    const { showPassword, name, password } = this.state;
    const { errors } = this.props;
    return (
      <main className="flex items-center justify-center min-h-screen bg-gray-50">
        <main className="shadow w-full mx-4 md:w-1/2 lg:w-1/3 p-4 md:p-12 rounded-md bg-white">
          <img alt="Nabati Logo" src={logo} className="mx-auto mb-8" />
          <h1 className="font-semibold text-xl mb-0">NABATI OUTDIG</h1>
          <p className="text-gray-700">Login Nabati Outdig</p>

          <div noValidate className="mt-4">
            <section className="">
              <label htmlFor="username" className="font-semibold">
                Username or Email
              </label>
              <input
                type="text"
                id="username"
                className="w-full"
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </section>
            <section className="mt-2">
              <div className="flex">
                <label htmlFor="password" className="font-semibold flex-1">
                  Password
                </label>
                {/* <p className="text-blue-900 text-sm">Lupa Password?</p> */}
              </div>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="w-full"
                  value={password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 absolute right-2 bottom-0 -translate-y-1/2 text-gray-600 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  onClick={() =>
                    this.setState((prev) => ({
                      showPassword: !prev.showPassword,
                    }))
                  }
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              </div>
            </section>
            <button
              className="uppercase py-3 w-full mt-4 rounded shadow font-bold bg-main-color hover:bg-main-color-dark"
              onClick={this.onSubmit}
            >
              LOGIN
            </button>
          </div>
          {errors?.name && (
            <section className="mt-4 py-3 w-full text-center rounded bg-red-100 border-red-400 border text-red-600">
              {errors.name}
            </section>
          )}
          <Link to="/register">
            <button className="button_main mt-6">
              Don't have an account yet? Register here
            </button>
          </Link>
        </main>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.error,
});
export default connect(mapStateToProps, { loginUser })(LoginPage);
