/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";

/*PERSONAL COMPONENT*/
import RAYON_DEPO_STEP_2 from "./RAYON_DEPO_STEP_2";
import RAYON_DEPO_STEP_3 from "./RAYON_DEPO_STEP_3";
import ProgressBar from "../common/ProgressBar";

/*REDUX FUNCTION*/
import {
  get_count_competitor_national,
  get_count_competitor_national_not_done,
} from "../../actions/dataActions";
import {
  create_competitor_rayon_task,
  get_competitor_rayon_task_newest,
} from "../../actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL*/

class DashboardRayonisasiCompetitor extends Component {
  state = {
    outlet_amount_every_task: 200_000,
  };

  componentDidMount() {
    this.props.get_count_competitor_national();
    this.props.get_competitor_rayon_task_newest();
    const { competitor_rayon_task_newest } = this.props.properties;
    const task_id = competitor_rayon_task_newest?._id;
    if (task_id) {
      this.props.get_count_competitor_national_not_done({ task_id });
    }
  }

  on_generate_child_task = () => {
    const { competitor_national_count } = this.props.data;
    if (competitor_national_count) {
      let child_task_array = [];

      const total = competitor_national_count;
      const limit = parseInt(this.state.outlet_amount_every_task);
      const iteration = Math.ceil(total / limit);

      for (let i = 0; i < iteration; i++) {
        child_task_array.push({
          start: Number(i * limit),
          end: Number(i * limit + limit),
          user: "64dacc0c5a4bfbcf934c5187",
          status: "not_yet_start",
        });
      }

      const body = { child_task_array, limit };
      this.props.create_competitor_rayon_task(body);
    }
  };

  on_change = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  render() {
    //local storage

    //local state
    const { outlet_amount_every_task } = this.state;

    //global props
    const { competitor_national_count } = this.props.data;
    const {
      loading_status,
      loading_item,
      parent_task_finish_index,
      parent_task_current,
    } = this.props.properties;

    //content

    return (
      <main className="body_container" style={{ marginBottom: "100px" }}>
        <Link to="/" className="button mb-10" id="green">
          Back
        </Link>
        <h1 className="text_header mb-10">
          Competitor outlets rayonization to the nearest depo
        </h1>
        <div className="container_light">
          <p
            className="text_inferior"
            style={{
              whiteSpace: "pre-wrap",
            }}
          >
            {`query_lookup_mongodb = {
  SYSTEM: { $nin: ["SAP S/4Hana", "SCYLLA", "SAP S/4HANA"] },
  COUNTRY: "INDONESIA",
};`}
          </p>
          <br />
          <h1 className="text_header">
            {new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(competitor_national_count)}{" "}
            outlets (all competitor outlets national)
          </h1>
        </div>
        <br />

        <section>
          <p className="badge" id="blue">
            STEP 1
          </p>
          <p className="text_medium">
            Pilih depo mana saja yang akan dilibatkan. Pastikan depo testing
            dihilangkan terlebih dahulu supaya tidak mengganggu data rayonisasi.
          </p>
          <p className="text_medium">
            Choose which depots will be involved. Make sure the depo testing is
            removed first so it doesn't interfere with the rayonization data.
          </p>
          <RAYON_DEPO_STEP_2 />
        </section>

        <section>
          <p className="badge" id="blue">
            STEP 2
          </p>
          <p className="text_medium">
            Set the number of outlets for each rayonization task
          </p>
          <section className="container_light mb-10">
            <input
              name="outlet_amount_every_task"
              value={outlet_amount_every_task}
              onChange={this.on_change}
              type="number"
            />
            <br />
            {loading_status &&
            loading_item === "create_competitor_rayon_task" ? (
              <section className="mt-3">
                <ProgressBar
                  current_number={parent_task_current}
                  total_number={parent_task_finish_index}
                  name="Creating task on outlet"
                />
                <div className="button background_grey">Loading...</div>
              </section>
            ) : (
              <button
                className="button"
                id="green"
                onClick={this.on_generate_child_task}
              >
                Start task
              </button>
            )}
          </section>
        </section>

        <section>
          <p className="badge" id="blue">
            STEP 3
          </p>
          <p className="text_medium">
            Mulai jalankan rayonisasi sesuai step pembagian, setiap admin yang
            mengklik tombol akan direkam sebagai <b>eksekutor</b> pada step yang
            diklik.
          </p>
          <p className="text_medium">
            Start running the rayonization according to the division step, every
            admin who clicks the button will be recorded as the <b>executor</b>{" "}
            of the step that was clicked.
          </p>
          <br />
          <RAYON_DEPO_STEP_3 />
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  get_count_competitor_national,
  create_competitor_rayon_task,
  get_competitor_rayon_task_newest,
  get_count_competitor_national_not_done,
})(DashboardRayonisasiCompetitor);
