/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import ToolSalesman from "../tool/ToolSalesman";
import ToolDepo from "../tool/ToolDepo";
import ToolDriver from "../tool/ToolDriver";
import ToolDristribution from "../tool/ToolDristribution";
import FILTER_OUTLET_BY_SALESMAN from "../menu/FILTER_OUTLET_BY_SALESMAN";

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

class RIGHT_SIDE_BAR extends Component {
  state = {};

  render() {
    /*
    key menu
    distribution
    merch
    promo
    stock
    omset
    order
    call
    competitor
    merket
    insentif
    salesman
    toko_salesman
    admin
    */

    let content;
    switch (this.props.data.selected_menu) {
      case "planing": //ketika masuk menu planing tracking
        if (this.props.tracking.tracking_menu_selected === "salesman") {
          content = <ToolSalesman />; //ketika masuk menu planing tracking & pilih tab salesman
        } else {
          content = <ToolDriver />; //ketika masuk menu planing tracking & pilih tab driver
        }
        break;
      case "toko_salesman":
        content = <FILTER_OUTLET_BY_SALESMAN />; //ketika masuk menu planing tracking & klik salah satu toko
        break;
      case "competitor":
        content = <ToolDepo />; //rayonisasi depo kompetitor
        break;
      case "dist":
        content = <ToolDristribution />; //unplot toko nabati via poligon
        break;
      default:
        content = <main>There is no tools in this menu</main>; //default untuk menu lain adalah ini
        break;
    }

    return (
      <main
        className="absolute inset-y-0 right-11 w-[400px] h-full bg-white py-5 pl-5 pr-11 z-[2] overflow-y-auto"
        style={{ scrollbarWidth: "thin" }}
      >
        {content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  tracking: state.tracking,
});

export default connect(mapStateToProps, {})(RIGHT_SIDE_BAR);
