/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";

/*PERSONAL COMPONENT*/
import Spinner from "../parent/Spinner";
import Modal from "../common/Modal";
import IconComponents from "../../img/icon-components/IconComponents";
import EditApkVersion from "../parent/EditApkVersion";
import Legenda from "../parent/Legenda";

import OUTLET_MANAGEMENT_MENU from "../menu/OUTLET_MANAGEMENT_MENU";
import OUTLET_SURVEY_MENU from "../menu/OUTLET_SURVEY_MENU";
import MERCH_MENU from "../menu/MERCH_MENU";
import OMSET_MENU from "../menu/OMSET_MENU";
import OUTLET_EXPANSION_MENU from "../menu/OUTLET_EXPANSION_MENU";
import PLANNING_TRACKING_MENU from "../menu/PLANNING_TRACKING_MENU";
import ADMIN_MENU from "../menu/ADMIN_MENU";

/*REDUX FUNCTION*/
import {
  set_pick_status,
  set_performance_status,
  set_selected_menu,
  turn_on_menu,
  changeBreakpoints,
  getSalesmanFilter,
  set_tool_status,
} from "../../actions/dataActions";
import { logoutUser, set_country } from "../../actions/authActions";

/*PICTURE ASSET*/
import logo from "../../img/logo.png";

/*GENERAL*/

/*NON IMPORT*/

class LEFT_SIDE_BAR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_apk_version: false,
      resizeData: {
        tracking: false,
        startWidth: null,
        startCursorScreenX: null,
        handleWidth: 10,
        resizeTarget: null,
        parentElement: null,
        maxWidth: null,
      },
      sidebarWidth: 400,
      sidebarMode: false,
      sidebarPosition: 1, // 1: default, 2:full, 3:minimaze
      sidebarBreakpoints: "sm", //sm, md, lg,
      toggleChartTable: "scatter",
      list_menu: [
        {
          key: "dist",
          name: "Outlet Management",
          title: "Oversee the daily operations of stores all over the country",
          component: <OUTLET_MANAGEMENT_MENU />,
        },
        {
          key: "toko_plot",
          name: "Outlet Survey",
          title: "Collected store on-site survey data by salesman",
          component: <OUTLET_SURVEY_MENU />,
        },
        {
          key: "competitor",
          name: "Outlet Expansion",
          title:
            "Manage current product line OUTLET_MANAGEMENT_MENU in stores and grow its market share",
          component: <OUTLET_EXPANSION_MENU />,
        },
        {
          key: "planing",
          name: "Planning & Tracking",
          title:
            "Set of designed strategies to help salesman reach their target sales across their sales target territory",
          component: <PLANNING_TRACKING_MENU />,
        },
        {
          key: "omset",
          name: "Sales Mapping",
          title: "See where the prospective and current stores monthly revenue",
          component: <OMSET_MENU />,
        },
        {
          key: "merch",
          name: "Merchandising",
          title:
            "Activity of promotion the sales of products represented by stores",
          component: <MERCH_MENU />,
        },
        {
          key: "admin",
          name: "Admin Dashboard",
          title:
            "Admin setting to manage the entire Nabati Outdig web application",
          component: <ADMIN_MENU />,
        },
      ],
      current_menu: {},
      listMenuAfter: false,
    };
  }

  componentDidMount() {
    const sidebarWidth = localStorage.getItem("sidebar-width")
      ? parseInt(localStorage.getItem("sidebar-width"))
      : 400;
    this.setState({
      sidebarWidth,
    });

    this.props.getSalesmanFilter(this.props.auth.country_selected);

    if (this.props.auth.user.badge !== "super_admin") {
      this.props.getSalesmanFilter(this.props.auth.user.negara);
    }

    this.filter_limitasi();
  }

  componentDidUpdate() {
    if (this.state.resizeData.tracking) {
      window.addEventListener("mousemove", this.sideMouseMove);
      window.addEventListener("mouseup", this.sideMouseUp);
    }
  }

  componentWillUnmount() {
    window.addEventListener("mousemove", this.sideMouseMove);
    window.addEventListener("mouseup", this.sideMouseUp);
  }

  filter_limitasi() {
    const limit = this.props.auth.job_positions.filter(
      (e) => e.value === this.props.auth.user.jabatan
    )[0];

    if (limit) {
      if (limit.limitasi.length > 0) {
        let new_list_menu = [];
        limit.limitasi.forEach((element) => {
          const menu = this.state.list_menu?.find((t) => t?.key === element);
          new_list_menu.push(menu);
        });
        this.setState({
          list_menu: new_list_menu,
        });
      }
    }
  }

  toggle_apk_version = () => {
    this.setState({ modal_apk_version: !this.state.modal_apk_version });
  };

  on_click_logo = () => {
    this.props.set_selected_menu("");
    this.setState({ listMenuAfter: false });
    this.props.set_performance_status(false);
    this.props.set_tool_status(false);
  };

  on_set_menu = (current_menu) => {
    this.setState({ current_menu, listMenuAfter: true });
    this.props.set_selected_menu(current_menu.key);
    // if (
    //   current_menu.key === "competitor" &&
    //   this.props.auth.user.badge === "super_admin"
    // ) {
    //   this.props.set_tool_status(true);
    // }
  };

  on_back = () => {
    this.setState({ listMenuAfter: false });
    this.props.set_performance_status(false);
    this.props.set_tool_status(false);
  };

  change_sidebar = (params) => {
    this.setState({ sidebarPosition: params, sidebarMode: false });
    if (params === 1 || params === 3) {
      localStorage.setItem("sidebar-width", 400);
      document.getElementById("sidebar").style.width = "500px";
      this.changeBreakpoints(400);
    }
    if (params === 2) {
      const clientWidth = document.documentElement.clientWidth - 80;
      localStorage.setItem("sidebar-width", clientWidth);
      const sideBar = document.getElementById("sidebar");
      sideBar.style.width = `${clientWidth}px`;
      this.changeBreakpoints(clientWidth);
    }
  };

  sideMouseDown = (params) => {
    const selectTarget = (fromElement, selector) => {
      if (!(fromElement instanceof HTMLElement)) {
        return null;
      }
      return fromElement.querySelector(selector);
    };
    params.preventDefault();
    params.stopPropagation();
    const handleElement = params.currentTarget;
    if (!handleElement.parentElement) {
      // console.error(new Error("Parent element not found."));
      return;
    }
    const targetSelector = handleElement.getAttribute("data-target");
    const targetElement = selectTarget(
      handleElement.parentElement,
      targetSelector
    );

    if (!targetElement) {
      // console.error(new Error("Resize target element not found."));
      return;
    }
    this.setState({
      resizeData: {
        startWidth: targetElement.offsetWidth,
        startCursorScreenX: params.screenX,
        resizeTarget: targetElement,
        parentElement: handleElement.parentElement,
        tracking: true,
        maxWidth: document.documentElement.clientWidth - 80,
      },
    });
  };

  sideMouseMove = (params) => {
    const { resizeData } = this.state;
    if (resizeData.tracking) {
      const cursorScreenXDelta = params.screenX - resizeData.startCursorScreenX;
      const newWidth = Math.min(
        resizeData.startWidth + cursorScreenXDelta,
        resizeData.maxWidth
      );

      if (newWidth < 300) {
        this.change_sidebar(3);
        this.setState({
          sidebarPosition: 3,
        });
      } else {
        this.setState({
          sidebarWidth: newWidth,
        });
      }
      this.changeBreakpoints(newWidth);
    }
  };

  sideMouseUp = () => {
    const { resizeData, sidebarWidth } = this.state;
    if (resizeData.tracking) {
      this.setState((prev) => ({
        resizeData: { ...prev.resizeData, tracking: false },
      }));

      localStorage.setItem("sidebar-width", sidebarWidth);
    }
  };

  changeBreakpoints = (params) => {
    if (params > 600 && params <= 999) {
      this.setState({ sidebarBreakpoints: "md" });
      this.props.changeBreakpoints("md");
    } else if (params > 1000) {
      this.setState({ sidebarBreakpoints: "lg" });
      this.props.changeBreakpoints("lg");
    } else if (params <= 600) {
      this.setState({ sidebarBreakpoints: "sm" });
      this.props.changeBreakpoints("sm");
    }
  };

  handle_country = (event) => {
    this.props.set_country(event.value);
    this.props.getSalesmanFilter(event.value);
  };

  render() {
    let { loading_status, loading_item, performance_status, version } =
      this.props.data;
    const { user, country_select } = this.props.auth;
    const {
      list_menu,
      listMenuAfter,
      sidebarBreakpoints,
      current_menu,
      modal_apk_version,
    } = this.state;
    let item_list_menu = (
      <main>
        <div
          className={`grid ${sidebarBreakpoints === "lg" && "grid-cols-4"} ${
            sidebarBreakpoints === "md" && "grid-cols-3"
          } ${sidebarBreakpoints === "sm" && "grid-cols-2"} gap-2`}
        >
          {list_menu.map((e, i) => (
            <div
              key={i}
              className={`h-20 px-1 rounded-md flex justify-center items-center text-center bg-gray-200 cursor-pointer hover:bg-green-300 ${
                e.key === current_menu.key && "bg-green-300"
              }`}
              title={e.title}
              onClick={this.on_set_menu.bind(this, e)}
            >
              {e.name}
            </div>
          ))}
        </div>
        <hr className="mt-4" />
      </main>
    );
    /*
  pending
  accepted
  rejected
  */
    if (user.status_badge !== "accepted") {
      item_list_menu = (
        <main>
          Your account do not have a status <b>{user.status_badge}</b>.
          <br />
          Contact super admin for activate your account
        </main>
      );
    }

    let item_after_list_menu = (
      <div className="">
        <div className="flex items-center">
          <div
            className="text-red-700 mr-2 cursor-pointer"
            title="Menu"
            onClick={this.on_back}
          >
            <IconComponents icon="menu" className="h-8 w-8" />
          </div>
          <div className="text-xl font-medium">{current_menu.name}</div>
        </div>
        <hr className="my-2" />
        {current_menu.component}
        {performance_status && <Legenda />}
      </div>
    );

    const item_filter = (
      <React.Fragment>
        {!listMenuAfter && item_list_menu}
        {listMenuAfter && item_after_list_menu}
      </React.Fragment>
    );

    const item_resize_menu = (
      <div className="relative">
        <div
          className="bg-gray-100 text-gray-600 mt-24 w-fit h-fit py-3 px-1 cursor-pointer"
          onClick={() =>
            this.setState((prev) => ({ sidebarMode: !prev.sidebarMode }))
          }
          onDoubleClick={this.change_sidebar.bind(this, 2)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
            />
          </svg>
        </div>
        <div
          className={`
            flex bg-white mt-1 gap-4 py-2 px-4 rounded shadow absolute transition-transform
            ${this.state.sidebarMode ? "" : "hidden"} 
            ${this.state.sidebarPosition === 1 && "-translate-x-1/3"}
            ${this.state.sidebarPosition === 2 && "-translate-x-2/3"}
            ${this.state.sidebarPosition === 3 && "-translate-x-3/3"}
            `}
        >
          <div
            className="cursor-pointer"
            onClick={this.change_sidebar.bind(this, 3)}
          >
            S
          </div>
          <div
            className="cursor-pointer"
            onClick={this.change_sidebar.bind(this, 1)}
          >
            M
          </div>
          <div
            className="cursor-pointer"
            onClick={this.change_sidebar.bind(this, 2)}
          >
            L
          </div>
        </div>
      </div>
    );

    const item_resize_line = (
      <div
        className="resize-handle--x"
        data-target="aside"
        onMouseDown={this.sideMouseDown}
        onDoubleClick={this.change_sidebar.bind(this, 1)}
      ></div>
    );

    const logout_button = (
      <div>
        <button className="button" id="red" onClick={this.props.logoutUser}>
          Logout
        </button>
      </div>
    );

    let apk_button;
    if (
      this.props.auth.master_admin.filter(
        (e) => e._id === this.props.auth.user._id
      ).length > 0
    ) {
      apk_button = (
        <button className="button" id="grey" onClick={this.toggle_apk_version}>
          APK version
        </button>
      );
    }

    const item_header = (
      <main className="flex items-center mb-2">
        <button onClick={this.on_click_logo}>
          <img alt="Nabati" src={logo} style={{ cursor: "pointer" }} />
        </button>
        <section className="">
          <h2 className="text-2xl font-bold mb-0 mr-10">NABATI-OUTDIG</h2>
          <small>Version: {version}</small>
          <div className="flex gap-1">
            {logout_button}
            {apk_button}
          </div>
          <div className="mt-2">
            {this.props.auth?.user?.badge !== "super_admin" ? (
              <div>{this.props.auth?.user?.negara}</div>
            ) : (
              <Select
                options={country_select}
                onChange={this.handle_country}
                defaultValue={{
                  label: this.props.auth?.user?.negara,
                  value: this.props.auth?.user?.negara,
                }}
                className="w-full"
              />
            )}
          </div>
        </section>
      </main>
    );

    let jabatan = this.props.auth.user.jabatan
      ? this.props.auth.user.jabatan
      : "";
    switch (this.props.auth.user?.jabatan) {
      case "ASS":
        jabatan = "AREA SALES SUPERVISOR";
        break;
      case "BM":
        jabatan = "BUSINESS MANAGER";
        break;
      case "RBM":
        jabatan = "REGIONAL BUSINESS MANAGER";
        break;
      default:
        break;
    }

    const user_info = (
      <div className="mb-4">
        <hr className="mb-2" />
        <p className="font-semibold">
          {this.props.auth.user.full_name.toUpperCase()}
        </p>
        <div className="flex">
          <small className="flex-1">
            Job Position: <b>{jabatan}</b>
          </small>
          <small className="rounded-full px-2 bg-gray-300">
            {this.props.auth.user.badge}
          </small>
        </div>
      </div>
    );

    const loadingSpiner = (
      <div>
        {!!(loading_status && loading_item === "get_outlet_list") && (
          <Spinner text="Memuat data outlet..." />
        )}
      </div>
    );

    const modal_apk_version_content = modal_apk_version && (
      <Modal
        modalSize="large"
        isOpen={modal_apk_version}
        onClose={this.toggle_apk_version}
      >
        <div className="box-body">
          <main style={{ minHeight: "100vh" }}>
            <EditApkVersion />
          </main>
        </div>
      </Modal>
    );

    return (
      <main
        className={`main-sidebar relative transition-transform duration-700 ${
          this.state.sidebarPosition === 1 && `translate-x-0`
        } ${this.state.sidebarPosition === 3 && `-translate-x-[504px]`} ${
          this.state.sidebarPosition === 2 && `translate-x-0`
        }`}
      >
        {modal_apk_version_content}
        <aside
          className="sidebar"
          id="sidebar"
          style={{ width: this.state.sidebarWidth }}
        >
          {item_header}
          {user_info}
          {loadingSpiner}
          {item_filter}
        </aside>
        {item_resize_line}
        {item_resize_menu}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({ data: state.data, auth: state.auth });

export default connect(mapStateToProps, {
  set_pick_status,
  set_performance_status,
  logoutUser,
  set_selected_menu,
  turn_on_menu,
  changeBreakpoints,
  getSalesmanFilter,
  set_tool_status,
  set_country,
})(LEFT_SIDE_BAR);
