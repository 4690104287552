import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";
import format_jam_menit from "../validation/format_jam_menit";

const SERVER_URL = geoServerBaseUrl;
// const SERVER_URL_OPEN =
//   "https://server.mapid.io/layers_new/get_detail_layer_by_id/61822fbd84665837ec1a6c26/617b7201f742b7120d37ecc2";

// export const getLatLong = () => async (dispatch) => {
//   try {
//     const res = await axios.get(SERVER_URL_OPEN);
//     const arrLongLat = res.data.geojson.features[33].geometry.coordinates[0];
//     dispatch({
//       type: "SAVE_LAT_LNG",
//       payload: { lat: arrLongLat[1], lng: arrLongLat[0], rad: arrLongLat[2] },
//     });
//   } catch (error) {
//     console.log(error);
//   }
// };

export const set_loading = (loading_item) => {
  return {
    type: "SET_LOADING",
    payload: loading_item,
  };
};

export const clear_loading = () => {
  return {
    type: "CLEAR_LOADING",
  };
};

export const push_rayon_id = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    dispatch(set_loading("push_rayon_id"));
    await axios.post(
      SERVER_URL + "/nabati_deliver/push_rayon_id",
      body,
      config
    );
    dispatch({
      type: "push_rayon_id",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (error) {
    dispatch(clear_loading());
  }
};

export const set_rayon_id_list = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    dispatch(set_loading("set_rayon_id_list"));
    await axios.post(
      SERVER_URL + "/nabati_deliver/set_rayon_id_list",
      body,
      config
    );
    dispatch({
      type: "set_rayon_id_list",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (error) {
    dispatch(clear_loading());
  }
};

export const set_rayon_id = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    dispatch(set_loading("set_rayon_id"));
    await axios.post(SERVER_URL + "/nabati_deliver/set_rayon_id", body, config);
    dispatch({
      type: "set_rayon_id",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (error) {
    dispatch(clear_loading());
  }
};

export const set_rayon_id_bulk = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("set_rayon_id_bulk"));

    let { array } = body;
    array = array.filter((item) => item.rayon_id);

    const delay_constant = 0;
    const delay_promise = () =>
      new Promise((res) => setTimeout(res, delay_constant));

    const parent_function = () => {
      return array.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };

    const child_function = async (item) => {
      return delay_promise().then(() => {
        const core_function = async () => {
          try {
            const driver_id = item._id;
            const rayon_id = item.rayon_id;
            const rayon_mode = "auto_pairing";
            const body = {
              driver_id,
              rayon_id,
              rayon_mode,
            };
            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };
            await axios.post(
              SERVER_URL + "/nabati_deliver/set_rayon_id",
              body,
              config
            );
            dispatch({
              type: "set_rayon_id",
              payload: body,
            });
          } catch (error) {}
        };
        return core_function();
      });
    };

    parent_function().then(() => {
      dispatch(clear_loading());
    });
  } catch (error) {
    dispatch(clear_loading());
  }
};

export const pull_rayon_id = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    dispatch(set_loading("pull_rayon_id"));
    await axios.post(
      SERVER_URL + "/nabati_deliver/pull_rayon_id",
      body,
      config
    );
    dispatch({
      type: "pull_rayon_id",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (error) {
    dispatch(clear_loading());
  }
};

export const getLog = (props) => async (dispatch) => {
  try {
    let res;
    if (props.filter) {
      res = await axios.get(
        `${SERVER_URL}/nabati/log_filter/${props.id}?start=${props.start}&end=${props.end}`
      );
    } else {
      res = await axios.get(`${SERVER_URL}/nabati/log/${props.id}`);
    }
    let filterLog = [];
    let now = 0;
    for (let index = 0; index < res.data.length; index++) {
      const element = res.data[index];
      if (element.time_stamp[0] > now + 60 * 1000) {
        filterLog.push(element);
        now = element.time_stamp[0];
      }
    }
    res.data = filterLog;
    dispatch({
      type: "SAVE_GET_LOG",
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const get_log_multiple = (props, depos) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data_log = [];

      const salesmans = await axios.get(
        `${SERVER_URL}/nabati/filter?hour=0&key=Depo&value=${props.value}`
      );
      let salesmans_log = [];
      for (let index = 0; index < salesmans.data.length; index++) {
        const element = salesmans.data[index];
        salesmans_log.push({
          _id: element._id,
          "Nama Salesman": element["Nama Salesman"],
          "Nama Only": element["Nama Only"],
          NIK: element["NIK"],
          Depo: element.Depo,
          "Kode Plan": element["Kode Plan"],
          kd_sales_arr: element.kd_sales_arr,
          divisi_arr: element.divisi_arr,
          log: [],
        });
      }

      for (let index = 0; index < salesmans_log.length; index++) {
        const element = salesmans_log[index];
        const _id = element._id;
        const logs = await axios.get(
          `${SERVER_URL}/nabati/log_by_salesman/by_one?start=${props.start}&end=${props.end}&_id=${_id}`
        );
        element.log = logs.data;
        data_log.push(element);
      }

      // const res = await axios.get(
      //   `${SERVER_URL}/nabati/log_by_depo/${props.value}?start=${
      //     props.start
      //   }&end=${props.end}&not_all=${props.not_all}&key=${"Depo"}&divisi_arr=${
      //     props.divisi_arr
      //   }`
      // );
      // data_log = res.data;
      if (props.status === "status" || props.status === "Salesman Online") {
        let newData = data_log.filter((e) => e.log.length !== 0);
        newData.forEach((el, i) => {
          let filterLog = [];
          let now = 0;
          for (let index = 0; index < el.log.length; index++) {
            const element = el.log[index];
            if (element.time_stamp[0] > now + 60 * 1000) {
              filterLog.push(element);
              now = element.time_stamp[0];
            }
          }
          newData[i].log = filterLog;
        });
        resolve(newData);
      } else {
        data_log.forEach((el, i) => {
          let filterLog = [];
          let now = 0;
          for (let index = 0; index < el.log.length; index++) {
            const element = el.log[index];
            if (element.time_stamp[0] > now + 60 * 1000) {
              filterLog.push(element);
              now = element.time_stamp[0];
            }
          }
          data_log[i].log = filterLog;
        });
        resolve(data_log);
      }
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const get_log_salesman_active = (props, depos) => {
  return new Promise(async (resolve, reject) => {
    try {
      let getData = [];
      for (let i = 0; i < depos.length; i++) {
        const element = depos[i];
        const res = await axios.get(
          `${SERVER_URL}/nabati/log_by_depo/${element}?start=${
            props.start
          }&end=${props.end}&not_all=false&key=${"Depo"}&divisi_arr=`
        );
        getData = [...getData, ...res.data];
      }
      const newData = getData.filter((e) => e.log.length !== 0);
      newData.forEach((el, i) => {
        let filterLog = [];
        let now = 0;
        for (let index = 0; index < el.log.length; index++) {
          const element = el.log[index];
          if (element.time_stamp[0] > now + 60 * 1000) {
            filterLog.push(element);
            now = element.time_stamp[0];
          }
        }
        newData[i].log = filterLog;
      });
      resolve(newData);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const get_driver_multi_depo = (body, status) => async (dispatch) => {
  try {
    dispatch(set_loading_top());
    let getData = [];
    for (let i = 0; i < body.length; i++) {
      const element = body[i];
      const res = await axios.post(
        `${SERVER_URL}/nabati_deliver/get_driver_multi_depo`,
        { depos: element }
      );
      getData = [...getData, ...res.data];
      //   const res = await axios.post(
      //   `${SERVER_URL}/nabati_deliver/get_driver_multi_depo`,
      //   { depos: body }
      // );
    }
    if (status === "Semua Driver") {
      dispatch({ type: "SET_DRIVER_LIST", payload: getData });
      dispatch({ type: "SET_DRIVER_LIST_WITH_LOCATION", payload: getData });
    } else if (status === "Online Driver") {
      const onlineDriver = getData.filter(
        (e) => e.status_bike === "bike_green"
      );
      dispatch({ type: "SET_DRIVER_LIST", payload: onlineDriver });
      dispatch({
        type: "SET_DRIVER_LIST_WITH_LOCATION",
        payload: onlineDriver,
      });
    } else if (status === "Active Driver") {
      const activeDriver = getData.filter(
        (e) => e.status_bike === "bike_blue" || e.status_bike === "bike_green"
      );
      dispatch({ type: "SET_DRIVER_LIST", payload: activeDriver });
      dispatch({
        type: "SET_DRIVER_LIST_WITH_LOCATION",
        payload: activeDriver,
      });
    } else {
      dispatch({ type: "SET_DRIVER_LIST", payload: getData });
      dispatch({
        type: "SET_DRIVER_LIST_WITH_LOCATION",
        payload: getData,
      });
    }

    dispatch(clear_loading_top());
  } catch (error) {
    dispatch(clear_loading_top());
    console.log(error);
  }
};

// export const get_driver_all = () => async (dispatch) => {
//   try {
//     dispatch(set_loading_top());
//     const res = await axios.get(`${SERVER_URL}/nabati_deliver/get_driver_all`);
//     dispatch({ type: "SET_DRIVER_LIST", payload: res.data });
//     dispatch({ type: "SET_DRIVER_LIST_WITH_LOCATION", payload: res.data });
//     dispatch(clear_loading_top());
//   } catch (error) {
//     dispatch(clear_loading_top());
//     console.log(error);
//   }
// };

export const api_force_logout = (_id) => async (dispatch) => {
  try {
    dispatch(set_loading_top());
    await axios.post(`${SERVER_URL}/nabati_deliver/force_logout`, {
      _id,
    });
    dispatch(clear_loading_top());
  } catch (error) {
    dispatch(clear_loading_top());
    console.log(error);
  }
};

export const edit_driver = (body) => async (dispatch) => {
  try {
    dispatch(set_loading_top());
    const res = await axios.post(`${SERVER_URL}/nabati_deliver/edit`, body);
    dispatch({
      type: "UPDATE_DRIVER_LIST",
      payload: res.data,
    });
    dispatch(clear_loading_top());
  } catch (error) {
    dispatch(clear_loading_top());
    console.log(error);
  }
};

export const add_driver = (body) => async (dispatch) => {
  try {
    dispatch(set_loading_top());
    await axios.post(`${SERVER_URL}/nabati_deliver/add_driver`, body);
    dispatch(clear_loading_top());
  } catch (error) {
    dispatch(clear_loading_top());
    console.log(error);
  }
};

export const get_log_one_driver =
  ({ _id, start_date, end_date }) =>
  async (dispatch) => {
    try {
      dispatch(set_loading_top());
      const res = await axios.get(
        `${SERVER_URL}/nabati_deliver/get_log_one_driver?id=${_id}&start=${start_date}&end=${end_date}`
      );

      let geojsonPoint = {};
      if (res.data.length !== 0) {
        geojsonPoint = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                rad: res.data[res.data.length - 1].bearing,
                timestamp: res.data[res.data.length - 1].time_stamp[0],
              },
              geometry: {
                type: "Point",
                coordinates: [
                  res.data[res.data.length - 1].longitude,
                  res.data[res.data.length - 1].latitude,
                ],
              },
            },
          ],
        };
      }

      const geojsonLineString = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: [],
            },
          },
        ],
      };
      const geojsonMultiPoint = {
        type: "FeatureCollection",
        features: [],
      };

      res.data.forEach((element) => {
        geojsonMultiPoint.features.push({
          type: "Feature",
          properties: {
            rad: element.bearing,
            timestamp: element.time_stamp[0],
            simple_timestamp: format_jam_menit(element.time_stamp[0]),
          },
          geometry: {
            type: "Point",
            coordinates: [element.longitude, element.latitude],
          },
        });

        geojsonLineString.features[0].geometry.coordinates.push([
          element.longitude,
          element.latitude,
        ]);
      });

      dispatch({
        type: "SET_DRIVER_GEOJSON_POINT",
        payload: geojsonPoint,
      });
      dispatch({
        type: "SET_DRIVER_GEOJSON_MULTI_POINT",
        payload: geojsonMultiPoint,
      });
      dispatch({
        type: "SET_DRIVER_GEOJSON_LINE_STRING",
        payload: geojsonLineString,
      });

      dispatch(clear_loading_top());
    } catch (error) {
      dispatch(clear_loading_top());
      console.log(error);
    }
  };

export const get_log_driver_multi_depo = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(SERVER_URL + "/nabati_deliver/get_log_multi_depo", body)
      .then((result) => resolve(result.data))
      .catch((err) => reject(err));
  });
};

export const get_all_log_driver = (start, end, depo) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        SERVER_URL +
          "/nabati_deliver/get_log_all" +
          `?start=${start}&end=${end}`
      )
      .then((result) => resolve(result.data))
      .catch((err) => reject(err));
  });
};

export const get_online_driver = (country) => async (dispatch) => {
  try {
    dispatch(set_loading_top());
    const res = await axios.get(
      `${SERVER_URL}/nabati_deliver/get_driver_online?country=${country}`
    );
    dispatch({ type: "SET_DRIVER_LIST", payload: res.data });
    dispatch({ type: "SET_DRIVER_LIST_WITH_LOCATION", payload: res.data });
    dispatch(clear_loading_top());
  } catch (error) {
    dispatch(clear_loading_top());
    console.log(error);
  }
};

export const get_active_driver = (country) => async (dispatch) => {
  try {
    const hour = new Date().getHours();
    dispatch(set_loading_top());
    const res = await axios.get(
      `${SERVER_URL}/nabati_deliver/get_driver_active?country=${country}&?hour=${hour}`
    );
    dispatch({ type: "SET_DRIVER_LIST", payload: res.data });
    dispatch({ type: "SET_DRIVER_LIST_WITH_LOCATION", payload: res.data });
    dispatch(clear_loading_top());
  } catch (error) {
    dispatch(clear_loading_top());
    console.log(error);
  }
};

export const get_log_online_driver = (start, end) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        SERVER_URL +
          "/nabati_deliver/get_log_online_driver" +
          `?start=${start}&end=${end}`
      )
      .then((result) => resolve(result.data))
      .catch((err) => reject(err));
  });
};

export const get_log_active_driver = (start, end) => {
  return new Promise((resolve, reject) => {
    const hour = new Date().getHours();
    axios
      .get(
        SERVER_URL +
          "/nabati_deliver/get_log_active_driver" +
          `?start=${start}&end=${end}&hour=${hour}`
      )
      .then((result) => resolve(result.data))
      .catch((err) => reject(err));
  });
};

export const get_driver_inactive = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        SERVER_URL +
          `/nabati_deliver/inactive?search=${params.search}&key=${params.key}`
      )
      .then((e) => resolve(e.data))
      .catch((e) => reject(e));
  });
};

export const edit_driver_inactive = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(SERVER_URL + `/nabati_deliver/inactive`, {
        _id: params._id,
        inactive: params.inactive,
      })
      .then((e) => resolve(e.data))
      .catch((e) => reject(e));
  });
};

// Non API

export const sliderLog = (value) => (dispatch) => {
  dispatch({ type: "SLIDER_LOG", payload: parseInt(value.slider) });
};

export const clearLog = () => (dispatch) => {
  dispatch({
    type: "CLEAR_LOG",
  });
};

export const filter_divisi = (key, value) => (dispatch) => {
  dispatch({
    type: "SET_FILTER_DIVISI",
    payload: { key, value },
  });
};

export const set_time_array = (payload) => (dispatch) => {
  dispatch({
    type: "SET_TIME_ARRAY",
    payload,
  });
};

export const set_loading_top = () => {
  return {
    type: "SET_LOADING_TOP",
  };
};

export const clear_loading_top = () => {
  return {
    type: "CLEAR_LOADING_TOP",
  };
};

export const set_driver_daerah_kerja = (payload) => (dispatch) => {
  dispatch({
    type: "SET_DRIVER_DAERAH_KERJA",
    payload,
  });
};

export const set_driver_daerah_kerja_value = (payload) => (dispatch) => {
  dispatch({
    type: "SET_DRIVER_DAERAH_KERJA_VALUE",
    payload,
  });
};

export const set_driver_daerah_kerja_2 = (payload) => (dispatch) => {
  dispatch({
    type: "SET_DRIVER_DAERAH_KERJA_2",
    payload,
  });
};

export const set_driver_daerah_kerja_2_value = (payload) => (dispatch) => {
  dispatch({
    type: "SET_DRIVER_DAERAH_KERJA_2_VALUE",
    payload,
  });
};

export const set_modal_current_driver = (payload) => (dispatch) => {
  dispatch({
    type: "SET_MODAL_CURRENT_DRIVER",
    payload,
  });
};

export const set_current_driver = (payload) => (dispatch) => {
  dispatch({
    type: "SET_CURRENT_DRIVER",
    payload,
  });
  if (Object.keys(payload).length !== 0) {
    if (payload?.log.length !== 0) {
      dispatch({
        type: "SET_DRIVER_GEOJSON_POINT",
        payload: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                rad: payload.log[0].bearing,
                timestamp: payload.log[0].time_stamp[0],
              },
              geometry: {
                type: "Point",
                coordinates: [
                  payload.log[0].longitude,
                  payload.log[0].latitude,
                ],
              },
            },
          ],
        },
      });
    }
  }
};

export const search_driver = (query) => (dispatch) => {
  dispatch({
    type: "SEARCH_DRIVER",
    payload: query,
  });
};

export const set_modal_add_driver = (payload) => (dispatch) => {
  dispatch({
    type: "SET_MODAL_ADD_DRIVER",
    payload,
  });
};

export const slider_log_driver = (payload) => (dispatch) => {
  dispatch({
    type: "SET_DRIVER_GEOJSON_POINT",
    payload,
  });
};

export const clear_current_driver = () => (dispatch) => {
  dispatch({
    type: "SET_DRIVER_GEOJSON_POINT",
    payload: {},
  });
  dispatch({
    type: "SET_DRIVER_GEOJSON_MULTI_POINT",
    payload: {},
  });
  dispatch({
    type: "SET_DRIVER_GEOJSON_LINE_STRING",
    payload: {},
  });
};

export const tracking_menu_selected = (payload) => (dispatch) => {
  dispatch({
    type: "SET_TRACKING_MENU_SELECTED",
    payload,
  });
};

export const undo_driver_with_location = () => {
  return { type: "UNDO_DRIVER_WITH_LOCATION" };
};

export const clear_driver_with_location = () => {
  return { type: "CLEAR_DRIVER_WITH_LOCATION" };
};

export const searchDriverRayon = (query) => (dispach) => {
  dispach({
    type: "SEARCH_DRIVER_RAYON_LIST",
    payload: query,
  });
};
