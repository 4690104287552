import React, { Component } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import {
  bulk_add_salesman,
  reset_bulk_add_salesman,
} from "../../actions/dataActions";
import readXlsxFile from "read-excel-file";
import writeXlsxFile from "write-excel-file";

const template_csv = [
  [
    "USERNAME",
    "PASSWORD",
    "DISTRIBUTOR/DEPO",
    "NIK",
    "KODE SALESMAN",
    "KODE PLAN",
    "NAMA SALESMAN",
    "KONTAK HP",
    "DIVISI",
    "COUNTRY",
  ],
  [
    "username_1",
    "password_1",
    "DEPO TEST",
    "123456",
    "123456",
    "P107",
    "nama_1",
    "000",
    "DA",
    "INDONESIA",
  ],
];

class UploadAddSalesman extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      file_header: [],
      file_body: [],
      mode_download: "",
      loadingExcel: false,
    };
  }
  on_reset_bulk_add_salesman = () => {
    this.props.reset_bulk_add_salesman();
    this.setState({ file_header: [], file_body: [] });
  };
  handleFile = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  };

  handleFileExcel = (event) => {
    this.setState({ loadingExcel: true });
    this.on_reset_bulk_add_salesman();
    try {
      readXlsxFile(event.target.files[0]).then((rows) => {
        const HEADER = rows.shift();
        // const HEADER = rawHeader;
        // const HEADER = rawHeader.map((e) =>
        //   e.replace(/\s*$/, "").toUpperCase()
        // );
        let DATA = [];
        rows.forEach((element) => {
          let obj = {};
          HEADER.forEach((el, idx) => {
            let value = element[idx];
            if (typeof value !== "string") {
              value = value.toString();
            }
            const value_test = String(value);
            if (value_test?.includes("#N/A") || !value_test || !value) {
              value = "";
            }
            if (el === "KONTAK HP") {
              value = element?.[idx][0]?.replace(/\s/g, "");
            }
            obj[el] = value;
          });
          if (HEADER.length === element.length) {
            DATA.push(obj);
          }
        });
        this.setState({
          file_header: HEADER,
          file_body: DATA,
          loadingExcel: false,
        });
      });
    } catch (err) {
      console.log(err);
      alert("Error process file!");
    }
  };

  processFile = async () => {
    this.on_reset_bulk_add_salesman();
    try {
      const file = this.state.file;
      const result = () => {
        return new Promise((resolve, reject) => {
          Papa.parse(file, {
            complete: function (results) {
              resolve(results);
            },
            error: function (err) {
              reject(err);
            },
          });
        });
      };
      const final_result = await result();
      // console.log(final_result);
      const file_header = final_result.data.shift();
      const file_body = final_result.data;

      let arrOfObject = [];
      file_body.forEach((element) => {
        let obj = {};
        file_header.forEach((el, idx) => {
          let value = element[idx];
          if (el === "KONTAK HP") value = element?.[idx]?.replace(/\s/g, "");
          obj[el] = value;
        });
        if (file_header.length === element.length) {
          arrOfObject.push(obj);
        }
      });
      this.setState({
        file_header,
        file_body: arrOfObject,
      });
    } catch (error) {
      console.log(error);
      alert("Error process file!");
    }
  };
  saveBulk = () => {
    let body = this.state.file_body;
    this.props.bulk_add_salesman(body);
  };
  on_change_download = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({
      [name]: value,
      file_body: [],
      file_header: [],
      is_checked: false,
    });
  };

  downloadExampleExcel = async () => {
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });
      try {
        const schema = [
          {
            column: "USERNAME",
            type: String,
            value: (target) => target.userName,
          },
          {
            column: "PASSWORD",
            type: String,
            value: (target) => target.password,
          },
          {
            column: "DISTRIBUTOR/DEPO",
            type: String,
            value: (target) => target.depo,
          },
          {
            column: "NIK",
            type: String,
            value: (target) => target.nik,
          },
          {
            column: "KODE SALESMAN",
            type: String,
            value: (target) => target.kodeSalesman,
          },
          {
            column: "KODE PLAN",
            type: String,
            value: (target) => target.kodePlan,
          },
          {
            column: "NAMA SALESMAN",
            type: String,
            value: (target) => target.namaSalesman,
          },
          {
            column: "KONTAK HP",
            type: String,
            value: (target) => target.kontakHp,
          },
          {
            column: "DIVISI",
            type: String,
            value: (target) => target.divisi,
          },
          {
            column: "COUNTRY",
            type: String,
            value: (target) => target.country,
          },
        ];
        let newData = [
          {
            userName: "username_1",
            password: "password_1",
            depo: "DEPO TEST",
            nik: "123456",
            kodeSalesman: "123456",
            kodePlan: "P107",
            namaSalesman: "nama_1",
            kontakHp: "000",
            divisi: "DA",
            country: "INDONESIA",
          },
        ];
        await writeXlsxFile(newData, {
          schema,
          fileName: "ExampleUploadSalesman.xlsx",
        });
        this.setState({
          loading_csv: false,
        });
      } catch (error) {
        console.log(error);
        this.setState({
          loading: false,
        });
      }
    }
  };
  render() {
    const { bulk_add_salesman_status, loading_status, loading_item } =
      this.props.data;
    const { file_header, file_body, mode_download } = this.state;
    const syarat_header = template_csv[0];
    const syarat_content = (
      <table className="table" style={{ fontSize: "11px" }}>
        <tbody>
          {syarat_header.map((e, idx) => {
            let status = (
              <td id="green" style={{ width: "70px", textAlign: "center" }}>
                V
              </td>
            );
            if (!file_header.includes(e))
              status = (
                <td id="red" style={{ width: "70px", textAlign: "center" }}>
                  X
                </td>
              );
            return (
              <tr key={idx}>
                <td>{e}</td>
                {status}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
    let button = (
      <button className={`button_main w-full mt-2`} onClick={this.saveBulk}>
        Start Upload
      </button>
    );
    if (loading_status && loading_item === "bulk_add_salesman") {
      button = (
        <button className={`button w-full mt-2`} id="green">
          Uploading...
        </button>
      );
    } else if (bulk_add_salesman_status.length > 0) {
      button = (
        <section className="text_center">
          <button className={`button w-full mt-2 mb-2`} id="green">
            Uploaded ✔
          </button>
          <br />
          <button
            className="button"
            id="green"
            onClick={this.on_reset_bulk_add_salesman}
          >
            Upload more
          </button>
        </section>
      );
    }
    const select_download = (
      <section className="main_container mb-3">
        <p className="text_bold">Select Mode Upload</p>
        <select
          name="mode_download"
          id="mode_download"
          value={mode_download}
          onChange={this.on_change_download}
        >
          <option value="pilih">Select</option>
          <option value="csv">CSV</option>
          <option value="excel">EXCEL</option>
        </select>
      </section>
    );
    let renderData;
    if (mode_download === "excel" || mode_download === "csv") {
      renderData = (
        <React.Fragment>
          {mode_download === "excel" ? (
            <section>
              <div className="border p-4 my-4 bg-yellow-100 rounded">
                Warning! for using this feature please follow the instructions
                according to the downloadable template{" "}
                <button
                  className="text-blue-600"
                  onClick={this.downloadExampleExcel}
                >
                  here
                </button>
              </div>
              <div className="grid grid-cols-1 gap-1">
                <label htmlFor="file">Select EXCEL file</label>
                <input
                  onChange={this.handleFileExcel}
                  className="w-fit"
                  type="file"
                  id="input"
                />
                {this.state.loadingExcel && (
                  <p
                    className="text_bold bg-[#51B13D] rounded w-1/5 text-white text-center
                        "
                  >
                    Loading Data...
                  </p>
                )}
              </div>
              {file_body.length !== 0 && (
                <main>
                  <p className="text_bold mt-5">Data Verification</p>
                  {syarat_content}
                </main>
              )}
              {file_body.length !== 0 && (
                <p className="text_bold mt-5">Data Preview</p>
              )}
              {file_body.length !== 0 && (
                <div
                  className="w-full overflow-auto n-scroll pb-2 pr-2"
                  style={{ maxHeight: "calc(( 100vh - 400px ))" }}
                >
                  <table className="border-2 border-separate">
                    <thead>
                      <tr className="text-left bg-gray-300">
                        <th>No</th>
                        {file_header.map((el, idx) => (
                          <th key={idx}>{el}</th>
                        ))}
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {file_body.map((el, idx) => {
                        let status = "Waiting for upload";
                        let id;
                        if (
                          loading_status &&
                          loading_item === "bulk_add_salesman"
                        ) {
                          status = "Uploading...";
                        } else if (bulk_add_salesman_status.length > 0) {
                          status = bulk_add_salesman_status.find(
                            (e) => e.USERNAME === el.USERNAME
                          ).status;
                          if (status === "success") {
                            id = "green";
                          } else {
                            id = "red";
                          }
                        }
                        return (
                          <tr key={idx} className={`even:bg-gray-50`}>
                            <td className="font-semibold">{idx + 1}</td>
                            {file_header.map((elHeader, idx) => (
                              <td key={idx}>{el[elHeader]}</td>
                            ))}
                            <td id={id}>{status}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {file_body.length !== 0 && <div>{button}</div>}
            </section>
          ) : (
            <React.Fragment>
              <div className="border p-4 my-4 bg-yellow-100 rounded">
                Warning! for using this feature please follow the instructions
                according to the downloadable template{" "}
                <CSVLink
                  className="text-blue-600"
                  data={template_csv}
                  filename="ADD SALESMAN.csv"
                  separator=";"
                >
                  here
                </CSVLink>
              </div>
              <div className="grid grid-cols-1 gap-1">
                <label htmlFor="file">Select CSV file</label>
                <input
                  type="file"
                  id="file"
                  className="w-fit"
                  accept=".csv"
                  onChange={this.handleFile}
                />
              </div>
              <button className="button_main" onClick={this.processFile}>
                Processing File
              </button>
              {file_body.length !== 0 && (
                <main>
                  <p className="text_bold mt-5">Data Verification</p>
                  {syarat_content}
                </main>
              )}
              {file_body.length !== 0 && (
                <p className="text_bold mt-5">Data Preview</p>
              )}
              {file_body.length !== 0 && (
                <div
                  className="w-full overflow-auto n-scroll pb-2 pr-2"
                  style={{ maxHeight: "calc(( 100vh - 400px ))" }}
                >
                  <table className="border-2 border-separate">
                    <thead>
                      <tr className="text-left bg-gray-300">
                        <th>No</th>
                        {file_header.map((el, idx) => (
                          <th key={idx}>{el}</th>
                        ))}
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {file_body.map((el, idx) => {
                        let status = "Waiting for upload";
                        let id;
                        if (
                          loading_status &&
                          loading_item === "bulk_add_salesman"
                        ) {
                          status = "Uploading...";
                        } else if (bulk_add_salesman_status.length > 0) {
                          status = bulk_add_salesman_status.find(
                            (e) => e.USERNAME === el.USERNAME
                          ).status;
                          if (status === "success") {
                            id = "green";
                          } else {
                            id = "red";
                          }
                        }
                        return (
                          <tr key={idx} className={`even:bg-gray-50`}>
                            <td className="font-semibold">{idx + 1}</td>
                            {file_header.map((elHeader, idx) => (
                              <td key={idx}>{el[elHeader]}</td>
                            ))}
                            <td id={id}>{status}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {file_body.length !== 0 && <div>{button}</div>}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }
    return (
      <main>
        <h1 className="text_bold mb-5">Add User Salesman By Upload</h1>
        {select_download}
        {renderData}
      </main>
    );
  }
}
const mapStateToProps = (state) => ({ data: state.data });
export default connect(mapStateToProps, {
  bulk_add_salesman,
  reset_bulk_add_salesman,
})(UploadAddSalesman);
