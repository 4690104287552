import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";
import st_colors from "../validation/st_colors";
import { get_count_competitor_national_not_done } from "./dataActions";
import { generate_filter_item } from "./filter_outlet_actions";

const SERVER_URL = geoServerBaseUrl;

/*GET METHODE*/

//get_merch_by_outlet_id
export const get_merch_by_outlet_id = (params) => async (dispatch) => {
  try {
    const { outlet_id } = params;
    dispatch({
      type: "set_loading_properties",
      payload: "get_merch_by_outlet_id",
    });

    dispatch({
      type: "set_value_properties",
      payload: { key: "merchandising_object", value: {} },
    });

    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let res = await axios.get(
      `${SERVER_URL}/properties/get_merch_by_outlet_id?outlet_id=${outlet_id}`,
      config
    );

    dispatch({
      type: "set_value_properties",
      payload: { key: "merchandising_object", value: res.data },
    });

    dispatch({
      type: "clear_loading_properties",
    });
  } catch (e) {
    dispatch({
      type: "clear_loading_properties",
    });
  }
};

//get_depo_list_by_country
export const get_depo_list_by_country = (params) => async (dispatch) => {
  try {
    const { country } = params;
    dispatch({
      type: "set_loading_properties",
      payload: "get_depo_list_by_country",
    });
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let res = await axios.get(
      `${SERVER_URL}/properties/get_depo_list_by_country?country=${country}`,
      config
    );
    dispatch({
      type: "set_value_properties",
      payload: { key: "depo_list", value: res.data },
    });
    dispatch({
      type: "clear_loading_properties",
    });
  } catch (e) {
    dispatch({
      type: "clear_loading_properties",
    });
  }
};

//get_competitor_rayon_task_newest
export const get_competitor_rayon_task_newest = () => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_properties",
      payload: "get_competitor_rayon_task_newest",
    });
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };

    let res = await axios.get(
      `${SERVER_URL}/properties/get_competitor_rayon_task_newest`,
      config
    );

    dispatch({
      type: "set_value_properties",
      payload: { key: "competitor_rayon_task_newest", value: res.data },
    });
    dispatch({
      type: "clear_loading_properties",
    });
  } catch (e) {
    dispatch({
      type: "clear_loading_properties",
    });
  }
};

//get_outlet_by_sfa
/*
body = {
  sfa_array: Array of string SFA
  file_body
}
*/
export const get_outlet_by_sfa = (body) => async (dispatch) => {
  dispatch({
    type: "set_loading_properties",
    payload: "get_outlet_by_sfa",
  });

  dispatch({
    type: "set_value_properties",
    payload: { key: "sfa_list", value: [] },
  });
  dispatch({
    type: "set_value_properties",
    payload: { key: "outlet_new", value: [] },
  });
  dispatch({
    type: "set_value_properties",
    payload: { key: "outlet_edit", value: [] },
  });
  dispatch({
    type: "set_value_properties",
    payload: { key: "progress_outlet_new", value: 0 },
  });
  dispatch({
    type: "set_value_properties",
    payload: { key: "progress_outlet_edit", value: 0 },
  });
  dispatch({
    type: "set_value_properties",
    payload: { key: "current_array_edit", value: [] },
  });
  dispatch({
    type: "set_value_properties",
    payload: { key: "success_array", value: [] },
  });
  dispatch({
    type: "set_value_properties",
    payload: { key: "not_found_array", value: [] },
  });
  dispatch({
    type: "set_value_properties",
    payload: { key: "error_array", value: [] },
  });
  try {
    let sfa_list = [];
    const { sfa_array, file_body } = body;
    const total = sfa_array.length;
    const limit = 5_000;
    const iteration = Math.ceil(total / limit);
    let array_loop = [];
    for (let i = 0; i < iteration; i++) {
      array_loop.push({
        start: i * limit,
        end: i * limit + limit,
      });
    }
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "total_get_id_sfa",
        value: total,
      },
    });
    const delay_c = 0;
    const delay = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function = () => {
      return array_loop.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const { start, end } = object;
            const sfa_array_new = sfa_array.slice(start, end);
            const body_new = {
              sfa_array: sfa_array_new,
            };
            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };
            const res = await axios.post(
              `${SERVER_URL}/nabati-outlet/get_outlet_by_sfa`,
              body_new,
              config
            );
            const current_length = sfa_array_new?.length || 0;
            dispatch({
              type: "incriment_properties",
              payload: {
                key: "progress_get_id_sfa",
                value: current_length,
              },
            });
            dispatch({
              type: "outlet_upload_changes",
            });
            if (res?.data?.length > 0) {
              dispatch({
                type: "push_list_bulk",
                payload: { key: "sfa_list", value: res.data },
              });

              sfa_list = [...sfa_list, ...res.data];
            }
          } catch (error) {}
        };
        return core_function();
      });
    };
    let outlet_new = [];
    let outlet_edit = [];
    parent_function().then(() => {
      //pemisahan outlet_new lakukan secara perlahan supaya tidak crash
      const sfa_only = sfa_list.map((t) => t["ID SFA"]);
      const total = file_body.length;
      const limit = 1_000;
      const iteration = Math.ceil(total / limit);
      let array_loop = [];
      for (let i = 0; i < iteration; i++) {
        array_loop.push({
          start: i * limit,
          end: i * limit + limit,
        });
      }
      const delay_c = 0;
      const delay = () => new Promise((res) => setTimeout(res, delay_c));

      dispatch({
        type: "set_value_properties",
        payload: {
          key: "total_check_id_sfa",
          value: array_loop?.[array_loop.length - 1]?.end,
        },
      });

      const parent_function = () => {
        return array_loop.reduce(
          (last_promise, object) =>
            last_promise.then((result_sum) =>
              child_function(object).then((result_current) => [
                ...result_sum,
                result_current,
              ])
            ),
          Promise.resolve([])
        );
      };

      const child_function = async (object) => {
        return delay().then(() => {
          const core_function = async () => {
            try {
              const { start, end } = object;
              const file_body_new = file_body.slice(start, end);

              const outlet_new_slice = file_body_new.filter(
                (t) => !sfa_only.includes(t["ID SFA"])
              );

              outlet_new = [...outlet_new, ...outlet_new_slice];

              dispatch({
                type: "set_value_properties",
                payload: {
                  key: "progress_outlet_new",
                  value: end,
                },
              });
            } catch (error) {}
          };
          return core_function();
        });
      };

      parent_function().then(() => {
        //pemisahan outlet_edit lakukan secara perlahan supaya tidak crash
        const sfa_only = sfa_list.map((t) => t["ID SFA"]);
        const total = file_body.length;
        const limit = 500;
        const iteration = Math.ceil(total / limit);
        let array_loop = [];
        for (let i = 0; i < iteration; i++) {
          array_loop.push({
            start: i * limit,
            end: i * limit + limit,
          });
        }
        const delay_c = 0;
        const delay = () => new Promise((res) => setTimeout(res, delay_c));

        dispatch({
          type: "set_value_properties",
          payload: {
            key: "total_check_id_sfa",
            value: array_loop?.[array_loop.length - 1]?.end,
          },
        });

        const parent_function = () => {
          return array_loop.reduce(
            (last_promise, object) =>
              last_promise.then((result_sum) =>
                child_function(object).then((result_current) => [
                  ...result_sum,
                  result_current,
                ])
              ),
            Promise.resolve([])
          );
        };

        const child_function = async (object) => {
          return delay().then(() => {
            const core_function = async () => {
              try {
                const { start, end } = object;
                const file_body_new = file_body.slice(start, end);
                let outlet_edit_slice = file_body_new.filter((t) =>
                  sfa_only.includes(t["ID SFA"])
                );
                outlet_edit_slice = outlet_edit_slice.map((item) => {
                  const data_id = sfa_list.find(
                    (outlet) => outlet["ID SFA"] === item["ID SFA"]
                  );
                  return { ...item, ...data_id };
                });
                outlet_edit = [...outlet_edit, ...outlet_edit_slice];
                dispatch({
                  type: "set_value_properties",
                  payload: {
                    key: "progress_outlet_edit",
                    value: end,
                  },
                });
              } catch (error) {}
            };
            return core_function();
          });
        };

        parent_function().then(() => {
          dispatch({
            type: "generate_outlet_new",
            payload: outlet_new,
          });
          dispatch({
            type: "generate_outlet_edit",
            payload: outlet_edit,
          });
          dispatch({
            type: "clear_loading_properties",
          });
          dispatch({
            type: "outlet_upload_changes",
          });
        });
      });
    });
  } catch (e) {
    dispatch({
      type: "clear_loading_properties",
    });
  }
};

//aggregate_segment_outlet
export const aggregate_segment_outlet = () => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_properties",
      payload: "aggregate_segment_outlet",
    });
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let res = await axios.get(
      `${SERVER_URL}/properties/aggregate_segment_outlet`,
      config
    );
    const segment_outlet = res.data || [];
    const segment_list_select = segment_outlet.map((item) => {
      return {
        label: item._id,
        value: item._id,
      };
    });
    const segment_list = segment_outlet.map((item, idx) => {
      let color_index = idx;
      if (idx > st_colors.length - 1) {
        color_index = idx % st_colors.length;
      }
      return {
        text: item._id,
        desc: item._id,
        color: st_colors[color_index],
      };
    });

    dispatch({
      type: "get_segment_list",
      payload: segment_list,
    });

    dispatch({
      type: "set_value_properties",
      payload: { key: "segment_list_select", value: segment_list_select },
    });
    dispatch({
      type: "aggregate_segment_outlet",
      payload: res.data,
    });
    dispatch({
      type: "clear_loading_properties",
    });
  } catch (e) {
    dispatch({
      type: "clear_loading_properties",
    });
  }
};

//aggregate_type_outlet
export const aggregate_type_outlet = () => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_properties",
      payload: "aggregate_type_outlet",
    });
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let res = await axios.get(
      `${SERVER_URL}/properties/aggregate_type_outlet`,
      config
    );
    const list = res.data || [];
    const color_list = list.map((item, idx) => {
      let color_index = idx;
      if (idx > st_colors.length - 1) {
        color_index = idx % st_colors.length;
      }
      return {
        text: item._id,
        desc: item._id,
        color: st_colors[color_index],
      };
    });
    dispatch({
      type: "get_type_outlet_list",
      payload: color_list,
    });
    dispatch({
      type: "clear_loading_properties",
    });
  } catch (e) {
    dispatch({
      type: "clear_loading_properties",
    });
  }
};

//aggregate_system_outlet
export const aggregate_system_outlet = () => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_properties",
      payload: "aggregate_system_outlet",
    });
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let res = await axios.get(
      `${SERVER_URL}/properties/aggregate_system_outlet`,
      config
    );
    const list = res.data || [];
    const color_list = list.map((item, idx) => {
      let color_index = idx;
      if (idx > st_colors.length - 1) {
        color_index = idx % st_colors.length;
      }
      return {
        text: item._id,
        desc: item._id,
        color: st_colors[color_index],
      };
    });
    dispatch({
      type: "get_competitor_code_list",
      payload: color_list,
    });
    dispatch({
      type: "clear_loading_properties",
    });
  } catch (e) {
    dispatch({
      type: "clear_loading_properties",
    });
  }
};

//get_merch
export const get_merch = (params) => async (dispatch) => {
  try {
    const { month, year, date_mode, area_mode, depo_selected, depo_list } =
      params;

    dispatch({
      type: "set_loading_properties",
      payload: "get_merch_by_date",
    });
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let res = await axios.get(
      `${SERVER_URL}/properties/get_merch?month=${month}&year=${year}&date_mode=${date_mode}&area_mode=${area_mode}&depo_selected=${depo_selected}`,
      config
    );
    let list = res.data || [];
    list = list.map((item) => {
      const depo_string = depo_list.find(
        (depo) => depo.plan_id === item.Plan
      )?.Depo;
      item.depo_string = depo_string;
      return item;
    });
    const outlet_merch_list_filter_0 = list.filter(
      (e) =>
        e?.outlet_id?.location?.coordinates?.[0] !== 0 &&
        e?.outlet_id?.location?.coordinates?.[1] >= -90 &&
        e?.outlet_id?.location?.coordinates?.[1] <= 90
    );
    dispatch({
      type: "set_value_data",
      payload: {
        key: "outlet_merch_list",
        value: list,
      },
    });
    dispatch({
      type: "set_value_data",
      payload: {
        key: "outlet_merch_list_back_up",
        value: list,
      },
    });
    dispatch({
      type: "set_value_data",
      payload: {
        key: "outlet_merch_list_filter_0",
        value: outlet_merch_list_filter_0,
      },
    });
    dispatch({
      type: "set_value_data",
      payload: {
        key: "outlet_merch_list_filter_0_back_up",
        value: outlet_merch_list_filter_0,
      },
    });
    const toko_list = list.map((item) => item?.outlet_id || {});
    dispatch(generate_filter_item(toko_list));

    dispatch({
      type: "clear_loading_properties",
    });
  } catch (e) {
    dispatch({
      type: "clear_loading_properties",
    });
  }
};

//get_merch_newest
export const get_merch_newest = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_properties",
      payload: "get_merch_by_date",
    });
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    let res = await axios.get(
      `${SERVER_URL}/properties/get_merch_newest?is_test=${params?.is_test}`,
      config
    );
    const list = res?.data || [];
    const outlet_merch_list_filter_0 = list.filter(
      (e) =>
        e?.outlet_id?.location?.coordinates?.[0] !== 0 &&
        e?.outlet_id?.location?.coordinates?.[1] >= -90 &&
        e?.outlet_id?.location?.coordinates?.[1] <= 90
    );
    dispatch({
      type: "set_value_data",
      payload: {
        key: "outlet_merch_list",
        value: list,
      },
    });
    dispatch({
      type: "set_value_data",
      payload: {
        key: "outlet_merch_list_back_up",
        value: list,
      },
    });
    dispatch({
      type: "set_value_data",
      payload: {
        key: "outlet_merch_list_filter_0",
        value: outlet_merch_list_filter_0,
      },
    });
    dispatch({
      type: "set_value_data",
      payload: {
        key: "outlet_merch_list_filter_0_back_up",
        value: outlet_merch_list_filter_0,
      },
    });
    const toko_list = list.map((item) => item?.outlet_id || {});
    dispatch(generate_filter_item(toko_list));

    dispatch({
      type: "clear_loading_properties",
    });
  } catch (e) {
    dispatch({
      type: "clear_loading_properties",
    });
  }
};

//get_merch_by_date
export const get_merch_by_date = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_properties",
      payload: "get_merch_by_date",
    });
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const { month, year } = params;
    let res = await axios.get(
      `${SERVER_URL}/properties/get_merch_by_date?month=${month}&year=${year}`,
      config
    );
    const list = res.data || [];
    const outlet_merch_list_filter_0 = list.filter(
      (e) =>
        e?.outlet_id?.location?.coordinates?.[0] !== 0 &&
        e?.outlet_id?.location?.coordinates?.[1] >= -90 &&
        e?.outlet_id?.location?.coordinates?.[1] <= 90
    );
    dispatch({
      type: "set_value_data",
      payload: {
        key: "outlet_merch_list",
        value: list,
      },
    });
    dispatch({
      type: "set_value_data",
      payload: {
        key: "outlet_merch_list_back_up",
        value: list,
      },
    });
    dispatch({
      type: "set_value_data",
      payload: {
        key: "outlet_merch_list_filter_0",
        value: outlet_merch_list_filter_0,
      },
    });
    dispatch({
      type: "set_value_data",
      payload: {
        key: "outlet_merch_list_filter_0_back_up",
        value: outlet_merch_list_filter_0,
      },
    });
    const toko_list = list.map((item) => item?.outlet_id || {});
    dispatch(generate_filter_item(toko_list));

    dispatch({
      type: "clear_loading_properties",
    });
  } catch (e) {
    dispatch({
      type: "clear_loading_properties",
    });
  }
};

//POST METHODE

//check_merch_by_id_sfa
/*
body={
  id_sfa_array
}
*/
export const check_merch_by_id_sfa = (body) => async (dispatch) => {
  let outlet_id_test = [];
  let id_sfa_test = [];
  dispatch({
    type: "set_value_properties",
    payload: {
      key: "outlet_id_test",
      value: [],
    },
  });
  dispatch({
    type: "set_value_properties",
    payload: {
      key: "id_sfa_test",
      value: [],
    },
  });
  dispatch({
    type: "set_value_properties",
    payload: {
      key: "id_sfa_test_status",
      value: "pending",
    },
  });

  dispatch({
    type: "set_loading_properties",
    payload: "check_merch_by_id_sfa",
  });

  try {
    const { id_sfa_array } = body;
    const total = id_sfa_array.length;
    const limit = 500;
    const iteration = Math.ceil(total / limit);
    let array_loop = [];

    for (let i = 0; i < iteration; i++) {
      array_loop.push({
        start: i * limit,
        end: i * limit + limit,
      });
    }

    const delay_c = 0;
    const delay = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function = () => {
      return array_loop.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };

    const child_function = async (object) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const { start, end } = object;
            const sfa_array_new = id_sfa_array.slice(start, end);

            const body_new = {
              id_sfa_array: sfa_array_new,
            };
            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };

            const res = await axios.post(
              `${SERVER_URL}/properties/check_merch_by_id_sfa`,
              body_new,
              config
            );

            outlet_id_test = [...outlet_id_test, ...res.data];
            id_sfa_test = outlet_id_test.map((item) => item?.["ID SFA"]);

            dispatch({
              type: "set_value_properties",
              payload: {
                key: "outlet_id_test",
                value: outlet_id_test,
              },
            });

            dispatch({
              type: "set_value_properties",
              payload: {
                key: "id_sfa_test",
                value: id_sfa_test,
              },
            });

            dispatch({
              type: "set_value_properties",
              payload: {
                key: "id_sfa_test_status",
                value: "done",
              },
            });
          } catch (error) {}
        };
        return core_function();
      });
    };

    parent_function().then(() => {
      dispatch({
        type: "clear_loading_properties",
      });
    });
  } catch (e) {
    dispatch({
      type: "clear_loading_properties",
    });
  }
};

//push_merch
/*
body={
  array
}
*/
export const push_merch = (body) => async (dispatch) => {
  let merch_status_upload = [];
  dispatch({
    type: "set_value_properties",
    payload: {
      key: "merch_status_upload",
      value: [],
    },
  });

  dispatch({
    type: "set_loading_properties",
    payload: "push_merch",
  });

  try {
    const { array } = body;
    const total = array.length;
    const limit = 500;
    const iteration = Math.ceil(total / limit);
    let array_loop = [];

    for (let i = 0; i < iteration; i++) {
      array_loop.push({
        start: i * limit,
        end: i * limit + limit,
      });
    }

    const delay_c = 0;
    const delay = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function = () => {
      return array_loop.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };

    const child_function = async (object) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const { start, end } = object;
            const array_new = array.slice(start, end);

            const body_new = {
              array: array_new,
            };
            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };

            const res = await axios.post(
              `${SERVER_URL}/properties/push_merch`,
              body_new,
              config
            );

            merch_status_upload = [...merch_status_upload, ...res.data];

            dispatch({
              type: "set_value_properties",
              payload: {
                key: "merch_status_upload",
                value: merch_status_upload,
              },
            });
          } catch (error) {}
        };
        return core_function();
      });
    };

    parent_function().then(() => {
      if (array.length === 1) {
        dispatch(get_merch_by_outlet_id({ outlet_id: array[0].outlet_id }));
      }
      dispatch({
        type: "clear_loading_properties",
      });
    });
  } catch (e) {
    dispatch({
      type: "clear_loading_properties",
    });
  }
};

//create_competitor_rayon_task
export const create_competitor_rayon_task = (body) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_properties",
      payload: "create_competitor_rayon_task",
    });
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const res = await axios.post(
      `${SERVER_URL}/properties/create_competitor_rayon_task`,
      body,
      config
    );

    let task = res.data;
    const task_id = task._id;

    let child_task_array = task.child_task_array;

    dispatch({
      type: "set_value_properties",
      payload: {
        key: "parent_task_finish_index",
        value: child_task_array.length,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "parent_task_current",
        value: 0,
      },
    });

    const delay_c = 1_000;
    const array = child_task_array;

    const delay = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function = () => {
      return array.reduce(
        (last_promise, object, index) =>
          last_promise.then((result_sum) =>
            child_function(object, index).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object, index) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const child_task_id = object._id;
            const body_aggregate = {
              limit: body.limit,
              task_id,
              child_task_id,
            };
            const config = {
              headers: {
                accesstoken: localStorage.jwtTokenNabati,
              },
            };
            const res = await axios.post(
              `${SERVER_URL}/properties/aggregate_child_task`,
              body_aggregate,
              config
            );
            const count = res.data;
            task.child_task_array[index].jumlah_outlet = count;

            dispatch({
              type: "set_value_properties",
              payload: {
                key: "parent_task_current",
                value: index,
              },
            });
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(() => {
      dispatch({
        type: "set_value_properties",
        payload: { key: "competitor_rayon_task_newest", value: task },
      });
      dispatch(get_count_competitor_national_not_done());
      dispatch({
        type: "clear_loading_properties",
      });
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_properties",
    });
  }
};

/*NON API*/

export const timpa_outlet_filter = (body) => async (dispatch) => {
  /*
  body={
  outlet_list,
  outlet_list_filter_0
  }
  */
  dispatch({
    type: "timpa_outlet_filter",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
  dispatch({
    type: "fly_action",
  });
};

export const set_value_properties = (body) => async (dispatch) => {
  /*
  body={
  key: nama key yang akan diset,
  value: value yang akan dimasukkan
  }
  */
  dispatch({
    type: "set_value_properties",
    payload: body,
  });
};
