import React, { Component } from "react";
import IconComponents from "./img/icon-components/IconComponents";

export default class Notify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: "opacity-100",
      translate: "translate-y-0",
      display: "block",
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        opacity: "opacity-0",
        translate: "-translate-y-4",
        display: "hidden",
      });
    }, 100);
  }
  render() {
    return (
      <React.Fragment>
        <div className={`fixed left-0 right-0 m-4 z-50 ${this.state.display}`}>
          <div
            className={`bg-red-100 border border-red-300 text-red-600 font-semibold p-2 rounded-md flex items-center transition-all duration-300 w-fit mx-auto ${this.state.opacity} ${this.state.translate}`}
          >
            <div>
              <IconComponents icon="exclamation_circle" />
            </div>
            <div className="ml-1">Failed to Edit Salesman!</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
