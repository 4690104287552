import axios from "axios";
import React, { useState } from "react";

export default function BloopTokos() {
  const [firstPercent, setFirstPercent] = useState(0);
  const [secondPercent, setSecondPercent] = useState(0);
  const [firstLink, setFirstLink] = useState("");
  const [secondLink, setSecondLink] = useState("");
  // const timer = (ms) => new Promise((res) => setTimeout(res, ms));
  const loadFirst = async () => {
    // console.log(new Date());
    let skip = 0;
    let limit = 500;
    let done = 1_000_000;
    let firstBigData = [];
    while (skip < done) {
      console.log(skip);
      const url = `https://outdigserver2.nabatisnack.co.id/nabati-outlet/stream?skip=${skip}&limit=${limit}`;
      const response = await axios.get(url);
      if (response?.data?.length === 0) {
        console.log("data kosong");
      }
      const list_data = response.data.map((e) => ({
        _id: e._id ?? "-",
        Plan: e.Plan ?? "-",
        "ID Outlet": e["ID Outlet"] ?? "-",
        "ID SFA": e["ID SFA"] ?? "-",
        // omset: `${e.value}` ?? "-",
        Depo: e.depo.Depo ?? "-",
        CABANG: e.depo.CABANG ?? "-",
        REGIONAL: e.depo.REGIONAL ?? "-",
        "STATUS DEPO": e["STATUS DEPO"] ?? "-",
        SYSTEM: e.SYSTEM ?? "-",
        "Nama Toko": e["Nama Toko"] ?? "-",
        ALAMAT: e.ALAMAT ?? "-",
        ALAMATLAIN: e.ALAMATLAIN ?? "-",
        "NO Tlpon": e["NO Tlpon"] ?? "-",
        "Type Outlet": e["Type Outlet"] ?? "-",
        KELURAHAN: e.KELURAHAN ?? "-",
        KECAMATAN: e.KECAMATAN ?? "-",
        KABUPATEN: e.KABUPATEN ?? "-",
        PROPINSI: e.PROPINSI ?? "-",
        "FLAG DISTRICT": e["FLAG DISTRICT"] ?? "-",
        location: e.location.coordinates ?? "-",
        "KET UPDATE": e["KET UPDATE"] ?? "-",
        KDPOS: e.KDPOS ?? "-",
        "Status Outlet": e["Status Outlet"] ?? "-",
        "ID BRANCH": e["ID BRANCH"] ?? "-",
        COUNTRY: e.COUNTRY ?? "-",
        status_koordinat: e.location_status ?? "-",
        segment: e.SEGMENT ?? "-",
      }));
      firstBigData = [...firstBigData, ...list_data];

      setFirstPercent(parseInt((skip / done) * 100));
      skip += limit;
      // await timer(1000);
    }
    setFirstPercent(100);

    const items = firstBigData;
    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    const csv = [
      header.join(";"), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(";")
      ),
    ].join("\r\n");

    // create BOM UTF-8
    var buffer = new ArrayBuffer(3);
    var dataView = new DataView(buffer);
    dataView.setUint8(0, 0xfe);
    dataView.setUint8(1, 0xbb);
    dataView.setUint8(2, 0xbf);
    var read = new Uint8Array(buffer);

    const csvData = new Blob([read, csv], {
      type: "text/csv;charset=utf-8",
    });
    const csvString = URL.createObjectURL(csvData);
    setFirstLink(csvString);
    const link = document.createElement("a");
    link.href = csvString;
    link.download = "download bulk tokos 1.csv";
    link.click();

    // const jsonData = new Blob([JSON.stringify(firstBigData, null, 2)], {
    //   type: "application/json",
    // });
    // const jsonString = URL.createObjectURL(jsonData);
    // const link = document.createElement("a");
    // link.href = jsonString;
    // link.download = "download_big_json.json";

    // link.click();
  };

  const loadSecond = async () => {
    // console.log(new Date());
    let skip = 1_000_000;
    let limit = 500;
    let done = 2_000_000;
    let secondBigData = [];
    while (skip < done) {
      console.log(skip);
      const url = `https://outdigserver2.nabatisnack.co.id/nabati-outlet/stream?skip=${skip}&limit=${limit}`;
      const response = await axios.get(url);
      if (response.data.length === 0) {
        skip = 2_000_001;
      }
      const list_data = response.data.map((e) => ({
        _id: e._id ?? "-",
        Plan: e.Plan ?? "-",
        "ID Outlet": e["ID Outlet"] ?? "-",
        "ID SFA": e["ID SFA"] ?? "-",
        // omset: `${e.value}` ?? "-",
        Depo: e.depo.Depo ?? "-",
        CABANG: e.depo.CABANG ?? "-",
        REGIONAL: e.depo.REGIONAL ?? "-",
        "STATUS DEPO": e["STATUS DEPO"] ?? "-",
        SYSTEM: e.SYSTEM ?? "-",
        "Nama Toko": e["Nama Toko"] ?? "-",
        ALAMAT: e.ALAMAT ?? "-",
        ALAMATLAIN: e.ALAMATLAIN ?? "-",
        "NO Tlpon": e["NO Tlpon"] ?? "-",
        "Type Outlet": e["Type Outlet"] ?? "-",
        KELURAHAN: e.KELURAHAN ?? "-",
        KECAMATAN: e.KECAMATAN ?? "-",
        KABUPATEN: e.KABUPATEN ?? "-",
        PROPINSI: e.PROPINSI ?? "-",
        "FLAG DISTRICT": e["FLAG DISTRICT"] ?? "-",
        location: e.location.coordinates ?? "-",
        "KET UPDATE": e["KET UPDATE"] ?? "-",
        KDPOS: e.KDPOS ?? "-",
        "Status Outlet": e["Status Outlet"] ?? "-",
        "ID BRANCH": e["ID BRANCH"] ?? "-",
        COUNTRY: e.COUNTRY ?? "-",
        status_koordinat: e.location_status ?? "-",
      }));
      secondBigData = [...secondBigData, ...list_data];

      setSecondPercent(
        parseInt(((skip - 1_000_000) / (done - 1_000_000)) * 100)
      );
      skip += limit;
      // await timer(1000);
    }
    setSecondPercent(100);

    const items = secondBigData;
    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    const csv = [
      header.join(";"), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(";")
      ),
    ].join("\r\n");

    // create BOM UTF-8
    var buffer = new ArrayBuffer(3);
    var dataView = new DataView(buffer);
    dataView.setUint8(0, 0xfe);
    dataView.setUint8(1, 0xbb);
    dataView.setUint8(2, 0xbf);
    var read = new Uint8Array(buffer);

    const csvData = new Blob([read, csv], {
      type: "text/csv;charset=utf-8",
    });
    const csvString = URL.createObjectURL(csvData);
    setSecondLink(csvString);
    const link = document.createElement("a");
    link.href = csvString;
    link.download = "download bulk tokos 2.csv";

    link.click();

    // const jsonData = new Blob([JSON.stringify(secondBigData, null, 2)], {
    //   type: "application/json",
    // });
    // const jsonString = URL.createObjectURL(jsonData);
    // const link = document.createElement("a");
    // link.href = jsonString;
    // link.download = "download_big_json.json";

    // link.click();
  };

  return (
    <React.Fragment>
      <div className="flex items-center justify-center flex-col gap-4 mb-5">
        <button
          onClick={loadFirst}
          className="p-4 bg-blue-500 rounded text-white"
        >
          Download CSV Page 1
        </button>
        <p>{firstPercent} %</p>
        <a href={firstLink}>{firstLink}</a>
      </div>
      {firstLink ? (
        <div className="flex items-center justify-center flex-col gap-4 mt-5">
          <button
            onClick={loadSecond}
            className="p-4 bg-blue-500 rounded text-white"
          >
            Download CSV Page 2
          </button>
          <p>{secondPercent} %</p>
          <a href={secondLink}>{secondLink}</a>
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}
