/*LIBRARY MODULE*/
import React, { Component } from "react";
import { Router, Route } from "react-router-dom";
import { Provider } from "react-redux";

/*PERSONAL COMPONENT*/
import PrivateRoute from "./components/common/PrivateRoute";
import LoginPage from "./components/auth/LoginPage";
import Register from "./components/auth/Register";
import ResetPassword from "./components/auth/ResetPassword";
import Main from "./components/main/MAIN_PARENT";
import Notify from "./Notify";
import Loading from "./Loading";
import RAYON_DEPO_STEP_1 from "./components/rayonisasi_competitor/RAYON_DEPO_STEP_1";

/*REDUX FUNCTION*/
import { setCurrentUser, get_user_from_token } from "./actions/authActions";

/*PICTURE ASSET*/

/*GENERAL*/
import history from "../src/actions/history";
import store from "./store";
import "./App.css";

/*NON IMPORT*/
const jwtTokenNabati = localStorage.jwtTokenNabati;
if (jwtTokenNabati) {
  store.dispatch(
    setCurrentUser(JSON.parse(localStorage.getItem("nabatiUser")))
  );
  store.dispatch(get_user_from_token(jwtTokenNabati));
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <div className="App" style={{ fontFamily: `"Inter", sans-serif` }}>
          <Notify />
          <Loading />
          <Router history={history}>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/reset_password" component={ResetPassword} />
            <PrivateRoute exact path="/" component={Main} />
            <PrivateRoute
              exact
              path="/dashboard_rayonisasi_competitor"
              component={RAYON_DEPO_STEP_1}
            />
          </Router>
        </div>
      </Provider>
    );
  }
}

export default App;
