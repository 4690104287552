/*PROTOTYPE*/

export const edit_preview_rayon_list_mode = (body) => {
  //edit_preview_rayon_list_mode
  /*
body: {
  index: Number,
  mode: String, manual || auto
}
*/
  return {
    type: "edit_preview_rayon_list_mode",
    payload: body,
  };
};

export const edit_preview_rayon_list_v_manual = (body) => {
  //edit_preview_rayon_list_v_manual
  /*
body: {
  index: Number,
  v_manual : Number
}
*/
  return {
    type: "edit_preview_rayon_list_v_manual",
    payload: body,
  };
};

export const status_action = () => {
  return {
    type: "status_action",
  };
};

export const set_depo_selected = (depo) => {
  return {
    type: "set_depo_selected",
    payload: depo,
  };
};

export const set_polygon_depo = (polygon_depo) => {
  return {
    type: "set_polygon_depo",
    payload: polygon_depo,
  };
};

export const set_unplot_polygon = (data) => {
  return {
    type: "set_unplot_polygon",
    payload: data,
  };
};

export const rayon_polygon = (data) => {
  return {
    type: "rayon_polygon",
    payload: data,
  };
};

export const activate_edit_point_depo = (depo) => {
  return {
    type: "activate_edit_point_depo",
    payload: depo,
  };
};

export const inactivate_edit_point_depo = () => {
  return {
    type: "inactivate_edit_point_depo",
  };
};

export const activate_draw_point_depo = () => {
  return {
    type: "activate_draw_point_depo",
  };
};

export const inactivate_draw_point_depo = () => {
  return {
    type: "inactivate_draw_point_depo",
  };
};

export const setMap = (map) => {
  return {
    type: "SET_MAP",
    payload: map,
  };
};

export const setOutletView = (map) => {
  return {
    type: "SET_OUTLET_VIEW",
    payload: map,
  };
};

export const setTrackingView = (map) => {
  return {
    type: "SET_TRACKING_VIEW",
    payload: map,
  };
};

export const setDrawMode = (map) => {
  return {
    type: "SET_DRAW_MODE",
    payload: map,
  };
};

export const statusDraw = (map) => {
  return {
    type: "STATUS_DRAW",
    payload: map,
  };
};

export const setDrawModeCompetitor = (map) => {
  return {
    type: "SET_DRAW_MODE_COMPETITOR",
    payload: map,
  };
};

export const addPolygon = (map) => {
  return {
    type: "ADD_POLYGON",
    payload: map,
  };
};

export const addPointResult = (map) => {
  return {
    type: "ADD_POINT_RESULT",
    payload: map,
  };
};

export const changePolygon = (map) => {
  return {
    type: "CHANGE_POLYGON",
    payload: map,
  };
};

export const selectedDrawFeature = (map) => {
  return {
    type: "SELECTED_DRAW_FEATURE",
    payload: map,
  };
};

export const replacePoint = (map) => {
  return {
    type: "REPLACE_POINT",
    payload: map,
  };
};

export const onDelete = (map) => {
  return {
    type: "DELETE_DATA_POINT",
    payload: map,
  };
};

export const changeHari = (old, newHari) => {
  return {
    type: "CHANGE_HARI",
    payload: { old, newHari },
  };
};

export const set_value = (body) => {
  /*
  body: {
  key: "string key yang akan diganti"
  value: value yang akan dimasukkan
  }
  */

  return {
    type: "set_value",
    payload: body,
  };
};
