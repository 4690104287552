//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
//Redux function
//Picture Asset
//General Function
import format_bulan_tahun from "../../validation/format_bulan_tahun";
import format_tanggal_bulan_tahun from "../../validation/format_tanggal_bulan_tahun";
import format_jam_menit from "../../validation/format_jam_menit";
class TrackingBulanan extends Component {
  state = {
    start_time_hour: "08",
    start_time_minutes: "00",
    end_time_hour: "16",
    end_time_minutes: "00",
    time_array: [],
  };
  componentDidMount() {
    this.generate_time_array();
  }
  generate_time_array = () => {
    const { lineString } = this.props.tracking;
    let time_array = lineString.map((e) => e.time_stamp).flat();
    //time stamp generate date_iso & month iso untuk pembagian perbulan dan perhari
    time_array = time_array.map((t) => {
      const t_date = new Date(t);
      //Format date iso 2022-06-16
      // let month_iso = t_date.toISOString().split("T")[0];
      // month_iso = month_iso.slice(0, -2) + "01";
      // console.log(t_date, month_iso);
      // return {
      //   unix: t,
      //   date_iso: t_date.toISOString().split("T")[0],
      //   month_iso,
      // };
      const month =
        t_date.getMonth() < 10 ? `0${t_date.getMonth()}` : t_date.getMonth();
      const date = "01";
      let month_iso = `${t_date.getFullYear()}-${month}-${date}`;
      return {
        unix: t,
        date_iso: `${t_date.getFullYear()}-${
          t_date.getMonth() + 1
        }-${t_date.getDate()}`,
        month_iso,
      };
    });
    //generate bulan unik, supaya bisa dipisahkan berdasarkan bulan
    let month_array_unique = time_array.map((t) => t.month_iso);
    month_array_unique = [...new Set(month_array_unique)];
    //looping dibulan unik
    time_array = month_array_unique.map((m) => {
      //generate date unik supaya bisa dipisahkan berdasarkan tanggal
      let date_array_unique = time_array.filter((t) => t.month_iso === m);
      date_array_unique = date_array_unique.map((t) => t.date_iso);
      date_array_unique = [...new Set(date_array_unique)];
      // date_array_unique = time_array.map((t) => t.date_iso);

      //jam mulai
      let hour_start = parseInt(this.state.start_time_hour);
      const min_start = parseInt(this.state.start_time_minutes);
      hour_start = hour_start + min_start / 60;

      //jam akhir
      let hour_end = parseInt(this.state.end_time_hour);
      const min_end = parseInt(this.state.end_time_minutes);
      hour_end = hour_end + min_end / 60;

      //akumulasi jam untuk setiap harinya
      date_array_unique = date_array_unique.map((d) => {
        let hour_array = time_array.filter((t) => t.date_iso === d);
        hour_array = hour_array.map((t) => {
          let jam_desimal = parseInt(new Date(t.unix).getHours());
          const min_desimal = parseInt(new Date(t.unix).getMinutes());
          jam_desimal = jam_desimal + min_desimal / 60;
          return {
            ...t,
            jam_desimal,
          };
        });
        hour_array = hour_array.filter(
          (t) => t.jam_desimal >= hour_start && t.jam_desimal <= hour_end
        );
        //get first hour
        const first_hour = hour_array?.[0]?.unix ? hour_array?.[0]?.unix : 0;
        //get last hour
        const last_hour = hour_array?.[hour_array.length - 1]?.unix
          ? hour_array?.[hour_array.length - 1]?.unix
          : 0;
        //get delta unix
        const delta_unix = parseInt(last_hour) - parseInt(first_hour);
        let seconds = parseFloat(delta_unix / 1000).toFixed(3);
        let minutes = parseFloat(seconds / 60).toFixed(3);
        let delta_hours = Number(parseFloat(minutes / 60).toFixed(3));
        //get the amount of data in one day
        const total_data = hour_array.length;
        //filter hari minggu
        let day_index = new Date(d);
        day_index = day_index.getDay();
        return {
          day_index,
          date_iso: d,
          hour_array,
          first_hour,
          last_hour,
          delta_unix,
          delta_hours,
          total_data,
        };
      });
      //0 = minggu
      date_array_unique = date_array_unique.filter((d) => d.day_index !== 0);
      let total_hours = date_array_unique.map((d) => d.delta_hours);
      total_hours = total_hours.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
      return {
        month_iso: m,
        date_array_unique,
        total_hours,
      };
    });
    this.setState({ time_array });
  };
  handleChangeTime = (event) => {
    const name = event.target.name;
    const value = event.target.value.split(":");
    this.setState(
      {
        [`${name}_hour`]: value[0],
        [`${name}_minutes`]: value[1],
      },
      () => {
        this.generate_time_array();
      }
    );
  };
  rumusHitungPrediksiJam = (totalJam, totalData) => {
    if (totalJam === "-" || totalJam === "0.0") {
      return 0;
    }
    const totalMenit = parseFloat(totalJam) * 60;
    const interval = totalMenit / totalData;
    let part = 1 / interval;
    if (part > 1) {
      part = 1;
    }
    const result = parseFloat((part * totalMenit) / 60).toFixed(1);
    return result;
  };
  render() {
    const {
      start_time_hour,
      start_time_minutes,
      end_time_hour,
      end_time_minutes,
      time_array,
    } = this.state;
    const content = time_array.map((t, idx) => {
      let month = format_bulan_tahun(new Date(t.month_iso));
      return (
        <main className="main_container mb-5" key={idx}>
          <p className="text_bold">{month}</p>
          <p className="text_medium">
            Total tracking hours: {parseFloat(t.total_hours).toFixed(1)} Hour
          </p>
          <p className="text_medium">
            Total working days: {t.date_array_unique.length} Day
          </p>
          <p>Details:</p>
          <table className="table_lite" style={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Start hour</th>
                <th>End hour</th>
                <th>Tracking time (hour) Before</th>
                <th>Total data</th>
                <th>Tracking time (hour) After</th>
              </tr>
            </thead>
            <tbody>
              {t.date_array_unique.map((d, idx) => {
                const date_formated = format_tanggal_bulan_tahun(
                  new Date(d.date_iso)
                );
                const jam_mulai = format_jam_menit(d.first_hour);
                const jam_akhir = format_jam_menit(d.last_hour);
                const days = [
                  "Minggu",
                  "Senin",
                  "Selasa",
                  "Rabu",
                  "Kamis",
                  "Jumat",
                  "Sabtu",
                ];
                let day_name = new Date(d.date_iso);
                day_name = days[day_name.getDay()];
                let day_content = (
                  <main>
                    <div className="button_small" id="green">
                      {day_name}
                    </div>
                  </main>
                );
                if (day_name === "Minggu") {
                  day_content = (
                    <main>
                      <div className="button_small" id="red">
                        {day_name}
                      </div>
                      <div className="text_inferior">(Not Taken)</div>
                    </main>
                  );
                }
                if (d.total_data === 0) {
                  return (
                    <tr key={idx}>
                      <td>
                        {date_formated}
                        <br />
                        {day_content}
                      </td>
                      <td>{"No hours"}</td>
                      <td>{"No hours"}</td>
                      <td>{parseFloat(d.delta_hours).toFixed(1)}</td>
                      <td>{d.total_data}</td>
                      <td>{parseFloat(d.delta_hours).toFixed(1)}</td>
                    </tr>
                  );
                }
                return (
                  <tr key={idx}>
                    <td>
                      {date_formated}
                      <br />
                      {day_content}
                    </td>
                    <td>{jam_mulai}</td>
                    <td>{jam_akhir}</td>
                    <td>{parseFloat(d.delta_hours).toFixed(1)}</td>
                    <td>{d.total_data}</td>
                    <td>
                      {this.rumusHitungPrediksiJam(
                        parseFloat(d.delta_hours).toFixed(1),
                        d.total_data
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      );
    });
    const filter = (
      <div className="grid grid-cols-6 gap-2 mt-1 items-end mb-4">
        <div className="flex flex-col">
          <label htmlFor="start_time">Choose Start Hour</label>
          <input
            type="time"
            className="w-full"
            id="start_time"
            name="start_time"
            required
            defaultValue={`${start_time_hour}:${start_time_minutes}`}
            onChange={this.handleChangeTime}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="end_time">Choose End Hour</label>
          <input
            type="time"
            className="w-full"
            id="end_time"
            name="end_time"
            required
            defaultValue={`${end_time_hour}:${end_time_minutes}`}
            onChange={this.handleChangeTime}
          />
        </div>
      </div>
    );
    return (
      <main>
        {filter}
        {content}
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  // time_array: state.tracking.time_array,
  tracking: state.tracking,
});
export default connect(mapStateToProps, {})(TrackingBulanan);
