/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_outlet } from "../../actions/outletGetActions";
import {
  set_config_mode_menu,
  popUpAction,
  turn_on_menu,
  turn_off_menu,
} from "../../actions/dataActions";
import {
  hide_filter_array,
  show_filter_array,
  hide_all_filter_array,
  show_all_filter_array,
} from "../../actions/filter_outlet_actions";

/*PICTURE ASSET*/

/*GENERAL*/

class FILTER_OUTLET_BY_SALESMAN extends Component {
  state = {
    name_search_params: "",
  };

  on_timpa_outlet_filter = () => {
    const { name_search_params } = this.state;
    const {
      outlet_by_salesman_list_backup,
      outlet_by_salesman_list_filter_0_back_up,
    } = this.props.data;

    if (name_search_params) {
      const outlet_by_salesman_list = outlet_by_salesman_list_backup.filter(
        (outlet) =>
          outlet["Nama Toko"]
            .toLowerCase()
            .includes(name_search_params.toLowerCase())
      );
      const outlet_by_salesman_list_filter_0 =
        outlet_by_salesman_list_filter_0_back_up.filter((outlet) =>
          outlet["Nama Toko"]
            .toLowerCase()
            .includes(name_search_params.toLowerCase())
        );

      this.props.set_value_outlet({
        key: "outlet_by_salesman_list",
        value: outlet_by_salesman_list,
      });
      this.props.set_value_outlet({
        key: "outlet_by_salesman_list_filter_0",
        value: outlet_by_salesman_list_filter_0,
      });
    } else {
      this.props.set_value_outlet({
        key: "outlet_by_salesman_list",
        value: outlet_by_salesman_list_backup,
      });
      this.props.set_value_outlet({
        key: "outlet_by_salesman_list_filter_0",
        value: outlet_by_salesman_list_filter_0_back_up,
      });
    }
  };

  toggle_filter_array = (array_name, element_name, is_hide) => {
    const body = { array_name, element_name };
    if (is_hide) {
      this.props.show_filter_array(body);
    } else {
      this.props.hide_filter_array(body);
    }
  };

  on_hide_all_filter_array = () => {
    this.props.hide_all_filter_array();
  };

  on_show_all_filter_array = (array_name) => {
    this.props.show_all_filter_array({ array_name });
  };

  on_set_config_mode_menu = (mode) => {
    this.props.set_config_mode_menu("outlet_by_salesman", mode);
  };

  on_change = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value });
    if (name === "filter_mode") {
      localStorage.setItem("filter_mode", value);
    }
  };

  render() {
    //local storage

    //local state

    //global props
    const { list_menu_active } = this.props.data;
    const { rayon_filter_array, day_filter_array, cycle_filter_array } =
      this.props.outlet;

    //content
    const menu_status = list_menu_active.find(
      (e) => e.key === "outlet_by_salesman"
    );
    const mode = menu_status?.config?.mode;
    const main_array = [
      {
        name: "DAY",
        key: "day_filter_array",
        all_array: day_filter_array,
      },
      {
        name: "CYCLE",
        key: "cycle_filter_array",
        all_array: cycle_filter_array,
      },

      {
        name: "RAYON",
        key: "rayon_filter_array",
        all_array: rayon_filter_array,
      },
    ];

    const filter_content = (
      <>
        {main_array.map((item, idx) => {
          return (
            <main key={idx} className="mb-10">
              <h1 className="text_bold">{item.name}</h1>
              <button
                className="button_small mr-1 mb-1"
                id="green"
                onClick={this.on_show_all_filter_array.bind(this, item.key)}
              >
                Show all
              </button>
              <button
                className="button_small mr-1 mb-1"
                id="green"
                onClick={this.on_hide_all_filter_array}
              >
                Hide all
              </button>
              <button
                className="button_small"
                id={mode === item.key ? "green" : "grey"}
                onClick={this.on_set_config_mode_menu.bind(this, item.key)}
              >
                Apply color
              </button>
              <section
                style={{ maxHeight: "300px", overflowY: "auto" }}
                className="container_light"
              >
                <table className="table full_width">
                  <tbody>
                    {item.all_array.map((element, idx) => {
                      return (
                        <tr key={idx}>
                          <td style={{ width: "30px" }}>
                            <button
                              className="button_pill"
                              id={element?.is_hide ? "grey" : "green"}
                              onClick={this.toggle_filter_array.bind(
                                this,
                                item.key,
                                element.name,
                                element.is_hide
                              )}
                            >
                              v
                            </button>
                          </td>
                          <td style={{ width: "30px" }}>
                            <button
                              className="button_pill"
                              style={{ backgroundColor: element.color }}
                              onClick={this.toggle_filter_array.bind(
                                this,
                                item.key,
                                element.name,
                                element.is_hide
                              )}
                            >
                              {" "}
                            </button>
                          </td>
                          <td>{element.name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </section>
            </main>
          );
        })}
      </>
    );

    return <main style={{ marginBottom: "100px" }}>{filter_content}</main>;
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  properties: state.properties,
  map: state.map,
  outlet: state.outlet,
});

export default connect(mapStateToProps, {
  set_config_mode_menu,
  hide_filter_array,
  show_filter_array,
  hide_all_filter_array,
  show_all_filter_array,
  popUpAction,
  turn_on_menu,
  turn_off_menu,
  set_value_outlet,
})(FILTER_OUTLET_BY_SALESMAN);
