import React, { Component } from "react";
import { connect } from "react-redux";
import legenda from "../../reducers/legenda.json";

class Legenda extends Component {
  render() {
    const { performance_object } = this.props.data;
    const { menu } = performance_object;
    let legenda_list = legenda?.[menu];
    return (
      <main>
        <h3 className="text-xl font-medium">Legend</h3>
        <table>
          <tbody>
            {legenda_list.map((l, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: l.color,
                        borderRadius: "3px",
                        marginBottom: "2px",
                        marginRight: "5px",
                      }}
                    />
                  </td>
                  <td>{l.text}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <section>
          <button className="button_main use_margin disabled:opacity-50 disabled:cursor-progress">
            Download CSV
          </button>
          <button className="button_main use_margin disabled:opacity-50 disabled:cursor-progress">
            Download Excel
          </button>
          <button className="button_main use_margin disabled:opacity-50 disabled:cursor-progress">
            Download PDF
          </button>
        </section>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({ data: state.data });
export default connect(mapStateToProps, {})(Legenda);
