import axios from "axios";
import React, { useState } from "react";

export default function BloopProduct({ throwParams }) {
  const [firstPercent, setFirstPercent] = useState(0);
  const [firstLink, setFirstLink] = useState("");
  // const timer = (ms) => new Promise((res) => setTimeout(res, ms));

  const getParams = throwParams;

  const loadFirst = async () => {
    // console.log(new Date());
    let firstBigData = [];
    for (let i = 0; i < getParams.plan_id.length; i++) {
      const depoID = getParams.plan_id[i];
      const depo = getParams.AREA[i];
      let skip = 0;
      let limit = 1000;
      let done = 100_000;

      let MONTH = `${parseInt(getParams.MONTH_NUMBER) + 1}`;
      if (MONTH.length < 2) {
        MONTH = 0 + MONTH;
      }
      while (skip < done) {
        const url = `https://outdigserver2.nabatisnack.co.id/integrations/combine?AREA=${depoID}&MONTH_NUMBER=${MONTH}&YEAR_NUMBER=${getParams.YEAR_NUMBER}&LIMIT=${limit}&SKIP=${skip}`;
        let response = [];
        response = await axios.get(url);

        response = response.data.map((d) => {
          return {
            ...d,
            depo: depo,
            month: MONTH,
            year: getParams.YEAR_NUMBER,
          };
        });

        const list_data = response.map((e) => ({
          depo: e.depo,
          "Type Outlet": e.channel,
          month: e.month,
          year: e.year,
          customer_id: e.customer_id,
          customer_name: e.customer_name,
          principle: e.principle,
          business_unit: e.business_unit,
          brand: e.brand,
          product_parent_id: e.product_parent_id,
          product_parent_name: e.product_parent_name,
          qty: parseFloat(e.qty).toString().replace(/\./g, ","),
          value: parseFloat(e.value).toString().replace(/\./g, ","),
        }));
        firstBigData = [...firstBigData, ...list_data];

        setFirstPercent(parseInt((skip / done) * 100));
        skip += limit;
        console.log(skip);
        // await timer(1000);
        if (response.length === 0) {
          console.log("data kosong");
          break;
        }
      }
    }

    setFirstPercent(100);

    const items = firstBigData;
    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    const csv = [
      header.join(";"), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(";")
      ),
    ].join("\r\n");

    // create BOM UTF-8
    var buffer = new ArrayBuffer(3);
    var dataView = new DataView(buffer);
    dataView.setUint8(0, 0xfe);
    dataView.setUint8(1, 0xbb);
    dataView.setUint8(2, 0xbf);
    var read = new Uint8Array(buffer);

    const csvData = new Blob([read, csv], {
      type: "text/csv;charset=utf-8",
    });
    const csvString = URL.createObjectURL(csvData);
    setFirstLink(csvString);
    const link = document.createElement("a");
    link.href = csvString;
    link.download = "download bulk product.csv";
    link.click();

    // const jsonData = new Blob([JSON.stringify(firstBigData, null, 2)], {
    //   type: "application/json",
    // });
    // const jsonString = URL.createObjectURL(jsonData);
    // const link = document.createElement("a");
    // link.href = jsonString;
    // link.download = "download_big_json.json";

    // link.click();
  };

  return (
    <React.Fragment>
      <div className="flex items-center justify-center flex-col gap-4 mb-5 bg-red-100 rounded">
        <button
          onClick={loadFirst}
          className="p-2 rounded text-white mt-8"
          id="green"
        >
          Download Bulk Product
        </button>
        <p>{firstPercent} %</p>
        <a href={firstLink}>{firstLink}</a>
      </div>
    </React.Fragment>
  );
}
