/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal";
import EditSalesman from "../parent/EditSalesman";
import EditAdmin from "../parent/EditAdmin";
import ListInActive from "../parent/ListInActive";
import HirarkiDepo from "../parent/HirarkiDepo";
import UploadAddSalesman from "../upload/UploadAddSalesman";
import UploadAddToko from "../upload/UploadAddToko";
import ListInActiveDriver from "../parent/ListInActiveDriver";
import UploadEditSalesman from "../upload/UploadEditSalesman";
import UploadMechandising from "../upload/UploadMechandising";

/*REDUX FUNCTION*/
import { set_value_properties } from "../../actions/propertiesActions";
import UploadDriver from "../upload/UploadDriver";

/*PICTURE ASSET*/

/*GENERAL*/

/*NON IMPORT*/

class ADMIN_MENU extends Component {
  state = {
    modal_edit_admin: false,
    modal_add_salesman: false,
    modal_add_toko: false,
    modal_edit_outlet: false,
    modal_edit_salesman: false,
    modal_merchandising: false,
    modal_upload_driver: false,
    render_list: true,
    list_menu: [
      {
        key: "edit_admin",
        title: "Admin Verification",
        sub_title: "Admin verification who registered at nabati outdig",
        components: <EditAdmin />,
      },
      {
        key: "edit_salesman",
        title: "Salesman Detail",
        sub_title: "Edit salesman details & forgot password",
        components: <EditSalesman />,
      },
      {
        key: "salesman_inactive",
        title: "Salesman Inactive",
        sub_title: "Inactive salesman list",
        components: <ListInActive />,
      },
      {
        key: "driver_inactive",
        title: "Driver Inactive",
        sub_title: "Inactive driver list",
        components: <ListInActiveDriver />,
      },
      {
        key: "depo",
        title: "Region Branch Depo",
        sub_title: "Add/change region branch depo",
        components: <HirarkiDepo />,
      },
    ],
    list_menu_outlet: [
      {
        key: "add_multiple_toko",
        title: "Upload Outlet",
        sub_title: "Add/Edit Multiple Outlet By Upload CSV/EXCEL File",
        components: "",
      },
      // {
      //   key: "edit_multiple_outlet",
      //   title: "Edit Outlet",
      //   sub_title: "Edit Multiple Outlet By Upload CSV/EXCEL File",
      //   components: "",
      // },
      {
        key: "upload_mechandising",
        title: "Upload Merchandising",
        sub_title:
          "Upload status of outlets participating in the merchandising program",
        components: "",
      },
    ],
    list_menu_salesman: [
      {
        key: "upload_add_salesman",
        title: "Add Salesman",
        sub_title: "Add Multiple Salesman By Upload CSV/EXCEL File",
        components: "",
      },
      {
        key: "edit_multiple_salesman",
        title: "Edit Salesman",
        sub_title: "Edit Multiple Salesman By Upload CSV/EXCEL File",
        components: "",
      },
    ],
    list_menu_driver: [
      {
        key: "upload_bulk_driver",
        title: "Upload Driver",
        sub_title: "Add & Edit Driver By CSV",
        component: "",
      },
    ],
    current_component: {},
  };

  componentDidMount() {
    //sembunyikan menu-menu rahasia yang hanya super_admin bisa akses

    if (this.props.auth.user.badge !== "super_admin") {
      const list_menu = this.state.list_menu.filter(
        (e) =>
          ![
            "depo",
            "upload_add_salesman",
            "add_multiple_toko",
            "edit_salesman",
            "upload_add_salesman",
            "edit_multiple_salesman",
            "edit_multiple_outlet",
            "salesman_inactive",
          ].includes(e.key)
      );

      const list_menu_outlet = this.state.list_menu_outlet.filter(
        (e) =>
          ![
            "depo",
            "upload_add_salesman",
            "add_multiple_toko",
            "edit_salesman",
            "upload_add_salesman",
            "edit_multiple_salesman",
            "edit_multiple_outlet",
            "salesman_inactive",
          ].includes(e.key)
      );

      const list_menu_salesman = this.state.list_menu_salesman.filter(
        (e) =>
          ![
            "depo",
            "upload_add_salesman",
            "add_multiple_toko",
            "edit_salesman",
            "upload_add_salesman",
            "edit_multiple_salesman",
            "edit_multiple_outlet",
            "salesman_inactive",
          ].includes(e.key)
      );

      this.setState({
        list_menu,
        list_menu_outlet,
        list_menu_salesman,
      });
    }
  }

  toggle_merchandising = () => {
    this.setState({ modal_merchandising: !this.state.modal_merchandising });

    //reset value upload merchandising

    this.props.set_value_properties({ key: "outlet_id_test", value: [] });
    this.props.set_value_properties({ key: "id_sfa_test", value: [] });
    this.props.set_value_properties({
      key: "id_sfa_test_status",
      value: "pending",
    });
    this.props.set_value_properties({ key: "merch_status_upload", value: [] });
  };

  toggle_edit_outlet = () => {
    this.setState({ modal_edit_outlet: !this.state.modal_edit_outlet });
  };

  toggle_upload_add_toko = () => {
    this.setState({
      modal_add_toko: !this.state.modal_add_toko,
    });
  };

  toggle_upload_add_salesman = () => {
    this.setState({
      modal_add_salesman: !this.state.modal_add_salesman,
    });
  };

  toggle_edit_admin = () => {
    this.setState({ modal_edit_admin: !this.state.modal_edit_admin });
  };

  handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({
      [name]: value,
    });
  };

  fun_render_list = (e) => {
    this.setState({
      render_list: false,
      current_component: e,
    });
  };

  fun_clear_render_list = () => {
    this.setState({
      render_list: true,
    });
  };

  toggle_edit_salesman = () => {
    this.setState({
      modal_edit_salesman: !this.state.modal_edit_salesman,
    });
  };

  render() {
    const {
      render_list,
      list_menu,
      list_menu_outlet,
      list_menu_salesman,
      current_component,
      modal_edit_admin,
      modal_add_salesman,
      modal_add_toko,
      modal_edit_salesman,
      modal_merchandising,
      list_menu_driver,
      modal_upload_driver,
    } = this.state;

    const list_menu_render = (
      <div className="grid grid-cols-2 gap-2 px-2">
        {list_menu.map((e, i) => {
          let button_function = this.fun_render_list.bind(this, e);
          if (e.key === "edit_admin") button_function = this.toggle_edit_admin;
          if (e.key === "upload_add_salesman")
            button_function = this.toggle_upload_add_salesman;
          if (e.key === "add_multiple_toko")
            button_function = this.toggle_upload_add_toko;
          if (e.key === "edit_multiple_outlet")
            button_function = this.toggle_edit_outlet;
          if (e.key === "edit_multiple_salesman")
            button_function = this.toggle_edit_salesman;
          return (
            <button
              key={i}
              onClick={button_function}
              className="border border-gray-300 py-4 px-2 w-full rounded shadow hover:bg-gray-100"
            >
              <div className="font-semibold">{e.title}</div>
              <small>{e.sub_title}</small>
            </button>
          );
        })}
      </div>
    );

    const list_menu_outlet_render = (
      <div className="grid grid-cols-2 gap-2 px-2">
        {list_menu_outlet.map((e, i) => {
          let button_function = this.fun_render_list.bind(this, e);
          if (e.key === "edit_admin") button_function = this.toggle_edit_admin;
          if (e.key === "upload_add_salesman")
            button_function = this.toggle_upload_add_salesman;
          if (e.key === "add_multiple_toko")
            button_function = this.toggle_upload_add_toko;
          if (e.key === "edit_multiple_outlet")
            button_function = this.toggle_edit_outlet;
          if (e.key === "edit_multiple_salesman")
            button_function = this.toggle_edit_salesman;
          if (e.key === "upload_mechandising")
            button_function = this.toggle_merchandising;
          return (
            <button
              key={i}
              onClick={button_function}
              className="border border-gray-300 py-4 px-2 w-full rounded shadow hover:bg-gray-100"
            >
              <div className="font-semibold">{e.title}</div>
              <small>{e.sub_title}</small>
            </button>
          );
        })}
      </div>
    );

    const list_menu_salesman_render = (
      <div className="grid grid-cols-2 gap-2 px-2">
        {list_menu_salesman.map((e, i) => {
          let button_function = this.fun_render_list.bind(this, e);
          if (e.key === "edit_admin") button_function = this.toggle_edit_admin;
          if (e.key === "upload_add_salesman")
            button_function = this.toggle_upload_add_salesman;
          if (e.key === "add_multiple_toko")
            button_function = this.toggle_upload_add_toko;
          if (e.key === "edit_multiple_outlet")
            button_function = this.toggle_edit_outlet;
          if (e.key === "edit_multiple_salesman")
            button_function = this.toggle_edit_salesman;
          return (
            <button
              key={i}
              onClick={button_function}
              className="border border-gray-300 py-4 px-2 w-full rounded shadow hover:bg-gray-100"
            >
              <div className="font-semibold">{e.title}</div>
              <small>{e.sub_title}</small>
            </button>
          );
        })}
      </div>
    );

    const list_menu_driver_render = (
      <div className="grid grid-cols-2 gap-2 px-2">
        {list_menu_driver.map((e, i) => {
          return (
            <button
              key={i}
              className="border border-gray-300 py-4 px-2 w-full rounded shadow hover:bg-gray-100"
              onClick={() => this.setState({ modal_upload_driver: true })}
            >
              <div className="font-semibold">{e.title}</div>
              <small>{e.sub_title}</small>
            </button>
          );
        })}
      </div>
    );

    const modal_upload_driver_content = modal_upload_driver && (
      <Modal
        modalSize="large"
        isOpen={modal_upload_driver}
        onClose={() => this.setState({ modal_upload_driver: false })}
      >
        <div className="box-body" id="box-body">
          <main style={{ minHeight: "100vh" }}>
            <UploadDriver />
          </main>
        </div>
      </Modal>
    );

    const modal_merchandising_content = modal_merchandising && (
      <Modal
        modalSize="large"
        isOpen={modal_merchandising}
        onClose={this.toggle_merchandising}
      >
        <div className="box-body" id="box-body">
          <main style={{ minHeight: "100vh" }}>
            <UploadMechandising />
          </main>
        </div>
      </Modal>
    );

    const modal_edit_admin_content = modal_edit_admin && (
      <Modal
        modalSize="large"
        isOpen={modal_edit_admin}
        onClose={this.toggle_edit_admin}
      >
        <div className="box-body" id="box-body">
          <main style={{ minHeight: "100vh" }}>
            <EditAdmin />
          </main>
        </div>
      </Modal>
    );

    const modal_upload_add_salesman_content = modal_add_salesman && (
      <Modal
        modalSize="large"
        isOpen={modal_add_salesman}
        onClose={this.toggle_upload_add_salesman}
      >
        <div className="box-body" id="box-body">
          <main style={{ minHeight: "100vh" }}>
            <UploadAddSalesman />
          </main>
        </div>
      </Modal>
    );

    const modal_upload_add_toko_content = modal_add_toko && (
      <Modal
        modalSize="large"
        isOpen={modal_add_toko}
        onClose={this.toggle_upload_add_toko}
      >
        <div className="box-body" id="box-body">
          <main style={{ minHeight: "100vh" }}>
            <UploadAddToko />
          </main>
        </div>
      </Modal>
    );

    const modal_edit_salesman_content = modal_edit_salesman && (
      <Modal
        modalSize="large"
        isOpen={modal_edit_salesman}
        onClose={this.toggle_edit_salesman}
      >
        <div className="box-body" id="box-body">
          <main style={{ minHeight: "100vh" }}>
            <UploadEditSalesman />
          </main>
        </div>
      </Modal>
    );

    return (
      <main className="mt-4 pb-8">
        {modal_merchandising_content}
        {modal_upload_add_toko_content}
        {modal_upload_add_salesman_content}
        {modal_edit_admin_content}
        {modal_edit_salesman_content}
        {modal_upload_driver_content}
        {render_list ? (
          <p>Admin Dashboard</p>
        ) : (
          <p>
            <span
              onClick={this.fun_clear_render_list}
              className="cursor-pointer font-semibold"
            >
              Admin Dashboard
            </span>{" "}
            /<span className="text-gray-700"> {current_component.title}</span>
          </p>
        )}
        <hr className="my-2" />
        {render_list && (
          <section>
            <h1>General</h1>
            {list_menu_render}
          </section>
        )}
        <br />
        {render_list && (
          <section>
            <h1>Outlet</h1>
            {list_menu_outlet_render}
          </section>
        )}
        <br />
        {render_list && (
          <section>
            <h1>Salesman</h1>
            {list_menu_salesman_render}
          </section>
        )}
        <br />
        {render_list && (
          <section>
            <h1>Driver</h1>
            {list_menu_driver_render}
          </section>
        )}
        {!render_list && current_component.components}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({ data: state.data, auth: state.auth });

export default connect(mapStateToProps, { set_value_properties })(ADMIN_MENU);
