//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";
import format_jam_menit from "../../validation/format_jam_menit";
//Personal Component
//Redux function
//Picture Asset
//General Function
class SalesmanLogLayer extends Component {
  render() {
    const is_visible = this.props.data.list_menu_active.find(
      (e) => e.key === "salesman_log"
    )?.active;
    let content;
    const {
      route_mode,
      // outlet_mode
    } = this.props.data;
    // const isOutletCluster = outlet_mode === "cluster";
    let pGeojson = {
      type: "FeatureCollection",
      features: [],
    };
    if (this.props.tracking?.lineString?.length > 0) {
      const coords = this.props.tracking.lineString.map((l) => [
        l.longitude,
        l.latitude,
        format_jam_menit(l.time_stamp[0]),
      ]);
      const coorForLine = this.props.tracking.lineString.map((l) => [
        l.longitude,
        l.latitude,
      ]);
      let lineFt = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: coorForLine,
        },
      };
      let trackFt = coords.map((c) => ({
        type: "Feature",
        properties: {
          time_salesman: `${c[2]}`,
        },
        geometry: {
          type: "Point",
          coordinates: [c[0], c[1]],
        },
      }));
      let geojson = {
        type: "FeatureCollection",
        features: [lineFt],
      };
      let trackGeojson = {
        type: "FeatureCollection",
        features: trackFt,
      };
      const pCoords = [
        this.props.tracking.point.longitude,
        this.props.tracking.point.latitude,
      ];
      let bearing = this.props.tracking.point.bearing;
      let pointFt = {
        type: "Feature",
        properties: { rad: bearing },
        geometry: {
          type: "Point",
          coordinates: pCoords,
        },
      };
      pGeojson = {
        type: "FeatureCollection",
        features: [pointFt],
      };
      // console.log(pGeojson);
      if (route_mode) {
        content = (
          <main>
            <section>
              <Source
                key={"salesman-log-src"}
                id={"salesman-log-src"}
                type="geojson"
                data={geojson}
                generateId={true}
                cluster={false}
              />
              <Layer
                id={"salesman-log-lyr"}
                key={"salesman-log-lyr"}
                // before={
                //   isOutletCluster
                //     ? "outlet-salesman-cluster"
                //     : "outlet-salesman-lyr"
                // }
                before={"salesman_bike"}
                type={"line"}
                source={"salesman-log-src"}
                layout={{
                  visibility: is_visible ? "visible" : "none",
                }}
                paint={{
                  "line-color": "#DF4F4F",
                  "line-width": 3,
                  "line-opacity": 1,
                }}
              />
            </section>
            <section>
              <Source
                key={"salesman-track-point-line-src"}
                id={"salesman-track-point-line-src"}
                type="geojson"
                data={trackGeojson}
                generateId={true}
                cluster={false}
              />
              {/* <Layer
              id={"salesman-point-track-lyr"}
              key={"salesman-point-track-lyr"}
              type={"circle"}
              source={"salesman-track-point-src"}
              layout={{
                visibility: is_visible ? "visible" : "none",
              }}
              paint={{
                "circle-color": "#DF4F4F",
                "circle-radius": 4,
              }}
              filter={["all", ["!=", "id", -1]]}
            /> */}
              <Layer
                id={"salesman-point-line-track-lyr"}
                key={"salesman-point-line-track-lyr"}
                type={"symbol"}
                before={"salesman_bike"}
                source={"salesman-track-point-line-src"}
                layout={{
                  visibility: is_visible ? "visible" : "none",
                  "icon-image": "titik",
                  "text-field": ["get", "time_salesman"],
                  "text-font": ["Arial Unicode MS Bold"],
                  "icon-size": 0.35,
                  "icon-padding": 0,
                  "icon-anchor": "center",
                  "text-anchor": "top",
                  "text-offset": [0, 1.5],
                  "text-line-height": 1,
                  "icon-allow-overlap": true,
                  "icon-ignore-placement": true,
                  "text-allow-overlap": true,
                  "text-ignore-placement": true,
                  "text-size": 14,
                  "text-max-width": 32,
                }}
                paint={{
                  "icon-color": "#DF4F4F",
                  "icon-opacity": 1,
                  "text-color": "black",
                  "text-halo-color": "white",
                  "text-halo-width": 2.2,
                  "text-halo-blur": 1,
                  "icon-halo-color": "black",
                  "icon-halo-width": 1,
                }}
                filter={["all", ["!=", "id", -1]]}
              />
            </section>
          </main>
        );
      } else {
        content = (
          <main>
            <Source
              key={"salesman-track-point-src"}
              id={"salesman-track-point-src"}
              type="geojson"
              data={trackGeojson}
              generateId={true}
              cluster={false}
            />
            {/* <Layer
              id={"salesman-point-track-lyr"}
              key={"salesman-point-track-lyr"}
              type={"circle"}
              source={"salesman-track-point-src"}
              layout={{
                visibility: is_visible ? "visible" : "none",
              }}
              paint={{
                "circle-color": "#DF4F4F",
                "circle-radius": 4,
              }}
              filter={["all", ["!=", "id", -1]]}
            /> */}
            <Layer
              id={"salesman-point-track-lyr"}
              key={"salesman-point-track-lyr"}
              type={"symbol"}
              before={"salesman_bike"}
              source={"salesman-track-point-src"}
              layout={{
                visibility: is_visible ? "visible" : "none",
                "icon-image": "titik",
                "text-field": ["get", "time_salesman"],
                "text-font": ["Arial Unicode MS Bold"],
                "icon-size": 0.5,
                "icon-padding": 0,
                "icon-anchor": "center",
                "text-anchor": "top",
                "text-offset": [0, 1.5],
                "text-line-height": 1,
                "icon-allow-overlap": true,
                "icon-ignore-placement": true,
                "text-allow-overlap": true,
                "text-ignore-placement": true,
                "text-size": 14,
                "text-max-width": 32,
              }}
              paint={{
                "icon-color": "#DF4F4F",
                "icon-opacity": 1,
                "text-color": "black",
                "text-halo-color": "white",
                "text-halo-width": 2,
                "text-halo-blur": 1,
                "icon-halo-color": "black",
                "icon-halo-width": 2.2,
              }}
              filter={["all", ["!=", "id", -1]]}
            />
          </main>
        );
      }
    }
    return (
      this.props.tracking?.lineString?.length > 0 && (
        <main>
          <Source
            key={"salesman-point-log-src"}
            id={"salesman-point-log-src"}
            type="geojson"
            data={pGeojson}
            generateId={true}
            cluster={false}
          />
          {/* <Layer
            id={"salesman-point-log-lyr"}
            key={"salesman-point-log-lyr"}
            type={"circle"}
            source={"salesman-point-log-src"}
            layout={{
              visibility: is_visible ? "visible" : "none",
            }}
            paint={{
              "circle-color": "#DF4F4F",
              "circle-radius": 6,
              "circle-stroke-width": 2,
              "circle-stroke-color": "#274392",
            }}
            filter={["all", ["!=", "id", -1]]}
          /> */}
          <Layer
            id={"salesman-point-log-lyr"}
            key={"salesman-point-log-lyr"}
            type={"symbol"}
            before={"salesman_bike"}
            source={"salesman-point-log-src"}
            layout={{
              visibility: is_visible ? "visible" : "none",
              "icon-image": "motor",
              // "text-field": "ini",
              // "text-font": ["Arial Unicode MS Bold"],
              "icon-size": 0.075,
              "icon-padding": 0,
              "icon-anchor": "center",
              // "text-anchor": "top",
              // "text-offset": [0, 1.5],
              // "text-line-height": 1,
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
              "icon-rotate": ["get", "rad"],
              // "text-allow-overlap": true,
              // "text-ignore-placement": true,
              // "text-size": 14,
              // "text-max-width": 32,
            }}
            paint={{
              "icon-color": "#DF4F4F",
              "icon-opacity": 1,
              // "text-color": "black",
              // "text-halo-color": "white",
              // "text-halo-width": 2,
              // "text-halo-blur": 1,
              "icon-halo-color": "black",
              "icon-halo-width": 2.2,
            }}
            filter={["all", ["!=", "id", -1]]}
          />
          {content}
        </main>
      )
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
  tracking: state.tracking,
});
export default connect(mapStateToProps, {})(SalesmanLogLayer);
