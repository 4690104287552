/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-moment";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { get_salesman_by_id, getDataSalesman } from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL*/
import sort_array from "../../validation/sort_array";

/*NON IMPORT*/

class OMSET_SALESMAN_POPUP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state_modal_edit_salesman: false,
    };
  }

  componentDidMount() {
    const pop_up_properties = this?.props?.properties?.pop_up_properties || {};

    let kdSales = {};
    const kodePlan = pop_up_properties?.["Kode Plan"];
    let depo = this.props.filterStruktur.depo.find(
      (e) => e.plan_id === kodePlan
    );
    const year = this.props.data.getDate.YEAR_NUMBER;
    const month = this.props.data.getDate.MONTH;
    const gaji = depo.gaji ? depo.gaji : "-";
    const bensin = depo.bensin ? depo.bensin : "-";
    const sewaMotor = depo.sewaMotor ? depo.sewaMotor : "-";
    const uangMakan = depo.uangMakan ? depo.uangMakan : "-";
    const insentif = depo.insentif ? depo.insentif : "-";
    const totalBenefit = depo.gaji ? depo.totalBenefit : "-";

    if (typeof pop_up_properties.kd_sales_arr === "object") {
      kdSales = pop_up_properties.kd_sales_arr[0];
    } else if (!!pop_up_properties?.kd_sales_arr) {
      kdSales = JSON.parse(pop_up_properties.kd_sales_arr)[0];
    } else {
      kdSales = "";
    }

    this.props.getDataSalesman({
      kdSales,
      kodePlan,
      year,
      month,
    });
    this.setState({
      gaji,
      bensin,
      sewaMotor,
      uangMakan,
      insentif,
      totalBenefit,
    });
  }

  changeOption = (e) => {
    this.setState({ optionValue: e.target.value });
  };

  render() {
    //localstorage

    //local state
    const { gaji, bensin, sewaMotor, uangMakan, insentif, totalBenefit } =
      this.state;

    //global props
    const { productSalesman, monthSalesman } = this.props.data;
    const pop_up_properties = this?.props?.properties?.pop_up_properties || {};

    //content
    let kdSales = {};

    if (typeof pop_up_properties?.kd_sales_arr === "object") {
      kdSales = pop_up_properties?.kd_sales_arr?.[0];
    } else if (!!pop_up_properties?.kd_sales_arr) {
      kdSales = JSON.parse(pop_up_properties.kd_sales_arr)[0];
    } else {
      kdSales = "";
    }

    let all_array = [];
    all_array = productSalesman.map((e) => {
      let qty_salesman = parseFloat(e.qty);
      qty_salesman = qty_salesman.toFixed(1);
      qty_salesman = parseFloat(qty_salesman);
      let omset_salesman = parseInt(e.value);
      omset_salesman = omset_salesman.toFixed(1);
      omset_salesman = parseFloat(omset_salesman);
      return {
        qty: qty_salesman,
        value: omset_salesman,
        brand: e.brand,
        business_unit: e.business_unit,
        principle: e.principle,
        product_parent_id: e.product_parent_id,
        product_parent_name: e.product_parent_name,
      };
    });
    all_array = sort_array(all_array, `${this.state.optionValue}`, false);

    let table = (
      <>
        <section className="mb-10 w-36 h-7">
          <p className="font-bold">Filter Select</p>
          <select name="" id="" onChange={this.changeOption}>
            <option value="">Select</option>
            <option value="qty">Quantity</option>
            <option value="value"> Revenue (Rp)</option>
          </select>
        </section>
        <table className="table_lite w-full">
          <thead>
            <tr className="text-left">
              <th>Brand</th>
              <th>Business Unit</th>
              <th>Principal</th>
              <th>Product Id</th>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>Revenue</th>
            </tr>
          </thead>
          <tbody>
            {all_array.map(
              (
                {
                  qty,
                  value,
                  brand,
                  business_unit,
                  principle,
                  product_parent_id,
                  product_parent_name,
                },
                idx
              ) => {
                return (
                  <tr key={idx}>
                    <td
                      className="text_inferior my-10"
                      style={{ fontSize: "10px" }}
                    >
                      {brand}
                    </td>
                    <td
                      className="text_inferior my-10"
                      style={{ fontSize: "10px" }}
                    >
                      {business_unit}
                    </td>
                    <td
                      className="text_inferior my-10"
                      style={{ fontSize: "10px" }}
                    >
                      {principle}
                    </td>
                    <td
                      className="text_inferior my-10"
                      style={{ fontSize: "10px" }}
                    >
                      {product_parent_id}
                    </td>
                    <td
                      className="text_inferior my-10"
                      style={{ fontSize: "10px" }}
                    >
                      {product_parent_name}
                    </td>
                    <td
                      className="text_inferior my-10"
                      style={{ fontSize: "10px" }}
                    >
                      {/* {Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(qty)} */}
                      {qty}
                    </td>
                    <td
                      className="text_inferior my-10"
                      style={{ fontSize: "10px" }}
                    >
                      Rp.{" "}
                      {Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(value)}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </>
    );

    const options = {
      animation: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      responsive: true,
      scales: {
        x: {
          type: "time",
          time: {
            unit: "month",
            displayFormats: {
              month: "MMM",
            },
          },
        },
        y: {
          display: true,
          scaleLabel: {
            display: true,
            labelString: "User sum",
          },
          ticks: {
            beginAtZero: true,
            source: "auto",
          },
        },
      },
    };
    let data_history = monthSalesman.map((e) => {
      return {
        x: new Date(
          `${e.period.slice(0, 4)}-${e.period.slice(4, 7)}-01`
        ).getTime(),
        y: parseFloat(e.value),
      };
    });
    const data = {
      datasets: [
        {
          data: data_history,
          label: "History_omset",
          backgroundColor: "#0ca5ebff",
          hoverBackgroundColor: "#8fcc1bff",
        },
      ],
    };
    let history_content;
    history_content = (
      <main className="mt-5">
        <section className="text_bold text_center mb-1">
          Revenue History (Rp)
        </section>
        <section style={{ width: "300px", margin: "auto" }}>
          <Chart type="bar" data={data} options={options} />
        </section>
      </main>
    );
    return (
      <main style={{ fontFamily: `"Inter", sans-serif` }}>
        <div
          className="text_bold mb-3"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          Salesman Omset Details
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Salesman Name
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {pop_up_properties?.["Nama Salesman"] || "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Salesman ID
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {kdSales ? kdSales : "Unfilled"}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Plan
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {pop_up_properties?.["Kode Plan"] || 0}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Quantity
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {pop_up_properties?.qty_salesman || 0}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Revenue
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {pop_up_properties?.omset_salesman || 0}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Gaji Pokok
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {gaji}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Bensin
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {bensin}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Sewa Motor
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {sewaMotor}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Uang Makan
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {uangMakan}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Insentif
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {insentif}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Total Benefit
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {totalBenefit}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Revenue 3 Month
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {history_content}
        </div>
        <div
          className="text_inferior"
          style={{ fontSize: "10px", lineHeight: "125%" }}
        >
          Color Status
        </div>
        <div
          className="text_bold mb-2.5"
          style={{ fontSize: "15px", wordBreak: "break-word", marginTop: 2.5 }}
        >
          {(() => {
            let text = "";
            if (pop_up_properties?.["status_bike"]) {
              if (pop_up_properties?.["status_bike"] === "bike_red")
                text = "Red";
              if (pop_up_properties?.["status_bike"] === "bike_blue")
                text = "Blue";
              if (pop_up_properties?.["status_bike"] === "bike_green")
                text = "Green";
            } else {
              text = "Unfilled";
            }
            return text;
          })()}
        </div>
        {table}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  filterStruktur: state.data.salesman_filter,
  data: state.data,
  auth: state.auth,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  get_salesman_by_id,
  getDataSalesman,
})(OMSET_SALESMAN_POPUP);
