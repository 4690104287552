/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import LEFT_SIDE_BAR from "./LEFT_SIDE_BAR";
import MAP_PARENT from "./MAP_PARENT";
import RIGHT_SIDE_BAR from "./RIGHT_SIDE_BAR";
import FilterBar from "../tool/FilterBar";
import ShowHide from "../parent/ShowHide";

/*REDUX FUNCTION*/
import { set_list } from "../../actions/dataActions";

/*PICTURE ASSET*/
import icon_tool from "../../img/icon_tool.png";
import icon_filter from "../../img/icon_filter.png";
import icon_mata from "../../img/icon_mata.png";

/*GENERAL*/
const iconClass =
  "fixed right-[10px] z-[3] w-[30px] h-[30px]  cursor-pointer justify-center";

/*NON IMPORT*/

class MAIN_PARENT extends Component {
  state = {
    showIt: false,
  };

  toggle_tool = () => {
    const { tool_status } = this.props.data;
    const body_tool = { text: "tool_status", list: !tool_status };
    this.props.set_list(body_tool);

    const body_filter = { text: "filter_status", list: false };
    this.props.set_list(body_filter);
    this.setState({ showIt: false });
  };

  toggle_filter = () => {
    const { filter_status } = this.props.data;
    const body_filter = { text: "filter_status", list: !filter_status };
    this.props.set_list(body_filter);

    const body_tool = { text: "tool_status", list: false };
    this.props.set_list(body_tool);
    this.setState({ showIt: false });
  };

  actived = () => {
    this.setState({ showIt: !this.state.showIt });
  };

  render() {
    const { tool_status, filter_status } = this.props.data;
    return (
      <main>
        <section
          className={`${iconClass} top-[300px]`}
          onClick={this.toggle_tool}
        >
          <img
            className={"mx-auto h-[25px]"}
            src={icon_tool}
            alt="ResumeStatistic"
          />
          <div className="text-[0.55rem] w-[30px] bg-white mt-[5px] border-solid border-1 border-[#73adde] text-center">
            TOOLS
          </div>
        </section>
        <section
          className={`${iconClass} top-[350px]`}
          onClick={this.toggle_filter}
        >
          <img
            className={"mx-auto h-[25px]"}
            src={icon_filter}
            alt="ResumeStatistic"
          />
          <div className="text-[0.55rem] w-[30px] bg-white mt-[5px] border-solid border-1 border-[#73adde] text-center">
            FILTER
          </div>
        </section>
        <section className={`${iconClass} top-[400px]`} onClick={this.actived}>
          <img
            className={"mx-auto h-[25px]"}
            src={icon_mata}
            alt="ResumeStatistic"
          />
          <div className="text-[0.55rem] w-[30px] bg-white mt-[5px] border-solid border-1 border-[#73adde] text-center">
            ACTIVE
          </div>
        </section>
        <MAP_PARENT />
        <LEFT_SIDE_BAR />
        {tool_status && <RIGHT_SIDE_BAR />}
        {filter_status && <FilterBar />}
        {this.state.showIt && <ShowHide />}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({ data: state.data });

export default connect(mapStateToProps, { set_list })(MAIN_PARENT);
