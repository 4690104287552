import { bbox } from "@turf/turf";

import sort_lat from "./sort_lat";
import sort_long from "./sort_long";
import st_colors from "./st_colors";
import generate_v_max from "./generate_v_max";

const rayonisasi_rayon_box = (body) => {
  let {
    //NUMBER
    jumlah_toko, //jumlah_toko = jumlah toko yang diingikan untuk setiap rayon
    // ratio, //ratio = rasio dari bentuk bbox dari array toko

    //STRING
    mode_sisa_toko_rayon, //mode sisa toko jika total toko dibagi jumlah rayon tidak habis dibagi
    mode_first, //mode frst apakah ingin dibagi vertikal dulu atau horisontal dulu

    //ARRAY
    toko_list_main, // array toko list yang kan dirayonisasi
    depo_loc_list, //list depo yang terlibat dalam rayonisasi

    mode_nama_rayon,
    nama_rayon_custom,
  } = body;

  let imbuhan = "_Rayon_";
  if (mode_nama_rayon === "custom") {
    imbuhan = `_${nama_rayon_custom}_`;
  }

  jumlah_toko = parseInt(jumlah_toko);
  // ratio = parseInt(ratio);

  /*
  mode_first mode pembagian mau horisontal dulu kemudian vertikal atau vertikal dulu kemudian horisontal
  v_first : vertical first
  h_first : horisontal first
  */

  //masukan ke index ke atribut index karena ketika toko difilter berdasarkan depo indexnya akan berubah
  let toko_list_new = toko_list_main.map((t, idx) => {
    t.index = idx;
    t.rayon = "";
    t.rayon_id = "";
    return t;
  });
  let rayon_list = [];
  let rayon_list_complete = [];

  depo_loc_list.forEach((d) => {
    //jumlah_toko_depo : jumlah toko yang ada pada depo yang akan dilooping
    // const jumlah_toko_depo = toko_list_new.filter(
    //   (t) => t.Depo === d.value
    // )?.length;

    const jumlah_toko_depo = toko_list_new.filter((t) => t)?.length;

    //untuk dua metode jumlah rayon tetap sama untuk simplifikasi
    let jumlah_rayon;
    jumlah_rayon = Math.ceil(jumlah_toko_depo / jumlah_toko);

    if (jumlah_rayon === 0) {
      jumlah_rayon = 1;
    }
    for (let index = 0; index < jumlah_rayon; index++) {
      const element = `${d.value}${imbuhan}${index + 1}`;
      const object = {
        name: element,
        depo_id: d._id,
        depo_string: d.value,
        date_created: Date.now(),
        date_updated: Date.now(),
      };
      rayon_list.push(element);
      rayon_list_complete.push(object);
    }
  });

  //looping algoritma untuk tiap depo (karena bisa multi depo)
  depo_loc_list.forEach((d) => {
    //hanya mengambil toko pada depo yang sedang diloop
    // let toko_list = toko_list_new.filter((t) => t.Depo === d.value) || [];

    let toko_list = toko_list_new.filter((t) => t) || [];

    const features = toko_list.map((t) => {
      return { type: "Feature", properties: {}, geometry: t.location };
    });
    const geojson = {
      type: "FeatureCollection",
      features: [...features],
    };

    //mengambil jumlah toko pada depo yang sedang diloop
    // const jumlah_toko_depo = toko_list.filter(
    //   (t) => t.Depo === d.value
    // )?.length;

    const jumlah_toko_depo = toko_list.filter((t) => t)?.length;

    //untuk dua metode jumlah rayon tetap sama untuk simplifikasi
    let jumlah_rayon;
    jumlah_rayon = Math.ceil(jumlah_toko_depo / jumlah_toko);
    if (jumlah_rayon === 0) {
      jumlah_rayon = 1;
    }
    const toko_perloop = Math.ceil(toko_list.length / jumlah_rayon);

    // mode_sisa_toko_rayon = "akhir";
    let jumlah_toko_final;
    if (mode_sisa_toko_rayon === "rata") {
      //jika menggunakan metode rata maka jumlah toko menjadi lebih rata dari 300 (misal      304)
      jumlah_toko_final = toko_perloop;
    } else if (mode_sisa_toko_rayon === "akhir") {
      //jika menggunakan metode akhir maka jumlah toko dipaksa 300 (misal)
      jumlah_toko_final = jumlah_toko;
    }

    /*STEP 1 Deklarasi variabel penting*/

    //index_loop_rayon: parameter index untuk looping rayon secara keseluruhan, jika index_loop_rayon sudah === maka keluar dari looping
    let index_loop_rayon = 0;

    const [minLng, minLat, maxLng, maxLat] = bbox(geojson);
    const delta_long = maxLng - minLng;
    const delta_lat = maxLat - minLat;
    const ratio = delta_lat / delta_long;

    //v_max : jumlah baris vertikal untuk pembagian rayon

    let v_max;

    const v_auto = generate_v_max(jumlah_rayon, ratio);
    const v_manual = d?.v_manual ? d?.v_manual : 1;

    const mode = d.mode; //mode pembagian v_max manual || auto

    if (mode === "manual") {
      v_max = v_manual;
    } else {
      //mode === auto || undefined
      v_max = v_auto;
    }

    v_max = parseInt(v_max);

    //pengali: pengali untuk setiap v_index
    const pengali = Math.floor(jumlah_rayon / v_max);

    //mod : sisa pembagian dari v_max untuk menentukan v_index mana yang akan dilebihkan 1 pengali nya
    const mod = jumlah_rayon % v_max;

    //vertical_range : array looping pertama, untuk kasus ini akan dilooping vertikal dulu
    let vertical_range = [];

    /*STEP 2 Looping untuk generate pengali_n (bagian dari total toko) untuk masing baris vertikal*/

    for (let n = 0; n < v_max; n++) {
      //pengali_n : bagian untuk masing-masing baris vertikal, misal jika jumlah_rayon = 3 , maka [1/3, 1/3, 1/3]
      const pengali_n =
        mod >= n + 1
          ? (1 / jumlah_rayon) * (pengali + 1)
          : (1 / jumlah_rayon) * pengali;

      //jumlah_toko_v : jumlah toko untuk masing-masing baris vertikal, misal jika jumlah_rayon = 3, jumlah total toko = 300 , maka [100, 100, 100]
      let jumlah_toko_v = Math.floor(pengali_n * jumlah_toko_depo);

      /*
      jumlah_toko_v tidak boleh lebih besar daripada jumlah_toko_final karena akan menyebabkan toko tidak terbagi ke rayon (warna hitam)
      */

      //jumlah_rayon_horisontal : berapa rayon yang dibagi secara horisontal pada suatu baris vertikal
      const jumlah_rayon_horisontal = mod >= n + 1 ? pengali + 1 : pengali;
      vertical_range.push({
        pengali_n,
        jumlah_rayon_horisontal,
        jumlah_toko_v,
      });
    }

    /*STEP 3 Modifikasi vertical_range untuk mendapatkan range start & end dari baris vertikal, membutuhkan map dari vertical_range original karena membutuhkan nilai dari elemen sebelumnya*/
    vertical_range = vertical_range.map(
      ({ jumlah_toko_v, jumlah_rayon_horisontal }, idx) => {
        //start: index awal dari range pembagian vertikal, end: index akhir dari range pembagian vertikal
        let start, end;
        if (idx === 0) {
          start = 0;
        } else {
          //sum dari semua toko sebelum index yang sedang looping
          start = vertical_range.slice(0, idx).map((r) => r.jumlah_toko_v);
          start = start.reduce((a, b) => a + b, 0);
        }
        if (idx === v_max - 1) {
          end = jumlah_toko_depo;
        } else {
          end = start + jumlah_toko_v;
        }

        //horisontal_range: array range untuk pembagian horisontal, isinya start & end dari rayon yang akan dibuat
        let horisontal_range = [];
        for (let h = 0; h < jumlah_rayon_horisontal; h++) {
          let start_h, end_h;
          start_h = h * jumlah_toko_final;
          if (idx === v_max - 1 && h === jumlah_rayon_horisontal - 1) {
            //perlakuan untuk rayon terakhir
            end_h = jumlah_toko_depo;
          } else {
            end_h = start_h + jumlah_toko_final;
          }
          horisontal_range.push({ start_h, end_h });
        }
        return {
          // beberapa atribut yang sudah tidak dibutuhkan lagi:
          // pengali_n,
          // jumlah_toko_v,
          jumlah_rayon_horisontal,
          jumlah_toko_v,

          //atribut yang pada akhirnya digunakan:
          start,
          end,
          horisontal_range,
        };
      }
    );

    //STEP 4 Urutkan toko_list berdasarkan latitude karena setelah ini akan dikelompokan secara vertikal
    if (mode_first === "v_first") {
      toko_list = sort_lat(toko_list, false);
    } else {
      toko_list = sort_long(toko_list, true);
    }

    //STEP 5 mulai looping pembagian vertikal
    vertical_range.forEach(
      (
        {
          start,
          end,
          horisontal_range,
          // jumlah_rayon_horisontal
        },
        index_vertical
      ) =>
        // index_v
        {
          //STEP 6 hanya ambil toko dari range start dan end vertikal, karena sudah diurutkan dengan latitudenya
          let toko_list_v = toko_list.slice(start, end);

          /*STEP 7 urutkan toko_list secara longitude karena setelah ini akan dikelompokan secara horisontal*/
          if (mode_first === "v_first") {
            toko_list_v = sort_long(toko_list_v, true);
          } else {
            toko_list_v = sort_lat(toko_list_v, false);
          }

          //STEP 8 looping bagikan secara horisontal toko_list ke kotak-kotak sebanyak jumlah_toko_final (sudah ada array horisontal_range di setiap element array range)
          horisontal_range.forEach(({ start_h, end_h }) =>
            // index_h
            {
              //STEP 9 index_loop_rayon bertambah 1 untuk membedakan dengan rayon lain
              index_loop_rayon = index_loop_rayon + 1;

              //STEP 10 value_string: nama rayon diambil dari nama DEPO dan nomor index
              const value_string = `${d.value}${imbuhan}${index_loop_rayon}`;

              //STEP 11 hanya ambil toko dari range start dan end horisontal, karena sudah diurutkan dengan longitudenya
              let toko_list_h = [];

              toko_list_h = toko_list_v.slice(start_h, end_h);

              toko_list_h.forEach((toko) => {
                //STEP 12 masukan nilai rayon ke toko-toko yang sudah dislice
                toko_list_new[toko.index].rayon = `${value_string}`;
              });
            }
          );
        }
    );
    //LOOPING RAYONISASI SELESAI
  });

  //merapikan hasil rayonisasi dan mengisi atribut yang diperlukan
  rayon_list_complete = rayon_list_complete.map((r, idx) => {
    const toko_list = toko_list_new.filter((t) => t.rayon === r.name);

    const KELURAHAN_array = [...new Set(toko_list.map((k) => k.KELURAHAN))];
    const KECAMATAN_array = [...new Set(toko_list.map((k) => k.KECAMATAN))];
    const KABUPATEN_array = [...new Set(toko_list.map((k) => k.KABUPATEN))];
    const PROPINSI_array = [...new Set(toko_list.map((k) => k.PROPINSI))];

    const negara = toko_list?.[0]?.COUNTRY
      ? toko_list?.[0]?.COUNTRY
      : "INDONESIA";
    const outlet_id_array = toko_list.map((k) => k._id);

    r.KELURAHAN_array = KELURAHAN_array;
    r.KECAMATAN_array = KECAMATAN_array;
    r.KABUPATEN_array = KABUPATEN_array;
    r.PROPINSI_array = PROPINSI_array;
    r.negara = negara;
    r.outlet_id_array = outlet_id_array;

    let long = toko_list.map((t) => t.location.coordinates[0]);
    let lat = toko_list.map((t) => t.location.coordinates[1]);
    long = long.reduce((p, a) => p + a, 0) / long.length; //average long
    lat = lat.reduce((p, a) => p + a, 0) / lat.length; //average lat
    const outlet_length = toko_list.length;
    r.location = {
      type: "Point",
      coordinates: [long, lat],
    };
    r.outlet_length = outlet_length;
    let index = idx;
    if (idx > 15) {
      index = idx % 16;
    }
    r.color = st_colors[index];
    return r;
  });
  return { toko_list_new, rayon_list, rayon_list_complete };
};

export default rayonisasi_rayon_box;
