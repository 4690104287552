/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { bbox } from "@turf/turf";
import Select from "react-select";
import { CSVLink } from "react-csv";
import writeXlsxFile from "write-excel-file";
import { Popup } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal";
import Aggregate from "./Aggregate";
import BloopTokos from "../parent/BloopTokos";
import ASSIGN_OUTLET_MERCH from "../popup/ASSIGN_OUTLET_MERCH";
import ON_FLY from "../main/ON_FLY";

/*REDUX FUNCTION*/
import {
  getSalesmanFilter,
  get_outlet_list_by_area,
  turn_on_menu,
  turn_on_menu_status,
  turn_off_menu,
  set_list,
  filter_outlet_by_search,
  clear_get_outlet_list_by_area,
  get_outlet_list_by_all_area,
} from "../../actions/dataActions";
import { set_value_properties } from "../../actions/propertiesActions";
import { inactivate_edit_point_depo } from "../../actions/mapActions";

/*PICTURE ASSET*/

/*GENERAL*/

/*NON IMPORT*/

class OUTLET_MANAGEMENT_MENU extends Component {
  state = {
    checkArea: "",
    area: "",
    name: "",
    salesman_filter: [],
    name_arr: [],
    list_column: 50,
    loading_csv: false,
    separator: ";",
    dataCSV: [],
    modal_agg: false,
    find_keyword: "",
    find_select: "outlet_name",
    downloadOutlet: false,
    showMe: false,
    mode_closed: "without_closed", //without_closed | with_closed
    mode_omset: "without_omset", //with_omset | without_omset
    modalBloops: false,
    mode: "",
    modal_merch: false,
    current_outlet: {},
  };

  componentDidMount() {
    if (this.props.data.salesman_filter.depo.length === 0) {
      this.props.getSalesmanFilter();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { outlet_list_filter_0 } = this.props.data;
    const sidebarW = parseInt(localStorage.getItem("sidebar-width") || 500);
    if (
      prevProps.data.outlet_list_filter_0.length !==
        this.props.data.outlet_list_filter_0.length &&
      outlet_list_filter_0.length > 0
    ) {
      const { map } = this?.props?.map;
      const new_features = outlet_list_filter_0.map((e) => {
        return {
          type: "Feature",
          properties: { ...e },
          geometry: e.location,
        };
      });
      const geojson = {
        type: "FeatureCollection",
        features: [...new_features],
      };
      let [minLng, minLat, maxLng, maxLat] = bbox(geojson);
      if (map) {
        map?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: { top: 200, bottom: 200, left: sidebarW + 50, right: 500 },
          }
        );
      }
    }
  }

  on_fly = (item) => {
    const { map } = this.props.map;
    this.props.set_value_properties({
      key: "pop_up_properties",
      value: item,
    });
    const center = item?.location?.coordinates;
    map.flyTo({
      center: center,
      zoom: 18,
    });
    this.props.set_value_properties({
      key: "modal_pop_up",
      value: true,
    });
    this.props.set_value_properties({
      key: "type_pop_up",
      value: "modal_distribution",
    });

    const pop_up_map = (
      <Popup
        tipSize={7}
        anchor="bottom"
        longitude={center[0]}
        latitude={center[1]}
        closebutton={true}
        closeOnClick={false}
        style={{
          zIndex: 2,
        }}
        onClose={() => {
          this.props.inactivate_edit_point_depo();
          this.props.set_value_properties({
            key: "modal_pop_up",
            value: false,
          });
          this.props.set_value_properties({
            key: "pop_up_map",
            value: null,
          });
        }}
      >
        <div
          style={{
            maxHeight: "45vh",
            overflowY: "scroll",
            overflowX: "hidden",
            padding: "0px 10px 0px 0px",
          }}
        >
          <div
            className="text_inferior"
            style={{ fontSize: "10px", lineHeight: "125%" }}
          >
            Outlet name
          </div>
          <div
            className="text_bold"
            style={{
              fontSize: "15px",
              wordBreak: "break-word",
              marginTop: 2.5,
            }}
          >
            {item?.["Nama Toko"]
              ? item?.["Nama Toko"]
              : item?.["NAMA OUTLET"]
              ? item?.["NAMA OUTLET"]
              : item?.["REGION"]
              ? item?.["REGION"]
              : item?.["Nama Salesman"]
              ? item?.["Nama Salesman"]
              : item?.["NOPOL"]
              ? `${item?.["NOPOL"]}`
              : item?.["Depo"]}
          </div>
        </div>
      </Popup>
    );

    this.props.set_value_properties({
      key: "pop_up_map",
      value: pop_up_map,
    });
  };

  toggle_merch = (current_outlet) => {
    this.setState({ modal_merch: !this.state.modal_merch });
    if (current_outlet) {
      this.setState({ current_outlet });
    }
  };

  toggle_agg = () => {
    this.setState({ modal_agg: !this.state.modal_agg });
  };

  on_turn_on_menu_status = () => {
    this.props.turn_on_menu_status("distribution");
  };

  on_turn_off_menu = () => {
    this.props.turn_off_menu("distribution");
  };

  handle_change = (e) => {
    if (Array.isArray(e)) {
      this.setState({
        name_arr: e,
      });
    } else {
      let newArea = "";
      switch (e.value) {
        case "all":
          newArea = "All";
          break;
        case "depo":
          newArea = "Depo";
          break;
        case "cabang":
          newArea = "Branch";
          break;
        case "region":
          newArea = "Region";
          break;
        default:
          break;
      }
      this.setState({ checkArea: newArea });
      if (e.value !== "all") {
        this.setState({
          [e.name]: newArea,
          name_arr: [],
          salesman_filter: this.props.data.salesman_filter[`${e.key}`].map(
            (params) => {
              return {
                key: params[`${e.db}`],
                label: params[`${e.db}`],
                value: params[`${e.db}`],
                name: "name",
              };
            }
          ),
        });
      }
    }
  };

  on_submit = (event) => {
    const { salesman_filter } = this.props.data;
    let selectedCountry = "";
    if (this.props.auth?.user?.badge !== "super_admin") {
      selectedCountry = this.props.auth?.user?.negara;
    } else {
      selectedCountry = this.props.auth?.country_selected;
    }
    const { mode_closed, mode_omset } = this.state;
    let { area, name_arr } = this.state;
    let newArea = "";
    switch (area) {
      case "Depo":
        newArea = "depo";
        break;
      case "Branch":
        newArea = "cabang";
        break;
      case "Region":
        newArea = "region";
        break;
      default:
        break;
    }
    const original_depo = salesman_filter?.original_depo
      ? salesman_filter?.original_depo
      : [];
    let name_arr_new = name_arr;
    if (newArea === "cabang") {
      const array = name_arr.map((n) => n.value);
      name_arr_new = original_depo.filter((d) => array.includes(d.CABANG));
      name_arr_new = name_arr_new.map((d) => {
        return { value: d.Depo };
      });
    }
    if (newArea === "region") {
      const array = name_arr.map((n) => n.value);
      name_arr_new = original_depo.filter((d) => array.includes(d.REGION));
      name_arr_new = name_arr_new.map((d) => {
        return { value: d.Depo };
      });
    }
    const params = {
      area: "depo",
      name: name_arr_new.map((e) => e.value),
      name_arr: name_arr_new,
      mode: "Depo",
      country: selectedCountry,
      mode_closed,
      mode_omset,
    };
    this.props.clear_get_outlet_list_by_area();
    if (this.state.checkArea === "All") {
      if (this.props.auth?.user?.badge !== "super_admin") {
        this.props.get_outlet_list_by_all_area(this.props.auth?.user?.negara);
      } else {
        this.props.get_outlet_list_by_all_area(selectedCountry);
      }
    } else {
      this.props.get_outlet_list_by_area(params);
    }
    this.props.turn_on_menu({ key: "distribution", config: params });
    this.setState({
      list_column: 50,
      showMe: true,
    });
  };

  generateDataCSV = (outlet_list) => {
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });
      try {
        let tempData = [];
        const header = [
          "_id",
          "PLAN",
          "ID OUTLET",
          "ID SFA",
          "OMSET",
          "DEPO",
          "BRANCH",
          "REGION",
          "STATUS DEPO",
          "SYSTEM",
          "NAMA OUTLET",
          "ALAMAT 1",
          "ALAMAT 2",
          "NO TELP",
          "TYPE OUTLET",
          "KELURAHAN",
          "KECAMATAN",
          "KABUPATEN",
          "PROPINSI",
          "FLAG DISTRICT",
          "DETECTED LOCATION",
          "KET UPDATE",
          "KDPOS",
          "STATUS OUTLET",
          "ID BRANCH",
          "COUNTRY",
          "COORDINATE STATUS",
          "SEGMENT",
        ];
        tempData.push(header);
        for (let index = 0; index < outlet_list.length; index++) {
          // const { salesman_filter } = this.props.data;
          const { user, master_admin } = this.props.auth;
          const element = outlet_list[index];
          let location = "";
          let status_koordinat = "NO";
          if (element?.location?.coordinates?.[0] !== 0) {
            const long = element.location.coordinates[0];
            const lat = element.location.coordinates[1];
            location = `${lat},${long}`;
            status_koordinat = "YES";
          }
          if (
            master_admin.filter((element) => element._id === user._id).length <=
            0
          ) {
            if (element?.location?.coordinates?.[0] !== 0) {
              location = "YES";
            } else {
              location = "NO";
            }
          }
          // let newDepo = salesman_filter.depo.find(
          //   (x) => x.plan_id === element.Plan
          // );
          tempData.push([
            element._id,
            element?.Plan ?? "-",
            element["ID Outlet"] ?? "-",
            element["ID SFA"] ?? "-",
            element["value"] ?? "-",
            element["Depo"] ?? "-",
            element["CABANG"] ?? "-",
            element["REGIONAL"] ?? "-",
            element["STATUS DEPO"] ?? "-",
            element["SYSTEM"] ?? "-",
            element["Nama Toko"] ?? "-",
            element["ALAMAT"] ?? "-",
            element["ALAMATLAIN"] ?? "-",
            element["NO Tlpon"] ?? "-",
            element["Type Outlet"] ?? "-",
            element["KELURAHAN"] ?? "-",
            element["KECAMATAN"] ?? "-",
            element["KABUPATEN"] ?? "-",
            element["PROPINSI"] ?? "-",
            element["FLAG DISTRICT"] ?? "-",
            location,
            element["KET UPDATE"] ?? "-",
            element["KDPOS"] ?? "-",
            element["Status Outlet"] ?? "-",
            element["ID BRANCH"] ?? "-",
            element["COUNTRY"] ?? "-",
            status_koordinat,
            element["SEGMENT"] ?? "-",
          ]);
        }
        this.setState({
          loading_csv: false,
          dataCSV: tempData,
        });
      } catch (error) {
        this.setState({
          loading: false,
        });
      }
    }
  };

  generateDataEXCEL = async (outlet_list) => {
    console.log(outlet_list);
    if (!this.state.loading_csv) {
      this.setState({
        loading_csv: true,
      });
      try {
        const schema = [
          {
            column: "_id",
            type: String,
            value: (distribution) => distribution._id,
          },
          {
            column: "PLAN",
            type: String,
            value: (distribution) => distribution.Plan,
          },
          {
            column: "ID OUTLET",
            type: String,
            value: (distribution) => distribution["ID Outlet"],
          },
          {
            column: "ID SFA",
            type: String,
            value: (distribution) => distribution["ID SFA"],
          },
          {
            column: "OMSET",
            type: String,
            value: (distribution) => distribution.omset,
          },
          {
            column: "DEPO",
            type: String,
            value: (distribution) => distribution.Depo,
          },
          {
            column: "BRANCH",
            type: String,
            value: (distribution) => distribution.CABANG,
          },
          {
            column: "REGION",
            type: String,
            value: (distribution) => distribution.REGIONAL,
          },
          {
            column: "DEPO STATUS",
            type: String,
            value: (distribution) => distribution["STATUS DEPO"],
          },
          {
            column: "SYSTEM",
            type: String,
            value: (distribution) => distribution.SYSTEM,
          },
          {
            column: "NAMA OUTLET",
            type: String,
            value: (distribution) => distribution["Nama Toko"],
          },
          {
            column: "ALAMAT 1",
            type: String,
            value: (distribution) => distribution.ALAMAT,
          },
          {
            column: "ALAMAT 2",
            type: String,
            value: (distribution) => distribution.ALAMATLAIN,
          },
          {
            column: "NO TELP",
            type: String,
            value: (distribution) => distribution["NO Tlpon"],
          },
          {
            column: "TYPE OUTLET",
            type: String,
            value: (distribution) => distribution["Type Outlet"],
          },
          {
            column: "KELURAHAN",
            type: String,
            value: (distribution) => distribution.KELURAHAN,
          },
          {
            column: "KECAMATAN",
            type: String,
            value: (distribution) => distribution.KECAMATAN,
          },
          {
            column: "KABUPATEN",
            type: String,
            value: (distribution) => distribution.KABUPATEN,
          },
          {
            column: "PROPINSI",
            type: String,
            value: (distribution) => distribution.PROPINSI,
          },
          {
            column: "FLAG DISTRICT",
            type: String,
            value: (distribution) => distribution["FLAG DISTRICT"],
          },
          {
            column: "DETECTED LOCATION",
            type: String,
            value: (distribution) => distribution.location,
          },
          {
            column: "KET UPDATE",
            type: String,
            value: (distribution) => distribution["KET UPDATE"],
          },
          {
            column: "KDPOS",
            type: String,
            value: (distribution) => distribution.KDPOS,
          },
          {
            column: "STATUS OUTLET",
            type: String,
            value: (distribution) => distribution["Status Outlet"],
          },
          {
            column: "ID BRANCH",
            type: String,
            value: (distribution) => distribution["ID BRANCH"],
          },
          {
            column: "COUNTRY",
            type: String,
            value: (distribution) => distribution.COUNTRY,
          },
          {
            column: "STATUS KOORDINAT",
            type: String,
            value: (distribution) => distribution.status_koordinat,
          },
          {
            column: "SEGMENT",
            type: String,
            value: (distribution) => distribution.segment,
          },
        ];

        let newData = [];
        const { salesman_filter } = this.props.data;
        outlet_list.forEach((e) => {
          const { user, master_admin } = this.props.auth;
          let location = "";
          let status_koordinat = "No";
          if (e?.location?.coordinates?.[0] !== 0) {
            const long = e.location.coordinates[0];
            const lat = e.location.coordinates[1];
            location = `${lat},${long}`;
            status_koordinat = "Yes";
          }
          if (master_admin.filter((e) => e._id === user._id).length <= 0) {
            if (e?.location?.coordinates?.[0] !== 0) {
              location = "YES";
            } else {
              location = "NO";
            }
          }
          let newDepo = salesman_filter.depo.find((x) => x.plan_id === e.Plan);
          let getData = {
            _id: e._id ?? "-",
            Plan: e.Plan ?? "-",
            "ID Outlet": e["ID Outlet"] ?? "-",
            "ID SFA": e["ID SFA"] ?? "-",
            omset: `${e.value}` ?? "-",
            Depo: newDepo?.Depo ?? "-",
            CABANG: e.CABANG ?? "-",
            REGIONAL: e.REGIONAL ?? "-",
            "STATUS DEPO": e["STATUS DEPO"] ?? "-",
            SYSTEM: e.SYSTEM ?? "-",
            "Nama Toko": e["Nama Toko"] ?? "-",
            ALAMAT: e.ALAMAT ?? "-",
            ALAMATLAIN: e.ALAMATLAIN ?? "-",
            "NO Tlpon": e["NO Tlpon"] ?? "-",
            "Type Outlet": e["Type Outlet"] ?? "-",
            KELURAHAN: e.KELURAHAN ?? "-",
            KECAMATAN: e.KECAMATAN ?? "-",
            KABUPATEN: e.KABUPATEN ?? "-",
            PROPINSI: e.PROPINSI ?? "-",
            "FLAG DISTRICT": e["FLAG DISTRICT"] ?? "-",
            location: location ?? "-",
            "KET UPDATE": e["KET UPDATE"] ?? "-",
            KDPOS: e.KDPOS ?? "-",
            "Status Outlet": e["Status Outlet"] ?? "-",
            "ID BRANCH": e["ID BRANCH"] ?? "-",
            COUNTRY: e.COUNTRY ?? "-",
            status_koordinat: status_koordinat ?? "-",
            segment: e.SEGMENT ?? "-",
          };
          newData.push(getData);
        });
        await writeXlsxFile(newData, {
          schema,
          fileName: "DataExcelDristribution.xlsx",
        });
        this.setState({
          loading_csv: false,
        });
      } catch (error) {
        console.log(error);
        this.setState({
          loading: false,
        });
      }
    }
  };

  find_submit_outlet = (event) => {
    event.preventDefault();
    const { find_keyword, find_select } = this.state;
    this.props.filter_outlet_by_search(find_keyword, find_select);
  };

  find_on_change = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  on_change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  render() {
    //local storage

    //local state
    const {
      area,
      salesman_filter,
      modal_agg,
      mode_closed,
      mode_omset,
      modalBloops,
      modal_merch,
      current_outlet,
    } = this.state;

    //global props
    const {
      loading_status,
      loading_item,
      outlet_list,
      outlet_list_filter_0,
      list_menu_active,
    } = this.props.data;

    //content

    const modal_merch_content = modal_merch && (
      <Modal
        modalSize="medium"
        isOpen={modal_merch}
        onClose={this.toggle_merch}
      >
        <div className="box-body" id="box-body">
          <ASSIGN_OUTLET_MERCH
            current_outlet={current_outlet}
            toggle_merch={this.toggle_merch}
          />
        </div>
      </Modal>
    );

    const menu_status = list_menu_active.find((e) => e.key === "distribution");
    let menu_button;
    if (menu_status.active === true) {
      menu_button = (
        <button onClick={this.on_turn_off_menu} className="button_main">
          Hide
        </button>
      );
    } else {
      menu_button = (
        <button onClick={this.on_turn_on_menu_status} className="button_main">
          View
        </button>
      );
    }

    const area_list = [
      {
        key: "all",
        label: "All Outlets",
        value: "all",
        db: "all",
        name: "area",
      },
      { key: "depo", label: "Depo", value: "depo", db: "Depo", name: "area" },
      {
        key: "cabang",
        label: "Branch",
        value: "cabang",
        db: "CABANG",
        name: "area",
      },
      {
        key: "regional",
        label: "Region",
        value: "region",
        db: "REGIONAL",
        name: "area",
      },
    ];

    const { loading_csv, separator, dataCSV } = this.state;
    let button_slice;
    let main_content;
    let search;
    let table_list;

    if (outlet_list.length > 0) {
      button_slice = (
        <div className="flex items-center justify-center w-full">
          <button
            className="button_main mt-5"
            onClick={() => {
              let number = this.state.list_column + 50;
              this.setState({ list_column: number });
            }}
          >
            Load More Datas
          </button>
        </div>
      );
    }

    if (this.state.list_column > outlet_list.length) {
      button_slice = (
        <div className="flex items-center justify-center mt-5">
          All Data Has Been Loaded
        </div>
      );
    }

    if (this.state.showMe || outlet_list.length > 0) {
      search = (
        <form onSubmit={this.find_submit_outlet}>
          <div className="flex gap-1">
            <input
              type="text"
              className="w-full"
              placeholder="Search By Name or Id Outlet"
              name="find_keyword"
              onChange={this.find_on_change}
              autoComplete="off"
            />
            <select
              className="w-fit"
              name="find_select"
              onChange={this.find_on_change}
            >
              <option value={"outlet_name"}>Outlet Name</option>
              <option value={"outlet_id"}>Outlet Id</option>
            </select>
            <button className="px-1" type="sumbit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </button>
          </div>
        </form>
      );
    }

    if (this.state.showMe) {
      table_list = (
        <>
          <button
            className="button mr-2 mb-3"
            id="green"
            onClick={this.toggle_agg}
          >
            Aggregate Dashboard
          </button>
          {menu_button}
          <table className="table_lite">
            <tbody>
              <tr>
                <td>Total outlet</td>
                <td>{outlet_list.length}</td>
              </tr>
              <tr>
                <td>Total outlet with location</td>
                <td>{outlet_list_filter_0.length}</td>
              </tr>
            </tbody>
          </table>
          <div className="my-2">
            <button
              className="button ml-1"
              id="green"
              onClick={() => {
                this.setState({ downloadOutlet: true });
              }}
            >
              Download Outlet
            </button>
          </div>
        </>
      );
    }

    if (outlet_list.length > 0) {
      main_content = (
        <main style={{ marginBottom: "20px" }}>
          <table className="table_lite" style={{ fontSize: "13px" }}>
            <thead>
              <tr>
                <th>No</th>
                <th>Outlet name</th>
                <th>Outlet type</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              {outlet_list
                .slice(0, this.state.list_column)
                .map((outlet, idx) => {
                  let button_fly = (
                    <ON_FLY item={outlet} modal_item="modal_distribution" />
                  );
                  if (!outlet?.location?.coordinates?.[0]) {
                    button_fly = (
                      <button className="button_small" id="red">
                        No location
                      </button>
                    );
                  }
                  let img;
                  if (outlet?.images?.length > 0) {
                    const src = outlet?.images?.[outlet?.images?.length - 1];
                    img = (
                      <img
                        alt="Store"
                        src={src?.Location}
                        style={{ height: "100px", width: "100px" }}
                      />
                    );
                  }
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>
                        {outlet?.["Nama Toko"]}
                        <br />
                        {img}
                        <br />
                        {button_fly}
                        <button
                          className="button_small background_green mt-2"
                          onClick={this.toggle_merch.bind(this, outlet)}
                        >
                          Assign merchandising
                        </button>
                      </td>
                      <td>{outlet?.["Type Outlet"]}</td>
                      <td
                        style={{
                          wordBreak: "break-all",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {outlet?.["ALAMAT"]}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {button_slice}
        </main>
      );
    }

    const modal_agg_content = modal_agg && (
      <Modal modalSize="large" isOpen={modal_agg} onClose={this.toggle_agg}>
        <div className="box-body">
          <main style={{ minHeight: "100vh" }}>
            <Aggregate />
          </main>
        </div>
      </Modal>
    );

    const modalDownloadOutlet = this.state.downloadOutlet && (
      <Modal
        modalSize="small"
        isOpen={this.state.downloadOutlet}
        onClose={() =>
          this.setState({ downloadOutlet: !this.state.downloadOutlet })
        }
      >
        <div className="box-body">
          <h1 className="text-center font-bold">Download Outlet by CSV</h1>
          <section className={`flex justify-center mb-3`}>
            <CSVLink
              className="button_main"
              data={dataCSV}
              separator={separator}
              filename={`Outlet Data.csv`}
              onClick={(e, done) => {
                this.generateDataCSV(outlet_list, done);
              }}
            >
              {loading_csv ? "Loading ..." : "Download Outlet CSV"}
            </CSVLink>
          </section>
          <h1 className="text-center font-bold">Download Outlet by EXCEL</h1>
          <section className={`flex justify-center`}>
            <button
              className="button_main"
              onClick={(e, done) => {
                this.generateDataEXCEL(outlet_list, done);
              }}
            >
              {loading_csv ? "Loading ..." : "Download Outlet EXCEL"}
            </button>
          </section>
        </div>
      </Modal>
    );

    let dropDown;
    if (this.state.checkArea === "All" && this.state.checkArea.length > 0) {
      dropDown = (
        <>
          <p className="text_inferior">Mode closed</p>
          <select
            name="mode_closed"
            value={mode_closed}
            onChange={this.on_change}
          >
            <option value="without_closed">without_closed</option>
            <option value="with_closed">with_closed</option>
          </select>

          <br />

          <p className="text_inferior">Mode omset</p>
          <select
            name="mode_omset"
            value={mode_omset}
            onChange={this.on_change}
          >
            <option value="with_omset">with_omset</option>
            <option value="without_omset">without_omset</option>
          </select>
        </>
      );
    }

    if (this.state.checkArea !== "All" && this.state.checkArea.length > 0) {
      dropDown = (
        <section className="mb-4">
          <p>
            Select {""}
            {area === "" ? "Detail Area" : area}
          </p>
          <Select
            options={salesman_filter}
            isMulti
            onChange={this.handle_change}
          />
          <p className="text_inferior">Mode closed</p>
          <select
            name="mode_closed"
            value={mode_closed}
            onChange={this.on_change}
          >
            <option value="without_closed">without_closed</option>
            <option value="with_closed">with_closed</option>
          </select>

          <br />

          <p className="text_inferior">Mode omset</p>
          <select
            name="mode_omset"
            value={mode_omset}
            onChange={this.on_change}
          >
            <option value="with_omset">with_omset</option>
            <option value="without_omset">without_omset</option>
          </select>
        </section>
      );
    }

    const popUpBloops = modalBloops && (
      <Modal
        modalSize="small"
        isOpen={modalBloops}
        onClose={() => this.setState({ modalBloops: false })}
      >
        <div className="box-body">
          <BloopTokos mode={this.state.mode} />
        </div>
      </Modal>
    );

    return (
      <main className="mt-4">
        {modal_merch_content}
        {modal_agg_content}
        {modalDownloadOutlet}
        <button
          className="button_main"
          onClick={() => this.setState({ modalBloops: true })}
        >
          Download Bloops
        </button>
        {popUpBloops}
        <section className="mb-2">
          <p>Select Depo/Branch/Region</p>
          <Select options={area_list} onChange={this.handle_change} />
        </section>
        {dropDown}

        {loading_status && loading_item === "get_outlet_list_by_area" ? (
          <button className="button" id="grey" style={{ marginBottom: "20px" }}>
            Loading...
          </button>
        ) : (
          <>
            <button
              onClick={this.on_submit.bind(this, "all")}
              className="button mr-3"
              id="green"
              style={{ marginBottom: "20px" }}
            >
              Get Outlets
            </button>
          </>
        )}
        <br />
        {table_list}
        {search}
        {main_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
  auth: state.auth,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  get_outlet_list_by_area,
  turn_on_menu,
  getSalesmanFilter,
  turn_on_menu_status,
  turn_off_menu,
  set_list,
  filter_outlet_by_search,
  clear_get_outlet_list_by_area,
  get_outlet_list_by_all_area,
  set_value_properties,
  inactivate_edit_point_depo,
})(OUTLET_MANAGEMENT_MENU);
