import { combineReducers } from "redux";
import dataReducer from "./dataReducer";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import trackingReducer from "./trackingReducer";
import mapReducer from "./mapReducer";
import propertiesReducer from "./propertiesReducer";
import outletReducer from "./outletReducer";

export default combineReducers({
  data: dataReducer,
  auth: authReducer,
  error: errorReducer,
  tracking: trackingReducer,
  map: mapReducer,
  properties: propertiesReducer,
  outlet: outletReducer,
});
