/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal";

/*REDUX FUNCTION*/
import {
  turn_on_menu_status,
  set_config_menu,
  searchSalesmanStep5,
} from "../../actions/dataActions";
import { create_rayon_edit_outlet } from "../../actions/rayonActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import rayonisasi_rayon_box from "../../validation/rayonisasi_rayon_box";
import rayonisasi_rayon_box_by_value from "../../validation/rayonisasi_rayon_box_by_value";
import rayonisasi_rayon_box_by_qty from "../../validation/rayonisasi_rayon_box_by_qty";

/*NON IMPORT*/

class Step2Run extends Component {
  state = { modal_warning: false, modal_error: false };

  toggle_warning = () => {
    this.setState({ modal_warning: !this.state.modal_warning });
  };

  toggle_error = () => {
    this.setState({ modal_error: !this.state.modal_error });
    this.props.searchSalesmanStep5("");
  };

  rayonisasi_rayon = (mode_timpa) => {
    this.setState({ modal_warning: false });

    const {
      list_menu_active,
      outlet_list_filter_0,
      mode_nama_rayon,
      nama_rayon_custom,
      jumlah_toko,
      mode_pembagian_rayon,
    } = this.props.data;
    const config_plan = list_menu_active.find((e) => e.key === "salesman")
      ?.config?.value
      ? list_menu_active.find((e) => e.key === "salesman")?.config?.value
      : [];
    let body = {};

    let result;

    //bedakan algoritma yang dipanggil berdasarkan state mode pembagian yang sedang aktif
    if (mode_pembagian_rayon === "jumlah_toko") {
      body = {
        jumlah_toko,
        mode_sisa_toko_rayon: "rata",
        toko_list_main: outlet_list_filter_0,
        depo_loc_list: config_plan,
        mode_first: "v_first",

        mode_nama_rayon,
        nama_rayon_custom,
      };
      result = rayonisasi_rayon_box(body);
    } else if (mode_pembagian_rayon === "jumlah_omset") {
      body = {
        toko_list_main: outlet_list_filter_0,
        depo_loc_list: config_plan,
        mode_first: "v_first",

        mode_nama_rayon,
        nama_rayon_custom,
      };
      result = rayonisasi_rayon_box_by_value(body);
    } else {
      body = {
        toko_list_main: outlet_list_filter_0,
        depo_loc_list: config_plan,
        mode_first: "v_first",

        mode_nama_rayon,
        nama_rayon_custom,
      };
      result = rayonisasi_rayon_box_by_qty(body);
    }

    const { rayon_list_complete } = result;
    let config_toko = list_menu_active.find(
      (e) => e.key === "distribution"
    ).config;
    config_toko.mode = "depo";
    // this.props.set_config_menu("distribution", config_toko);

    config_toko.mode = "rayon";
    // this.props.set_config_menu("distribution", config_toko);

    this.props.turn_on_menu_status("rayon");
    this.props.create_rayon_edit_outlet({ rayon_list_complete, mode_timpa });
  };

  render() {
    //local state
    const { modal_error, modal_warning } = this.state;

    //global props
    const {
      loading_status,
      // loading_item,
      outlet_list_filter_0_back_up,
      // preview_rayon_list,
    } = this.props.data;

    //content

    let percent_rayon = 0;
    const outlet_length_with_rayon = outlet_list_filter_0_back_up.filter(
      (t) => t.rayon_id
    ).length;
    const outlet_length = outlet_list_filter_0_back_up.length;

    if (outlet_length > 0) {
      percent_rayon = parseInt(
        (outlet_length_with_rayon / outlet_length) * 100
      );
    }

    let button;

    if (loading_status) {
      button = (
        <button className="button mt-3" id="grey">
          Running...
        </button>
      );
    } else if (outlet_list_filter_0_back_up.length === 0) {
      button = (
        <button className="button" id="grey" onClick={this.toggle_error}>
          Run & Save
        </button>
      );
    } else if (
      outlet_length - outlet_length_with_rayon > 0 &&
      outlet_length_with_rayon > 0
    ) {
      button = (
        <button className="button" id="grey" onClick={this.toggle_warning}>
          Info
        </button>
      );
    } else if (percent_rayon === 100) {
      button = (
        <>
          <button className="button" id="green" onClick={this.toggle_warning}>
            Rayon area saved
          </button>
          <p className="text_inferior">Please continue on the next step</p>
        </>
      );
    } else {
      button = (
        <button className="button" id="green" onClick={this.rayonisasi_rayon}>
          Run & Save
        </button>
      );
    }

    const modal_warning_content = modal_warning && (
      <Modal
        modalSize="small"
        isOpen={modal_warning}
        onClose={this.toggle_warning}
      >
        <div className="box-body text_center">
          Rayonization has been done on this Depo, are you sure you want to run
          it again?
          <br />
          (Current rayonization data will overwrite previous data)
          <br />
          <button
            className="button mt-3"
            id="green"
            onClick={this.rayonisasi_rayon.bind(this, "overwrite")}
          >
            Run & overwrite
          </button>
        </div>
      </Modal>
    );

    const modal_error_content = modal_error && (
      <Modal modalSize="small" isOpen={modal_error} onClose={this.toggle_error}>
        <div className="box-body text_center">
          Please complete the previous step
        </div>
      </Modal>
    );

    return (
      <>
        {modal_warning_content}
        {modal_error_content}
        {button}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, {
  set_config_menu,
  searchSalesmanStep5,
  turn_on_menu_status,
  create_rayon_edit_outlet,
})(Step2Run);
