/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  set_list,
  set_config_mode_menu,
  turn_off_menu,
} from "../../actions/dataActions";
import { set_value } from "../../actions/mapActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import sort_array from "../../validation/sort_array";
import generate_array_color from "../../validation/generate_array_color";
import rayonisasi_rayon_by_grouping from "../../validation/rayonisasi_rayon_by_grouping";

/*NON IMPORT*/

class STEP_2_RUN_MODE_GROUPING extends Component {
  state = {};

  on_run = () => {
    const {
      list_menu_active,
      outlet_list_filter_0,
      mode_nama_rayon,
      nama_rayon_custom,
      jumlah_toko,
      jumlah_rayon,
    } = this.props.data;
    const config_plan = list_menu_active.find((e) => e.key === "salesman")
      ?.config?.value
      ? list_menu_active.find((e) => e.key === "salesman")?.config?.value
      : [];

    const { grouping_list } = this.props.map;
    const body = {
      grouping_list,
      jumlah_toko,
      jumlah_rayon,
      depo_loc_list: config_plan,
      mode_nama_rayon,
      nama_rayon_custom,
      outlet_list_filter_0,
      total_toko: outlet_list_filter_0.length,
    };

    const result = rayonisasi_rayon_by_grouping(body);

    const rayon_list_complete = result.rayon_list_complete;
    this.props.set_value({
      key: "rayon_list_complete",
      value: rayon_list_complete,
    });
  };

  on_change_props = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.props.set_list({ text: name, list: value });
    if (name === "jumlah_toko") {
      const { outlet_list_filter_0 } = this.props.data;
      const jumlah_rayon = Math.ceil(outlet_list_filter_0?.length / value);
      this.props.set_list({ text: "jumlah_rayon", list: jumlah_rayon });
    } else if (name === "jumlah_rayon") {
      const { outlet_list_filter_0 } = this.props.data;
      const jumlah_toko = Math.ceil(outlet_list_filter_0?.length / value);
      this.props.set_list({ text: "jumlah_toko", list: jumlah_toko });
    }
  };

  on_generate_grouping_list = () => {
    const { outlet_list_filter_0, mode_grouping } = this.props.data;

    let grouping_list = [
      ...new Set(outlet_list_filter_0.map((item) => item[mode_grouping])),
    ];
    grouping_list = grouping_list.map((item) => {
      //ambil outlet_list
      const outlet_list = outlet_list_filter_0.filter(
        (outlet) => outlet[mode_grouping] === item
      );
      //ambil titik tengah dari kelurahan berdasarkan persebaran toko
      let long = outlet_list.map((t) => t.location.coordinates[0]);
      let lat = outlet_list.map((t) => t.location.coordinates[1]);
      long = long.reduce((p, a) => p + a, 0) / long.length;
      lat = lat.reduce((p, a) => p + a, 0) / lat.length;
      return {
        name: item,
        outlet_length: outlet_list.length,
        location: { type: "Point", coordinates: [long, lat] },
      };
    });
    grouping_list = sort_array(grouping_list, "outlet_length", false);

    this.props.set_value({ key: "grouping_list", value: grouping_list });
    this.props.set_config_mode_menu("distribution", mode_grouping);
    this.props.turn_off_menu("rayon");
  };

  render() {
    //local state

    //global props
    const {
      mode_grouping, //KELURAHAN || KECAMATAN
      jumlah_toko,
      jumlah_rayon,
      outlet_list_filter_0,
    } = this.props.data;
    const { grouping_list, rayon_list_complete } = this.props.map;

    let array_name = grouping_list.map((item) => item.name);
    array_name = array_name.length > 0 ? array_name : ["tes"];
    const array_color = generate_array_color(array_name);

    //content

    return (
      <main className="main_container mt-5 mb-5" id="white">
        <section>
          <p className="text_medium">Select grouping/clusterung parameters</p>
          <select
            name="mode_grouping"
            value={mode_grouping}
            onChange={this.on_change_props}
          >
            <option value="KELURAHAN">SUBDISTRICT</option>
            <option value="KECAMATAN">DISTRICT</option>
          </select>
          <button
            className="button_small"
            id="green"
            onClick={this.on_generate_grouping_list}
          >
            Generate group
          </button>
        </section>
        <section>
          {grouping_list.length > 0 && (
            <main>
              <p>
                Classify to: <b>{mode_grouping}</b>
              </p>
              <hr />
              <section className="preview_scroll">
                <table className="table text_inferior full_width">
                  <tbody>
                    {grouping_list.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            <div
                              className="button_small"
                              style={{
                                backgroundColor: array_color[idx],
                                height: "10px",
                              }}
                            />
                          </td>
                          <td>{item.name}</td>
                          <td>{item.outlet_length}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </section>
              <hr />
              <section>
                <table className="table full_width">
                  <tbody>
                    <tr>
                      <td className="text_inferior">
                        Set the number of stores per rayon
                      </td>
                      <td className="text_right">
                        <input
                          className="text_right"
                          name="jumlah_toko"
                          id="jumlah_toko"
                          value={jumlah_toko}
                          onChange={this.on_change_props}
                          type="number"
                          min="1"
                          style={{ width: "100px" }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text_inferior">
                        Or set the number of rayons
                      </td>
                      <td className="text_right">
                        <input
                          className="text_right"
                          name="jumlah_rayon"
                          id="jumlah_rayon"
                          value={
                            jumlah_rayon === 0
                              ? Math.ceil(
                                  outlet_list_filter_0?.length / jumlah_toko
                                )
                              : jumlah_rayon
                          }
                          onChange={this.on_change_props}
                          type="number"
                          min="1"
                          style={{ width: "100px" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>

              <hr />

              <button className="button" id="green" onClick={this.on_run}>
                Run rayonization by grouping
              </button>

              <hr />

              <section className="preview_scroll">
                <table className="table text_inferior full_width">
                  <tbody>
                    {rayon_list_complete.map((item, idx) => {
                      const list = item?.list || [];

                      return (
                        <tr key={idx}>
                          <td>
                            {item.name}
                            <br />
                            {list.map((kec) => {
                              return (
                                <div
                                  className="button_small mr-1 mb-1"
                                  id="green"
                                >
                                  {kec.name}:{kec.outlet_length}
                                </div>
                              );
                            })}
                          </td>
                          <td>{item.outlet_length}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </section>

              <br />
              <button className="button" id="green">
                Save rayons by grouping (coming soon)
              </button>
            </main>
          )}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  map: state.map,
});

export default connect(mapStateToProps, {
  set_list,
  set_value,
  set_config_mode_menu,
  turn_off_menu,
})(STEP_2_RUN_MODE_GROUPING);
