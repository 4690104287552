//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
//Redux function
import { get_aggregate_outlet } from "../../actions/dataActions";
import sort_array from "../../validation/sort_array";
//Picture Asset
//General Function
class Aggregate extends Component {
  state = {
    idx_c: 0,
  };
  on_get_aggregate_outlet = (mode) => {
    let body = {};
    if (mode === "depo_nabati") {
      body = {
        object_query: {
          "Status Outlet": { $ne: "competitor" },
          COUNTRY: "INDONESIA",
        },
        param_agg: "Depo",
      };
    }

    if (mode === "depo_competitor") {
      body = {
        object_query: {
          "Status Outlet": "competitor",
          COUNTRY: "INDONESIA",
        },
        param_agg: "Depo",
      };
    }

    if (mode === "location_status") {
      body = {
        object_query: {
          COUNTRY: "INDONESIA",
        },
        param_agg: "location_status",
      };
    }

    this.props.get_aggregate_outlet(body);
  };
  render() {
    const { agg_list, loading_status, loading_item } = this.props.data;
    const { idx_c } = this.state;
    const agg_object = agg_list[idx_c];
    const list_parent_content = (
      <main style={{ textAlign: "center" }}>
        {agg_list.map((i, idx) => {
          const body = i.body;
          const object_query = body.object_query;
          const param_agg = body.param_agg;
          let note = [];
          Object.entries(object_query).forEach(([key, value], idx) =>
            note.push(
              <section key={idx}>
                {String(key)}: {JSON.stringify(value)}
              </section>
            )
          );
          return (
            <main
              key={idx}
              className="mt-1 mb-1 ml-1 mr-1 main_container_outline"
              style={{ width: "220px" }}
            >
              <button className="button" id="green">
                {idx}
              </button>
              <section className="text_left">
                <p className="text_bold">Filter by</p>
                <section className="text_left text_inferior">{note}</section>

                <br />
                <p className="text_bold">Value to be aggregate</p>
                <p className="text_left text_inferior">{param_agg}</p>
              </section>
            </main>
          );
        })}
      </main>
    );
    let result = agg_object?.result ? agg_object.result : [];
    result = sort_array(result, "_id", true);
    // const body = i.result;
    const list_content = (
      <main className="text_center">
        {result.map((r, idx) => {
          return (
            <main
              key={idx}
              style={{
                width: "200px",
                height: "80px",
                display: "inline-block",
                verticalAlign: "top",
                textAlign: "left",
              }}
              className="mt-1 mb-1 ml-1 mr-1"
            >
              <main className="main_container" style={{ height: "100%" }}>
                <h1 className="text_inferior">{r?._id ? r?._id : "-"}</h1>
                <p className="text_bold">
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(r?.SUM)}
                </p>
              </main>
            </main>
          );
        })}
      </main>
    );
    return (
      <main>
        <h1 className="text_bold">Aggregate</h1>
        <button
          className="button mr-2 mb-2"
          id="green"
          onClick={this.on_get_aggregate_outlet.bind(this, "depo_competitor")}
        >
          Aggregate Competitor Outlet By Depo
        </button>
        <button
          className="button mr-2 mb-2"
          id="green"
          onClick={this.on_get_aggregate_outlet.bind(this, "depo_nabati")}
        >
          Aggregate Nabati Outlet By Depo
        </button>
        <button
          className="button mr-2 mb-2"
          id="green"
          onClick={this.on_get_aggregate_outlet.bind(this, "location_status")}
        >
          Aggregate Outlet Location Status
        </button>
        {list_parent_content}
        {loading_status && loading_item === "get_aggregate_outlet" && (
          <main>Loading...</main>
        )}
        {list_content}
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, { get_aggregate_outlet })(Aggregate);
