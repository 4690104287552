/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { bbox } from "@turf/turf";
import { CSVLink } from "react-csv";
import writeXlsxFile from "write-excel-file";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal";
import DownloadTracking from "../parent/DownloadTracking";
import TOOL_RAYON_OUTLET_INTERNAL from "./TOOL_RAYON_OUTLET_INTERNAL";

/*REDUX FUNCTION*/
import {
  set_legend_color_toko_by_salesman,
  set_config_mode_menu,
  reset_distribution_toko,
  filter_siklus_toko,
  filter_siklus_toko_all,
  get_outlet_list_by_area,
  turn_on_menu,
  ambilWaktu,
} from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL*/
import isEmpty from "../../validation/is-empty";
import formatDateFormal from "../../validation/format_date_formal";
import isEqual from "../../validation/is-equal";

const nonNational = ["REGIONAL", "CABANG", "Depo"];
const onlineMode = [
  {
    bgColor: "bg-green-600",
    stat: "Online less than an hour",
    key: "bike_green",
  },
  {
    bgColor: "bg-blue-600",
    stat: "Online a few hours ago",
    key: "bike_blue",
  },
  {
    bgColor: "bg-red-600",
    stat: "Last online a day ago",
    key: "bike_red",
  },
];

export class ToolSalesman extends Component {
  state = {
    dataCSV: [],
    loading: false,
    separator: ";",
    modal_tracking_bulanan: false,
    state_modal_download_log_by_depo: false,
    siklus: "ALL",
    downloadDaily: false,
    downloadTarget: false,
    downloadSalesman: false,
    mode: "",
    dataCSVSalesman: [],
  };

  componentDidUpdate(prevProps) {
    const sidebarW = parseInt(localStorage.getItem("sidebar-width") || 500);
    if (
      !isEqual(
        prevProps.data.outlet_list_filter_0,
        this.props.data.outlet_list_filter_0
      ) &&
      this.props.data.outlet_list_filter_0.length > 0
    ) {
      const { map } = this.props.map;
      const new_features = this.props.data.outlet_list_filter_0.map((e) => {
        return {
          type: "Feature",
          properties: { ...e },
          geometry: e.location,
        };
      });
      const geojson = {
        type: "FeatureCollection",
        features: [...new_features],
      };
      let [minLng, minLat, maxLng, maxLat] = bbox(geojson);
      map.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: { top: 200, bottom: 200, left: sidebarW + 50, right: 500 },
        }
      );
    }
  }

  on_submit_get_toko_list = () => {
    const { list_menu_active } = this.props.data;
    const config_plan = list_menu_active.find((e) => e.key === "salesman")
      ?.config?.value;
    const name = config_plan.map((e) => e.value);
    const name_arr = config_plan.map((e) => {
      return {
        key: e.value,
        label: e.value,
        value: e.value,
        name: "name",
      };
    });
    const params = {
      area: "depo",
      name,
      name_arr,
    };
    this.props.get_outlet_list_by_area(params);
    this.props.turn_on_menu({ key: "distribution", config: params });
  };

  on_auto_route_plan = () => {
    let { legend_color_toko_by_salesman, outlet_list_filter_0 } =
      this.props.data;
    let toko_list = outlet_list_filter_0;
    const average_toko = parseInt(
      outlet_list_filter_0.length / legend_color_toko_by_salesman.length
    );
    //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
    function calc_crow(lat1, lon1, lat2, lon2) {
      const R = 6371; // km
      const dLat = to_rad(lat2 - lat1);
      const dLon = to_rad(lon2 - lon1);
      const lat_1 = to_rad(lat1);
      const lat_2 = to_rad(lat2);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
          Math.sin(dLon / 2) *
          Math.cos(lat_1) *
          Math.cos(lat_2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c;
      return d;
    }
    // Converts numeric degrees to radians
    function to_rad(Value) {
      return (Value * Math.PI) / 180;
    }
    const sort_array = function (array, key, is_from_small) {
      if (is_from_small) {
        function compare(a, b) {
          if (a[key] < b[key]) return -1;
          if (a[key] > b[key]) return 1;
          return 0;
        }
        return array.sort(compare);
      } else {
        function compare(a, b) {
          if (a[key] < b[key]) return 1;
          if (a[key] > b[key]) return -1;
          return 0;
        }
        return array.sort(compare);
      }
    };
    for (let index = 0; index < legend_color_toko_by_salesman.length; index++) {
      //nama atribut = kode_new
      //atribut lama = salesman_color;
      const salesman_color = legend_color_toko_by_salesman[index].kode;
      let toko_list_undone = toko_list.map((toko, index) => {
        toko.index = index;
        return toko;
      });
      toko_list_undone = toko_list_undone.filter((toko) => !toko.kode_new);
      if (index < legend_color_toko_by_salesman.length) {
        //nama atribut = kode_new
        //atribut lama = salesman_color;
        toko_list_undone = toko_list_undone.map((toko) => {
          const jarak_pojok = calc_crow(
            20.298626098167475,
            81.61835965538779,
            toko.location.coordinates[1],
            toko.location.coordinates[0]
          );
          toko.jarak_pojok = jarak_pojok;
          return toko;
        });
        toko_list_undone = sort_array(toko_list_undone, "jarak_pojok", true);
        const first_toko = toko_list_undone[0];
        const is_pojok_kiri_atas = (toko) => {
          if (
            toko?.location?.coordinates?.[1] ===
              first_toko?.location?.coordinates?.[1] &&
            toko?.location?.coordinates?.[0] ===
              first_toko?.location?.coordinates?.[0]
          ) {
            return true;
          }
        };
        const first_index = outlet_list_filter_0.findIndex(is_pojok_kiri_atas);
        toko_list[first_index].kode_new = salesman_color;
        toko_list_undone = toko_list.filter((toko) => !toko.kode_new);
        toko_list_undone = toko_list_undone.map((toko) => {
          const distance = calc_crow(
            toko.location.coordinates[1],
            toko.location.coordinates[0],
            first_toko.location.coordinates[1],
            first_toko.location.coordinates[0]
          );
          const result = { index: toko.index, distance };
          return result;
        });
        toko_list_undone = sort_array(toko_list_undone, "distance", true);
        toko_list_undone = toko_list_undone.slice(0, average_toko);
        toko_list_undone.map((toko) => {
          return (toko_list[toko.index].kode_new = salesman_color);
        });
      } else {
        toko_list_undone.map((toko) => {
          return (toko_list[toko.index].kode_new = salesman_color);
        });
      }
    }
    this.props.set_config_mode_menu("distribution", "salesman_color_new");
  };

  on_change = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({
      [name]: value,
    });
    if (value !== "ALL") {
      this.props.filter_siklus_toko(value);
    } else {
      this.props.filter_siklus_toko_all(value);
    }
  };

  on_reset_distribution_toko = () => {
    this.props.reset_distribution_toko();
  };

  on_set_color_toko = (array) => {
    let divisi = ["TX2D1"];
    divisi = divisi?.[0] ? divisi[0] : "";
    this.props.set_legend_color_toko_by_salesman({ array, div: divisi });
    this.props.set_config_mode_menu("distribution", "salesman_color");
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  generateDataCSV = (data, done) => {
    if (!this.state.loading) {
      this.setState({
        loading: true,
      });

      const {
        title,
        totalSalesman,
        currDate,
        totalSalesmanRegister,
        totalSalesmanLocation,
        totalStatus,
      } = data;
      const { salesman_list } = this.props.data;

      try {
        let tempData = [];
        // const self = this;
        // const header =
        //   Object.keys(salesman_list[0])?.filter((k) => !hideAttr.includes(k)) ||
        //   [];
        const header = [
          "Employee ID",
          "Name",
          "Depo",
          "Status",
          "Kode Sales",
          "Kode Plan",
          "Divisi",
        ];

        tempData.push(header);
        for (let i = 0; i < salesman_list.length; i++) {
          const element = salesman_list[i];
          // const item = header.map((key) =>
          //   JSON.stringify(salesman_list[i][key])
          // );
          let status = "";
          let kodeSales = element.kd_sales_arr[0];
          let divisi = element.divisi_arr[0];
          const status_bike = element["status_bike"];
          if (status_bike === "bike_red") {
            status = "Last online a day ago";
          }
          if (status_bike === "bike_blue") {
            status = "Online a few hours ago";
          }
          if (status_bike === "bike_green") {
            status = "Online";
          }
          tempData.push([
            element["NIK"] ?? "-",
            element["Nama Only"] ?? "-",
            element["Depo"] ?? "-",
            status ?? "-",
            kodeSales ?? "-",
            element["Kode Plan"] ?? "-",
            divisi ?? "-",
          ]);
        }
        tempData.push([...title.split(":")]);
        tempData.push(["Access Date: ", currDate]);
        // tempData.push(["Registered Salesman: ", totalSalesmanRegister]);
        tempData.push([
          "Existing Salesman On Location: ",
          totalSalesmanLocation,
        ]);
        for (let i = 0; i < onlineMode.length; i++) {
          const { stat, key } = onlineMode[i];
          tempData.push([stat, totalStatus[key]]);
        }
        this.setState({ dataCSV: tempData, loading: false });
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    }
  };
  generateDataExcel = async (data, done) => {
    if (!this.state.loading) {
      this.setState({
        loading: true,
      });

      const {
        title,
        totalSalesman,
        currDate,
        totalSalesmanRegister,
        totalSalesmanLocation,
        totalStatus,
      } = data;
      const { salesman_list } = this.props.data;
      try {
        const schema = [
          {
            column: "Salesman Name",
            type: String,
            value: (salesman) => salesman["Nama Salesman"],
          },
          {
            column: "Employee ID",
            type: String,
            value: (salesman) => salesman.NIK,
          },
          {
            column: "Depo",
            type: String,
            value: (salesman) => salesman.Depo,
          },
          {
            column: "Status",
            type: String,
            value: (salesman) => salesman.status,
          },
          {
            column: "Salesman Code",
            type: String,
            value: (salesman) => salesman.kodeSales,
          },
          {
            column: "Plan",
            type: String,
            value: (salesman) => salesman["Kode Plan"],
          },
          {
            column: "Divisi",
            type: String,
            value: (salesman) => salesman.divisi,
          },
        ];

        let newData = [];
        salesman_list.forEach((e) => {
          let status = "";
          let kodeSales = e.kd_sales_arr[0].toString();
          let divisi = e.divisi_arr[0];
          const status_bike = e["status_bike"];
          if (status_bike === "bike_red") {
            status = "Last online a day ago";
          }
          if (status_bike === "bike_blue") {
            status = "Online a few hours ago";
          }
          if (status_bike === "bike_green") {
            status = "Online";
          }
          let getData = {
            "Nama Salesman": e["Nama Salesman"],
            NIK: e.NIK,
            Depo: e.Depo,
            status: status,
            kodeSales: kodeSales,
            "Kode Plan": e["Kode Plan"],
            divisi: divisi,
          };
          newData.push(getData);
        });

        newData = [
          ...newData,
          { "Nama Salesman": title },
          { "Nama Salesman": `Access Date: ${currDate}` },
          // {
          //   "Nama Salesman": `Registered Salesman: ${totalSalesmanRegister}`,
          // },
          {
            "Nama Salesman": `Existing Salesman On Location: ${totalSalesmanLocation}`,
          },
          {
            "Nama Salesman": `Online less than an hour: ${totalStatus.bike_green}`,
          },
          {
            "Nama Salesman": `Online a few hours ago: ${totalStatus.bike_blue}`,
          },
          {
            "Nama Salesman": `Last online a day ago: ${totalStatus.bike_red}`,
          },
        ];
        await writeXlsxFile(newData, {
          schema,
          fileName: "DataExcelDailySalesman.xlsx",
        });
        this.setState({
          loading: false,
        });
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    }
  };
  generateDataCSVSalesman = () => {
    this.setState({
      loading: true,
    });
    const { backup_salesman_list } = this.props.data;
    const { original_depo } = this.props.data.salesman_filter;
    const header = [
      "_id",
      "DISTRIBUTOR/DEPO",
      "NIK",
      "SALESMAN CODE",
      "PLAN CODE",
      "SALESMAN NAME",
      "CONTACT NUMBER",
      "DIVISION",
      "COUNTRY",
      "USERNAME",
    ];
    let body = [];
    body.push(header);
    backup_salesman_list.forEach((e) => {
      let depo = e["Depo"] ?? "-";
      let kode_plan = "not found";
      if (depo !== "-") {
        const depo_find = original_depo.find((e) => e.Depo === depo);
        kode_plan = depo_find["plan_id"] ?? "not found";
      }
      let username = e["user"] ? e["user"] : "-";
      // .map((e) => e.name).join("|")
      username = username.map((e) => e.name);
      username = [...new Set(username)];
      username = username.join("-");
      body.push([
        e["_id"] ?? "-",
        e["Depo"] ?? "-",
        e["NIK"] ?? "-",
        e["kd_sales_arr"] ? e["kd_sales_arr"].join("|") : "-",
        kode_plan,
        e["Nama Salesman"] ?? "-",
        e["phone_number"] ?? "-",
        e["divisi_arr"] ? e["divisi_arr"].join("|") : "-",
        e["country"] ?? "-",
        username,
      ]);
    });

    this.setState({
      loading: false,
      dataCSVSalesman: body,
    });
  };

  generateDataEXCELSalesman = async () => {
    this.setState({
      loading: true,
    });
    const { backup_salesman_list } = this.props.data;
    const { original_depo } = this.props.data.salesman_filter;
    try {
      const schema = [
        {
          column: "_id",
          type: String,
          value: (salesman) => salesman._id,
        },
        {
          column: "DISTRIBUTOR/DEPO",
          type: String,
          value: (salesman) => salesman.depo,
        },
        {
          column: "NIK",
          type: String,
          value: (salesman) => salesman.nik,
        },
        {
          column: "SALESMAN CODE",
          type: String,
          value: (salesman) => salesman.kodeSalesman,
        },
        {
          column: "PLAN CODE",
          type: String,
          value: (salesman) => salesman.kodePlan,
        },
        {
          column: "SALESMAN NAME",
          type: String,
          value: (salesman) => salesman.namaSalesman,
        },
        {
          column: "CONTACT NUMBER",
          type: String,
          value: (salesman) => salesman.kontakHp,
        },
        {
          column: "DIVISION",
          type: String,
          value: (salesman) => salesman.divisi,
        },
        {
          column: "COUNTRY",
          type: String,
          value: (salesman) => salesman.country,
        },
        {
          column: "USERNAME",
          type: String,
          value: (salesman) => salesman.userName,
        },
      ];

      let newData = [];
      backup_salesman_list.forEach((e) => {
        let depo = e["Depo"] ?? "-";
        let kode_plan = "not found";
        if (depo !== "-") {
          const depo_find = original_depo.find((e) => e.Depo === depo);
          kode_plan = depo_find.plan_id ?? "not found";
        }

        let username = e.user ? e.user : "-";
        // .map((e) => e.name).join("|")
        username = username.map((e) => e.name);
        username = [...new Set(username)];
        username = username.join("-");
        let getData = {
          _id: e._id ?? "-",
          depo: e.Depo ?? "-",
          nik: e.NIK ?? "-",
          kodeSalesman: e.kd_sales_arr ? e.kd_sales_arr.join("|") : "-",
          kodePlan: kode_plan,
          namaSalesman: e["Nama Salesman"] ?? "-",
          kontakHp: e.phone_number ?? "-",
          divisi: e.divisi_arr ? e.divisi_arr.join("|") : "-",
          country: e.country ?? "-",
          userName: username,
        };
        newData.push(getData);
      });
      await writeXlsxFile(newData, {
        schema,
        fileName: "DataExcelSalesman.xlsx",
      });
      this.setState({
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
  ambilWaktu = () => {
    let startOnline = new Date().getTime();
    let endOnline = new Date().getTime() - 3_600_000;
    startOnline = new Date(startOnline).getHours();
    endOnline = new Date(endOnline).getHours();
    let params = { startOnline, endOnline };
    this.props.ambilWaktu(params);
  };

  render() {
    let {
      // loading_status,
      // loading_item,
      salesman_filter_mode,
      // salesman_filter_val,
      salesman_total_count,
      loading,
      // selectedFilter1Obj,
      // list_menu_active,
      // salesman_list,
      // outlet_list_filter_0,
      selectedFilter2Obj,
    } = this.props.data;
    const map_toko_to_salesman_content = <TOOL_RAYON_OUTLET_INTERNAL />;
    const {
      dataCSV,
      separator,
      state_modal_download_log_by_depo,
      downloadSalesman,
    } = this.state;
    const isNational = nonNational.includes(salesman_filter_mode);
    const title = isNational
      ? `Name ${this.capitalizeFirstLetter(
          salesman_filter_mode
        )} : ${selectedFilter2Obj.map((e) => e.label).join()}`
      : `National`;
    const currDate = formatDateFormal(Date.now());
    const totalSalesman = !isNational
      ? this.props.data.backup_salesman_list?.length
      : this.props.data.backup_salesman_list?.length || 0;
    const totalSalesmanRegister =
      this.props.data.backup_salesman_list.filter(
        (e) => e["status_register"] !== "Belum Register"
      )?.length || 0;
    const totalSalesmanLocation =
      this.props.data.backup_salesman_list.filter(
        (e) => !isEmpty(e["location"]["coordinates"])
      )?.length || 0;

    const registerPercent = parseInt(
      (totalSalesmanRegister / totalSalesman) * 100
    );
    const locationPercent = parseInt(
      (totalSalesmanLocation / totalSalesman) * 100
    );
    let totalStatus = {};
    onlineMode.map(
      (m) =>
        (totalStatus[m.key] =
          this.props.data.backup_salesman_list.filter(
            (e) => e["status_bike"] === m.key
          )?.length || 0)
    );
    const modal_download_log_by_depo = state_modal_download_log_by_depo && (
      <Modal
        modalSize="small"
        isOpen={state_modal_download_log_by_depo}
        onClose={() =>
          this.setState({ state_modal_download_log_by_depo: false })
        }
      >
        <div className="box-body">
          <DownloadTracking mode={this.state.mode} />
        </div>
      </Modal>
    );
    const modalDownloadDaily = this.state.downloadDaily && (
      <Modal
        modalSize="small"
        isOpen={this.state.downloadDaily}
        onClose={() =>
          this.setState({ downloadDaily: !this.state.downloadDaily })
        }
      >
        <div className="box-body">
          <h1 className="text-center font-bold">
            Download Daily Active by CSV
          </h1>
          <section className={`flex justify-center mb-3`}>
            <CSVLink
              className="button_main mx-2"
              data={dataCSV}
              filename={`Data Salesman_${title}_${currDate}.csv`}
              onClick={(e, done) => {
                this.generateDataCSV(
                  {
                    title,
                    totalSalesman,
                    currDate,
                    totalSalesmanRegister,
                    totalSalesmanLocation,
                    totalStatus,
                  },
                  done
                );
              }}
              separator={separator}
            >
              {loading ? "Downloading data . . ." : "Download Daily CSV"}
            </CSVLink>
          </section>
          <h1 className="text-center font-bold">
            Download Daily Active by EXCEL
          </h1>
          <section className={`flex justify-center`}>
            <button
              className="button_main"
              onClick={(e, done) => {
                this.generateDataExcel(
                  {
                    title,
                    totalSalesman,
                    currDate,
                    totalSalesmanRegister,
                    totalSalesmanLocation,
                    totalStatus,
                  },
                  done
                );
              }}
            >
              {loading ? "Downloading data . . ." : "Download Daily EXCEL"}
            </button>
          </section>
        </div>
      </Modal>
    );
    const modalDownloadTarget = this.state.downloadTarget && (
      <Modal
        modalSize="small"
        isOpen={this.state.downloadTarget}
        onClose={() =>
          this.setState({ downloadTarget: !this.state.downloadTarget })
        }
      >
        <div className="box-body">
          <h1 className="text-center font-bold">
            Download Target vs Actual Working Hours by CSV
          </h1>
          <section className={`flex justify-center mb-3`}>
            <button
              className="button_main"
              onClick={() =>
                this.setState({
                  state_modal_download_log_by_depo: true,
                  mode: "csv",
                })
              }
            >
              Download Target vs Actual CSV
            </button>
          </section>{" "}
          <h1 className="text-center font-bold">
            Download Target vs Actual Working Hours by EXCEL
          </h1>
          <section className={`flex justify-center`}>
            <button
              className="button_main"
              onClick={() =>
                this.setState({
                  state_modal_download_log_by_depo: true,
                  mode: "excel",
                })
              }
            >
              Download Target vs Actual EXCEL
            </button>
          </section>
        </div>
      </Modal>
    );
    const modalDownloadSalesman = downloadSalesman && (
      <Modal
        modalSize="small"
        isOpen={downloadSalesman}
        onClose={() => this.setState({ downloadSalesman: !downloadSalesman })}
      >
        <div className="box-body">
          <h1 className="text-center font-bold">Download Salesman by CSV</h1>
          <section className={`flex justify-center mb-3`}>
            <CSVLink
              className="button_main mx-2"
              data={this.state.dataCSVSalesman}
              filename={`Data Salesman.csv`}
              onClick={(e, done) => {
                this.generateDataCSVSalesman({}, done);
              }}
              separator={separator}
            >
              {loading ? "Downloading data . . ." : "Download Salesman CSV"}
            </CSVLink>
          </section>{" "}
          <h1 className="text-center font-bold">Download Salesman by EXCEL</h1>
          <section className={`flex justify-center`}>
            <button
              className="button_main"
              onClick={(e, done) => {
                this.generateDataEXCELSalesman({}, done);
              }}
            >
              Download Salesman EXCEL
            </button>
          </section>
        </div>
      </Modal>
    );
    // let downloadButton = "";
    // if (
    //   this.props.redux_data.selectedFilter1Obj.label === "Active Salesman" ||
    //   this.props.redux_data.selectedFilter1Obj.label === "Online Salesman"
    // ) {
    //   downloadButton = (
    //     <section className="text-center">
    //       Online Salesman And Active Salesman didn't have buttons download
    //       anymore
    //     </section>
    //   );
    // } else {
    //   downloadButton = (
    //     <>
    //       <section className="flex justify-center mb-3">
    //         <button
    //           className="button_main"
    //           onClick={() => this.setState({ downloadDaily: true })}
    //         >
    //           Download Daily Active Status
    //         </button>
    //       </section>
    //       <section className="flex justify-center mb-3">
    //         <button
    //           className="button_main"
    //           onClick={() => this.setState({ downloadTarget: true })}
    //         >
    //           Download Target vs Actual Working Hours
    //         </button>
    //       </section>
    //       <section className="flex justify-center">
    //         <button
    //           className="button_main"
    //           onClick={() => this.setState({ downloadSalesman: true })}
    //         >
    //           Download Salesman
    //         </button>
    //       </section>
    //     </>
    //   );
    // }
    let content = (
      <main className="mb-20">
        <section className="text-lg">
          <h4 className="text-2xl font-bold">{title}</h4>
        </section>
        <section className="text-xs mb-7">
          Access Date: {formatDateFormal(Date.now())}
        </section>
        <section className="mb-5">
          <div>Total Salesman</div>
          <div className="text-5xl">{totalSalesman}</div>
        </section>
        <section>
          {/* <div className="text-s">Total Registered Salesman</div>
          <div className="text-2xl">{totalSalesmanRegister}</div> */}
          {/* <div
            className="w-full rounded-full h-1.5"
            style={{ backgroundColor: "#dedede" }}
          >
            <div
              className=" h-1.5 rounded-full"
              style={{
                width: `${registerPercent}%`,
                backgroundColor: "#16a34aff",
              }}
            />
          </div> */}
        </section>
        <section>
          <div className="text-s">Total existing salesman with location</div>
          <div className="text-2xl">{totalSalesmanLocation}</div>
          <div
            className="w-full rounded-full h-1.5"
            style={{ backgroundColor: "#dedede" }}
          >
            <div
              className=" h-1.5 rounded-full"
              style={{
                width: `${locationPercent}%`,
                backgroundColor: "#16a34aff",
              }}
            />
          </div>
        </section>
        <section className="mt-5">
          {onlineMode.map((m, idx) => (
            <div key={`rsm-${idx}`} className="text-xs">
              <div className="flex my-0.5">
                <div className={`${m.bgColor} w-5 h-5 mr-0.5`}></div>
                <p>
                  <span className="align-middle">
                    {m.stat} : {totalStatus[m.key]}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </section>
        <section className="flex mt-12 w-full flex-col">
          <div className="mt-4 grid grid-cols-2 gap-1 mb-4">
            <div className="flex flex-1 items-center">
              <p>Separator CSV</p>
            </div>
            <div className="w-full">
              <select
                name="separator"
                value={separator}
                onChange={this.handleChange}
                className="w-full"
              >
                <option value=",">, (Comma)</option>
                <option value=";">; (Semicolon)</option>
              </select>
            </div>
          </div>
        </section>
        <>
          <section className="flex justify-center mb-3">
            <button
              className="button_main"
              onClick={() => this.setState({ downloadDaily: true })}
            >
              Download Daily Active Status
            </button>
          </section>
          <section className="flex justify-center mb-3">
            <button
              className="button_main"
              onClick={() => [
                this.ambilWaktu(),
                this.setState({ downloadTarget: true }),
              ]}
            >
              Download Target vs Actual Working Hours
            </button>
          </section>
          <section className="flex justify-center">
            <button
              className="button_main"
              onClick={() => this.setState({ downloadSalesman: true })}
            >
              Download Salesman
            </button>
          </section>
        </>
        {/* {downloadButton} */}
        {modalDownloadDaily}
        {modalDownloadTarget}
        {modalDownloadSalesman}
        {map_toko_to_salesman_content}
      </main>
    );
    return (
      <nav>
        {content}
        {modal_download_log_by_depo}
      </nav>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.data,
  redux_data: state.data,
  tracking: state.tracking,
  map: state.map,
});
export default connect(mapStateToProps, {
  set_legend_color_toko_by_salesman,
  set_config_mode_menu,
  reset_distribution_toko,
  filter_siklus_toko,
  filter_siklus_toko_all,
  get_outlet_list_by_area,
  turn_on_menu,
  ambilWaktu,
})(ToolSalesman);
