/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal";
import STEP_4_2_EXPAND_CYCLE from "./STEP_4_2_EXPAND_CYCLE";

/*REDUX FUNCTION*/
import {
  turn_on_menu_status,
  turn_off_menu,
  set_cycle_list_to_rayon,
} from "../../actions/dataActions";

import { edit_cycle_outlet } from "../../actions/rayonActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import rayonisasi_general from "../../validation/rayonisasi_general";

/*NON IMPORT*/

class STEP_4_1_RUN_CYCLE extends Component {
  state = { modal_error: false, modal_cycle: false, jumlah_cycle: 2 };

  toggle_error = () => {
    this.setState({ modal_error: !this.state.modal_error });
    this.props.searchSalesmanStep5("");
  };

  toggle_cycle = () => {
    this.setState({ modal_cycle: !this.state.modal_cycle });
  };

  rayonisasi_siklus = () => {
    const { jumlah_cycle } = this.state;
    let { outlet_list_filter_0, rayon_select, list_menu_active } =
      this.props.data;
    const text = "cycle";
    const body = {
      toko_list_main: outlet_list_filter_0,
      text: text,
      jumlah_rayon: jumlah_cycle,
    };
    const result = rayonisasi_general(body);

    //sudah tidak perlu gunakan cycle_list yang diluar, gunakan langsung dari rayon_object

    let config_toko = list_menu_active.find(
      (e) => e.key === "distribution"
    ).config;
    config_toko.mode = text;

    this.props.turn_off_menu("rayon");
    this.props.turn_on_menu_status("cycle");
    //save to database
    const body_db = {
      rayon_id: rayon_select,
      cycle_list: result.rayon_list_complete,
    };
    this.props.set_cycle_list_to_rayon(body_db);
    this.setState({ modal_cycle: false });

    const body_cycle = {
      cycle_list: result.rayon_list_complete,
      toko_list: outlet_list_filter_0,
    };

    this.props.edit_cycle_outlet(body_cycle);
  };

  render() {
    //local storage

    //local state
    const { modal_error, modal_cycle } = this.state;

    //global props
    const { rayon_select, rayon_list_database, outlet_list_filter_0 } =
      this.props.data;

    //content
    const outlet_without_cycle = outlet_list_filter_0.filter((t) => !t.cycle);

    let rayon_object = {};
    if (rayon_list_database.length > 0) {
      rayon_object = rayon_list_database.find((r) => r._id === rayon_select);
    }
    const cycle_list = rayon_object?.cycle_list ? rayon_object?.cycle_list : [];

    const modal_error_content = modal_error && (
      <Modal modalSize="small" isOpen={modal_error} onClose={this.toggle_error}>
        <div className="box-body text_center">
          Please complete the previous step
        </div>
      </Modal>
    );

    const modal_cycle_content = modal_cycle && (
      <Modal modalSize="small" isOpen={modal_cycle} onClose={this.toggle_cycle}>
        <div className="box-body text_center">
          Cycle division has been done on this Rayon, are you sure you want to
          run it again?
          <br />
          (Current planning data will overwrite previous data)
          <br />
          <button
            className="button mt-3"
            id="green"
            onClick={this.rayonisasi_siklus}
          >
            Run & overwrite
          </button>
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_error_content}
        {modal_cycle_content}
        <section className="main_container mb-3">
          <p className="badge mb-1" id="blue">
            Step 4.1
          </p>
          <p className="text_medium">Divide into odd-even weeks</p>
          <hr />
          <p className="text_inferior">
            Divide outlets into odd-even week areas, then start planning the odd
            week area first and even weeks respectively.
            <br />
            Note:
            <br />
            cycle 1 : odd week (M2C13)
            <br />
            cycle 2 : even week (M2C24)
          </p>
          <br />
          <table className="table text_inferior" style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td>Cycles in one month:</td>
                <td>2</td>
              </tr>
            </tbody>
          </table>
          {!rayon_select ? (
            <button
              className="button mt-3"
              id="grey"
              onClick={this.toggle_error}
            >
              Run and save
            </button>
          ) : cycle_list.length > 0 ? (
            <button
              className="button mt-3"
              id="red"
              onClick={this.toggle_cycle}
            >
              Overwrite cycle
            </button>
          ) : (
            <button
              className="button mt-3"
              id="green"
              onClick={this.rayonisasi_siklus}
            >
              Run and save
            </button>
          )}
        </section>
        <section className="main_container mb-3">
          <p className="badge mb-1" id="blue">
            Step 4.2
          </p>
          <p className="text_medium">Cycle expansion</p>
          <hr />
          <table className="table full_width mb-5">
            <tbody>
              <tr>
                <td className="text_small">
                  Total outlet without cycle
                  <br />
                  <b>
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(outlet_without_cycle.length)}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
          <STEP_4_2_EXPAND_CYCLE />
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, {
  set_cycle_list_to_rayon,
  edit_cycle_outlet,
  turn_off_menu,
  turn_on_menu_status,
})(STEP_4_1_RUN_CYCLE);
