/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Popup } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_properties } from "../../actions/propertiesActions";
import { inactivate_edit_point_depo } from "../../actions/mapActions";
import { popUpAction } from "../../actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class ON_FLY extends Component {
  state = {};

  on_fly = () => {
    const item = this.props.item;
    const modal_item = this.props.modal_item;

    const { map } = this.props.map;

    this.props.popUpAction(item, "modal_omset");

    this.props.set_value_properties({
      key: "pop_up_properties",
      value: item,
    });
    const center = item?.location?.coordinates;

    if (center) {
      map.flyTo({
        center: center,
        zoom: 18,
      });
      this.props.set_value_properties({
        key: "modal_pop_up",
        value: true,
      });
      this.props.set_value_properties({
        key: "type_pop_up",
        value: modal_item,
      });

      const pop_up_map = (
        <Popup
          tipSize={7}
          anchor="bottom"
          longitude={center[0]}
          latitude={center[1]}
          closebutton={true}
          closeOnClick={false}
          style={{
            zIndex: 2,
          }}
          onClose={() => {
            this.props.inactivate_edit_point_depo();
            this.props.set_value_properties({
              key: "modal_pop_up",
              value: false,
            });
            this.props.set_value_properties({
              key: "pop_up_map",
              value: null,
            });
          }}
        >
          <div
            style={{
              maxHeight: "45vh",
              overflowY: "scroll",
              overflowX: "hidden",
              padding: "0px 10px 0px 0px",
            }}
          >
            <div
              className="text_inferior"
              style={{ fontSize: "10px", lineHeight: "125%" }}
            >
              NAME
            </div>
            <div
              className="text_bold"
              style={{
                fontSize: "15px",
                wordBreak: "break-word",
                marginTop: 2.5,
              }}
            >
              {item?.["Nama Toko"]
                ? item?.["Nama Toko"]
                : item?.["NAMA OUTLET"]
                ? item?.["NAMA OUTLET"]
                : item?.["REGION"]
                ? item?.["REGION"]
                : item?.["Nama Salesman"]
                ? item?.["Nama Salesman"]
                : item?.["NOPOL"]
                ? `${item?.["NOPOL"]}`
                : item?.["Depo"]}
            </div>
          </div>
        </Popup>
      );

      this.props.set_value_properties({
        key: "pop_up_map",
        value: pop_up_map,
      });
    }
  };

  render() {
    //local storage

    //local state

    //global props

    //content
    return (
      <button className="button_small" id="green" onClick={this.on_fly}>
        View on map
      </button>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
});

export default connect(mapStateToProps, {
  set_value_properties,
  inactivate_edit_point_depo,
  popUpAction,
})(ON_FLY);
