import React, { Component } from "react";
import { connect } from "react-redux";
import {
  clear_current_driver,
  get_log_one_driver,
  set_current_driver,
  slider_log_driver,
  undo_driver_with_location,
} from "../../actions/tracking";
import formatDateLengkap from "../../validation/format-date-lengkap";
import { setToggleRoute } from "../../actions/dataActions";
export class DriverHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: "",
      end_date: "",
    };
  }
  on_handle_date = (event) => {
    let unixDate = 0;
    if (event.target.name === "start_date") {
      unixDate = new Date(`${event.target.value} 00:00:00`).getTime();
    }
    if (event.target.name === "end_date") {
      unixDate = new Date(`${event.target.value} 23:59:00`).getTime();
    }
    this.setState({
      [event.target.name]: unixDate,
    });
  };
  on_handle_submit = (event) => {
    event.preventDefault();
    const { currentDriver } = this.props.tracking;
    this.props.get_log_one_driver({
      _id: currentDriver._id,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    });
  };
  slider_log = (event) => {
    const index = parseInt(event.target.value);
    const features = this.props.tracking.driver_geojsonMultiPoint.features;
    const geojson = {
      type: "FeatureCollection",
      features: [features[index]],
    };
    this.props.slider_log_driver(geojson);
  };
  close = () => {
    this.props.set_current_driver({});
    this.props.clear_current_driver();
    this.props.undo_driver_with_location();
  };
  routeToggle = (p) => {
    const checked = p.target.checked;
    this.props.setToggleRoute(checked);
  };
  render() {
    const { route_mode } = this.props.data;
    const { currentDriver, driver_geojsonMultiPoint, driver_geojsonPoint } =
      this.props.tracking;
    const { loading_top, salesman_filter } = this.props.data;
    const driver_info = (
      <div>
        <div className="flex">
          <p className="flex-1">DRIVER: {currentDriver?.DRIVER}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-700 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            onClick={this.close}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <hr className="my-2" />
        <div className="grid grid-cols-2">
          <small className="text-sm text-gray-600">
            License plate number: {currentDriver?.["NOPOL"]}
          </small>
          <small className="text-sm text-gray-600">
            Depo: {currentDriver?.["Depo"]}
          </small>
          <small className="text-sm text-gray-600">
            Branch:{" "}
            {
              salesman_filter.depo.filter(
                (e) => e.Depo === currentDriver?.Depo
              )?.[0]?.CABANG
            }
          </small>
          <small className="text-sm text-gray-600">
            Region:{" "}
            {
              salesman_filter.depo.filter(
                (e) => e.Depo === currentDriver?.Depo
              )?.[0]?.REGION
            }
          </small>
          <small className="text-sm text-gray-600">
            Car Type: {currentDriver?.["JENIS"]}
          </small>
          <small className="text-sm text-gray-600">
            Carton Capacity: {currentDriver?.["KAPASITAS_CTN"]}
          </small>
          <small className="text-sm text-gray-600">
            Volume Capacity: {currentDriver?.["KAPASITAS_M3"]} m³
          </small>
        </div>
        <div className="mb-1">
          <small className="text-sm text-gray-600">
            Last Update:{" "}
            {currentDriver?.log.length > 0
              ? formatDateLengkap(currentDriver.log[0].time_stamp[0])
              : "-"}
          </small>
        </div>
      </div>
    );
    const filter_log_history = (
      <form onSubmit={this.on_handle_submit}>
        <div className="grid grid-cols-2 gap-2">
          <div className="flex flex-col">
            <label htmlFor="start-date">Select Start Date</label>
            <input
              type="date"
              className="w-full"
              id="start_date"
              name="start_date"
              onChange={this.on_handle_date}
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="end-date">Select End Date</label>
            <input
              type="date"
              className="w-full"
              id="end_date"
              name="end_date"
              onChange={this.on_handle_date}
              required
            />
          </div>
        </div>
        <div className="mt-2">
          <button className="button" type="submit" id="green">
            {loading_top ? "Loading..." : "Filter"}
          </button>
        </div>
      </form>
    );
    const slider_log_history = (
      <div>
        <input
          type="range"
          step="1"
          min="0"
          max={driver_geojsonMultiPoint?.features?.length - 1}
          className="w-full"
          onChange={this.slider_log}
        />
        <div>
          <p>
            Time:{" "}
            {formatDateLengkap(
              driver_geojsonPoint?.features?.[0]?.properties?.timestamp
            )}
          </p>
          <div className="flex items-end">
            <p className="mr-2">Showing route</p>
            <label className="switch">
              <input
                type="checkbox"
                onChange={this.routeToggle}
                checked={route_mode}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    );
    const slider_log_history_not_found = (
      <div className="mt-4">
        <hr />
        <div className="text-center py-4">"No history data recorded"</div>
      </div>
    );
    return (
      <div className="mt-4">
        {driver_info}
        <hr className="my-2" />
        {filter_log_history}
        {driver_geojsonMultiPoint?.features?.length > 0
          ? slider_log_history
          : slider_log_history_not_found}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  tracking: state.tracking,
  data: state.data,
});

const mapDispatchToProps = {
  set_current_driver,
  get_log_one_driver,
  slider_log_driver,
  clear_current_driver,
  setToggleRoute,
  undo_driver_with_location,
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverHistory);
