import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";
import calc_crow from "../validation/calc_crow";
import sort_array from "../validation/sort_array";

import { get_count_competitor_national_not_done } from "./dataActions";

const SERVER_URL = geoServerBaseUrl;

//NON API

/*GET METHODE*/

/*POST METHODE*/

//start_rayon_depo_competitor
export const start_rayon_depo_competitor = (body) => async (dispatch) => {
  /*
  body = {
    start, end, depo_list_filtered, _id, task_id, user
  }
  */
  try {
    const { depo_list_filtered, _id, task_id, user, child_task_id } = body;
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "child_task_finish_index",
        value: 0,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "child_task_current",
        value: 0,
      },
    });
    dispatch({
      type: "set_loading_properties",
      payload: "start_rayon_depo_competitor",
    });
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenNabati,
      },
    };
    const body_update_1 = {
      task_id,
      child_task_id: _id,
      user,
    };
    await axios.post(
      `${SERVER_URL}/properties/on_progress_child_task`,
      body_update_1,
      config
    );
    dispatch({
      type: "on_progress_child_task",
      payload: body_update_1,
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "task_id_current",
        value: _id,
      },
    });
    const res = await axios.get(
      `${SERVER_URL}/nabati-outlet/get_outlet_by_child_task_id?child_task_id=${child_task_id}`,
      config
    );
    const outlet_list = res?.data || [];
    const outlet_list_length = outlet_list.length;
    if (outlet_list_length > 0) {
      const array = outlet_list;
      const total = array.length;
      const limit = 5_000;
      const iteration = Math.ceil(total / limit);
      let array_loop = [];
      for (let i = 0; i < iteration; i++) {
        array_loop.push({
          start: i * limit,
          end: i * limit + limit,
        });
      }
      dispatch({
        type: "set_value_properties",
        payload: {
          key: "child_task_finish_index",
          value: array_loop[array_loop.length - 1].end,
        },
      });
      const delay_c = 0;
      const delay = () => new Promise((res) => setTimeout(res, delay_c));
      const parent_function = () => {
        return array_loop.reduce(
          (last_promise, object) =>
            last_promise.then((result_sum) =>
              child_function(object).then((result_current) => [
                ...result_sum,
                result_current,
              ])
            ),
          Promise.resolve([])
        );
      };
      const child_function = async (object) => {
        return delay().then(() => {
          const core_function = async () => {
            try {
              const { start, end } = object;
              let array_new = array.slice(start, end);
              array_new = array_new.map((outlet) => {
                const lon1 = outlet?.location?.coordinates?.[0] || 0;
                const lat1 = outlet?.location?.coordinates?.[1] || 0;
                let depo_distance_array = depo_list_filtered.map((depo) => {
                  const lon2 = depo?.location?.coordinates?.[0] || 0;
                  const lat2 = depo?.location?.coordinates?.[1] || 0;
                  const distance = calc_crow(lat1, lon1, lat2, lon2);
                  depo.distance = distance;
                  return depo;
                });
                depo_distance_array = sort_array(
                  depo_distance_array,
                  "distance",
                  true
                );
                const depo_closest = depo_distance_array[0];
                const depo_id = depo_closest?._id;
                const depo_name = depo_closest?.Depo;
                const depo_plan_id = depo_closest?.plan_id;
                const depo_distance = depo_closest?.distance;
                return {
                  _id: outlet._id,
                  depo_id,
                  depo_name,
                  depo_plan_id,
                  depo_distance,
                };
              });
              const body_update_depo = { array: array_new };
              await axios.post(
                `${SERVER_URL}/properties/update_many_outlet_depo_v2`,
                body_update_depo,
                config
              );
              dispatch({
                type: "set_value_properties",
                payload: {
                  key: "child_task_current",
                  value: end,
                },
              });
            } catch (error) {}
          };
          return core_function();
        });
      };
      parent_function().then(async () => {
        await axios.post(
          `${SERVER_URL}/properties/finish_child_task`,
          body_update_1,
          config
        );
        dispatch(get_count_competitor_national_not_done());
        dispatch({
          type: "finish_child_task",
          payload: body_update_1,
        });
        dispatch({
          type: "clear_loading_properties",
        });
      });
    }
  } catch (e) {
    dispatch({
      type: "clear_loading_properties",
    });
  }
};
