//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import {
  create_segment,
  edit_segment,
  delete_segment,
} from "../../actions/dataActions";
import { aggregate_segment_outlet } from "../../actions/propertiesActions";

//Picture Asset

//General Function

class EditSement extends Component {
  state = {};

  componentDidMount() {
    this.props.aggregate_segment_outlet();
  }

  render() {
    const { segment_list } = this.props.data;
    const list_content = (
      <table style={{ width: "100%" }} className="table_lite mt-10">
        <tbody>
          {segment_list.map((item, idx) => {
            return (
              <tr key={idx}>
                <td>{idx + 1}.</td>
                <td>{item.text}</td>
                <td>
                  <div
                    style={{
                      backgroundColor: item.color,
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );

    return (
      <>
        <p className="text_header">Segment List</p>
        {list_content}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, {
  create_segment,
  edit_segment,
  delete_segment,
  aggregate_segment_outlet,
})(EditSement);
