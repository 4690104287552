const filter_outlet_lokasi_kosong = (list) => {
  let outlet_list_filter_0 = list.filter(
    (e) =>
      e?.location?.coordinates?.[0] &&
      e?.location?.coordinates?.[1] &&
      e?.location?.coordinates?.[0] !== 0 &&
      e?.location?.coordinates?.[1] >= -90 &&
      e?.location?.coordinates?.[1] <= 90
  );
  return outlet_list_filter_0;
};

export default filter_outlet_lokasi_kosong;
