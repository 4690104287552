//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
import Modal from "../common/Modal";
//Redux function
import {
  get_user_list,
  edit_user,
  generate_secret_reset_password,
  // searchUserList,
  // valueUserList,
  submitUserList,
} from "../../actions/authActions";
// import { getSalesmanFilter } from "../actions/dataActions";
//Picture Asset
//General Function
import sort_array from "../../validation/sort_array";
import formatDateLengkap from "../../validation/format-date-lengkap";
import isEqual from "../../validation/is-equal";
import { CSVLink } from "react-csv";
import formatDateFormal from "../../validation/format_date_formal";
import writeXlsxFile from "write-excel-file";
class EditAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_list_state: [],
      user_id: "",
      modal_reset_password: false,
      modal_edit_admin: false,
      user_select: {},
      list_column: 50,
      dataCSV: [],
      loadingUnduh: false,
      nama: "",
      depo: "",
      jabatan: "",
      role: "",
      status: "",
      current_admin: {},
      downloadAdmin: false,
    };
    // this.handleSearch = this.handleSearch.bind(this);
  }
  componentDidMount() {
    this.props.get_user_list();
    // this.props.getSalesmanFilter();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps?.auth?.user_list, prevState?.user_list_state)) {
      return {
        user_list_state: nextProps?.auth?.user_list,
      };
    } else {
      return null;
    }
  }

  on_generate_secret_reset_password = () => {
    const { user_select } = this.state;
    const body = {
      _id: user_select._id,
    };
    this.props.generate_secret_reset_password(body);
  };
  toggle_reset_password = (user_select) => {
    this.setState({ modal_reset_password: !this.state.modal_reset_password });
    if (user_select) {
      this.setState({ user_select });
    }
  };
  on_change = (e) => {
    const target = e.target;
    let value = target.value;
    const name = target.name;
    const id = target.id;
    this.setState({ user_id: id });
    let { user_list_state } = this.state;
    const idx = user_list_state.findIndex((user) => user._id === id);
    if (name === "depo") {
      const { salesman_filter } = this.props.data;
      const list_depo = salesman_filter?.depo ? salesman_filter.depo : [];
      const depo_id = list_depo.find((d) => d.Depo === value)?._id;
      user_list_state[idx].depo_id = depo_id;
    }
    user_list_state[idx][name] = value;
    this.setState({
      user_list_state,
    });
    const body = {
      _id: id,
      edited_object: {
        [name]: value,
      },
    };
    if (name !== "depo") {
      this.props.edit_user(body);
    }
  };
  on_save_depo = () => {
    const { user_list_state, user_id } = this.state;
    const idx = user_list_state.findIndex((user) => user._id === user_id);
    const value = user_list_state[idx].depo;
    const { salesman_filter } = this.props.data;
    const list_depo = salesman_filter?.depo ? salesman_filter.depo : [];
    const depo_id = list_depo.find((d) => d.Depo === value)?._id;
    const body = {
      _id: user_id,
      edited_object: {
        depo: depo_id,
      },
    };
    this.props.edit_user(body);
  };
  submitForm = (e) => {
    e.preventDefault();
    this.props.submitUserList({
      nama: this.state.nama,
      jabatan: this.state.jabatan,
      role: this.state.role,
      status: this.state.status,
      depo: this.state.depo,
    });
    if (this.props.auth.back_up_user_list) {
      this.setState({
        list_column: 50,
      });
    }
  };
  // valueSearch = (e) => {
  //   this.props.valueUserList(e.target.value);
  // };
  // handleSearch = (e) => {
  //   this.setState({
  //     // depo: e.target.listDepo.value,
  //     // jabatan: e.target.listJabatan.value,
  //     // role: e.target.listRole.value,
  //     // status: e.target.listStatus.value,
  //     [e.target.name]: e.target.value,
  //   });
  //   // this.props.searchUserList(e.target.value);
  //   // if (this.props.auth.back_up_user_list) {
  //   //   this.setState({
  //   //     list_column: 10,
  //   //   });
  //   // }
  // };
  on_unduh_csv = () => {
    this.setState({
      loading: true,
    });
    let tempData = [];
    let header = [
      "Nama",
      "No Telp",
      "email",
      "Jabatan",
      "Role",
      "Status",
      "Kewenangan",
      "Wilayah Kerja",
      "Tanggal daftar",
    ];
    tempData.push(header);
    const { salesman_filter } = this.props.data;
    for (let index = 0; index < this.state.user_list_state.length; index++) {
      const element = this.state.user_list_state[index];
      let wilayah_kerja = "-";
      if (element.cakupan_wilayah_kerja === "depo") {
        const list_depo = salesman_filter?.depo ? salesman_filter.depo : [];
        wilayah_kerja = list_depo.find((e) => e._id === element["depo"])?.Depo;
      }
      if (element.cakupan_wilayah_kerja === "cabang") {
        const list_cabang = salesman_filter?.cabang
          ? salesman_filter.cabang
          : [];
        wilayah_kerja = list_cabang.find(
          (e) => e._id === element.cabang_list[0]
        )?.CABANG;
      }
      if (element.cakupan_wilayah_kerja === "regional") {
        const list_region = salesman_filter?.regional
          ? salesman_filter.regional
          : [];
        wilayah_kerja = list_region.find(
          (e) => e._id === element.regional_list[0]
        )?.REGIONAL;
      }
      tempData.push([
        element["full_name"],
        element["phone_number"],
        element["email"],
        element["jabatan"],
        element["badge"],
        element["status_badge"],
        element["cakupan_wilayah_kerja"],
        wilayah_kerja,
        formatDateFormal(element["date_created"]),
      ]);
    }
    this.setState({
      loading: true,
      dataCSV: tempData,
    });
  };
  on_unduh_excel = async () => {
    this.setState({
      loading: true,
    });
    const { salesman_filter } = this.props.data;
    const schema = [
      {
        column: "Name",
        type: String,
        value: (admin) => admin.name,
      },
      {
        column: "Telephone Number",
        type: String,
        value: (admin) => admin.phone_number,
      },
      {
        column: "Email",
        type: String,
        value: (admin) => admin.email,
      },
      {
        column: "Job Position",
        type: String,
        value: (admin) => admin.jabatan,
      },
      {
        column: "Role",
        type: String,
        value: (admin) => admin.badge,
      },
      {
        column: "Status",
        type: String,
        value: (admin) => admin.status_badge,
      },
      {
        column: "Authority",
        type: String,
        value: (admin) => admin.cakupan_wilayah_kerja,
      },
      {
        column: "Working Area",
        type: String,
        value: (admin) => admin.wilayah_kerja,
      },
      {
        column: "Country",
        type: String,
        value: (admin) => admin.negara,
      },
      {
        column: "Registered Date",
        type: String,
        value: (admin) => admin.status,
      },
    ];
    let newData = [];
    this.state.user_list_state.map((e) => {
      let wilayah_kerja = "-";
      if (e.cakupan_wilayah_kerja === "depo") {
        const list_depo = salesman_filter?.depo ? salesman_filter.depo : [];
        wilayah_kerja = list_depo.find((i) => i._id === e["depo"])?.Depo;
      }
      if (e.cakupan_wilayah_kerja === "cabang") {
        const list_cabang = salesman_filter?.cabang
          ? salesman_filter.cabang
          : [];
        wilayah_kerja = list_cabang.find(
          (i) => i._id === e.cabang_list[0]
        )?.CABANG;
      }
      if (e.cakupan_wilayah_kerja === "regional") {
        const list_region = salesman_filter?.regional
          ? salesman_filter.regional
          : [];
        wilayah_kerja = list_region.find(
          (i) => i._id === e.regional_list[0]
        )?.REGIONAL;
      }

      let getData = {
        name: e.name,
        phone_number: e.phone_number,
        email: e.email,
        jabatan: e.jabatan,
        badge: e.badge,
        status_badge: e.status_badge,
        cakupan_wilayah_kerja: e.cakupan_wilayah_kerja,
        wilayah_kerja: wilayah_kerja,
        negara: e.negara,
        status: formatDateFormal(e.date_created),
      };
      newData.push(getData);
      return null;
    });
    await writeXlsxFile(newData, {
      schema,
      fileName: "DataExcelAdmin.xlsx",
    });
    this.setState({
      loading: true,
    });
  };
  onSubmitEditAdmin = (event) => {
    event.preventDefault();
    // console.log(this.state.current_admin);
    const body = {
      _id: this.state.current_admin._id,
      edited_object: this.state.current_admin,
    };
    this.props.edit_user(body);
    this.setState({
      modal_edit_admin: false,
    });
    this.props.get_user_list();
  };
  onChangeModalEditAdmin = (event) => {
    this.setState((prevState) => ({
      current_admin: {
        ...prevState.current_admin,
        [event.target.name]: event.target.value,
      },
    }));
    if (event.target.name === "cakupan_wilayah_kerja") {
      switch (event.target.value) {
        case "nasional":
          this.setState((prevState) => ({
            current_admin: {
              ...prevState.current_admin,
              depo: "",
              depo_list: [],
              cabang_list: [],
              regional_list: [],
            },
          }));
          break;
        case "depo":
          this.setState((prevState) => ({
            current_admin: {
              ...prevState.current_admin,
              cabang_list: [],
              regional_list: [],
            },
          }));
          break;
        case "cabang":
          this.setState((prevState) => ({
            current_admin: {
              ...prevState.current_admin,
              depo: "",
              depo_list: [],
              regional_list: [],
            },
          }));
          break;
        case "regional":
          this.setState((prevState) => ({
            current_admin: {
              ...prevState.current_admin,
              depo: "",
              depo_list: [],
              cabang_list: [],
            },
          }));
          break;
        default:
          break;
      }
    }
  };
  onChangeDepoCabangRegion = (event) => {
    this.setState((prevState) => ({
      current_admin: {
        ...prevState.current_admin,
        [event.target.name]: [event.target.value],
      },
    }));
    if (event.target.name === "depo_list") {
      // const { salesman_filter } = this.props.data;
      // const list_depo = salesman_filter?.depo ? salesman_filter.depo : [];
      // const objDepo = list_depo.find((d) => d._id === event.target.value);
      this.setState((prevState) => ({
        current_admin: {
          ...prevState.current_admin,
          depo: event.target.value,
        },
      }));
    }
  };
  render() {
    const {
      user_list_state,
      // user_id,
      modal_reset_password,
      user_select,
      dataCSV,
      loadingUnduh,
      modal_edit_admin,
      current_admin,
    } = this.state;

    const { item_loading, loading, user } = this.props.auth;
    const { salesman_filter, sidebarBreakpoints } = this.props.data;
    let list_depo = salesman_filter?.depo ? salesman_filter.depo : [];
    list_depo = sort_array(list_depo, `Depo`, true);
    let list_cabang = salesman_filter?.cabang ? salesman_filter.cabang : [];
    list_cabang = sort_array(list_cabang, `CABANG`, true);
    let list_region = salesman_filter?.regional ? salesman_filter.regional : [];
    list_region = sort_array(list_region, `REGIONAL`, true);
    const original_list_depo = salesman_filter?.original_depo
      ? salesman_filter.original_depo
      : [];
    const original_cabang = salesman_filter?.original_cabang
      ? salesman_filter.original_cabang
      : [];
    const original_regional = salesman_filter?.original_regional
      ? salesman_filter.original_regional
      : [];
    const {
      full_name,
      phone_number,
      email,
      badge,
      status_badge,
      cakupan_wilayah_kerja,
      depo,
      cabang_list,
      regional_list,
    } = user;
    let text_status;
    if (badge === "super_admin" && status_badge === "accepted") {
      text_status = "Verified super admin can edit other admins";
    } else if (badge === "super_admin" && status_badge !== "accepted") {
      text_status = "Your super admin account is not verified yet";
    } else {
      text_status =
        "You are not super admin, you can only see this dashboard as a viewer";
    }
    let cabangUser = "",
      regionalUser = "",
      depoUser = "";
    if (cakupan_wilayah_kerja === "depo") {
      if (typeof depo === "object") {
        depoUser = depo.Depo;
      } else {
        let depo_value = list_depo.find((e) => e._id === depo).Depo;
        depoUser = depo_value;
      }
    }
    if (cakupan_wilayah_kerja === "cabang") {
      let cabang_value = list_cabang.find(
        (e) => e._id === cabang_list[0]
      )?.CABANG;
      cabangUser = cabang_value;
    }
    if (cakupan_wilayah_kerja === "regional") {
      let region_value = list_region.find(
        (e) => e._id === regional_list[0]
      )?.REGIONAL;
      regionalUser = region_value;
    }
    const your_content = (
      <main className="mb-10">
        <section>
          Account Status: <b>{text_status}</b>
        </section>
        <table className="table_lite_fix">
          <tbody>
            <tr>
              <td>Full Name</td>
              <td>{full_name}</td>
            </tr>
            <tr>
              <td>Telephone Number</td>
              <td>{phone_number}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{email}</td>
            </tr>
            <tr>
              <td>Badge</td>
              <td>{badge}</td>
            </tr>
            <tr>
              <td>Badge Status</td>
              <td>{status_badge}</td>
            </tr>
            <tr>
              <td>Area Coverage</td>
              <td>{cakupan_wilayah_kerja}</td>
            </tr>
            <tr>
              <td>Working Area</td>
              {cakupan_wilayah_kerja === "depo" && <td>{depoUser}</td>}
              {cakupan_wilayah_kerja === "cabang" && <td>{cabangUser}</td>}
              {cakupan_wilayah_kerja === "regional" && <td>{regionalUser}</td>}
            </tr>
          </tbody>
        </table>
      </main>
    );
    const list_badge = [
      {
        value: "super_admin",
        name: "Super admin",
      },
      {
        value: "admin",
        name: "Admin",
      },
      {
        value: "planning",
        name: "Planning",
      },
      {
        value: "reporting",
        name: "Reporting",
      },
    ];
    const list_cakupan_wilayah_kerja = [
      {
        value: "nasional",
        name: "Nasional",
      },
      {
        value: "depo",
        name: "Depo",
      },
      {
        value: "cabang",
        name: "Cabang",
      },
      {
        value: "regional",
        name: "Regional",
      },
    ];
    const list_status_badge = [
      {
        value: "pending",
        name: "pending",
      },
      {
        value: "accepted",
        name: "accepted",
      },
      {
        value: "rejected",
        name: "rejected",
      },
    ];
    let button_slice;
    if (user_list_state.length > 0) {
      button_slice = (
        <div className="flex items-center justify-center w-full">
          <button
            className="button_main mt-5"
            onClick={() => {
              let number = this.state.list_column + 50;
              this.setState({ list_column: number });
            }}
          >
            Load More Datas
          </button>
        </div>
      );
    }
    if (this.state.list_column > user_list_state.length) {
      button_slice = (
        <div className="flex items-center justify-center mt-5">
          All Data Has Been Loaded
        </div>
      );
    }
    if (badge !== "super_admin") {
      if (
        this.state.list_column >
        user_list_state.filter((e) => e.negara === user.negara).length
      ) {
        button_slice = (
          <div className="flex items-center justify-center mt-5">
            All Data Has Been Loaded
          </div>
        );
      }
    }
    const list_jabatan = this.props.auth.job_positions;
    let list_content;
    if (user.badge === "super_admin" && user.status_badge === "accepted") {
      // console.log(user_list_state);
      list_content = (
        <React.Fragment>
          <table
            style={{ width: "100%", tableLayout: "fixed" }}
            className="table_lite"
          >
            <thead>
              <tr>
                <th style={{ width: "30px" }}>No</th>
                <th>Name</th>
                <th>Contact</th>
                <th>Job Position</th>
                <th>Role</th>
                <th>Status</th>
                <th>Authority</th>
                <th>Working Area</th>
                <th>Country</th>
                <th>Registered Date</th>
              </tr>
            </thead>
            <tbody>
              {user_list_state
                .slice(0, this.state.list_column)
                .map((user, idx) => {
                  let {
                    // _id,
                    full_name,
                    email,
                    phone_number,
                    jabatan,
                    cakupan_wilayah_kerja,
                    depo,
                    date_created,
                    status_badge,
                    badge,
                    cabang_list,
                    regional_list,
                    negara,
                  } = user;
                  jabatan = jabatan ? jabatan : "";
                  if (jabatan === "ASS") {
                    jabatan = "AREA SALES SUPERVISOR";
                  }
                  if (jabatan === "BM") {
                    jabatan = "BUSINESS MANAGER";
                  }
                  if (jabatan === "RBM") {
                    jabatan = "REGIONAL BUSINESS MANAGER";
                  }
                  let depo_content;
                  if (cakupan_wilayah_kerja === "nasional") {
                    depo_content = "-";
                  } else if (cakupan_wilayah_kerja === "depo") {
                    let depo_value = original_list_depo.find(
                      (e) => e._id === depo
                    )?.Depo;
                    depo_content = depo_value;
                  } else if (cakupan_wilayah_kerja === "cabang") {
                    let cabang_value = list_cabang.find(
                      (e) => e._id === cabang_list[0]
                    )?.CABANG;
                    depo_content = cabang_value;
                  } else if (cakupan_wilayah_kerja === "regional") {
                    let region_value = list_region.find(
                      (e) => e._id === regional_list[0]
                    )?.REGIONAL;
                    depo_content = region_value;
                  }
                  let backgroundColor;
                  if (status_badge === "accepted") {
                    backgroundColor = "#c1e776ff";
                  } else if (status_badge === "pending") {
                    backgroundColor = "#e2e2e2ff";
                  } else if (status_badge === "rejected") {
                    backgroundColor = "#e59e9eff";
                  }
                  return (
                    <tr
                      key={idx}
                      className="hover:bg-gray-200 hover:cursor-pointer even:bg-gray-100"
                      onClick={() =>
                        this.setState({
                          modal_edit_admin: true,
                          current_admin: user,
                        })
                      }
                    >
                      <td className="w-fit">{idx + 1}</td>
                      <td>{full_name}</td>
                      <td>
                        {phone_number}
                        <br />
                        {email}
                      </td>
                      <td>{jabatan}</td>
                      <td>{badge.toUpperCase()}</td>
                      <td>
                        <div style={{ backgroundColor }}>
                          {status_badge.toUpperCase()}
                        </div>
                      </td>
                      <td>{cakupan_wilayah_kerja.toUpperCase()}</td>
                      <td>{depo_content}</td>
                      <td>{negara}</td>
                      <td>{formatDateLengkap(date_created)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {button_slice}
        </React.Fragment>
      );
    } else {
      list_content = (
        <React.Fragment>
          <table
            style={{ width: "100%", tableLayout: "fixed" }}
            className="table_lite"
          >
            <thead>
              <tr>
                <th style={{ width: "30px" }}>No</th>
                <th>Name</th>
                <th>Contact</th>
                <th>Role</th>
                <th>Status</th>
                <th>Authority</th>
                <th>Working Area</th>
                <th>Registered Date</th>
              </tr>
            </thead>
            <tbody>
              {user_list_state
                .filter((e) => e.negara === user.negara)
                .slice(0, this.state.list_column)
                .map((user, idx) => {
                  const {
                    full_name,
                    email,
                    phone_number,
                    cakupan_wilayah_kerja,
                    depo,
                    date_created,
                    status_badge,
                    badge,
                    cabang_list,
                    regional_list,
                  } = user;
                  let depo_content;
                  if (cakupan_wilayah_kerja === "nasional") {
                    depo_content = "-";
                  } else if (cakupan_wilayah_kerja === "depo") {
                    let depo_value = original_list_depo.find(
                      (e) => e._id === depo
                    )?.Depo;
                    depo_content = depo_value;
                  } else if (cakupan_wilayah_kerja === "cabang") {
                    let cabang_value = original_cabang.find(
                      (e) => e._id === cabang_list[0]
                    )?.CABANG;
                    depo_content = cabang_value;
                  } else if (cakupan_wilayah_kerja === "regional") {
                    let region_value = original_regional.find(
                      (e) => e._id === regional_list[0]
                    )?.REGIONAL;
                    depo_content = region_value;
                  }
                  let backgroundColor;
                  if (status_badge === "accepted") {
                    backgroundColor = "#c1e776ff";
                  } else if (status_badge === "pending") {
                    backgroundColor = "#e2e2e2ff";
                  } else if (status_badge === "rejected") {
                    backgroundColor = "#e59e9eff";
                  }
                  return (
                    <tr key={idx}>
                      <td className="w-fit">{idx + 1}</td>
                      <td>{full_name}</td>
                      <td>
                        {phone_number}
                        <br />
                        {email}
                      </td>
                      <td>{badge}</td>
                      <td>
                        <div style={{ backgroundColor }}>{status_badge}</div>
                      </td>
                      <td>
                        <div>{cakupan_wilayah_kerja}</div>
                      </td>
                      <td>{depo_content}</td>
                      <td>{formatDateLengkap(date_created)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {button_slice}
        </React.Fragment>
      );
    }
    if (item_loading && loading === "get_user_list") {
      list_content = <div>Loading...</div>;
    }
    const modal_reset_password_content = modal_reset_password && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_reset_password}
        onClose={this.toggle_reset_password}
      >
        <div className="box-body">
          <section className="main_container mb-3">
            <p className="text_bold">Reset password</p>
            <p>
              Account Owner: <b>{user_select.full_name}</b>
            </p>
            <p>
              Username: <b>{user_select.name}</b>
            </p>
          </section>
          <section className="main_container">
            <p className="text_bold">Reset password procedure</p>
            <ol>
              <li className="mb-5">
                Click generate link
                <br />
                <button
                  onClick={this.on_generate_secret_reset_password}
                  className="button"
                  id="green"
                >
                  Generate link
                </button>
              </li>

              <li className="mb-5">
                Paste and share this link below to the account owner
                {user_select?.reset_code && (
                  <section>
                    <b>
                      https://outdigwebadmin.nabatisnack.co.id/reset_password?username=
                      {user_select.name}&reset_code={user_select.reset_code}
                    </b>
                  </section>
                )}
              </li>

              <li>
                Account owner admin will access the link and fill in the new
                password
              </li>
            </ol>
          </section>
        </div>
      </Modal>
    );
    const modal_edit_admin_content = modal_edit_admin && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_edit_admin}
        onClose={() => this.setState({ modal_edit_admin: false })}
      >
        <div className="box-body">
          <div className="font-semibold text-lg">Change Admin Data</div>
          <hr className="my-2" />
          <div className="text-right">
            <button
              className="button"
              id="grey"
              onClick={this.toggle_reset_password.bind(this, current_admin)}
            >
              Reset password
            </button>
          </div>
          <form onSubmit={this.onSubmitEditAdmin}>
            <div className="grid grid-col-1 gap-2 mt-2">
              <label>Name</label>
              <input
                type="text"
                defaultValue={current_admin?.full_name}
                className="w-full"
                name="full_name"
                onChange={this.onChangeModalEditAdmin}
              />
            </div>
            <div className="grid grid-col-1 gap-2 mt-2">
              <label>Email</label>
              <input
                type="email"
                defaultValue={current_admin?.email}
                className="w-full"
                name="email"
                onChange={this.onChangeModalEditAdmin}
              />
            </div>
            <div className="grid grid-col-1 gap-2 mt-2">
              <label>Telephone Number</label>
              <input
                type="text"
                defaultValue={current_admin?.phone_number}
                className="w-full"
                name="phone_number"
                onChange={this.onChangeModalEditAdmin}
              />
            </div>
            <div className="grid grid-col-1 gap-2 mt-2">
              <label>Job Position</label>
              <select
                name="jabatan"
                defaultValue={current_admin?.jabatan}
                onChange={this.onChangeModalEditAdmin}
                className="w-full"
              >
                {list_jabatan.map(({ name, value }, idx) => {
                  return (
                    <option key={idx} value={value}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="grid grid-col-1 gap-2 mt-2">
              <label>Role</label>
              <select
                name="badge"
                defaultValue={current_admin?.badge}
                onChange={this.onChangeModalEditAdmin}
                className="w-full"
              >
                {list_badge.map(({ name, value }, idx) => {
                  return (
                    <option key={idx} value={value}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="grid grid-col-1 gap-2 mt-2">
              <label>Status</label>
              {(() => {
                let backgroundColor;
                if (current_admin?.status_badge === "accepted") {
                  backgroundColor = "#c1e776ff";
                } else if (current_admin?.status_badge === "pending") {
                  backgroundColor = "#e2e2e2ff";
                } else if (current_admin?.status_badge === "rejected") {
                  backgroundColor = "#e59e9eff";
                }
                return (
                  <select
                    name="status_badge"
                    defaultValue={current_admin?.status_badge}
                    onChange={this.onChangeModalEditAdmin}
                    style={{ backgroundColor }}
                    className="w-full"
                  >
                    {list_status_badge.map(({ name, value }, idx) => {
                      return (
                        <option key={idx} value={value}>
                          {name}
                        </option>
                      );
                    })}
                  </select>
                );
              })()}
            </div>
            <div className="grid grid-col-1 gap-2 mt-2">
              <label>Authority</label>
              <select
                name="cakupan_wilayah_kerja"
                defaultValue={current_admin?.cakupan_wilayah_kerja}
                onChange={this.onChangeModalEditAdmin}
                className="w-full"
              >
                {list_cakupan_wilayah_kerja.map(({ name, value }, idx) => {
                  return (
                    <option key={idx} value={value}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="grid grid-col-1 gap-2 mt-2">
              <label>Working Area</label>
              {current_admin?.cakupan_wilayah_kerja === "depo" && (
                <select
                  name="depo_list"
                  defaultValue={current_admin?.depo}
                  onChange={this.onChangeDepoCabangRegion}
                  className="w-full"
                >
                  <option value="">Select Depo</option>
                  {list_depo.map(({ Depo, _id }, idx) => {
                    return (
                      <option key={idx} value={_id}>
                        {Depo}
                      </option>
                    );
                  })}
                </select>
              )}
              {current_admin?.cakupan_wilayah_kerja === "cabang" && (
                <select
                  name="cabang_list"
                  defaultValue={current_admin?.cabang_list?.[0]}
                  onChange={this.onChangeDepoCabangRegion}
                  className="w-full"
                >
                  <option value="">Choose Branch </option>
                  {list_cabang.map(({ CABANG, _id }, idx) => {
                    return (
                      <option key={idx} value={_id}>
                        {CABANG}
                      </option>
                    );
                  })}
                </select>
              )}
              {current_admin?.cakupan_wilayah_kerja === "regional" && (
                <select
                  name="regional_list"
                  defaultValue={current_admin?.regional_list?.[0]}
                  onChange={this.onChangeDepoCabangRegion}
                  className="w-full"
                >
                  <option value="">Choose Region</option>
                  {list_region.map(({ REGIONAL, _id }, idx) => {
                    return (
                      <option key={idx} value={_id}>
                        {REGIONAL}
                      </option>
                    );
                  })}
                </select>
              )}
              {current_admin?.cakupan_wilayah_kerja === "nasional" && (
                <div className="my-1">National</div>
              )}
            </div>
            <div className="mt-4">
              <button className="button_main w-full">Edit Admin</button>
            </div>
          </form>
        </div>
      </Modal>
    );
    const currDate = formatDateFormal(Date.now());
    let cariNama = (
      <div>
        <input
          id="cariNama"
          name="cariNama"
          type="text"
          className="w-full"
          placeholder={`Find Name`}
          autoComplete="off"
          onChange={(e) => {
            this.setState({ nama: e.target.value });
          }}
        />
      </div>
    );
    let listDepo = (
      <select
        id="listDepo"
        name="listDepo"
        onChange={(e) => {
          this.setState({ depo: e.target.value });
        }}
        className="w-full"
      >
        <option value="semua">All Depo</option>
        {list_depo.map((data, idx) => {
          return (
            <option key={idx} value={data._id}>
              {data.Depo}
            </option>
          );
        })}
      </select>
    );
    let listJabatan = (
      <select
        id="listJabatan"
        name="listJabatan"
        onChange={(e) => {
          this.setState({ jabatan: e.target.value });
        }}
        className="w-full"
      >
        <option value="semua">All Job Position</option>
        {this.props.auth.job_positions.map((e) => (
          <option value={e.value}>{e.name}</option>
        ))}
        {/* <option value="ASS">ASS</option>
        <option value="BM">BM</option>
        <option value="RBM">RBM</option>
        <option value="KADIV">KADIV</option>
        <option value="HEAD OF SALEAS">HEAD OF SALES</option>
        <option value="CM">CM</option>
        <option value="OPERATION MANAGER">OM</option>
        <option value="AREA OPERATIONS SUPERVISOR">AOS</option> */}
      </select>
    );
    let listRole = (
      <select
        id="listRole"
        name="listRole"
        onChange={(e) => {
          this.setState({ role: e.target.value });
        }}
        className="w-full"
      >
        <option value="semua">All Role</option>
        {list_badge.map((e, idx) => {
          return (
            <option key={idx} value={e.value}>
              {e.name}
            </option>
          );
        })}
      </select>
    );
    let listStatus = (
      <select
        id="listStatus"
        name="listStatus"
        onChange={(e) => {
          this.setState({ status: e.target.value });
        }}
        className="w-full"
      >
        <option value="semua">All Status</option>
        {list_status_badge.map((e, idx) => {
          return (
            <option key={idx} value={e.value}>
              {e.name}
            </option>
          );
        })}
      </select>
    );
    const modalDownloadAdmin = this.state.downloadAdmin && (
      <Modal
        modalSize="small"
        isOpen={this.state.downloadAdmin}
        onClose={() =>
          this.setState({ downloadAdmin: !this.state.downloadAdmin })
        }
      >
        <div className="box-body">
          <h1 className="text-center font-bold">Download Admin by CSV</h1>
          <section className={`flex justify-center mb-3`}>
            <CSVLink
              className="button_main"
              data={dataCSV}
              filename={`Data_Admin_${currDate}.csv`}
              onClick={(e, done) => {
                this.on_unduh_csv({}, done);
              }}
              separator={","}
            >
              {loadingUnduh ? "Downloading data . . ." : "Download Admin Data"}
            </CSVLink>
          </section>
          <h1 className="text-center font-bold">Download Admin by EXCEL</h1>
          <section className={`flex justify-center`}>
            <button
              className="button_main"
              onClick={(e, done) => {
                this.on_unduh_excel({}, done);
              }}
            >
              {loadingUnduh ? "Downloading data . . ." : "Download Admin Data"}
            </button>
          </section>
        </div>
      </Modal>
    );
    return (
      <React.Fragment>
        <div className="pl-1 mt-4">
          <p className="text_bold mb-5">Verfication & Admin Monitoring</p>
          {your_content}
          <div className="mb-2">
            <div className="mb-2">
              <button
                className="button ml-1"
                id="green"
                onClick={() => {
                  this.setState({ downloadAdmin: true });
                }}
              >
                Download Admin
              </button>
            </div>

            <form
              className={`grid grid-cols-4 gap-2`}
              onSubmit={this.submitForm}
            >
              {listJabatan}
              {listRole}
              {listStatus}
              {listDepo}
              {cariNama}
              <button
                className={`grid ${
                  sidebarBreakpoints === "lg" &&
                  "button_main mb-2 col-span-2 w-1/2"
                } 
                ${
                  sidebarBreakpoints === "md"
                    ? "button_main mb-2 col-span-2 w-1/2"
                    : "button_main mb-2"
                } gap-2`}
                type="submit"
              >
                Get Data
              </button>
            </form>
          </div>
          {list_content}
          {modal_reset_password_content}
          {modal_edit_admin_content}
          {modalDownloadAdmin}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.data,
});
export default connect(mapStateToProps, {
  get_user_list,
  // getSalesmanFilter,
  edit_user,
  generate_secret_reset_password,
  // searchUserList,
  // valueUserList,
  submitUserList,
})(EditAdmin);
